<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <ValidationProvider name="nombre" rules="required" v-slot="{ errors }"
          :custom-messages="{ required: 'El nombre es obligatorio' }">
          <base-input type="text" label="Nombre" placeholder="Nombre" v-model="correduria.nombre">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <label>Domicilio</label>
    <div class="row">
      <div class="col-8">
        <div class="row">
          <div class="col-md-6">
            <ValidationProvider name="entidad" rules="required" v-slot="{ errors }" :custom-messages="{
              required: 'La entidad es obligatoria'
            }">
              <!-- <label>Entidad Federativa</label>
              <select v-model="correduria.domicilio.entidad" class="custom-select" @change="selectEntidad">
                <option v-for="option in options_entidades" :key="option.value">
                  {{ option.text }}
                </option>
              </select> -->
              <label>Entidad Federativa</label>
              <select @change="fetchCitiesByState" v-model="selectState" class="form-control">
                <option v-if="isEmptyStates" value="noStates" selected>
                  {{ noStatesText }}
                </option>
                <option v-for="option in states" :key="option.id">
                  {{ option.name }}
                </option>
              </select>
              <span class="cardErrors">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="col-md-6">
            <ValidationProvider name="delegacion_municipio" rules="required" v-slot="{ errors }" :custom-messages="{
              required: 'La demarcación territorial es obligatoria'
            }">
              <!-- <label>Demarcación Territorial o Municipio</label>
              <select v-model="correduria.domicilio.delegacion_municipio" class="custom-select">
                <option v-for="option in options_municipios" :key="option.value">
                  {{ option.text }}
                </option>
              </select> -->

              <label>Demarcación Territorial o Municipio</label>
              <select v-model="selectCity" class="form-control">
                <option v-if="isEmptyCities" value="noCities" selected>
                  {{ noCitiesText }}
                </option>
                <option v-for="option in cities" :key="option.id">
                  {{ option.name }}
                </option>
              </select>
              <span class="cardErrors">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <ValidationProvider name="calle" rules="required" v-slot="{ errors }" :custom-messages="{
              required: 'La calle es obligatoria'
            }">
              <base-input type="text" label="Calle / Avenida / Vía" placeholder="Calle"
                v-model="correduria.domicilio.calle">
              </base-input>
              <span class="cardErrors">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="col-md-3">
            <ValidationProvider name="num_exterior" rules="required" v-slot="{ errors }" :custom-messages="{
              required: 'El número exterior es obligatorio'
            }">
              <base-input type="text" label="Número exterior" placeholder="Número exterior"
                v-model="correduria.domicilio.num_exterior">
              </base-input>
              <span class="cardErrors">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="col-md-3">
            <base-input type="text" label="Número interior" placeholder="Número interior"
              v-model="correduria.domicilio.num_interior">
            </base-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <ValidationProvider name="colonia" rules="required" v-slot="{ errors }" :custom-messages="{
              required: 'La colonia es obligatoria'
            }">
              <base-input type="text" label="Colonia o Urbanización" placeholder="Colonia o Urbanización"
                v-model="correduria.domicilio.colonia">
              </base-input>
              <span class="cardErrors">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="col-md-6">
            <ValidationProvider name="ciudad" rules="required" v-slot="{ errors }" :custom-messages="{
              required: 'La ciudad o población es obligatoria'
            }">
              <base-input type="text" label="Ciudad o Población" placeholder="Ciudad o Población"
                v-model="correduria.domicilio.ciudad">
              </base-input>
              <span class="cardErrors">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <ValidationProvider name="pais" rules="required" v-slot="{ errors }" :custom-messages="{
              required: 'El país es obligatorio'
            }">
              <!-- <label>País</label> -->
              <base-input type="text" disabled label="País" placeholder="País" v-model="correduria.domicilio.pais">
              </base-input>
              <!-- <select v-model="correduria.domicilio.pais" class="custom-select">
                <option v-for="option in options" :key="option.value">
                  {{ option.text }}
                </option>
              </select> -->
              <span class="cardErrors">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="col-md-6">
            <ValidationProvider name="codigo_postal" rules="required|numeric" v-slot="{ errors }" :custom-messages="{
              required: 'El código postal es obligatorio',
              numeric: 'El código postal debe ser un número'
            }">
              <base-input type="text" label="Código Postal" placeholder="Código Postal"
                v-model="correduria.domicilio.codigo_postal">
              </base-input>
              <span class="cardErrors">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <ValidationProvider name="email" rules="required" v-slot="{ errors }" :custom-messages="{
              required: 'El email es requerido',
            }">
              <base-input type="email" label="Email" placeholder="Email" v-model="correduria.email">
              </base-input>
              <span class="cardErrors">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
      </div>

      <div class="col-4">
        <DrawGoogleMap @location="updateCoordinates" :lat="parseFloat(correduria.domicilio.lat)"
          :lng="parseFloat(correduria.domicilio.lng)" />
      </div>
    </div>

    <b-button type="submit" class="btn-fill float-right orange-btn">Guardar</b-button>

    <b-modal no-close-on-backdrop centered ref="staff-modal" title="" hide-footer id="modalC" size="xl">
      <ModalStaff :user="currentStaff" :corredurias_id="correduria.id" @closeModal="closeModal" />
    </b-modal>

    <div class="titulo">
      <hr />
      <label>Miembros del Staff</label>
    </div>

    <div class="row">
      <div class="col-8"></div>

      <div class="col-4" v-if="correduria.id != 0">
        <b-button variant="warning" @click="newStaff()" size="l" v-b-tooltip.hover title="Editar"
          class="btn-fill float-right orange-btn">
          Nuevo staff
        </b-button>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog" hide-footer id="modal-confirm-dialog">
          <h4 class="text-center">¿Seguro que quiere eliminar al staff?</h4>
          <h5 class="text-center">Se eliminarán todos los datos.</h5>
          <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog')">Cancelar</b-button>
          <b-button class="mt-3 float-right" variant="primary" @click="deleteStaff()">OK</b-button>
        </b-modal>

        <div class="text-center">
          <b-table :fields="fields" :items="correduria.staff" hover>
            <template #cell(acciones)="{ item }">
              <b-button variant="warning" @click="editStaff(item.id)" size="l" v-b-tooltip.hover title="Editar"
                class="editBtn">
                <b-icon-pencil />
              </b-button>

              <b-button variant="danger" @click="predeleteStaff(item.id)" size="sm" v-b-tooltip.hover title="Eliminar">
                <b-icon-trash />
              </b-button>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

import DrawGoogleMap from "@/views/clients/comparecientes/DrawGoogleMap.vue";
import { StaffApi } from "@/api";
import { FuncionesApi } from "@/api";
import { CatEntidadesFederativasApi } from "@/api";
import ModalStaff from "@/views/staff/corredurias/ModalStaff.vue";

export default {
  name: "Form",

  props: {
    correduria: { type: Object }
  },

  components: {
    DrawGoogleMap,
    ModalStaff
  },

  computed: {
    ...mapState([
      "countries",
      "states",
      "cities",
      "isEmptyStates",
      "isEmptyCities",
    ]),

    ...mapGetters([
      "getCountries",
      "getStatesByCountryId",
      "getCitiesByStateId",
    ]),

    fields() {
      let fields = [
        { key: "index", label: "", class: "text-center" },
        { key: "nombre", label: "Nombre", class: "text-center" },
        { key: "paterno", label: "Paterno", class: "text-center" },
        { key: "materno", label: "Materno", class: "text-center" },
        { key: "email", label: "Email", class: "text-center" },
        { key: "rol", label: "Rol", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" }
      ];
      return fields;
    }
  },

  created() {
    this.correduria.domicilio.pais = "México";
    this.getEntidades();
    // this.selectEntidad();
  },

  watch: {
    "selectCountry": function (val, oldVal) {
      // this.correduria.domicilio.pais = this.selectCountry;
    },
    "selectState": function (val, oldVal) {
      this.correduria.domicilio.entidad = this.selectState;
    },
    "selectCity": function (val, oldVal) {
      this.correduria.domicilio.delegacion_municipio = this.selectCity;
    }
  },

  async mounted() {
    await this.fetchCountries();

    // debugger;
    await this.fetchStatesByCountry();

    if (this.correduria.domicilio.pais) {
      this.selectCountry = this.correduria.domicilio.pais;

      if (this.correduria.domicilio.pais) {
        await this.getExistCountry(this.correduria.domicilio.pais);
      }
      if (this.correduria.domicilio.entidad) {
        await this.getExistState(this.correduria.domicilio.entidad);
      }
      if (this.correduria.domicilio.delegacion_municipio) {
        await this.getExistCity(this.correduria.domicilio.delegacion_municipio);
      }
    }
  },

  data() {
    return {
      form: { nombre: null, staff: [] },
      currentStaffId: null,
      currentStaff: null,
      options: [
        { text: "México", value: "México" },
        { text: " España", value: " España" },
        { text: " Argentina", value: " Argentina" },
        { text: " Estados Unidos", value: " Estados Unidos" },
        { text: " Afganistán", value: " Afganistán" },
        { text: " Albania", value: " Albania" },
        { text: " Alemania", value: " Alemania" },
        { text: " Andorra", value: " Andorra" },
        { text: " Angola", value: " Angola" },
        { text: " Antigua y Barbuda", value: " Antigua y Barbuda" },
        { text: " Arabia Saudita", value: " Arabia Saudita" },
        { text: " Argelia", value: " Argelia" },
        { text: " Armenia", value: " Armenia" },
        { text: " Australia", value: " Australia" },
        { text: " Austria", value: " Austria" },
        { text: " Azerbaiyán", value: " Azerbaiyán" },
        { text: " Bahamas", value: " Bahamas" },
        { text: " Bangladés", value: " Bangladés" },
        { text: " Barbados", value: " Barbados" },
        { text: " Baréin", value: " Baréin" },
        { text: " Bélgica", value: " Bélgica" },
        { text: " Belice", value: " Belice" },
        { text: " Benín", value: " Benín" },
        { text: " Bielorrusia", value: " Bielorrusia" },
        { text: " Birmania", value: " Birmania" },
        { text: " Bolivia", value: " Bolivia" },
        { text: " Bosnia y Herzegovina", value: " Bosnia y Herzegovina" },
        { text: " Botsuana", value: " Botsuana" },
        { text: " Brasil", value: " Brasil" },
        { text: " Brunéi", value: " Brunéi" },
        { text: " Bulgaria", value: " Bulgaria" },
        { text: " Burkina Faso", value: " Burkina Faso" },
        { text: " Burundi", value: " Burundi" },
        { text: " Bután", value: " Bután" },
        { text: " Cabo Verde", value: " Cabo Verde" },
        { text: " Camboya", value: " Camboya" },
        { text: " Camerún", value: " Camerún" },
        { text: " Canadá", value: " Canadá" },
        { text: " Catar", value: " Catar" },
        { text: " Chad", value: " Chad" },
        { text: " Chile", value: " Chile" },
        { text: " China", value: " China" },
        { text: " Chipre", value: " Chipre" },
        { text: " Ciudad del Vaticano", value: " Ciudad del Vaticano" },
        { text: " Colombia", value: " Colombia" },
        { text: " Comoras", value: " Comoras" },
        { text: " Corea del Norte", value: " Corea del Norte" },
        { text: " Corea del Sur", value: " Corea del Sur" },
        { text: " Costa de Marfil", value: " Costa de Marfil" },
        { text: " Costa Rica", value: " Costa Rica" },
        { text: " Croacia", value: " Croacia" },
        { text: " Cuba", value: " Cuba" },
        { text: " Dinamarca", value: " Dinamarca" },
        { text: " Dominica", value: " Dominica" },
        { text: " Ecuador", value: " Ecuador" },
        { text: " Egipto", value: " Egipto" },
        { text: " El Salvador", value: " El Salvador" },
        { text: " Emiratos Árabes Unidos", value: " Emiratos Árabes Unidos" },
        { text: " Eritrea", value: " Eritrea" },
        { text: " Eslovaquia", value: " Eslovaquia" },
        { text: " Eslovenia", value: " Eslovenia" },
        { text: " Estonia", value: " Estonia" },
        { text: " Etiopía", value: " Etiopía" },
        { text: " Filipinas", value: " Filipinas" },
        { text: " Finlandia", value: " Finlandia" },
        { text: " Fiyi", value: " Fiyi" },
        { text: " Francia", value: " Francia" },
        { text: " Gabón", value: " Gabón" },
        { text: " Gambia", value: " Gambia" },
        { text: " Georgia", value: " Georgia" },
        { text: " Ghana", value: " Ghana" },
        { text: " Granada", value: " Granada" },
        { text: " Grecia", value: " Grecia" },
        { text: " Guatemala", value: " Guatemala" },
        { text: " Guyana", value: " Guyana" },
        { text: " Guinea", value: " Guinea" },
        { text: " Guinea ecuatorial", value: " Guinea ecuatorial" },
        { text: " Guinea-Bisáu", value: " Guinea-Bisáu" },
        { text: " Haití", value: " Haití" },
        { text: " Honduras", value: " Honduras" },
        { text: " Hungría", value: " Hungría" },
        { text: " India", value: " India" },
        { text: " Indonesia", value: " Indonesia" },
        { text: " Irak", value: " Irak" },
        { text: " Irán", value: " Irán" },
        { text: " Irlanda", value: " Irlanda" },
        { text: " Islandia", value: " Islandia" },
        { text: " Islas Marshall", value: " Islas Marshall" },
        { text: " Islas Salomón", value: " Islas Salomón" },
        { text: " Israel", value: " Israel" },
        { text: " Italia", value: " Italia" },
        { text: " Jamaica", value: " Jamaica" },
        { text: " Japón", value: " Japón" },
        { text: " Jordania", value: " Jordania" },
        { text: " Kazajistán", value: " Kazajistán" },
        { text: " Kenia", value: " Kenia" },
        { text: " Kirguistán", value: " Kirguistán" },
        { text: " Kiribati", value: " Kiribati" },
        { text: " Kuwait", value: " Kuwait" },
        { text: " Laos", value: " Laos" },
        { text: " Lesoto", value: " Lesoto" },
        { text: " Letonia", value: " Letonia" },
        { text: " Líbano", value: " Líbano" },
        { text: " Liberia", value: " Liberia" },
        { text: " Libia", value: " Libia" },
        { text: " Liechtenstein", value: " Liechtenstein" },
        { text: " Lituania", value: " Lituania" },
        { text: " Luxemburgo", value: " Luxemburgo" },
        { text: " Macedonia del Norte", value: " Macedonia del Norte" },
        { text: " Madagascar", value: " Madagascar" },
        { text: " Malasia", value: " Malasia" },
        { text: " Malaui", value: " Malaui" },
        { text: " Maldivas", value: " Maldivas" },
        { text: " Malí", value: " Malí" },
        { text: " Malta", value: " Malta" },
        { text: " Marruecos", value: " Marruecos" },
        { text: " Mauricio", value: " Mauricio" },
        { text: " Mauritania", value: " Mauritania" },
        { text: " México", value: " México" },
        { text: " Micronesia", value: " Micronesia" },
        { text: " Moldavia", value: " Moldavia" },
        { text: " Mónaco", value: " Mónaco" },
        { text: " Mongolia", value: " Mongolia" },
        { text: " Montenegro", value: " Montenegro" },
        { text: " Mozambique", value: " Mozambique" },
        { text: " Namibia", value: " Namibia" },
        { text: " Nauru", value: " Nauru" },
        { text: " Nepal", value: " Nepal" },
        { text: " Nicaragua", value: " Nicaragua" },
        { text: " Níger", value: " Níger" },
        { text: " Nigeria", value: " Nigeria" },
        { text: " Noruega", value: " Noruega" },
        { text: " Nueva Zelanda", value: " Nueva Zelanda" },
        { text: " Omán", value: " Omán" },
        { text: " Países Bajos", value: " Países Bajos" },
        { text: " Pakistán", value: " Pakistán" },
        { text: " Palaos", value: " Palaos" },
        { text: " Panamá", value: " Panamá" },
        { text: " Papúa Nueva Guinea", value: " Papúa Nueva Guinea" },
        { text: " Paraguay", value: " Paraguay" },
        { text: " Perú", value: " Perú" },
        { text: " Polonia", value: " Polonia" },
        { text: " Portugal", value: " Portugal" },
        { text: " Reino Unido", value: " Reino Unido" },
        {
          text: " República Centroafricana",
          value: " República Centroafricana"
        },
        { text: " República Checa", value: " República Checa" },
        { text: " República del Congo", value: " República del Congo" },
        {
          text: " República Democrática del Congo",
          value: " República Democrática del Congo"
        },
        { text: " República Dominicana", value: " República Dominicana" },
        { text: " Ruanda", value: " Ruanda" },
        { text: " Rumanía", value: " Rumanía" },
        { text: " Rusia", value: " Rusia" },
        { text: " Samoa", value: " Samoa" },
        { text: " San Cristóbal y Nieves", value: " San Cristóbal y Nieves" },
        { text: " San Marino", value: " San Marino" },
        {
          text: " San Vicente y las Granadinas",
          value: " San Vicente y las Granadinas"
        },
        { text: " Santa Lucía", value: " Santa Lucía" },
        { text: " Santo Tomé y Príncipe", value: " Santo Tomé y Príncipe" },
        { text: " Senegal", value: " Senegal" },
        { text: " Serbia", value: " Serbia" },
        { text: " Seychelles", value: " Seychelles" },
        { text: " Sierra Leona", value: " Sierra Leona" },
        { text: " Singapur", value: " Singapur" },
        { text: " Siria", value: " Siria" },
        { text: " Somalia", value: " Somalia" },
        { text: " Sri Lanka", value: " Sri Lanka" },
        { text: " Suazilandia", value: " Suazilandia" },
        { text: " Sudáfrica", value: " Sudáfrica" },
        { text: " Sudán", value: " Sudán" },
        { text: " Sudán del Sur", value: " Sudán del Sur" },
        { text: " Suecia", value: " Suecia" },
        { text: " Suiza", value: " Suiza" },
        { text: " Surinam", value: " Surinam" },
        { text: " Tailandia", value: " Tailandia" },
        { text: " Tanzania", value: " Tanzania" },
        { text: " Tayikistán", value: " Tayikistán" },
        { text: " Timor Oriental", value: " Timor Oriental" },
        { text: " Togo", value: " Togo" },
        { text: " Tonga", value: " Tonga" },
        { text: " Trinidad y Tobago", value: " Trinidad y Tobago" },
        { text: " Túnez", value: " Túnez" },
        { text: " Turkmenistán", value: " Turkmenistán" },
        { text: " Turquía", value: " Turquía" },
        { text: " Tuvalu", value: " Tuvalu" },
        { text: " Ucrania", value: " Ucrania" },
        { text: " Uganda", value: " Uganda" },
        { text: " Uruguay", value: " Uruguay" },
        { text: " Uzbekistán", value: " Uzbekistán" },
        { text: " Vanuatu", value: " Vanuatu" },
        { text: " Venezuela", value: " Venezuela" },
        { text: " Vietnam", value: " Vietnam" },
        { text: " Yemen", value: " Yemen" },
        { text: " Yibuti", value: " Yibuti" },
        { text: " Zambia", value: " Zambia" },
        { text: " Zimbabue", value: " Zimbabue" }
      ],
      options_entidades: [],
      // options_entidades: [
      //   { text: "Aguascalientes", value: "Aguascalientes" },
      //   { text: "Baja California", value: "Baja California" },
      //   { text: "Baja California Sur", value: "Baja California Sur" },
      //   { text: "Campeche", value: "Campeche" },
      //   { text: "Chiapas", value: "Chiapas" },
      //   { text: "Chihuahua", value: "Chihuahua" },
      //   { text: "Coahuila", value: "Coahuila" },
      //   { text: "Colima", value: "Colima" },
      //   { text: "Ciudad de México", value: "Ciudad de México" },
      //   { text: "Durango", value: "Durango" },
      //   { text: "Guanajuato", value: "Guanajuato" },
      //   { text: "Guerrero", value: "Guerrero" },
      //   { text: "Hidalgo", value: "Hidalgo" },
      //   { text: "Jalisco", value: "Jalisco" },
      //   { text: "Estado de México", value: "Estado de México" },
      //   { text: "Michoacán", value: "Michoacán" },
      //   { text: "Morelos", value: "Morelos" },
      //   { text: "Nayarit", value: "Nayarit" },
      //   { text: "Nuevo León", value: "Nuevo León" },
      //   { text: "Oaxaca", value: "Oaxaca" },
      //   { text: "Puebla", value: "Puebla" },
      //   { text: "Querétaro", value: "Querétaro" },
      //   { text: "Quintana Roo", value: "Quintana Roo" },
      //   { text: "San Luis Potosí", value: "San Luis Potosí" },
      //   { text: "Sinaloa", value: "Sinaloa" },
      //   { text: "Sonora", value: "Sonora" },
      //   { text: "Tabasco", value: "Tabasco" },
      //   { text: "Tamaulipas", value: "Tamaulipas" },
      //   { text: "Tlaxcala", value: "Tlaxcala" },
      //   { text: "Veracruz ", value: "Veracruz " },
      //   { text: "Yucatán", value: "Yucatán" },
      //   { text: "Zacatecas", value: "Zacatecas" }
      // ],
      options_municipios: [],

      noStatesText: "No hay estados disponibles",
      noCitiesText: "No hay ciudades disponibles",
      selectCountry: "México",
      selectState: "",
      selectCity: "",
    };
  },

  methods: {
    ...mapActions(["fetchCountries", "fetchStates", "fetchCities"]),

    async fetchStatesByCountry() {
      /**
       * Esta fx se llama solo si ya se tiene un país seleccionado
       */
      this.selectState = "";
      const pais = this.selectCountry;
      this.selectCity = "";
      // change store state cities to empty
      this.$store.commit("setCities", []);
      // Obtengo el id del país
      const paisId = await this.getIdCountry(pais);
      // obtengo el código de area del país
      const phoneCode = await this.getPhoneCode(pais);
      this.phoneKey = phoneCode;
      // this.currentCompareciente.telefonos[0].clave = phoneCode;
      // obtengo los estados del país
      await this.fetchStates(paisId);
      if (this.isEmptyStates) {
        this.selectState = "noStates";
        this.selectCity = "noCities";
      }
    },

    async fetchCitiesByState() {
      /**
       * Se llama para obtener las ciudades de un estado seleccionado
       */
      this.selectCity = "";
      const estado = this.selectState
      const estadoId = await this.getIdState(estado);
      await this.fetchCities(estadoId);
    },

    async getIdCountry(countryName) {
      /**
       * Obtiene el id del país seleccionado
       */
      const country = this.countries.find((c) => c.name === countryName);
      return country ? country.id : null;
    },

    async getIdState(stateName) {
      /**
       * Obtiene el id del estado seleccionado
       */
      const state = this.states.find((s) => s.name === stateName);
      return state ? state.id : null;
    },

    async getPhoneCode(countryName) {
      /**
       * Obtiene el código de área del país seleccionado
       */
      const country = this.countries.find((c) => c.name === countryName);
      return country ? country.phone_code : null;
    },

    async getExistCountry(countryName) {
      /**
       * Se llama si ya se tiene un país seleccionado
       */
      const currentCountry = countryName === "Mexico" ? "México" : this.correduria.domicilio.pais;
      this.selectCountry = currentCountry;
    },

    async getExistState(stateName) {
      /**
       * Se llama si ya se tiene un estado seleccionado
       */
      const currentState = stateName
      this.selectState = currentState;
      const paisId = await this.getIdCountry(this.selectCountry);
      await this.fetchStates(paisId);
    },

    async getExistCity(cityName) {
      /**
       * Se llama si ya se tiene una ciudad seleccionada
       */
      const currentCity = cityName;
      this.selectCity = currentCity;
      const estadoId = await this.getIdState(this.selectState);
      await this.fetchCities(estadoId);
    },

    async getEntidades() {
      const { value } = await CatEntidadesFederativasApi.list({
        page: 0,
        registersPerPage: 0
      });
      value.forEach(element => {
        const obj = { text: element.entidad_federativa, value: element.id };
        this.options_entidades.push(obj);
      });
      // if (this.correduria.domicilio.entidad != null) {
      this.selectEntidad();
      // }
    },

    async selectEntidad() {
      try {
        this.options_municipios = [];
        if (this.options_entidades) {
          let cat_entidades_id = this.options_entidades.find(
            o => o.text === this.correduria.domicilio.entidad
          ).value;
          console.log(cat_entidades_id);

          const { value } = await FuncionesApi.getMunicipiosDeEntidad(
            cat_entidades_id
          );

          value.forEach(element => {
            const obj = { text: element.municipio, value: element.id };
            this.options_municipios.push(obj);
          });
        }
      } catch (error) {
        // console.log(error);
        // this.selectEntidad();
      }
    },

    updateCoordinates(coordinates) { },

    editStaff(id) {
      this.currentStaff = this.correduria.staff.find(x => x.id == id);

      this.$refs["staff-modal"].show();
    },

    newStaff() {
      this.currentStaff = { id: 0 };
      this.$refs["staff-modal"].show();
    },

    predeleteStaff(id) {
      this.currentStaffId = id;
      this.$refs["modal-confirm-dialog"].show();
    },

    closeModal() {
      this.$refs["staff-modal"].hide();
    },

    async deleteStaff() {
      this.loading = true;
      try {
        let id = this.currentStaffId;

        await StaffApi.delete(id);
        // console.log(this.staff)
        var index = this.correduria.staff.findIndex(function (o) {
          return o.id === id;
        });
        this.correduria.staff.splice(index, 1);
        this.totalStaff--;
        // });
        // })
        this.$toast.success("Staff eliminado", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
        // this.$vueConfirm.close();
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.$toast.error("Sucedió un error, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
      }
      this.$refs["modal-confirm-dialog"].hide();
    }
  }
};
</script>
<style>
.orange-btn {
  background-color: #ea640b !important;
  border-color: #ea640b !important;
  color: white !important;
}

.orange-btn:hover {
  background-color: #e87b31 !important;
  border-color: #e87b31 !important;
  color: white !important;
}

.orange-btn:active {
  background-color: #ea640b !important;
  border-color: #ea640b !important;
  color: white !important;
}

#card {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* color: #9a9a9a; */
  color: #ea640b;
  font-size: 13px;
}

.titulo {
  margin-top: 7%;
}
</style>
