<template>
  <div class="container">
    <b-modal no-close-on-backdrop centered ref="documento-modal" title="" hide-footer id="modalC" size="l">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(putDocumento)">
          <ModalDocumento :currentDocumento="currentDocumento" :optionsDocumentos="optionsDocumentos" @submit.prevent />
        </form>
      </ValidationObserver>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog" hide-footer id="modal-confirm-dialog">
      <h4 class="text-center">¿Seguro que quiere eliminar el documento?</h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="deleteDocumento">OK</b-button>
    </b-modal>

    <h4 class="cardD">Información de los Documentos a Notificar</h4>
    <!-- <p class="info">
      En esta sección deberás agregar los documentos que serán notificados a
      los destinatarios. Si alguno de los documentos será firmado mediante
      Firma Autógrafa Digital, selecciona la casilla correspondiente.
    </p> -->
    <div class="mt-4">
      <!-- <div class="col-12 mt-4"> -->
      <label for="btn-add-document">Agregar documento</label>
      <b-button id="btn-add-document" variant="success" class="btn-fill spaceL" size="sm" @click="addDocumento">+</b-button>
    </div>

    <div>
      <b-table ref="table" hover :fields="fieldsCuadro" :items="notificacion.documentos">
        <template #cell(acciones)="{ item }">
          <b-button variant="warning" size="sm" v-b-tooltip.hover title="Editar" class="editBtn"
            @click="editDocumento(item)">
            <b-icon-pencil />
          </b-button>
          <b-button variant="danger" size="sm" v-b-tooltip.hover title="Eliminar" @click="predeleteDocumento(item.id)">
            <b-icon-trash />
          </b-button>
        </template>
      </b-table>
    </div>

    <div class="row mt-1" v-if="notificacion.documentos.length > 0">
      <div class="col-12 d-flex">
        <b-form-checkbox class="orange" id="checkbox-fad" v-model="notificacion.fad">
          Alguno de los documentos será firmado mediante Firma Autógrafa Digital
        </b-form-checkbox>
      </div>

      <!-- <div class="col-12 mt-4">
        <p class="info">
          <i class="fa fa-info"></i>
          <a href="https://firmaautografa.com/es" target="_blank">Firma Autógrafa Digital</a> es un mecanismo de
          autenticación que
          permite acreditar la identidad de una persona que firma un documento
          electrónico, garantizando la integridad y autenticidad del mismo.
        </p>
      </div> -->
    </div>

    <div class="mt-4">
      <div>
        <b-button type="submit" class="btn-fill orange-btn bottom2" size="m">Continuar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import ModalDocumento from "@/views/clients/procesos/notificaciones/infoBase/modales/ModalDocumento.vue";
// API
import { CatDocumentosApi } from "@/api";

export default {
  name: "Documentos",

  components: { ModalDocumento },

  props: {
    notificacion: { type: Object },
  },

  computed: {
    fieldsCuadro() {
      let fieldsCuadro = [
        { key: "index", label: "", class: "text-center" },
        { key: "tipo", label: "Tipo", class: "text-center" },
        { key: "paginas", label: "No. Páginas", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" },
      ];
      return fieldsCuadro;
    },
  },

  created() {
    this.getCatDocumentosNotificaciones();
  },

  data() {
    return {
      selectedDocumento: null,
      currentDocumento: null,
      currentDocumentoId: null,

      optionsDocumentos: [],
      id_anexo: 0
    }
  },

  methods: {
    async getCatDocumentosNotificaciones() {
      this.loading = true;
      try {
        const { value } = await CatDocumentosApi.list({
          page: 0,
          registersPerPage: 0,
          categoria: "notificaciones"
        });

        value.forEach(element => {
          const obj = { text: element.documento, value: element.id };
          this.optionsDocumentos.push(obj);
        });
        this.getIdAnexo();
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
      this.loading = false;
    },

    getIdAnexo() {
      this.optionsDocumentos.forEach(element => {
        if (element.text === 'Anexo') {
          this.id_anexo = element.value;
        }
      })
    },

    addDocumento() {
      this.$refs["documento-modal"].show();
      this.currentDocumento = {};
    },

    putDocumento() {
      if (this.currentDocumento.descripcion) {
        this.currentDocumento.tipo = this.currentDocumento.descripcion;
      } else {
        this.optionsDocumentos.forEach(element => {
          if (element.value === this.currentDocumento.catDocumentos_id) {
            this.currentDocumento.tipo = element.text;
          }
        })
      }

      if (!this.currentDocumento.id) {
        const id = this.notificacion.documentos.length + 1;

        this.currentDocumento.id = id;
        this.notificacion.documentos.push(this.currentDocumento);
      }

      const index = this.notificacion.documentos.findIndex(o => o.id === this.currentDocumento.id);

      this.notificacion.documentos[index].tipo = this.currentDocumento.tipo;

      if (this.currentDocumento.tiene_anexo) {
        this.addAnexo(this.currentDocumento, index + 1);
      } else {
        if (this.notificacion.documentos[index + 1]) {
          if (this.notificacion.documentos[index + 1].anexoDe) {
            this.notificacion.documentos.splice(index + 1, 1);
          }
        }
      }

      this.$refs.table.refresh();
      this.$refs["documento-modal"].hide();
    },

    editDocumento(item) {
      let documentoPadre = item;
      if (item.anexoDe) {
        this.notificacion.documentos.forEach(documento => {
          if (documento.id == item.anexoDe) {
            documentoPadre = documento;
          }
        })
      }
      item = documentoPadre;
      this.$refs["documento-modal"].show();
      this.currentDocumento = item;
    },

    addAnexo(documento, index_to_slice) {
      if (this.notificacion.documentos[index_to_slice]) {
        if (this.notificacion.documentos[index_to_slice].anexoDe) {
          this.notificacion.documentos.splice(index_to_slice, 1);
        }
      }
      const currentDocumento = {
        catDocumentos_id: this.id_anexo,
        paginas: documento.paginas_anexo,
        tipo: "Anexo",
        anexoDe: documento.id
      };
      this.notificacion.documentos.splice(index_to_slice, 0, currentDocumento);
    },

    predeleteDocumento(id) {
      this.currentDocumentoId = id;
      this.$refs["modal-confirm-dialog"].show();
    },

    deleteDocumento() {
      let index = this.notificacion.documentos.findIndex(
        (o) => o.id === this.currentDocumentoId
      );
      if (this.notificacion.documentos[index].anexoDe) {
        this.notificacion.documentos.forEach(documento => {
          if (documento.id == this.notificacion.documentos[index].anexoDe) {
            documento.tiene_anexo = false;
          }
        })
      }
      if (this.notificacion.documentos[index + 1]) {
        if (this.notificacion.documentos[index + 1].anexoDe) {
          this.notificacion.documentos.splice(index + 1, 1);
        }
      }
      this.notificacion.documentos.splice(index, 1);

      this.$refs["modal-confirm-dialog"].hide();
      this.$refs.table.refresh();
    },
  }
}
</script>

<style scoped>
.spaceL {
  margin-left: 20px;
}

.bottom2 {
  float: right;
}

.control-label {
  margin-left: 30px;
  position: absolute;
  vertical-align: middle;
}

.custom-control-label {
  color: #ea640b !important;
}
</style>
