<template>
  <div>
    <div class="row" v-if="!currentCompareciente.existente">
      <div class="col-md-12">
        <ValidationProvider name="persona" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La persona es obligatoria',
        }">
          <label for="">Persona</label>
          <select class="custom-select" v-model="currentCompareciente.persona">
            <option v-for="option in optionsPersona" :key="option.value" :value="option.value">
              {{ option.text }}
            </option>
          </select>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row mt-4" v-if="currentCompareciente.persona === 'Moral' && !currentCompareciente.existente">
      <div class="col-md-12">
        <ValidationProvider name="denominacion_social" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La denominacion social es obligatoria',
        }">
          <base-input type="text" label="Denominación social (Incluir tipo de sociedad)"
            placeholder="Denominacion social" v-model="currentCompareciente.denominacion_social">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row mt-4" v-else-if="currentCompareciente.persona === 'Física' && !currentCompareciente.existente">
      <div class="col-md-4">
        <ValidationProvider name="nombre" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El nombre es obligatorio',
        }">
          <base-input type="text" label="Nombre" placeholder="Nombre" v-model="currentCompareciente.nombre">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <ValidationProvider name="nombre" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El paterno es obligatorio',
        }">
          <base-input type="text" label="Paterno" placeholder="Paterno" v-model="currentCompareciente.paterno">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <base-input type="text" label="Materno" placeholder="Materno" v-model="currentCompareciente.materno">
        </base-input>
      </div>
    </div>

    <div class="row mt-4" v-if="currentCompareciente.persona === 'Física' && vistaRatificantes">
      <div class="col-md-12">
        <ValidationProvider name="email" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El email es obligatorio',
        }">
          <base-input type="text" label="Correo electrónico" placeholder="correo@dominio.com"
            v-model="currentCompareciente.email" :disabled="currentCompareciente.existente">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row mt-4" v-if="currentCompareciente.persona === 'Física'">
      <div class="col-md-4">
        <ValidationProvider name="principal" v-slot="{ errors }">
          <label for="chkPrinciapl" class="control-label">Actúa por su propio derecho.</label>
          <b-form-checkbox id="chkPrinciapl" v-model="currentCompareciente.principal"
            @change="deshabilitaRepresentante">
          </b-form-checkbox>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4" v-if="representadas.length > 0 && currentCompareciente.persona === 'Física'">
        <ValidationProvider name="representante" v-slot="{ errors }">
          <label class="control-label">Representante</label>
          <b-form-checkbox v-model="representante" @change="deshabilitaPrincipal">
          </b-form-checkbox>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4" v-if="representante">
        <ValidationProvider name="representada" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La persona moral representada es obligatoria',
        }">
          <label for="">Persona representada</label>
          <select class="custom-select" v-model="currentCompareciente.representada"
            @change="agregaRepresentadanombre($event)">
            <option v-for="option in representadas" :key="option.id" :value="option.id">
              {{ option.nombreCompleto }}
            </option>
          </select>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row mt-4"></div>
    <div class="mt-4 text-center">
      <button type="submit" class="btn btn-fill float-right orange-btn">
        Guardar
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentCompareciente: { type: Object },
    ratificantes: { type: Array },
    vistaRatificantes: { type: Boolean }
  },

  created() {
    this.defineRepresentadas()
    this.checkRepresentante()
  },

  data() {
    return {
      optionsPersona: [
        { text: "Persona Física", value: "Física" },
        { text: "Persona Moral", value: "Moral" },
      ],
      representadas: [],
      representante: false,
    };
  },

  methods: {
    deshabilitaPrincipal() {
      if (this.representante) {
        this.currentCompareciente.principal = false;
      } else {
        this.currentCompareciente.representada = null;
        this.currentCompareciente.representadanombre = null;
      }
    },

    deshabilitaRepresentante() {
      if (this.currentCompareciente.principal) {
        this.representante = false;
        this.currentCompareciente.representada = null;
        this.currentCompareciente.representadanombre = null;
      }
    },

    defineRepresentadas() {
      this.ratificantes.forEach(ratificante => {
        const representantes = this.countRepresentantes(ratificante.id)
        let maxRrepresentantes = 1;
        if (ratificante.persona === 'Moral') {
          maxRrepresentantes = 2;
        }

        if ((!ratificante.principal &&
          !ratificante.representada &&
          representantes < maxRrepresentantes &&
          ratificante.id != this.currentCompareciente.id) ||
          this.currentCompareciente.representada == ratificante.id) {
          this.representadas.push(ratificante);
        }
      });
      this.checkNotSelf();
    },

    checkNotSelf() {
      let index = this.representadas.findIndex(
        (o) => o.id === this.currentCompareciente.id
      );
      if (index !== -1) {
        this.representadas.splice(index, 1);
      };
    },

    countRepresentantes(representadaId) {
      let representantes = 0;
      this.ratificantes.forEach(ratificante => {
        if (parseInt(ratificante.representada) == parseInt(representadaId)) {
          representantes += 1;
        }
      })
      return representantes;
    },

    checkRepresentante() {
      if (this.currentCompareciente.representada) {
        this.representante = true;
      }
    },

    agregaRepresentadanombre(event) {
      let index = this.ratificantes.findIndex(
        (o) => o.id == event.target.value
      );
      console.log(index)
      this.currentCompareciente.representadanombre = this.ratificantes[index].nombreCompleto;
    }
  }
}
</script>

<style scoped>
.cardErrors {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* color: #9a9a9a; */
  color: #ea640b;
  font-size: 13px;
}

.leyenda {
  text-transform: lowercase !important;
  color: #79cff7;
}

.leyenda:first-letter {
  text-transform: capitalize;
}
</style>
