<template>
  <div>
    <h4 class="cardD">Firma digital</h4>

    <EsperaValidacion></EsperaValidacion>

    <!-- <card class="blue mt-5">
      <i class="fa fa-info"></i>
      Validaremos la información y los documentos que has cargado de los
      comparecientes y te contacteremos por email en caso de ser necesario.
      <p></p>
      <i class="fa fa-envelope"></i>
      Si todo es correcto, te llegará un email con los documentos que firmarás
      digitalmente, además, del proyecto de la constitutiva que firmarás
      presencialmente. Después, te llegará un email de FAD - Firma Autógrafa
      Digital, donde se te indicarán los pasos para realizar la firma digital.
      <p></p>
      <i class="fa fa-pencil"></i>
      Si estás de acuerdo en el contenido de los documentos, te pedimos que los
      firmes, de lo contrario, te pedimos que te comuniques con nosotros.
    </card> -->

    <h4 class="">
      <i class="fa fa-info"></i>
      Validaremos la información y los documentos que has cargado de los
      comparecientes y te contacteremos por email en caso de ser necesario.
      <p></p>
      <br />
      <i class="fa fa-envelope"></i>
      Si todo es correcto, te llegará un email con los documentos que firmarás
      digitalmente, además, del proyecto de la constitutiva que firmarás
      presencialmente. Después, te llegará un email de FAD - Firma Autógrafa
      Digital, donde se te indicarán los pasos para realizar la firma digital.
      <p></p>
      <br />
      <i class="fa fa-pencil"></i>
      Si estás de acuerdo en el contenido de los documentos, te pedimos que los
      firmes, de lo contrario, te pedimos que te comuniques con nosotros.
    </h4>

    <b-modal no-close-on-backdrop centered ref="documento-modal-preview-img" title="" hide-footer id="modaldi"
      size="xl">
      <ModalPreviewImg :currentDocumento="currentDocumento" />
    </b-modal>
    <b-modal no-close-on-backdrop centered ref="documento-modal-preview-pdf" title="" hide-footer id="modaldf" size="xl">
      <ModalPreviewPDF :currentDocumento="currentDocumento" />
    </b-modal>
    <b-modal no-close-on-backdrop centered ref="documento-modal-preview-video" title="" hide-footer id="modalvideo" size="xl">
      <ModalPreviewVideo :currentDocumento="currentDocumento" />
    </b-modal>

    <h4 class="cardD mt-4">Documentos firmados</h4>
    <label for="">Se mostrarán en la tabla los archivos que firmen los accionistas</label>

    <b-table hover :fields="fields" :items="documentosFAD">
      <template #cell(index)="{ index }">
        {{ getIndex(index + 1) }}
      </template>
      <template #cell(acciones)="{ item }">
        <b-button variant="primary" size="sm" v-b-tooltip.hover title="Vista previa" @click="previewDocumento(item)"
          class="editBtn" :hidden="item.disabled">
          <b-icon-eye />
        </b-button>
        <!-- <b-button
          variant="success"
          size="sm"
          title="Descarga documento"
          @click="descargaFile(item.documento)"
        >
          <i class="fa fa-download"></i>
        </b-button> -->
      </template>
    </b-table>
  </div>
</template>

<script>
import EsperaValidacion from "@/components/EsperaValidacion.vue";
import ModalPreviewImg from "@/views/clients/procesos/constitucion/entregadocumentos/ModalPreviewImg.vue";
import ModalPreviewPDF from "@/views/clients/procesos/constitucion/entregadocumentos/ModalPreviewPDF.vue";
import ModalPreviewVideo from "@/views/clients/procesos/constitucion/entregadocumentos/ModalPreviewVideo.vue";

export default {
  name: "FirmaDigital",

  props: {
    constitutiva: { type: Object }
  },

  components: {
    // ModalDocumentos,
    ModalPreviewImg,
    ModalPreviewPDF,
    ModalPreviewVideo,
    EsperaValidacion
  },

  data() {
    return {
      currentDocumento: null,
      currentDocumentoId: null,
      documentosFAD: [],
      form: {
        documentos: []
      }
    };
  },

  created() {
    this.getDocsFAD();
  },

  computed: {
    fields() {
      let fields = [
        { key: "index", label: "", class: "text-center" },
        { key: "nombreFile", label: "Nombre", class: "text-center" },
        { key: "tipo", label: "Tipo", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" }
      ];
      return fields;
    }
  },

  methods: {
    getDocsFAD() {
      this.constitutiva.documentos.forEach(element => {
        if (element.cat_documentos_id == "38") {
          //tipo FAD
          this.documentosFAD.push(element);
        }
      });
      this.setDisabled();
    },

    getIndex(index) {
      // return index + (this.currentPage - 1) * this.perPage;
      return index + (1 - 1) * this.constitutiva.documentos.length;
    },

    setDisabled() {
      for (let i = 0; i < this.documentosFAD.length; i++) {
        let pretipoDocumento = this.documentosFAD[i].nombreFile.split(".");
        let tipoDocumento = pretipoDocumento[pretipoDocumento.length - 1];
        if (
          tipoDocumento == "docx" ||
          tipoDocumento == "pdf" ||
          tipoDocumento == "jpg" ||
          tipoDocumento == "JPG" ||
          tipoDocumento == "jpeg" ||
          tipoDocumento == "png" ||
          tipoDocumento == "gif" ||
          tipoDocumento == "bmp" ||
          tipoDocumento == "webp" ||
          tipoDocumento == "svg" ||
          tipoDocumento == "tiff" ||
          tipoDocumento == "avif" ||
          tipoDocumento == "mov" ||
          tipoDocumento == "mp4"
        ) {
          this.documentosFAD[i].disabled = false;
        } else {
          this.documentosFAD[i].disabled = true;
        }
      }
    },

    previewDocumento(item) {
      this.currentDocumento = item;

      let pretipoDocumento = item.nombreFile.split(".");
      let tipoDocumento = pretipoDocumento[pretipoDocumento.length - 1];

      if (tipoDocumento === "pdf") {
        this.$refs["documento-modal-preview-pdf"].show();
      }
      if (
        tipoDocumento === "jpg" ||
        tipoDocumento === "JPG" ||
        tipoDocumento === "jpeg" ||
        tipoDocumento === "png" ||
        tipoDocumento === "gif" ||
        tipoDocumento === "bmp" ||
        tipoDocumento === "webp" ||
        tipoDocumento === "svg" ||
        tipoDocumento === "tiff" ||
        tipoDocumento === "avif"
      ) {
        this.$refs["documento-modal-preview-img"].show();
      }

      if (tipoDocumento === "mov" || tipoDocumento === "mp4") {
        this.$refs["documento-modal-preview-video"].show();
      }
    }
  }
};
</script>

<style scoped>
.blue {
  background-color: #effbff;
  border-color: #effbff !important;
  text-align: justify;
}
</style>
