// API
import BaseApi from './base';

class CambiosConstitutivaApi extends BaseApi {
  constructor() {
    super('cambiosConstitucion');
  }
}

export default new CambiosConstitutivaApi();
