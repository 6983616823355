<template>
  <div class="container">
    <b-modal no-close-on-backdrop centered ref="documento-modal-preview" title="" hide-footer id="modaldf" size="xl">
      <ModalPreviewPDF :currentDocumento="currentDocumento" />
    </b-modal>
    <b-modal no-close-on-backdrop centered ref="modal-regenerate" hide-footer id="modal-regenerate">
      <h4 class="text-center">
        ¿Está seguro de regenerar la carta responsiva? La existente será eliminada.
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-regenerate')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="generaCartaResponsiva()">OK</b-button>
    </b-modal>
    <b-modal no-close-on-backdrop centered ref="modal-confirm-fad" hide-footer id="modal-confirm-fad">
      <h4 class="text-center">
        ¿Está seguro de enviar a FAD? El cliente será notificado.
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-fad')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="sendToFad()">OK</b-button>
    </b-modal>

    <h4 class="cardD">FAD</h4>

    <b-table ref="table" hover :fields="fieldsCuadro" :items="docsToShow">
      <template #cell(acciones)="{ item }">
        <b-button variant="primary" size="sm" v-b-tooltip.hover title="Vista previa" @click="openPreviewModal(item)"
          class="editBtn">
          <b-icon-eye />
        </b-button>
      </template>
    </b-table>

    <div class="mt-4">
      <div class="">
        <b-button class="btn-fill green-btn bottom1" size="m" @click="openModalRegenerate()"
          :disabled="[19, 20, 75, 76].includes(cotejo.id_estatus)">Regenerar Carta</b-button>
      </div>
    </div>
    <div class="mt-4">
      <div class="">
        <b-button class="btn-fill orange-btn bottom2" size="m" :disabled="[19, 20, 75, 76].includes(cotejo.id_estatus)"
          @click="openSendToFadModal()">
          Enviar a FAD
        </b-button>
      </div>
    </div>
    <div class="mt-4">
      <div class="">
        <b-button @click="$emit('checkFadStatus')" class="btn-fill orange-btn bottom2" size="m"
          :disabled="[19, 20, 75, 76].includes(cotejo.id_estatus)">Verificar firma</b-button>
      </div>
    </div>

    <div hidden>
      <CartaResponsivaTemplate :id="'CartaResponsiva'" :cotejo="cotejo" />
    </div>
  </div>
</template>

<script>
import ModalPreviewPDF from "@/views/clients/procesos/constitucion/entregadocumentos/ModalPreviewPDF.vue";
import CartaResponsivaTemplate from "@/views/clients/procesos/cotejo/CartaResponsivaTemplate.vue";

import { FuncionesApi } from "@/api";
import loadingMixin from "@/mixins/loadingMixin";

export default ({
  name: "FAD",

  components: {
    ModalPreviewPDF,
    CartaResponsivaTemplate
  },

  props: {
    cotejo: { type: Object }
  },

  mixins: [loadingMixin],

  computed: {
    fieldsCuadro() {
      let fieldsCuadro = [
        { key: "index", label: "", class: "text-center" },
        { key: "nombre", label: "Nombre", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" },
      ];
      return fieldsCuadro;
    }
  },

  async created() {
    this.toggleOnLoading();
    this.selectDocumentsToShow()
      .then(() => this.seleccionaFirmante())
      .catch((error) => {
        console.log(error);
        this.showErrorToast('No se pudieron obtener los documentos. Intente más tarde.')
      })
      .finally(this.toggleOffLoading);

    if ([19, 20, 74, 75, 76].includes(this.cotejo.id_estatus) && !this.docsToShow[0].documento) {
      this.$nextTick()
        .then(() => this.generaCartaResponsiva(true));
    }
  },

  data() {
    return {
      selectedDocumento: null,
      currentDocumento: null,
      currentDocumentiId: null,

      docsToShow: [],
      firmante: {},

      style_pdf: `<style>
        .new-page {
          page-break-before: always;
          }
        .tabla {
          width: 100%;
          font-size: 14px;
          font-family: Arial, Helvetica, sans-serif;
          border-collapse: separate;
          margin-top: 10px;
        }
        .section {
          text-align: center;
          height: 40px;
        }
        .date {
          text-align: right;
          height: 40px;
        }
        .paragraph {
          padding-bottom: 2em;
        }
        </style>`,

      cartaKey: 0
    }
  },

  methods: {
    showErrorToast(msg) {
      this.$toast.error(msg, {
        timeout: 4000, //false,
        hideProgressBar: false,
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        closeButton: "button",
        icon: true,
      });
    },

    openPreviewModal(item) {
      this.currentDocumento = item;
      this.$refs["documento-modal-preview"].show();
    },

    closePreviewModal() {
      this.$refs["documentos-modal-preview"].hide();
    },

    openModalRegenerate() {
      this.$refs['modal-regenerate'].show();
    },

    closeModalRegenerate() {
      this.$refs['modal-regenerate'].hide();
    },

    openSendToFadModal() {
      this.$refs["modal-confirm-fad"].show();
    },

    hideSendToFadModal() {
      this.$refs["modal-confirm-fad"].hide();
    },

    selectDocumentsToShow() {
      return new Promise(resolve => {
        this.docsToShow = this.cotejo.documentos.filter(doc => parseInt(doc.cat_documentos_id) == 75);
        resolve(this.docsToShow);
      })
    },


    seleccionaFirmante() {
      return new Promise((resolve, reject) => {
        try {
          this.firmante = this.cotejo.solicitantes.filter(sol => sol.persona === 'Física')[0];
          resolve(this.firmante)
        } catch (error) {
          console.log(error);
          reject(error);
        }
      })
    },

    async generaCartaResponsiva(isCreated = false) {
      try {
        this.toggleOnLoading();

        if (!isCreated) {
          this.closeModalRegenerate();
        }

        const html = document.getElementById('CartaResponsiva').innerHTML;
        const styled_html = this.style_pdf + html;
        const full_html = `<!DOCTYPE html>
            <html>
            <head>
              <meta charset="utf-8">
            </head>
            <body>
            ${styled_html}
            </body>
            </html>
            `;
        const buffer = Buffer.from(full_html);
        const buffer_str = buffer.toString("base64");
        const data = {
          proceso_id: this.cotejo.id,
          cat_servicios_id: 4,
          html: buffer_str
        };
        const docs = await FuncionesApi.generaKyc(data);

        // this.docsToShow = docs;

        this.$toast.success("Carta generada exitosamente.", {
          timeout: 3000,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true,
        });

      } catch (error) {
        this.$toast.error("Ocurrió un error al regenerar el documento.", {
          timeout: 4000,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true,
        });
      } finally {
        this.toggleOffLoading();
        location.reload();
      }
    },

    async sendToFad() {
      try {
        this.toggleOnLoading();
        this.hideSendToFadModal();

        const dataFad = {
          proceso_id: this.cotejo.id,
          cat_servicios_id: 4
        };

        await FuncionesApi.sendKycsFad(dataFad);

        this.$toast.success("Documentos enviados a firma.", {
          timeout: 3000,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });

        this.$emit('allChecksPassed');
      } catch (error) {
        console.log(error);
        this.$toast.error("Ocurrió un error al enviar los documentos a FAD.", {
          timeout: 4000,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
      } finally {
        this.toggleOffLoading();
      }
    },
  },
})
</script>
