<template>
  <div>
    <template v-if="user === 'Staff'">
      <div>
        <div>
          <!-- <div class="row">
            <div class="col-10">
              <b-button block class="btn-fill blue-btn title-text" @click="selected('DatosGenerales')">
                Entrega de información base
              </b-button>
            </div>
          </div> -->

          <div class="row">
            <div class="col-12 text-center mb-3">
              <label>
                Entrega de información base
              </label>
            </div>
          </div>

          <!-- <div class="row">
        <div class="col-1">
          <div :class="['circle', constitutiva.validoGenerales ? 'completado' : '']">
            <span class="number">
              <i v-if="constitutiva.validoGenerales" class="fa fa-check"></i>
              <i v-else class="fa fa-minus"></i>
            </span>
          </div>
        </div>
        <div class="col-10">
          <b-button block class="btn-fill blue-btn child" @click="selected('DatosGenerales')"
            :class="{ 'complete-text': constitutiva.validoGenerales }">
            Datos de la Sociedad
          </b-button>
        </div>
      </div> -->

          <div class="row flex align-items-center p-2 pointer justify-content-between">
            <div class="ml-3">
              <a :class="selectedComponent === 'DatosGenerales' ? 'active-menu' : 'text-menu'"
                @click="selected('DatosGenerales')">
                Datos de la Sociedad
              </a>
            </div>

            <div class="mr-3">
              <div :class="['circle', constitutiva.validoGenerales ? 'completado' : '']">
                <span class="number">
                  <i v-if="constitutiva.validoGenerales" class="fa fa-check"></i>
                  <i v-else class="fa fa-minus"></i></span>
              </div>
            </div>
          </div>

          <!-- <div class="row">
        <div class="col-1">
          <div :class="['circle', constitutiva.validoCapital ? 'completado' : '']">
            <span class="number"><i v-if="constitutiva.validoCapital" class="fa fa-check"></i><i v-else
                class="fa fa-minus"></i></span>
          </div>
        </div>
        <div class="col-10">
          <b-button block class="btn-fill blue-btn child" @click="selected('DatosCapital')"
            :class="{ 'complete-text': constitutiva.validoCapital }">
            Capital y Accionistas
          </b-button>
        </div>
      </div> -->

          <div class="row flex align-items-center p-2 pointer justify-content-between">
            <div class="ml-3">
              <a :class="selectedComponent === 'DatosCapital' ? 'active-menu' : 'text-menu'"
                @click="selected('DatosCapital')">
                Capital y Accionistas
              </a>
            </div>

            <div class="mr-3">
              <div :class="['circle', constitutiva.validoCapital ? 'completado' : '']">
                <span class="number">
                  <i v-if="constitutiva.validoCapital" class="fa fa-check"></i>
                  <i v-else class="fa fa-minus"></i>
                </span>
              </div>
            </div>
          </div>

          <!-- <div class="row">
        <div class="col-1">
          <div :class="['circle', constitutiva.validoOrganos ? 'completado' : '']">
            <span class="number"><i v-if="constitutiva.validoOrganos" class="fa fa-check"></i><i v-else
                class="fa fa-minus"></i></span>
          </div>
        </div>
        <div class="col-10">
          <b-button block class="btn-fill blue-btn" @click="selected('DatosOrganos')"
            :class="{ 'complete-text': constitutiva.validoOrganos }">
            Órganos Corporativos
          </b-button>
        </div>
      </div> -->

          <div class="row flex align-items-center p-2 pointer justify-content-between">
            <div class="ml-3">
              <a :class="selectedComponent === 'DatosOrganos' ? 'active-menu' : 'text-menu'"
                @click="selected('DatosOrganos')">
                Órganos Corporativos
              </a>
            </div>

            <div class="mr-3">
              <div :class="['circle', constitutiva.validoOrganos ? 'completado' : '']">
                <span class="number">
                  <i v-if="constitutiva.validoOrganos" class="fa fa-check"></i>
                  <i v-else class="fa fa-minus"></i>
                </span>
              </div>
            </div>
          </div>

          <!-- <div class="row">
        <div class="col-1">
          <div :class="[
            'circle',
            constitutiva.validoRepresentantes ? 'completado' : ''
          ]">
            <span class="number">
              <i v-if="constitutiva.validoRepresentantes" class="fa fa-check"></i>
              <i v-else class="fa fa-minus"></i>
            </span>
          </div>
        </div>
        <div class="col-10">
          <b-button block class="btn-fill blue-btn child" @click="selected('RepresentantesYApoderados')"
            :class="{ 'complete-text': constitutiva.validoRepresentantes }">
            Representantes
          </b-button>
        </div>
      </div> -->

          <div class="row flex align-items-center p-2 pointer justify-content-between">
            <div class="ml-3">
              <a :class="selectedComponent === 'RepresentantesYApoderados' ? 'active-menu' : 'text-menu'"
                @click="selected('RepresentantesYApoderados')">
                Representantes
              </a>
            </div>

            <div class="mr-3">
              <div :class="['circle', constitutiva.validoRepresentantes ? 'completado' : '']">
                <span class="number">
                  <i v-if="constitutiva.validoRepresentantes" class="fa fa-check"></i>
                  <i v-else class="fa fa-minus"></i></span>
              </div>
            </div>
          </div>

          <!-- <div class="row">
        <div class="col-1">
          <div :class="[
            'circle',
            constitutiva.validoCapitulos ? 'completado' : ''
          ]">
            <span class="number"><i v-if="constitutiva.validoCapitulos" class="fa fa-check"></i><i v-else
                class="fa fa-minus"></i></span>
          </div>
        </div>
        <div class="col-10">
          <b-button block class="btn-fill blue-btn child" @click="selected('CapitulosEspeciales')"
            :class="{ 'complete-text': constitutiva.validoCapitulos }">
            Capítulos especiales
          </b-button>
        </div>
      </div> -->

          <div class="row flex align-items-center p-2 pointer justify-content-between">
            <div class="ml-3">
              <a :class="selectedComponent === 'CapitulosEspeciales' ? 'active-menu' : 'text-menu'"
                @click="selected('CapitulosEspeciales')">
                Capítulos especiales
              </a>
            </div>

            <div class="mr-3">
              <div :class="['circle', constitutiva.validoCapitulos ? 'completado' : '']">
                <span class="number">
                  <i v-if="constitutiva.validoCapitulos" class="fa fa-check"></i>
                  <i v-else class="fa fa-minus"></i></span>
              </div>
            </div>
          </div>

          <!-- <div class="row">
        <div class="col-1">
          <div :class="['circle', constitutiva.validoEntidad ? 'completado' : '']">
            <span class="number"><i v-if="constitutiva.validoEntidad" class="fa fa-check"></i><i v-else
                class="fa fa-minus"></i></span>
          </div>
        </div>
        <div class="col-10">
          <b-button block class="btn-fill blue-btn child" @click="selected('EntidadFederativa')"
            :class="{ 'complete-text': constitutiva.validoEntidad }">
            Entidad Federativa firma
          </b-button>
        </div>
      </div> -->

          <div class="row flex align-items-center p-2 pointer justify-content-between">
            <div class="ml-3">
              <a :class="selectedComponent === 'EntidadFederativa' ? 'active-menu' : 'text-menu'"
                @click="selected('EntidadFederativa')">
                Entidad Federativa firma
              </a>
            </div>

            <div class="mr-3">
              <div :class="['circle', constitutiva.validoEntidad ? 'completado' : '']">
                <span class="number">
                  <i v-if="constitutiva.validoEntidad" class="fa fa-check"></i>
                  <i v-else class="fa fa-minus"></i></span>
              </div>
            </div>
          </div>

          <!-- <div class="row">
        <div class="col-1">
          <div :class="[
            'circle',
            constitutiva.validoCotizacion ? 'completado' : ''
          ]">
            <span class="number"><i v-if="constitutiva.validoCotizacion" class="fa fa-check"></i><i v-else
                class="fa fa-minus"></i></span>
          </div>
        </div>
        <div class="col-10">
          <b-button block class="btn-fill blue-btn" @click="selected('Cotizacion')"
            :class="{ 'complete-text': constitutiva.validoCotizacion }">
            Cotización y pago
          </b-button>
        </div>
      </div> -->

          <div class="row flex align-items-center p-2 pointer justify-content-between">
            <div class="ml-3">
              <a :class="selectedComponent === 'Cotizacion' ? 'active-menu' : 'text-menu'"
                @click="selected('Cotizacion')">
                Cotización y pago
              </a>
            </div>

            <div class="mr-3">
              <div :class="['circle', constitutiva.validoCotizacion ? 'completado' : '']">
                <span class="number">
                  <i v-if="constitutiva.validoCotizacion" class="fa fa-check"></i>
                  <i v-else class="fa fa-minus"></i></span>
              </div>
            </div>
          </div>

          <!-- <div class="row">
        <div class="col-1">
          <div :class="[
            'circle',
            constitutiva.validoComplementa ? 'completado' : ''
          ]">
            <span class="number"><i v-if="constitutiva.validoComplementa" class="fa fa-check"></i><i v-else
                class="fa fa-minus"></i></span>
          </div>
        </div>
        <div class="col-10">
          <b-button block class="btn-fill blue-btn title-text" @click="selected('ComplementaInformacion')">
            Complementa la información</b-button>
        </div>
      </div> -->

          <div class="row flex align-items-center p-2 pointer justify-content-between">
            <div class="ml-3">
              <a :class="selectedComponent === 'ComplementaInformacion' ? 'active-menu' : 'text-menu'"
                @click="selected('ComplementaInformacion')">
                Complementa la información
              </a>
            </div>

            <div class="mr-3">
              <div :class="['circle', constitutiva.validoComplementa ? 'completado' : '']">
                <span class="number">
                  <i v-if="constitutiva.validoComplementa" class="fa fa-check"></i>
                  <i v-else class="fa fa-minus"></i></span>
              </div>
            </div>
          </div>

          <!-- <div class="row">
            <div class="col-10">
              <b-button block class="btn-fill blue-btn title-text" @click="selected('AsignarCorreduria')">
                Asignar correduría
              </b-button>
            </div>
          </div> -->

          <div class="row flex align-items-center p-2 pointer justify-content-between">
            <div class="ml-3">
              <a :class="selectedComponent === 'AsignarCorreduria' ? 'active-menu' : 'text-menu'"
                @click="selected('AsignarCorreduria')">
                Asignar correduría
              </a>
            </div>
          </div>

          <!-- <div class="row">
            <div class="col-10">
              <b-button block class="btn-fill blue-btn title-text" @click="selected('DenominacionSocial')">
                Denominación social
              </b-button>
            </div>
          </div> -->

          <div class="row flex align-items-center p-2 pointer justify-content-between">
            <div class="ml-3">
              <a :class="selectedComponent === 'DenominacionSocial' ? 'active-menu' : 'text-menu'"
                @click="selected('DenominacionSocial')">
                Denominación social
              </a>
            </div>
          </div>

          <!-- <div class="row">
            <div class="col-10">
              <b-button block class="btn-fill blue-btn title-text" @click="selected('Expediente')">Expediente
              </b-button>
            </div>
          </div> -->

          <div class="row flex align-items-center p-2 pointer justify-content-between">
            <div class="ml-3">
              <a :class="selectedComponent === 'Expediente' ? 'active-menu' : 'text-menu'"
                @click="selected('Expediente')">
                Expediente
              </a>
            </div>
          </div>

          <!-- <div class="row"> -->
          <!-- <div class="col-1">
          <div
            :class="['circle', constitutiva.validoGenera ? 'completado' : '']"
          >
            <span class="number"
              ><i v-if="constitutiva.validoGenera" class="fa fa-check"></i
              ><i v-else class="fa fa-minus"></i
            ></span>
          </div>
        </div> -->
          <!-- <div class="col-10">
              <b-button block class="btn-fill blue-btn" @click="selected('GeneraDocumento')"
                :class="{ 'complete-text': constitutiva.validoGenera }">
                Genera documentos
              </b-button>
            </div>
          </div> -->

          <div class="row flex align-items-center p-2 pointer justify-content-between">
            <div class="ml-3">
              <a :class="selectedComponent === 'GeneraDocumento' ? 'active-menu' : 'text-menu'"
                @click="selected('GeneraDocumento')">
                Genera documentos
              </a>
            </div>
          </div>

          <!-- <div class="row"> -->
          <!-- <div class="col-1">
          <div
            :class="['circle', constitutiva.validoDocListo ? 'completado' : '']"
          >
            <span class="number"
              ><i v-if="constitutiva.validoDocListo" class="fa fa-check"></i
              ><i v-else class="fa fa-minus"></i
            ></span>
          </div>
        </div> -->
          <!-- <div class="col-10">
              <b-button block class="btn-fill blue-btn" @click="selected('DocFirmado')"
                :class="{ 'complete-text': constitutiva.validoDocListo }">
                Documento firmado
              </b-button>
            </div>
          </div> -->

          <div class="row flex align-items-center p-2 pointer justify-content-between">
            <div class="ml-3">
              <a :class="selectedComponent === 'DocFirmado' ? 'active-menu' : 'text-menu'"
                @click="selected('DocFirmado')">
                Documento firmado
              </a>
            </div>
          </div>

          <!-- <div class="row"> -->
          <!-- <div class="col-1">
          <div :class="['circle', constitutiva.validoCita ? 'completado' : '']">
            <span class="number"
              ><i v-if="constitutiva.validoCita" class="fa fa-check"></i
              ><i v-else class="fa fa-minus"></i
            ></span>
          </div>
        </div> -->
          <!-- <div class="col-10">
              <b-button block class="btn-fill blue-btn" @click="selected('CitaFirma')"
                :class="{ 'complete-text': constitutiva.validoCita }">
                Agenda tu cita
              </b-button>
            </div>
          </div> -->

          <div class="row flex align-items-center p-2 pointer justify-content-between">
            <div class="ml-3">
              <a :class="selectedComponent === 'CitaFirma' ? 'active-menu' : 'text-menu'"
                @click="selected('CitaFirma')">
                Agenda tu cita
              </a>
            </div>
          </div>

          <!-- <div class="row">
        <div class="col-1">
          <div :class="['circle', constitutiva.validoCita ? 'completado' : '']">
            <span class="number"><i v-if="constitutiva.validoCita" class="fa fa-check"></i><i v-else
                class="fa fa-minus"></i></span>
          </div>
        </div>
        <div class="col-10">
          <b-button block class="btn-fill blue-btn" @click="selected('Terminado')"
            :class="{ 'complete-text': constitutiva.validoCita }">
            Terminado
          </b-button>
        </div>
      </div> -->

          <div class="row flex align-items-center p-2 pointer justify-content-between">
            <div class="ml-3">
              <a :class="selectedComponent === 'Terminado' ? 'active-menu' : 'text-menu'"
                @click="selected('Terminado')">
                Terminado
              </a>
            </div>

            <div class="mr-3">
              <div :class="['circle', constitutiva.validoCita ? 'completado' : '']">
                <span class="number">
                  <i v-if="constitutiva.validoCita" class="fa fa-check"></i>
                  <i v-else class="fa fa-minus"></i></span>
              </div>
            </div>
          </div>

        </div>
      </div>
    </template>

    <template v-else-if="user === 'Client'">
      <div>
        <div>


          <!-- <div class="row">
        <div class="col-12">
          <b-button block class="btn-fill blue-btn title-text" @click="selected('DatosGenerales')">
            Entrega de información base
          </b-button>
        </div>
      </div> -->

          <div class="row">
            <div class="col-12 text-center mb-3">
              <label>
                Entrega de información base
              </label>
            </div>
          </div>

          <!-- <div class="row">
        <div class="col-1">
          <div :class="[
            'circle',
            constitutiva.validoGenerales ? 'completado' : ''
          ]">
            <span class="number"><i v-if="constitutiva.validoGenerales" class="fa fa-check"></i><i v-else
                class="fa fa-minus"></i></span>
          </div>
        </div>
        <div class="col-10">
          <b-button block class="btn-fill blue-btn child" @click="selected('DatosGenerales')">
            Datos de la Sociedad
          </b-button>
        </div>
      </div> -->

          <div class="row flex align-items-center p-2 pointer justify-content-between">
            <div class="ml-3">
              <a :class="selectedComponent === 'DatosGenerales' ? 'active-menu' : 'text-menu'"
                @click="selected('DatosGenerales')">
                Datos de la Sociedad
              </a>
            </div>

            <div class="mr-3">
              <div :class="['circle', constitutiva.validoGenerales ? 'completado' : '']">
                <span class="number">
                  <i v-if="constitutiva.validoGenerales" class="fa fa-check"></i>
                  <i v-else class="fa fa-minus"></i></span>
              </div>
            </div>
          </div>

          <!-- <div class="row">
        <div class="col-1">
          <div :class="['circle', constitutiva.validoCapital ? 'completado' : '']">
            <span class="number"><i v-if="constitutiva.validoCapital" class="fa fa-check"></i><i v-else
                class="fa fa-minus"></i></span>
          </div>
        </div>

        <div class="col-10">
          <b-button block class="btn-fill blue-btn child" @click="selected('DatosCapital')">
            Capital y Accionistas
          </b-button>
        </div>
      </div> -->

          <div class="row flex align-items-center p-2 pointer justify-content-between">
            <div class="ml-3">
              <a :class="selectedComponent === 'DatosCapital' ? 'active-menu' : 'text-menu'"
                @click="selected('DatosCapital')">
                Capital y Accionistas
              </a>
            </div>

            <div class="mr-3">
              <div :class="['circle', constitutiva.validoCapital ? 'completado' : '']">
                <span class="number">
                  <i v-if="constitutiva.validoCapital" class="fa fa-check"></i>
                  <i v-else class="fa fa-minus"></i></span>
              </div>
            </div>
          </div>

          <!-- <div class="row">
        <div class="col-1">
          <div :class="['circle', constitutiva.validoOrganos ? 'completado' : '']">
            <span class="number"><i v-if="constitutiva.validoOrganos" class="fa fa-check"></i><i v-else
                class="fa fa-minus"></i></span>
          </div>
        </div>

        <div class="col-10">
          <b-button block class="btn-fill blue-btn child" @click="selected('DatosOrganos')">
            Órganos Corporativos
          </b-button>
        </div>
      </div> -->

          <div class="row flex align-items-center p-2 pointer justify-content-between">
            <div class="ml-3">
              <a :class="selectedComponent === 'DatosOrganos' ? 'active-menu' : 'text-menu'"
                @click="selected('DatosOrganos')">
                Órganos Corporativos
              </a>
            </div>

            <div class="mr-3">
              <div :class="['circle', constitutiva.validoOrganos ? 'completado' : '']">
                <span class="number">
                  <i v-if="constitutiva.validoOrganos" class="fa fa-check"></i>
                  <i v-else class="fa fa-minus"></i></span>
              </div>
            </div>
          </div>

          <!-- <div class="row">
        <div class="col-1">
          <div :class="[
            'circle',
            constitutiva.validoRepresentantes ? 'completado' : ''
          ]">
            <span class="number"><i v-if="constitutiva.validoRepresentantes" class="fa fa-check"></i><i v-else
                class="fa fa-minus"></i></span>
          </div>
        </div>

        <div class="col-10">
          <b-button block class="btn-fill blue-btn child" @click="selected('RepresentantesYApoderados')">
            Representantes
          </b-button>
        </div>
      </div> -->

          <div class="row flex align-items-center p-2 pointer justify-content-between">
            <div class="ml-3">
              <a :class="selectedComponent === 'RepresentantesYApoderados' ? 'active-menu' : 'text-menu'"
                @click="selected('RepresentantesYApoderados')">
                Representantes
              </a>
            </div>

            <div class="mr-3">
              <div :class="['circle', constitutiva.validoRepresentantes ? 'completado' : '']">
                <span class="number">
                  <i v-if="constitutiva.validoRepresentantes" class="fa fa-check"></i>
                  <i v-else class="fa fa-minus"></i></span>
              </div>
            </div>
          </div>

          <!-- <div class="row">
        <div class="col-1">
          <div :class="[
            'circle',
            constitutiva.validoCapitulos ? 'completado' : ''
          ]">
            <span class="number"><i v-if="constitutiva.validoCapitulos" class="fa fa-check"></i><i v-else
                class="fa fa-minus"></i></span>
          </div>
        </div>

        <div class="col-10">
          <b-button block class="btn-fill blue-btn child" @click="selected('CapitulosEspeciales')">
            Capítulos especiales
          </b-button>
        </div>
      </div> -->

          <div class="row flex align-items-center p-2 pointer justify-content-between">
            <div class="ml-3">
              <a :class="selectedComponent === 'CapitulosEspeciales' ? 'active-menu' : 'text-menu'"
                @click="selected('CapitulosEspeciales')">
                Capítulos especiales
              </a>
            </div>

            <div class="mr-3">
              <div :class="['circle', constitutiva.validoCapitulos ? 'completado' : '']">
                <span class="number">
                  <i v-if="constitutiva.validoCapitulos" class="fa fa-check"></i>
                  <i v-else class="fa fa-minus"></i></span>
              </div>
            </div>
          </div>

          <!-- <div class="row">
        <div class="col-1">
          <div :class="['circle', constitutiva.validoEntidad ? 'completado' : '']">
            <span class="number"><i v-if="constitutiva.validoEntidad" class="fa fa-check"></i><i v-else
                class="fa fa-minus"></i></span>
          </div>
        </div>

        <div class="col-10">
          <b-button block class="btn-fill blue-btn child" @click="selected('EntidadFederativa')">
            Entidad Federativa firma
          </b-button>
        </div>
      </div> -->

          <div class="row flex align-items-center p-2 pointer justify-content-between">
            <div class="ml-3">
              <a :class="selectedComponent === 'EntidadFederativa' ? 'active-menu' : 'text-menu'"
                @click="selected('EntidadFederativa')">
                Entidad Federativa firma
              </a>
            </div>

            <div class="mr-3">
              <div :class="['circle', constitutiva.validoEntidad ? 'completado' : '']">
                <span class="number">
                  <i v-if="constitutiva.validoEntidad" class="fa fa-check"></i>
                  <i v-else class="fa fa-minus"></i></span>
              </div>
            </div>
          </div>

          <!-- <div class="row">
        <div class="col-1">
          <div :class="[
            'circle',
            constitutiva.validoCotizacion ? 'completado' : ''
          ]">
            <span class="number"><i v-if="constitutiva.validoCotizacion" class="fa fa-check"></i><i v-else
                class="fa fa-minus"></i></span>
          </div>
        </div>
        <div class="col-10">
          <b-button block class="btn-fill blue-btn child" @click="selected('Cotizacion')">
            Cotización y pago
          </b-button>
        </div>
      </div> -->

          <div class="row flex align-items-center p-2 pointer justify-content-between">
            <div class="ml-3">
              <a :class="selectedComponent === 'Cotizacion' ? 'active-menu' : 'text-menu'"
                @click="selected('Cotizacion')">
                Cotización y pago
              </a>
            </div>

            <div class="mr-3">
              <div :class="['circle', constitutiva.validoCotizacion ? 'completado' : '']">
                <span class="number">
                  <i v-if="constitutiva.validoCotizacion" class="fa fa-check"></i>
                  <i v-else class="fa fa-minus"></i></span>
              </div>
            </div>
          </div>

          <!-- <div class="row">
        <div class="col-1">
          <div :class="[
            'circle',
            constitutiva.validoComplementa ? 'completado' : ''
          ]">
            <span class="number"><i v-if="constitutiva.validoComplementa" class="fa fa-check"></i><i v-else
                class="fa fa-minus"></i></span>
          </div>
        </div>
        <div class="col-10">
          <b-button block class="btn-fill blue-btn" @click="selected('ComplementaInformacion')">
            Complementa la información
          </b-button>
        </div>
      </div> -->

          <div class="row flex align-items-center p-2 pointer justify-content-between">
            <div class="ml-3">
              <a :class="selectedComponent === 'ComplementaInformacion' ? 'active-menu' : 'text-menu'"
                @click="selected('ComplementaInformacion')">
                Complementa la información
              </a>
            </div>

            <div class="mr-3">
              <div :class="['circle', constitutiva.validoComplementa ? 'completado' : '']">
                <span class="number">
                  <i v-if="constitutiva.validoComplementa" class="fa fa-check"></i>
                  <i v-else class="fa fa-minus"></i></span>
              </div>
            </div>
          </div>

          <!-- <div class="row">
        <div class="col-1">
          <div :class="['circle', constitutiva.validoCita ? 'completado' : '']">
            <span class="number"><i v-if="constitutiva.validoCita" class="fa fa-check"></i><i v-else
                class="fa fa-minus"></i></span>
          </div>
        </div>

        <div class="col-10">
          <b-button block class="btn-fill blue-btn" @click="selected('FirmaDigital')">
            Firma digital
          </b-button>
        </div>
      </div> -->

          <div class="row flex align-items-center p-2 pointer justify-content-between">
            <div class="ml-3">
              <a :class="selectedComponent === 'FirmaDigital' ? 'active-menu' : 'text-menu'"
                @click="selected('FirmaDigital')">
                Firma digital
              </a>
            </div>

            <div class="mr-3">
              <div :class="['circle', constitutiva.validoCita ? 'completado' : '']">
                <span class="number">
                  <i v-if="constitutiva.validoCita" class="fa fa-check"></i>
                  <i v-else class="fa fa-minus"></i></span>
              </div>
            </div>
          </div>

          <!-- <div class="row">
        <div class="col-1">
          <div :class="['circle', constitutiva.validoCita ? 'completado' : '']">
            <span class="number"><i v-if="constitutiva.validoCita" class="fa fa-check"></i><i v-else
                class="fa fa-minus"></i></span>
          </div>
        </div>

        <div class="col-10">
          <b-button block class="btn-fill blue-btn" @click="selected('CitaFirma')">
            Agenda tu cita
          </b-button>
        </div>
      </div> -->

          <div class="row flex align-items-center p-2 pointer justify-content-between">
            <div class="ml-3">
              <a :class="selectedComponent === 'CitaFirma' ? 'active-menu' : 'text-menu'"
                @click="selected('CitaFirma')">
                Agenda tu cita
              </a>
            </div>

            <div class="mr-3">
              <div :class="['circle', constitutiva.validoCita ? 'completado' : '']">
                <span class="number">
                  <i v-if="constitutiva.validoCita" class="fa fa-check"></i>
                  <i v-else class="fa fa-minus"></i></span>
              </div>
            </div>
          </div>

          <!-- <div class="row">
        <div class="col-10">
          <b-button
            block
            class="btn-fill blue-btn"
            @click="selected('DocListo')"
          >
            Documento listo para entrega
          </b-button>
        </div>
        <div class="col-1">
          <div v-if="constitutiva.validoDocListo" class="led-green"></div>
          <div v-else class="led-yellow"></div>
        </div>
      </div> -->


        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "TreeM",

  props: {
    constitutiva: { type: Object },
    user: { type: String },
    selectedComponent: { type: String }
  },

  methods: {
    // validaEstatusForms() {
    //   for (let i = 0; i < this.constitutiva.formularios.length; i++) {
    //     if (this.constitutiva.formularios[i].valido === 1) {
    //       switch (this.constitutiva.formularios[i].formulario) {
    //         case "DatosGenerales":
    //           this.validoGenerales = true;
    //           break;
    //         case 2:
    //           this.validoCapital = true;
    //           break;
    //         case 3:
    //           this.validoOrganos = true;
    //           break;
    //         case 4:
    //           this.validoRepresentantes = true;
    //           break;
    //         case 5:
    //           this.validoCapitulos = true;
    //           break;
    //         case 6:
    //           this.validoEntidad = true;
    //           break;
    //         case 7:
    //           this.validoCotizacion = true;
    //           break;
    //         case 8:
    //           this.validoComplementa = true;
    //           break;
    //         case 9:
    //           this.validoDenominacion = true;
    //           break;
    //         case 10:
    //           this.validoExpediente = true;
    //           break;
    //         case 11:
    //           this.validoGenera = true;
    //           break;
    //         case 12:
    //           this.validoCita = true;
    //           break;
    //       }
    //     }
    //   }
    // },

    selected(node) {
      this.$emit("node", node);
    }
  }
};
</script>

<style scoped>
.invi {
  display: none !important;
}

.completado {
  background-color: yellowgreen !important;
}

/* .circle {
  height: 20px;
  width: 20px;
  margin-top: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: silver;
} */

.icon-title {
  height: 20px;
  width: 20px;
  margin-top: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.icon-title-fa {
  font-size: 11px;
  color: black;
}

.number {
  font-size: 11px;
  color: white;
}

.title-text {
  font-weight: 600;
  color: black !important;
}

.complete-text {
  color: black !important;
}

.blue-btn {
  padding-left: 0px;
  padding-right: 0px;
  background-color: transparent !important;
  border-color: transparent !important;
  color: rgb(154, 154, 154);
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: -10px;
  text-align: left !important;
}

.blue-btn:hover {
  color: rgb(154, 154, 154);
  background-color: transparent !important;
  border-color: transparent !important;
  background-color: #effbff !important;
  border-color: #effbff !important;
}

.blue-btn:active {
  background-color: #effbff !important;
  border-color: #effbff !important;
  /* color: blue !important; */
}

.led-green {
  margin: 10px auto;
  width: 10px;
  height: 10px;
  background-color: #43eb34;
  border-radius: 50%;
  box-shadow: #257a1d 0 -1px 7px 1px, inset #460 0 -1px 9px, #7d0 0 2px 12px;
}

.led-red {
  margin: 10px auto;
  width: 10px;
  height: 10px;
  background-color: #e60505;
  border-radius: 50%;
  box-shadow: #a60903 0 -1px 7px 1px, inset #600 0 -1px 9px, #f00 0 2px 12px;
}

.led-yellow {
  margin: 10px auto;
  width: 10px;
  height: 10px;
  background-color: #f7e00a;
  border-radius: 50%;
  box-shadow: #d1ca04 0 -1px 7px 1px, inset #c4b206 0 -1px 9px, #dd0 0 2px 12px;
}
</style>
