import { render, staticRenderFns } from "./Notificaciones.vue?vue&type=template&id=08a78eca&scoped=true"
import script from "./Notificaciones.vue?vue&type=script&lang=js"
export * from "./Notificaciones.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08a78eca",
  null
  
)

export default component.exports