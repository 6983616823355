<template>
  <div class="container">
    <div v-if="loading">
      <div id="loader">
        <font-awesome-icon icon="spinner" spin />
      </div>
    </div>

    <h4 class="cardD">
      Entidad Federativa en la que será firmada la Constitutiva
    </h4>

    <div class="row">
      <div class="col-md-6">
        <label>Entidad Federativa</label>
        <select :disabled="constitutiva.disabled" class="custom-select" v-model="constitutiva.selectedEntidad"
          @change="getMunicipios">
          <option v-for="option in constitutiva.optionsEntidades" :key="option.value">
            {{ option.text }}
          </option>
        </select>
      </div>

      <div class="col-md-6" v-if="hay_municipios">
        <label>Municipio</label>
        <select :disabled="constitutiva.disabled" class="custom-select" v-model="constitutiva.selectedMunicipio"
          @change="selectCorreduria($event)">
          <option v-for="option in constitutiva.optionsMunicipios" :key="option.value">
            {{ option.text }}
          </option>
        </select>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-8">
        <label for="">La firma será en la oficina del Corredor </label>
        <b-form-checkbox v-model="constitutiva.firma_oficina" :disabled="constitutiva.disabled">
        </b-form-checkbox>
        <label class="mb-12 leyenda" v-if="constitutiva.firma_oficina === false">
          Aplicará un costo extra para gastos de movilidad en caso de recabar
          firmas.
        </label>
      </div>
    </div>

    <div class="mt-4">
      <div class="">
        <b-button type="submit" class="btn-fill orange-btn bottom2" size="m"
          :disabled="constitutiva.disabled">Continuar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EntidadFederativa",

  props: {
    catCorredurias: { type: Array },
    constitutiva: { type: Object }
  },

  data() {
    return {
      loading: false,
      hay_municipios: true
    };
  },

  created() {
    // this.constitutiva.disabled = false;

    if (this.constitutiva.selectedEntidad != null) {
      this.getMunicipios();
    }
  },

  methods: {
    async getMunicipios() {
      this.constitutiva.optionsMunicipios = [];
      try {
        if (this.constitutiva.selectedEntidad == "Ciudad de México") {
          //no trae municipios y se oculta
          this.hay_municipios = false;
          this.constitutiva.selectedMunicipio = "";
        } else {
          this.hay_municipios = true;
          this.catCorredurias.forEach(element => {
            if (element.entidad === this.constitutiva.selectedEntidad) {
              const obj = {
                text: element.delegacion_municipio,
                value: element.corredurias_id
              };
              this.constitutiva.optionsMunicipios.push(obj);
            }
          });
        }
      } catch (error) { }
    }

    // async selectCorreduria(event) {
    //   //aqui solo se guardan los nuevos campos consti: entidad y municipio
    //   // this.constitutiva.corredurias_id = event.target.value;
    // }
  }
};
</script>

<style scoped>
.bottom2 {
  /* position: fixed;
  right: 2%;
  bottom: 5%;
  overflow: visible;
  z-index: 100; */
  float: right;
}

.leyenda {
  text-transform: lowercase !important;
  color: #79cff7;
}

.leyenda:first-letter {
  text-transform: capitalize;
}
</style>
