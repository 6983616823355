<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <ValidationProvider name="persona" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La persona es obligatoria',
        }">
          <label for="">Persona</label>
          <select class="custom-select" v-model="currentCompareciente.persona" disabled>
            <option v-for="option in optionsPersona" :key="option.value" :value="option.value">
              {{ option.text }}
            </option>
          </select>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row mt-4" v-if="currentCompareciente.persona === 'Moral'">
      <div class="col-md-12">
        <ValidationProvider name="denominacion_social" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La denominacion social es obligatoria',
        }">
          <base-input type="text" label="Denominación social (Incluir tipo de sociedad)"
            placeholder="Denominacion social" v-model="currentCompareciente.denominacion_social"
            :disabled="cotejo.validaSolicitantes == 1">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row mt-4" v-else-if="currentCompareciente.persona === 'Física'">
      <div class="col-md-4">
        <ValidationProvider name="nombre" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El nombre es obligatorio',
        }">
          <base-input type="text" label="Nombre" placeholder="Nombre" v-model="currentCompareciente.nombre"
            :disabled="cotejo.validaSolicitantes == 1">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <ValidationProvider name="nombre" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El paterno es obligatorio',
        }">
          <base-input type="text" label="Paterno" placeholder="Paterno" v-model="currentCompareciente.paterno"
            :disabled="cotejo.validaSolicitantes == 1">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <base-input type="text" label="Materno" placeholder="Materno" v-model="currentCompareciente.materno"
          :disabled="cotejo.validaSolicitantes == 1">
        </base-input>
      </div>
    </div>

    <div class="row mt-4" v-if="currentCompareciente.persona === 'Física'">
      <div class="col-md-12">
        <ValidationProvider name="email" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El email es obligatorio',
        }">
          <base-input type="text" label="Correo electrónico" placeholder="correo@dominio.com"
            v-model="currentCompareciente.email" :disabled="cotejo.validaSolicitantes == 1">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <b-modal no-close-on-backdrop centered ref="telefono-modal" title="" hide-footer id="modalTR" size="lg">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(editTelefono())">
          <ModalTelefono :currentTelefono="currentCompareciente.telefono" @submit.prevent />
        </form>
      </ValidationObserver>
    </b-modal>

    <h4 class="cardD" v-if="currentCompareciente.persona === 'Física'">
      Teléfonos
      <b-button variant="success" class="btn-fill" size="sm" @click="addTelefono()">+</b-button>
    </h4>

    <div class="row mt-4"></div>
    <div class="mt-4 text-center">
      <button type="submit" class="btn btn-fill float-right orange-btn" :disabled="cotejo.validaSolicitantes == 1">
        Guardar
      </button>
    </div>
  </div>
</template>

<script>
import ModalTelefono from "@/views/clients/comparecientes/ModalTelefono.vue";
export default {
  name: "ModalValidaCompareciente",
  props: {
    currentCompareciente: { type: Object },
    cotejo: { type: Object }
  },

  components: {
    ModalTelefono,
  },

  data() {
    return {
      optionsPersona: [
        { text: "Persona Física", value: "Física" },
        { text: "Persona Moral", value: "Moral" },
      ]
    };
  },

  methods: {
    addTelefono() {
      this.$refs["telefono-modal"].show();
      if (!(this.currentCompareciente.hasOwnProperty('telefono'))) {
        this.currentCompareciente.telefono = this.currentCompareciente.telefonos.sort((a, b) => {
          const dateA = new Date(a.actualizado);
          const dateB = new Date(b.actualizado);
          return dateB - dateA;
        })[0];
      }
    },

    editTelefono() {
      this.$refs["telefono-modal"].hide();
    },
  }
}
</script>

<style scoped>
.cardErrors {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* color: #9a9a9a; */
  color: #ea640b;
  font-size: 13px;
}

.leyenda {
  text-transform: lowercase !important;
  color: #79cff7;
}

.leyenda:first-letter {
  text-transform: capitalize;
}
</style>
