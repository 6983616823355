// API
import BaseApi from './base';

class ConstitucionSociedadesApi extends BaseApi {
  constructor() {
    super('constitucionSociedades');
  }
}

export default new ConstitucionSociedadesApi();
