<template>
  <div class="container">
    <h4 class="cardD">Representantes orgánicos y apoderados</h4>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog" hide-footer id="modal-confirm-dialog">
      <h4 class="text-center">
        ¿Seguro que quiere eliminar al representante o apoderado?
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="deleteApoderado">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="apoderado-modal" title="" hide-footer id="modalC" size="xl">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(putApoderado)">
          <ModalApoderados :currentApoderado="currentApoderado" @submit.prevent :options_facultades="options_facultades"
            :complete="false" />
        </form>
      </ValidationObserver>
    </b-modal>

    <div class="row mt-5">
      <div class="col-12">
        <label for="">Apoderados</label>
        <b-button v-if="constitutiva.roleStaff" :disabled="constitutiva.disabledRepresentantes" variant="success"
          class="btn-fill spaceL" size="sm" @click="addApoderado">
          +
        </b-button>

        <b-table hover :fields="fieldsCuadro" :items="constitutiva.apoderados">
          <template #cell(acciones)="{ item }">
            <b-button :disabled="constitutiva.disabledRepresentantes" variant="warning" size="sm" v-b-tooltip.hover
              title="Editar" class="editBtn" @click="editApoderado(item)">
              <b-icon-pencil />
            </b-button>
            <b-button v-if="constitutiva.roleStaff" :disabled="constitutiva.disabledRepresentantes" variant="danger"
              size="sm" v-b-tooltip.hover title="Eliminar" @click="predeleteApoderado(item.id)">
              <b-icon-trash />
            </b-button>
          </template>
        </b-table>

        <label class="mt-2 leyenda" v-if="constitutiva.apoderados.length > 1">
          La cotización básica incluye solo apoderado.
        </label>
      </div>
    </div>

    <div class="mt-4">
      <label>Dueño beneficiario</label>

      <label class="beneficiario">
        Conforme a lo establecido en la Ley Federal para la Prevención e
        Identificación de Operaciones con Recursos de Procedencia Ilícita, su
        Reglamento y Reglas de Carácter General, manifiesto, bajo protesta de
        decir verdad, que:
      </label>
      <div class="row mt-2">
        <div class="col-1">
          <b-form-radio v-model="constitutiva.beneficiario_pld" name="pld" value="1" @change="selectBeneficiarioPLD(1)">
          </b-form-radio>
        </div>
        <div class="col-md-11">
          <b>NO</b> tengo conocimiento del dueño beneficiario o beneficiario
          controlador, pues actúo por mi propio derecho.
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-1">
          <b-form-radio v-model="constitutiva.beneficiario_pld" name="pld" value="2" @change="selectBeneficiarioPLD(2)">
          </b-form-radio>
        </div>
        <div class="col-md-11">
          <b>NO</b>
          tengo conocimiento del dueño beneficiario o beneficiario controlador,
          ya que actué por cuenta de mi representada, por lo que son sus
          accionistas quienes se benefician de las consecuencias jurídicas del
          otorgamiento del instrumento público correspondiente.
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-1">
          <b-form-radio v-model="constitutiva.beneficiario_pld" name="pld" value="3" @change="selectBeneficiarioPLD(3)">
          </b-form-radio>
        </div>
        <div class="col-md-11">
          <b>SI</b> tengo conocimiento del dueño beneficiario o beneficiario
          controlador.
        </div>
      </div>

      <label class="beneficiario">
        Conforme a lo establecido en el Código Fiscal de la Federación,
        manifiesto, bajo protesta de decir verdad, que:
      </label>
      <div class="row mt-2">
        <div class="col-1">
          <b-form-radio v-model="constitutiva.beneficiario_cff" name="cff" value="4" @change="selectBeneficiarioCFF(4)">
          </b-form-radio>
        </div>
        <div class="col-md-11">
          Actúo por mi propio derecho, por lo que <b>NO</b> hay beneficiario
          controlador distinto al suscrito.
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-1">
          <b-form-radio v-model="constitutiva.beneficiario_cff" name="cff" value="6" @change="selectBeneficiarioCFF(6)">
          </b-form-radio>
        </div>
        <div class="col-md-11">
          <b>NO</b> tengo conocimiento del beneficiario controlador bajo los
          criterios establecidos en la regla 2.8.1.20. de la RMF. No obstante lo
          anterior, tengo conocimiento de la forma en la que está compuesta la
          administración de mi representada, por lo que haré entrega de la
          información y documentación que me sea solicitada.
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-1">
          <b-form-radio v-model="constitutiva.beneficiario_cff" name="cff" value="5" @change="selectBeneficiarioCFF(5)">
          </b-form-radio>
        </div>
        <div class="col-md-11">
          <b>SI</b> tengo conocimiento del o los beneficiarios controladores, por lo que haré entrega de la información
          y documentación que me sea solicitada.
        </div>
      </div>
      <!-- <br /> -->
      <div class="row mt-4" v-if="constitutiva.beneficiario_cff === 5">
        <div class="col-12">
          <b-form-group id="input-group-beneficiario" label="Nombre beneficiario controlador:"
            label-for="input-beneficiario">
            <b-form-input id="input-beneficiario" type="text" placeholder="Nombre completo"
              v-model="constitutiva.nombre_beneficiario"></b-form-input>
            <span class="cardErrors">Para varios beneficiarios controladores, incluirlos separándolos por comas.</span>
          </b-form-group>
        </div>
      </div>
    </div>

    <div class="mt-4">
      <div class="row">
        <div class="col-10">
          <b-button type="submit" class="btn-fill orange-btn bottom2" size="m"
            :disabled="constitutiva.disabledRepresentantes">Guardar</b-button>
        </div>
        <div class="col-2">
          <b-button variant="success" class="btn-fill bottom2" size="m" :disabled="constitutiva.disabledRepresentantes"
            @click="validar()">Validar</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalApoderados from "@/views/clients/procesos/constitucion/infoBase/modales/ModalApoderados.vue";
// import { CatFacultadesApi } from "@/api";

export default {
  name: "TabRepresentantesYApoderados",

  components: {
    ModalApoderados
  },

  created() {
    this.getFacultades();
  },

  props: {
    constitutiva: { type: Object },
    cat_facultades: { type: Array }
  },

  computed: {
    fieldsCuadro() {
      let fieldsCuadro = [
        { key: "index", label: "", class: "text-center" },
        { key: "nombre", label: "Nombre", class: "text-center" },
        { key: "paterno", label: "Paterno", class: "text-center" },
        { key: "materno", label: "Materno", class: "text-center" },
        // { key: "rfc", label: "RFC", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" }
      ];
      return fieldsCuadro;
    }
  },

  data() {
    return {
      currentApoderado: null,
      currentApoderadoId: null,
      options_facultades: [],
      // cat_facultades: [],

      form: {
        apoderados: []
      }
    };
  },

  methods: {
    selectBeneficiarioPLD(value) {
      this.constitutiva.beneficiario_pld = value;
    },

    selectBeneficiarioCFF(value) {
      this.constitutiva.beneficiario_cff = value;
    },

    async getFacultades() {
      try {
        this.loading = true;

        // const { value } = await CatFacultadesApi.list({
        //   page: 0,
        //   registersPerPage: 0,
        // });

        this.cat_facultades.forEach(element => {
          this.options_facultades.push({
            text: element.facultad,
            value: element.facultad
          });
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    addApoderado() {
      this.$refs["apoderado-modal"].show();
      this.currentApoderado = {};
    },

    editApoderado(item) {
      this.$refs["apoderado-modal"].show();
      this.currentApoderado = item;
    },

    putApoderado() {
      if (!this.currentApoderado.id) {
        const id = this.constitutiva.apoderados.length + 1;
        this.currentApoderado.id = id;
        const a = Object.assign({}, this.currentApoderado);

        this.constitutiva.apoderados.push(a);
      } else {
        const index = this.constitutiva.apoderados.findIndex(o => o.id === this.currentApoderado.id);
        const a = Object.assign({}, this.currentApoderado);

        // this.constitutiva.apoderados[index] = a;
        this.constitutiva.apoderados.splice([index], 1, a);
      }

      this.$refs["apoderado-modal"].hide();
    },

    predeleteApoderado(id) {
      this.currentApoderadoId = id;

      this.$refs["modal-confirm-dialog"].show();
    },

    deleteApoderado() {
      const index2 = this.constitutiva.apoderados.findIndex(o => o.id === this.currentApoderadoId);

      this.constitutiva.apoderados.splice(index2, 1);

      this.$refs["modal-confirm-dialog"].hide();
    },

    validar() {
      this.$emit("validaForm", "RepresentantesYApoderados");
    }
  }
};
</script>

<style scoped>
.bottom2 {
  /* position: fixed;
  right: 2%;
  bottom: 5%;
  overflow: visible;
  z-index: 100; */
  float: right;
}

.spaceL {
  margin-left: 1%;
}

.leyenda {
  text-transform: lowercase !important;
  color: #79cff7;
}

.leyenda:first-letter {
  text-transform: capitalize;
}

.beneficiario {
  color: #363535 !important;
  margin: 2% 0 2% 0 !important;
  text-transform: lowercase !important;
  font-size: 13px;
}

.beneficiario:first-letter {
  text-transform: capitalize !important;
}
</style>
