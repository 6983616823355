<template>
  <div class="container">
    <!-- <div v-if="loading">
      <div id="loader">
        <font-awesome-icon icon="spinner" spin />
      </div>
    </div> -->

    <div v-if="!alreadyInvoiced">
      <div class="row">
        <h5 class="col-12" style="color: #ea640b">Facturación</h5>
        <p class="col-12" style="font-size: 15px;">Ingrese los datos fiscales para facturación</p>
      </div>

      <div class="row mt-3">
        <div class="col-md-4">
          <ValidationProvider rules="required" v-slot="{ errors }"
            :custom-messages="{ required: 'Seleccione una opcion' }">
            <b-form-group id="input-group-tipo-persona" label="Tipo persona:" label-for="input-tipo-persona">
              <b-form-select id="input-tipo-persona" v-model="tipoPersonaSelected"
                :options="tipoPersonaOptions"></b-form-select>
            </b-form-group>
            <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>

      <div v-if="tipoPersonaSelected">
        <div class="row mt-3">
          <div class="col-md-4">
            <ValidationProvider name="rfc"
              :rules="{ required: true, regex: /^[A-Z&Ñ]{3,4}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A]$/ }"
              v-slot="{ errors }" :custom-messages="{
                required: 'Campo obligatorio',
                regex: 'Formato incorrecto'
              }">
              <b-form-group label="RFC">
                <b-form-input type="text" placeholder="RFC" v-model="rfc"
                  :formatter="toUpperAndNormalizerFormatter"></b-form-input>
                <span class="cardErrors">{{ errors[0] }}</span>
              </b-form-group>
            </ValidationProvider>
          </div>

          <div class="col-md-6">
            <ValidationProvider name="razonSocial" rules="required"
              v-slot="{ errors }" :custom-messages="{ required: 'Campo obligatorio' }">
              <b-form-group :label="labelRazonSocialComputed">
                <b-form-input type="text" :placeholder="labelRazonSocialComputed" v-model="razonSocial"
                  :formatter="toUpperAndNormalizerFormatter"></b-form-input>
                <span class="cardErrors">{{ errors[0] }}</span>
              </b-form-group>
            </ValidationProvider>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-5">
            <ValidationProvider rules="required" v-slot="{ errors }" :custom-messages="{
              required: 'Seleccione una opcion'
            }">
              <b-form-group id="input-group-regimen-fiscal" label="Régimen fiscal:" label-for="input-regimen-fiscal">
                <b-form-select id="input-regimen-fiscal" v-model="regimenFiscal"
                  :options="regimenesFiscalesOptions"></b-form-select>
              </b-form-group>
              <span class="cardErrors">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="col-md-5">
            <ValidationProvider rules="required" v-slot="{ errors }" :custom-messages="{
              required: 'Seleccione una opcion'
            }">
              <b-form-group id="input-group-uso-cfdi" label="Uso de CFDI:" label-for="input-uso-cfdi">
                <b-form-select id="input-uso-cfdi" v-model="usoCFDI" :options="usoCFDIOptions"></b-form-select>
              </b-form-group>
              <span class="cardErrors">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-3">
            <ValidationProvider name="codigoPostal" :rules="{ required: true, regex: /^\d{5}$/ }" v-slot="{ errors }"
              :custom-messages="{
                required: 'Campo obligatorio',
                regex: 'El código postal es incorrecto'
              }">
              <b-form-group label="Código postal">
                <b-form-input type="text" placeholder="Código postal" v-model="codigoPostal"></b-form-input>
                <span class="cardErrors">{{ errors[0] }}</span>
              </b-form-group>
            </ValidationProvider>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row">
        <h5 class="col-12" style="color: #ea640b">Facturación</h5>
      </div>

      <div class="mt-3">
        <p>Folio Fiscal: <span class="text-monospace text-uppercase">{{ folioFiscal }}</span></p>
        <div class="row justify-content-center">
          <b-button class="btn btn-fill orange-btn" size="lg" v-b-tooltip.hover title="Descargar"
            @click="downloadCFDI(urlFacturaPdf, 'PDF')">
            <b-icon-download />
            PDF
          </b-button>
          <b-button class="btn btn-fill orange-btn" size="lg" v-b-tooltip.hover title="Descargar"
            @click="downloadCFDI(urlFacturaXml, 'XML')">
            <b-icon-download />
            XML
          </b-button>
        </div>
      </div>
    </div>

    <div class="row mt-3"></div>

    <div class="row mt-4 justify-content-end">
      <div v-if="!alreadyInvoiced">
        <b-button class="btn btn-fill gray-btn" :disabled="loading" @click.prevent="cancelFacturacion()">
          Cancelar
        </b-button>
        <b-button class="btn btn-fill orange-btn" :disabled="loading" @click.prevent="submitFactura()">
          Facturar
        </b-button>
      </div>
      <div v-else>
        <b-button class="btn btn-fill gray-btn" :disabled="loading" @click.prevent="closeFacturacion()">
          Cerrar
        </b-button>
      </div>
    </div>

    <b-overlay :show="loading" no-wrap></b-overlay>

  </div>
</template>

<script>
import { ClientesApi, FacturasApi } from "@/api";
import config from "@/config";
import Storage from "@/utils/storage";
import axios from "axios";
import { saveAs } from 'file-saver';

const storage = new Storage();

export default {
  name: "FacturacionComponent",

  props: {
    datosFacturacion: {
      cotizacionId: Number,
      alreadyInvoiced: Boolean
    },
  },

  computed: {
    labelRazonSocialComputed() {
      if (this.tipoPersonaSelected === "pf") {
        return "Nombre completo";
      }

      return "Razón Social";
    }
  },

  created() {
    this.checkExistingData();
  },

  data() {
    return {
      loading: false,

      tipoPersonaSelected: null,
      tipoPersonaOptions: tipoPersonaDictionary,
      regimenesFiscalesOptions: null,
      usoCFDIOptions: null,

      datosFiscalesId: null,
      clienteId: null,
      rfc: null,
      razonSocial: null,
      codigoPostal: null,
      regimenFiscal: null,
      usoCFDI: null,

      $datosFiscalesCliente: {
        id: 0,
        clienteId: 0,
        rfc: "",
        razonSocial: "",
        regimenFiscal: "",
        usoCFDI: "",
        codigoPostal: "",
        correo: "",
        calle: "",
        numeroExterior: "",
        numeroInterior: "",
        colonia: "",
        ciudad: "",
        municipio: "",
        estado: "",
        pais: "",
        activo: 0,
        creado: null,
        actualizado: null
      },

      alreadyInvoiced: false,
      fetchedDatosFiscales: false,
      datosCompletos: false,

      // Datos factura a descargar
      folioFiscal: null,
      urlFacturaPdf: null,
      urlFacturaXml: null,
    }
  },

  watch: {
    "tipoPersonaSelected": function (val, oldVal) {
      // this.regimenFiscal = null;
      // this.usoCFDI = null;
      this.regimenFiscalFunction();
    },
    "regimenFiscal": function (val, oldVal) {
      // this.usoCFDI = null;
      this.usoCFDIFunction();
    },
  },

  methods: {
    toUpperAndNormalizerFormatter(value = "") {
      return value.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },

    async checkExistingData() {
      try {
        this.loading = true;

        const factura = await FacturasApi.getFacturaByCotizacionId(this.datosFacturacion.cotizacionId);

        this.populateDatosFactura(factura);
        this.alreadyInvoiced = true;
      } catch (error) {
        if (error.status === 404) {
          // debugger;
          this.alreadyInvoiced = false;
          await this.fetchDatosFiscales();
        }
      } finally {
        this.loading = false;
      }
    },

    async fetchDatosFiscales() {
      try {
        const emailUser = storage.getItem("user");
        const { id } = await ClientesApi.clienteId(emailUser);

        this.clienteId = id;

        const datosFiscales = await FacturasApi.getDatosFiscalesByClienteId(id);

        this.datosFiscalesId = datosFiscales.id;
        this.rfc = datosFiscales.rfc;
        this.razonSocial = datosFiscales.razonSocial;
        this.regimenFiscal = datosFiscales.regimenFiscal;
        this.usoCFDI = datosFiscales.usoCFDI;
        this.codigoPostal = datosFiscales.codigoPostal;

        if (this.rfc.length === 13) {
          this.tipoPersonaSelected = "pf"
        }
        else {
          this.tipoPersonaSelected = "pm"
        }

        this.fetchedDatosFiscales = true;
      } catch (error) {
        if (error.status !== 404) {
          console.error(error);
          return;
        }
        this.fetchedDatosFiscales = false;
      }
    },

    async submitFactura() {
      try {
        this.loading = true;

        let datosFiscalesCliente = {
          clienteId: this.clienteId,
          rfc: this.rfc,
          razonSocial: this.razonSocial,
          regimenFiscal: this.regimenFiscal,
          usoCFDI: this.usoCFDI,
          codigoPostal: this.codigoPostal,
        }

        if (!this.fetchedDatosFiscales) {
          datosFiscalesCliente = await FacturasApi.postDatosFiscalesByCliente(datosFiscalesCliente);

          this.datosFiscalesId = datosFiscalesCliente.id;
          this.fetchedDatosFiscales = true;
        } else {
          datosFiscalesCliente = await FacturasApi.putDatosFiscalesByClienteId(this.datosFiscalesId, datosFiscalesCliente);

          this.datosFiscalesId = datosFiscalesCliente.id;
        }

        const createFacturaResponse = await FacturasApi.create({
          cotizacionId: this.datosFacturacion.cotizacionId,
          enviarCorreo: true,
          correo: storage.getItem("user")
        });

        this.$toast.success("Factura generada exitosamente.", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });

        this.alreadyInvoiced = true;
        this.populateDatosFactura(createFacturaResponse);
      } catch (error) {
        console.log(error);

        let message = "Ocurrió un error con el proceso de facturación, inténtelo de nuevo.";
        const contactMessage = "\nSi el problema persiste, póngase en contacto con el equipo de Correduría Digital";

        if (error.data.message) {
          message = error.data.message;
        }

        if (error.data.details && error.data.details[0]) {
          message = message + "\n" + error.data.details[0];
        }

        message += contactMessage;

        this.$toast.error(message, {
          timeout: 8000,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
      } finally {
        this.loading = false;
      }
    },

    populateDatosFactura(factura) {
      this.folioFiscal = factura.folioFiscal;
      this.urlFacturaPdf = factura.pdfStorageUrl;
      this.urlFacturaXml = factura.xmlStorageUrl;
    },

    downloadCFDI(url = String, tipo = String) {
      axios.get(url + config.STG_ACCESS_KEY, {
        responseType: "blob",
        // headers: {
        //     'Access-Control-Allow-Origin': '*',
        //     'Access-Control-Allow-Methods': ' GET, PUT, POST, DELETE, OPTIONS',
        //     'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
        //     'Access-Control-Allow-Credentials': 'false',
        // },
      }).then((res) => {
        if (tipo === "PDF") {
          saveAs(res.data, this.folioFiscal + ".pdf");
        }
        else if (tipo === "XML") {
          saveAs(res.data, this.folioFiscal + ".xml");
        }
      }).catch(console.error);
    },

    cancelFacturacion() {
      this.$emit('canceledFacturacionEvent');
    },

    closeFacturacion() {
      this.$emit('closedFacturacionEvent');
    },

    regimenFiscalFunction() {
      this.regimenesFiscalesOptions = regimenesFiscalesDictionary[this.tipoPersonaSelected];
    },

    usoCFDIFunction() {
      this.usoCFDIOptions = usosCFDIDictionary[this.regimenFiscal];
    }
  }
}

const tipoPersonaDictionary = {
  "null": { value: null, text: "Seleccione una opción" },
  "pf": "Persona fisica",
  "pm": "Persona moral"
};

const regimenesFiscalesDictionary = {
  "pf": [
    { value: null, text: "Seleccione una opción" },
    { value: "605", text: "Sueldos y Salarios e Ingresos Asimilados a Salarios" },
    { value: "606", text: "Arrendamiento" },
    { value: "608", text: "Demás ingresos" },
    { value: "611", text: "Ingresos por Dividendos (socios y accionistas)" },
    { value: "612", text: "Personas Físicas con Actividades Empresariales y Profesionales" },
    { value: "614", text: "Ingresos por intereses" },
    { value: "615", text: "Régimen de los ingresos por obtención de premios" },
    { value: "616", text: "Sin obligaciones fiscales" },
    { value: "621", text: "Incorporación Fiscal" },
    { value: "625", text: "Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas" },
    { value: "626", text: "Régimen Simplificado de Confianza" },
  ],
  "pm": [
    { value: null, text: "Seleccione una opción" },
    { value: "601", text: "General de Ley Personas Morales" },
    { value: "603", text: "Personas Morales con Fines no Lucrativos" },
    { value: "610", text: "Residentes en el Extranjero sin Establecimiento Permanente en México" },
    { value: "620", text: "Sociedades Cooperativas de Producción que optan por diferir sus ingresos" },
    { value: "622", text: "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras" },
    { value: "623", text: "Opcional para Grupos de Sociedades" },
    { value: "624", text: "Coordinados" },
    { value: "626", text: "Régimen Simplificado de Confianza" }
  ]
};

const usosCFDIDictionary = {
  "601": [
    { value: null, text: "Seleccione una opción" },
    { value: "G01", text: "Adquisición de mercancias" },
    { value: "G02", text: "Devoluciones, descuentos o bonificaciones" },
    { value: "G03", text: "Gastos en general" },
    { value: "I01", text: "Construcciones" },
    { value: "I02", text: "Mobilario y equipo de oficina por inversiones" },
    { value: "I03", text: "Equipo de transporte" },
    { value: "I04", text: "Equipo de computo y accesorios" },
    { value: "I05", text: "Dados, troqueles, moldes, matrices y herramental" },
    { value: "I06", text: "Comunicaciones telefónicas" },
    { value: "I07", text: "Comunicaciones satelitales" },
    { value: "I08", text: "Otra maquinaria y equipo" },
    { value: "S01", text: "Sin efectos fiscales" },
    { value: "CP01", text: "Pagos" },
  ],
  "603": [
    { value: null, text: "Seleccione una opción" },
    { value: "G01", text: "Adquisición de mercancias" },
    { value: "G02", text: "Devoluciones, descuentos o bonificaciones" },
    { value: "G03", text: "Gastos en general" },
    { value: "I01", text: "Construcciones" },
    { value: "I02", text: "Mobilario y equipo de oficina por inversiones" },
    { value: "I03", text: "Equipo de transporte" },
    { value: "I04", text: "Equipo de computo y accesorios" },
    { value: "I05", text: "Dados, troqueles, moldes, matrices y herramental" },
    { value: "I06", text: "Comunicaciones telefónicas" },
    { value: "I07", text: "Comunicaciones satelitales" },
    { value: "I08", text: "Otra maquinaria y equipo" },
    { value: "S01", text: "Sin efectos fiscales" },
    { value: "CP01", text: "Pagos" },
  ],
  "605": [
    { value: null, text: "Seleccione una opción" },
    { value: "D01", text: "Honorarios médicos, dentales y gastos hospitalarios" },
    { value: "D02", text: "Gastos médicos por incapacidad o discapacidad" },
    { value: "D03", text: "Gastos funerales" },
    { value: "D04", text: "Donativos" },
    { value: "D05", text: "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)" },
    { value: "D06", text: "Aportaciones voluntarias al SAR" },
    { value: "D07", text: "Primas por seguros de gastos médicos" },
    { value: "D08", text: "Gastos de transportación escolar obligatoria" },
    { value: "D09", text: "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones" },
    { value: "D10", text: "Pagos por servicios educativos (colegiaturas)" },
    { value: "S01", text: "Sin efectos fiscales" },
    { value: "CP01", text: "Pagos" },
    { value: "CN01", text: "Nómina" },
  ],
  "606": [
    { value: null, text: "Seleccione una opción" },
    { value: "G01", text: "Adquisición de mercancias" },
    { value: "G02", text: "Devoluciones, descuentos o bonificaciones" },
    { value: "G03", text: "Gastos en general" },
    { value: "I01", text: "Construcciones" },
    { value: "I02", text: "Mobilario y equipo de oficina por inversiones" },
    { value: "I03", text: "Equipo de transporte" },
    { value: "I04", text: "Equipo de computo y accesorios" },
    { value: "I05", text: "Dados, troqueles, moldes, matrices y herramental" },
    { value: "I06", text: "Comunicaciones telefónicas" },
    { value: "I07", text: "Comunicaciones satelitales" },
    { value: "I08", text: "Otra maquinaria y equipo" },
    { value: "D01", text: "Honorarios médicos, dentales y gastos hospitalarios" },
    { value: "D02", text: "Gastos médicos por incapacidad o discapacidad" },
    { value: "D03", text: "Gastos funerales" },
    { value: "D04", text: "Donativos" },
    { value: "D05", text: "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)" },
    { value: "D06", text: "Aportaciones voluntarias al SAR" },
    { value: "D07", text: "Primas por seguros de gastos médicos" },
    { value: "D08", text: "Gastos de transportación escolar obligatoria" },
    { value: "D09", text: "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones" },
    { value: "D10", text: "Pagos por servicios educativos (colegiaturas)" },
    { value: "S01", text: "Sin efectos fiscales" },
    { value: "CP01", text: "Pagos" },
  ],
  "607": [
    { value: null, text: "Seleccione una opción" },
    { value: "D01", text: "Honorarios médicos, dentales y gastos hospitalarios" },
    { value: "D02", text: "Gastos médicos por incapacidad o discapacidad" },
    { value: "D03", text: "Gastos funerales" },
    { value: "D04", text: "Donativos" },
    { value: "D05", text: "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)" },
    { value: "D06", text: "Aportaciones voluntarias al SAR" },
    { value: "D07", text: "Primas por seguros de gastos médicos" },
    { value: "D08", text: "Gastos de transportación escolar obligatoria" },
    { value: "D09", text: "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones" },
    { value: "D10", text: "Pagos por servicios educativos (colegiaturas)" },
    { value: "S01", text: "Sin efectos fiscales" },
    { value: "CP01", text: "Pagos" },
  ],
  "608": [
    { value: null, text: "Seleccione una opción" },
    { value: "D01", text: "Honorarios médicos, dentales y gastos hospitalarios" },
    { value: "D02", text: "Gastos médicos por incapacidad o discapacidad" },
    { value: "D03", text: "Gastos funerales" },
    { value: "D04", text: "Donativos" },
    { value: "D05", text: "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)" },
    { value: "D06", text: "Aportaciones voluntarias al SAR" },
    { value: "D07", text: "Primas por seguros de gastos médicos" },
    { value: "D08", text: "Gastos de transportación escolar obligatoria" },
    { value: "D09", text: "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones" },
    { value: "D10", text: "Pagos por servicios educativos (colegiaturas)" },
    { value: "S01", text: "Sin efectos fiscales" },
    { value: "CP01", text: "Pagos" },
  ],
  "610": [
    { value: null, text: "Seleccione una opción" },
    { value: "S01", text: "Sin efectos fiscales" },
    { value: "CP01", text: "Pagos" },
  ],
  "611": [
    { value: null, text: "Seleccione una opción" },
    { value: "D01", text: "Honorarios médicos, dentales y gastos hospitalarios" },
    { value: "D02", text: "Gastos médicos por incapacidad o discapacidad" },
    { value: "D03", text: "Gastos funerales" },
    { value: "D04", text: "Donativos" },
    { value: "D05", text: "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)" },
    { value: "D06", text: "Aportaciones voluntarias al SAR" },
    { value: "D07", text: "Primas por seguros de gastos médicos" },
    { value: "D08", text: "Gastos de transportación escolar obligatoria" },
    { value: "D09", text: "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones" },
    { value: "D10", text: "Pagos por servicios educativos (colegiaturas)" },
    { value: "S01", text: "Sin efectos fiscales" },
    { value: "CP01", text: "Pagos" },
  ],
  "612": [
    { value: null, text: "Seleccione una opción" },
    { value: "G01", text: "Adquisición de mercancias" },
    { value: "G02", text: "Devoluciones, descuentos o bonificaciones" },
    { value: "G03", text: "Gastos en general" },
    { value: "I01", text: "Construcciones" },
    { value: "I02", text: "Mobilario y equipo de oficina por inversiones" },
    { value: "I03", text: "Equipo de transporte" },
    { value: "I04", text: "Equipo de computo y accesorios" },
    { value: "I05", text: "Dados, troqueles, moldes, matrices y herramental" },
    { value: "I06", text: "Comunicaciones telefónicas" },
    { value: "I07", text: "Comunicaciones satelitales" },
    { value: "I08", text: "Otra maquinaria y equipo" },
    { value: "D01", text: "Honorarios médicos, dentales y gastos hospitalarios" },
    { value: "D02", text: "Gastos médicos por incapacidad o discapacidad" },
    { value: "D03", text: "Gastos funerales" },
    { value: "D04", text: "Donativos" },
    { value: "D05", text: "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)" },
    { value: "D06", text: "Aportaciones voluntarias al SAR" },
    { value: "D07", text: "Primas por seguros de gastos médicos" },
    { value: "D08", text: "Gastos de transportación escolar obligatoria" },
    { value: "D09", text: "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones" },
    { value: "D10", text: "Pagos por servicios educativos (colegiaturas)" },
    { value: "S01", text: "Sin efectos fiscales" },
    { value: "CP01", text: "Pagos" },
  ],
  "614": [
    { value: null, text: "Seleccione una opción" },
    { value: "D01", text: "Honorarios médicos, dentales y gastos hospitalarios" },
    { value: "D02", text: "Gastos médicos por incapacidad o discapacidad" },
    { value: "D03", text: "Gastos funerales" },
    { value: "D04", text: "Donativos" },
    { value: "D05", text: "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)" },
    { value: "D06", text: "Aportaciones voluntarias al SAR" },
    { value: "D07", text: "Primas por seguros de gastos médicos" },
    { value: "D08", text: "Gastos de transportación escolar obligatoria" },
    { value: "D09", text: "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones" },
    { value: "D10", text: "Pagos por servicios educativos (colegiaturas)" },
    { value: "S01", text: "Sin efectos fiscales" },
    { value: "CP01", text: "Pagos" },
  ],
  "615": [
    { value: null, text: "Seleccione una opción" },
    { value: "D01", text: "Honorarios médicos, dentales y gastos hospitalarios" },
    { value: "D02", text: "Gastos médicos por incapacidad o discapacidad" },
    { value: "D03", text: "Gastos funerales" },
    { value: "D04", text: "Donativos" },
    { value: "D05", text: "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)" },
    { value: "D06", text: "Aportaciones voluntarias al SAR" },
    { value: "D07", text: "Primas por seguros de gastos médicos" },
    { value: "D08", text: "Gastos de transportación escolar obligatoria" },
    { value: "D09", text: "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones" },
    { value: "D10", text: "Pagos por servicios educativos (colegiaturas)" },
    { value: "S01", text: "Sin efectos fiscales" },
    { value: "CP01", text: "Pagos" },
  ],
  "616": [
    { value: null, text: "Seleccione una opción" },
    { value: "S01", text: "Sin efectos fiscales" },
    { value: "CP01", text: "Pagos" },
  ],
  "620": [
    { value: null, text: "Seleccione una opción" },
    { value: "G01", text: "Adquisición de mercancias" },
    { value: "G02", text: "Devoluciones, descuentos o bonificaciones" },
    { value: "G03", text: "Gastos en general" },
    { value: "I01", text: "Construcciones" },
    { value: "I02", text: "Mobilario y equipo de oficina por inversiones" },
    { value: "I03", text: "Equipo de transporte" },
    { value: "I04", text: "Equipo de computo y accesorios" },
    { value: "I05", text: "Dados, troqueles, moldes, matrices y herramental" },
    { value: "I06", text: "Comunicaciones telefónicas" },
    { value: "I07", text: "Comunicaciones satelitales" },
    { value: "I08", text: "Otra maquinaria y equipo" },
    { value: "S01", text: "Sin efectos fiscales" },
    { value: "CP01", text: "Pagos" },
  ],
  "621": [
    { value: null, text: "Seleccione una opción" },
    { value: "G01", text: "Adquisición de mercancias" },
    { value: "G02", text: "Devoluciones, descuentos o bonificaciones" },
    { value: "G03", text: "Gastos en general" },
    { value: "I01", text: "Construcciones" },
    { value: "I02", text: "Mobilario y equipo de oficina por inversiones" },
    { value: "I03", text: "Equipo de transporte" },
    { value: "I04", text: "Equipo de computo y accesorios" },
    { value: "I05", text: "Dados, troqueles, moldes, matrices y herramental" },
    { value: "I06", text: "Comunicaciones telefónicas" },
    { value: "I07", text: "Comunicaciones satelitales" },
    { value: "I08", text: "Otra maquinaria y equipo" },
    { value: "S01", text: "Sin efectos fiscales" },
    { value: "CP01", text: "Pagos" },
  ],
  "622": [
    { value: null, text: "Seleccione una opción" },
    { value: "G01", text: "Adquisición de mercancias" },
    { value: "G02", text: "Devoluciones, descuentos o bonificaciones" },
    { value: "G03", text: "Gastos en general" },
    { value: "I01", text: "Construcciones" },
    { value: "I02", text: "Mobilario y equipo de oficina por inversiones" },
    { value: "I03", text: "Equipo de transporte" },
    { value: "I04", text: "Equipo de computo y accesorios" },
    { value: "I05", text: "Dados, troqueles, moldes, matrices y herramental" },
    { value: "I06", text: "Comunicaciones telefónicas" },
    { value: "I07", text: "Comunicaciones satelitales" },
    { value: "I08", text: "Otra maquinaria y equipo" },
    { value: "S01", text: "Sin efectos fiscales" },
    { value: "CP01", text: "Pagos" },
  ],
  "623": [
    { value: null, text: "Seleccione una opción" },
    { value: "G01", text: "Adquisición de mercancias" },
    { value: "G02", text: "Devoluciones, descuentos o bonificaciones" },
    { value: "G03", text: "Gastos en general" },
    { value: "I01", text: "Construcciones" },
    { value: "I02", text: "Mobilario y equipo de oficina por inversiones" },
    { value: "I03", text: "Equipo de transporte" },
    { value: "I04", text: "Equipo de computo y accesorios" },
    { value: "I05", text: "Dados, troqueles, moldes, matrices y herramental" },
    { value: "I06", text: "Comunicaciones telefónicas" },
    { value: "I07", text: "Comunicaciones satelitales" },
    { value: "I08", text: "Otra maquinaria y equipo" },
    { value: "S01", text: "Sin efectos fiscales" },
    { value: "CP01", text: "Pagos" },
  ],
  "624": [
    { value: null, text: "Seleccione una opción" },
    { value: "G01", text: "Adquisición de mercancias" },
    { value: "G02", text: "Devoluciones, descuentos o bonificaciones" },
    { value: "G03", text: "Gastos en general" },
    { value: "I01", text: "Construcciones" },
    { value: "I02", text: "Mobilario y equipo de oficina por inversiones" },
    { value: "I03", text: "Equipo de transporte" },
    { value: "I04", text: "Equipo de computo y accesorios" },
    { value: "I05", text: "Dados, troqueles, moldes, matrices y herramental" },
    { value: "I06", text: "Comunicaciones telefónicas" },
    { value: "I07", text: "Comunicaciones satelitales" },
    { value: "I08", text: "Otra maquinaria y equipo" },
    { value: "S01", text: "Sin efectos fiscales" },
    { value: "CP01", text: "Pagos" },
  ],
  "625": [
    { value: null, text: "Seleccione una opción" },
    { value: "G01", text: "Adquisición de mercancias" },
    { value: "G02", text: "Devoluciones, descuentos o bonificaciones" },
    { value: "G03", text: "Gastos en general" },
    { value: "I01", text: "Construcciones" },
    { value: "I02", text: "Mobilario y equipo de oficina por inversiones" },
    { value: "I03", text: "Equipo de transporte" },
    { value: "I04", text: "Equipo de computo y accesorios" },
    { value: "I05", text: "Dados, troqueles, moldes, matrices y herramental" },
    { value: "I06", text: "Comunicaciones telefónicas" },
    { value: "I07", text: "Comunicaciones satelitales" },
    { value: "I08", text: "Otra maquinaria y equipo" },
    { value: "D01", text: "Honorarios médicos, dentales y gastos hospitalarios" },
    { value: "D02", text: "Gastos médicos por incapacidad o discapacidad" },
    { value: "D03", text: "Gastos funerales" },
    { value: "D04", text: "Donativos" },
    { value: "D05", text: "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)" },
    { value: "D06", text: "Aportaciones voluntarias al SAR" },
    { value: "D07", text: "Primas por seguros de gastos médicos" },
    { value: "D08", text: "Gastos de transportación escolar obligatoria" },
    { value: "D09", text: "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones" },
    { value: "D10", text: "Pagos por servicios educativos (colegiaturas)" },
    { value: "S01", text: "Sin efectos fiscales" },
    { value: "CP01", text: "Pagos" },
  ],
  "626": [
    { value: null, text: "Seleccione una opción" },
    { value: "G01", text: "Adquisición de mercancias" },
    { value: "G02", text: "Devoluciones, descuentos o bonificaciones" },
    { value: "G03", text: "Gastos en general" },
    { value: "I01", text: "Construcciones" },
    { value: "I02", text: "Mobilario y equipo de oficina por inversiones" },
    { value: "I03", text: "Equipo de transporte" },
    { value: "I04", text: "Equipo de computo y accesorios" },
    { value: "I05", text: "Dados, troqueles, moldes, matrices y herramental" },
    { value: "I06", text: "Comunicaciones telefónicas" },
    { value: "I07", text: "Comunicaciones satelitales" },
    { value: "I08", text: "Otra maquinaria y equipo" },
    { value: "S01", text: "Sin efectos fiscales" },
    { value: "CP01", text: "Pagos" },
  ],
}
</script>

<style scoped>
.modal-title {
  font-size: 15px;
  color: #ea640b;
}

.cardErrors {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* color: #9a9a9a; */
  color: #ea640b;
  /* font-size: 12px; */
}
</style>
