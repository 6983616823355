<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <ValidationProvider name="denominacion_social" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La denominación social es obligatoria'
        }">
          <base-input :disabled="user.disabled" type="text" label="Denominación Social"
            placeholder="Denominación Social" v-model="user.denominacion_social">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row">
      <!-- <div class="col-md-4">
        <ValidationProvider
          name="email"
          rules="required|email"
          v-slot="{ errors }"
          :custom-messages="{
            required: 'El email es obligatorio',
            email: 'Debe ser un email válido'
          }"
        >
          <base-input
            :disabled="user.disabled"
            type="email"
            label="Email"
            placeholder="Email"
            v-model="user.email"
          >
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div> -->
      <div class="col-md-4">
        <ValidationProvider name="rfc" rules="required|length:12" v-slot="{ errors }" :custom-messages="{
          required: 'El RFC es obligatorio',
          length: 'Debe tener 12 caracteres'
        }">
          <base-input :disabled="user.disabled" type="text" label="RFC" placeholder="RFC" v-model="user.rfc">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <ValidationProvider name="fecha_constitucion" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La fecha de constitución es obligatoria'
        }">
          <base-input :disabled="user.disabled" type="date" label="Fecha de constitución"
            placeholder="Fecha de constitución" v-model="user.fecha_constitucion">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <!-- TODO: Refactorizar este select para utilizar las nacionalidades de la api -->
        <ValidationProvider name="nacionalidad" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La nacionalidad es obligatoria'
        }">
          <label>Nacionalidades</label>
          <v-select :disabled="user.disabled" multiple v-model="user.nacionalidades" :options="optionsNacionalidades"
            :selectable="option => !option.includes('_______________________________________')" />
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <ValidationProvider name="actividad" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La actividad es obligatoria'
        }">
          <base-input :disabled="user.disabled" type="text" label="Actividad" placeholder="Actividad"
            v-model="user.actividad">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-6">
        <ValidationProvider name="giro" rules="required" v-slot="{ errors }"
          :custom-messages="{ required: 'El giro es obligatorio' }">
          <base-input :disabled="user.disabled" type="text" label="Giro" placeholder="Giro" v-model="user.giro">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <ValidationProvider name="objeto_social" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El objeto social es obligatorio'
        }">
          <label>Objeto Social</label>
          <b-form-textarea :disabled="user.disabled" type="text" placeholder="Objeto Social"
            v-model="user.objeto_social">
          </b-form-textarea>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>
    <br />

    <div class="row">
      <div class="col-md-6">
        <ValidationProvider name="instrumento_numero" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El número de instrumento es obligatorio'
        }">
          <base-input :disabled="user.disabled" type="text" label="Instrumento Constitutivo: Número"
            placeholder="Número" v-model="user.instrumento_numero">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-6">
        <ValidationProvider name="instrumento_numero_fecha" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La fecha es obligatoria'
        }">
          <base-input :disabled="user.disabled" type="date" label="De Fecha" placeholder="De Fecha"
            v-model="user.instrumento_numero_fecha">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row">
      <div class="col-md-2">
        <ValidationProvider name="instrumento_fedatario" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El tipo de fedatario es obligatorio'
        }">
          <label>Tipo de Fedatario</label>
          <select v-model="user.instrumento_fedatario" class="custom-select">
            <option value="Notario">Notario</option>
            <option value="Corredor Público">Corredor Público</option>
          </select>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <ValidationProvider name="instrumento_fedatario_nombre" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El nombre del fedatario es obligatorio'
        }">
          <base-input :disabled="user.disabled" type="text" label="Nombre de Fedatario"
            placeholder="Nombre de Fedatario" v-model="user.instrumento_fedatario_nombre">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-3">
        <ValidationProvider name="instrumento_fedatario_numero" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El número es obligatorio'
        }">
          <base-input :disabled="user.disabled" type="text" label="Número" placeholder="Número"
            v-model="user.instrumento_fedatario_numero">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-3">
        <ValidationProvider name="instrumento_fedatario_estado" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El estado es obligatorio'
        }">
          <!-- <base-input
            type="text"
            label="Del Estado de"
            placeholder="Estado"
            v-model="user.instrumento_fedatario_estado"
          >
          </base-input> -->
          <label>Entidad Federativa</label>
          <select v-model="user.instrumento_fedatario_estado" class="custom-select" :disabled="user.disabled">
            <option v-for="option in optionsEstados" :key="option.value">
              {{ option.text }}
            </option>
          </select>

          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <ValidationProvider name="instrumento_rpf_folio" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El RPF Folio es obligatorio'
        }">
          <base-input :disabled="user.disabled" type="text" label="RPF Folio" placeholder="RPF Folio"
            v-model="user.instrumento_rpf_folio">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <ValidationProvider name="instrumento_rpf_folio_fecha" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La fecha es obligatoria'
        }">
          <base-input :disabled="user.disabled" type="date" label="De Fecha" placeholder="De Fecha"
            v-model="user.instrumento_rpf_folio_fecha">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <ValidationProvider name="instrumento_rpf_folio_lugar" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El lugar es obligatorio'
        }">
          <base-input :disabled="user.disabled" type="text" label="Lugar" placeholder="Lugar"
            v-model="user.instrumento_rpf_folio_lugar">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <h4 class="cardD">Datos de identificación del Fideicomiso</h4>
    <div class="row">
      <div class="col-md-12">
        <ValidationProvider name="fiduciario" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El fiduciario es obligatorio'
        }">
          <base-input :disabled="user.disabled" type="text" label="Fiduciario"
            placeholder="Fiduciario (denominación social completa)" v-model="user.fiduciario">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <ValidationProvider name="nombre_fideicomiso" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El Nombre o número de identificación es obligatorio'
        }">
          <base-input :disabled="user.disabled" type="text" label="Nombre o número de identificación"
            placeholder="Nombre o número de identificación del fideicomiso" v-model="user.nombre_fideicomiso">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <ValidationProvider name="fideicomitente" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El fideicomitente principal es obligatorio'
        }">
          <base-input :disabled="user.disabled" type="text" label="Fideicomitente principal"
            placeholder="Fideicomitente principal" v-model="user.fideicomitente">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <ValidationProvider name="tipo_fideicomiso" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El tipo de fideicomiso es obligatorio'
        }">
          <label for="">Tipo</label>
          <select v-model="user.tipo_fideicomiso" class="custom-select" :disabled="user.disabled">
            <option value="Administración">Administración</option>
            <option value="Garantía">Garantía</option>
            <option value="Fuente de Pago">Fuente de Pago</option>
            <option value="Empresarial">Empresarial</option>
            <option value="Otro">Otro</option>
          </select>

          <base-input class="mt-2" type="text" label="" placeholder="Tipo" v-model="user.tipo_fideicomiso_otro"
            v-if="user.tipo_fideicomiso === 'Otro'" :disabled="user.disabled">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <ValidationProvider name="fecha_creacion_fideicomiso" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La fecha de creación es obligatoria'
        }">
          <base-input :disabled="user.disabled" type="date" label="Fecha de creación"
            placeholder="Fecha de creación del fideicomiso" v-model="user.fecha_creacion_fideicomiso">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <!-- <ValidationProvider
          name="folio_inscripcion"
          rules="required"
          v-slot="{ errors }"
          :custom-messages="{
            required: 'El RFC es obligatorio',
          }"
        > -->
        <base-input :disabled="user.disabled" type="text" label="Folio de inscripción en el RUG"
          placeholder="Folio de inscripción en el RUG" v-model="user.folio_inscripcion">
        </base-input>
        <!-- <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider> -->
      </div>
      <div class="col-md-4">
        <base-input :disabled="user.disabled" type="text" label="RFC" placeholder="RFC del Fideicomiso (en su caso)"
          v-model="user.rfc_fideicomiso">
        </base-input>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <base-input :disabled="user.disabled" type="text" label="E-mail de contacto principal"
          placeholder="E-mail de contacto principal" v-model="user.email_fideicomiso">
        </base-input>
      </div>
      <div class="col-md-6">
        <base-input :disabled="user.disabled" type="text" label="Teléfono de contacto principal"
          placeholder="Teléfono de contacto principal" v-model="user.tel_fideicomiso">
        </base-input>
      </div>
    </div>

    <!-- <button type="submit" class="btn btnForm orange-btn">Guardar</button> -->
    <b-button type="submit" class="btn-fill orange-btn bottom" size="lg" :disabled="user.disabled">Guardar</b-button>
  </div>
</template>
<script>
import Card from "src/components/Cards/Card.vue";

export default {
  name: "FormMoral",

  components: {
    Card
  },

  props: {
    user: { type: Object }
  },

  watch: {
    "user.rfc": function (value) {
      if (!value) {
        this.user.rfc = "";

        return;
      }

      this.user.rfc = this.user.rfc.toUpperCase();
    },

    "user.rfc_fideicomiso": function (value) {
      if (!value) {
        this.user.rfc_fideicomiso = "";

        return;
      }

      this.user.rfc_fideicomiso = this.user.rfc_fideicomiso.toUpperCase();
    },
  },

  data() {
    return {
      optionsNacionalidades: [
        "México",
        "España",
        "Estados Unidos",
        "Argentina",
        "_______________________________________",
        "Afganistán",
        "Albania",
        "Alemania",
        "Andorra",
        "Angola",
        "Antigua y Barbuda",
        "Arabia Saudita",
        "Argelia",
        "Armenia",
        "Australia",
        "Austria",
        "Azerbaiyán",
        "Bahamas",
        "Bangladés",
        "Barbados",
        "Baréin",
        "Bélgica",
        "Belice",
        "Benín",
        "Bielorrusia",
        "Birmania",
        "Bolivia",
        "Bosnia y Herzegovina",
        "Botsuana",
        "Brasil",
        "Brunéi",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Bután",
        "Cabo Verde",
        "Camboya",
        "Camerún",
        "Canadá",
        "Catar",
        "Chad",
        "Chile",
        "China",
        "Chipre",
        "Ciudad del Vaticano",
        "Colombia",
        "Comoras",
        "Corea del Norte",
        "Corea del Sur",
        "Costa de Marfil",
        "Costa Rica",
        "Croacia",
        "Cuba",
        "Dinamarca",
        "Dominica",
        "Ecuador",
        "Egipto",
        "El Salvador",
        "Emiratos Árabes Unidos",
        "Eritrea",
        "Eslovaquia",
        "Eslovenia",
        "Estonia",
        "Etiopía",
        "Filipinas",
        "Finlandia",
        "Fiyi",
        "Francia",
        "Gabón",
        "Gambia",
        "Georgia",
        "Ghana",
        "Granada",
        "Grecia",
        "Guatemala",
        "Guyana",
        "Guinea",
        "Guinea ecuatorial",
        "Guinea-Bisáu",
        "Haití",
        "Honduras",
        "Hungría",
        "India",
        "Indonesia",
        "Irak",
        "Irán",
        "Irlanda",
        "Islandia",
        "Islas Marshall",
        "Islas Salomón",
        "Israel",
        "Italia",
        "Jamaica",
        "Japón",
        "Jordania",
        "Kazajistán",
        "Kenia",
        "Kirguistán",
        "Kiribati",
        "Kuwait",
        "Laos",
        "Lesoto",
        "Letonia",
        "Líbano",
        "Liberia",
        "Libia",
        "Liechtenstein",
        "Lituania",
        "Luxemburgo",
        "Macedonia del Norte",
        "Madagascar",
        "Malasia",
        "Malaui",
        "Maldivas",
        "Malí",
        "Malta",
        "Marruecos",
        "Mauricio",
        "Mauritania",
        "Micronesia",
        "Moldavia",
        "Mónaco",
        "Mongolia",
        "Montenegro",
        "Mozambique",
        "Namibia",
        "Nauru",
        "Nepal",
        "Nicaragua",
        "Níger",
        "Nigeria",
        "Noruega",
        "Nueva Zelanda",
        "Omán",
        "Países Bajos",
        "Pakistán",
        "Palaos",
        "Panamá",
        "Papúa Nueva Guinea",
        "Paraguay",
        "Perú",
        "Polonia",
        "Portugal",
        "Reino Unido",
        "República Centroafricana",
        "República Checa",
        "República del Congo",
        "República Democrática del Congo",
        "República Dominicana",
        "Ruanda",
        "Rumanía",
        "Rusia",
        "Samoa",
        "San Cristóbal y Nieves",
        "San Marino",
        "San Vicente y las Granadinas",
        "Santa Lucía",
        "Santo Tomé y Príncipe",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leona",
        "Singapur",
        "Siria",
        "Somalia",
        "Sri Lanka",
        "Suazilandia",
        "Sudáfrica",
        "Sudán",
        "Sudán del Sur",
        "Suecia",
        "Suiza",
        "Surinam",
        "Tailandia",
        "Tanzania",
        "Tayikistán",
        "Timor Oriental",
        "Togo",
        "Tonga",
        "Trinidad y Tobago",
        "Túnez",
        "Turkmenistán",
        "Turquía",
        "Tuvalu",
        "Ucrania",
        "Uganda",
        "Uruguay",
        "Uzbekistán",
        "Vanuatu",
        "Venezuela",
        "Vietnam",
        "Yemen",
        "Yibuti",
        "Zambia",
        "Zimbabue"
      ],
      optionsEstados: [
        { text: "Aguascalientes", value: "Aguascalientes" },
        { text: "Baja California", value: "Baja California" },
        { text: "Baja California Sur", value: "Baja California Sur" },
        { text: "Campeche", value: "Campeche" },
        { text: "Chiapas", value: "Chiapas" },
        { text: "Chihuahua", value: "Chihuahua" },
        { text: "Coahuila", value: "Coahuila" },
        { text: "Colima", value: "Colima" },
        { text: "Ciudad de México", value: "Ciudad de México" },
        { text: "Durango", value: "Durango" },
        { text: "Estado de México", value: "Estado de México" },
        { text: "Guanajuato", value: "Guanajuato" },
        { text: "Guerrero", value: "Guerrero" },
        { text: "Hidalgo", value: "Hidalgo" },
        { text: "Jalisco", value: "Jalisco" },
        { text: "Michoacán ", value: "Michoacán " },
        { text: "Morelos", value: "Morelos" },
        { text: "Nayarit", value: "Nayarit" },
        { text: "Nuevo León", value: "Nuevo León" },
        { text: "Oaxaca", value: "Oaxaca" },
        { text: "Puebla", value: "Puebla" },
        { text: "Querétaro", value: "Querétaro" },
        { text: "Quintana Roo", value: "Quintana Roo" },
        { text: "San Luis Potosí", value: "San Luis Potosí" },
        { text: "Sinaloa", value: "Sinaloa" },
        { text: "Sonora", value: "Sonora" },
        { text: "Tabasco", value: "Tabasco" },
        { text: "Tamaulipas", value: "Tamaulipas" },
        { text: "Tlaxcala", value: "Tlaxcala" },
        { text: "Veracruz ", value: "Veracruz " },
        { text: "Yucatán", value: "Yucatán" },
        { text: "Zacatecas", value: "Zacatecas" }
      ],

      form: {
        denominacion_social: null,
        rfc: null,
        email: null,
        fecha_constitucion: null,
        nacionalidades: [],

        actividad: null,
        giro: null,
        objeto_social: null,
        instrumento_numero: null,
        instrumento_numero_fecha: null,
        instrumento_fedatario: null,
        instrumento_fedatario_numero: null,
        instrumento_fedatario_estado: null,
        instrumento_rpf_folio: null,
        instrumento_rpf_folio_fecha: null,
        instrumento_rpf_folio_lugar: null
      }
    };
  }
};
</script>
<style>
.orange-btn {
  background-color: #ea640b !important;
  border-color: #ea640b !important;
  color: white !important;
}

/* .btnForm {
  cursor: pointer;
  display: block;
  float: right;
  z-index: 3;
  position: absolute;
  right: 5px;
  top: 5px;
} */
.orange-btn:hover {
  background-color: #e87b31 !important;
  border-color: #e87b31 !important;
  color: white !important;
}

.orange-btn:active {
  background-color: #ea640b !important;
  border-color: #ea640b !important;
  color: white !important;
}

#card {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* color: #9a9a9a; */
  color: #ea640b;
  font-size: 13px;
}

.bottom {
  position: fixed;
  right: 2%;
  bottom: 5%;
  overflow: visible;
  z-index: 100;
}
</style>
