<template>
  <div class="contact-us full-screen">
    <div class="container">
      <div v-if="loading">
        <div id="loader">
          <font-awesome-icon icon="spinner" spin />
        </div>
      </div>
    </div>

    <TopNavbar />
    <div class="wrapper wrapper-full-page section content">
      <div class="container">
        <h4 slot="header" class="card-title">Recuperación de contraseña</h4>

        <card id="card">
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(enviar)">
              <Form :user="user" @submit.prevent />
              <!-- @onSubmit="enter()" -->
            </form>
          </ValidationObserver>
        </card>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Card from "src/components/Cards/Card.vue";
import Footer from "@/layout/ContentFooter.vue";
import TopNavbar from "@/layout/TopNavbar.vue";
import Form from "@/views/recuperacion/Form.vue";
import { RecuperarPassApi } from "@/api";

export default {
  name: "RecuperacionPass",

  components: {
    Card,
    TopNavbar,
    Footer,
    Form,
  },

  data() {
    return {
      loading: false,
      user: {
        email: null,
      },
      // role: "",
    };
  },

  methods: {
    async enviar() {
      this.loading = true;
      var data = {
        email: this.user.email,
      };
      try {
        await RecuperarPassApi.recuperarPass(data);
        this.$toast.success(
          "Le llegará un mail de recuperación de contraseña si está registrado",
          {
            timeout: false, //10000, //
            hideProgressBar: false,
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: "button",
            icon: true,
          }
        );
        this.loading = false;
      } catch {
        this.$toast.error("Sucedió un error, intente más tarde", {
          timeout: 15000, //false, //
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true,
        });
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
#card {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* color: #9a9a9a; */
  color: #ea640b;
  font-size: 13px;
}
</style>
