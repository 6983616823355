<template>
  <div class="content">
    <b-breadcrumb>
      <b-breadcrumb-item active>Configuraciones</b-breadcrumb-item>
    </b-breadcrumb>
    <div class="container">
      <div v-if="loading">
        <div id="loader">
          <font-awesome-icon icon="spinner" spin />
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div>
        <b-card no-body>
          <b-tabs content-class="mt-3" fill>
            <b-tab title="Catálogo de gastos" active
              ><CatGastos :loading="loading" />
            </b-tab>
            <!-- <b-tab title="Catálogo de corredores"><CatCorredores /></b-tab> -->
            <b-tab title="Layouts de emails">
              <LayoutsDeEmails
                :layout="layout"
                @getLayout="getLayout($event, layout.id)"
                @deleteFile="deleteFile($event, layout.id)"
                @saveLayout="saveLayout($event, layout.id)"
              />
            </b-tab>

            <b-tab title="Cupones">
              <Cupones />
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
// import Card from "src/components/Cards/Card.vue";
// import LayoutEmail from "src/views/staff/settings/LayoutEmail.vue";
import LayoutsDeEmails from "src/views/staff/settings/LayoutsDeEmails.vue";
import CatGastos from "src/views/staff/settings/CatGastos.vue";
import Cupones from "src/views/staff/settings/Cupones.vue";

import { LayoutsApi } from "@/api";
import { FuncionesApi } from "@/api";

export default {
  name: "Settings",

  data: () => ({
    loading: false,

    layout: {
      body: null,
      file: null,
      header: null,
      // footer: null,
      subject: null,
      id: null
    }
  }),

  watch: {
    "layout.file": "addFile"
  },

  components: {
    // Card,
    // LayoutEmail,
    LayoutsDeEmails,
    CatGastos,
    Cupones
  },

  created() {
    this.getContent();
  },

  methods: {
    async getContent() {
      this.loading = true;
      try {
        const { id, header, body, footer, subject } = await LayoutsApi.detail(
          1
        );
        this.layout.header = header;
        this.layout.body = body;
        this.layout.footer = footer;
        this.layout.subject = subject;
        this.layout.id = id;
        if (header != null) {
          // this.layout.file = "s";
        }

        this.loading = false;
      } catch {
        this.loading = false;
      }
    },

    async getLayout(id) {
      this.loading = true;
      try {
        const { header, body, footer, subject } = await LayoutsApi.detail(id);
        this.layout.header = header;
        this.layout.body = body;
        this.layout.footer = footer;
        this.layout.subject = subject;
        this.layout.id = id;

        this.loading = false;
      } catch {
        this.loading = false;
      }
    },

    async addFile() {
      this.loading = true;
      try {
        const formData = new FormData();
        formData.append("file", this.layout.file);
        const value = await FuncionesApi.file(
          this.layout.id,
          "layouts",
          formData,
          {}
        );
        this.layout.header = value;
      } catch (error) {
        //
      }
      this.loading = false;
    },

    async deleteFile(id) {
      this.loading = true;

      var data = {
        url: this.layout.header,
        id: this.layout.id
      };
      try {
        await FuncionesApi.deleteFile("layouts", data, {});
        // this.layout.header = null;
        // this.layout.file = null;
        this.layout.header = null;
        this.layout.file = null;
      } catch (error) {
        //
      }
      this.loading = false;
    },

    async containsUrlLoginStr() {
      const contains =
        '<a data-fr-linked="true" href="{urlLogin}">{urlLogin}</a>'; //"{urlLogin}";
      var result = this.layout.body.includes(contains);
      return result;
    },

    async containsPasswordStr() {
      const contains = "{password}";
      var result = this.layout.body.includes(contains);
      return result;
    },

    async containsAdminNameStr() {
      const contains = "{adminName}";
      var result = this.layout.body.includes(contains);
      return result;
    },

    async containsUrlVerificacionStr() {
      const contains = '<a href="{urlVerificacion}">{urlVerificacion}</a>';
      var result = this.layout.body.includes(contains);
      return result;
    },

    async containsUrlRecuperacionStr() {
      const contains =
        '<a href="{urlRecuperacion}" style="user-select: auto;">{urlRecuperacion}</a>';
      var result = this.layout.body.includes(contains);
      return result;
    },

    async saveLayout() {
      this.loading = true;
      if (this.layout.id === 1) {
        var containsUrl = await this.containsUrlLoginStr();
        if (!containsUrl) {
          this.$toast.error("El cuerpo debe contener: {urlLogin}", {
            timeout: 6000, //false,
            hideProgressBar: false,
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: "button",
            icon: true
          });
          this.loading = false;
          return;
        }

        var containsPass = await this.containsPasswordStr();
        if (!containsPass) {
          this.$toast.error("El cuerpo debe contener: {password}", {
            timeout: 6000, //false,
            hideProgressBar: false,
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: "button",
            icon: true
          });
          this.loading = false;
          return;
        }
        this.saveLayoutGo();
      } else if (this.layout.id === 3) {
        var containsAdminNa = await this.containsAdminNameStr();
        if (!containsAdminNa) {
          this.$toast.error("El cuerpo debe contener: {adminName}", {
            timeout: 6000, //false,
            hideProgressBar: false,
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: "button",
            icon: true
          });
          this.loading = false;
          return;
        }
        var containsUrl = await this.containsUrlLoginStr();
        if (!containsUrl) {
          this.$toast.error("El cuerpo debe contener: {urlLogin}", {
            timeout: 6000, //false,
            hideProgressBar: false,
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: "button",
            icon: true
          });
          this.loading = false;
          return;
        }
        var containsPass = await this.containsPasswordStr();
        if (!containsPass) {
          this.$toast.error("El cuerpo debe contener: {password}", {
            timeout: 6000, //false,
            hideProgressBar: false,
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: "button",
            icon: true
          });
          this.loading = false;
          return;
        }
        this.saveLayoutGo();
      } else if (this.layout.id === 4) {
        var containsVerif = await this.containsUrlVerificacionStr();
        if (!containsVerif) {
          this.$toast.error("El cuerpo debe contener: {urlVerificacion}", {
            timeout: 6000, //false,
            hideProgressBar: false,
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: "button",
            icon: true
          });
          this.loading = false;
          return;
        }
        this.saveLayoutGo();
      } else if (this.layout.id === 5) {
        var containsRecu = await this.containsUrlRecuperacionStr();
        if (!containsRecu) {
          this.$toast.error("El cuerpo debe contener: {urlRecuperacion}", {
            timeout: 6000, //false,
            hideProgressBar: false,
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: "button",
            icon: true
          });
          this.loading = false;
          return;
        }
        this.saveLayoutGo();
      } else {
        this.saveLayoutGo();
      }
    },

    async saveLayoutGo() {
      this.loading = true;
      var data = {
        tipo: this.layout.id,
        header: this.layout.header,
        body: this.layout.body,
        footer: "",
        subject: this.layout.subject,
        activo: 1
      };
      try {
        await LayoutsApi.edit(this.layout.id, data);
        this.$toast.success("Plantilla actualizada", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
        this.loading = false;
      } catch (error) {
        this.$toast.error("Sucedió un error, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
        this.loading = false;
      }
      this.loading = false;
    }
  }
};
</script>

<style scoped>
/* .subtitle {
  font-size: 18px;
} */
.orange-btn-line {
  background-color: white !important;
  border-color: #ea640b !important;
  color: #ea640b !important;
}
.orange-btn-line:hover {
  background-color: #e87b31 !important;
  border-color: #e87b31 !important;
  color: white !important;
}
</style>
