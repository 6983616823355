<template>
  <div>
    <div class="row">
      <div class="col-md-4">
        <ValidationProvider name="nombre" rules="required" v-slot="{ errors }"
          :custom-messages="{ required: 'El nombre es obligatorio' }">
          <base-input type="text" label="Nombre(s)" placeholder="Nombre(s)" v-model="currentVigilancia.nombre">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <ValidationProvider name="paterno" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El apellido paterno es obligatorio',
        }">
          <base-input type="text" label="Apellido Paterno" placeholder="Apellido Paterno"
            v-model="currentVigilancia.paterno">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <!-- <ValidationProvider
          name="lastNameM"
          rules="required"
          v-slot="{ errors }"
          :custom-messages="{
            required: 'El apellido materno es obligatorio',
          }"
        > -->
        <base-input type="text" label="Apellido Materno" placeholder="Apellido Materno"
          v-model="currentVigilancia.materno">
        </base-input>
        <!-- <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider> -->
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <ValidationProvider name="cargo" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El Cargo es obligatorio',
        }">
          <label for="">Cargo</label>
          <select class="custom-select" v-model="currentVigilancia.cargo">
            <option v-for="option in options" :key="option.value">
              {{ option.text }}
            </option>
          </select>

          <!-- <base-input
            type="text"
            label="Cargo"
            placeholder="Cargo"
            v-model="currentVigilancia.cargo"
          >
          </base-input> -->

          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-6" v-if="complete">
        <ValidationProvider name="rfc" rules="required|length:13" v-slot="{ errors }" :custom-messages="{
          required: 'El RFC es obligatorio',
          length: 'Debe tener 13 caracteres',
        }">
          <base-input type="text" label="RFC" placeholder="RFC" v-model="currentVigilancia.rfc">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="text-center">
      <button type="submit" class="btn btn-fill float-right orange-btn">
        Guardar
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentVigilancia: { type: Object },
    complete: { type: Boolean },
  },

  watch: {
    "currentVigilancia.rfc": function (value) {
      if (!value) {
        this.currentVigilancia.rfc = "";

        return;
      }

      this.currentVigilancia.rfc = this.currentVigilancia.rfc.toUpperCase();
    },
  },

  data() {
    return {
      options: [{ value: "Comisario", text: "Comisario" }],

      form: {
        nombre: null,
        paterno: null,
        materno: null,
        rfc: null,
        cargo: null,
      },
    };
  },

  methods: {
    // calcularCapitales() {
    //   this.$emit("calcular", "");
    // },
  },
};
</script>

<style scoped>
.cardErrors {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* color: #9a9a9a; */
  color: #ea640b;
  font-size: 13px;
}
</style>
