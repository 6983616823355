var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('h4',{staticClass:"cardD"},[_vm._v("Documentos")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.formatDiligencia())}}},[_c('div',{staticClass:"row mt-1"},[_c('b-table',{ref:"table",attrs:{"hover":"","fields":_vm.fieldsDocumentos,"items":_vm.documentos_principales},scopedSlots:_vm._u([{key:"cell(fecha)",fn:function({ item }){return [_c('base-input',{staticClass:"date-input",attrs:{"id":"fecha","type":"date","disabled":_vm.ratificacion.validaDiligencia == 1},model:{value:(item.fecha),callback:function ($$v) {_vm.$set(item, "fecha", $$v)},expression:"item.fecha"}})]}},{key:"cell(anexosdigitales)",fn:function({ item }){return [(item.anexosdigitales > 0)?_c('div',[_vm._v(" SI ")]):_c('div',[_vm._v(" NO ")])]}},{key:"cell(tiene_idioma_extranjero)",fn:function({ item }){return [_c('div',{staticClass:"col-6"},[_c('b-form-radio',{attrs:{"name":`radios-${item.id}`,"value":"1","disabled":_vm.ratificacion.validaDiligencia == 1},model:{value:(item.idioma_extranjero),callback:function ($$v) {_vm.$set(item, "idioma_extranjero", $$v)},expression:"item.idioma_extranjero"}},[_vm._v(" Sí ")])],1),_c('div',{staticClass:"col-6"},[_c('b-form-radio',{attrs:{"name":`radios-${item.id}`,"value":"0","disabled":_vm.ratificacion.validaDiligencia == 1},model:{value:(item.idioma_extranjero),callback:function ($$v) {_vm.$set(item, "idioma_extranjero", $$v)},expression:"item.idioma_extranjero"}},[_vm._v(" No ")])],1)]}}],null,true)})],1),_c('div',{staticClass:"row mt-2"}),_c('h4',{staticClass:"cardD"},[_vm._v("Preguntas")]),_c('div',{staticClass:"row mt-1"}),_c('div',{staticClass:"row mt-1"},[_c('div',{staticClass:"col-12"},[_c('ValidationProvider',{attrs:{"name":"todas_firmaron","rules":"required","custom-messages":{
            required: 'Este campo es obligatorio.'
          }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"label",attrs:{"for":"todas_firmaron"}},[_vm._v("¿Todas las personas que firmaron el documento, ¿ratifican el mismo? ")]),_c('div',{staticClass:"row mt-2"},[_c('b-form-radio',{attrs:{"name":"radios_todos_firmaron","value":"1","disabled":_vm.ratificacion.validaDiligencia == 1},model:{value:(_vm.todos_firmaron),callback:function ($$v) {_vm.todos_firmaron=$$v},expression:"todos_firmaron"}},[_vm._v(" Sí ")])],1),_c('div',{staticClass:"row mt-1"},[_c('b-form-radio',{attrs:{"name":"radios_todos_firmaron","value":"0","disabled":_vm.ratificacion.validaDiligencia == 1},model:{value:(_vm.todos_firmaron),callback:function ($$v) {_vm.todos_firmaron=$$v},expression:"todos_firmaron"}},[_vm._v(" No ")])],1),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),(_vm.todos_firmaron == '0')?_c('div',{staticClass:"row mt-1"},[_c('div',{staticClass:"col-12"},[_c('ValidationProvider',{attrs:{"rules":"required","custom-messages":{
            required: 'Este campo es requerido.',
          }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"label",attrs:{"for":"reporte_fotografico"}},[_vm._v("¿Quiénes ratifican?")]),_c('base-input',{attrs:{"type":"text","disabled":""},model:{value:(_vm.firmantes_si_ratificaron),callback:function ($$v) {_vm.firmantes_si_ratificaron=$$v},expression:"firmantes_si_ratificaron"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]):_vm._e(),(_vm.todos_firmaron == '0')?_c('div',{staticClass:"row mt-1"},[_c('div',{staticClass:"col-12"},[_c('ValidationProvider',{attrs:{"rules":"required","custom-messages":{
            required: 'Este campo es requerido.',
          }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"label",attrs:{"for":"reporte_fotografico"}},[_vm._v("¿Quiénes NO ratifican?")]),_c('base-input',{attrs:{"type":"text","placeholder":"Separados por comas","disabled":_vm.ratificacion.validaDiligencia == 1},model:{value:(_vm.ratificacion.firmantes_no_ratificaron),callback:function ($$v) {_vm.$set(_vm.ratificacion, "firmantes_no_ratificaron", $$v)},expression:"ratificacion.firmantes_no_ratificaron"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]):_vm._e(),_c('div',{staticClass:"col-6"},[_c('ValidationProvider',{attrs:{"name":"numero_ejemplares","rules":"required","custom-messages":{
          required: 'Este campo es obligatorio.'
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"label",attrs:{"for":"numero_ejemplares"}},[_vm._v("¿Cuántos ejemplares se solicitaron? ")]),_c('base-input',{staticClass:"custom-text",attrs:{"type":"number","disabled":""},model:{value:(_vm.documentos_entregables),callback:function ($$v) {_vm.documentos_entregables=$$v},expression:"documentos_entregables"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col-6"},[_c('ValidationProvider',{attrs:{"name":"inscripcion_rug","rules":"required","custom-messages":{
          required: 'Este campo es obligatorio.'
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"label",attrs:{"for":"inscripcion_rug"}},[_vm._v("¿Se inscribe en el RUG? ")]),_c('base-input',{staticClass:"custom-text",attrs:{"type":"text","disabled":""},model:{value:(_vm.inscripcion_rug),callback:function ($$v) {_vm.inscripcion_rug=$$v},expression:"inscripcion_rug"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col-6"},[_c('ValidationProvider',{attrs:{"name":"monto_garantia","rules":"required","custom-messages":{
          required: 'Este campo es obligatorio.'
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"label",attrs:{"for":"monto_garantia"}},[_vm._v("Monto que se garantiza e inscribe ")]),_c('base-input',{staticClass:"custom-text",attrs:{"type":"number","disabled":""},model:{value:(_vm.ratificacion.monto_garantia),callback:function ($$v) {_vm.$set(_vm.ratificacion, "monto_garantia", $$v)},expression:"ratificacion.monto_garantia"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('br'),_c('br'),_c('div',{staticClass:"mt-4"},[_c('b-button',{staticClass:"btn-fill orange-btn bottom2",attrs:{"type":"submit","size":"m","disabled":_vm.ratificacion.validaDiligencia == 1 || !_vm.allFieldsCompleted}},[_vm._v("Validar")])],1)])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }