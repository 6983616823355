<template>
  <div class="container">
    <h4 class="cardD">Cita para firma</h4>

    <div>
      <ValidationProvider name="fecha_firma" rules="required" v-slot="{ errors }"
        :custom-messages="{ required: 'El link es obligatorio' }">

        <b-form-group label="Link cita Calendly" label-for="input-link-cita">
          <b-input-group class="mt-3">
            <b-input-group-append>
              <b-button :variant="isTextCopied ? 'success' : 'secondary'" squared
                v-b-tooltip.hover title="Copiar" @click="copyLinkToClipboard">
                <b-icon v-if="isTextCopied" icon="check"></b-icon>
                <b-icon v-else icon="clipboard"></b-icon>
              </b-button>
            </b-input-group-append>
            <b-form-input id="input-link-cita" type="link" v-model="constitutiva.link_calendario"
              placeholder="Link Calendly"></b-form-input>
          </b-input-group>
        </b-form-group>

        <!-- <base-input type="text" label="Link cita Calendly" placeholder="Link Calendly"
          v-model="constitutiva.link_calendario">
        </base-input> -->

        <span class="cardErrors">{{ errors[0] }}</span>
      </ValidationProvider>
    </div>

    <div v-if="!constitutiva.firma_oficina" class="mt-3">
      <ValidationProvider name="direccion_firma" rules="required" v-slot="{ errors }" :custom-messages="{
        required: 'La dirección es obligatoria'
      }">
        <!-- :disabled="constitutiva.disabledCita" -->
        <base-input type="text" label="Dirección" placeholder="Dirección donde será la firma"
          v-model="constitutiva.direccion_firma">
        </base-input>
        <span class="cardErrors">{{ errors[0] }}</span>
      </ValidationProvider>
    </div>

    <div class="mt-4">
      <div class="">
        <b-button type="submit" class="btn-fill orange-btn bottom2" size="m">Enviar a cliente</b-button>
      </div>
    </div>

    <div class="row mt-4 fecha">
      <div class="col-3">
        <label class="">Fecha seleccionada</label>
      </div>
      <div class="col-9">
        {{ fecha_formatted }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TabCitaFirma",

  props: {
    constitutiva: { type: Object }
  },

  created() {
    if (this.constitutiva.fecha_firma) {
      let date = new Date(this.constitutiva.fecha_firma);
      let hour = this.constitutiva.fecha_firma.substring(11, 16);
      this.fecha_formatted = date.toLocaleDateString();
      this.fecha_formatted += " ";
      this.fecha_formatted += hour;
    }
  },

  data: () => ({
    isTextCopied: false,
    fecha_formatted: null
  }),

  methods: {
    validar() {
      this.$emit("validaForm", "CitaFirma");
    },

    copyLinkToClipboard() {
      if (!this.constitutiva.link_calendario) {
        return;
      }

      navigator.clipboard
        .writeText(this.constitutiva.link_calendario)
        .then(this.isTextCopied = true);
    }
  }
};
</script>

<style scoped>
.blue {
  background-color: #effbff;
  border-color: #effbff !important;
  text-align: justify;
}

.bottom2 {
  /* position: fixed;
  right: 2%;
  bottom: 5%;
  overflow: visible;
  z-index: 100; */
  float: right;
}

.fecha {
  margin-top: 60px !important;
}
</style>
