<template>
  <div class="content">
    <div class="container">
      <div v-if="loading">
        <div id="loader">
          <font-awesome-icon icon="spinner" spin />
        </div>
      </div>
    </div>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog" hide-footer id="modal-confirm-dialog">
      <h4 class="text-center">¿Seguro que quiere eliminar el gasto?</h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="deleteGasto">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="gasto-modal" title="" hide-footer id="modalC" size="m">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(putGasto)">
          <ModalGasto :currentGasto="currentGasto" @submit.prevent />
        </form>
      </ValidationObserver>
    </b-modal>

    <div class="container-fluid">
      <div class="row">
        <div class="col-4">
          <base-input v-model="searchGasto" placeholder="Buscar"></base-input>
        </div>
        <div class="col-4"></div>
        <div class="col-4">
          <b-button type="submit" class="btn-fill float-right orange-btn" @click="addGasto">
            Nuevo gasto
          </b-button>
        </div>
      </div>

      <b-table hover :fields="fieldsGastos" :items="gastos">
        <template #cell(index)="{ index }">
          {{ getIndex(index + 1) }}
        </template>
        <template #cell(acciones)="{ item }">
          <b-button variant="warning" size="sm" v-b-tooltip.hover title="Editar" @click="editGasto(item)"
            class="editBtn">
            <b-icon-pencil />
          </b-button>

          <b-button v-if="item.clave === null" variant="danger" size="sm" v-b-tooltip.hover title="Editar"
            @click="predeleteGasto(item.id)" class="editBtn">
            <b-icon-trash />
          </b-button>
        </template>
      </b-table>
      <div class="d-flex justify-content-between pages">
        <b-pagination v-model="currentPage" :per-page="perPage" :total-rows="total" />

        <span>Mostrando {{ gastos.length }} registros de {{ total }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { CatGastosApi } from "@/api";
import ModalGasto from "@/views/staff/settings/modales/ModalGasto.vue";

export default {
  name: "CatGastos",

  created() {
    this.getGastos();
  },

  components: {
    ModalGasto
  },

  watch: {
    currentPage: "getGastos",
    searchGasto: "getGastos"
  },

  computed: {
    fieldsGastos() {
      let fieldsGastos = [
        { key: "index", label: "", class: "text-center" },
        { key: "servicio", label: "Servicio", class: "text-center" },
        { key: "costo", label: "Costo", class: "text-center" },
        { key: "proceso", label: "Proceso", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" }
      ];
      return fieldsGastos;
    }
  },

  data() {
    return {
      loading: true,
      searchGasto: null,
      currentPage: 1,
      perPage: 10,
      total: 0,
      gastos: [],
      currentGasto: null,
      currentGastoId: null
    };
  },

  methods: {
    getIndex(index) {
      return index + (this.currentPage - 1) * this.perPage;
    },

    async getGastos() {
      this.loading = true;
      try {
        const {
          odata_page: page,
          odata_total: total,
          odata_perpage: perPage,
          value
        } = await CatGastosApi.list({
          page: this.currentPage,
          data: this.searchGasto,
          registersPerPage: this.perPage
        });

        this.currentPage = page;
        this.total = total;

        for (let i = 0; i < value.length; i++) {
          if (value[i].cat_servicios_id == "1")
            value[i].proceso = "Constitución de sociedades";
          else if (value[i].cat_servicios_id == "2")
            value[i].proceso = "Notificaciones";
          else if (value[i].cat_servicios_id == "3")
            value[i].proceso = "Formalización de actas";
          else if (value[i].cat_servicios_id == "4")
            value[i].proceso = "Copias certificadas";
          else if (value[i].cat_servicios_id == "5")
            value[i].proceso = "Fes de hechos";
          else if (value[i].cat_servicios_id == "6")
            value[i].proceso = "Ratificación de firmas";
          else if (value[i].cat_servicios_id == "7")
            value[i].proceso = "Avalúos de mejoras";
        }

        this.gastos = value;
      } catch { }
      this.loading = false;
    },

    addGasto() {
      this.$refs["gasto-modal"].show();
      this.currentGasto = {};
    },

    editGasto(item) {
      this.$refs["gasto-modal"].show();
      this.currentGasto = item;
    },

    async putGasto() {
      this.loading = true;
      if (!this.currentGasto.id) {
        try {
          let cat_servicios_id = 0;
          if (this.currentGasto.proceso == "Constitución de sociedades") {
            cat_servicios_id = 1;
          } else if (this.currentGasto.proceso == "Notificaciones") {
            cat_servicios_id = 2;
          } else if (this.currentGasto.proceso == "Formalización de actas") {
            cat_servicios_id = 3;
          } else if (this.currentGasto.proceso == "Copias certificadas") {
            cat_servicios_id = 4;
          } else if (this.currentGasto.proceso == "Fes de hechos") {
            cat_servicios_id = 5;
          } else if (this.currentGasto.proceso == "Ratificación de firmas") {
            cat_servicios_id = 6;
          } else if (this.currentGasto.proceso == "Avalúos de mejoras") {
            cat_servicios_id = 7;
          }

          var data = {
            servicio: this.currentGasto.servicio,
            costo: this.currentGasto.costo,
            staff_id: 19,
            cat_servicios_id: cat_servicios_id,
            activo: 1
          };

          let { id } = await CatGastosApi.create(data);
          this.currentGasto.id = id;
          this.currentGasto.cat_servicios_id = cat_servicios_id;
          this.gastos.push(this.currentGasto);

          this.$toast.success("Gasto agregado", {
            timeout: 4000, //false,
            hideProgressBar: false,
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: "button",
            icon: true
          });
          this.total++;
          this.loading = false;
        } catch {
          this.$toast.error("Sucedió un error, intente más tarde", {
            timeout: 4000, //false,
            hideProgressBar: false,
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: "button",
            icon: true
          });
          this.loading = false;
        }
      } else {
        var data = {
          servicio: this.currentGasto.servicio,
          costo: this.currentGasto.costo,
          // proceso: this.currentGasto.proceso,
          staff_id: 19,
          cat_servicios_id: this.currentGasto.cat_servicios_id,
          activo: 1
        };
        try {
          await CatGastosApi.edit(this.currentGasto.id, data);
          // this.id = id;
          this.$toast.success("Gasto guardado", {
            timeout: 4000, //false,
            hideProgressBar: false,
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: "button",
            icon: true
          });
          this.loading = false;
        } catch {
          this.$toast.error("Sucedió un error, intente más tarde", {
            timeout: 4000, //false,
            hideProgressBar: false,
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: "button",
            icon: true
          });
          this.loading = false;
        }
      }
      this.$refs["gasto-modal"].hide();
    },

    predeleteGasto(id) {
      this.currentGastoId = id;
      this.$refs["modal-confirm-dialog"].show();
    },

    async deleteGasto() {
      this.loading = true;
      try {
        let id = this.currentGastoId;
        await CatGastosApi.delete(id);
        var index = this.gastos.findIndex(function (o) {
          return o.id === id;
        });
        this.gastos.splice(index, 1);
        this.total--;

        this.$toast.success("Gasto eliminado", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
        this.loading = false;
        this.$refs["modal-confirm-dialog"].hide();
      } catch {
        this.loading = false;
        this.$refs["modal-confirm-dialog"].hide();
        this.$toast.error("Sucedió un error, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
      }
    }
  }
};
</script>

<style scoped>
.editBtn {
  margin-right: 10px;
}
</style>
