// Utils
import request from '@/utils/request';

/**
 * Base CRUD operations for the provided endpoint.
 */
export default class BaseApi {
  constructor(baseUri) {
    this.baseUri = baseUri;
    this.request = request;
    this.pagination = {
      page: 1,
      registersPerPage: 10,
    };
    this.paginationCatalogues = {
      page: 0,
      registersPerPage: 0,
    };
  }

  list(params = {}) {
    return this.request.get(this.baseUri, {
      params: {
        ...this.pagination,
        ...params,
      },
    });
  }

  create(data, params = {}) {
    return this.request.post(this.baseUri, data, {
      params: {
        ...params,
      },
    });
  }

  detail(id, params = {}) {
    return this.request.get(`${this.baseUri}/${id}`, {
      params: {
        ...params,
      },
    });
  }

  edit(id, data, params = {}) {
    return this.request.put(`${this.baseUri}/${id}`, data, {
      params: {
        ...params,
      },
    });
  }

  delete(id, params = {}) {
    return this.request.delete(`${this.baseUri}/${id}`, {
      params: {
        ...params,
      },
    });
  }
}
