<template>
  <div class="container">
    <h4 class="cardD">Datos generales de los Órganos Corporativos</h4>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog" hide-footer id="modal-confirm-dialog">
      <h4 class="text-center">
        ¿Seguro que quiere eliminar al administrador o gerente?
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="deleteAdministrador">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="administrador-modal" title="" hide-footer id="modalC" size="xl">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(putAdministrador)">
          <ModalOrganosAdministradores :currentAdministrador="currentAdministrador" @submit.prevent :complete="false" />
        </form>
      </ValidationObserver>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog-vigilancia" hide-footer
      id="modal-confirm-dialog-vigilancia">
      <h4 class="text-center">
        ¿Seguro que quiere eliminar al miembro de vigilancia?
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger"
        @click="$bvModal.hide('modal-confirm-dialog-vigilancia')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="deleteVigilancia">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="vigilancia-modal" title="" hide-footer id="modalV" size="xl">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(putVigilancia)">
          <ModalOrganosVigilancia :currentVigilancia="currentVigilancia" @submit.prevent :complete="false" />
        </form>
      </ValidationObserver>
    </b-modal>

    <div class="row mt-4">
      <div class="col-md-12">
        <ValidationProvider name="tipo_administracion" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El tipo de administración es obligatorio'
        }">
          <label for="">Administración de la Sociedad</label>
          <select :disabled="constitutiva.disabledOrganos" class="custom-select"
            v-model="constitutiva.tipo_administracion" v-if="constitutiva.tipo_societario === 'SA'">
            <option v-for="option in optionsSa" :key="option.value">
              {{ option.text }}
            </option>
          </select>
          <select :disabled="constitutiva.disabled" class="custom-select" v-model="constitutiva.tipo_administracion"
            v-else-if="constitutiva.tipo_societario === 'SAPI'">
            <option v-for="option in optionsSapi" :key="option.value">
              {{ option.text }}
            </option>
          </select>
          <select class="custom-select" v-model="constitutiva.tipo_administracion"
            v-else-if="constitutiva.tipo_societario === 'S de RL'" :disabled="constitutiva.disabledOrganos">
            <option v-for="option in optionsSrl" :key="option.value">
              {{ option.text }}
            </option>
          </select>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
        <label class="mt-2 leyenda">
          Los administradores o miembros del consejo pueden o no ser accionistas
          de la sociedad.
        </label>
      </div>
    </div>

    <div class="row">
      <div class="col-12 mt-4">
        <label v-if="
          constitutiva.tipo_societario === 'SA' ||
          constitutiva.tipo_societario === 'SAPI'
        ">Administradores</label>
        <label v-if="constitutiva.tipo_societario === 'S de RL'">Gerentes</label>
        <b-button :disabled="constitutiva.disabledOrganos" variant="success" class="btn-fill spaceL" size="sm"
          @click="addAdministrador" v-if="
            constitutiva.roleStaff &&
            (constitutiva.tipo_administracion ===
              'Consejo de Administración' ||
              (constitutiva.tipo_administracion === 'Administrador único' &&
                constitutiva.administradores.length === 0) ||
              constitutiva.tipo_administracion === 'Consejo de Gerentes' ||
              (constitutiva.tipo_administracion === 'Gerente General' &&
                constitutiva.administradores.length === 0))
          ">+</b-button>

        <b-table hover :fields="fieldsCuadro" :items="constitutiva.administradores">
          <template #cell(acciones)="{ item }">
            <b-button :disabled="constitutiva.disabledOrganos" variant="warning" size="sm" v-b-tooltip.hover
              title="Editar" class="editBtn" @click="editAdministrador(item)">
              <b-icon-pencil />
            </b-button>
            <b-button v-if="constitutiva.roleStaff" :disabled="constitutiva.disabledOrganos" variant="danger" size="sm"
              v-b-tooltip.hover title="Eliminar" @click="predeleteAdministrador(item.id)">
              <b-icon-trash />
            </b-button>
          </template>
        </b-table>
      </div>
    </div>

    <div v-if="
      constitutiva.tipo_administracion === 'Consejo de Administración' ||
      constitutiva.tipo_administracion === 'Consejo de Gerentes'
    ">
      <label class="mt-2 leyenda">
        Se recomienda tener un consejo con un número impar de miembros.
      </label>

      <div class="row mt-4">
        <div class="col-6">
          <label for="">El presidente tiene voto de calidad</label>
          <b-form-checkbox v-model="constitutiva.presidente_voto" :disabled="constitutiva.disabledOrganos">
          </b-form-checkbox>
        </div>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col md-12">
        <label for="">Comisario</label>
        <b-button v-if="constitutiva.roleStaff && constitutiva.vigilancia.length < 1"
          :disabled="constitutiva.disabledOrganos" variant="success" class="btn-fill spaceL" size="sm"
          @click="addVigilancia">
          +
        </b-button>
        <div>
          <label class="mb-12 leyenda" v-if="constitutiva.tipo_societario === 'S de RL'">
            Campo opcional
          </label>
        </div>

        <b-table hover :fields="fieldsCuadroVigilancia" :items="constitutiva.vigilancia">
          <template #cell(acciones)="{ item }">
            <b-button :disabled="constitutiva.disabledOrganos" variant="warning" size="sm" v-b-tooltip.hover
              title="Editar" class="editBtn" @click="editVigilancia(item)">
              <b-icon-pencil />
            </b-button>
            <b-button v-if="constitutiva.roleStaff" :disabled="constitutiva.disabledOrganos" variant="danger" size="sm"
              v-b-tooltip.hover title="Eliminar" @click="predeleteVigilancia(item.id)">
              <b-icon-trash />
            </b-button>
          </template>
        </b-table>

        <label class="mt-2 leyenda" v-if="constitutiva.vigilancia.length > 1">
          La cotización básica incluye solo un comisario.
        </label>

        <label class="mb-12 leyenda">
          El comisario u órgano de vigilancia no pueden ser familiares de los
          administradores ni apoderados de la sociedad.
        </label>
      </div>
    </div>

    <div class="mt-4">
      <div class="row">
        <div class="col-10">
          <b-button type="submit" class="btn-fill orange-btn bottom2" size="m"
            :disabled="constitutiva.disabledOrganos">Guardar</b-button>
        </div>
        <div class="col-2">
          <b-button variant="success" class="btn-fill bottom2" size="m" :disabled="constitutiva.disabledOrganos"
            @click="validar()">Validar</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalOrganosAdministradores from "@/views/clients/procesos/constitucion/infoBase/modales/ModalOrganosAdministradores.vue";
import ModalOrganosVigilancia from "@/views/clients/procesos/constitucion/infoBase/modales/ModalOrganosVigilancia.vue";

export default {
  name: "TabDatosOrganos",

  props: {
    constitutiva: { type: Object }
  },

  components: {
    ModalOrganosAdministradores,
    ModalOrganosVigilancia
  },

  computed: {
    fieldsCuadro() {
      let fieldsCuadro = [
        // { key: "index", label: "", class: "text-center" },
        { key: "nombre", label: "Nombre", class: "text-center" },
        { key: "paterno", label: "Paterno", class: "text-center" },
        { key: "materno", label: "Materno", class: "text-center" },
        // { key: "rfc", label: "RFC", class: "text-center" },
        { key: "cargo", label: "Cargo", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" }
      ];
      return fieldsCuadro;
    },
    fieldsCuadroVigilancia() {
      let fieldsCuadroVigilancia = [
        // { key: "index", label: "", class: "text-center" },
        { key: "nombre", label: "Nombre", class: "text-center" },
        { key: "paterno", label: "Paterno", class: "text-center" },
        { key: "materno", label: "Materno", class: "text-center" },
        // { key: "rfc", label: "RFC", class: "text-center" },
        { key: "cargo", label: "Cargo", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" }
      ];
      return fieldsCuadroVigilancia;
    }
  },

  data() {
    return {
      optionsSa: [
        { text: "Administrador único", value: "Administrador único" },
        {
          text: "Consejo de Administración",
          value: "Consejo de Administración"
        }
      ],
      optionsSapi: [
        {
          text: "Consejo de Administración",
          value: "Consejo de Administración"
        }
      ],
      optionsSrl: [
        { text: "Gerente General", value: "Gerente General" },
        { text: "Consejo de Gerentes", value: "Consejo de Gerentes" }
      ],
      currentAdministrador: null,
      currentAdministradorId: null,

      currentVigilancia: null,
      currentVigilanciaId: null,

      form: {
        tipo_administracion: null,
        administradores: [],
        presidente_voto: null,
        vigilancia: []
      }
    };
  },

  methods: {
    addAdministrador() {
      this.$refs["administrador-modal"].show();
      this.currentAdministrador = {};
      if (this.constitutiva.tipo_administracion === "Administrador único") {
        this.currentAdministrador.cargo = "Administrador único";
      }
      if (this.constitutiva.tipo_administracion === "Gerente General") {
        this.currentAdministrador.cargo = "Gerente General";
      }
    },

    editAdministrador(item) {
      this.$refs["administrador-modal"].show();
      this.currentAdministrador = item;
    },

    putAdministrador() {
      if (!this.currentAdministrador.id) {
        let id = this.constitutiva.administradores.length + 1;
        this.currentAdministrador.id = id;
        var a = Object.assign({}, this.currentAdministrador);
        this.constitutiva.administradores.push(a);
      } else {
        let index = this.constitutiva.administradores.findIndex(
          // (o) => o.id === this.currentAdministradorId
          o => o.id === this.currentAdministrador.id
        );
        var a = Object.assign({}, this.currentAdministrador);
        // this.constitutiva.administradores[index] = a;
        this.constitutiva.administradores.splice([index], 1, a);
      }
      this.$refs["administrador-modal"].hide();
    },

    predeleteAdministrador(id) {
      this.currentAdministradorId = id;
      this.$refs["modal-confirm-dialog"].show();
    },

    deleteAdministrador() {
      let index2 = this.constitutiva.administradores.findIndex(
        o => o.id === this.currentAdministradorId
      );
      this.constitutiva.administradores.splice(index2, 1);
      this.$refs["modal-confirm-dialog"].hide();
    },

    addVigilancia() {
      this.$refs["vigilancia-modal"].show();
      this.currentVigilancia = {};
    },

    editVigilancia(item) {
      this.$refs["vigilancia-modal"].show();
      this.currentVigilancia = item;
    },

    putVigilancia() {
      if (!this.currentVigilancia.id) {
        let id = this.constitutiva.vigilancia.length + 1;
        this.currentVigilancia.id = id;
        var a = Object.assign({}, this.currentVigilancia);
        this.constitutiva.vigilancia.push(a);
      } else {
        let index = this.constitutiva.vigilancia.findIndex(
          o => o.id === this.currentVigilancia.id
        );
        var a = Object.assign({}, this.currentVigilancia);
        // this.constitutiva.vigilancia[index] = a;
        this.constitutiva.vigilancia.splice([index], 1, a);
      }
      this.$refs["vigilancia-modal"].hide();
    },

    predeleteVigilancia(id) {
      this.currentVigilanciaId = id;
      this.$refs["modal-confirm-dialog-vigilancia"].show();
    },

    deleteVigilancia() {
      let index2 = this.constitutiva.vigilancia.findIndex(
        o => o.id === this.currentVigilanciaId
      );
      this.constitutiva.vigilancia.splice(index2, 1);
      this.$refs["modal-confirm-dialog-vigilancia"].hide();
    },

    validar() {
      this.$emit("validaForm", "DatosOrganos");
    }
  }
};
</script>

<style scoped>
.bottom2 {
  /* position: fixed;
  right: 2%;
  bottom: 5%;
  overflow: visible;
  z-index: 100; */
  float: right;
}

.leyenda {
  text-transform: lowercase !important;
  color: #79cff7;
}

.leyenda:first-letter {
  text-transform: capitalize;
}

.spaceL {
  margin-left: 1%;
}
</style>
