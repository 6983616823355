<template>
  <div>
    <div v-if="user === 'Staff'">
      <div class="row">
        <div class="col-10">
          <b-button
            block
            class="btn-fill blue-btn title-text"
            @click="selected('DatosSociedad')"
          >
            Entrega de información base
          </b-button>
        </div>
      </div>

      <div class="row">
        <div class="col-1">
          <div
            :class="[
              'circle',
              formalizacion.validoSociedad ? 'completado' : ''
            ]"
          >
            <span class="number"
              ><i v-if="formalizacion.validoSociedad" class="fa fa-check"></i
              ><i v-else class="fa fa-minus"></i
            ></span>
          </div>
        </div>
        <div class="col-10">
          <b-button
            block
            class="btn-fill blue-btn child"
            @click="selected('DatosSociedad')"
            :class="{ 'complete-text': formalizacion.validoSociedad }"
          >
            Datos de la Sociedad
          </b-button>
        </div>
      </div>

      <div class="row">
        <div class="col-1">
          <div
            :class="[
              'circle',
              formalizacion.validoAsamblea ? 'completado' : ''
            ]"
          >
            <span class="number"
              ><i v-if="formalizacion.validoAsamblea" class="fa fa-check"></i
              ><i v-else class="fa fa-minus"></i
            ></span>
          </div>
        </div>
        <div class="col-10">
          <b-button
            block
            class="btn-fill blue-btn child"
            @click="selected('DatosAsamblea')"
            :class="{ 'complete-text': formalizacion.validoAsamblea }"
          >
            Datos de la Asamblea
          </b-button>
        </div>
      </div>

      <!-- <div class="row">
        <div class="col-1">
          <div
            :class="['circle', constitutiva.validoOrganos ? 'completado' : '']"
          >
            <span class="number"
              ><i v-if="constitutiva.validoOrganos" class="fa fa-check"></i
              ><i v-else class="fa fa-minus"></i
            ></span>
          </div>
        </div>
        <div class="col-10">
          <b-button
            block
            class="btn-fill blue-btn"
            @click="selected('DatosOrganos')"
            :class="{ 'complete-text': constitutiva.validoOrganos }"
          >
            Datos del acta
          </b-button>
        </div>
      </div> -->

      <div class="row">
        <div class="col-1">
          <div
            :class="[
              'circle',
              formalizacion.validoDelegado ? 'completado' : ''
            ]"
          >
            <span class="number"
              ><i v-if="formalizacion.validoDelegado" class="fa fa-check"></i
              ><i v-else class="fa fa-minus"></i
            ></span>
          </div>
        </div>
        <div class="col-10">
          <b-button
            block
            class="btn-fill blue-btn child"
            @click="selected('DatosDelegado')"
            :class="{ 'complete-text': formalizacion.validoDelegado }"
          >
            Datos Delegado
          </b-button>
        </div>
      </div>

      <div class="row">
        <div class="col-1">
          <div
            :class="['circle', formalizacion.validoEntidad ? 'completado' : '']"
          >
            <span class="number"
              ><i v-if="formalizacion.validoEntidad" class="fa fa-check"></i
              ><i v-else class="fa fa-minus"></i
            ></span>
          </div>
        </div>
        <div class="col-10">
          <b-button
            block
            class="btn-fill blue-btn child"
            @click="selected('EntidadFederativa')"
            :class="{ 'complete-text': formalizacion.validoEntidad }"
          >
            Entidad Federativa firma
          </b-button>
        </div>
      </div>

      <div class="row">
        <div class="col-1">
          <div
            :class="[
              'circle',
              formalizacion.validoCotizacion ? 'completado' : ''
            ]"
          >
            <span class="number"
              ><i v-if="formalizacion.validoCotizacion" class="fa fa-check"></i
              ><i v-else class="fa fa-minus"></i
            ></span>
          </div>
        </div>
        <div class="col-10">
          <b-button
            block
            class="btn-fill blue-btn"
            @click="selected('Cotizacion')"
            :class="{ 'complete-text': formalizacion.validoCotizacion }"
          >
            Cotización
          </b-button>
        </div>
      </div>

      <div class="row">
        <div class="col-1">
          <div
            :class="[
              'circle',
              formalizacion.validoComplementa ? 'completado' : ''
            ]"
          >
            <span class="number"
              ><i v-if="formalizacion.validoComplementa" class="fa fa-check"></i
              ><i v-else class="fa fa-minus"></i
            ></span>
          </div>
        </div>
        <div class="col-10">
          <b-button
            block
            class="btn-fill blue-btn title-text"
            @click="selected('ComplementaInformacion')"
            :class="{ 'complete-text': formalizacion.validoComplementa }"
          >
            Complementa la información</b-button
          >
        </div>
      </div>

      <!-- <div class="row">
        <div class="col-10">
          <b-button
            block
            class="btn-fill blue-btn title-text"
            @click="selected('DenominacionSocial')"
          >
            Denominación social
          </b-button>
        </div>
      </div> -->
      <div class="row">
        <div class="col-1">
          <div
            :class="[
              'circle',
              formalizacion.validoDenominacion ? 'completado' : ''
            ]"
          >
            <span class="number"
              ><i
                v-if="formalizacion.validoDenominacion"
                class="fa fa-check"
              ></i
              ><i v-else class="fa fa-minus"></i
            ></span>
          </div>
        </div>
        <div class="col-10">
          <b-button
            block
            class="btn-fill blue-btn title-text"
            @click="selected('DenominacionSocial')"
            :class="{ 'complete-text': formalizacion.validoDenominacion }"
          >
            Denominación social</b-button
          >
        </div>
      </div>

      <div class="row">
        <div class="col-1">
          <div
            :class="[
              'circle',
              formalizacion.validoPagoComplementario ? 'completado' : ''
            ]"
          >
            <span class="number"
              ><i
                v-if="formalizacion.validoPagoComplementario"
                class="fa fa-check"
              ></i
              ><i v-else class="fa fa-minus"></i
            ></span>
          </div>
        </div>
        <div class="col-10">
          <b-button
            block
            class="btn-fill blue-btn title-text"
            @click="selected('PagoComplementario')"
            :class="{ 'complete-text': formalizacion.validoPagoComplementario }"
          >
            Pago complementario</b-button
          >
        </div>
      </div>

      <div class="row">
        <div class="col-10">
          <b-button
            block
            class="btn-fill blue-btn title-text"
            @click="selected('AsignarCorreduria')"
          >
            Asignar correduría
          </b-button>
        </div>
      </div>

      <div class="row">
        <div class="col-10">
          <b-button
            block
            class="btn-fill blue-btn title-text"
            @click="selected('Expediente')"
            >Expediente
          </b-button>
        </div>
      </div>

      <div class="row">
        <div class="col-10">
          <b-button
            block
            class="btn-fill blue-btn"
            @click="selected('GeneraDocumento')"
            :class="{ 'complete-text': formalizacion.validoGenera }"
          >
            Genera documentos
          </b-button>
        </div>
      </div>

      <div class="row">
        <div class="col-10">
          <b-button
            block
            class="btn-fill blue-btn"
            @click="selected('DocFirmado')"
            :class="{ 'complete-text': formalizacion.validoDocListo }"
          >
            Documento firmado
          </b-button>
        </div>
      </div>

      <div class="row">
        <div class="col-1">
          <div
            :class="['circle', formalizacion.validoCita ? 'completado' : '']"
          >
            <span class="number"
              ><i v-if="formalizacion.validoCita" class="fa fa-check"></i
              ><i v-else class="fa fa-minus"></i
            ></span>
          </div>
        </div>
        <div class="col-10">
          <b-button
            block
            class="btn-fill blue-btn"
            @click="selected('CitaFirma')"
            :class="{ 'complete-text': formalizacion.validoCita }"
          >
            Agenda tu cita
          </b-button>
        </div>
      </div>

      <div class="row">
        <div class="col-1">
          <div
            :class="[
              'circle',
              formalizacion.validoTerminado ? 'completado' : ''
            ]"
          >
            <span class="number"
              ><i v-if="formalizacion.validoTerminado" class="fa fa-check"></i
              ><i v-else class="fa fa-minus"></i
            ></span>
          </div>
        </div>
        <div class="col-10">
          <b-button
            block
            class="btn-fill blue-btn"
            @click="selected('Terminado')"
            :class="{ 'complete-text': formalizacion.validoTerminado }"
          >
            Terminado
          </b-button>
        </div>
      </div>
    </div>

    <div v-else-if="user === 'Client'">
      <div class="row">
        <div class="col-12">
          <b-button
            block
            class="btn-fill blue-btn title-text"
            @click="selected('DatosSociedad')"
          >
            Entrega de información base
          </b-button>
        </div>
      </div>
      <div class="row">
        <div class="col-1">
          <div
            :class="[
              'circle',
              formalizacion.validoSociedad ? 'completado' : ''
            ]"
          >
            <span class="number"
              ><i v-if="formalizacion.validoSociedad" class="fa fa-check"></i
              ><i v-else class="fa fa-minus"></i
            ></span>
          </div>
        </div>
        <div class="col-10">
          <b-button
            block
            class="btn-fill blue-btn child"
            @click="selected('DatosSociedad')"
          >
            Datos de la Sociedad
          </b-button>
        </div>
      </div>

      <div class="row">
        <div class="col-1">
          <div
            :class="[
              'circle',
              formalizacion.validoAsamblea ? 'completado' : ''
            ]"
          >
            <span class="number"
              ><i v-if="formalizacion.validoAsamblea" class="fa fa-check"></i
              ><i v-else class="fa fa-minus"></i
            ></span>
          </div>
        </div>
        <div class="col-10">
          <b-button
            block
            class="btn-fill blue-btn child"
            @click="selected('DatosAsamblea')"
          >
            Datos de la asamblea
          </b-button>
        </div>
      </div>

      <div class="row">
        <div class="col-1">
          <div
            :class="[
              'circle',
              formalizacion.validoDelegado ? 'completado' : ''
            ]"
          >
            <span class="number"
              ><i v-if="formalizacion.validoDelegado" class="fa fa-check"></i
              ><i v-else class="fa fa-minus"></i
            ></span>
          </div>
        </div>

        <div class="col-10">
          <b-button
            block
            class="btn-fill blue-btn child"
            @click="selected('DatosDelegado')"
          >
            Datos Delegado
          </b-button>
        </div>
      </div>
      <div class="row">
        <div class="col-1">
          <div
            :class="['circle', formalizacion.validoEntidad ? 'completado' : '']"
          >
            <span class="number"
              ><i v-if="formalizacion.validoEntidad" class="fa fa-check"></i
              ><i v-else class="fa fa-minus"></i
            ></span>
          </div>
        </div>
        <div class="col-10">
          <b-button
            block
            class="btn-fill blue-btn child"
            @click="selected('EntidadFederativa')"
            :class="{ 'complete-text': formalizacion.validoEntidad }"
          >
            Entidad Federativa firma
          </b-button>
        </div>
      </div>
      <div class="row">
        <div class="col-1">
          <div
            :class="[
              'circle',
              formalizacion.validoCotizacion ? 'completado' : ''
            ]"
          >
            <span class="number"
              ><i v-if="formalizacion.validoCotizacion" class="fa fa-check"></i
              ><i v-else class="fa fa-minus"></i
            ></span>
          </div>
        </div>
        <div class="col-10">
          <b-button
            block
            class="btn-fill blue-btn child"
            @click="selected('Cotizacion')"
          >
            Cotización y pago
          </b-button>
        </div>
      </div>

      <div class="row">
        <div class="col-1">
          <div
            :class="[
              'circle',
              formalizacion.validoComplementa ? 'completado' : ''
            ]"
          >
            <span class="number"
              ><i v-if="formalizacion.validoComplementa" class="fa fa-check"></i
              ><i v-else class="fa fa-minus"></i
            ></span>
          </div>
        </div>
        <div class="col-10">
          <b-button
            block
            class="btn-fill blue-btn"
            @click="selected('ComplementaInformacion')"
          >
            Complementa la información</b-button
          >
        </div>
      </div>

      <div class="row">
        <div class="col-1">
          <div
            :class="[
              'circle',
              formalizacion.validoPagoComplementario ? 'completado' : ''
            ]"
          >
            <span class="number"
              ><i
                v-if="formalizacion.validoPagoComplementario"
                class="fa fa-check"
              ></i
              ><i v-else class="fa fa-minus"></i
            ></span>
          </div>
        </div>
        <div class="col-10">
          <b-button
            block
            class="btn-fill blue-btn"
            @click="selected('PagoComplementario')"
          >
            Pago complementario
          </b-button>
        </div>
      </div>

      <div class="row">
        <div class="col-1">
          <div
            :class="['circle', formalizacion.validoCita ? 'completado' : '']"
          >
            <span class="number"
              ><i v-if="formalizacion.validoCita" class="fa fa-check"></i
              ><i v-else class="fa fa-minus"></i
            ></span>
          </div>
        </div>
        <div class="col-10">
          <b-button
            block
            class="btn-fill blue-btn"
            @click="selected('FirmaDigital')"
          >
            Firma digital
          </b-button>
        </div>
      </div>
      <div class="row">
        <div class="col-1">
          <div
            :class="['circle', formalizacion.validoCita ? 'completado' : '']"
          >
            <span class="number"
              ><i v-if="formalizacion.validoCita" class="fa fa-check"></i
              ><i v-else class="fa fa-minus"></i
            ></span>
          </div>
        </div>

        <div class="col-10">
          <b-button
            block
            class="btn-fill blue-btn"
            @click="selected('CitaFirma')"
          >
            Agenda tu cita
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tree",

  props: {
    formalizacion: { type: Object },
    user: { type: String }
  },

  methods: {
    selected(node) {
      this.$emit("node", node);
    }
  }
};
</script>

<style scoped>
.invi {
  display: none !important;
}

.completado {
  background-color: yellowgreen !important;
}

.circle {
  height: 20px;
  width: 20px;
  margin-top: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: silver;
}

.icon-title {
  height: 20px;
  width: 20px;
  margin-top: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.icon-title-fa {
  font-size: 11px;
  color: black;
}

.number {
  font-size: 11px;
  color: white;
}

.title-text {
  font-weight: 600;
  color: black !important;
}

.complete-text {
  color: black !important;
}

.blue-btn {
  padding-left: 0px;
  padding-right: 0px;
  background-color: transparent !important;
  border-color: transparent !important;
  color: rgb(154, 154, 154);
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: -10px;
  text-align: left !important;
}

.blue-btn:hover {
  color: rgb(154, 154, 154);
  background-color: transparent !important;
  border-color: transparent !important;
  background-color: #effbff !important;
  border-color: #effbff !important;
}

.blue-btn:active {
  background-color: #effbff !important;
  border-color: #effbff !important;
  /* color: blue !important; */
}

.led-green {
  margin: 10px auto;
  width: 10px;
  height: 10px;
  background-color: #43eb34;
  border-radius: 50%;
  box-shadow: #257a1d 0 -1px 7px 1px, inset #460 0 -1px 9px, #7d0 0 2px 12px;
}

.led-red {
  margin: 10px auto;
  width: 10px;
  height: 10px;
  background-color: #e60505;
  border-radius: 50%;
  box-shadow: #a60903 0 -1px 7px 1px, inset #600 0 -1px 9px, #f00 0 2px 12px;
}

.led-yellow {
  margin: 10px auto;
  width: 10px;
  height: 10px;
  background-color: #f7e00a;
  border-radius: 50%;
  box-shadow: #d1ca04 0 -1px 7px 1px, inset #c4b206 0 -1px 9px, #dd0 0 2px 12px;
}
</style>
