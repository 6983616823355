<template>
  <div class="container">
    <h4 class="cardD">Pago Complementario</h4>

    <!-- <h4>
      Aquí se mostrará el link que le fue enviado a su mail por el staff de
      Correduría Digital para que realice el pago complementario de los derechos
      de acuerdo a la Entidad Federativa en la que se realiza el trámite
    </h4> -->

    <!-- <label for="">Aquí se coloca la liga en la cual realizarán el pago complentario.</label>
    <label for=""> Se enviará un mail informando al cliente.</label>
    <label for="">
      Una vez recibida la respuesta y verificado el pago por parte de staff, validar en el botón de "Validar"
    </label> -->

    <div class="mt-4">
      <ValidationProvider name="liga_pago" rules="required" v-slot="{ errors }"
        :custom-messages="{ required: 'La liga de pago es requerida' }">

        <b-form-group label="Liga de pago Openpay" label-for="input-link-pago-complementario">
          <b-input-group class="mt-3">
            <b-input-group-append>
              <b-button :variant="isTextCopied ? 'success' : 'secondary'" squared v-b-tooltip.hover title="Copiar"
                @click="copyLinkToClipboard">
                <b-icon v-if="isTextCopied" icon="check"></b-icon>
                <b-icon v-else icon="clipboard"></b-icon>
              </b-button>
            </b-input-group-append>
            <b-form-input id="input-link-pago-complementario" type="link" v-model="formalizacion.liga_pago_complementario"
              :disabled="formalizacion.disabledPagoComplementario && !forma.staff"
              placeholder="Link Openpay"></b-form-input>
          </b-input-group>
        </b-form-group>

        <span class="cardErrors">{{ errors[0] }}</span>
      </ValidationProvider>
    </div>

    <!-- <div class="row mt-4">
      <div class="col-12">
        <base-input type="text" label="Liga pago complementario" placeholder="Liga pago complementario"
          v-model="formalizacion.liga_pago_complementario" :disabled="formalizacion.disabledPagoComplementario">
        </base-input>
      </div>
    </div> -->

    <div class="mt-4">
      <div class="row">
        <div class="col-9">
          <b-button type="submit" class="btn-fill orange-btn bottom2" size="m"
            :disabled="formalizacion.disabledPagoComplementario">Enviar liga</b-button>
        </div>
        <div class="col-3">
          <b-button variant="success" class="btn-fill bottom2" size="m"
            :disabled="formalizacion.disabledPagoComplementario" @click="validar()">Marcar como pagado</b-button>
        </div>
      </div>
    </div>
    <!-- <div class="mt-4">
      <div class="">
        <b-button
          type="submit"
          class="btn-fill orange-btn bottom2"
          size="m"
          :disabled="formalizacion.disabled"
          >Continuar</b-button
        >
      </div>
    </div> -->
  </div>
</template>

<script>
// import Domicilio from "@/views/clients/comparecientes/Domicilios.vue";

export default {
  name: "PagoComplementario",

  props: {
    formalizacion: { type: Object }
  },

  components: {
    // Domicilio
  },

  created() {
    // if (this.formalizacion.sociedad_inscrita_rpc == 0) {
    //   this.sociedadInscrita = "No";
    // } else if (this.formalizacion.sociedad_inscrita_rpc == 0) {
    //   this.sociedadInscrita = "Sí";
    // }
  },

  // watch: {
  //   "formalizacion.subtipo_acta": "blockProceso",
  //   sociedadInscrita: "blockProcesoNoInscrita"
  // },

  data() {
    return {
      isTextCopied: false,
      // block: false,
      // sociedadInscrita: null,
      // blockInscrita: false
    };
  },

  methods: {
    copyLinkToClipboard() {
      if (!this.cotejo.liga_pago_complementario) {
        return;
      }

      navigator.clipboard
        .writeText(this.cotejo.liga_pago_complementario)
        .then(this.isTextCopied = true);
    },

    validar() {
      this.$emit("validaForm", "PagoComplementario");
    }
  }
};
</script>

<style scoped>
.bottom2 {
  float: right;
}

.leyendaBlock {
  /* text-transform: lowercase !important; */
  color: #2937cf;
  font-size: 15px;
}
</style>
