<template>
  <div>
    <div v-if="user === 'Staff'">
      <div class="row">
        <div class="col-1">
          <div :class="['circle', ratificacion.validaComparecientes == 1 ? 'completado' : '']">
            <span class="number"><i v-if="ratificacion.validaComparecientes == 1" class="fa fa-check"></i><i v-else
                class="fa fa-minus"></i></span>
          </div>
        </div>
        <div class="col-10">
          <b-button block class="btn-fill blue-btn" @click="selected('ValidaComparecientes')">
            Comparecientes
          </b-button>
        </div>
      </div>
        
      <div class="row">
        <div class="col-1">
          <div :class="['circle', ratificacion.validaDocumentos == 1 ? 'completado' : '']">
            <span class="number"><i v-if="ratificacion.validaDocumentos == 1" class="fa fa-check"></i><i v-else
                class="fa fa-minus"></i></span>
          </div>
        </div>

        <div class="col-10">
          <b-button block class="btn-fill blue-btn" @click="selected('ValidaDocumentos')">
            Documentos
          </b-button>
        </div>
      </div>
        
      <div class="row">
        <div class="col-1" v-if="ratificacion.firma_fuera_oficina == 1">
          <div :class="['circle', ratificacion.validaDomicilio == 1 ? 'completado' : '']">
            <span class="number"><i v-if="ratificacion.validaDomicilio == 1" class="fa fa-check"></i><i v-else
                class="fa fa-minus"></i></span>
          </div>
        </div>

        <div class="col-10" v-if="ratificacion.firma_fuera_oficina == 1">
          <b-button block class="btn-fill blue-btn" @click="selected('Domicilio')">
            Domicilio
          </b-button>
        </div>
      </div>
        
      <div class="row">
        <div class="col-1">
          <div :class="['circle', ratificacion.corredurias_id ? 'completado' : '']">
            <span class="number"><i v-if="ratificacion.corredurias_id" class="fa fa-check"></i><i v-else
                class="fa fa-minus"></i></span>
          </div>
        </div>

        <div class="col-10">
          <b-button block class="btn-fill blue-btn" @click="selected('Correduria')">
            Asignar Correduria
          </b-button>
        </div>
      </div>
        
      <div class="row">
        <div class="col-1">
          <div :class="['circle', ratificacion.validaDocumentos == 1 ? 'completado' : '']">
            <span class="number"><i v-if="ratificacion.validaDocumentos == 1" class="fa fa-check"></i><i v-else
                class="fa fa-minus"></i></span>
          </div>
        </div>

        <div class="col-10">
          <b-button block class="btn-fill blue-btn" @click="selected('EstatusDocumentos')">
            Estatus Documentos
          </b-button>
        </div>
      </div>
        
      <div class="row" v-if="ratificacion.pago_complementario">
        <div class="col-1">
          <div :class="['circle', ratificacion.estatus_id >= 39 || this.ratificacion.cotizaciones_id_complementario ? 'completado' : '']">
            <span class="number"><i v-if="ratificacion.estatus_id >= 39 || this.ratificacion.cotizaciones_id_complementario" class="fa fa-check"></i><i v-else
                class="fa fa-minus"></i></span>
          </div>
        </div>

        <div class="col-10">
          <b-button block class="btn-fill blue-btn" @click="selected('PagoComplementario')">
            Pago Complementario</b-button>
        </div>
      </div>
        
      <div class="row">
        <div class="col-1">
          <div :class="['circle', ratificacion.estatus_id >= 40 ? 'completado' : '']">
            <span class="number"><i v-if="ratificacion.estatus_id >= 40" class="fa fa-check"></i><i v-else
                class="fa fa-minus"></i></span>
          </div>
        </div>

        <div class="col-10">
          <b-button block class="btn-fill blue-btn" @click="selected('FAD')">
            FAD
          </b-button>
        </div>
      </div>
        
      <div class="row">
        <div class="col-1">
          <div :class="['circle', [41, 42, 43, 56, 70].includes(ratificacion.estatus_id) ? 'completado' : '']">
            <span class="number"><i v-if="[41, 42, 43, 56, 70].includes(ratificacion.estatus_id)" class="fa fa-check"></i><i
                v-else class="fa fa-minus"></i></span>
          </div>
        </div>
        <div class="col-10">
          <b-button block class="btn-fill blue-btn" @click="selected('Cita')">
            Cita
          </b-button>
        </div>
      </div>
        
      <div class="row" v-if="ratificacion.inscripcion_rug == 1">
        <div class="col-1">
          <div :class="['circle', [43, 56, 70].includes(ratificacion.estatus_id) ? 'completado' : '']">
            <span class="number"><i v-if="[43, 56, 70].includes(ratificacion.estatus_id)" class="fa fa-check"></i><i
                v-else class="fa fa-minus"></i></span>
          </div>
        </div>
        <div class="col-10">
          <b-button block class="btn-fill blue-btn" @click="selected('RUG')">
            RUG
          </b-button>
        </div>
      </div>

      <div class="row">
        <div class="col-1">
          <div :class="['circle', ratificacion.validaDiligencia == 1 ? 'completado' : '']">
            <span class="number"><i v-if="ratificacion.validaDiligencia == 1" class="fa fa-check"></i><i
                v-else class="fa fa-minus"></i></span>
          </div>
        </div>
        <div class="col-10">
          <b-button block class="btn-fill blue-btn" @click="selected('Preguntas')">
            Preguntas
          </b-button>
        </div>
      </div>

      <div class="row">
        <div class="col-1">
          <div :class="['circle', [43, 56].includes(ratificacion.estatus_id) ? 'completado' : '']">
            <span class="number"><i v-if="[43, 56].includes(ratificacion.estatus_id)" class="fa fa-check"></i><i v-else
                class="fa fa-minus"></i></span>
          </div>
        </div>

        <div class="col-10">
          <b-button block class="btn-fill blue-btn" @click="selected('Actas')">
            Actas
          </b-button>
        </div>
      </div>
        
      <div class="row">
        <div class="col-1">
          <div :class="['circle', [43].includes(ratificacion.estatus_id) ? 'completado' : '']">
            <span class="number"><i v-if="[43].includes(ratificacion.estatus_id)" class="fa fa-check"></i><i v-else
                class="fa fa-minus"></i></span>
          </div>
        </div>

        <div class="col-10">
          <b-button block class="btn-fill blue-btn" @click="selected('FADActa')">
            FAD Acta
          </b-button>
        </div>
      </div>
        
      <div class="row">
        <div class="col-1">
          <div :class="['circle', [43, 56].includes(ratificacion.estatus_id) ? 'completado' : '']">
            <span class="number"><i v-if="[43, 56].includes(ratificacion.estatus_id)" class="fa fa-check"></i><i v-else
                class="fa fa-minus"></i></span>
          </div>
        </div>

        <div class="col-10">
          <b-button block class="btn-fill blue-btn" @click="selected('Expediente')">
            Expediente
          </b-button>
        </div>
      </div>
        
      <div class="row">
        <div class="col-1">
          <div :class="['circle', ratificacion.estatus_id == 56 ? 'completado' : '']">
            <span class="number"><i v-if="ratificacion.estatus_id == 56" class="fa fa-check"></i><i v-else
                class="fa fa-minus"></i></span>
          </div>
        </div>
        <div class="col-10">
          <b-button block class="btn-fill blue-btn" @click="selected('Terminado')">
            Terminado
          </b-button>
        </div>
      </div>
    </div>

    <div v-if="user === 'Client'">
      <div class="row">
        <div class="col-1">
          <div :class="['circle', ratificacion.currentComponentValues.Pago ? 'completado' : '']">
            <span class="number"><i v-if="ratificacion.currentComponentValues.Pago" class="fa fa-check"></i><i
                v-else class="fa fa-minus"></i></span>
          </div>
        </div>

        <div class="col-10">
          <b-button block class="btn-fill blue-btn child" @click="selected('Pago')">
            Pago
          </b-button>
        </div>
        <!--         <div class="col-1">
          <div v-if="ratificacion.currentComponentValues.Comparecientes" class="led-green"></div>
          <div v-else class="led-yellow"></div>
        </div> -->
      </div>


      <div class="row">
        <div class="col-1">
          <div :class="['circle', ratificacion.currentComponentValues.ComplementaInformacion ? 'completado' : '']">
            <span class="number"><i v-if="ratificacion.currentComponentValues.ComplementaInformacion" class="fa fa-check"></i><i v-else
                class="fa fa-minus"></i></span>
          </div>
        </div>
        <div class="col-10">
          <b-button block class="btn-fill blue-btn child" @click="selected('ComplementaInformacion')">
            Complementa Informacion
          </b-button>
        </div>
        <!--         <div class="col-1">
          <div v-if="ratificacion.currentComponentValues.Montos" class="led-green"></div>
          <div v-else class="led-yellow"></div>
        </div> -->
      </div>
      <div class="row">
        <div class="col-1">
          <div :class="['circle', ratificacion.currentComponentValues.Documentos ? 'completado' : '']">
            <span class="number"><i v-if="ratificacion.currentComponentValues.Documentos" class="fa fa-check"></i><i
                v-else class="fa fa-minus"></i></span>
          </div>
        </div>
        <div class="col-10">
          <b-button block class="btn-fill blue-btn child" @click="selected('Documentos')">
            Documentos
          </b-button>
        </div>
        <!--         <div class="col-1">
          <div v-if="ratificacion.currentComponentValues.Documentos" class="led-green"></div>
          <div v-else class="led-yellow"></div>
        </div> -->
      </div>
      <div class="row" v-if="ratificacion.entrega_fuera_oficina === 1">
        <div class="col-1">
          <div :class="['circle', ratificacion.currentComponentValues.Domicilio ? 'completado' : '']">
            <span class="number"><i v-if="ratificacion.currentComponentValues.Domicilio" class="fa fa-check"></i><i
                v-else class="fa fa-minus"></i></span>
          </div>
        </div>
        <div class="col-10">
          <b-button block class="btn-fill blue-btn" @click="selected('Domicilio')">
            Domicilio de Entrega
          </b-button>
        </div>
        <!--       <div class="col-1">
          <div v-if="ratificacion.currentComponentValues.Cotizacion" class="led-green"></div>
          <div v-else class="led-yellow"></div>
        </div> -->
      </div>
      <div class="row" v-if="ratificacion.pago_complementario">
        <div class="col-1">
          <div :class="['circle', ratificacion.currentComponentValues.PagoComplementario ? 'completado' : '']">
            <span class="number"><i v-if="ratificacion.currentComponentValues.PagoComplementario" class="fa fa-check"></i><i
                v-else class="fa fa-minus"></i></span>
          </div>
        </div>
        <div class="col-10">
          <b-button block class="btn-fill blue-btn" @click="selected('PagoComplementario')">
            Pago Complementario
          </b-button>
        </div>
        <!--       <div class="col-1">
          <div v-if="ratificacion.currentComponentValues.Cotizacion" class="led-green"></div>
          <div v-else class="led-yellow"></div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TreeRatificaciones",

  props: {
    ratificacion: { type: Object },
    user: { type: String }
  },

  methods: {
    selected(node) {
      this.$emit("node", node);
    }
  }
};
</script>

<style scoped>
.invi {
  display: none !important;
  ;
}

.completado {
  background-color: yellowgreen !important;
}

.circle {
  height: 20px;
  width: 20px;
  margin-top: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: silver;
}

.icon-title {
  height: 20px;
  width: 20px;
  margin-top: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.icon-title-fa {
  font-size: 11px;
  color: black;
}

.number {
  font-size: 11px;
  color: white;
}

.title-text {
  font-weight: 600;
  color: black !important;

}

.complete-text {

  color: black !important;
}

.blue-btn {

  padding-left: 0px;
  padding-right: 0px;
  background-color: transparent !important;
  border-color: transparent !important;
  color: rgb(154, 154, 154);
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: -10px;
  text-align: left !important;

}

.blue-btn:hover {
  color: rgb(154, 154, 154);
  background-color: transparent !important;
  border-color: transparent !important;
  background-color: #effbff !important;
  border-color: #effbff !important;
}

.blue-btn:active {
  background-color: #effbff !important;
  border-color: #effbff !important;

}


.led-green {
  margin: 10px auto;
  width: 10px;
  height: 10px;
  background-color: #43eb34;
  border-radius: 50%;
  box-shadow: #257a1d 0 -1px 7px 1px, inset #460 0 -1px 9px, #7d0 0 2px 12px;
}

.led-red {
  margin: 10px auto;
  width: 10px;
  height: 10px;
  background-color: #e60505;
  border-radius: 50%;
  box-shadow: #a60903 0 -1px 7px 1px, inset #600 0 -1px 9px, #f00 0 2px 12px;
}

.led-yellow {
  margin: 10px auto;
  width: 10px;
  height: 10px;
  background-color: #f7e00a;
  border-radius: 50%;
  box-shadow: #d1ca04 0 -1px 7px 1px, inset #c4b206 0 -1px 9px, #dd0 0 2px 12px;
}
</style>
