// API
import BaseApi from './base';

class CatFormulariosFormalizacionesApi extends BaseApi {
  constructor() {
    super('formulariosFormalizacion');
  }

  getEstatusForm(formulario, formalizacion_id, params = {}) {
    return this.request.get(`/formulariosFormalizacion/estatus/${formulario}/${formalizacion_id}`, {
      params: {
        ...params,
      },
    });
  }
}

export default new CatFormulariosFormalizacionesApi();
