<template>
  <div class="container">
    <h4 class="cardD">Finalización de proceso</h4>

    <div class="mt-4">
      <div class="row ">
        <div class="col-12">
          <b-button type="submit" block class="btn-fill orange-btn bottom2" size="m"
            :disabled="cotejo.id_estatus != 20">Dar proceso por terminado</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Terminado",

  props: {
    cotejo: { type: Object },
  },
}
</script>

<style scoped>
.cardErrors {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* color: #9a9a9a; */
  color: #ea640b;
  font-size: 13px;
}

.cardD {
  font-size: 15px !important;
  color: #ea640b;
  margin-top: 0%;
}

.bottom2 {
  float: right;
}

.bottom1 {
  float: left;
}

.green-btn {
  background-color: green;
}

.container>>>.validacionDatosLabel {
  position: absolute;
  margin-left: 30px;
  top: 25%;
  max-width: 1500px;
}
</style>