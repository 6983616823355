import Vue from 'vue'
import App from './App.vue'
import LightBootstrap from './light-bootstrap-main'
import './registerServiceWorker'
import VueSession from 'vue-session'
import router from '@/routes/routes.js'
import store from '@/store/store.js'

import { ValidationProvider, ValidationObserver, extend } from 'vee-validate/dist/vee-validate.full.esm';
// import { ValidationObserver } from 'vee-validate';


import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret, faBuilding,faImages,faEnvelope,faPhone,faSpinner,faSignOut,faDollarSign } from '@fortawesome/free-solid-svg-icons'
// import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'


import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

// import VueConfirmDialog from 'vue-confirm-dialog'

import Vue2Editor from "vue2-editor";

import "vue-easytable/libs/theme-default/index.css";
import VueEasytable from "vue-easytable";

import * as VueGoogleMaps from 'vue2-google-maps'

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';


// import VueTreeNavigation from 'vue-tree-navigation';

// import GmapVue from 'gmap-vue';
// import VueHtml2pdf from "vue-html2pdf";


library.add(faUserSecret,faBuilding,faImages,faEnvelope,faPhone,faSpinner,faSignOut,faDollarSign)

Vue.component('font-awesome-icon', FontAwesomeIcon)


Vue.use(LightBootstrap)
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

var options = {
  persist: true
}
Vue.use(VueSession,options)

Vue.component('ValidationProvider',ValidationProvider);
Vue.component('ValidationObserver',ValidationObserver);
// Vue.component('Validator',Validator);

Vue.use(Toast);

// Vue.use(VueConfirmDialog)
// Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

Vue.use(Vue2Editor);

Vue.use(VueEasytable);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyC1LrDy2Gk5QhV8SjD7k4S6b4zg-wF2YEA',
    libraries: 'places',
  }
});

Vue.component('v-select', vSelect)

// Vue.use(VueHtml2pdf);
// Vue.component('vue-html2pdf', VueHtml2pdf);

// Vue.use(VueTreeNavigation);

// Vue.use(GmapVue, {
//   load: {
//     key: 'AIzaSyC1LrDy2Gk5QhV8SjD7k4S6b4zg-wF2YEA',
//     libraries: 'places',
//     // v: '3.26',
//     customCallback: 'MyCustomCallback',
//   },
//   autoBindAllEvents: false,
//   installComponents: true,
//   dynamicLoad: false,
// })

extend('cien', {
  message:
  'El porcentaje tiene que ser 100',
  validate: value => {
    let result = true;
    if(value !=100){result = false}
    return result;
  }
});

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App),

})
