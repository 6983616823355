<template>
  <div class="container">
    <!-- YA NO SE UTILIZA CREO
       <h4 class="cardD">
      Entrega de documentación digital e información complementaria
    </h4>
    <card class="blue">
      <i class="fa fa-info"></i>
      Aquí van instrucciones.
      <p></p>
      <i class="fa fa-play"></i>
      Links a videos.
      <p></p>
      <i class="fa fa-question"></i>
      Datos de contacta a un asesor.
    </card>

    <div class="container-fluid">
      <b-modal
        centered
        ref="modal-confirm-dialog"
        hide-footer
        id="modal-confirm-dialog"
      >
        <h4 class="text-center">¿Seguro que quiere eliminar el documento?</h4>
        <h5 class="text-center"></h5>
        <b-button
          class="mt-3"
          variant="danger"
          @click="$bvModal.hide('modal-confirm-dialog')"
          >Cancelar</b-button
        >
        <b-button
          class="mt-3 float-right"
          variant="primary"
          @click="deleteDocumento"
          >OK</b-button
        >
      </b-modal>

      <b-modal
        centered
        ref="documento-modal"
        title=""
        hide-footer
        id="modalC"
        size="xl"
      >
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(postDocumento)">
            <ModalDocumentos
              :currentDocumento="currentDocumento"
              :options="options"
              @submit.prevent
            />
          </form>
        </ValidationObserver>
      </b-modal>

      <b-modal
        centered
        ref="documento-modal-preview-img"
        title=""
        hide-footer
        id="modaldi"
        size="xl"
      >
        <ModalPreviewImg :currentDocumento="currentDocumento" />
      </b-modal>
      <b-modal
        centered
        ref="documento-modal-preview-pdf"
        title=""
        hide-footer
        id="modaldf"
        size="xl"
      >
        <ModalPreviewPDF :currentDocumento="currentDocumento" />
      </b-modal>

      <div class="row">
        <div class="col-md-4">
          <b-button
            class="btn-fill"
            @click="addDocumento"
            variant="success"
            :disabled="constitutiva.disabledDocumentos"
          >
            + Cargar
          </b-button>
        </div>
      </div>

      <b-table hover :fields="fields" :items="constitutiva.documentos">

        <template #cell(acciones)="{ item }">
          <b-button
            variant="warning"
            size="sm"
            v-b-tooltip.hover
            title="Editar"
            @click="editDocumento(item)"
            class="editBtn"
            :disabled="constitutiva.disabledDocumentos"
          >
            <b-icon-pencil />
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            v-b-tooltip.hover
            title="Vista previa"
            @click="previewDocumento(item)"
            class="editBtn"
          >
            <b-icon-eye />
          </b-button>
          <b-button
            variant="danger"
            size="sm"
            v-b-tooltip.hover
            title="Editar"
            @click="predeleteDocumento(item.id)"
            class="editBtn"
            :disabled="constitutiva.disabledDocumentos"
          >
            <b-icon-trash />
          </b-button>
        </template>
      </b-table>
    </div>

    <div class="mt-4">
      <div class="">
        <b-button
          type="submit"
          class="btn-fill orange-btn bottom2 float-right"
          size="m"
          :disabled="constitutiva.disabledDocumentos"
          >Guardar</b-button
        >
      </div>
    </div> -->
  </div>
</template>

<script>
import ModalDocumentos from "@/views/clients/procesos/constitucion/entregadocumentos/ModalDocumentos.vue";
import ModalPreviewImg from "@/views/clients/procesos/constitucion/entregadocumentos/ModalPreviewImg.vue";
import ModalPreviewPDF from "@/views/clients/procesos/constitucion/entregadocumentos/ModalPreviewPDF.vue";
import { FuncionesApi } from "@/api";

export default {
  name: "EntregaDocumentos",

  props: {
    constitutiva: { type: Object }
  },

  components: {
    ModalDocumentos,
    ModalPreviewImg,
    ModalPreviewPDF
  },

  computed: {
    fields() {
      let fields = [
        { key: "nombreFile", label: "Nombre", class: "text-center" },
        { key: "tipo", label: "Tipo", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" }
      ];
      return fields;
    }
  },

  data() {
    return {
      //   currentDocumento: null,
      //   currentDocumentoId: null,
      //   options: [
      //     { text: "Comprobante de domicilio", value: "Comprobante de domicilio" },
      //     { text: "Otro", value: "Otro" }
      //   ],
      //   optionsFisicas: [
      //     { text: "Identificación oficial", value: "Identificación oficial" },
      //     { text: "Comprobante de domicilio", value: "Comprobante de domicilio" },
      //     {
      //       text: "RFC o Cédula de identificación Fiscal",
      //       value: "RFC o Cédula de identificación Fiscal"
      //     },
      //     { text: "CURP", value: "CURP" },
      //     { text: "Otro", value: "Otro" }
      //   ],
      //   optionsMorales: [
      //     {
      //       text: "Constitutiva",
      //       value: "Constitutiva"
      //     },
      //     {
      //       text: "Poderes del representante",
      //       value: "Poderes del representante"
      //     },
      //     {
      //       text: "Cuadro de distribución accionaria",
      //       value: "Cuadro de distribución accionaria"
      //     },
      //     // {
      //     //   text: "PDF del formato de beneficiario controlador correspondiente a cada uno de las personas físicas finales, accionistas de la persona moral, que califiquen en tal carácter",
      //     //   value:
      //     //     "PDF del formato de beneficiario controlador correspondiente a cada uno de las personas físicas finales, accionistas de la persona moral, que califiquen en tal carácter",
      //     // },
      //     { text: "Otro", value: "Otro" }
      //   ],
      //   form: {
      //     documentos: []
      //   }
    };
  },

  methods: {
    addDocumento() {
      this.$refs["documento-modal"].show();
      this.currentDocumento = {};
    },

    async postDocumento() {
      this.loading = true;
      await this.addFile();

      if (this.currentDocumento.id === undefined) {
        let id = this.constitutiva.documentos.length + 1;
        this.currentDocumento.id = id;
        var a = Object.assign({}, this.currentDocumento);
        this.constitutiva.documentos.push(a);
      } else if (this.currentDocumento.id != undefined) {
        const index = this.constitutiva.documentos.findIndex(
          o => o.id === this.currentDocumento.id
        );
        var a = Object.assign({}, this.currentDocumento);
        this.constitutiva.documentos.splice([index], 1, a);
      }

      this.$refs["documento-modal"].hide();
      this.loading = false;
    },

    async addFile() {
      this.loading = true;
      try {
        const formData = new FormData();
        this.currentDocumento.nombreFile = this.currentDocumento.file.name;
        formData.append("file", this.currentDocumento.file);
        const value = await FuncionesApi.file(
          this.constitutiva.id,
          "documentosConstituciones",
          formData,
          {}
        );
        this.currentDocumento.documento = value;
      } catch (error) {
        //
      }
      this.loading = false;
    },

    previewDocumento(item) {
      this.currentDocumento = item;

      let pretipoDocumento = item.nombreFile.split(".");
      let tipoDocumento = pretipoDocumento[pretipoDocumento.length - 1];

      if (tipoDocumento === "pdf") {
        this.$refs["documento-modal-preview-pdf"].show();
      }
      if (
        tipoDocumento === "jpg" ||
        tipoDocumento === "JPG" ||
        tipoDocumento === "jpeg" ||
        tipoDocumento === "png" ||
        tipoDocumento === "gif" ||
        tipoDocumento === "bmp" ||
        tipoDocumento === "webp" ||
        tipoDocumento === "svg" ||
        tipoDocumento === "tiff" ||
        tipoDocumento === "avif"
      ) {
        this.$refs["documento-modal-preview-img"].show();
      }
    },

    editDocumento(documento) {
      this.$refs["documento-modal"].show();
      // console.log(documento);
      this.currentDocumento = documento;
    },

    predeleteDocumento(id) {
      this.currentDocumentoId = id;
      this.$refs["modal-confirm-dialog"].show();
    },

    async deleteDocumento() {
      // await this.deleteFile(this.currentDocumento.id);
      await this.deleteFile(this.constitutiva.id);
      let index2 = this.constitutiva.documentos.findIndex(
        o => o.id === this.currentDocumentoId
      );
      this.constitutiva.documentos.splice(index2, 1);
      this.$refs["modal-confirm-dialog"].hide();
    },

    async deleteFile(id) {
      this.loading = true;
      var data = {
        url: this.currentDocumento.documento,
        id: this.constitutiva.id
      };
      try {
        await FuncionesApi.deleteFile("documentosConstituciones", data, {});
      } catch (error) {
        //
      }
      this.loading = false;
    }
  }
};
</script>

<style scoped>
.blue {
  background-color: #effbff;
  border-color: #effbff !important;
  text-align: justify;
}
</style>
