<template>
  <div class="contact-us full-screen">
    <div class="container">
      <div v-if="loading">
        <div id="loader">
          <font-awesome-icon icon="spinner" spin />
        </div>
      </div>
    </div>

    <TopNavbar />

    <div class="wrapper wrapper-full-page section content">
      <div class="container">
        <h4 slot="header" class="card-title">Verificación de cuenta</h4>
        <div class="center">
          Para poder acceder a su cuenta es necesario que la verifique. Por favor
          haga clic en el botón de Verificar, que está a continuación.
        </div>
        <div class="center">
          <button @click="verificar()" class="btn orange-btn">
            Verificar cuenta
          </button>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Footer from "@/layout/ContentFooter.vue";
import TopNavbar from "@/layout/TopNavbar.vue";

import { VerificarCuentaApi } from "@/api";

export default {
  name: "VerificacionCuenta",

  components: {
    TopNavbar,
    Footer,
  },

  methods: {
    async verificar() {
      try {
        this.loading = true;

        const hash = this.$route.params.hash;
        const data = {
          hash: hash,
        };

        await VerificarCuentaApi.verificarCuenta(data);

        this.loading = false;
        this.$toast.success("Cuenta verificada. Puede iniciar sesión", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true,
        });
        this.$router.push({ name: "Login" });
      } catch (error) {
        console.log(error);
        this.$toast.error("No es posible verificar la cuenta", {
          timeout: 8000, //15000, //false
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true,
        });
        this.$router.push({ name: "Home" });
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
#card {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* color: #9a9a9a; */
  color: #ea640b;
  font-size: 13px;
}

.center {
  text-align: center !important;
  margin-top: 2%;
}
</style>
