<template>
  <div>
    <!-- <GmapAutocomplete @place_changed="setPlace" />
    <button @click="addMarker">Add</button> -->

    <!-- <GmapMap :center="center" :zoom="12" style="width: 100%; height: 300px">
      <GmapMarker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        @click="center = m.position"
      />
    </GmapMap> -->

    <!-- ESTO YA NO SE UTILIZA PARA LO QUE NECESITAMOS -->
    <iframe
      width="100%"
      height="300px"
      frameborder="0"
      style="border: 0"
      :src="mapUrl"
      allowfullscreen
    >
    </iframe>
  </div>
</template>

<script>
export default {
  name: "GoogleMap",

  props: {
    mapUrl: { type: String }
  }

  // data() {
  //   return {
  //     // center: { lat: 19.428, lng: -99.128 },
  //     // currentPlace: null,
  //     // markers: [],
  //     // places: [],
  //     mapUrl: null,
  //   };
  // },

  // //obtiene localizacion del usuario
  // mounted() {
  //   this.geolocate();
  // },
  // methods: {
  //   geolocate: function () {
  //     navigator.geolocation.getCurrentPosition((position) => {
  //       this.center = {
  //         lat: position.coords.latitude,
  //         lng: position.coords.longitude,
  //       };
  //     });
  //   },
  // async createInfoWindow(latLng) {
  //   // const google = await gmapsInit();
  //   // const infoWindow = new google.maps.InfoWindow({
  //   //   content: "InfoWindow",
  //   //   position: latLng,
  //   // });
  //   // infoWindow.open(this.map);
  //   console.log(latLng);
  // },

  // setPlace(place) {
  //   this.currentPlace = place;
  //   console.log(place);
  // },

  // addMarker() {
  //   if (this.currentPlace) {
  //     const marker = {
  //       lat: this.currentPlace.geometry.location.lat(),
  //       lng: this.currentPlace.geometry.location.lng(),
  //     };
  //     console.log(this.currentPlace.geometry.location.lat());
  //     console.log(this.currentPlace.geometry.location.lng());
  //     this.markers.push({ position: marker });
  //     this.places.push(this.currentPlace);
  //     this.center = marker;
  //     this.currentPlace = null;
  //   }
  // },
  // },
};
</script>
