<template>
  <div class="container">
    <div v-if="formalizacion.tipo_acta == 'Asamblea de Accionistas'">
      <h4 class="cardD">Cuestionario relacionado con la instalación de la asamblea cuya acta se protocolizará</h4>

      <div class="row mt-4">
        <div class="col-md-12">
          <label>Existe convocatoria y publicaciones hechas conforme a estatutos sociales y LGSM</label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <b-form-radio v-model="convocatoriaLgsm" name="radios" value="Sí" @change="selectLgsm('Sí')">
            Sí
          </b-form-radio>
        </div>
        <div class="col-md-3">
          <b-form-radio v-model="convocatoriaLgsm" name="radios" value="No" @change="selectLgsm('No')">
            No
          </b-form-radio>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-md-12">
          <label>Existe convocatoria en el PSM</label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <b-form-radio v-model="convocatoriaPsm" name="radios92" value="Sí" @change="selectPsm('Sí')">
            Sí
          </b-form-radio>
        </div>
        <div class="col-md-3">
          <b-form-radio v-model="convocatoriaPsm" name="radios92" value="No" @change="selectPsm('No')">
            No
          </b-form-radio>
        </div>
      </div>

      <div v-if="formalizacion.convocatoria_psm == '0'">
        <div class="row mt-4">
          <div class="col-md-12">
            <label>Estuvo representado en asamblea el 100% del capital con derecho a voto</label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <b-form-radio v-model="representacionCien" name="radios3" value="Sí"
              @change="selectRepresentacionCien('Sí')">
              Sí
            </b-form-radio>
          </div>
          <div class="col-md-3">
            <b-form-radio v-model="representacionCien" name="radios3" value="No"
              @change="selectRepresentacionCien('No')">
              No
            </b-form-radio>
          </div>
        </div>
      </div>
      <label class="mt-2 leyendaBlock" v-if="blockCien" :disabled="formalizacion.disabled">
        <b>
          Para poder continuar con el proceso es necesario que esté representado en asamblea con el 100%.
        </b>
      </label>

      <div class="row mt-4">
        <div class="col-md-12">
          <label for="">
            Existe lista de asistencia firmada por los asistentes a la misma
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <b-form-radio v-model="listaAsistencia" name="radios4" value="Sí" @change="selectLista('Sí')">
            Sí
          </b-form-radio>
        </div>
        <div class="col-md-3">
          <b-form-radio v-model="listaAsistencia" name="radios4" value="No" @change="selectLista('No')">
            No
          </b-form-radio>
        </div>
      </div>

      <div v-if="
        formalizacion.lista_asistencia_firmada == 0 &&
        formalizacion.representacion_cien == 1
      ">
        <div class="row mt-4">
          <div class="col-md-12">
            <label for="">
              Todos los asistentes firmaron el acta correspondiente
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <b-form-radio v-model="asistentesFirma" name="radios5" value="Sí" @change="selectAsistentesFirma('Sí')">
              Sí
            </b-form-radio>
          </div>
          <div class="col-md-3">
            <b-form-radio v-model="asistentesFirma" name="radios5" value="No" @change="selectAsistentesFirma('No')">
              No
            </b-form-radio>
          </div>
        </div>
      </div>
      <label class="mt-2 leyendaBlock" v-if="blockFirma" :disabled="formalizacion.disabled">
        <b>
          Para poder continuar con el proceso es necesario que los asistentes
          hayan firmado el acta.
        </b>
      </label>

      <div class="row mt-4">
        <div class="col-md-12">
          <label for="">
            Cuenta con la clave de RFC de los accionistas y administradores de
            la sociedad
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <b-form-radio v-model="rfcsAccionistas" name="radios6" value="Sí" @change="selectRfcs('Sí')">
            Sí
          </b-form-radio>
        </div>
        <div class="col-md-3">
          <b-form-radio v-model="rfcsAccionistas" name="radios6" value="No" @change="selectRfcs('No')">
            No
          </b-form-radio>
        </div>
      </div>
    </div>

    <div v-if="
      formalizacion.tipo_acta ==
      'Acta de sesión del Consejo de Administración'
    ">
      <h4 class="cardD">
        Cuestionario relacionado con la instalación de la Sesión de Consejo cuya
        acta se protocolizará
      </h4>

      <div class="row mt-4">
        <div class="col-md-12">
          <label for="">
            Existe convocatoria hecha conforme a estatutos sociales y LGSM
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <b-form-radio v-model="convocatoriaLgsm" name="radios" value="Sí" @change="selectLgsm('Sí')">
            Sí
          </b-form-radio>
        </div>
        <div class="col-md-3">
          <b-form-radio v-model="convocatoriaLgsm" name="radios" value="No" @change="selectLgsm('No')">
            No
          </b-form-radio>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-md-12">
          <label for="">
            Estuvo debidamente instalada la sesión de consejo, conforme a
            estatutos sociales
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <b-form-radio v-model="sesionInstalada" name="radios2" value="Sí" @change="selectSesion('Sí')">
            Sí
          </b-form-radio>
        </div>
        <div class="col-md-3">
          <b-form-radio v-model="sesionInstalada" name="radios3" value="No" @change="selectSesion('No')">
            No
          </b-form-radio>
        </div>
      </div>
      <label class="mt-2 leyendaBlock" v-if="blockSesion" :disabled="formalizacion.disabled">
        <b>
          Para poder continuar con el proceso es necesario que la sesión esté
          instalada
        </b>
      </label>

      <div class="row mt-4">
        <div class="col-md-12">
          <label for="">
            Existe lista de asistencia firmada por los asistentes a la misma
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <b-form-radio v-model="listaAsistencia" name="radios4" value="Sí" @change="selectLista('Sí')">
            Sí
          </b-form-radio>
        </div>
        <div class="col-md-3">
          <b-form-radio v-model="listaAsistencia" name="radios4" value="No" @change="selectLista('No')">
            No
          </b-form-radio>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-md-12">
          <label for="">
            Fue una sesión totalitaria
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <b-form-radio v-model="sesionTotalitaria" name="radios5" value="Sí" @change="selectTotalitaria('Sí')">
            Sí
          </b-form-radio>
        </div>
        <div class="col-md-3">
          <b-form-radio v-model="sesionTotalitaria" name="radios5" value="No" @change="selectTotalitaria('No')">
            No
          </b-form-radio>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-md-12">
          <label for="">
            Todos los asistentes firmaron el acta correspondiente
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <b-form-radio v-model="asistentesFirma" name="radios51" value="Sí" @change="selectAsistentesFirma('Sí')">
            Sí
          </b-form-radio>
        </div>
        <div class="col-md-3">
          <b-form-radio v-model="asistentesFirma" name="radios51" value="No" @change="selectAsistentesFirma('No')">
            No
          </b-form-radio>
        </div>
      </div>
      <label class="mt-2 leyendaBlock" v-if="blockFirma" :disabled="formalizacion.disabled">
        <b>
          Para poder continuar con el proceso es necesario que los asistentes
          hayan firmado el acta.
        </b>
      </label>
    </div>

    <div v-if="
      formalizacion.tipo_acta == 'Asamblea de Accionistas' ||
      formalizacion.tipo_acta ==
      'Resoluciones Unánimes adoptadas fuera de asamblea (RUAs)'
    ">
      <h4 class="cardD">
        Cuestionario relacionado al contenido del acta que se protocoliza
      </h4>

      <div class="row mt-4">
        <div class="col-6">
          <div class="row">
            <div class="col-md-12">
              <label for="">
                Hay modificaciones al capital
              </label>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3">
              <b-form-radio v-model="modificaCapital" name="radios12" :disabled="formalizacion.disabledAsamblea"
                value="Sí" @change="selectModificaCapital('Sí')">
                Sí
              </b-form-radio>
            </div>
            <div class="col-md-3">
              <b-form-radio v-model="modificaCapital" name="radios12" value="No"
                :disabled="formalizacion.disabledAsamblea" @change="selectModificaCapital('No')">
                No
              </b-form-radio>
            </div>
          </div>
        </div>

        <div class="col-6" v-if="formalizacion.modifica_capital == 1">
          <div class="row">
            <div class="col-md-6">
              <label for="">
                Capital modificado
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <b-form-radio v-model="formalizacion.modifica_capital" name="radios8" value="Fijo"
                :disabled="formalizacion.disabledAsamblea">
                Fijo
              </b-form-radio>
            </div>
            <div class="col-md-3">
              <b-form-radio v-model="formalizacion.modifica_capital" name="radios8" value="Variable"
                :disabled="formalizacion.disabledAsamblea">
                Variable
              </b-form-radio>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-4" v-if="formalizacion.modifica_capital == 1">
        <div class="col-6">
          <div class="row">
            <div class="col-md-6">
              <label for="">
                Acción
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <b-form-radio v-model="formalizacion.tipo_modificado" name="radios9" value="Aumenta"
                :disabled="formalizacion.disabledAsamblea" @change="selectTipoModificado('Aumenta')">
                Aumenta
              </b-form-radio>
            </div>
            <div class="col-md-3">
              <b-form-radio :disabled="formalizacion.disabledAsamblea" v-model="formalizacion.tipo_modificado"
                name="radios9" value="Disminuye" @change="selectTipoModificado('Disminuye')">
                Disminuye
              </b-form-radio>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="row mt-4">
            <div class="col-md-9">
              <ValidationProvider name="nombre_sociedad" rules="required" v-slot="{ errors }" :custom-messages="{
                required: 'El monto es obligatorio'
              }">
                <base-input type="number" label="Monto" placeholder="Monto" v-model="formalizacion.monto_modificado">
                </base-input>
                <span class="cardErrors">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
        </div>
        <label class="mt-2 leyendaBlock" v-if="blockMonto" :disabled="formalizacion.disabledAsamblea">
          <b>
            Para poder continuar con el proceso para montos superiores a 200MDP
            es necesario que se comunique con Correduría Digital
          </b>
        </label>
      </div>

      <div class="row mt-3">
        <div class="col-6">
          <div class="row">
            <div class="col-md-12">
              <label for="">
                Ingresan nuevos accionistas o salen accionistas
              </label>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3">
              <b-form-radio v-model="nuevosAccionistas" name="radios11" :disabled="formalizacion.disabledAsamblea"
                value="Sí" @change="selectNuevosAccionistas('Sí')">
                Sí
              </b-form-radio>
            </div>
            <div class="col-md-3">
              <b-form-radio v-model="nuevosAccionistas" name="radios11" value="No"
                :disabled="formalizacion.disabledAsamblea" @change="selectNuevosAccionistas('No')">
                No
              </b-form-radio>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-6">
          <div class="row">
            <div class="col-md-12">
              <label for="">
                Ingresa capital extranjero a la sociedad
              </label>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3">
              <b-form-radio v-model="capitalExtranjero" name="radios10" :disabled="formalizacion.disabledAsamblea"
                value="Sí" @change="selectCapitalExtranjero('Sí')">
                Sí
              </b-form-radio>
            </div>
            <div class="col-md-3">
              <b-form-radio v-model="capitalExtranjero" name="radios10" value="No"
                :disabled="formalizacion.disabledAsamblea" @change="selectCapitalExtranjero('No')">
                No
              </b-form-radio>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="
      formalizacion.subtipo_acta == 'General Extraordinaria' ||
      formalizacion.tipo_acta ==
      'Resoluciones Unánimes adoptadas fuera de asamblea (RUAs)'
    ">
      <div class="row mt-4">
        <div class="col-6">
          <div class="row">
            <div class="col-md-12">
              <label for="">
                Se modifican los estatutos de la sociedad
              </label>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3">
              <b-form-radio v-model="modificaEstatutos" name="radios20" :disabled="formalizacion.disabledAsamblea"
                value="Sí" @change="selectModificaEstatutos('Sí')">
                Sí
              </b-form-radio>
            </div>
            <div class="col-md-3">
              <b-form-radio v-model="modificaEstatutos" name="radios20" value="No"
                :disabled="formalizacion.disabledAsamblea" @change="selectModificaEstatutos('No')">
                No
              </b-form-radio>
            </div>
          </div>
        </div>
        <div class="col-6" v-if="formalizacion.modifica_estatutos == 1">
          <div class="row">
            <div class="col-md-12">
              <label for="">
                Hubo reforma total de estatutos o compulsa de los mismos
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <b-form-radio v-model="reformaTotal" name="radios21" :disabled="formalizacion.disabledAsamblea" value="Sí"
                @change="selectReformaTotal('Sí')">
                Sí
              </b-form-radio>
            </div>
            <div class="col-md-3">
              <b-form-radio v-model="reformaTotal" name="radios21" value="No" :disabled="formalizacion.disabledAsamblea"
                @change="selectReformaTotal('No')">
                No
              </b-form-radio>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-4" v-if="formalizacion.modifica_estatutos == 1">
        <div class="col-6">
          <div class="row">
            <div class="col-md-12">
              <label for="">
                Se modificó el objeto social
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <b-form-radio v-model="modificaObjeto" name="radios22" :disabled="formalizacion.disabledAsamblea"
                value="Sí" @change="selectModificaObjeto('Sí')">
                Sí
              </b-form-radio>
            </div>
            <div class="col-md-3">
              <b-form-radio v-model="modificaObjeto" name="radios23" value="No"
                :disabled="formalizacion.disabledAsamblea" @change="selectModificaObjeto('No')">
                No
              </b-form-radio>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="row">
            <div class="col-md-12">
              <label for="">
                Cambió la duración de la sociedad
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <b-form-radio v-model="cambioDuracion" name="radios24" :disabled="formalizacion.disabledAsamblea"
                value="Sí" @change="selectCambioDuracion('Sí')">
                Sí
              </b-form-radio>
            </div>
            <div class="col-md-3">
              <b-form-radio v-model="cambioDuracion" name="radios24" value="No"
                :disabled="formalizacion.disabledAsamblea" @change="selectCambioDuracion('No')">
                No
              </b-form-radio>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-4" v-if="formalizacion.modifica_estatutos == 1">
        <div class="col-6">
          <div class="row">
            <div class="col-md-12">
              <label>Cambió la denominación social</label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <b-form-radio v-model="cambioDenominacion" name="radios25" :disabled="formalizacion.disabledAsamblea"
                value="Sí" @change="selectCambioDenominacion('Sí')">
                Sí
              </b-form-radio>
            </div>
            <div class="col-md-3">
              <b-form-radio v-model="cambioDenominacion" name="radios25" value="No"
                :disabled="formalizacion.disabledAsamblea" @change="selectCambioDenominacion('No')">
                No
              </b-form-radio>
            </div>
          </div>
        </div>
        <div class="col-6" v-if="formalizacion.modifica_denominacion_social">
          <div class="row">
            <div class="col-md-12">
              <label>
                Domicilio social y corporativo de la sociedad
                <!-- Se modificó el domicilio social de la sociedad -->
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <b-form-radio v-model="cambioDomicilio" name="radios26" :disabled="formalizacion.disabledAsamblea"
                value="Sí" @change="selectCambioDomicilio('Sí')">
                Sí
              </b-form-radio>
            </div>
            <div class="col-md-3">
              <b-form-radio v-model="cambioDomicilio" name="radios26" value="No"
                :disabled="formalizacion.disabledAsamblea" @change="selectCambioDomicilio('No')">
                No
              </b-form-radio>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-7">
          <div class="row">
            <div class="col-md-12">
              <label for="">
                Revocación o Renuncia de funcionarios o apoderados
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <b-form-radio v-model="revocaFuncionarios" name="radios30" :disabled="formalizacion.disabledAsamblea"
                value="Sí" @change="selectRevocaFuncionarios('Sí')">
                Sí
              </b-form-radio>
            </div>
            <div class="col-md-3">
              <b-form-radio v-model="revocaFuncionarios" name="radios30" value="No"
                :disabled="formalizacion.disabledAsamblea" @change="selectRevocaFuncionarios('No')">
                No
              </b-form-radio>
            </div>
          </div>
        </div>
        <div class="col-5" v-if="formalizacion.revoca_funcionarios">
          <div class="row">
            <div class="col-10">
              <base-input :disabled="formalizacion.disabledAsamblea" type="text" label="Cantidad" placeholder="Cantidad"
                v-model="formalizacion.num_funcionarios_revocados">
              </base-input>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-8">
          <div class="row">
            <div class="col-md-12">
              <label for="">
                Nombramiento o modificación de facultades de funcionarios o
                apoderados
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <b-form-radio v-model="modificaFacutlades" name="radios31" :disabled="formalizacion.disabledAsamblea"
                value="Sí" @change="selectModificaFacutlades('Sí')">
                Sí
              </b-form-radio>
            </div>
            <div class="col-md-3">
              <b-form-radio v-model="modificaFacutlades" name="radios31" value="No"
                :disabled="formalizacion.disabledAsamblea" @change="selectModificaFacutlades('No')">
                No
              </b-form-radio>
            </div>
          </div>
        </div>
        <!-- <div class="col-5" v-if="formalizacion.modifica_facultades_funcionarios">
          <div class="row">
            <div class="col-10">
              <base-input
                :disabled="formalizacion.disabled"
                type="text"
                label="Cantidad"
                placeholder="Cantidad"
                v-model="formalizacion.num"
              >
              </base-input>
            </div>
          </div>
        </div> -->
      </div>

      <div class="row mt-3">
        <div class="col-6">
          <div class="row">
            <div class="col-md-12">
              <label for="">
                Se transformó la sociedad
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <b-form-radio v-model="transformaSociedad" name="radios32" value="Sí"
                @change="selectTransformaSociedad('Sí')">
                Sí
              </b-form-radio>
            </div>
            <div class="col-md-3">
              <b-form-radio name="radios32" v-model="transformaSociedad" value="No"
                @change="selectTransformaSociedad('No')">
                No
              </b-form-radio>
            </div>
          </div>
        </div>
      </div>
      <label class="mt-2 leyendaBlock" v-if="blockTransformo" :disabled="formalizacion.disabled">
        <b>
          Para poder continuar con el proceso es necesario que contacte a
          Correduría Digital
        </b>
      </label>

      <div class="row mt-3">
        <div class="col-6">
          <div class="row">
            <div class="col-md-12">
              <label for="">
                Fusión o escisión
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <b-form-radio v-model="fusion" name="radios34" value="Sí" @change="selectFusion('Sí')">
                Sí
              </b-form-radio>
            </div>
            <div class="col-md-3">
              <b-form-radio v-model="fusion" name="radios34" value="No" @change="selectFusion('No')">
                No
              </b-form-radio>
            </div>
          </div>
        </div>
      </div>
      <label class="mt-2 leyendaBlock" v-if="blockFusion" :disabled="formalizacion.disabledAsamblea">
        <b>
          Para poder continuar con el proceso es necesario que contacte a
          Correduría Digital
        </b>
      </label>

      <div class="row mt-3">
        <div class="col-6">
          <div class="row">
            <div class="col-md-12">
              <label for="">
                Se disuelve la sociedad
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <b-form-radio v-model="disolucion" name="radios37" :disabled="formalizacion.disabledAsamblea" value="Sí"
                @change="selectDisolucion('Sí')">
                Sí
              </b-form-radio>
            </div>
            <div class="col-md-3">
              <b-form-radio v-model="disolucion" name="radios37" value="No" :disabled="formalizacion.disabledAsamblea"
                @change="selectDisolucion('No')">
                No
              </b-form-radio>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-6">
          <div class="row">
            <div class="col-md-12">
              <label for="">
                Se liquida la sociedad
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <b-form-radio v-model="liquidacion" name="radios35" value="Sí" @change="selectLiquidacion('Sí')">
                Sí
              </b-form-radio>
            </div>
            <div class="col-md-3">
              <b-form-radio v-model="liquidacion" name="radios35" value="No" @change="selectLiquidacion('No')">
                No
              </b-form-radio>
            </div>
          </div>
        </div>
      </div>
      <label class="mt-2 leyendaBlock" v-if="blockLiquidacion">
        <b>
          Para poder continuar con el proceso es necesario que contacte a
          Correduría Digital
        </b>
      </label>
    </div>

    <label class="leyenda mt-4">
      Será necesario entregar todas las claves de RFC de los accionistas y
      administradores
    </label>

    <div class="mt-4">
      <div class="row">
        <div class="col-10">
          <b-button type="submit" class="btn-fill orange-btn bottom2" size="m"
            :disabled="formalizacion.disabledAsamblea">Guardar</b-button>
        </div>
        <div class="col-2">
          <b-button variant="success" class="btn-fill bottom2" size="m" :disabled="formalizacion.disabledAsamblea"
            @click="validar()">Validar</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DatosAsamblea",

  props: {
    formalizacion: { type: Object }
  },

  created() {
    if (this.formalizacion.convocatoria_lgsm == 0) {
      this.convocatoriaLgsm = "No";
    } else if (this.formalizacion.convocatoria_lgsm == 1) {
      this.convocatoriaLgsm = "Sí";
    }

    if (this.formalizacion.convocatoria_psm == 0) {
      this.convocatoriaPsm = "No";
    } else if (this.formalizacion.convocatoria_psm == 1) {
      this.convocatoriaPsm = "Sí";
    }

    if (this.formalizacion.representacion_cien == 0) {
      this.representacionCien = "No";
    } else if (this.formalizacion.representacion_cien == 1) {
      this.representacionCien = "Sí";
    }

    if (this.formalizacion.lista_asistencia_firmada == 0) {
      this.listaAsistencia = "No";
    } else if (this.formalizacion.lista_asistencia_firmada == 1) {
      this.listaAsistencia = "Sí";
    }

    if (this.formalizacion.asistentes_firma_acta == 0) {
      this.asistentesFirma = "No";
    } else if (this.formalizacion.asistentes_firma_acta == 1) {
      this.asistentesFirma = "Sí";
    }

    if (this.formalizacion.rfcs_accionistas == 0) {
      this.rfcsAccionistas = "No";
    } else if (this.formalizacion.rfcs_accionistas == 1) {
      this.rfcsAccionistas = "Sí";
    }

    if (this.formalizacion.sesion_instalada == 0) {
      this.sesionInstalada = "No";
    } else if (this.formalizacion.sesion_instalada == 1) {
      this.sesionInstalada = "Sí";
    }

    if (this.formalizacion.sesion_totalitaria == 0) {
      this.sesionTotalitaria = "No";
    } else if (this.formalizacion.sesion_totalitaria == 1) {
      this.sesionTotalitaria = "Sí";
    }

    if (this.formalizacion.nuevos_accionistas == 0) {
      this.nuevosAccionistas = "No";
    } else if (this.formalizacion.nuevos_accionistas == 1) {
      this.nuevosAccionistas = "Sí";
    }

    if (this.formalizacion.capital_extranjero == 0) {
      this.capitalExtranjero = "No";
    } else if (this.formalizacion.capital_extranjero == 1) {
      this.capitalExtranjero = "Sí";
    }

    if (this.formalizacion.modifica_estatutos == 0) {
      this.modificaEstatutos = "No";
    } else if (this.formalizacion.modifica_estatutos == 1) {
      this.modificaEstatutos = "Sí";
    }

    if (this.formalizacion.reforma_total == 0) {
      this.reformaTotal = "No";
    } else if (this.formalizacion.reforma_total == 1) {
      this.reformaTotal = "Sí";
    }

    if (this.formalizacion.modifica_objeto_social == 0) {
      this.modificaObjeto = "No";
    } else if (this.formalizacion.modifica_objeto_social == 1) {
      this.modificaObjeto = "Sí";
    }

    if (this.formalizacion.modifica_duracion_sociedad == 0) {
      this.cambioDuracion = "No";
    } else if (this.formalizacion.modifica_duracion_sociedad == 1) {
      this.cambioDuracion = "Sí";
    }

    if (this.formalizacion.modifica_denominacion_social == 0) {
      this.cambioDenominacion = "No";
    } else if (this.formalizacion.modifica_denominacion_social == 1) {
      this.cambioDenominacion = "Sí";
    }

    if (this.formalizacion.modifica_domicilio_social == 0) {
      this.cambioDomicilio = "No";
    } else if (this.formalizacion.modifica_domicilio_social == 1) {
      this.cambioDomicilio = "Sí";
    }

    if (this.formalizacion.modifica_facultades_funcionarios == 0) {
      this.modificaFacutlades = "No";
    } else if (this.formalizacion.modifica_facultades_funcionarios == 1) {
      this.modificaFacutlades = "Sí";
    }
    if (this.formalizacion.transforma_sociedad == 0) {
      this.transformaSociedad = "No";
    } else if (this.formalizacion.transforma_sociedad == 1) {
      this.transformaSociedad = "Sí";
    }
    if (this.formalizacion.fusion_sociedad == 0) {
      this.fusion = "No";
    } else if (this.formalizacion.fusion_sociedad == 1) {
      this.fusion = "Sí";
    }
    if (this.formalizacion.disolucion_sociedad == 0) {
      this.disolucion = "No";
    } else if (this.formalizacion.disolucion_sociedad == 1) {
      this.disolucion = "Sí";
    }
    if (this.formalizacion.liquidacion_sociedad == 0) {
      this.liquidacion = "No";
    } else if (this.formalizacion.liquidacion_sociedad == 1) {
      this.liquidacion = "Sí";
    }

    if (this.formalizacion.modifica_capital == 0) {
      this.modificaCapital = "No";
    } else if (this.formalizacion.modifica_capital == 1) {
      this.modificaCapital = "Sí";
    }

    if (this.formalizacion.revoca_funcionarios == 0) {
      this.revocaFuncionarios = "No";
    } else if (this.formalizacion.revoca_funcionarios == 1) {
      this.revocaFuncionarios = "Sí";
    }
  },

  watch: {
    representacionCien: "blockRepresentacionCien",
    asistentesFirma: "blockAsistentesFirma",
    sesionInstalada: "blockSesionInstalada",
    "formalizacion.monto_modificado": "blockMontoCapital",
    transformaSociedad: "blockTransformoSociedad",
    fusion: "blockFusionSociedad",
    liquidacion: "blockLiquidacionSociedad"
  },

  data() {
    return {
      convocatoriaLgsm: null,
      convocatoriaPsm: null,
      representacionCien: null,
      blockCien: false,
      listaAsistencia: null,
      asistentesFirma: null,
      blockFirma: false,
      rfcsAccionistas: null,
      sesionInstalada: null,
      blockSesion: false,
      sesionTotalitaria: null,
      modificaCapital: null,
      // capitalModificado: null,
      tipoModificado: null,
      blockMonto: false,
      nuevosAccionistas: null,
      capitalExtranjero: null,
      modificaEstatutos: null,
      reformaTotal: null,
      modificaObjeto: null,
      cambioDuracion: null,
      cambioDenominacion: null,
      cambioDomicilio: null,
      revocaFuncionarios: null,
      modificaFacutlades: null,
      transformaSociedad: null,
      blockTransformo: null,
      fusion: null,
      blockFusion: null,
      disolucion: null,
      liquidacion: null,
      blockLiquidacion: null
    };
  },

  methods: {
    validar() {
      this.$emit("validaForm", "DatosAsamblea");
    },

    blockRepresentacionCien() {
      if (this.representacionCien == "No") {
        this.formalizacion.disabled = true;
        this.blockCien = true;
      } else {
        this.formalizacion.disabled = false;
        this.blockCien = false;
      }
    },

    blockAsistentesFirma() {
      if (this.asistentesFirma == "No") {
        this.formalizacion.disabled = true;
        this.blockFirma = true;
      } else {
        this.formalizacion.disabled = false;
        this.blockFirma = false;
      }
    },

    blockSesionInstalada() {
      if (this.sesionInstalada == "No") {
        this.formalizacion.disabled = true;
        this.blockSesion = true;
      } else {
        this.formalizacion.disabled = false;
        this.blockSesion = false;
      }
    },

    blockMontoCapital() {
      if (this.formalizacion.monto_modificado > 200000000) {
        this.formalizacion.disabled = true;
        this.blockMonto = true;
      } else {
        this.formalizacion.disabled = false;
        this.blockMonto = false;
      }
    },

    blockTransformoSociedad() {
      if (this.transformaSociedad == "Sí") {
        this.formalizacion.disabled = true;
        this.blockTransformo = true;
      } else {
        this.formalizacion.disabled = false;
        this.blockTransformo = false;
      }
    },

    blockFusionSociedad() {
      if (this.fusion == "Sí") {
        this.formalizacion.disabled = true;
        this.blockFusion = true;
      } else {
        this.formalizacion.disabled = false;
        this.blockFusion = false;
      }
    },

    blockLiquidacionSociedad() {
      if (this.liquidacion == "Sí") {
        this.formalizacion.disabled = true;
        this.blockLiquidacion = true;
      } else {
        this.formalizacion.disabled = false;
        this.blockLiquidacion = false;
      }
    },

    selectLgsm(value) {
      if (value === "Sí") {
        this.formalizacion.convocatoria_lgsm = 1;
      } else if (value === "No") {
        this.formalizacion.convocatoria_lgsm = 0;
      }
    },

    selectPsm(value) {
      if (value === "Sí") {
        this.formalizacion.convocatoria_psm = 1;
      } else if (value === "No") {
        this.formalizacion.convocatoria_psm = 0;
      }
    },

    selectRepresentacionCien(value) {
      if (value === "Sí") {
        this.formalizacion.representacion_cien = 1;
      } else if (value === "No") {
        this.formalizacion.representacion_cien = 0;
        this.blockCien = true;
        this.formalizacion.disabled = true;
      }
    },

    selectLista(value) {
      if (value === "Sí") {
        this.formalizacion.lista_asistencia_firmada = 1;
      } else if (value === "No") {
        this.formalizacion.lista_asistencia_firmada = 0;
      }
    },

    selectAsistentesFirma(value) {
      if (value === "Sí") {
        this.formalizacion.asistentes_firma_acta = 1;
      } else if (value === "No") {
        this.formalizacion.asistentes_firma_acta = 0;
        this.blockFirma = true;
        this.formalizacion.disabled = true;
      }
    },

    selectRfcs(value) {
      if (value === "Sí") {
        this.formalizacion.rfcs_accionistas = 1;
      } else if (value === "No") {
        this.formalizacion.rfcs_accionistas = 0;
      }
    },

    selectSesion(value) {
      if (value === "Sí") {
        this.formalizacion.sesion_instalada = 1;
      } else if (value === "No") {
        this.formalizacion.sesion_instalada = 0;
      }
    },

    selectTotalitaria(value) {
      if (value === "Sí") {
        this.formalizacion.sesion_totalitaria = 1;
      } else if (value === "No") {
        this.formalizacion.sesion_totalitaria = 0;
      }
    },

    selectModificaCapital(value) {
      if (value === "Sí") {
        this.formalizacion.modifica_capital = 1;
      } else if (value === "No") {
        this.formalizacion.modifica_capital = 0;
      }
    },

    selectCambioDomicilio(value) {
      if (value === "Sí") {
        this.formalizacion.modifica_domicilio_social = 1;
      } else if (value === "No") {
        this.formalizacion.modifica_domicilio_social = 0;
      }
    },

    selectNuevosAccionistas(value) {
      if (value === "Sí") {
        this.formalizacion.nuevos_accionistas = 1;
      } else if (value === "No") {
        this.formalizacion.nuevos_accionistas = 0;
      }
    },

    selectCapitalExtranjero(value) {
      if (value === "Sí") {
        this.formalizacion.capital_extranjero = 1;
      } else if (value === "No") {
        this.formalizacion.capital_extranjero = 0;
      }
    },

    selectModificaEstatutos(value) {
      if (value === "Sí") {
        this.formalizacion.modifica_estatutos = 1;
      } else if (value === "No") {
        this.formalizacion.modifica_estatutos = 0;
      }
    },

    selectReformaTotal(value) {
      if (value === "Sí") {
        this.formalizacion.reforma_total = 1;
      } else if (value === "No") {
        this.formalizacion.reforma_total = 0;
      }
    },

    selectModificaObjeto(value) {
      if (value === "Sí") {
        this.formalizacion.modifica_objeto_social = 1;
      } else if (value === "No") {
        this.formalizacion.modifica_objeto_social = 0;
      }
    },

    selectCambioDuracion(value) {
      if (value === "Sí") {
        this.formalizacion.modifica_duracion_sociedad = 1;
      } else if (value === "No") {
        this.formalizacion.modifica_duracion_sociedad = 0;
      }
    },

    selectTipoModificado(value) {
      if (value === "Sí") {
        this.formalizacion.tipo_modificado = 1;
      } else if (value === "No") {
        this.formalizacion.tipo_modificado = 0;
      }
    },

    selectCambioDenominacion(value) {
      if (value === "Sí") {
        this.formalizacion.modifica_denominacion_social = 1;
      } else if (value === "No") {
        this.formalizacion.modifica_denominacion_social = 0;
      }
    },

    selectcCambioDomicilio(value) {
      if (value === "Sí") {
        this.formalizacion.modifica_domicilio_social = 1;
      } else if (value === "No") {
        this.formalizacion.modifica_domicilio_social = 0;
      }
    },

    selectRevocaFuncionarios(value) {
      if (value === "Sí") {
        this.formalizacion.revoca_funcionarios = 1;
      } else if (value === "No") {
        this.formalizacion.revoca_funcionarios = 0;
      }
    },

    selectModificaFacutlades(value) {
      if (value === "Sí") {
        this.formalizacion.modifica_facultades_funcionarios = 1;
      } else if (value === "No") {
        this.formalizacion.modifica_facultades_funcionarios = 0;
      }
    },

    selectTransformaSociedad(value) {
      if (value === "Sí") {
        this.formalizacion.transforma_sociedad = 1;
        this.blockTransformo = true;
        this.formalizacion.disabled = true;
      } else if (value === "No") {
        this.formalizacion.transforma_sociedad = 0;
      }
    },

    selectFusion(value) {
      if (value === "Sí") {
        this.formalizacion.fusion_sociedad = 1;

        this.blockFusion = true;
        this.formalizacion.disabled = true;
      } else if (value === "No") {
        this.formalizacion.fusion_sociedad = 0;
      }
    },

    selectDisolucion(value) {
      if (value === "Sí") {
        this.formalizacion.disolucion_sociedad = 1;
      } else if (value === "No") {
        this.formalizacion.disolucion_sociedad = 0;
      }
    },

    selectLiquidacion(value) {
      if (value === "Sí") {
        this.formalizacion.liquidacion_sociedad = 1;
        this.blockLiquidacion = true;
        this.formalizacion.disabled = true;
      } else if (value === "No") {
        this.formalizacion.liquidacion_sociedad = 0;
      }
    }
  }
};
</script>

<style scoped>
.bottom2 {
  float: right;
}

.leyendaBlock {
  color: #2937cf;
  font-size: 15px;
}

.leyenda {
  text-transform: lowercase !important;
  color: #79cff7;
}

.leyenda:first-letter {
  text-transform: capitalize;
}
</style>
