<template>
  <div>
    <div class="col-md-12">
      <ValidationProvider name="documento" rules="required" v-slot="{ errors }"
        :custom-messages="{ required: 'El documento es obligatorio' }">
        <b-form-group label="Documento:">
          <b-form-file v-model="currentDocumento.file" browse-text="Seleccionar" drop-placeholder="Cargar aquí"
            placeholder="Da click aquí o arrastra el archivo" :accept="acceptedFileTypes" />
          <span class="cardErrors">{{ errors[0] }}</span>
        </b-form-group>
      </ValidationProvider>
    </div>

    <div class="mt-4 text-center">
      <button type="submit" class="btn btn-fill float-right orange-btn">
        Guardar
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalEntregaDocumento",

  props: {
    currentDocumento: { type: Object },
    cotejo: { type: Object },
    acceptedFileTypes: {
      type: String,
      default: () => "application/pdf"
    }
  }
}
</script>

<style scoped>
.cardErrors {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* color: #9a9a9a; */
  color: #ea640b;
  font-size: 13px;
}

.leyenda {
  text-transform: lowercase !important;
  color: #79cff7;
}

.leyenda:first-letter {
  text-transform: capitalize;
}

.control-label {
  position: absolute;
  display: inline-block;
  margin-left: 30px;
}

.custom-number {
  width: 100px !important;
  display: inline-block;
  margin-left: 30px;
}
</style>
