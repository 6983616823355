<template>
  <div>
    <div v-if="user === 'Staff'">
      <div class="row">
        <div class="col-1">
          <div :class="['circle', cotejo.validaSolicitantes == 1 ? 'completado' : '']">
            <span class="number">
              <i v-if="cotejo.validaSolicitantes == 1" class="fa fa-check"></i>
              <i v-else class="fa fa-minus"></i>
            </span>
          </div>
        </div>
        <div class="col-10">
          <b-button block class="btn-fill blue-btn" @click="selected('ValidaComparecientes')">
            Solicitantes
          </b-button>
        </div>
      </div>

      <div class="row">
        <div class="col-1">
          <div :class="['circle', cotejo.validaDocumentos == 1 ? 'completado' : '']">
            <span class="number">
              <i v-if="cotejo.validaDocumentos == 1" class="fa fa-check"></i>
              <i v-else class="fa fa-minus"></i>
            </span>
          </div>
        </div>

        <div class="col-10">
          <b-button block class="btn-fill blue-btn" @click="selected('ValidaDocumentos')">
            Documentos
          </b-button>
        </div>
      </div>

      <div class="row">
        <div class="col-1" v-if="cotejo.envio_domicilio === 1">
          <div :class="['circle', cotejo.validaDomicilio == 1 ? 'completado' : '']">
            <span class="number">
              <i v-if="cotejo.validaDomicilio == 1" class="fa fa-check"></i>
              <i v-else class="fa fa-minus"></i>
            </span>
          </div>
        </div>

        <div class="col-10" v-if="cotejo.envio_domicilio === 1">
          <b-button block class="btn-fill blue-btn" @click="selected('Domicilio')">
            Domicilio
          </b-button>
        </div>
      </div>

      <div class="row">
        <div class="col-1">
          <div :class="['circle', cotejo.corredurias_id ? 'completado' : '']">
            <span class="number"><i v-if="cotejo.corredurias_id" class="fa fa-check"></i><i v-else
                class="fa fa-minus"></i></span>
          </div>
        </div>

        <div class="col-10">
          <b-button block class="btn-fill blue-btn" @click="selected('Correduria')">
            Asignar Correduria
          </b-button>
        </div>
      </div>

      <div class="row">
        <div class="col-1">
          <div :class="['circle', cotejo.validaDocumentos == 1 ? 'completado' : '']">
            <span class="number">
              <i v-if="cotejo.validaDocumentos == 1" class="fa fa-check"></i>
              <i v-else class="fa fa-minus"></i>
            </span>
          </div>
        </div>

        <div class="col-10">
          <b-button block class="btn-fill blue-btn" @click="selected('EstatusDocumentos')">
            Estatus Documentos
          </b-button>
        </div>
      </div>

      <div class="row" v-if="cotejo.pago_complementario">
        <div class="col-1">
          <div
            :class="['circle', [19, 20, 21, 74, 75, 76].includes(this.cotejo.id_estatus) || this.cotejo.cotizaciones_id_complementario ? 'completado' : '']">
            <span class="number">
              <i v-if="[19, 20, 21, 74, 75, 76].includes(this.cotejo.id_estatus) || this.cotejo.cotizaciones_id_complementario"
                class="fa fa-check"></i>
              <i v-else class="fa fa-minus"></i>
            </span>
          </div>
        </div>

        <div class="col-10">
          <b-button block class="btn-fill blue-btn" @click="selected('PagoComplementario')">
            Pago Complementario</b-button>
        </div>
      </div>

      <div class="row">
        <div class="col-1">
          <div :class="['circle', [19, 20, 21, 75, 76].includes(this.cotejo.id_estatus) ? 'completado' : '']">
            <span class="number">
              <i v-if="[19, 20, 21, 75, 76].includes(this.cotejo.id_estatus)" class="fa fa-check"></i>
              <i v-else class="fa fa-minus"></i>
            </span>
          </div>
        </div>

        <div class="col-10">
          <b-button block class="btn-fill blue-btn" @click="selected('FAD')">
            FAD
          </b-button>
        </div>
      </div>

      <div class="row">
        <div class="col-1">
          <div :class="['circle', [20, 21].includes(this.cotejo.id_estatus) ? 'completado' : '']">
            <span class="number">
              <i v-if="[20, 21].includes(this.cotejo.id_estatus)" class="fa fa-check"></i>
              <i v-else class="fa fa-minus"></i>
            </span>
          </div>
        </div>

        <div class="col-10">
          <b-button block class="btn-fill blue-btn" @click="selected('Actas')">
            Acuse
          </b-button>
        </div>
      </div>

      <div class="row">
        <div class="col-1">
          <div :class="['circle', [20, 21].includes(cotejo.id_estatus) ? 'completado' : '']">
            <span class="number">
              <i v-if="[20, 21].includes(cotejo.id_estatus)" class="fa fa-check"></i>
              <i v-else class="fa fa-minus"></i>
            </span>
          </div>
        </div>

        <div class="col-10">
          <b-button block class="btn-fill blue-btn" @click="selected('Expediente')">
            Expediente
          </b-button>
        </div>
      </div>

      <div class="row">
        <div class="col-1">
          <div :class="['circle', cotejo.id_estatus == 21 ? 'completado' : '']">
            <span class="number">
              <i v-if="cotejo.id_estatus == 21" class="fa fa-check"></i>
              <i v-else class="fa fa-minus"></i>
            </span>
          </div>
        </div>
        <div class="col-10">
          <b-button block class="btn-fill blue-btn" @click="selected('Terminado')">
            Terminado
          </b-button>
        </div>
      </div>
    </div>

    <div v-else-if="user === 'Client'">
      <div class="row">
        <div class="col-1">
          <div :class="['circle', cotejo.currentComponentValues.Pago ? 'completado' : '']">
            <span class="number">
              <i v-if="cotejo.currentComponentValues.Pago" class="fa fa-check"></i>
              <i v-else class="fa fa-minus"></i>
            </span>
          </div>
        </div>

        <div class="col-10">
          <b-button block class="btn-fill blue-btn" @click="selected('Pago')">
            Pago
          </b-button>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <b-button block class="btn-fill blue-btn">
            Complementa la información</b-button>
        </div>
      </div>
      <div class="row" v-if="cotejo.envio_domicilio === 1">
        <div class="col-1">
          <div :class="['circle', cotejo.currentComponentValues.Domicilio ? 'completado' : '']">
            <span class="number">
              <i v-if="cotejo.currentComponentValues.Domicilio" class="fa fa-check"></i>
              <i v-else class="fa fa-minus"></i></span>
          </div>
        </div>
        <div class="col-10">
          <b-button block class="btn-fill blue-btn" @click="selected('Domicilio')">
            Domicilio de Entrega
          </b-button>
        </div>
      </div>
      <div class="row">
        <div class="col-1">
          <div :class="['circle', cotejo.id_estatus >= 16 ? 'completado' : '']">
            <span class="number">
              <i v-if="cotejo.id_estatus >= 16" class="fa fa-check"></i>
              <i v-else class="fa fa-minus"></i></span>
          </div>
        </div>
        <div class="col-10">
          <b-button block class="btn-fill blue-btn child" @click="selected('Documentos')">
            Documentos</b-button>
        </div>
      </div>
      <div class="row" v-if="cotejo.pago_complementario">
        <div class="col-1">
          <div :class="['circle', cotejo.currentComponentValues.PagoComplementario ? 'completado' : '']">
            <span class="number">
              <i v-if="cotejo.currentComponentValues.PagoComplementario" class="fa fa-check"></i>
              <i v-else class="fa fa-minus"></i></span>
          </div>
        </div>
        <div class="col-10">
          <b-button block class="btn-fill blue-btn" @click="selected('PagoComplementario')">
            Pago Complementario
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TreeCotejo",

  props: {
    cotejo: { type: Object },
    user: { type: String }
  },

  methods: {
    selected(node) {
      this.$emit("node", node);
    }
  }
};
</script>

<style scoped>
.invi {
  display: none !important;
  ;
}

.completado {
  background-color: yellowgreen !important;
}

.circle {
  height: 20px;
  width: 20px;
  margin-top: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: silver;
}

.icon-title {
  height: 20px;
  width: 20px;
  margin-top: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.icon-title-fa {
  font-size: 11px;
  color: black;
}

.number {
  font-size: 11px;
  color: white;
}

.title-text {
  font-weight: 600;
  color: black !important;

}

.complete-text {

  color: black !important;
}

.blue-btn {

  padding-left: 0px;
  padding-right: 0px;
  background-color: transparent !important;
  border-color: transparent !important;
  color: rgb(154, 154, 154);
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: -10px;
  text-align: left !important;

}

.blue-btn:hover {
  color: rgb(154, 154, 154);
  background-color: transparent !important;
  border-color: transparent !important;
  background-color: #effbff !important;
  border-color: #effbff !important;
}

.blue-btn:active {
  background-color: #effbff !important;
  border-color: #effbff !important;

}


.led-green {
  margin: 10px auto;
  width: 10px;
  height: 10px;
  background-color: #43eb34;
  border-radius: 50%;
  box-shadow: #257a1d 0 -1px 7px 1px, inset #460 0 -1px 9px, #7d0 0 2px 12px;
}

.led-red {
  margin: 10px auto;
  width: 10px;
  height: 10px;
  background-color: #e60505;
  border-radius: 50%;
  box-shadow: #a60903 0 -1px 7px 1px, inset #600 0 -1px 9px, #f00 0 2px 12px;
}

.led-yellow {
  margin: 10px auto;
  width: 10px;
  height: 10px;
  background-color: #f7e00a;
  border-radius: 50%;
  box-shadow: #d1ca04 0 -1px 7px 1px, inset #c4b206 0 -1px 9px, #dd0 0 2px 12px;
}
</style>
