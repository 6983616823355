// API
import BaseApi from './base';

class NotificadosApi extends BaseApi {
  constructor() {
    super('notificados');
  }
}

export default new NotificadosApi();
