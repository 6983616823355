/**
 * Global configuration variables.
 */
export default {
  // TODO Eliminar urls hardcodeadas
  DEBUG: process.env.NODE_ENV === 'development',
  BASE_URL: process.env.BASE_URL,
  DOMAIN: process.env.VUE_APP_DOMAIN,
  API_URL: process.env.VUE_APP_API_URL,
  API_KEY: process.env.VUE_APP_API_KEY,
  URL_TEST: process.env.VUE_APP_URL_TEST,
  STG_BASE_URL: process.env.VUE_APP_STG_BASE_URL,
  STG_ACCESS_KEY: process.env.VUE_APP_STG_ACCESS_KEY,
  STG_ACCOUNT_NAME: process.env.VUE_APP_STG_ACCOUNT_NAME,
  STG_ACCOUNT_KEY: process.env.VUE_APP_STG_ACCOUNT_KEY,
  STG_CONTAINER_NAME: process.env.VUE_APP_STG_CONTAINER_NAME,
  GOOGLE_API_KEY: process.env.VUE_APP_GOOGLE_API_KEY,
  EXAMPLE_ENV_VARIABLE: process.env.VUE_APP_EXAMPLE_ENV_VARIABLE
};
