<template>
  <div class="container">
    <h4 class="cardD">Complementa Información</h4>
    <!-- Modales -->
    <!-- Template -->
    <div>
      <b-table ref="table" hover :fields="fieldsRatificantes" :items="ratificacion.ratificantes">
        <template #cell(acciones)="{ item }">
          <b-button variant="warning" size="sm" v-b-tooltip.hover title="Completar Información" class="editBtn"
            @click="editCompareciente(item)">
            <b-icon-pencil />
          </b-button>
        </template>
      </b-table>
    </div>
    <div class="mt-4">
      <div class="">
        <b-button class="btn-fill orange-btn bottom2" size="m" :disabled="isButtonDisabled"
          @click="sendAllChecksPassed()">
          {{ buttonText }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
// API
import { CatDocumentosApi } from "@/api";
import { DocumentosXServiciosApi } from "@/api";
import { ComparecientesApi } from "@/api";
import BaseCheckboxVue from "../../../../components/Inputs/BaseCheckbox.vue";

export default ({
  name: "ComplementaInformacion",

  props: {
    ratificacion: { type: Object }
  },

  computed: {
    fieldsRatificantes() {
      let fieldsRatificantes = [
        { key: "nombreCompleto", label: "Nombre", class: "text-center" },
        { key: "infoBasica", label: "Info. Básica Completa", class: "text-center" },
        { key: "infoComplementaria", label: "Info. Complemantaria Completa", class: "text-center" },
        { key: "docsCompletos", label: "Documentos Completos", class: "text-center" }
      ];

      if ((!this.ratificacion.staff && this.ratificacion.estatus_id <= 46) || (this.ratificacion.staff && this.ratificacion.validaComparecientes == 0)) {
        fieldsRatificantes.push({ key: "acciones", label: "Acciones", class: "text-center" });
      }

      return fieldsRatificantes;
    },

    buttonText() {
      return this.ratificacion.staff ? 'Validar' : 'Continuar';
    },

    isButtonDisabled() {
      // return this.infoIncomplete() || this.ratificacion.estatus_id != 24 || this.ratificacion.validaComparecientes == 1;
      return this.ratificacion.estatus_id < 46 || this.ratificacion.validaComparecientes == 1;
    }
  },

  created() {
    this.loading = true;
    this.checkBasicInfo()
      .then(() => this.checkCompleteInfo())
      .then(() => this.checkDocuments())
      .then(() => this.refreshTable())
      .catch((error) => {
        console.log(error);
        this.showErrorToast('No se pudo obtener la información. Intente más tarde')
      })
      .finally(() => { this.loading = false; })
  },

  data() {
    return {
    }
  },

  methods: {
    showErrorToast(msg) {
      this.$toast.error(msg, {
        timeout: 4000, //false,
        hideProgressBar: false,
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        closeButton: "button",
        icon: true,
      });
    },


    async checkBasicInfo() {
      const promises = this.ratificacion.ratificantes.map(async ratificante => {
        const infoBasica = await this.isInfoComplete(ratificante.id, ratificante.persona, 'basic');
        infoBasica == 1 ? ratificante.infoBasica = '✔' : ratificante.infoBasica = 'X';
        return ratificante;
      })
      this.ratificacion.ratificantes = await Promise.all(promises);
      return;
    },

    async checkCompleteInfo() {
      const promises = this.ratificacion.ratificantes.map(async ratificante => {
        const infoComplementaria = await this.isInfoComplete(ratificante.id, ratificante.persona, 'complete');
        infoComplementaria == 1 ? ratificante.infoComplementaria = '✔' : ratificante.infoComplementaria = 'X';
        return ratificante;
      })
      this.ratificacion.ratificantes = await Promise.all(promises);
      return;
    },

    async isInfoComplete(comparecientes_id, persona, tipo_info) {
      let tipo_persona = 'pf'
      if (persona == 'Moral') {
        tipo_persona = 'pm'
      }
      let tipo_busqueda = 'new_constitucion';
      if (tipo_info == 'complete') {
        tipo_busqueda = 'ratificacion'
      }
      return await ComparecientesApi.validacion({
        comparecientes_id: comparecientes_id,
        tipo: `${tipo_busqueda}_${tipo_persona}`
      })
    },

    async checkDocuments() {
      return new Promise((resolve, reject) => {
        try {
          this.ratificacion.ratificantes.forEach(ratificante => {
            let completed = '✔';
            if (ratificante.documentos.length == 0) {
              completed = 'X';
            } else {
              if (!this.areDocumentsCompleted(ratificante.documentos, ratificante.persona)) { completed = 'X'; }
            }
            ratificante.docsCompletos = completed;
          })
          resolve();
        } catch (error) {
          reject(error);
        }
      })
    },

    areDocumentsCompleted(documentos, persona) {
      let idsToCheck = [17, 19]
      if (persona == 'Moral') {
        idsToCheck = [14, 51, 52]
      }
      return this.containsIds(documentos, idsToCheck);
    },

    containsIds(arr, ids) {
      return ids.every(id => arr.some(obj => parseInt(obj.cat_documentos_id) === id));
    },

    refreshTable() {
      this.$refs.table.refresh();
      return;
    },

    editCompareciente(item) {
      this.$router.push({
        name: 'EditComparecientes',
        params: {
          id: item.id,
          ratificacion: true
        }
      })
      return;
    },

    infoIncomplete() {
      const fieldsToCheck = ['infoBasica', 'infoComplementaria', 'docsCompletos'];
      const complete = this.ratificacion.ratificantes.every(ratificante => fieldsToCheck.every(field => ratificante[field] === '✔'))
      return !complete;
    },

    sendAllChecksPassed() {
      this.$emit('allChecksPassed');
    }
  }
})
</script>
