<template>
  <div class="content">
    <b-breadcrumb>
      <b-breadcrumb-item :to="{ path: '/dashboard' }">Inicio</b-breadcrumb-item>
      <b-breadcrumb-item active>Nueva Copia Certificada</b-breadcrumb-item>
    </b-breadcrumb>

    <div class="container">
      <div v-if="loading">
        <div id="loader">
          <font-awesome-icon icon="spinner" spin />
        </div>
      </div>
    </div>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog-route" hide-footer id="modal-confirm-dialog-route">
      <h4 class="text-center">
        ¿Seguro que quiere salir de la página?
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog-route')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="beforeLeave(true)">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog" hide-footer id="modal-confirm-dialog">
      <h4 class="text-center">
        ¿Seguro que quiere salir sin guardar los cambios?
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-cotizacion" hide-footer id="modal-confirm-cotizacion">
      <h4 class="text-center">
        ¿Seguro que quiere continuar al pago?
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-cotizacion')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="generaObjetosBD()">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop id="modal-help-cotejos-1" size="xl" centered hide-footer>
      <div>
        <b-carousel id="carousel-cotejos-1" :interval="0" controls indicators background="#ababab">
          <b-carousel-slide :img-src="require('@/assets/img/cotejos/help/2_1.png')"></b-carousel-slide>
          <b-carousel-slide :img-src="require('@/assets/img/cotejos/help/2_2.png')"></b-carousel-slide>
          <b-carousel-slide :img-src="require('@/assets/img/cotejos/help/2_3.png')"></b-carousel-slide>
        </b-carousel>
      </div>
    </b-modal>

    <div class="container-fluid">
      <b-row>
        <b-col class="col-3">
          <b-card>
            <TreeCotejo :cotejo="cotejo" :user="'Client'" @node="selected" />
          </b-card>
          <div class="d-flex justify-content-center">
            <b-card no-body v-if="currentComponentBody === 'Solicitantes'"
              :img-src="require('@/assets/img/cotejos/help/1.png')" img-alt="Descripción del solicitante" tag="article"
              style="max-width: 20rem;">
            </b-card>
            <b-card no-body v-if="currentComponentBody === 'Documentos'"
              :img-src="require('@/assets/img/cotejos/help/2.png')" img-alt="Descripción de los documentos"
              tag="article" style="max-width: 20rem;">
              <b-button class="orange-btn" @click="$bvModal.show('modal-help-cotejos-1')">
                Más información
              </b-button>
            </b-card>
            <b-card no-body v-if="currentComponentBody === 'Cotizacion'"
              :img-src="require('@/assets/img/cotejos/help/3.png')" img-alt="Descripción de la cotización" tag="article"
              style="max-width: 20rem;">
            </b-card>
          </div>
        </b-col>
        <b-col class="col-9">
          <card>
            <b-row>
              <b-col class="col-12">
                <div v-if="currentComponentBody === 'Solicitantes'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form ref="solicitantes" @submit.prevent="handleSubmit(changeCurrentComponentBody('Documentos'))">
                      <Solicitantes :cotejo="cotejo" @submit.prevent @allChecksPassed="submitForm('solicitantes')"
                        @node="selected" @toggleOnLoadingEvent="toggleOnLoadingHandler"
                        @toggleOffLoadingEvent="toggleOffLoadingHandler" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'Documentos'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form ref="documentos" @submit.prevent="handleSubmit(changeCurrentComponentBody('Cotizacion'))">
                      <Documentos :cotejo="cotejo" @submit.prevent @allChecksPassed="submitForm('documentos')"
                        @node="selected" @toggleOnLoadingEvent="toggleOnLoadingHandler"
                        @toggleOffLoadingEvent="toggleOffLoadingHandler" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'Cotizacion'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form ref="cotizacion" @submit.prevent="handleSubmit(showCotizacionModal())">
                      <Cotizacion :cotejo="cotejo" @submit.prevent @allChecksPassed="submitForm('cotizacion')"
                        @node="selected" @toggleOnLoadingEvent="toggleOnLoadingHandler"
                        @toggleOffLoadingEvent="toggleOffLoadingHandler" />
                    </form>
                  </ValidationObserver>
                </div>
              </b-col>
            </b-row>
          </card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import TreeCotejo from "@/views/clients/procesos/cotejo/TreeCotejo.vue";
import Cotizacion from "@/views/clients/procesos/cotejo/infoBase/Cotizacion.vue";
import Documentos from "@/views/clients/procesos/cotejo/infoBase/Documentos.vue";
import Solicitantes from "@/views/clients/procesos/cotejo/infoBase/Solicitante.vue";
import Card from "src/components/Cards/Card.vue";

import { ClientesApi, ComparecientesApi, CotejosApi, CotizacionesApi, DocumentosApi, DocumentosXServiciosApi } from "@/api";
import Storage from "@/utils/storage";

const storage = new Storage();

export default {
  name: "AddCotejo",

  components: {
    Card,
    TreeCotejo,
    Solicitantes,
    Documentos,
    Cotizacion
  },

  created() {
  },

  beforeRouteLeave(to, from, next) {
    if (!to.name === 'EditCotejo') {
      this.$refs["modal-confirm-dialog-route"].show();
      this.next = next;
    }
    else {
      this.next = next;
      this.beforeLeave(true);
    }
  },

  data: () => ({
    loading: false,
    next: null,
    prenode: null,

    currentComponentBody: "Solicitantes",
    cliente_id: 0,

    cotejo: {
      // Objeto creado en BD
      id: null,
      id_estatus: null,
      id_prioridad: 2,
      id_cotizaciones: null,
      id_clientes: null,
      id_comparecientes: null,
      pago_complementario: null,
      id_cotizaciones_pago_complementario: null,
      envio_domicilio: 0,
      id_comparecientes_representante: null,

      // Valores de Tree
      currentComponentValues: {
        Solicitantes: false,
        Documentos: false,
        Cotizacion: false,
        Terminado: false,
      },

      // Solicitantes
      solicitantes: [],
      telefono: {},

      // Extras
      envio_domicilio_bool: false,

      // Documentos
      documentos: [],

      // Totales cotizacion
      costo_total: 0,
    },

    comparecientes: []
  }),

  methods: {
    toggleOnLoadingHandler() {
      console.log("toggle On");
      this.loading = true;
    },

    toggleOffLoadingHandler() {
      console.log("toggle Off");
      this.loading = false;
    },

    showErrorToast(msg) {
      this.$toast.error(msg, {
        timeout: 4000, //false,
        hideProgressBar: false,
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        closeButton: "button",
        icon: true,
      });
    },

    // Navegación
    async beforeLeave(answer) {
      if (answer) {
        this.next();
      }
    },

    async selected(node) {
      const keys = Object.keys(this.cotejo.currentComponentValues);
      const values = Object.values(this.cotejo.currentComponentValues);
      const dest = keys.find(value => value == node);
      const currentIndex = keys.findIndex(value => value == this.currentComponentBody);
      const destIndex = keys.findIndex(value => value == node);
      const lastCompletedIndex = values.lastIndexOf(true);
      // Ir mas adelante
      if (destIndex > currentIndex) {
        // Dest verde
        if (this.cotejo.currentComponentValues[dest]) {
          this.currentComponentBody = dest;
        }
        // Dest amarillo
        else {
          var lastCompleted = keys[lastCompletedIndex + 1];
          if ((destIndex - lastCompletedIndex) === 1) {
            this.currentComponentBody = dest;
          }
          else {
            if (lastCompletedIndex === -1) {
              lastCompleted = keys[0]
            }
            this.$toast.warning("Completa la sección " + lastCompleted + " para poder continuar", {
              timeout: 3000, //false,
              hideProgressBar: false,
              position: "top-right",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              closeButton: "button",
              icon: true,
            });
          }
        }
      }
      // Ir mas atras
      else {
        this.currentComponentBody = dest;
      }
    },

    changeCurrentComponentBody(body) {
      this.cotejo.currentComponentValues[this.currentComponentBody] = true;
      this.currentComponentBody = body;
    },

    submitForm(formToSubmit) {
      this.$refs[formToSubmit].dispatchEvent(new Event('submit'));
    },

    showCotizacionModal() {
      this.$refs["modal-confirm-cotizacion"].show();
    },

    hideCotizacionModal() {
      this.$refs["modal-confirm-cotizacion"].hide();
    },

    generaObjetosBD() {
      this.loading = true;
      this.hideCotizacionModal();
      this.getClienteId()
        .then(() => this.fillComparecientes())
        .then(() => this.postCotejo())
        .then(() => this.modifyCotizacion())
        .then(() => this.addDocumentos())
        .then(() => this.addAnexos())
        .then(() => this.moveToEditCotejos())
        .catch((error) => {
          console.log(error);
          this.showErrorToast('No se pudieron obtener los datos. Intente más tarde.')
        })
        .finally(() => { this.loading = false; })
    },

    async getClienteId() {
      var clienteEmail = storage.getItem("user");
      const { id } = await ClientesApi.clienteId(clienteEmail);
      this.cliente_id = id;
    },

    async fillComparecientes() {
      const moralesComparecientes = this.cotejo.solicitantes.filter(compareciente => compareciente.persona === 'Moral');
      const fisicasComparecientes = this.cotejo.solicitantes.filter(compareciente => compareciente.persona !== 'Moral');

      await this.processComparecientes(moralesComparecientes);
      await this.processComparecientes(fisicasComparecientes);
    },

    async processComparecientes(comparecientes) {
      console.log(comparecientes);

      for (let i = 0; i < comparecientes.length; i++) {
        let compareciente = comparecientes[i];

        if (!('cliente_id' in compareciente)) {
          const index = this.cotejo.solicitantes.indexOf(compareciente);
          if (index !== -1) {
            this.cotejo.solicitantes[index] = await this.postCompareciente(compareciente);
          }
        }
      }
    },

    async postCompareciente(compareciente) {
      const tipo_validacion = compareciente.persona == 'Moral' ? "new_constitucion_pm" : "new_constitucion_pf";
      const personaMoral = this.cotejo.solicitantes.find(item => item.persona === 'Moral');
      const idMoral = personaMoral ? personaMoral.id : null;
      const moral_representada_id = compareciente.persona == 'Física' ? idMoral : null;

      const response = await ComparecientesApi.create({
        cliente_id: this.cliente_id,
        denominacion_social: compareciente.denominacion_social || null,
        nombre: compareciente.nombre || null,
        paterno: compareciente.paterno || null,
        materno: compareciente.materno || null,
        tipo_validacion: tipo_validacion,
        moral_representada_id: moral_representada_id,
        persona: compareciente.persona,
        telefonos: [compareciente.telefono],
        email: compareciente.email || null
      })

      return {
        ...compareciente,
        ...response
      }
    },

    async postCotejo() {
      const personaMoral = this.cotejo.solicitantes.find(item => item.persona === 'Moral');
      const id_principal = personaMoral ? personaMoral.id : this.cotejo.solicitantes[0].id;

      const personaFisica = this.cotejo.solicitantes.find(item => item.persona === 'Física');
      const id_repre = personaMoral ? personaFisica.id : null;

      this.cotejo = {
        ...this.cotejo,
        ...await CotejosApi.create({
          id_prioridad: this.cotejo.urgencia,
          id_clientes: this.cliente_id,
          id_comparecientes: id_principal,
          pago_complementario: null,
          envio_domicilio: this.cotejo.envio_domicilio,
          corredurias_id: 1,
          id_comparecientes_representante: id_repre
        })
      }
    },

    async modifyCotizacion() {
      return await CotizacionesApi.edit(this.cotejo.id_cotizaciones, {
        precio_total: this.cotejo.costo_total,
        activo: 1
      });
    },

    async addDocumentos() {
      const docsPrincipales = this.cotejo.documentos.filter(doc => !('anexoDe' in doc));

      const principalPromises = docsPrincipales.map(async documento => {
        const documento_id = await this.postDocumento({
          cat_documentos_id: documento.catDocumentos_id,
          paginas: parseInt(documento.paginas),
          nombre: documento.nombre,
          copias_certificadas: parseInt(documento.copias_certificadas)
        });
        documento.documento_id = documento_id;
      })

      const additionalPromises = [
        this.postDocumento({ cat_documentos_id: 75, nombre: 'Carta Responsiva' }),
        this.postDocumento({ cat_documentos_id: 76, nombre: 'Acta PDF' })
      ];

      await Promise.all([...principalPromises, ...additionalPromises]);
    },

    async addAnexos() {
      const docsWithAnexo = this.cotejo.documentos.filter(doc => 'tiene_anexo' in doc && doc.tiene_anexo);

      const promises = docsWithAnexo.map(async documento => {
        const documento_id = await this.postDocumento({
          cat_documentos_id: 73,
          auto_referencia: documento.documento_id,
          paginas: parseInt(documento.paginas_anexo),
          nombre: `Anexo de ${documento.tipo}`
        });
        documento.documento_id = documento_id;
      });

      await Promise.all(promises);
    },

    async postDocumento({
      cat_documentos_id,
      auto_referencia = null,
      descripcion = null,
      paginas = null,
      nombre = null,
      copias_certificadas = null
    }) {
      const { id } = await DocumentosApi.create({
        documento: "",
        cat_documentos_id,
        auto_referencia,
        descripcion,
        paginas,
        copias_certificadas,
        nombre
      });
      await DocumentosXServiciosApi.create({
        documentos_id: id,
        cat_servicios_id: 4,
        proceso_id: this.cotejo.id
      });
      return id;
    },

    moveToEditCotejos() {
      this.$router.push({
        path: `/dashboard/clienttasks/editcotejo/${this.cotejo.id}`,
      })
      return;
    }
  },
}
</script>

<style scoped>
.custom-modal .modal-dialog {
  max-width: none !important;
  width: auto !important;
}
</style>
