<template>
  <div class="container">
    <b-modal no-close-on-backdrop centered ref="documento-modal" title="" hide-footer id="modalC" size="l">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(putDocumento)">
          <ModalDocumento :currentDocumento="currentDocumento" @submit.prevent />
        </form>
      </ValidationObserver>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog" hide-footer id="modal-confirm-dialog">
      <h4 class="text-center">¿Seguro que quiere eliminar el documento?</h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="deleteDocumento">OK</b-button>
    </b-modal>

    <h4 class="cardD">Información de los Documentos a Notificar</h4>

    <div class="mt-4">
      <!-- <div class="col-12 mt-4"> -->
      <label for="">Agregar documento</label>
      <b-button variant="success" class="btn-fill spaceL" size="sm" @click="addDocumento">+</b-button>
    </div>

    <div>
      <b-table ref="table" hover :fields="fieldsCuadro" :items="ratificacion.documentos">
        <template #cell(acciones)="{ item }">
          <b-button variant="warning" size="sm" v-b-tooltip.hover title="Editar" class="editBtn"
            @click="editDocumento(item)">
            <b-icon-pencil />
          </b-button>
          <b-button variant="danger" size="sm" v-b-tooltip.hover title="Eliminar" @click="predeleteDocumento(item.id)">
            <b-icon-trash />
          </b-button>
        </template>
      </b-table>
    </div>

    <div class="mt-4">
      <div class="">
        <b-button class="btn-fill orange-btn bottom2" size="m" @click="checkDocumentos()">Continuar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import ModalDocumento from "@/views/clients/procesos/ratificaciones/infoBase/modales/ModalDocumento.vue";
// API
import { CatDocumentosApi } from "@/api";

export default {
  name: "Documentos",

  components: { ModalDocumento },

  props: {
    ratificacion: { type: Object },
  },

  computed: {
    fieldsCuadro() {
      let fieldsCuadro = [
        { key: "index", label: "", class: "text-center" },
        { key: "nombre", label: "Nombre", class: "text-center" },
        { key: "paginas", label: "No. Páginas", class: "text-center" },

        { key: "acciones", label: "Acciones", class: "text-center" },
      ];
      return fieldsCuadro;
    },
  },

  created() {
  },

  data() {
    return {
      selectedDocumento: null,
      currentDocumento: null,
      currentDocumentoId: null,

      optionsDocumentos: [],
      id_anexo: 0
    }
  },

  methods: {
    showErrorToast(msg) {
      this.$toast.error(msg, {
        timeout: 4000, //false,
        hideProgressBar: false,
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        closeButton: "button",
        icon: true,
      });
    },

    addDocumento() {
      this.$refs["documento-modal"].show();
      this.currentDocumento = {
        paginas_anexos: [],
        numero_anexos: 0
      };
    },

    putDocumento() {
      console.log(this.currentDocumento)
      if (!this.currentDocumento.id) {
        let id = this.ratificacion.documentos.length + 1;
        this.currentDocumento.id = id;
        this.ratificacion.documentos.push(this.currentDocumento);
      }
      this.$refs.table.refresh();
      this.$refs["documento-modal"].hide();
    },

    editDocumento(item) {
      console.log(item)
      this.$refs["documento-modal"].show();
      this.currentDocumento = item;
    },

    addAnexo(documento) {
    },

    predeleteDocumento(id) {
      this.currentDocumentoId = id;
      this.$refs["modal-confirm-dialog"].show();
    },

    deleteDocumento() {
      let index = this.ratificacion.documentos.findIndex(
        (o) => o.id === this.currentDocumentoId
      );
      if (this.ratificacion.documentos[index].anexoDe) {
        this.ratificacion.documentos.forEach(documento => {
          if (documento.id == this.ratificacion.documentos[index].anexoDe) {
            documento.tiene_anexo = false;
          }
        })
      }
      if (this.ratificacion.documentos[index + 1]) {
        if (this.ratificacion.documentos[index + 1].anexoDe) {
          this.ratificacion.documentos.splice(index + 1, 1);
        }
      }
      this.ratificacion.documentos.splice(index, 1);

      this.$refs["modal-confirm-dialog"].hide();
      this.$refs.table.refresh();
    },

    checkDocumentos() {
      this.loading = true;
      this.isAnyDocumento()
        .then((continuar) => {
          if (continuar) {
            this.$emit('allChecksPassed');
          }
        })
        .catch((error) => {
          console.log(error);
          this.showErrorToast('Sucedió un error. Intente más tarde')
        })
        .finally(() => {
          this.loading = false;
        })
    },

    isAnyDocumento() {
      return new Promise((resolve, reject) => {
        if (this.ratificacion.documentos.length > 0) {
          resolve(true);
        } else {
          this.showErrorToast('Debe ingresar al menos 1 documento.');
          resolve(false);
        }
      });
    },
  }
}
</script>

<style scoped>
.spaceL {
  margin-left: 20px;
}

.bottom2 {
  float: right;
}

.control-label {
  margin-left: 30px;
  position: absolute;
  vertical-align: middle;
}
</style>
