// API
import BaseApi from './base';

class FormalizacionActasApi extends BaseApi {
  constructor() {
    super('formalizacionActas');
  }
}

export default new FormalizacionActasApi();
