// API
import BaseApi from './base';

class CatEntidadesFederativasApi extends BaseApi {
  constructor() {
    super('catEntidades');
  }
}

export default new CatEntidadesFederativasApi();
