var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('div',[_c('div',{attrs:{"id":"loader"}},[_c('font-awesome-icon',{attrs:{"icon":"spinner","spin":""}})],1)]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"nombre","rules":"required","custom-messages":{ required: 'El nombre es obligatorio' }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"type":"text","label":"Nombre(s)","placeholder":"Nombre(s)"},model:{value:(_vm.currentAdministrador.nombre),callback:function ($$v) {_vm.$set(_vm.currentAdministrador, "nombre", $$v)},expression:"currentAdministrador.nombre"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"paterno","rules":"required","custom-messages":{
        required: 'El apellido paterno es obligatorio'
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"type":"text","label":"Apellido Paterno","placeholder":"Apellido Paterno"},model:{value:(_vm.currentAdministrador.paterno),callback:function ($$v) {_vm.$set(_vm.currentAdministrador, "paterno", $$v)},expression:"currentAdministrador.paterno"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-4"},[_c('base-input',{attrs:{"type":"text","label":"Apellido Materno","placeholder":"Apellido Materno"},model:{value:(_vm.currentAdministrador.materno),callback:function ($$v) {_vm.$set(_vm.currentAdministrador, "materno", $$v)},expression:"currentAdministrador.materno"}})],1)]),_c('div',{staticClass:"row"}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-5"},[_c('ValidationProvider',{attrs:{"name":"rfc","rules":"required|length:13","custom-messages":{
        required: 'El RFC es obligatorio',
        length: 'Debe tener 13 caracteres'
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"type":"text","label":"RFC","placeholder":"RFC"},model:{value:(_vm.currentAdministrador.rfc),callback:function ($$v) {_vm.$set(_vm.currentAdministrador, "rfc", $$v)},expression:"currentAdministrador.rfc"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"porcentaje","rules":"required","custom-messages":{ required: 'El cargo es obligatorio' }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',[_vm._v("Cargo")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentAdministrador.cargo),expression:"currentAdministrador.cargo"}],staticClass:"custom-select",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.currentAdministrador, "cargo", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"Administrador único"}},[_vm._v("Administrador único")]),_c('option',{attrs:{"value":"Miembro del consejo"}},[_vm._v("Miembro del consejo")]),_c('option',{attrs:{"value":"Apoderado"}},[_vm._v("Apoderado")])]),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_vm._m(0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('button',{staticClass:"btn btn-fill float-right orange-btn",attrs:{"type":"submit"}},[_vm._v(" Guardar ")])])
}]

export { render, staticRenderFns }