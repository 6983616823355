// API
import BaseApi from './base';

class ClientesApi extends BaseApi {
  constructor() {
    super('clientes');
  }

  clienteId(email, params = {}) {
    return this.request.get(`/clientes/id/${email}`, {
      params: {
        ...params,
      },
    });
  }

  clienteNombreComercial(id, params = {}) {
    return this.request.get(`/clientes/comercial/${id}`, {
      params: {
        ...params,
      },
    });
  }

  allComerciales(params = {}) {
    return this.request.get(`/clientes/comerciales/all`, {
      params: {
        ...params,
      },
    });
  }

  deleteUser(id, params = {}) {
    return this.request.delete(`/clientes/usuarios/${id}`, {
      params: {
        ...params,
      },
    });
  }
}

export default new ClientesApi();
