<template>
  <div>
    <div class="row mt-4">
      <div class="col-md-12">
        <ValidationProvider name="nombre" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El nombre es obligatorio',
        }">
          <base-input type="text" label="Nombre" placeholder="Nombre" v-model="currentCompareciente.nombre">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-12">
        <ValidationProvider name="paterno" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El paterno es obligatorio',
        }">
          <base-input type="text" label="Paterno" placeholder="Paterno" v-model="currentCompareciente.paterno">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-12">
        <base-input type="text" label="Materno" placeholder="Materno" default="" v-model="currentCompareciente.materno">
        </base-input>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-md-4" hidden>
        <ValidationProvider name="principal" v-slot="{ errors }">
          <!-- <label class="control-label">Es el solicitante principal</label> -->
          <b-form-checkbox v-model="currentCompareciente.principal" @change="deshabilitaRepresentante">
            Es el solicitante principal
          </b-form-checkbox>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4" v-if="morales.length > 0 && currentCompareciente.persona !== 'Moral'" hidden>
        <ValidationProvider name="representante" v-slot="{ errors }">
          <!-- <label class="control-label">Representante</label> -->
          <b-form-checkbox v-model="representante" @change="deshabilitaPrincipal">
            Representante
          </b-form-checkbox>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4" v-if="representante" hidden>
        <ValidationProvider name="representada" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La persona moral representada es obligatoria',
        }">
          <label for="">Persona moral representada</label>
          <select class="custom-select" v-model="currentCompareciente.representada"
            @change="agregaRepresentadanombre($event)">
            <option v-for="option in morales" :key="option.id" :value="option.id">
              {{ option.denominacion_social }}
            </option>
          </select>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row mt-4"></div>

    <div class="mt-4 text-center">
      <button type="submit" class="btn btn-fill float-right orange-btn">
        {{ isEmpty ? "Actualizar" : "Agregar" }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentCompareciente: { type: Object },
    solicitantes: { type: Array },
    solicitanteToAdd: {
      type: Number,
      default: 2
    },
    isEmpty: { type: Boolean, default: false },
  },

  created() {
    this.definemorales();
    this.whatSolicitanteCanAdd();
  },

  data() {
    return {
      optionsPersona: [
        { text: "Persona Física", value: "Física" },
        { text: "Persona Moral", value: "Moral" },
      ],
      morales: [],
      representante: false,

      form: {
        nombre: null,
        paterno: null,
        materno: null,
        denominacion_social: null,

        porcentaje: null,
        capital_fijo: null,
        capital_variable: null,

        persona: null,
      },
      isUpdating: false,
    };
  },

  methods: {
    definemorales() {
      this.solicitantes.forEach(element => {
        if (element.persona === "Moral") {
          this.morales.push(element);
        }
      })
      if (this.currentCompareciente.representada) {
        this.representante = true;
      }
    },

    deshabilitaPrincipal() {
      if (this.representante) {
        this.currentCompareciente.principal = false;
      } else {
        this.currentCompareciente.representada = null;
        this.currentCompareciente.representadanombre = null;
      }
    },

    deshabilitaRepresentante() {
      if (this.currentCompareciente.principal) {
        this.representante = false;
        this.currentCompareciente.representada = null;
        this.currentCompareciente.representadanombre = null;
      }
    },

    agregaRepresentadanombre(event) {
      let index = this.morales.findIndex(
        (o) => o.id == event.target.value
      );
      // console.log(index)
      this.currentCompareciente.representadanombre = this.morales[index].denominacion_social;
    },

    whatSolicitanteCanAdd() {
      console.log("...... ");
      switch (this.solicitanteToAdd) {
        case 0:
          this.optionsPersona = [
            { text: "Persona Física", value: "Física" },
          ];
          break;
        case 1:
          this.optionsPersona = [
            { text: "Persona Moral", value: "Moral" },
          ];
          break;
        case 2:
        default:
          this.optionsPersona = [
            { text: "Persona Física", value: "Física" },
            { text: "Persona Moral", value: "Moral" },
          ];
          break;
      }
      if (this.solicitanteToAdd === 2) {
        this.optionsPersona = [
          { text: "Persona Física", value: "Física" },
          { text: "Persona Moral", value: "Moral" },
        ];
      }
    }
  }
}
</script>

<style scoped>
.cardErrors {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* color: #9a9a9a; */
  color: #ea640b;
  font-size: 13px;
}

.leyenda {
  text-transform: lowercase !important;
  color: #79cff7;
}

.leyenda:first-letter {
  text-transform: capitalize;
}
</style>