import { render, staticRenderFns } from "./EstatusDocumentos.vue?vue&type=template&id=326d36f1&scoped=true"
import script from "./EstatusDocumentos.vue?vue&type=script&lang=js"
export * from "./EstatusDocumentos.vue?vue&type=script&lang=js"
import style0 from "./EstatusDocumentos.vue?vue&type=style&index=0&id=326d36f1&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "326d36f1",
  null
  
)

export default component.exports