<template>
  <div>
    <table class="tabla">
      <tr class="date">
        <th>
          Ciudad de México a {{ day }} de {{ month }} de {{ year }}
        </th>
      </tr>
    </table>

    <table class="tabla">
      <tr>
        <td style="width: 62.5%; font-weight: bolder;">
          Correduria Digital, S. de R.L. de C.V.
        </td>
      </tr>
      <tr>
        <td>
          Av. Paseo de las Palmas 731, oficina 202,
        </td>
      </tr>
      <tr>
        <td>
          Lomas de Chapultepec, 11000, Miguel Hidalgo,
        </td>
      </tr>
      <tr>
        <td>
          Ciudad de México, México
        </td>
      </tr>
    </table>

    <table class="tabla" style="margin-top: 5px; border-spacing: 0 15px;">
      <tr>
        <td>
          Por medio del presente escrito, autorizo e instruyo a Correduría Digital, S. de R.L. de C.V. para que
          gestione con el Corredor Público de su elección, la elaboración del cotejo de los documentos
          contenidos como anexos en esta solicitud y/o aquellos que sean presentados directamente en la
          oficina que haya sido asignada, aceptando en su totalidad los términos y condiciones del servicio.
        </td>
      </tr>
      <tr>
        <td>
          El solicitante declara a Correduria Digital y al Corredor Público que ésta designe, bajo protesta de
          decir verdad:
        </td>
      </tr>
      <tr>
        <td>
          <ul style="list-style-type:'- ';">
            <li>
              Que la totalidad de documentos, correspondientes al servicio solicitado, es aquella que ha
              quedado descrita en la plataforma de “Correduria Digital”, conforme a la información
              proporcionada por el solicitante. <br><br>

              Entiendo que el staff de Correduría Digital revisará la información proporcionada, conforme a la
              documentación electrónica y/o física que sea entrega, por lo que, en caso de existir
              diferencias entre éstas, entiendo que se generarán ajustes a la cotización proporcionada,
              mismos que, en este acto, me obligo a cubrir para que se pueda concluir con la prestación del
              servicio. <br><br>
            </li>
            <li>
              Que los archivos en PDF que, en su caso, han sido enviados a través de la plataforma de
              Correduría Digital, han sido digitalizados por el solicitante, y los mismos corresponden
              <b>EXACTAMENTE</b> al/los documento/s que presentaré para su cotejo; los cuales no difieren
              en ninguna de sus partes, ni en volumen ni en contenido a aquellos que serán presentados para
              cotejo. <br><br>

              Entiendo que, en caso de que, al presentar los documentos originales para cotejo, éstos
              difieran, por cualquier motivo, del archivo digitalizado enviado, se generarán cargos
              adicionales y Correduría Digital no podrá garantizar los tiempos de entrega señalados. <br>
              Comprendo que, en caso de presentar un testimonio o cualquier tipo de documento
              identificable de forma individual, a cotejo, se deberá escanear, subir y presentar exactamente
              el mismo ejemplar del cual requiero obtener la copia certificada (por ejemplo, en caso de
              escanear el primer testimonio y presentar en las oficinas de la correduría un testimonio
              distinto, se deberá realizar de nuevo el trabajo y dicha situación generará un costo extra). <br><br>
            </li>
            <li>
              Que me encuentro en legítima posesión de los documentos que serán entregados para cotejo y
              que, en su caso, han sido previamente digitalizados y enviados a través de la plataforma de
              Correduría Digital. <br><br>
            </li>
            <li>
              Que los documentos objeto de la solicitud de servicio: (i) son mercantiles; (ii) no se refieren a
              documentos de los señalados como no cotejables; (iii) se encuentran completos; y (iv) no han
              sido alterados ni modificados, ni presentan enmendaduras. <br>
            </li>
          </ul>
        </td>
      </tr>
      <tr>
        <td style="text-align: center;">
          <b>“EN TODOS LOS CASOS ES NECESARIO LA PRESENTACIÓN DE LOS DOCUMENTOS ORIGINALES PARA SU COTEJO.” </b>
        </td>
      </tr>
      <tr>
        <td style="text-align: center;">
          <b>“NO SE ENTREGARÁ COTEJO ALGUNO SIN LA PRESENTACIÓN DE DOCUMENTOS ORIGINALES”</b> <br>
        </td>
      </tr>
      <tr>
        <td>
          Atentamente,
        </td>
      </tr>
    </table>

    <table class="tabla">
      <tr class="space" style="height: 100px;"></tr>
      <tr>
        <th colspan="10" class="section">
          {{ nombre_firmante }}
        </th>
      </tr>
      <tr class="space" style="height: 50px;"></tr>
      <tr>
        <td class="space" style="width: 20%;"></td>
        <td class="user" style="width: 60%; background-color: white !important; border-bottom: solid 2px black;"></td>
        <td class="space" style="width: 20%;"></td>
      </tr>
      <tr v-if="apoderado">
        <td class="space" style="width: 20%;"></td>
        <td style="width: 60%; vertical-align: top; font-size: 14px; text-align: center; background-color: white; ">
          Por: {{ nombre_apoderado }}
        </td>
        <td colspan="2" class="space" style="width: 20%;"></td>
      </tr>
      <tr v-if="apoderado">
        <td class="space" style="width: 20%;"></td>
        <td style="width: 60%; vertical-align: top; font-size: 14px; text-align: center; background-color: white; ">
          Cargo: Representante Legal
        </td>
        <td colspan="2" class="space" style="width: 20%;"></td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: "CartaResponsivaTemplate",

  props: {
    cotejo: { type: Object },
  },

  created() {
    if (this.cotejo.solicitantes.length > 1) {
      this.apoderado = true;
    }

    const today = new Date();
    const optionsMonth = { month: 'long' };
    const yyyy = today.getFullYear();
    const formatedMonth = today.toLocaleDateString('es-MX', optionsMonth);

    let dd = today.getDate();

    if (dd < 10) dd = '0' + dd;

    this.day = dd.toString();
    this.month = formatedMonth
    this.year = yyyy.toString();

    let firmante = this.cotejo.solicitantes[0];
    let apoderado = null;

    if (this.cotejo.solicitantes.length > 1) {
      firmante = this.cotejo.solicitantes.filter(sol => sol.persona === 'Física')[0];
      apoderado = this.cotejo.solicitantes.filter(sol => sol.persona === 'Moral')[0];
      this.nombre_apoderado = apoderado.nombreCompleto;
    }

    this.nombre_firmante = firmante.nombreCompleto;
  },

  data() {
    return {
      apoderado: false,
      day: null,
      month: null,
      year: null,
      nombre_firmante: null,
      nombre_apoderado: null
    }
  },

}
</script>
