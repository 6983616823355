// API
import BaseApi from './base';

class NotificacionesApi extends BaseApi {
  constructor() {
    super('notificaciones');
  }
}

export default new NotificacionesApi();
