<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <ValidationProvider name="persona" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La persona es obligatoria',
        }">
          <label for="">Persona</label>
          <select class="custom-select" v-model="currentNotificado.persona"
            :disabled="currentNotificado.aceptado || notificacion.validaNotificado == 1">
            <option v-for="option in optionsPersona" :key="option.value" :value="option.value">
              {{ option.text }}
            </option>
          </select>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row mt-4" v-if="currentNotificado.persona === 'Moral'">
      <div class="col-md-12">
        <ValidationProvider name="denominacion_social" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La denominacion social es obligatoria',
        }">
          <base-input :disabled="currentNotificado.aceptado || notificacion.validaNotificado == 1" type="text"
            label="Denominacion social" placeholder="Denominacion social" v-model="currentNotificado.razon_social">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row mt-4" v-else-if="currentNotificado.persona === 'Física'">
      <div class="col-md-4">
        <ValidationProvider name="nombre" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El nombre es obligatorio',
        }">
          <base-input :disabled="currentNotificado.aceptado || notificacion.validaNotificado == 1" type="text"
            label="Nombre" placeholder="Nombre" v-model="currentNotificado.nombre">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <ValidationProvider name="paterno" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El paterno es obligatorio',
        }">
          <base-input :disabled="currentNotificado.aceptado || notificacion.validaNotificado == 1" type="text"
            label="Paterno" placeholder="Paterno" v-model="currentNotificado.paterno">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <base-input :disabled="currentNotificado.aceptado || notificacion.validaNotificado == 1" type="text"
          label="Materno" placeholder="Materno" v-model="currentNotificado.materno">
        </base-input>
      </div>
    </div>

    <div class="row mt-4" v-if="currentNotificado.persona === 'Moral'">
      <div class="col-md-12">
        <ValidationProvider name="nombre_notificado" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El nombre del notificado es obligatoria',
        }">
          <base-input :disabled="currentNotificado.aceptado || notificacion.validaNotificado == 1" type="text"
            label="Nombre del Notificado" placeholder="Nombre del Notificado"
            v-model="currentNotificado.nombre_notificado">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>


    <div class="row mt-4"></div>
    <div class="mt-4 text-center" v-if="!currentNotificado.aceptado == true && !notificacion.validaNotificado == 1">
      <button type="submit" class="btn btn-fill float-right orange-btn">
        Guardar
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentNotificado: { type: Object },
    notificacion: { type: Object }
  },

  created() {
  },

  data() {
    return {
      optionsPersona: [
        { text: "Persona Física", value: "Física" },
        { text: "Persona Moral", value: "Moral" },
      ],
    };
  },

  methods: {
  }
}
</script>

<style scoped>
.cardErrors {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* color: #9a9a9a; */
  color: #ea640b;
  font-size: 13px;
}

.leyenda {
  text-transform: lowercase !important;
  color: #79cff7;
}

.leyenda:first-letter {
  text-transform: capitalize;
}
</style>
