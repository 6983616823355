var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('div',[_c('div',{attrs:{"id":"loader"}},[_c('font-awesome-icon',{attrs:{"icon":"spinner","spin":""}})],1)]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"nombre","rules":"required","custom-messages":{ required: 'El nombre es obligatorio' }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"type":"text","label":"Nombre(s)","placeholder":"Nombre(s)"},model:{value:(_vm.currentApoderado.nombre),callback:function ($$v) {_vm.$set(_vm.currentApoderado, "nombre", $$v)},expression:"currentApoderado.nombre"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"paterno","rules":"required","custom-messages":{
        required: 'El apellido paterno es obligatorio',
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"type":"text","label":"Apellido Paterno","placeholder":"Apellido Paterno"},model:{value:(_vm.currentApoderado.paterno),callback:function ($$v) {_vm.$set(_vm.currentApoderado, "paterno", $$v)},expression:"currentApoderado.paterno"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-4"},[_c('base-input',{attrs:{"type":"text","label":"Apellido Materno","placeholder":"Apellido Materno"},model:{value:(_vm.currentApoderado.materno),callback:function ($$v) {_vm.$set(_vm.currentApoderado, "materno", $$v)},expression:"currentApoderado.materno"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('b-form-group',{attrs:{"label":"Facultades"}},[_c('b-form-checkbox-group',{attrs:{"id":"checkbox-group-1","options":_vm.options_facultades,"name":"flavour-1"},model:{value:(_vm.currentApoderado.facultades),callback:function ($$v) {_vm.$set(_vm.currentApoderado, "facultades", $$v)},expression:"currentApoderado.facultades"}})],1)],1)]),_c('div',{staticClass:"row"},[(_vm.complete)?_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"rfc","rules":"required|length:13","custom-messages":{
        required: 'El RFC es obligatorio',
        length: 'Debe tener 13 caracteres',
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"type":"text","label":"RFC","placeholder":"RFC"},model:{value:(_vm.currentApoderado.rfc),callback:function ($$v) {_vm.$set(_vm.currentApoderado, "rfc", $$v)},expression:"currentApoderado.rfc"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3293201657)})],1):_vm._e()]),_vm._m(0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('button',{staticClass:"btn btn-fill float-right orange-btn",attrs:{"type":"submit"}},[_vm._v(" Guardar ")])])
}]

export { render, staticRenderFns }