// API
import BaseApi from './base';

class RecuperarPassApi extends BaseApi {
  recuperarPass(data, params = {}) {
    return this.request.post(`/recuperarPass`, data, {
      params: {
        ...params
      }
    });
  }

  validaReestabPass(data, params = {}) {
    return this.request.post(`/validaReestabPass`, data, {
      params: {
        ...params
      }
    });
  }

  reestablecePass(data, params = {}) {
    return this.request.post(`/reestablecerPass`, data, {
      params: {
        ...params
      }
    });
  }
}

export default new RecuperarPassApi();
