// API
import BaseApi from './base';

class CotejosApi extends BaseApi {
  constructor() {
    super('cotejos');
  }
}

export default new CotejosApi();
