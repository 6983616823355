<template>
  <div class="container">
    <h4 class="cardD">Representantes orgánicos y apoderados</h4>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog" hide-footer id="modal-confirm-dialog">
      <h4 class="text-center">
        ¿Seguro que quiere eliminar al representante o apoderado?
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="deleteApoderado">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="apoderado-modal" title="" hide-footer id="modalC" size="xl">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(putApoderado)">
          <ModalApoderados :currentApoderado="currentApoderado" @submit.prevent :options_facultades="options_facultades"
            :complete="false" />
        </form>
      </ValidationObserver>
    </b-modal>

    <div class="row mt-5">
      <div class="col-12">
        <label for="">Apoderados</label>
        <b-button :disabled="constitutiva.disabled" variant="success" class="btn-fill spaceL" size="sm"
          @click="addApoderado">
          +
        </b-button>

        <b-table hover :fields="fieldsCuadro" :items="constitutiva.apoderados">
          <template #cell(acciones)="{ item }">
            <b-button :disabled="constitutiva.disabled" variant="warning" size="sm" v-b-tooltip.hover title="Editar"
              class="editBtn" @click="editApoderado(item)">
              <b-icon-pencil />
            </b-button>
            <b-button :disabled="constitutiva.disabled" variant="danger" size="sm" v-b-tooltip.hover title="Eliminar"
              @click="predeleteApoderado(item.id)">
              <b-icon-trash />
            </b-button>
          </template>
        </b-table>

        <label class="mt-2 leyenda" v-if="constitutiva.apoderados.length > 1">
          La cotización básica incluye solo un apoderado.
        </label>
      </div>
    </div>

    <div class="col-6" v-if="false">
      <label for="">Exclusión de accionistas</label>

      <div v-if="constitutiva.roleStaff">
        <b-form-checkbox v-model="constitutiva.exclusion_accionistas" :disabled="constitutiva.disabledEspeciales">
        </b-form-checkbox>
      </div>
      <div v-else>
        <b-form-checkbox v-model="constitutiva.exclusion_accionistas" :disabled="constitutiva.disabled">
        </b-form-checkbox>
      </div>

      <label class="mt-2 leyenda" v-if="constitutiva.exclusion_accionistas">
        La cotización básica no incluye esta opción.
      </label>
    </div>

    <div class="mt-4">
      <label>Dueño beneficiario</label>

      <label class="beneficiario">
        Conforme a lo establecido en la Ley Federal para la Prevención e Identificación de Operaciones con Recursos de
        Procedencia Ilícita, su Reglamento y Reglas de Carácter General, manifiesto, bajo protesta de decir verdad, que:
      </label>

      <b-button :id="`popover-1`" variant="info" size="sm">
        <i class="fa fa-question"> </i>
      </b-button>
      <b-popover :target="`popover-1`" :placement="'left'" title="LEY FEDERAL PARA LA PREVENCIÓN E IDENTIFICACIÓN DE OPERACIONES CON
        RECURSOS DE PROCEDENCIA ILÍCITA." triggers="hover focus" :content="`Artículo 3.- Para los efectos de esta Ley, se entenderá por:
…III. Beneficiario Controlador, a la persona o grupo de personas que:
a) Por medio de otra o de cualquier acto, obtiene el beneficio derivado de éstos y es quien, en última instancia, ejerce los derechos de uso, goce, disfrute, aprovechamiento o disposición de un bien o servicio, o
b) Ejerce el control de aquella persona moral que, en su carácter de cliente o usuario, lleve a cabo actos u operaciones con quien realice Actividades Vulnerables, así como las personas por cuenta de quienes celebra alguno de ellos.
Se entiende que una persona o grupo de personas controla a una persona moral cuando, a través de la titularidad de valores, por contrato o de cualquier otro acto, puede:
i) Imponer, directa o indirectamente, decisiones en las asambleas generales de accionistas, socios unos órganos equivalentes, o nombrar o destituir a la mayoría de los consejeros, administradores o sus equivalentes;
ii) Mantener la titularidad de los derechos que permitan, directa o indirectamente, ejercer el voto respecto de más del cincuenta por ciento del capital social, o
iii) Dirigir, directa o indirectamente, la administración, la estrategia o las principales políticas de la misma.`
          "></b-popover>

      <div class="row mt-2">
        <div class="col-1">
          <b-form-radio v-model="constitutiva.beneficiario_pld" name="pld" value="1" @change="selectBeneficiarioPLD(1)">
          </b-form-radio>
        </div>
        <div class="col-md-11">
          <b>NO</b> tengo conocimiento del dueño beneficiario o beneficiario controlador, pues actúo por mi propio
          derecho.
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-1">
          <b-form-radio v-model="constitutiva.beneficiario_pld" name="pld" value="2" @change="selectBeneficiarioPLD(2)">
          </b-form-radio>
        </div>
        <div class="col-md-11">
          <b>NO</b> tengo conocimiento del dueño beneficiario o beneficiario controlador,ya que actué por cuenta de mi
          representada, por lo que son sus accionistas quienes se benefician de las consecuencias jurídicas del
          otorgamiento del instrumento público correspondiente.
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-1">
          <b-form-radio v-model="constitutiva.beneficiario_pld" name="pld" value="3" @change="selectBeneficiarioPLD(3)">
          </b-form-radio>
        </div>
        <div class="col-md-11">
          <b>SI</b> tengo conocimiento del dueño beneficiario o beneficiario controlador.
        </div>
      </div>
      <!-- <br /> -->
      <div class="row mt-4" v-if="constitutiva.beneficiario_pld === 3">
        <div class="col-12">
          <b-form-group id="input-group-beneficiario" label="Nombre beneficiario:"
            label-for="input-beneficiario">
            <b-form-input id="input-beneficiario" type="text" placeholder="Nombre completo"
              v-model="constitutiva.nombre_beneficiario_pld"></b-form-input>
            <span class="cardErrors">Para varios beneficiarios, incluirlos separándolos por comas.</span>
          </b-form-group>
        </div>
      </div>

      <br />
      <label class="beneficiario">
        Conforme a lo establecido en el Código Fiscal de la Federación, manifiesto, bajo protesta de decir verdad, que:
      </label>

      <b-button :id="`popover-2`" variant="info" size="sm">
        <i class="fa fa-question"> </i>
      </b-button>
      <b-popover :target="`popover-2`" :placement="'left'"
        title="Artículo 32-B Quáter CFF. Para efectos de este Código se entenderá por beneficiario controlador a la persona física o grupo de personas físicas que:"
        triggers="hover focus" :content="`I. Directamente o por medio de otra u otras o de cualquier acto jurídico, obtiene u obtienen el beneficio derivado de su participación en una persona moral, un fideicomiso o cualquier otra figura jurídica, así como de cualquier otro acto jurídico, o es quien o quienes en última instancia ejerce o ejercen los derechos de uso, goce, disfrute, aprovechamiento o disposición de un bien o servicio o en cuyo nombre se realiza una transacción, aun y cuando lo haga o hagan de forma contingente.
II. Directa, indirectamente o de forma contingente, ejerzan el control de la persona moral, fideicomiso o cualquier otra figura jurídica.
Se entiende que una persona física o grupo de personas físicas ejerce el control cuando, a través de la titularidad de valores, por contrato o por cualquier otro acto jurídico, puede o pueden:
a) Imponer, directa o indirectamente, decisiones en las asambleas generales de accionistas, socios u órganos equivalentes, o nombrar o destituir a la mayoría de los consejeros, administradores o sus equivalentes.
b) Mantener la titularidad de los derechos que permitan, directa o indirectamente, ejercer el voto respecto de más del 15% del capital social o bien.
c) Dirigir, directa o indirectamente, la administración, la estrategia o las principales políticas de la persona moral, fideicomiso o cualquier otra figura jurídica.`
          "></b-popover>

      <b-button :id="`popover-3`" variant="primary fill" size="sm" style="margin-left:10px !important; ">
        Criterios
      </b-button>
      <b-popover :target="`popover-3`" :placement="'left'"
        title="CRITERIOS PARA LA DETERMINACIÓN DE LA CONDICIÓN DE BENEFICIARIO CONTROLADOR DE LAS PERSONAS MORALES"
        triggers="hover focus" :content="`Para los efectos del artículo 32-B Ter del CFF, en la identificación de los beneficiarios controladores, se deberá aplicar lo dispuesto en el artículo 32-B Quáter, fracciones I y II, incisos a), b) y c) de forma sucesiva, como criterios para su determinación.
Para efectos de la obtención de la información a que se refiere el artículo 32-B Ter del CFF, las personas morales deberán identificar, verificar y validar la información sobre los beneficiarios controladores, indicando los porcentajes de participación en el capital de la persona moral, incluyendo la información relativa a la cadena de titularidad, en los casos en que el beneficiario controlador lo sea indirectamente. Se entiende por cadena de titularidad el supuesto en que se ostente la propiedad indirecta, a través de otras personas morales. Asimismo, las personas morales también deberán identificar, verificar y validar la información relativa a la cadena de control, en los casos en los que el beneficiario controlador lo sea por medios distintos a la propiedad. Se entiende por cadena de control el supuesto en que se ostente el control indirectamente, a través de otras personas morales, fideicomisos o cualquier otra figura jurídica.
Cuando no se identifique a persona física alguna bajo los criterios establecidos en esta regla, en relación con el artículo 32-B Quáter del CFF, se considerará como beneficiario controlador a la persona física que ocupe el cargo de administrador único de la persona moral o equivalente. En caso de que la persona moral cuente con un consejo de administración u órgano equivalente, cada miembro de dicho consejo se  considerará como beneficiario controlador de la persona moral.`
          "></b-popover>

      <div class="row mt-2">
        <div class="col-1">
          <b-form-radio v-model="constitutiva.beneficiario_cff" name="cff" value="4" @change="selectBeneficiarioCFF(4)">
          </b-form-radio>
        </div>
        <div class="col-md-11">
          Actúo por mi propio derecho, por lo que <b>NO</b> hay beneficiario controlador distinto al suscrito.
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-1">
          <b-form-radio v-model="constitutiva.beneficiario_cff" name="cff" value="6" @change="selectBeneficiarioCFF(6)">
          </b-form-radio>
        </div>
        <div class="col-md-11">
          <b>NO</b> tengo conocimiento del beneficiario controlador bajo los criterios establecidos en la regla
          2.8.1.20. de la RMF. No obstante lo anterior, tengo conocimiento de la forma en la que está compuesta la
          administración de mi representada, por lo que haré entrega de la información y documentación que me sea
          solicitada.
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-1">
          <b-form-radio v-model="constitutiva.beneficiario_cff" name="cff" value="5" @change="selectBeneficiarioCFF(5)">
          </b-form-radio>
        </div>
        <div class="col-md-11">
          <b>SI</b> tengo conocimiento del o los beneficiarios controladores, por lo que haré entrega de la
          información y documentación que me sea solicitada.
        </div>
      </div>
      <!-- <br /> -->
      <div class="row mt-4" v-if="constitutiva.beneficiario_cff === 5">
        <div class="col-12">
          <b-form-group id="input-group-beneficiario" label="Nombre beneficiario:"
            label-for="input-beneficiario">
            <b-form-input id="input-beneficiario" type="text" placeholder="Nombre completo"
              v-model="constitutiva.nombre_beneficiario_cff"></b-form-input>
            <span class="cardErrors">Para varios beneficiarios, incluirlos separándolos por comas.</span>
          </b-form-group>
        </div>
      </div>
    </div>

    <div class="mt-12">
      <div class="">
        <b-button type="submit" class="btn-fill orange-btn bottom2" size="m"
          :disabled="constitutiva.disabled">Continuar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import ModalApoderados from "@/views/clients/procesos/constitucion/infoBase/modales/ModalApoderados.vue";

export default {
  name: "RepresentantesYApoderados",

  components: {
    ModalApoderados
  },

  created() {
    this.getFacultades();
  },

  props: {
    constitutiva: { type: Object },
    cat_facultades: { type: Array }
  },

  computed: {
    fieldsCuadro() {
      let fieldsCuadro = [
        { key: "index", label: "", class: "text-center" },
        { key: "nombre", label: "Nombre", class: "text-center" },
        { key: "paterno", label: "Paterno", class: "text-center" },
        { key: "materno", label: "Materno", class: "text-center" },
        // { key: "rfc", label: "RFC", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" }
      ];
      return fieldsCuadro;
    }
  },

  data() {
    return {
      currentApoderado: null,
      currentApoderadoId: null,
      options_facultades: [],
      // cat_facultades: [],

      form: {
        apoderados: []
      }
    };
  },

  methods: {
    selectBeneficiarioPLD(value) {
      this.constitutiva.beneficiario_pld = value;
    },

    selectBeneficiarioCFF(value) {
      this.constitutiva.beneficiario_cff = value;
    },

    async getFacultades() {
      this.loading = true;

      try {
        // const { value } = await CatFacultadesApi.list({
        //   page: 0,
        //   registersPerPage: 0,
        // });

        this.cat_facultades.forEach(element => {
          this.options_facultades.push({
            text: element.facultad,
            value: element.facultad
          });
        });

      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;

      }
    },

    addApoderado() {
      this.$refs["apoderado-modal"].show();
      this.currentApoderado = {};
    },

    editApoderado(item) {
      this.$refs["apoderado-modal"].show();
      this.currentApoderado = item;
    },

    putApoderado() {
      if (!this.currentApoderado.id) {
        const id = this.constitutiva.apoderados.length + 1;

        this.currentApoderado.id = id;

        const a = Object.assign({}, this.currentApoderado);

        this.constitutiva.apoderados.push(a);
      } else {
        const index = this.constitutiva.apoderados.findIndex(o => o.id === this.currentApoderado.id);
        const a = Object.assign({}, this.currentApoderado);

        // this.constitutiva.apoderados[index] = a;
        this.constitutiva.apoderados.splice([index], 1, a);
      }

      this.$refs["apoderado-modal"].hide();
    },

    predeleteApoderado(id) {
      this.currentApoderadoId = id;

      this.$refs["modal-confirm-dialog"].show();
    },

    deleteApoderado() {
      const index2 = this.constitutiva.apoderados.findIndex(o => o.id === this.currentApoderadoId);

      this.constitutiva.apoderados.splice(index2, 1);

      this.$refs["modal-confirm-dialog"].hide();
    }
  }
};
</script>

<style scoped>
.bottom2 {
  /* position: fixed;
  right: 2%;
  bottom: 5%;
  overflow: visible;
  z-index: 100; */
  float: right;
}

.spaceL {
  margin-left: 1%;
}

.leyenda {
  text-transform: lowercase !important;
  color: #79cff7;
}

.leyenda:first-letter {
  text-transform: capitalize;
}
</style>
