<template>
    <div class="container">
        <div class="content">
            <h1 class="number">404</h1>
            <p class="text">Oops! La página que estás buscando no fue encontrada.</p>
            <router-link to="/" class="text">Volver al inicio</router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: "NotFound"
};
</script>
<style lang="scss" scoped>
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.content {
    text-align: center;
}
.number {
    font-size: 100px;
    font-weight: bold;
    color: #f00;
}
.text {
    font-size: 20px;
    margin: 20px 0;
}
</style>
