import Storage from "@/utils/storage";
const storage = new Storage();

export function isLoggedIn() {
    let isLogged = false;
    let item = storage.getItem('logged');
    let token = storage.getItem('accessToken');
    // console.log('auth logged: '+item)
    // if (!item) {
      if(item){
        isLogged=true;
      }
    if (token == null) {
      isLogged = false;
    }
    // }
    // console.log(isLogged)
    return isLogged
}

export function roleIs() {
  let role = null;
  let item = storage.getItem('role');
  // console.log('auth role: '+item)

  if(item != null){
    role = item;
  }

  return role
}

export function getToken() {
  return storage.getItem('accessToken');
}