<template>
  <div class="content">
    <b-breadcrumb>
      <b-breadcrumb-item :to="{ path: '/dashboard/comparecientes' }">Comparecientes</b-breadcrumb-item>
      <b-breadcrumb-item active>Nuevo compareciente</b-breadcrumb-item>
    </b-breadcrumb>

    <div class="container">
      <div v-if="loading">
        <div id="loader">
          <font-awesome-icon icon="spinner" spin />
        </div>
      </div>
    </div>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog-dom" hide-footer id="modal-confirm-dialog-dom">
      <h4 class="text-center">¿Seguro que quiere eliminar el domicilio?</h4>
      <h5 class="text-center">Se eliminarán todos los datos.</h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog-dom')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="deleteDomicilio()">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog-tel" hide-footer id="modal-confirm-dialog-tel">
      <h4 class="text-center">¿Seguro que quiere eliminar el teléfono?</h4>
      <h5 class="text-center">Se eliminarán todos los datos.</h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog-tel')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="deleteTelefono()">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog-repre" hide-footer id="modal-confirm-dialog-repre">
      <h4 class="text-center">¿Seguro que quiere eliminar el representante?</h4>
      <h5 class="text-center">Se eliminarán todos los datos.</h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog-repre')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="deleteRepresentante()">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog-repre-dom" hide-footer
      id="modal-confirm-dialog-repre-dom">
      <h4 class="text-center">¿Seguro que quiere eliminar el domicilio?</h4>
      <h5 class="text-center">Se eliminarán todos los datos.</h5>
      <b-button class="mt-3" variant="danger"
        @click="$bvModal.hide('modal-confirm-dialog-repre-dom')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="deleteDomicilioRepresentante()">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog-repre-tel" hide-footer
      id="modal-confirm-dialog-repre-tel">
      <h4 class="text-center">¿Seguro que quiere eliminar el teléfono?</h4>
      <h5 class="text-center">Se eliminarán todos los datos.</h5>
      <b-button class="mt-3" variant="danger"
        @click="$bvModal.hide('modal-confirm-dialog-repre-tel')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="deleteTelefonoRepresentante()">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop ref="domicilio-modal" title="" hide-footer id="modalD" size="xl">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(postDomicilio)" @change="getAddressInMapCompar(null)">
          <ModalDomicilio :currentDomicilio="currentDomicilio" @submit.prevent />
        </form>
        <!-- @pais="changePais(currentDomicilio.pais)" -->
      </ValidationObserver>
    </b-modal>

    <b-modal no-close-on-backdrop ref="telefono-modal" title="" hide-footer id="modalT" size="lg">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(postTelefono)">
          <ModalTelefono :currentTelefono="currentTelefono" @submit.prevent />
        </form>
      </ValidationObserver>
    </b-modal>

    <b-modal no-close-on-backdrop ref="representante-modal" title="" hide-footer id="modalR" size="xl">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(postRepresentante)">
          <ModalRepresentante :currentRepresentante="currentRepresentante" @submit.prevent
            @state="mismaDataConstitutiva" />
        </form>
      </ValidationObserver>

      <card>
        <h4 class="cardD">
          Domicilio
          <!-- <button
            v-if="currentRepresentante.domicilios.length === 0"
            @click="addDomicilioRepresentante"
            class="btn btn-success btn-fill"
          >
            <i class="nc-icon nc-simple-add"></i>
          </button> -->
          <b-button v-if="currentRepresentante.domicilios.length === 0" variant="success" class="btn-fill" size="sm"
            @click="addDomicilioRepresentante">+</b-button>
        </h4>
        <!-- <div
          v-for="item in currentRepresentante.domicilios"
          :key="item.id"
          @change="getAddressInMapRepre(item.id)"
        >
          <div class="col-md-12 text-right mb-3">
            <b-button
              variant="danger"
              size="sm"
              v-b-tooltip.hover
              title="Eliminar"
              @click="predeleteDomicilioRepresentante(item.id)"
            >
              <b-icon-trash />
            </b-button>
          </div>
          <Domicilios :domicilio="item" />
          <br />
          <br />
        </div> -->
        <b-table :fields="fieldsDomicilios" :items="currentRepresentante.domicilios" hover>
          <template #cell(index)="{ index }">
            {{ getIndex(index + 1) }}
          </template>
          <template #cell(mapUrl)="{ item }">
            <DrawGoogleMap @location="updateCoordinates" :lat="parseFloat(item.lat)" :lng="parseFloat(item.lng)"
              style="width:300px;" />
          </template>
          <template #cell(acciones)="{ item }">
            <b-button variant="warning" size="sm" v-b-tooltip.hover title="Editar" class="editBtn"
              @click="editDomicilioRepresentante(item)">
              <b-icon-pencil />
            </b-button>
            <b-button variant="danger" @click="predeleteDomicilioRepresentante(item.id)" size="sm" v-b-tooltip.hover
              title="Eliminar">
              <b-icon-trash />
            </b-button>
          </template>
        </b-table>
      </card>

      <card>
        <h4 class="cardD">
          Teléfonos
          <!-- <button
            @click="addTelefonoRepresentante"
            class="btn btn-success btn-fill"
          >
            <i class="nc-icon nc-simple-add"></i>
          </button> -->
          <b-button variant="success" class="btn-fill" size="sm" @click="addTelefonoRepresentante">+</b-button>
        </h4>
        <!-- <div v-for="item in currentRepresentante.telefonos" :key="item.id">
          <div class="col-md-12 text-right">
            <b-button
              variant="danger"
              size="sm"
              v-b-tooltip.hover
              title="Eliminar"
              @click="predeleteTelefonoRepresentante(item.id)"
            >
              <b-icon-trash />
            </b-button>
          </div>
          <Telefonos :telefono="item" />
          <br />
        </div> -->
        <b-table :fields="fieldsTelefonos" :items="currentRepresentante.telefonos" hover>
          <template #cell(index)="{ index }">
            {{ getIndex(index + 1) }}
          </template>
          <template #cell(acciones)="{ item }">
            <b-button variant="warning" size="sm" v-b-tooltip.hover title="Editar" class="editBtn"
              @click="editTelefonoRepresentante(item)">
              <b-icon-pencil />
            </b-button>
            <b-button variant="danger" @click="predeleteTelefonoRepresentante(item.id)" size="sm" v-b-tooltip.hover
              title="Eliminar">
              <b-icon-trash />
            </b-button>
          </template>
        </b-table>
      </card>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="domicilio-modal-representante" title="" hide-footer id="modalDR"
      size="xl">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(postDomicilioRepresentante)" @change="getAddressInMapRepre(null)">
          <ModalDomicilio :currentDomicilio="currentDomicilio" @submit.prevent />
        </form>
      </ValidationObserver>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="telefono-modal-representante" title="" hide-footer id="modalTR"
      size="lg">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(postTelefonoRepresentante)">
          <ModalTelefono :currentTelefono="currentTelefono" @submit.prevent />
        </form>
      </ValidationObserver>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog-documento" hide-footer
      id="modal-confirm-dialog-documento">
      <h4 class="text-center">¿Seguro que quiere eliminar el documento?</h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="deleteDocumento">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="documento-modal" title="" hide-footer id="modalC" size="xl">
      <div v-if="user.persona === 'Física'">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(postDocumento)">
            <ModalDocumentos :currentDocumento="currentDocumento" :options="optionsFisicas" @submit.prevent />
          </form>
        </ValidationObserver>
      </div>
      <div v-else>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(postDocumento)">
            <ModalDocumentos :currentDocumento="currentDocumento" :options="optionsMorales" @submit.prevent />
          </form>
        </ValidationObserver>
      </div>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="documento-modal-preview-img" title="" hide-footer id="modaldi"
      size="xl">
      <ModalPreviewImg :currentDocumento="currentDocumento" />
    </b-modal>
    <b-modal no-close-on-backdrop centered ref="documento-modal-preview-pdf" title="" hide-footer id="modaldf"
      size="xl">
      <ModalPreviewPDF :currentDocumento="currentDocumento" />
    </b-modal>

    <div class="container">
      <card class="card">
        <div class="row">
          <div class="col-md-6">
            <ValidationProvider name="persona" rules="required" v-slot="{ errors }" :custom-messages="{
              required: 'La persona es obligatoria'
            }">
              <label for="">Persona</label>
              <select v-model="user.persona" class="custom-select">
                <option value="Física">Física</option>
                <option value="Moral">Moral</option>
                <option value="Fideicomiso">Fideicomiso</option>
              </select>
              <span class="cardErrors">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
        <br />

        <h4 class="cardD" v-if="user.persona === 'Fideicomiso'">
          Datos de identificación del Fiduciario
        </h4>
        <h4 class="cardD" v-else>Datos de identificación</h4>

        <div v-if="user.persona === 'Física'">
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(newCompareciente)">
              <FormFisica :user="user" @submit.prevent />
            </form>
          </ValidationObserver>
        </div>

        <div v-if="user.persona === 'Moral'">
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(newCompareciente)">
              <FormMoral :user="user" @submit.prevent />
              <!-- @state="entidadExtranjera" -->
            </form>
          </ValidationObserver>
        </div>

        <div v-if="user.persona === 'Fideicomiso'">
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(newCompareciente)">
              <FormFideicomiso :user="user" @submit.prevent />
            </form>
          </ValidationObserver>
        </div>
      </card>

      <card>
        <h4 class="cardD">
          Domicilio
          <!-- <button
            v-if="domicilios.length === 0"
            @click="addDomicilio"
            class="btn btn-success btn-fill"
          >
            <i class="nc-icon nc-simple-add"></i>
          </button> -->
          <b-button v-if="addDomicilioBtn" variant="success" class="btn-fill" size="sm"
            @click="addDomicilio">+</b-button>
        </h4>
        <!-- <div
          v-for="item in domicilios"
          :key="item.id"
          @change="getAddressInMapCompar(item.id)"
        >
          <div class="col-md-12 text-right mb-3">
            <b-button
              variant="danger"
              size="sm"
              v-b-tooltip.hover
              title="Eliminar"
              @click="predeleteDomicilio(item.id)"
            >
              <b-icon-trash />
            </b-button>
          </div>
          <Domicilios :domicilio="item" />
          <br />
          <br />
        </div> -->
        <b-table :fields="fieldsDomicilios" :items="domicilios" hover>
          <template #cell(index)="{ index }">
            {{ getIndex(index + 1) }}
          </template>
          <template #cell(mapUrl)="{ item }">
            <DrawGoogleMap @location="updateCoordinates" :lat="parseFloat(item.lat)" :lng="parseFloat(item.lng)"
              style="width:300px;" />
          </template>
          <template #cell(acciones)="{ item }">
            <b-button variant="warning" size="sm" v-b-tooltip.hover title="Editar" class="editBtn"
              @click="editDomicilio(item)">
              <b-icon-pencil />
            </b-button>
            <b-button variant="danger" @click="predeleteDomicilio(item.id)" size="sm" v-b-tooltip.hover
              title="Eliminar">
              <b-icon-trash />
            </b-button>
          </template>
        </b-table>
      </card>

      <card>
        <h4 class="cardD">Datos de contacto</h4>
        <span class="cardErrors mb-2">
          Favor de ingresar un correo electrónico válido así como un número telefónico de celular ya que serán
          necesarios para el proceso de Firma Digital.
        </span>
        <div>
          <ValidationProvider name="email" rules="required|email" v-slot="{ errors }" :custom-messages="{
            required: 'El email es obligatorio',
            email: 'Debe ser un email válido'
          }">
            <base-input type="email" label="Email" placeholder="Email" v-model="user.email">
            </base-input>
            <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <h4 class="cardD">
          Teléfonos
          <!-- <button @click="addTelefono" class="btn btn-success btn-fill">
            <i class="nc-icon nc-simple-add"></i>
          </button> -->
          <b-button variant="success" class="btn-fill" size="sm" @click="addTelefono">+</b-button>
        </h4>
        <!-- <div v-for="item in telefonos" :key="item.id">
          <div class="col-md-12 text-right">
            <b-button
              variant="danger"
              size="sm"
              v-b-tooltip.hover
              title="Eliminar"
              @click="predeleteTelefono(item.id)"
            >
              <b-icon-trash />
            </b-button>
          </div>
          <Telefonos :telefono="item" />
          <br />
        </div> -->
        <b-table :fields="fieldsTelefonos" :items="telefonos" hover>
          <template #cell(index)="{ index }">
            {{ getIndex(index + 1) }}
          </template>
          <template #cell(acciones)="{ item }">
            <b-button variant="warning" size="sm" v-b-tooltip.hover title="Editar" class="editBtn"
              @click="editTelefono(item)">
              <b-icon-pencil />
            </b-button>
            <b-button variant="danger" @click="predeleteTelefono(item.id)" size="sm" v-b-tooltip.hover title="Eliminar">
              <b-icon-trash />
            </b-button>
          </template>
        </b-table>
      </card>

      <card>
        <!-- <div v-if="id === 0">
          <h4 class="cardD">Documentos</h4>
          <label class="mb-12 leyenda">
            Guarde el compareciente para que pueda cargar los documentos.
          </label>
        </div> -->

        <!-- <div v-else>
          <div v-if="user.persona === 'Física'">
            <h4 class="cardD">
              Documentos
              <b-button
                variant="success"
                class="btn-fill"
                size="sm"
                @click="addDocumento"
                >+</b-button
              >
               v-if="user.documentos.length < optionsFisicas.length"
            </h4>
          </div>
          <div v-else>
            <h4 class="cardD">
              Documentos
              <b-button
                variant="success"
                class="btn-fill"
                size="sm"
                @click="addDocumento"
                >+</b-button
              >
              v-if="user.documentos.length < optionsMorales.length"
            </h4>
          </div>
        </div> -->

        <div>
          <h4 class="cardD">
            Documentos
            <b-button variant="success" class="btn-fill" size="sm" @click="addDocumento" v-if="user.persona != null">
              +
            </b-button>
          </h4>
        </div>

        <b-table hover :fields="fieldsDocus" :items="user.documentos">
          <template #cell(acciones)="{ item }">
            <b-button variant="warning" size="sm" v-b-tooltip.hover title="Editar" @click="editDocumento(item)"
              class="editBtn">
              <b-icon-pencil />
            </b-button>
            <b-button variant="primary" size="sm" v-b-tooltip.hover title="Vista previa" @click="previewDocumento(item)"
              class="editBtn">
              <b-icon-eye />
            </b-button>
            <b-button variant="danger" size="sm" v-b-tooltip.hover title="Eliminar" @click="predeleteDocumento(item.id)"
              class="editBtn">
              <b-icon-trash />
            </b-button>
          </template>
        </b-table>
      </card>

      <card v-if="user.persona !== 'Física'">
        <h4 class="cardD">
          Apoderados o Representantes Legales
          <!-- <button @click="addRepresentante" class="btn btn-success btn-fill">
            <i class="nc-icon nc-simple-add"></i>
          </button> -->
          <b-button variant="success" class="btn-fill" size="sm" @click="addRepresentante">+</b-button>
        </h4>

        <div class="text-center">
          <b-table :fields="fields" :items="representantes" hover>
            <template #cell(index)="{ index }">
              {{ getIndex(index + 1) }}
            </template>
            <template #cell(acciones)="{ item }">
              <b-button variant="warning" size="sm" v-b-tooltip.hover title="Editar" class="editBtn"
                @click="editRepresentante(item)">
                <b-icon-pencil />
              </b-button>
              <!-- </router-link> -->

              <b-button variant="danger" @click="predeleteRepresentantes(item.id)" size="sm" v-b-tooltip.hover
                title="Eliminar">
                <b-icon-trash />
              </b-button>
            </template>
          </b-table>
        </div>
      </card>

      <card v-if="this.user.persona === 'Física'">
        <h4 class="cardD">Redes Sociales</h4>
        <RedesSociales :user="user" />
      </card>
    </div>
  </div>
</template>

<script>
import FormFideicomiso from "@/views/clients/comparecientes/FormFideicomiso.vue";
import FormFisica from "@/views/clients/comparecientes/FormFisica.vue";
import FormMoral from "@/views/clients/comparecientes/FormMoral.vue";
import Card from "src/components/Cards/Card.vue";
// import Domicilios from "@/views/clients/comparecientes/Domicilios.vue";
import ModalDomicilio from "@/views/clients/comparecientes/ModalDomicilio.vue";
// import Telefonos from "@/views/clients/comparecientes/Telefonos.vue";
import ModalRepresentante from "@/views/clients/comparecientes/ModalRepresentante.vue";
import ModalTelefono from "@/views/clients/comparecientes/ModalTelefono.vue";
import RedesSociales from "@/views/clients/comparecientes/RedesSociales.vue";
// import Representantes from "@/views/clients/comparecientes/Representantes.vue";
import { CatNacionalidadesApi, ClientesApi, ComparecientesApi } from "@/api";
// import GoogleMap from "@/views/clients/comparecientes/GoogleMap.vue";
import { CatDocumentosApi, FuncionesApi } from "@/api";
import DrawGoogleMap from "@/views/clients/comparecientes/DrawGoogleMap.vue";
import ModalDocumentos from "@/views/clients/procesos/constitucion/entregadocumentos/ModalDocumentos.vue";
import ModalPreviewImg from "@/views/clients/procesos/constitucion/entregadocumentos/ModalPreviewImg.vue";
import ModalPreviewPDF from "@/views/clients/procesos/constitucion/entregadocumentos/ModalPreviewPDF.vue";

import Storage from "@/utils/storage";

const storage = new Storage();

import { getGoogleMapUrl } from "@/utils/google-maps-utils";

export default {
  name: "AddCompareciente",

  components: {
    Card,
    FormFisica,
    FormMoral,
    // Domicilios,
    // Telefonos,
    ModalDomicilio,
    ModalTelefono,
    ModalRepresentante,
    FormFideicomiso,
    RedesSociales,
    // Representantes,
    // GoogleMap,
    DrawGoogleMap,
    ModalDocumentos,
    ModalPreviewImg,
    ModalPreviewPDF
  },

  computed: {
    fields() {
      let fields = [
        { key: "index", label: "", class: "text-center" },
        // { key: "representante", label: "Representante", class: "text-center" },
        { key: "nombre", label: "Nombre", class: "text-center" },
        { key: "paterno", label: "Paterno", class: "text-center" },
        { key: "materno", label: "Materno", class: "text-center" },
        { key: "email", label: "Email", class: "text-center" },
        { key: "rfc", label: "RFC", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" }
      ];
      return fields;
    },
    fieldsDomicilios() {
      let fieldsDomicilios = [
        { key: "index", label: "", class: "text-center" },
        { key: "tipo", label: "Tipo", class: "text-center" },
        { key: "calle", label: "Calle", class: "text-center" },
        { key: "num_exterior", label: "Número", class: "text-center" },
        { key: "colonia", label: "Colonia", class: "text-center" },
        { key: "entidad", label: "Entidad", class: "text-center" },
        { key: "pais", label: "País", class: "text-center" },
        { key: "mapUrl", label: "Mapa", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" }
      ];
      return fieldsDomicilios;
    },
    fieldsTelefonos() {
      let fieldsTelefonos = [
        { key: "index", label: "", class: "text-center" },
        { key: "tipo", label: "Tipo", class: "text-center" },
        { key: "clave", label: "Clave", class: "text-center" },
        { key: "numero", label: "Número", class: "text-center" },
        { key: "extension", label: "Extension", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" }
      ];
      return fieldsTelefonos;
    },
    fieldsDocus() {
      let fieldsDocus = [
        // { key: "index", label: "", class: "text-center" },
        { key: "nombreFile", label: "Nombre", class: "text-center" },
        { key: "tipo", label: "Tipo", class: "text-center" },
        // { key: "nombre", label: "Persona Física", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" }
      ];
      return fieldsDocus;
    }
  },

  watch: {
    "currentDomicilio.pais": "changePais",
    "currentDomicilio.tipo": "tipoDomicilio"
  },

  created() {
    this.getCatNacionalidades();
    this.getCatDocumentosPF();
    this.getCatDocumentosPM();
  },

  data: () => ({
    // autocomplete: null,

    addDomicilioBtn: true,

    optionsFisicas: [],
    optionsMorales: [],
    currentDocumento: null,
    currentDocumentoId: null,

    id: 0,
    loading: false,
    user: {
      cliente_id: null,
      persona: null,
      firstName: null,
      lastNameP: null,
      lastNameM: null,
      email: null,
      rfc: null,
      facebook: null,
      twitter: null,
      instagram: null,
      linkedin: null,

      fecha_nacimiento: null,
      lugar_nacim_ciudad: null,
      lugar_nacim_estado: null,
      lugar_nacim_pais: null,
      // nacionalidad: null,
      curp: null,
      ocupacion: null,
      giro_negocios: null,
      alias: null,
      sexo: null,
      pais_residencia: null,
      estado_civil: null,
      identificacion_conyuge: null,
      regimen_patrimonial: null,
      identificacion_tipo: null,
      identificacion_autoridad: null,
      identificacion_numero: null,

      identificacion_tipo_otro: null,
      identificacion_autoridad_otro: null,

      denominacion_social: null,
      fecha_constitucion: null,
      // nacionalidad: null,
      actividad: null,
      giro: null,
      objeto_social: null,
      domicilio_social: null,

      instrumento_numero: null,
      instrumento_numero_fecha: null,
      instrumento_fedatario: null,
      instrumento_fedatario_nombre: null,
      instrumento_fedatario_numero: null,
      instrumento_fedatario_estado: null,
      instrumento_rpf_folio: null,
      instrumento_rpf_folio_fecha: null,
      instrumento_rpf_folio_lugar: null,

      entidad_extranjera: null,
      datos_prueba_constitucion: null,

      estatus_migratorio: null,
      fecha_ingreso_pais: null,

      fiduciario: null,
      nombre_fideicomiso: null,
      fideicomitente: null,
      tipo_fideicomiso: null,
      tipo_fideicomiso_otro: null,
      fecha_creacion_fideicomiso: null,
      folio_inscripcion: null,
      rfc_fideicomiso: null,
      email_fideicomiso: null,
      tel_fideicomiso: null,

      nacionalidades: [],
      documentos: []
    },
    domicilio: {},
    domicilios: [],
    telefono: {},
    telefonos: [],
    representante: {},
    representantes: [],

    cat_nacionalidades: [],

    currentDomicilio: {
      tieneExtranjero: true,
      tieneTipo: true,
      compareciente: true,
      extranjero: null
    },
    currentTelefono: {},
    currentRepresentante: {
      domicilios: [],
      telefonos: [],
      instrumento_numero: null,
      instrumento_numero_fecha: null,
      instrumento_fedatario: null,
      instrumento_fedatario_nombre: null,
      instrumento_fedatario_numero: null,
      instrumento_fedatario_estado: null,
      instrumento_rpf_folio: null,
      instrumento_rpf_folio_fecha: null,
      instrumento_rpf_folio_lugar: null
    },

    currentPage: 1,
    perPage: 10,
    current_representantes_id: null,
    countDomRep: 1,
    countTelRep: 1,

    currentDomicilioId: null,
    currentTelefonoId: null,
    currentRepresentanteId: null,
    currentRepresentanteDomicilioId: null,
    currentRepresentanteTelefonoId: null
  }),

  methods: {
    async getCatDocumentosPF() {
      try {
        const { value } = await CatDocumentosApi.list({
          page: 0,
          registersPerPage: 0,
          categoria: "compareciente_pf"
        });
        value.forEach(element => {
          const obj = { text: element.documento, value: element.id };
          this.optionsFisicas.push(obj);
        });
      } catch (error) {
        // this.loading = false;
      }
    },

    async getCatDocumentosPM() {
      try {
        const { value } = await CatDocumentosApi.list({
          page: 0,
          registersPerPage: 0,
          categoria: "compareciente_pm"
        });

        value.forEach(element => {
          const obj = { text: element.documento, value: element.id };
          this.optionsMorales.push(obj);
          // console.log("🚀 ~ getCatDocumentosPM ~ obj:", obj)
        });
      } catch (error) {
        // this.loading = false;
      }
    },

    async getAddressInMapCompar(direcciones_id) {
      if (direcciones_id != null) {
        const index = this.domicilios.findIndex(o => o.id === direcciones_id);
        const address = `${this.domicilios[index].calle},
                      ${this.domicilios[index].num_exterior},
                      ${this.domicilios[index].colonia},
                      ${this.domicilios[index].delegacion_municipio},
                      ${this.domicilios[index].codigo_postal},
                      ${this.domicilios[index].ciudad},
                      ${this.domicilios[index].entidad}
                      `;

        const mapUrl = await getGoogleMapUrl(address);
        // this.domicilios[index].mapUrl = mapUrl;
        this.domicilios[index].lat = mapUrl.lat;
        this.domicilios[index].lng = mapUrl.lng;
      } else if (direcciones_id === null) {
        const address = `${this.currentDomicilio.calle},
                      ${this.currentDomicilio.num_exterior},
                      ${this.currentDomicilio.colonia},
                      ${this.currentDomicilio.delegacion_municipio},
                      ${this.currentDomicilio.codigo_postal},
                      ${this.currentDomicilio.ciudad},
                      ${this.currentDomicilio.entidad}
                      `;
        const mapUrl = await getGoogleMapUrl(address);
        // this.currentDomicilio.mapUrl = mapUrl;
        this.currentDomicilio.lat = mapUrl.lat.toString(); //parseFloat(mapUrl.lat);
        this.currentDomicilio.lng = mapUrl.lng.toString(); //parseFloat(mapUrl.lng);
        var a = Object.assign({}, this.currentDomicilio);
        this.currentDomicilio = a;
      }
    },

    async getAddressInMapRepre(direcciones_id) {
      if (direcciones_id != null) {
        const index = this.currentRepresentante.domicilios.findIndex(
          o => o.id === direcciones_id
        );
        const address = `${this.currentRepresentante.domicilios[index].calle},
                      ${this.currentRepresentante.domicilios[index].num_exterior
          },
                      ${this.currentRepresentante.domicilios[index].colonia},
                      ${this.currentRepresentante.domicilios[index]
            .delegacion_municipio
          },
                      ${this.currentRepresentante.domicilios[index]
            .codigo_postal
          },
                      ${this.currentRepresentante.domicilios[index].ciudad},
                      ${this.currentRepresentante.domicilios[index].entidad}
                      `;

        const mapUrl = await getGoogleMapUrl(address);
        // this.currentRepresentante.domicilios[index].mapUrl = mapUrl;
        this.currentRepresentante.domicilios[index].lat = mapUrl.lat;
        this.currentRepresentante.domicilios[index].lng = mapUrl.lng;
      } else if (direcciones_id === null) {
        const address = `${this.currentDomicilio.calle},
                      ${this.currentDomicilio.num_exterior},
                      ${this.currentDomicilio.colonia},
                      ${this.currentDomicilio.delegacion_municipio},
                      ${this.currentDomicilio.codigo_postal},
                      ${this.currentDomicilio.ciudad},
                      ${this.currentDomicilio.entidad}
                      `;
        const mapUrl = await getGoogleMapUrl(address);
        // this.currentDomicilio.mapUrl = mapUrl;
        this.currentDomicilio.lat = mapUrl.lat.toString();
        this.currentDomicilio.lng = mapUrl.lng.toString();
        var a = Object.assign({}, this.currentDomicilio);
        this.currentDomicilio = a;
      }
    },

    updateCoordinates(coordinates) {
      // console.log("updateCoordEditCompar");
      // console.log(coordinates.lat);
      // console.log(coordinates.lng);
      // this.currentDomicilio.lat=coordinates.lat;
      // this.currentDomicilio.lng=coordinates.lng;
      //acá no se hace nada porque está fuera de modal donde se save all
    },

    getIndex(index) {
      return index + (this.currentPage - 1) * this.perPage;
    },

    async getCatNacionalidades() {
      const { value } = await CatNacionalidadesApi.list({
        page: 0,
        registersPerPage: 0
      });
      this.cat_nacionalidades = value;
    },

    addDomicilio() {
      this.$refs["domicilio-modal"].show();
      this.currentDomicilio = {};
      this.currentDomicilio.tieneExtranjero = true;
      this.currentDomicilio.tieneTipo = true;
      this.currentDomicilio.compareciente = true;
      this.currentDomicilio.extranjero = false;
      // this.currentDomicilio.mapUrl =
      // "https://www.google.com/maps/embed/v1/place?q=place_id:El5Bdi4gSnXDoXJleiwgQ2VudHJvIEhpc3TDs3JpY28gZGUgbGEgQ2RhZC4gZGUgTcOpeGljbywgQ2VudHJvLCBDaXVkYWQgZGUgTcOpeGljbywgQ0RNWCwgTWV4aWNvIi4qLAoUChIJe7aYkCr50YUR0gAJ5MV3QdUSFAoSCb1NDi4t-dGFET2JxECndFWQ&zoom=12&key=AIzaSyC1LrDy2Gk5QhV8SjD7k4S6b4zg-wF2YEA";
      this.currentDomicilio.lat = 19.4320605;
      this.currentDomicilio.lng = -99.1335938;
      // if (this.currentDomicilio.id === undefined) {
      // this.currentDomicilio.id = this.domicilios.length + 1;
      // }
    },

    addTelefono() {
      this.$refs["telefono-modal"].show();
      this.currentTelefono = {};
    },

    addRepresentante() {
      this.$refs["representante-modal"].show();
      this.currentRepresentante = {
        domicilios: [],
        telefonos: [],
        instrumento_numero: null,
        instrumento_numero_fecha: null,
        instrumento_fedatario: null,
        instrumento_fedatario_nombre: null,
        instrumento_fedatario_nombre: null,
        instrumento_fedatario_numero: null,
        instrumento_fedatario_estado: null,
        instrumento_rpf_folio: null,
        instrumento_rpf_folio_fecha: null,
        instrumento_rpf_folio_lugar: null,
        personaARepresentar: this.user.persona
      };
    },

    editRepresentante(representante) {
      this.$refs["representante-modal"].show();
      this.currentRepresentante = representante;
      this.current_representantes_id = representante.id;
      this.currentRepresentante.personaARepresentar = this.user.persona;
    },

    editDomicilio(domicilio) {
      this.$refs["domicilio-modal"].show();
      this.currentDomicilio = domicilio;
    },

    editTelefono(telefono) {
      this.$refs["telefono-modal"].show();
      this.currentTelefono = telefono;
    },

    changePais() {
      if (this.currentDomicilio.pais === "México") {
        this.currentDomicilio.extranjero = false;
        var a = Object.assign({}, this.currentDomicilio);
        this.currentDomicilio = a;
      } else if (this.currentDomicilio.pais != "México") {
        this.currentDomicilio.extranjero = true;
        var a = Object.assign({}, this.currentDomicilio);
        this.currentDomicilio = a;
      }
    },

    tipoDomicilio() {
      if (this.currentDomicilio.tipo != "Personal") {
        if (this.currentDomicilio.tipo === "Particular") {
          this.addDomicilioBtn = false;
        } else if (this.currentDomicilio.tipo === "Extranjero") {
          this.addDomicilioBtn = false;
        }
        // else if (this.domicilios.length > 3) {
        //   this.addDomicilioBtn = false;
        //   //borra las otras direcciones?
        // }
      }

      if (this.domicilios.length >= 3) {
        this.addDomicilioBtn = false;
      }
    },

    async postDomicilio() {
      this.currentDomicilio.tieneExtranjero = true;
      this.currentDomicilio.tieneTipo = true;

      if (
        this.currentDomicilio.tipo === "Extranjero" ||
        this.currentDomicilio.tipo === "Particular" ||
        this.currentDomicilio.tipo === "Fiscal"
      ) {
        this.addDomicilioBtn = true;
      } else if (this.currentDomicilio.tipo === "Particular y Fiscal") {
        this.addDomicilioBtn = false;
      }

      //ver si es post o put
      if (this.currentDomicilio.id === undefined) {
        this.currentDomicilio.id = this.domicilios.length + 1;
        var a = Object.assign({}, this.currentDomicilio);
        this.domicilios.push(a);
      } else if (this.currentDomicilio.id != undefined) {
        const index = this.domicilios.findIndex(
          o => o.id === this.currentDomicilio.id
        );
        var a = Object.assign({}, this.currentDomicilio);
        this.domicilios.splice([index], 1, a);
      }

      this.$refs["domicilio-modal"].hide();
      this.currentDomicilio = {};

      if (this.domicilios.length === 2) {
        let count = 0;
        for (let u = 0; u < this.domicilios.length; u++) {
          if (this.domicilios[u].tipo === "Particular") {
            count++;
          } else if (this.domicilios[u].tipo === "Fiscal") {
            count++;
          }
        }
        if (count === 2) {
          this.addDomicilioBtn = false;
        }
      }

      // if (this.currentDomicilio.id === undefined) {
      //   this.currentDomicilio.id = this.domicilios.length + 1;
      // }

      // var a = Object.assign({}, this.currentDomicilio);
      // // this.domicilios.push(this.currentDomicilio);
      // this.domicilios.push(a);

      // this.$refs["domicilio-modal"].hide();
      // if (this.currentDomicilio.extranjero === true) {
      //   var newDom = {};
      //   newDom.pais = "México";
      //   newDom.tieneExtranjero = false; //true
      //   newDom.tieneTipo = true;
      //   newDom.mapUrl =
      //     "https://www.google.com/maps/embed/v1/place?q=place_id:El5Bdi4gSnXDoXJleiwgQ2VudHJvIEhpc3TDs3JpY28gZGUgbGEgQ2RhZC4gZGUgTcOpeGljbywgQ2VudHJvLCBDaXVkYWQgZGUgTcOpeGljbywgQ0RNWCwgTWV4aWNvIi4qLAoUChIJe7aYkCr50YUR0gAJ5MV3QdUSFAoSCb1NDi4t-dGFET2JxECndFWQ&zoom=12&key=AIzaSyC1LrDy2Gk5QhV8SjD7k4S6b4zg-wF2YEA";
      //   newDom.id = this.domicilios.length + 1;
      //   this.domicilios.push(newDom);
      // }
      // if (this.currentDomicilio.tipo === "Particular") {
      //   var newDom = {};
      //   newDom.tieneExtranjero = false;
      //   newDom.tieneTipo = true;
      //   newDom.tipo = "Fiscal";
      //   newDom.pais = "México";
      //   newDom.extranjero = false;
      //   newDom.mapUrl =
      //     "https://www.google.com/maps/embed/v1/place?q=place_id:El5Bdi4gSnXDoXJleiwgQ2VudHJvIEhpc3TDs3JpY28gZGUgbGEgQ2RhZC4gZGUgTcOpeGljbywgQ2VudHJvLCBDaXVkYWQgZGUgTcOpeGljbywgQ0RNWCwgTWV4aWNvIi4qLAoUChIJe7aYkCr50YUR0gAJ5MV3QdUSFAoSCb1NDi4t-dGFET2JxECndFWQ&zoom=12&key=AIzaSyC1LrDy2Gk5QhV8SjD7k4S6b4zg-wF2YEA";
      //   newDom.id = this.domicilios.length + 1;
      //   this.domicilios.push(newDom);
      // }
    },

    async postTelefono() {
      //   let new_telefono = {
      //     tipo: this.currentTelefono.tipo,
      //     clave: this.currentTelefono.clave,
      //     numero: this.currentTelefono.numero,
      //     extension: this.currentTelefono.extension,
      //   };
      //   this.telefonos.push(new_telefono);
      //
      if (this.currentTelefono.id === undefined) {
        this.currentTelefono.id = this.telefonos.length + 1;
        var a = Object.assign({}, this.currentTelefono);
        this.telefonos.push(a);
      } else if (this.currentTelefono.id != undefined) {
        const index = this.telefonos.findIndex(
          o => o.id === this.currentTelefono.id
        );
        var a = Object.assign({}, this.currentTelefono);
        this.telefonos.splice([index], 1, a);
      }
      this.$refs["telefono-modal"].hide();
    },

    async postRepresentante() {
      if (this.currentRepresentante.id === undefined) {
        if (this.representantes != undefined) {
          this.currentRepresentante.id = this.representantes.length;
        } else {
          this.currentRepresentante.id = 0;
        }
        this.representantes.push(this.currentRepresentante);
      } else if (this.currentRepresentante.id != undefined) {
        const index = this.representantes.findIndex(
          o => o.id === this.currentRepresentante.id
        );
        this.representantes[index] = this.currentRepresentante;
      }
      this.$refs["representante-modal"].hide();
    },

    predeleteDomicilio(id) {
      this.currentDomicilioId = id;
      this.$refs["modal-confirm-dialog-dom"].show();
      // this.$confirm({
      //   title: "¿Seguro que quiere eliminar el domicilio?",
      //   message: "Se eliminarán todos los datos.",
      //   button: {
      //     yes: "OK",
      //     no: "Cancelar",
      //   },
      //   callback: (confirm) => {
      //     if (confirm) {
      //       this.deleteDomicilio(id);
      //     }
      //   },
      // });
    },

    async deleteDomicilio() {
      let id = this.currentDomicilioId;

      const index = this.domicilios.findIndex(o => o.id == id);
      this.domicilios.splice(index, 1);
      if (this.domicilios.length > 1) {
        for (let i = 0; i < this.domicilios.length; i++) {
          if (this.domicilios[i].tipo != "Particular y Fiscal") {
            this.addDomicilioBtn = true;
          } else {
            this.addDomicilioBtn = false;
          }
        }
      } else {
        this.addDomicilioBtn = true;
      }
      this.$refs["modal-confirm-dialog-dom"].hide();
    },

    predeleteTelefono(id) {
      this.currentTelefonoId = id;
      this.$refs["modal-confirm-dialog-tel"].show();
      // this.$confirm({
      //   title: "¿Seguro que quiere eliminar el teléfono?",
      //   message: "Se eliminarán todos los datos.",
      //   button: {
      //     yes: "OK",
      //     no: "Cancelar",
      //   },
      //   callback: (confirm) => {
      //     if (confirm) {
      //       this.deleteTelefono(id);
      //     }
      //   },
      // });
    },

    async deleteTelefono() {
      let id = this.currentTelefonoId;

      const index = this.telefonos.findIndex(o => o.id == id);
      this.telefonos.splice(index, 1);
      this.$refs["modal-confirm-dialog-tel"].hide();
    },

    predeleteRepresentantes(id) {
      this.currentRepresentanteId = id;
      this.$refs["modal-confirm-dialog-repre"].show();
      // this.$confirm({
      //   title: "¿Seguro que quiere eliminar el representante?",
      //   message: "Se eliminarán todos los datos.",
      //   button: {
      //     yes: "OK",
      //     no: "Cancelar",
      //   },
      //   callback: (confirm) => {
      //     if (confirm) {
      //       this.deleteRepresentante(id);
      //     }
      //   },
      // });
    },

    async deleteRepresentante() {
      let id = this.currentRepresentanteId;

      const index = this.representantes.findIndex(o => o.id == id);
      this.representantes.splice(index, 1);
      this.$refs["modal-confirm-dialog-repre"].hide();
    },

    addDomicilioRepresentante() {
      this.$refs["domicilio-modal-representante"].show();
      this.currentDomicilio = {};
      this.currentDomicilio.tieneExtranjero = false;
      this.currentDomicilio.tieneTipo = false;
      this.currentDomicilio.tipo = "Personal";
      this.currentDomicilio.compareciente = false;
      // this.currentDomicilio.mapUrl =
      // "https://www.google.com/maps/embed/v1/place?q=place_id:El5Bdi4gSnXDoXJleiwgQ2VudHJvIEhpc3TDs3JpY28gZGUgbGEgQ2RhZC4gZGUgTcOpeGljbywgQ2VudHJvLCBDaXVkYWQgZGUgTcOpeGljbywgQ0RNWCwgTWV4aWNvIi4qLAoUChIJe7aYkCr50YUR0gAJ5MV3QdUSFAoSCb1NDi4t-dGFET2JxECndFWQ&zoom=12&key=AIzaSyC1LrDy2Gk5QhV8SjD7k4S6b4zg-wF2YEA";
      this.currentDomicilio.lat = 19.4320605;
      this.currentDomicilio.lng = -99.1335938;
      // if (this.currentDomicilio.id === undefined) {
      // this.currentDomicilio.id =
      //   this.currentRepresentante.domicilios.length + 1;
    },

    addTelefonoRepresentante() {
      this.$refs["telefono-modal-representante"].show();
      this.currentTelefono = {};
    },

    editDomicilioRepresentante(domicilio) {
      this.$refs["domicilio-modal-representante"].show();
      this.currentDomicilio = domicilio;
    },

    editTelefonoRepresentante(telefono) {
      this.$refs["telefono-modal-representante"].show();
      this.currentTelefono = telefono;
    },

    async postDomicilioRepresentante() {
      this.currentRepresentante.domicilios.tieneExtranjero = false;
      this.currentRepresentante.domicilios.tieneTipo = false;

      if (this.currentDomicilio.id === undefined) {
        this.currentDomicilio.id =
          this.currentRepresentante.domicilios.length + 1;
        var a = Object.assign({}, this.currentDomicilio);
        this.currentRepresentante.domicilios.push(a);
      } else if (this.currentDomicilio.id != undefined) {
        const index = this.currentRepresentante.domicilios.findIndex(
          o => o.id === this.currentDomicilio.id
        );
        var a = Object.assign({}, this.currentTelefono);
        this.currentRepresentante.telefonos.splice([index], 1, a);
        // this.currentRepresentante.domicilios[index] = this.currentDomicilio;
      }
      // if (this.currentDomicilio.id === undefined) {
      //   this.currentDomicilio.id =
      //     this.currentRepresentante.domicilios.length + 1;
      // }
      // this.currentDomicilio.tipo = "Personal";
      // // this.currentRepresentante.domicilios.push(this.currentDomicilio);
      // var a = Object.assign({}, this.currentDomicilio);
      // this.currentRepresentante.domicilios.push(a);
      this.$refs["domicilio-modal-representante"].hide();
    },

    async postTelefonoRepresentante() {
      // if (this.currentTelefono.id === undefined) {
      //   this.currentTelefono.id = this.currentRepresentante.telefonos.length;
      // }
      // this.currentRepresentante.telefonos.push(this.currentTelefono);
      //
      if (this.currentTelefono.id === undefined) {
        this.currentTelefono.id = this.currentRepresentante.telefonos.length;
        var a = Object.assign({}, this.currentTelefono);
        this.currentRepresentante.telefonos.push(a);
      } else if (this.currentTelefono.id != undefined) {
        const index = this.currentRepresentante.telefonos.findIndex(
          o => o.id === this.currentTelefono.id
        );
        var a = Object.assign({}, this.currentTelefono);
        this.currentRepresentante.telefonos.splice([index], 1, a);
      }
      this.$refs["telefono-modal-representante"].hide();
    },

    predeleteDomicilioRepresentante(domicilio_id) {
      this.currentRepresentanteDomicilioId = domicilio_id;
      this.$refs["modal-confirm-dialog-repre-dom"].show();
      // this.$confirm({
      //   title: "¿Seguro que quiere eliminar el domicilio?",
      //   message: "Se eliminarán todos los datos.",
      //   button: {
      //     yes: "OK",
      //     no: "Cancelar",
      //   },
      //   callback: (confirm) => {
      //     if (confirm) {
      //       this.deleteDomicilioRepresentante(domicilio_id);
      //     }
      //   },
      // });
    },

    async deleteDomicilioRepresentante() {
      let domicilio_id = this.currentRepresentanteDomicilioId;
      // const index2 = this.representantes.findIndex(
      //   (o) => o.id == this.current_representantes_id
      // );
      // const index = this.representantes[index2].domicilios.findIndex(
      //   (o) => o.id == domicilio_id
      // );
      // this.representantes[index2].domicilios.splice(index, 1);
      const index = this.currentRepresentante.domicilios.findIndex(
        o => o.id == domicilio_id
      );

      this.currentRepresentante.domicilios.splice(index, 1);
      this.$refs["modal-confirm-dialog-repre-dom"].hide();
    },

    predeleteTelefonoRepresentante(telefono_id) {
      this.currentRepresentanteTelefonoId = telefono_id;
      this.$refs["modal-confirm-dialog-repre-tel"].show();
      // this.$confirm({
      //   title: "¿Seguro que quiere eliminar el teléfono?" + telefono_id,
      //   message: "Se eliminarán todos los datos.",
      //   button: {
      //     yes: "OK",
      //     no: "Cancelar",
      //   },
      //   callback: (confirm) => {
      //     if (confirm) {
      //       this.deleteTelefonoRepresentante(telefono_id);
      //     }
      //   },
      // });
    },

    async deleteTelefonoRepresentante() {
      let telefono_id = this.currentRepresentanteTelefonoId;
      // const index2 = this.representantes.findIndex(
      //   (o) => o.id == this.current_representantes_id
      // );

      // const index = this.representantes[index2].telefonos.findIndex(
      //   (o) => o.id == telefono_id
      // );
      // this.representantes[index2].telefonos.splice(index, 1);
      const index = this.currentRepresentante.telefonos.findIndex(
        o => o.id == telefono_id
      );
      this.currentRepresentante.telefonos.splice(index, 1);
      this.$refs["modal-confirm-dialog-repre-tel"].hide();
    },

    async mismaDataConstitutiva(state) {
      if (state === true) {
        this.currentRepresentante.instrumento_numero = this.user.instrumento_numero;
        this.currentRepresentante.instrumento_numero_fecha = this.user.instrumento_numero_fecha;
        this.currentRepresentante.instrumento_fedatario = this.user.instrumento_fedatario;
        this.currentRepresentante.instrumento_fedatario_nombre = this.user.instrumento_fedatario_nombre;
        this.currentRepresentante.instrumento_fedatario_numero = this.user.instrumento_fedatario_numero;
        this.currentRepresentante.instrumento_fedatario_estado = this.user.instrumento_fedatario_estado;
        this.currentRepresentante.instrumento_rpf_folio = this.user.instrumento_rpf_folio;
        this.currentRepresentante.instrumento_rpf_folio_fecha = this.user.instrumento_rpf_folio_fecha;
        this.currentRepresentante.instrumento_rpf_folio_lugar = this.user.instrumento_rpf_folio_lugar;
      } else if (state === false) {
        this.currentRepresentante.instrumento_numero = null;
        this.currentRepresentante.instrumento_numero_fecha = null;
        this.currentRepresentante.instrumento_fedatario = null;
        this.currentRepresentante.instrumento_fedatario_nombre = null;
        this.currentRepresentante.instrumento_fedatario_numero = null;
        this.currentRepresentante.instrumento_fedatario_estado = null;
        this.currentRepresentante.instrumento_rpf_folio = null;
        this.currentRepresentante.instrumento_rpf_folio_fecha = null;
        this.currentRepresentante.instrumento_rpf_folio_lugar = null;
      }
    },

    addDocumento() {
      console.log("addDocumento");
      this.$refs["documento-modal"].show();
      this.currentDocumento = {};
    },

    async postDocumento() {
      let id = this.id;
      let carpeta = "documentosComparecientes";
      if (!this.id) {
        id = "0";
        carpeta = "temporalesComparecientes";
      }
      this.$refs["documento-modal"].hide();
      this.loading = true;
      await this.addFile(id, carpeta);

      try {
        // Buscar el índice en optionsFisicas
        let index_s = this.optionsFisicas.findIndex(
          o => o.text === this.currentDocumento.tipo
        );

        // Si se encuentra, asignar el valor correspondiente
        if (index_s !== -1) {
          this.currentDocumento.cat_documentos_id = this.optionsFisicas[index_s].value;
        } else {
          // Si no se encuentra en optionsFisicas, buscar en optionsMorales
          index_s = this.optionsMorales.findIndex(
            o => o.text === this.currentDocumento.tipo
          );
          if (index_s !== -1) {
            this.currentDocumento.cat_documentos_id = this.optionsMorales[index_s].value;
          } else {
            throw new Error(`Tipo de documento "${this.currentDocumento.tipo}" no encontrado en optionsFisicas ni en optionsMorales`);
          }
        }
      } catch (error) {
        console.error("Error al asignar cat_documentos_id:", error.message);
        // Manejar el error de manera apropiada, por ejemplo, mostrando un mensaje al usuario
        alert("Hubo un error al procesar el documento. Por favor, intenta de nuevo.");
      }


      if (this.currentDocumento.id === undefined) {
        let id = this.user.documentos.length + 1;
        this.currentDocumento.id = id;
        var a = Object.assign({}, this.currentDocumento);
        this.user.documentos.push(a);
      } else if (this.currentDocumento.id != undefined) {
        const index = this.user.documentos.findIndex(
          o => o.id === this.currentDocumento.id
        );
        var a = Object.assign({}, this.currentDocumento);
        this.user.documentos.splice([index], 1, a);
      }

      this.loading = false;
    },
    async addFile(id, carpeta) {
      try {
        const formData = new FormData();
        this.currentDocumento.nombreFile = this.currentDocumento.file.name;
        formData.append("file", this.currentDocumento.file);
        const value = await FuncionesApi.file(
          id, //this.id,
          carpeta, //"documentosComparecientes",
          formData,
          {}
        );
        this.currentDocumento.documento = value;
      } catch (error) {
        //
      }
    },

    previewDocumento(item) {
      this.currentDocumento = item;

      let pretipoDocumento = item.nombreFile.split(".");
      let tipoDocumento = pretipoDocumento[pretipoDocumento.length - 1];

      if (tipoDocumento === "pdf") {
        this.$refs["documento-modal-preview-pdf"].show();
      }
      if (
        tipoDocumento === "jpg" ||
        tipoDocumento === "JPG" ||
        tipoDocumento === "jpeg" ||
        tipoDocumento === "png" ||
        tipoDocumento === "gif" ||
        tipoDocumento === "bmp" ||
        tipoDocumento === "webp" ||
        tipoDocumento === "svg" ||
        tipoDocumento === "tiff" ||
        tipoDocumento === "avif"
      ) {
        this.$refs["documento-modal-preview-img"].show();
      }
    },

    editDocumento(documento) {
      this.$refs["documento-modal"].show();
      // console.log(documento);
      this.currentDocumento = documento;
    },

    predeleteDocumento(id) {
      this.currentDocumentoId = id;
      this.$refs["modal-confirm-dialog-documento"].show();
    },
    async deleteDocumento() {
      // await this.deleteFile(this.currentDocumento.id);
      await this.deleteFile(this.user.id);
      let index2 = this.user.documentos.findIndex(
        o => o.id === this.currentDocumentoId
      );
      this.user.documentos.splice(index2, 1);
      this.$refs["modal-confirm-dialog-documento"].hide();
    },
    async deleteFile(id) {
      this.loading = true;
      var data = {
        url: this.currentDocumento.documento,
        id: this.id
      };
      try {
        await FuncionesApi.deleteFile("documentosComparecientes", data, {});
      } catch (error) {
        //
      }
      this.loading = false;
    },

    async newCompareciente() {
      this.loading = true;

      if (this.id != 0) {
        this.editCompareciente();
      } else {
        //get cliente id con email
        const emailUser = storage.getItem("user");
        const { id } = await ClientesApi.clienteId(emailUser);

        this.user.cliente_id = id;

        if (this.user.email == null) {
          this.$toast.warning(
            "Por favor escriba su Email en la seccion Datos de contacto",
            {
              timeout: 4000, //false,
              hideProgressBar: false,
              position: "top-right",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              closeButton: "button",
              icon: true
            }
          );
          this.loading = false;
        } else {
          let nacionalidadesIds = [];
          if (this.user.nacionalidades != null) {
            for (let i = 0; i < this.user.nacionalidades.length; i++) {
              const index = this.cat_nacionalidades.findIndex(
                o => o.nacionalidad == this.user.nacionalidades[i]
              );
              let nacionalidadId = this.cat_nacionalidades[index].id;
              const obj = {};
              obj["nacionalidades_id"] = nacionalidadId;
              nacionalidadesIds.push(obj);
            }
          }

          var data = {
            cliente_id: this.user.cliente_id,
            email: this.user.email,
            rfc: this.user.rfc,
            persona: this.user.persona,
            activo: 1,
            nacionalidad: this.user.nacionalidad,
            facebook: this.user.facebook,
            twitter: this.user.twitter,
            instagram: this.user.instagram,
            linkedin: this.user.linkedin,
            nacionalidades: nacionalidadesIds
            // documentos: this.user.documentos,
          };

          var documentos = JSON.parse(JSON.stringify(this.user.documentos));
          // for (let u = 0; u < documentos.length; u++) {
          //   if (documentos[u].tipo === "Otro") {
          //     documentos[u].tipo = documentos[u].tipoOtro;
          //   }
          // }
          data["documentos"] = documentos;

          if (this.user.persona === "Física") {
            data["nombre"] = this.user.nombre;
            data["paterno"] = this.user.paterno;
            data["materno"] = this.user.materno;
            data["fecha_nacimiento"] = this.user.fecha_nacimiento;
            data["lugar_nacim_ciudad"] = this.user.lugar_nacim_ciudad;
            data["lugar_nacim_estado"] = this.user.lugar_nacim_estado;
            data["lugar_nacim_pais"] = this.user.lugar_nacim_pais;
            data["curp"] = this.user.curp;
            data["ocupacion"] = this.user.ocupacion;
            data["giro_negocios"] = this.user.giro_negocios;
            data["alias"] = this.user.alias;
            data["sexo"] = this.user.sexo;
            data["pais_residencia"] = this.user.pais_residencia;
            data["estado_civil"] = this.user.estado_civil;
            data["identificacion_conyuge"] = this.user.identificacion_conyuge;
            data["regimen_patrimonial"] = this.user.regimen_patrimonial;
            data["identificacion_numero"] = this.user.identificacion_numero;

            data["estatus_migratorio"] = this.user.estatus_migratorio;
            data["fecha_ingreso_pais"] = this.user.fecha_ingreso_pais;

            if (this.user.identificacion_tipo === "Otro") {
              data["identificacion_tipo"] = this.user.identificacion_tipo_otro;
            } else {
              data["identificacion_tipo"] = this.user.identificacion_tipo;
            }

            if (this.user.identificacion_autoridad === "Otro") {
              data[
                "identificacion_autoridad"
              ] = this.user.identificacion_autoridad_otro;
            } else {
              data[
                "identificacion_autoridad"
              ] = this.user.identificacion_autoridad;
            }
          }
          if (this.user.persona === "Moral") {
            data["denominacion_social"] = this.user.denominacion_social;
            data["fecha_constitucion"] = this.user.fecha_constitucion;
            data["actividad"] = this.user.actividad;
            data["giro"] = this.user.giro;
            data["objeto_social"] = this.user.objeto_social;
            data["domicilio_social"] = this.user.domicilio_social;
            data["instrumento_numero"] = this.user.instrumento_numero;
            data[
              "instrumento_numero_fecha"
            ] = this.user.instrumento_numero_fecha;
            data["instrumento_fedatario"] = this.user.instrumento_fedatario;
            data[
              "instrumento_fedatario_nombre"
            ] = this.user.instrumento_fedatario_nombre;
            data[
              "instrumento_fedatario_numero"
            ] = this.user.instrumento_fedatario_numero;
            data[
              "instrumento_fedatario_estado"
            ] = this.user.instrumento_fedatario_estado;
            data["instrumento_rpf_folio"] = this.user.instrumento_rpf_folio;
            data[
              "instrumento_rpf_folio_fecha"
            ] = this.user.instrumento_rpf_folio_fecha;
            data[
              "instrumento_rpf_folio_lugar"
            ] = this.user.instrumento_rpf_folio_lugar;

            if (
              this.user.entidad_extranjera === true ||
              this.user.entidad_extranjera === 1
            ) {
              data["entidad_extranjera"] = 1;
            } else if (
              this.user.entidad_extranjera === false ||
              this.user.entidad_extranjera === 0
            ) {
              data["entidad_extranjera"] = 0;
            }

            data[
              "datos_prueba_constitucion"
            ] = this.user.datos_prueba_constitucion;
          }
          if (this.user.persona === "Fideicomiso") {
            data["denominacion_social"] = this.user.denominacion_social;
            data["fecha_constitucion"] = this.user.fecha_constitucion;
            data["actividad"] = this.user.actividad;
            data["giro"] = this.user.giro;
            data["objeto_social"] = this.user.objeto_social;
            data["instrumento_numero"] = this.user.instrumento_numero;
            data[
              "instrumento_numero_fecha"
            ] = this.user.instrumento_numero_fecha;
            data["instrumento_fedatario"] = this.user.instrumento_fedatario;
            data[
              "instrumento_fedatario_numero"
            ] = this.user.instrumento_fedatario_numero;
            data[
              "instrumento_fedatario_estado"
            ] = this.user.instrumento_fedatario_estado;
            data["instrumento_rpf_folio"] = this.user.instrumento_rpf_folio;
            data[
              "instrumento_rpf_folio_fecha"
            ] = this.user.instrumento_rpf_folio_fecha;
            data[
              "instrumento_rpf_folio_lugar"
            ] = this.user.instrumento_rpf_folio_lugar;

            if (
              this.user.entidad_extranjera === true ||
              this.user.entidad_extranjera === 1
            ) {
              data["entidad_extranjera"] = 1;
            } else if (
              this.user.entidad_extranjera === false ||
              this.user.entidad_extranjera === 0
            ) {
              data["entidad_extranjera"] = 0;
            }

            data[
              "datos_prueba_constitucion"
            ] = this.user.datos_prueba_constitucion;

            data["fiduciario"] = this.user.fiduciario;
            data["nombre_fideicomiso"] = this.user.nombre_fideicomiso;
            data["fideicomitente"] = this.user.fideicomitente;
            // data["tipo_fideicomiso"] = this.user.tipo_fideicomiso;
            if (this.user.identificacion_tipo === "Otro") {
              data["tipo_fideicomiso"] = this.user.tipo_fideicomiso;
            } else {
              data["tipo_fideicomiso"] = this.user.tipo_fideicomiso;
            }

            data[
              "fecha_creacion_fideicomiso"
            ] = this.user.fecha_creacion_fideicomiso;
            data["folio_inscripcion"] = this.user.folio_inscripcion;
            data["rfc_fideicomiso"] = this.user.rfc_fideicomiso;
            data["email_fideicomiso"] = this.user.email_fideicomiso;
            data["tel_fideicomiso"] = this.user.tel_fideicomiso;
          }

          if (this.domicilios != null) {
            for (let i = 0; i < this.domicilios.length; i++) {
              if (
                this.domicilios[i].extranjero === true ||
                this.domicilios[i].extranjero === 1
              ) {
                this.domicilios[i].extranjero = 1;
              } else if (
                this.domicilios[i].extranjero === false ||
                this.domicilios[i].extranjero === 0
              ) {
                this.domicilios[i].extranjero = 0;
              }
            }
          }
          data["domicilios"] = this.domicilios;
          data["telefonos"] = this.telefonos;

          if (this.representantes != null) {
            for (let i = 0; i < this.representantes.length; i++) {
              if (
                this.representantes[i].mismo_constitutiva === true ||
                this.representantes[i].mismo_constitutiva === 1
              ) {
                this.representantes[i].mismo_constitutiva = 1;
              } else if (
                this.representantes[i].mismo_constitutiva === false ||
                this.representantes[i].mismo_constitutiva === 0
              ) {
                this.representantes[i].mismo_constitutiva = 0;
              }

              if (this.representantes[i].domicilios != null) {
                for (
                  let k = 0;
                  k < this.representantes[i].domicilios.length;
                  k++
                ) {
                  if (
                    this.representantes[i].domicilios[k].extranjero === true ||
                    this.representantes[i].domicilios[k].extranjero === 1
                  ) {
                    this.representantes[i].domicilios[k].extranjero = 1;
                  } else if (
                    this.representantes[i].domicilios[k].extranjero === false ||
                    this.representantes[i].domicilios[k].extranjero === 0
                  ) {
                    this.representantes[i].domicilios[k].extranjero = 0;
                  } else {
                    this.representantes[i].domicilios[k].extranjero = null;
                  }
                }
              }
            }
          }
          data["representantes"] = this.representantes;

          let tipo_validacion = "";
          if (this.user.persona === "Física") {
            tipo_validacion = "individual_pf";
          } else if (this.user.persona === "Moral") {
            tipo_validacion = "individual_pm";
          } else if (this.user.persona === "Fideicomiso") {
            tipo_validacion = "individual_fid";
          }
          data["tipo_validacion"] = tipo_validacion;

          try {
            let { id, documentos } = await ComparecientesApi.create(data);
            this.id = id;

            for (let r = 0; r < documentos.length; r++) {
              let pre = documentos[r].documento.split("/");
              let fileName = pre[pre.length - 1];
              documentos[r].nombreFile = fileName;
            }
            this.user.documentos = documentos;
            this.$toast.success("Compareciente agregado", {
              timeout: 4000, //false,
              hideProgressBar: false,
              position: "top-right",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              closeButton: "button",
              icon: true
            });
            this.loading = false;
          } catch (error) {
            this.$toast.error("Sucedió un error, intente más tarde", {
              timeout: 4000, //false,
              hideProgressBar: false,
              position: "top-right",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              closeButton: "button",
              icon: true
            });
            this.loading = false;
          }
        }
      }
    },

    async editCompareciente() {
      this.loading = true;

      let nacionalidadesIds = [];

      if (this.user.nacionalidades != null) {
        for (let i = 0; i < this.user.nacionalidades.length; i++) {
          const index = this.cat_nacionalidades.findIndex(o => o.nacionalidad == this.user.nacionalidades[i]);
          let nacionalidadId = this.cat_nacionalidades[index].id;
          const obj = {};
          obj["nacionalidades_id"] = nacionalidadId;
          nacionalidadesIds.push(obj);
        }
      }

      var data = {
        cliente_id: this.user.cliente_id,
        email: this.user.email,
        rfc: this.user.rfc,
        persona: this.user.persona,
        activo: 1,
        nacionalidad: this.user.nacionalidad,

        facebook: this.user.facebook,
        twitter: this.user.twitter,
        instagram: this.user.instagram,
        linkedin: this.user.linkedin,
        nacionalidades: nacionalidadesIds,
        documentos: this.user.documentos
      };

      if (this.user.persona === "Física") {
        data["nombre"] = this.user.nombre;
        data["paterno"] = this.user.paterno;
        data["materno"] = this.user.materno;
        data["fecha_nacimiento"] = this.user.fecha_nacimiento;
        data["lugar_nacim_ciudad"] = this.user.lugar_nacim_ciudad;
        data["lugar_nacim_estado"] = this.user.lugar_nacim_estado;
        data["lugar_nacim_pais"] = this.user.lugar_nacim_pais;
        data["curp"] = this.user.curp;
        data["ocupacion"] = this.user.ocupacion;
        data["giro_negocios"] = this.user.giro_negocios;
        data["alias"] = this.user.alias;
        data["sexo"] = this.user.sexo;
        data["pais_residencia"] = this.user.pais_residencia;
        data["estado_civil"] = this.user.estado_civil;
        data["identificacion_conyuge"] = this.user.identificacion_conyuge;
        data["regimen_patrimonial"] = this.user.regimen_patrimonial;
        data["identificacion_numero"] = this.user.identificacion_numero;

        data["estatus_migratorio"] = this.user.estatus_migratorio;
        data["fecha_ingreso_pais"] = this.user.fecha_ingreso_pais;

        if (this.user.identificacion_tipo === "Otro") {
          data["identificacion_tipo"] = this.user.identificacion_tipo_otro;
        } else {
          data["identificacion_tipo"] = this.user.identificacion_tipo;
        }

        if (this.user.identificacion_autoridad === "Otro") {
          data["identificacion_autoridad"] = this.user.identificacion_autoridad_otro;
        } else {
          data["identificacion_autoridad"] = this.user.identificacion_autoridad;
        }
      }
      if (this.user.persona === "Moral") {
        data["denominacion_social"] = this.user.denominacion_social;
        data["fecha_constitucion"] = this.user.fecha_constitucion;
        data["actividad"] = this.user.actividad;
        data["giro"] = this.user.giro;
        data["objeto_social"] = this.user.objeto_social;
        data["domicilio_social"] = this.user.domicilio_social;
        data["instrumento_numero"] = this.user.instrumento_numero;
        data["instrumento_numero_fecha"] = this.user.instrumento_numero_fecha;
        data["instrumento_fedatario"] = this.user.instrumento_fedatario;
        data[
          "instrumento_fedatario_numero"
        ] = this.user.instrumento_fedatario_numero;
        data[
          "instrumento_fedatario_estado"
        ] = this.user.instrumento_fedatario_estado;
        data["instrumento_rpf_folio"] = this.user.instrumento_rpf_folio;
        data[
          "instrumento_rpf_folio_fecha"
        ] = this.user.instrumento_rpf_folio_fecha;
        data[
          "instrumento_rpf_folio_lugar"
        ] = this.user.instrumento_rpf_folio_lugar;

        if (
          this.user.entidad_extranjera === true ||
          this.user.entidad_extranjera === 1
        ) {
          data["entidad_extranjera"] = 1;
        } else if (
          this.user.entidad_extranjera === false ||
          this.user.entidad_extranjera === 0
        ) {
          data["entidad_extranjera"] = 0;
        }

        data["datos_prueba_constitucion"] = this.user.datos_prueba_constitucion;
      }

      for (let i = 0; i < this.domicilios.length; i++) {
        if (
          this.domicilios[i].extranjero === true ||
          this.domicilios[i].extranjero === 1
        ) {
          this.domicilios[i].extranjero = 1;
        } else if (
          this.domicilios[i].extranjero === false ||
          this.domicilios[i].extranjero === 0
        ) {
          this.domicilios[i].extranjero = 0;
        }
      }
      data["domicilios"] = this.domicilios;
      data["telefonos"] = this.telefonos;

      for (let i = 0; i < this.representantes.length; i++) {
        if (
          this.representantes[i].mismo_constitutiva === true ||
          this.representantes[i].mismo_constitutiva === 1
        ) {
          this.representantes[i].mismo_constitutiva = 1;
        } else if (
          this.representantes[i].mismo_constitutiva === false ||
          this.representantes[i].mismo_constitutiva === 0
        ) {
          this.representantes[i].mismo_constitutiva = 0;
        }

        for (let k = 0; k < this.representantes[i].domicilios.length; k++) {
          if (
            this.representantes[i].domicilios[k].extranjero === true ||
            this.representantes[i].domicilios[k].extranjero === 1
          ) {
            this.representantes[i].domicilios[k].extranjero = 1;
          } else if (
            this.representantes[i].domicilios[k].extranjero === false ||
            this.representantes[i].domicilios[k].extranjero === 0
          ) {
            this.representantes[i].domicilios[k].extranjero = 0;
          } else {
            this.representantes[i].domicilios[k].extranjero = null;
          }
        }
      }
      data["representantes"] = this.representantes;

      let tipo_validacion = "";
      //de constitutivas
      let query = this.$route.query;
      let accion = query.accion;
      if (accion === "nuevo" || accion === "edit" || accion === "valid") {
        if (this.user.persona === "Física") {
          tipo_validacion = "constitucion_pf";
        } else if (this.user.persona === "Moral") {
          tipo_validacion = "constitucion_pm";
        }
      } else if (accion == undefined) {
        if (this.user.persona === "Física") {
          tipo_validacion = "individual_pf";
        } else if (this.user.persona === "Moral") {
          tipo_validacion = "individual_pm";
        } else if (this.user.persona === "Fideicomiso") {
          tipo_validacion = "individual_fid";
        }
      }
      data["tipo_validacion"] = tipo_validacion;

      try {
        const { documentos } = await ComparecientesApi.edit(this.id, data);

        for (let r = 0; r < documentos.length; r++) {
          let pre = documentos[r].documento.split("/");
          let fileName = pre[pre.length - 1];
          documentos[r].nombreFile = fileName;
        }
        this.documentos = documentos;

        this.$toast.success("Compareciente guardado", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
        this.loading = false;
      } catch (error) {
        this.$toast.error("Sucedió un error, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
.client {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* color: #9a9a9a; */
  color: #ea640b;
  font-size: 13px;
}

.btnG {
  margin-bottom: 5%;
}

.cardD {
  font-size: 15px !important;
  color: #ea640b;
  margin-top: 0%;
}

.leyenda {
  text-transform: lowercase !important;
  color: #79cff7;
}

.leyenda:first-letter {
  text-transform: capitalize;
}
</style>
