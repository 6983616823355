// API
import BaseApi from './base';

class CorreduriasApi extends BaseApi {
  constructor() {
    super('corredurias');
  }
}

export default new CorreduriasApi();
