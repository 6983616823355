// API
import BaseApi from './base';

class VerificarCuentaApi extends BaseApi {
  verificarCuenta(data, params = {}) {
    return this.request.post(`/verificarCuenta`, data, {
      params: {
        ...params
      }
    });
  }
}

export default new VerificarCuentaApi();
