// API
import BaseApi from './base';

class RatificacionesApi extends BaseApi {
  constructor() {
    super('ratificaciones');
  }
}

export default new RatificacionesApi();
