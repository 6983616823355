// API
import BaseApi from './base';

class CatNacionalidadesApi extends BaseApi {
  constructor() {
    super('catNacionalidades');
  }
}

export default new CatNacionalidadesApi();
