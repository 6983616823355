<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <ValidationProvider
          name="persona"
          rules="required"
          v-slot="{ errors }"
          :custom-messages="{
            required: 'La persona es obligatoria',
          }"
        >
          <label for="">Persona</label>
          <select class="custom-select" v-model="currentAccionista.persona">
            <option v-for="option in optionsPersona" :key="option.value">
              {{ option.text }}
            </option>
          </select>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row mt-4" v-if="currentAccionista.persona === 'Persona Moral'">
      <div class="col-md-12">
        <ValidationProvider
          name="denominacion_social"
          rules="required"
          v-slot="{ errors }"
          :custom-messages="{
            required: 'La denominacion social es obligatoria',
          }"
        >
          <base-input
            type="text"
            label="Denominacion social"
            placeholder="Denominacion social"
            v-model="currentAccionista.denominacion_social"
          >
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div
      class="row mt-4"
      v-else-if="currentAccionista.persona === 'Persona Física'"
    >
      <div class="col-md-4">
        <ValidationProvider
          name="nombre"
          rules="required"
          v-slot="{ errors }"
          :custom-messages="{
            required: 'El nombre es obligatorio',
          }"
        >
          <base-input
            type="text"
            label="Nombre"
            placeholder="Nombre"
            v-model="currentAccionista.nombre"
          >
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <ValidationProvider
          name="nombre"
          rules="required"
          v-slot="{ errors }"
          :custom-messages="{
            required: 'El paterno es obligatorio',
          }"
        >
          <base-input
            type="text"
            label="Paterno"
            placeholder="Paterno"
            v-model="currentAccionista.paterno"
          >
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <base-input
          type="text"
          label="Materno"
          placeholder="Materno"
          v-model="currentAccionista.materno"
        >
        </base-input>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <ValidationProvider
          name="porcentaje"
          rules="required"
          v-slot="{ errors }"
          :custom-messages="{
            required: 'El porcentaje es obligatorio',
          }"
        >
          <base-input
            type="number"
            label="Porcentaje"
            placeholder="Porcentaje"
            v-model="currentAccionista.porcentaje"
            @keyup="calcularCapitales"
          >
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <label
      class="mt-2 leyenda"
      v-if="currentAccionista.persona === 'Persona Moral'"
    >
      La cotización básica incluye solo persona física.
    </label>

    <div class="text-center">
      <button type="submit" class="btn btn-fill float-right orange-btn">
        Guardar
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentAccionista: { type: Object },
  },

  data() {
    return {
      optionsPersona: [
        { text: "Persona Física", value: "Persona Física" },
        { text: "Persona Moral", value: "Persona Moral" },
      ],

      form: {
        nombre: null,
        paterno: null,
        materno: null,
        denominacion_social: null,

        porcentaje: null,
        capital_fijo: null,
        capital_variable: null,

        persona: null,
      },
    };
  },

  methods: {
    calcularCapitales() {
      this.$emit("calcular", "");
    },
  },
};
</script>

<style scoped>
.cardErrors {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* color: #9a9a9a; */
  color: #ea640b;
  font-size: 13px;
}
.leyenda {
  text-transform: lowercase !important;
  color: #79cff7;
}
.leyenda:first-letter {
  text-transform: capitalize;
}
</style>
