<template>
  <div class="container">
    <h4 class="cardD">Documento listo para entrega</h4>
    <card class="blue">
      <i class="fa fa-info"></i>
      Aquí van instrucciones.
      <p></p>
      <i class="fa fa-play"></i>
      Links a videos.
      <p></p>
      <i class="fa fa-question"></i>
      Datos de contacta a un asesor.
    </card>

    <div v-if="constitutiva.estatus === 'Documento listo'">
      <h4 class="cardG">
        Pase por su documento a la Correduría en un horario de 10:00 a 18:00
        horas de lunes a viernes. De ser posible llame para hacer una cita.
      </h4>
    </div>
    <div v-else class="cardY">En proceso</div>
  </div>
</template>

<script>
export default {
  name: "DocListo",

  props: {
    constitutiva: { type: Object },
  },
};
</script>

<style scoped>
.blue {
  background-color: #effbff;
  border-color: #effbff !important;
  text-align: justify;
}
.bottom2 {
  /* position: fixed;
  right: 2%;
  bottom: 5%;
  overflow: visible;
  z-index: 100; */
  float: right;
}
.cardG {
  font-size: 15px !important;
  background-color: #8fc99f;
  text-align: center !important;
  height: 100px;
  width: 100%;
  line-height: 40px;
}
.cardY {
  font-size: 15px !important;
  background-color: #fff18a;
  line-height: 50px;
  text-align: center !important;
  height: 50px;
  width: 100%;
}
</style>
