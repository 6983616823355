// API
import BaseApi from './base';

class CatFormulariosConstitucionSociedadesApi extends BaseApi {
  constructor() {
    super('formulariosConstitucion');
  }

  getEstatusForm(formulario, constitucion_sociedades_id, params = {}) {
    return this.request.get(`/formulariosConstitucion/estatus/${formulario}/${constitucion_sociedades_id}`, {
      params: {
        ...params,
      },
    });
  }
}

export default new CatFormulariosConstitucionSociedadesApi();
