// API
import BaseApi from './base';

class LayoutsApi extends BaseApi {
  constructor() {
    super('layouts');
  }
}

export default new LayoutsApi();
