// API
import BaseApi from './base';

class DocumentosApi extends BaseApi {
  constructor() {
    super('documentos');
  }
}

export default new DocumentosApi();
