// API
import BaseApi from './base';

class MunicipiosApi extends BaseApi {
  constructor() {
    super('municipios');
  }
}

export default new MunicipiosApi();
