<template>
  <div>
    <gmap-map ref="mymap" :center="center" :zoom="17" :style="`height: ${heightInPx}px;`">
      <gmap-marker :draggable="true" @dragend="updateCoordinates" ref="mymarker" :position="center" />
      <!-- :position="center" -->
    </gmap-map>
  </div>
</template>

<script>
export default {
  name: "DrawGoogleMap",

  computed: {
    center() {
      return {
        lat: this.lat, //19.41942170363536,
        lng: this.lng, //-99.16301485403224,
      };
    },
  },

  data() {
    return {
      // center: {
      //   lat: this.lat, //19.41942170363536,
      //   lng: this.lng, //-99.16301485403224,
      // },

      coordinates: null,
    };
  },

  props: {
    // center: { type: Obj }, //mapUrl

    lat: null,
    lng: null,
    // center: {
    //   lat: "18.41942170363536",
    //   lng: "-98.16301485403224",
    // },
    heightInPx: {
      type: Number,
      default: () => 300
    }
  },

  methods: {
    updateCoordinates(location) {
      this.coordinates = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      };
      // console.log(location.latLng.lat() + "," + location.latLng.lng());
      this.$emit("location", this.coordinates);
    }
  },
};
</script>

<style scoped></style>
