<template>
  <div>
    <h4 class="cardD">Firma digital</h4>

    <h4 class="">
      <i class="fa fa-info"></i>
      Validaremos la información y los documentos que has cargado de los
      comparecientes y te contacteremos por email en caso de ser necesario.
      <p></p>
      <br />
      <i class="fa fa-envelope"></i>
      Si todo es correcto, te llegará un email con los documentos que firmarás
      digitalmente, además, del proyecto de la constitutiva que firmarás
      presencialmente. Después, te llegará un email de FAD - Firma Autógrafa
      Digital, donde se te indicarán los pasos para realizar la firma digital.
      <p></p>
      <br />
      <i class="fa fa-pencil"></i>
      Si estás de acuerdo en el contenido de los documentos, te pedimos que los
      firmes, de lo contrario, te pedimos que te comuniques con nosotros.
    </h4>
  </div>
</template>

<script>
export default {
  name: "FirmaDigital"
};
</script>

<style scoped>
.blue {
  background-color: #effbff;
  border-color: #effbff !important;
  text-align: justify;
}
</style>
