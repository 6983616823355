<template>
  <div>
    <!--
      <div>
        <label>País</label>
        <select @change="fetchStatesByCountry" v-model="selectCountry" class="form-control">
          <option v-for="option in countries" :key="option.id">
            {{ option.name }}
          </option>
        </select>
      </div>
      -->
    <!-- <div>
      <span>Env: {{ envVariable.EXAMPLE_ENV_VARIABLE }}</span>
      <br>
      <span>Env: {{ envVariable.API_KEY }}</span>
    </div> -->
    <div>
      <Overview></Overview>
    </div>
  </div>
</template>

<script>
import config from "@/config/";
import Overview from "@/views/staff/dashboard_content/Overview.vue";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "ExampleComponent",

  components: {
    Overview,
  },

  computed: {
    ...mapState([
      "countries",
      "states",
      "cities",
      "isEmptyStates",
      "isEmptyCities",
    ]),

    ...mapGetters([
      "getCountries",
      "getStatesByCountryId",
      "getCitiesByStateId",
    ]),
  },

  watch: {
    selectCountry: function (val, oldVal) { },
    selectState: function (val, oldVal) { },
    selectCity: function (val, oldVal) { },
  },

  async mounted() {
    // if (this.getCountries.length === 0) {
    //   await this.fetchCountries();
    // }

    // if (this.currentDomicilio.pais && this.currentDomicilio.entidad && this.currentDomicilio.delegacion_municipio) {
    //   this.selectCountry = this.currentDomicilio.pais;

    //   if (this.currentDomicilio.pais) {
    //     await this.getExistCountry(this.currentDomicilio.pais);
    //   }
    //   if (this.currentDomicilio.entidad) {
    //     await this.getExistState(this.currentDomicilio.entidad);
    //   }
    //   if (this.currentDomicilio.delegacion_municipio) {
    //     await this.getExistCity(this.currentDomicilio.delegacion_municipio);
    //   }
    // } else {
    //   this.country = "México";
    //   this.selectCountry = this.country;
    //   this.currentDomicilio.pais = this.country;

    //   await this.fetchStatesByCountry();
    // }

    // // Se coloca para que se muestre en la b-table como row details
    // this.currentDomicilio._showDetails = true;
  },

  data() {
    return {
      noStatesText: "No hay estados disponibles",
      noCitiesText: "No hay ciudades disponibles",
      selectCountry: "",
      selectState: "",
      selectCity: "",

      envVariable: config,
      // datosFacturacion: {
      //   cotizacionId: 1275,
      //   alreadyInvoiced: false
      // }
    };
  },

  methods: {
    ...mapActions(["fetchCountries", "fetchStates", "fetchCities"]),

    async fetchStatesByCountry() {
      // /**
      //  * Esta fx se llama solo si ya se tiene un país seleccionado
      //  */
      // this.selectState = "";
      // const pais = this.selectCountry;
      // this.selectCity = "";
      // // change store state cities to empty
      // this.$store.commit("setCities", []);
      // // Obtengo el id del país
      // const paisId = await this.getIdCountry(pais);
      // // obtengo el código de area del país
      // const phoneCode = await this.getPhoneCode(pais);
      // this.phoneKey = phoneCode;
      // // this.currentCompareciente.telefonos[0].clave = phoneCode;
      // // obtengo los estados del país
      // await this.fetchStates(paisId);
      // if (this.isEmptyStates) {
      //   this.selectState = "noStates";
      //   this.selectCity = "noCities";
      // }
    },

    async fetchCitiesByState() {
      // /**
      //  * Se llama para obtener las ciudades de un estado seleccionado
      //  */
      // this.selectCity = "";
      // const estado = this.selectState
      // const estadoId = await this.getIdState(estado);
      // await this.fetchCities(estadoId);
    },

    async getIdCountry(countryName) {
      // /**
      //  * Obtiene el id del país seleccionado
      //  */
      // const country = this.countries.find((c) => c.name === countryName);
      // return country ? country.id : null;
    },

    async getIdState(stateName) {
      // /**
      //  * Obtiene el id del estado seleccionado
      //  */
      // const state = this.states.find((s) => s.name === stateName);
      // return state ? state.id : null;
    },

    async getPhoneCode(countryName) {
      // /**
      //  * Obtiene el código de área del país seleccionado
      //  */
      // const country = this.countries.find((c) => c.name === countryName);
      // return country ? country.phone_code : null;
    },

    async getExistCountry(countryName) {
      // /**
      //  * Se llama si ya se tiene un país seleccionado
      //  */
      // const currentCountry = countryName === "Mexico" ? "México" : this.currentDomicilio.pais;
      // this.selectCountry = currentCountry;
    },

    async getExistState(stateName) {
      // /**
      //  * Se llama si ya se tiene un estado seleccionado
      //  */
      // const currentState = stateName
      // this.selectState = currentState;
      // const paisId = await this.getIdCountry(this.selectCountry);
      // await this.fetchStates(paisId);
    },

    async getExistCity(cityName) {
      // /**
      //  * Se llama si ya se tiene una ciudad seleccionada
      //  */
      // const currentCity = cityName;
      // this.selectCity = currentCity;
      // const estadoId = await this.getIdState(this.selectState);
      // await this.fetchCities(estadoId);
    },
  },
};
</script>
