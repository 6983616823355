<template>
  <div class="container">

    <!-- Modales -->
    <b-modal no-close-on-backdrop centered ref="notificado-modal" title="" hide-footer id="modalC" size="xl">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form
          @submit.prevent="currentNotificado.nuevo ? handleSubmit(completeCurrentNotificado) : handleSubmit(editExistentNotificadoFromCurrent)">
          <ModalCompletaNotificado :currentNotificado="currentNotificado" :notificacion="notificacion"
            @submit.prevent />
        </form>
      </ValidationObserver>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-delete" hide-footer id="modal-confirm-delete">
      <h4 class="text-center">¿Seguro que quiere eliminar al notificado?</h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-delete')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="deleteNotificadoFromTable">OK</b-button>
    </b-modal>

    <!-- Template -->
    <h4 class="cardD">Información de los Notificados</h4>

    <div class="mt-4" v-if="!notificacion.staff">
      <label for="">Agregar nuevo</label>
      <b-button variant="success" class="btn-fill spaceL" size="sm" @click="openNotificadoModalVacio">+</b-button>
    </div>

    <div>
      <b-table ref="table" hover :fields="fieldsCuadro" :items="notificacion.notificados">
        <template #cell(acciones)="{ item }">
          <b-button variant="warning" size="sm" v-b-tooltip.hover title="Editar" class="editBtn"
            @click="openEditNotificadoModal(item)">
            <b-icon-pencil />
          </b-button>
          <b-button v-if="!notificado.aceptado && !notificacion.staff" variant="danger" size="sm" v-b-tooltip.hover
            title="Eliminar" @click="openModalConfirmDelete(item.id)">
            <b-icon-trash />
          </b-button>
        </template>
      </b-table>
    </div>

    <div class="row mt-5">
    </div>
    <div class="row mt-4" v-if="!notificacion.staff">
      <div class="col-md-12">
        <ValidationProvider name="validacionDatos" :rules="{ required: { allowFalse: false } }" v-slot="{ errors }"
          :custom-messages="{
            required: 'La confirmación es obligatoria'
          }">
          <b-form-checkbox id="validacionDatos-notificado-checkbox" v-model="validacionDatos"
            :disabled="(!notificacion.staff && notificado.aceptado) || (notificacion.staff && notificacion.validaNotificado == 1)"
            class="orange">
            Acepto que la información proporcionada es correcta.
          </b-form-checkbox>
          <span class="cardErrors ">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="mt-5" v-if="!notificacion.staff">
      <div class="mt-5">
        <b-button type="submit" class="btn-fill orange-btn bottom2" size="m"
          :disabled="!validacionDatos || notificado.aceptado">Continuar</b-button>
      </div>
    </div>

    <div class="mt-5" v-if="notificacion.staff">
      <div class="mt-5">
        <b-button type="submit" class="btn-fill orange-btn bottom2" size="m"
          :disabled="notificacion.validaNotificado == 1">Validar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import ModalCompletaNotificado from "@/views/clients/procesos/notificaciones/completaInfo/modales/ModalCompletaNotificado.vue";

export default {
  name: "ComplementaNotificado",

  components: {
    ModalCompletaNotificado
  },

  props: {
    notificado: { type: Object },
    notificacion: { type: Object }
  },

  computed: {
    fieldsCuadro() {
      let fieldsCuadro = [
        { key: "index", label: "", class: "text-center" },
        {
          key: "nombreCompleto",
          label: "Nombre",
          class: "text-center",
        },
        { key: "persona", label: "Persona", class: "text-center" },

        { key: "acciones", label: "Acciones", class: "text-center" },
      ];
      return fieldsCuadro;
    },
  },

  data() {
    return {
      validacionDatos: false,

      currentNotificado: {},
      notificadoIdToDelete: null,
    }
  },

  created() {
    if (this.notificado.aceptado) {
      this.createNombreCompletoForAll();
    }
  },

  methods: {
    openNotificadoModalVacio() {
      this.currentNotificado = {
        nuevo: true
      };
      this.addEstatusToCurrentNotificado();
      this.$refs["notificado-modal"].show();
    },

    addEstatusToCurrentNotificado() {
      this.currentNotificado.aceptado = this.notificado.aceptado;
    },

    completeCurrentNotificado() {
      const nombreCompleto = this.createNombreCompleto(this.currentNotificado);
      this.addNombreCompletoToCurrentNotificado(nombreCompleto);
      if (!this.currentNotificado.id) {
        this.createIdForCurrentNotificado();
      }
      this.addCurrentNotificadoToNotificadosTable();
      this.refreshTable();
    },

    createNombreCompletoForAll() {
      this.notificacion.notificados.forEach(notificado => {
        const nombreCompleto = this.createNombreCompleto(notificado);
        notificado.nombreCompleto = nombreCompleto;
      })
    },

    createNombreCompleto(notificado) {
      let nombreCompletoTemp = ""
      if (notificado.persona === 'Física') {
        nombreCompletoTemp = notificado.nombre + ' ' + notificado.paterno;
        if (notificado.materno) {
          nombreCompletoTemp = nombreCompletoTemp + ' ' + notificado.materno
        }
      } else {
        nombreCompletoTemp = notificado.razon_social;
      }
      return nombreCompletoTemp;
    },

    addNombreCompletoToCurrentNotificado(nombreCompleto) {
      this.currentNotificado.nombreCompleto = nombreCompleto;
    },

    createIdForCurrentNotificado() {
      let id = this.notificacion.notificados.length + 1;
      this.currentNotificado.id = id;
    },

    addCurrentNotificadoToNotificadosTable() {
      this.notificacion.notificados.push(this.currentNotificado);
    },

    openEditNotificadoModal(item) {
      this.currentNotificado = item;
      this.currentNotificado.nuevo = false;
      this.addEstatusToCurrentNotificado();
      this.$refs["notificado-modal"].show();
    },

    editExistentNotificadoFromCurrent() {
      const nombreCompleto = this.createNombreCompleto(this.currentNotificado);
      this.addNombreCompletoToCurrentNotificado(nombreCompleto);
      const currentNotificadoIndex = this.getNotificadoIndexInNotificados(this.currentNotificado.id);
      this.editNombreCompletoFromExistentNotificado(currentNotificadoIndex);
    },

    getNotificadoIndexInNotificados(idNotificado) {
      let index = this.notificacion.notificados.findIndex(
        (o) => o.id === idNotificado
      );
      return index;
    },

    editNombreCompletoFromExistentNotificado(currentNotificadoIndex) {
      this.notificacion.notificados[currentNotificadoIndex].nombreCompleto = this.currentNotificado.nombreCompleto;
      this.refreshTable();
    },

    refreshTable() {
      this.$refs.table.refresh();
      this.hideNotificadoModal();
    },

    hideNotificadoModal() {
      this.$refs["notificado-modal"].hide();
    },

    openModalConfirmDelete(id) {
      this.notificadoIdToDelete = id;
      this.$refs["modal-confirm-delete"].show();
    },

    deleteNotificadoFromTable() {
      const notificadoIndex = this.getNotificadoIndexInNotificados(this.notificadoIdToDelete);
      this.notificacion.notificados.splice(notificadoIndex, 1);

      this.$refs["modal-confirm-delete"].hide();
      this.$refs.table.refresh();
    },
  }
}
</script>

<style scoped>
.container>>>.cardErrors {
  position: absolute;
  padding-top: 20px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* color: #9a9a9a; */
  color: #ea640b;
  font-size: 13px;
}

.cardErrors {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* color: #9a9a9a; */
  color: #ea640b;
  font-size: 13px;
}

.cardD {
  font-size: 15px !important;
  color: #ea640b;
  margin-top: 0%;
}

.bottom2 {
  float: right;
}

.container>>>.control-label {
  margin-left: 0px;
  width: 350px;
  position: absolute;
  vertical-align: middle;
}

.container>>>.form-control {
  width: 300px;
  margin-left: 260px;
  position: absolute;
}

.container>>>.custom-select {
  width: 150px;
  margin-left: 50px;
  position: absolute;
  vertical-align: middle;
}

.container>>>.validacionDatosLabel {
  position: absolute;
  margin-left: 30px;
  top: 25%;
  max-width: 1500px;
}

.spaceL {
  margin-left: 20px;
}

.errorLabel {
  position: absolute;
  margin-top: 20px !important;
}
</style>
