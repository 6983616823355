<template>
  <div class="container">
    <h4 class="cardD">Asignar Correduría</h4>

    <div class="container">
      <div v-if="loading">
        <div id="loader">
          <font-awesome-icon icon="spinner" spin />
        </div>
      </div>
    </div>

    <div class="mt-5 mb-4"><label for="">Ubicación del cliente</label></div>

    <div class="row ">
      <div class="col-3">
        <label>Entidad federativa</label>
      </div>
      <div class="col-9">
        {{ this.constitutiva.entidad }}
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-3">
        <label>Municipio</label>
      </div>
      <div class="col-9">
        {{ this.constitutiva.delegacion_municipio }}
      </div>
    </div>

    <label>Correduría</label>
    <select class="custom-select" v-model="constitutiva.selectedCorreduria" @change="selectCorreduria($event)">
      <option v-for="option in options_correduria" :key="option.value" :value="option.value">
        {{ option.text }}
      </option>
    </select>

    <div class="mt-4">
      <div class="row">
        <div class="col-12">
          <b-button type="submit" class="btn-fill orange-btn bottom2" size="m"
            :disabled="constitutiva.disabled">Asignar</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FuncionesApi } from "@/api";

export default {
  name: "TabAsignarCorreduria",

  props: {
    constitutiva: { type: Object }
    // catCorredurias: { type: Array }
  },

  created() {
    this.getCorredurias();
  },

  data() {
    return {
      loading: false,
      options_correduria: []
    };
  },

  methods: {
    async getCorredurias() {
      try {
        const entidad = this.constitutiva.selectedEntidad;
        let municipio = null;

        if (entidad != "Ciudad de México") {
          municipio = this.constitutiva.selectedMunicipio;
        }

        const { value } = await FuncionesApi.getCorreduriasDomicilios({
          entidad: entidad,
          municipio: municipio
        });

        value.forEach(element => {
          let exists = this.options_correduria.findIndex(
            x => x.text === element.entidad
          );
          if (exists == -1) {
            const obj = {
              text: `${element.entidad}, ${element.delegacion_municipio}, ${element.colonia
                }`,
              value: element.corredurias_id
            };
            this.options_correduria.push(obj);
          }
        });
      } catch (error) {
        console.log(error);
      }
    },

    selectCorreduria(event) {
      console.log(event.target.value);
      //esto lo pongo en corredurias_id
      this.constitutiva.corredurias_id = event.target.value;
    }
  }
};
</script>

<style scoped>
.bottom2 {
  /* position: fixed;
  right: 2%;
  bottom: 5%;
  overflow: visible;
  z-index: 100; */
  float: right;
}
</style>
