<template>
  <div class="content">
    <b-breadcrumb>
      <b-breadcrumb-item :to="{ path: '/dashboard' }">
        Inicio
      </b-breadcrumb-item>
      <b-breadcrumb-item active>Información Fes de Hechos</b-breadcrumb-item>
    </b-breadcrumb>

    <!-- <div class="container">
      <div v-if="loading">
        <div id="loader">
          <font-awesome-icon icon="spinner" spin />
        </div>
      </div>
    </div> -->

    <router-link
      :to="{ path: '/dashboard/procesos/addconstitucion/' }"
      class="route btn-fill orange-btn bottom2"
      tag="b-button"
    >
      Iniciar proceso
    </router-link>
  </div>
</template>

<script>
export default {
  name: "FesHechosInfo",
};
</script>

<style scoped>
.bottom2 {
  /* position: fixed;
  right: 2%;
  bottom: 5%;
  overflow: visible;
  z-index: 100; */
  float: left;
}
</style>
