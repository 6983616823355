// API
import BaseApi from './base';

class CotizacionesApi extends BaseApi {
  constructor() {
    super('cotizaciones');
  }
}

export default new CotizacionesApi();
