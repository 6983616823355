<template>
  <div class="content">
    <b-breadcrumb>
      <b-breadcrumb-item active>Cuenta</b-breadcrumb-item>
    </b-breadcrumb>

    <div class="container">
      <div v-if="loading">
        <div id="loader">
          <font-awesome-icon icon="spinner" spin />
        </div>
      </div>
    </div>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog" hide-footer id="modal-confirm-dialog">
      <h4 class="text-center">
        ¿Seguro que quiere eliminar al cliente secundario?
      </h4>
      <h5 class="text-center">Se eliminarán todos los datos.</h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="deleteUsuarioSecundario()">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop ref="my-modal" centered title="" hide-footer id="modal" size="lg">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(postUsuario)">
          <Modal :currentuser="currentuser" @submit.prevent />
        </form>
      </ValidationObserver>
    </b-modal>

    <div class="wrapper wrapper-full-page section content">
      <div class="container">
        <!-- <card class="card"> -->
        <div v-if="user.rol === 'Cliente Usuario'" class="mb-4 client">
          Usuario del cliente administrador: {{ user.admin_cliente_nombre }}
        </div>
        <!-- <h4 slot="header" class="card-title">Datos</h4> -->
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(updateClient)">
            <Form :user="user" :second_users="second_users" @submit.prevent @id="predeleteUsuarioSecundario"
              @dialog="showFormDialog" @usuariosecundario="editUsusarioSecundario"></Form>
          </form>
        </ValidationObserver>

        <!-- <div
          class="mb-4"
          v-if="
            second_users.length < 3 &&
            user.rol === 'Cliente Administrador' &&
            user.persona === 'Moral'
          "
        >
          <b-button
            variant="success"
            class="btn-fill"
            size="sm"
            @click="showFormDialog"
            >+</b-button
          >
        </div> -->
        <!-- </card> -->
      </div>
    </div>
  </div>
</template>
<script>
import Card from "src/components/Cards/Card.vue";
import Form from "@/views/clients/cuenta/Form.vue";
import Modal from "@/views/clients/cuenta/FormModalClientes.vue";
import { ClientesApi } from "@/api";
import { RolesApi } from "@/api";
import Storage from "@/utils/storage";
const storage = new Storage();

export default {
  name: "EditOwnClient",

  components: {
    Form,
    Card,
    Modal,
  },

  data: () => ({
    id: 0,
    loading: false,
    user: {
      firstName: null,
      lastNameP: null,
      lastNameM: null,
      email: null,
      password: null,
      rol: null,
      roles_id: null,
      // telefono: null,
      persona: null,
      rfc: null,
      admin_id: null,
      admin_cliente_nombre: null,
      nombre_comercial: null,
    },
    currentuser: {
      // rol: "Cliente Usuario",
    },
    // empresa: {
    //   nombre: null,
    //   rfc: null,
    // },
    second_users: [],
    rolesOptions: ["Cliente Administrador", "Cliente Usuario"],

    currentUsuarioSecundarioId: null,
  }),

  created() {
    this.getClient();
  },

  methods: {
    async getClient() {
      try {
        this.loading = true;

        const clienteEmail = storage.getItem("user");
        const { id } = await ClientesApi.clienteId(clienteEmail);

        this.id = id;
        const {
          nombre,
          paterno,
          materno,
          email,
          // contraseña,
          // telefono,
          persona,
          rfc,
          roles_id,
          admin_id,
          nombre_comercial,
          // empresas,
          usuarios_secundarios,
        } = await ClientesApi.detail(id);
        // .then((response) => {
        // this.currentProveedor = response;

        this.user.firstName = nombre;
        this.user.lastNameP = paterno;
        this.user.lastNameM = materno;
        this.user.email = email;
        // this.user.password = contraseña;
        // this.user.telefono = telefono;
        this.user.persona = persona;
        this.user.rfc = rfc;
        this.user.admin_id = admin_id;
        this.user.nombre_comercial = nombre_comercial;

        const { rol } = await RolesApi.rolPerId(roles_id);
        this.user.rol = rol;

        if (roles_id === 4) {
          this.user.admin_cliente_nombre = await this.getAdminName(admin_id);
        }

        if (persona === "Moral") {
          // //empresas
          // this.empresa.nombre = empresas.nombre;
          // this.empresa.rfc = empresas.rfc;

          //get second users
          for (let i = 0; i < usuarios_secundarios.length; i++) {
            // const { rol } = await RolesApi.rolPerId(
            //   usuarios_secundarios[i].roles_id
            // );
            usuarios_secundarios[i].rol = "Cliente Usuario";
          }
          this.second_users = usuarios_secundarios;
        }
        // })
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async getAdminName(admin_id) {
      const { nombre, paterno, materno, email } = await ClientesApi.detail(
        admin_id
      );
      let datos_admin = `${nombre} ${paterno} ${materno} ${email}`;
      return datos_admin;
    },

    showFormDialog() {
      this.$refs["my-modal"].show();
      this.currentuser = {};
      this.currentuser.rol = "Cliente Usuario";
    },

    async addUser() {
      this.$refs["my-modal"].show();
    },

    async getRoleId(roleName) {
      const { id } = await RolesApi.rolId(roleName);
      return id;
    },

    editUsusarioSecundario(usuariosecundario) {
      this.$refs["my-modal"].show();
      this.currentuser = usuariosecundario;
    },

    async postUsuario() {
      this.$refs["my-modal"].hide();

      const a = Object.assign({}, this.currentuser);

      if (!this.currentuser.id) {
        this.currentuser.id = this.second_users.length + 1;
        this.second_users.push(a);
      } else if (this.currentuser.id) {
        const index = this.second_users.findIndex((o) => o.id === this.currentuser.id);
        this.second_users.splice([index], 1, a);
      }

      await this.updateClient();
    },

    async updateClient() {
      try {
        this.loading = true;

        const { id } = await RolesApi.rolId(this.user.rol);
        const usuarios_secundarios = [];

        for (let i = 0; i < this.second_users.length; i++) {
          const role_id = await this.getRoleId(this.second_users[i].rol);

          usuarios_secundarios.push({
            id: this.second_users[i].id,
            nombre: this.second_users[i].nombre,
            paterno: this.second_users[i].paterno,
            materno: this.second_users[i].materno,
            email: this.second_users[i].email,
            roles_id: role_id,
            persona: "Física",
            nombre_comercial: this.user.nombre_comercial,
            activo: 1,
          });
        }

        const data = {
          nombre: this.user.firstName,
          paterno: this.user.lastNameP,
          materno: this.user.lastNameM,
          email: this.user.email,
          roles_id: id,
          persona: this.user.persona,
          rfc: this.user.rfc,
          admin_id: this.user.admin_id,
          nombre_comercial: this.user.nombre_comercial,
          activo: 1,
          usuarios_secundarios: usuarios_secundarios,
        };

        await ClientesApi.edit(this.id, data);

        this.$toast.success("Cliente actualizado", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true,
        });
      } catch (error) {
        if (error.status === 409 && error.data.message === "El correo ya ha sido registrado.") {
          const email = error.data.detail.Email;
          const tipoUsuario = error.data.detail.TipoUsuario;

          this.$toast.error(`El correo ${email} ya ha sido registrado.`, {
            timeout: 4000,
            hideProgressBar: false,
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: "button",
            icon: true,
          });

          if (tipoUsuario === "Principal") {
            this.user.email = "";
          } else if (tipoUsuario === "Secundario") {
            const index = this.second_users.findIndex(u => u.email === email);

            if (index !== -1 && !this.second_users[index].id) {
              this.second_users.splice(index, 1);
              console.log(this.second_users.length);
            }
          }

          return;
        }

        this.$toast.error("Sucedió un error, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true,
        });
      } finally {
        this.loading = false;
      }
      // var data = {
      //   email: this.user.email,
      //   contraseña: this.user.password,
      // };
      // LoginApi.login(data)
      //   .then((response) => {
      //     storage.saveItem("user", this.user.email);
      //     storage.saveItem("role", response.rol);
      //     storage.saveItem("logged", true);
      //     // this.role = response.rol;
      //     // storage.saveItem("role", "client");
      //     this.$router.push("/dashboard");
      //   })
      //   .catch(() => {
      //     storage.saveItem("logged", false);
      //     this.loading = false;
      //     this.$toast.error("Datos incorrectos", {
      //       timeout: false,
      //       hideProgressBar: true,
      //       position: "top-right",
      //       closeOnClick: true,
      //       draggable: false,
      //       closeButton: "button",
      //       icon: true,
      //     });
      //   });
      // this.loading = false;
    },

    predeleteUsuarioSecundario(id) {
      this.currentUsuarioSecundarioId = id;
      this.$refs["modal-confirm-dialog"].show();
      // this.$confirm({
      //   title: "¿Seguro que quiere eliminar al cliente secundario?",
      //   message: "Se eliminarán todos los datos.",
      //   button: {
      //     yes: "OK",
      //     no: "Cancelar",
      //   },
      //   callback: (confirm) => {
      //     if (confirm) {
      //       this.deleteUsuarioSecundario(id);
      //       // self.$vueConfirm.close();
      //     }
      //   },
      // });
    },

    async deleteUsuarioSecundario() {
      let id = this.currentUsuarioSecundarioId;

      await ClientesApi.deleteUser(id);
      const index = this.second_users.findIndex((o) => o.id == id);
      this.second_users.splice(index, 1);
      this.$refs["modal-confirm-dialog"].hide();
    },
  },
};
</script>
<style scoped>
/* .card, */
.client,
.modal {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* color: #9a9a9a; */
  color: #ea640b;
  font-size: 13px;
}
</style>
