<template>
  <div>
    <div v-if="currentCompareciente.persona == 'Persona Física' || currentCompareciente.persona == 'Física'">
      <img :src="bannerColorUrl" class="logo" />

      <div id="seccion_uno">
        <table class="tabla">
          <tr>
            <th colspan="10" class="section">
              FORMATO DE IDENTIFICACIÓN DE CLIENTES - PERSONAS FÍSICAS
            </th>
          </tr>

          <tr class="firstline">
            <td class="title">
              Mexicano
            </td>
            <td class="select">
              <h3 v-if="currentCompareciente.estatus_migratorio == 'Mexicano'" class="filledcircle"></h3>
              <h3 v-else class="circle"></h3>
            </td>
            <td class="space" style="width: 5%;"></td>
            <td class="title">
              Extranjero Visitante
            </td>
            <td class="select">
              <h3 v-if="currentCompareciente.estatus_migratorio == 'Extranjero'" class="filledcircle"></h3>
              <h3 v-else class="circle"></h3>
            </td>
            <td class="space" style="width: 5%;"></td>
            <td class="right-title">
              Fecha:
            </td>
            <td class="user" style="width: 30%;">
              {{ fecha }}
            </td>
          </tr>
          <tr>
            <td class="title">Residente Temporal o<br />Permanente</td>
            <td class="select">
              <h3 v-if="currentCompareciente.estatus_migratorio == 'Residente Temporal'" class="filledcircle"></h3>
              <h3 v-else class="circle"></h3>
            </td>
            <td class="space" style="width: 5%;"></td>
          </tr>
        </table>
      </div>

      <div id="seccion_dos">
        <table class="tabla">
          <tr>
            <th colspan="8" class="section" style="width: 100%;">
              DATOS GENERALES DE IDENTIFICACIÓN
            </th>
          </tr>
          <tr class="firstline">
            <td class="title" style="width: 15%;">
              Nombre y apellidos:
            </td>
            <td colspan="7" class="user" style="width: 80%;">
              {{ currentCompareciente.nombre }}
              {{ currentCompareciente.paterno }}
              {{ currentCompareciente.materno }}
            </td>
          </tr>
          <tr>
            <td class="title" style="width: 10%;">
              Fecha de Nacimiento:
            </td>
            <td colspan="1" class="user" style="width: 30%;">
              <!-- {{ currentCompareciente.fecha_nacimiento.substr(0, 10) }} -->
              {{ currentCompareciente.fecha_nacimiento }}
            </td>
            <td class="right-title" style="width: 11.5%;">
              Sexo:
            </td>
            <td class="user" colspan="3" style="width: 7.5%;">
              {{ currentCompareciente.sexo }}
            </td>
            <td class="right-title" style="width: 10%;">
              Alias/AKA:
            </td>
            <td class="user" style="width: 25%;">
              {{ currentCompareciente.alias }}
            </td>
          </tr>
          <tr>
            <td class="title" style="width: 15%;">
              Lugar de Nacimiento:
            </td>
            <td class="title" style="width: 7.5%;">
              Ciudad:
            </td>
            <td class="user" style="width: 10%;">
              {{ currentCompareciente.lugar_nacim_ciudad }}
            </td>
            <td class="right-title" style="width: 7.5%;">
              Estado:
            </td>
            <td colspan="2" class="user" style="width: 10%;">
              {{ currentCompareciente.lugar_nacim_estado }}
            </td>
            <td class="right-title" style="width: 10%;">
              País:
            </td>
            <td class="user" style="width: 15%;">
              {{ currentCompareciente.lugar_nacim_pais }}
            </td>
          </tr>
          <tr>
            <td class="title" style="width: 15%;">
              Nacionalidades:
            </td>
            <td colspan="7" class="user" style="width: 80%;">
              {{ currentCompareciente.nacionalidades_nombres }}
            </td>
          </tr>
          <tr>
            <td class="title" style="width: 15%;">
              Actividad / Profesión:
            </td>
            <td colspan="3" class="user" style="width: 30%;">
              {{ currentCompareciente.ocupacion }}
            </td>
            <td colspan="2" class="right-title" style="width: 15%;">
              Giro de negocios:
            </td>
            <td colspan="2" class="user" style="width: 35%;">
              {{ currentCompareciente.giro_negocios }}
            </td>
          </tr>
          <tr>
            <td class="title" style="width: 15%;">
              CURP o equivalente:
            </td>
            <td colspan="3" class="user" style="width: 30%;">
              {{ currentCompareciente.curp }}
            </td>
            <td colspan="2" class="right-title" style="width: 15%;">
              RFC o Tax ID:
            </td>
            <td colspan="2" class="user" style="width: 35%;">
              {{ currentCompareciente.rfc }}
            </td>
          </tr>
          <tr>
            <td class="title" style="width: 15%;">
              Identificación:
            </td>
            <td class="title" style="width: 7.5%;">
              Tipo:
            </td>
            <td colspan="2" class="user" style="width: 12.5%;">
              {{ currentCompareciente.identificacion_tipo }}
            </td>
            <td colspan="2" class="right-title" style="width: 15%;">
              Autoridad que expide:
            </td>
            <td colspan="2" class="user" style="width: 35%;">
              {{ currentCompareciente.identificacion_autoridad }}
            </td>
          </tr>
          <tr>
            <td class="title" style="width: 15%;"></td>
            <td class="title" style="width: 7.5%;">
              Número:
            </td>
            <td colspan="6" class="user" style="width: 72.5%;">
              {{ currentCompareciente.identificacion_numero }}
            </td>
          </tr>
          <tr>
            <td class="title" style="width: 15%;">
              Estado Civil:
            </td>
            <td colspan="3" class="user" style="width: 30%;">
              {{ currentCompareciente.estado_civil }}
            </td>
            <td colspan="2" class="right-title" style="width: 15%;">
              Régimen patrimonial
            </td>
            <td colspan="2" class="user" style="width: 35%;">
              {{ currentCompareciente.regimen_patrimonial }}
            </td>
          </tr>
          <tr class="firstline">
            <td class="title" style="width: 15%;">
              Nombre del Cónyuge:
            </td>
            <td colspan="7" class="user" style="width: 80%;">
              {{ currentCompareciente.identificacion_conyuge }}
            </td>
          </tr>
        </table>
      </div>

      <div id="seccion_tres">
        <table class="tabla">
          <tr class="firstline">
            <th colspan="8" class="section">
              DOMICILIO PARTICULAR DE LA PERSONA FÍSICA
            </th>
          </tr>
          <div v-for="domicilio in currentCompareciente.domicilios" :key="domicilio.id">
            <div v-if="
              domicilio.tipo == 'Particular' ||
              domicilio.tipo == 'Particular y Fiscal'
            ">
              <tr>
                <td class="title" style="width: 15%;">
                  Calle / Avenida / Vía:
                </td>
                <td colspan="4" class="user" style="width: 30%;">
                  {{ domicilio.calle }}
                </td>
                <td colspan="1"></td>
                <td rowspan="8" class="maps" style="width: 50%; " valign="center">
                  <img width="95%" height="70%" :src="`https://maps.googleapis.com/maps/api/staticmap?center=${domicilio.lat
                    },${domicilio.lng
                    }&zoom=17&scale=1&size=600x300&maptype=roadmap&format=png&visual_refresh=true
                        &markers=color:red%7Alabel:C%7C${domicilio.lat},${domicilio.lng
                    }
                        &key=AIzaSyC1LrDy2Gk5QhV8SjD7k4S6b4zg-wF2YEA`
                    " />
                </td>
              </tr>
              <tr>
                <td class="title" style="width: 15%;">
                  Número Exterior:
                </td>
                <td class="user" style="width: 15%;">
                  {{ domicilio.num_exterior }}
                </td>
                <td class="right-title" style="width: 7.5%;">
                  Interior:
                </td>
                <td colspan="2" class="user" style="width: 7.5%;">
                  {{ domicilio.num_interior }}
                </td>
              </tr>
              <tr>
                <td class="title" style="width: 15%;">
                  Alcaldía / Municipio:
                </td>
                <td colspan="4" class="user" style="width: 30%;">
                  {{ domicilio.delegacion_municipio }}
                </td>
              </tr>
              <tr>
                <td class="title" style="width: 15%;">
                  Entidad Federativa:
                </td>
                <td colspan="4" class="user" style="width: 30%;">
                  {{ domicilio.entidad }}
                </td>
              </tr>
              <tr>
                <td class="title" style="width: 15%;">
                  Colonia o Urbanización:
                </td>
                <td class="user" style="width: 15%;">
                  {{ domicilio.colonia }}
                </td>
                <td class="right-title" style="width: 7.5%;">
                  CP:
                </td>
                <td colspan="2" class="user" style="width: 7.5%;">
                  {{ domicilio.codigo_postal }}
                </td>
              </tr>
              <tr>
                <td class="title" style="width: 15%;">
                  Ciudad o Población:
                </td>
                <td class="user" style="width: 15%;">
                  {{ domicilio.ciudad }}
                </td>
                <td class="right-title" style="width: 7.5%;">
                  País:
                </td>
                <td colspan="2" class="user" style="width: 7.5%;">
                  {{ domicilio.pais }}
                </td>
              </tr>
            </div>
          </div>
        </table>

        <table class="tabla">
          <tr class="firstline">
            <td colspan="8" class="section">
              DOMCILIO FISCAL
            </td>
          </tr>
          <div v-for="domicilio in currentCompareciente.domicilios" :key="domicilio.id">
            <div v-if="domicilio.tipo == 'Fiscal'">
              <tr>
                <td class="title" style="width: 15%;">
                  Calle / Avenida / Vía:
                </td>
                <td colspan="4" class="user" style="width: 30%;">
                  {{ domicilio.calle }}
                </td>
                <td colspan="1"></td>
                <td rowspan="8" class="maps" style="width: 50%; " valign="center">
                  <img width="95%" height="70%" :src="`https://maps.googleapis.com/maps/api/staticmap?center=${domicilio.lat
                    },${domicilio.lng
                    }&zoom=17&scale=1&size=600x300&maptype=roadmap&format=png&visual_refresh=true
                        &markers=color:red%7Alabel:C%7C${domicilio.lat},${domicilio.lng
                    }
                        &key=AIzaSyC1LrDy2Gk5QhV8SjD7k4S6b4zg-wF2YEA`
                    " />
                </td>
              </tr>
              <tr>
                <td class="title" style="width: 15%;">
                  Número Exterior:
                </td>
                <td class="user" style="width: 15%;">
                  {{ domicilio.num_exterior }}
                </td>
                <td class="right-title" style="width: 7.5%;">
                  Interior:
                </td>
                <td colspan="2" class="user" style="width: 7.5%;">
                  {{ domicilio.num_interior }}
                </td>
              </tr>
              <tr>
                <td class="title" style="width: 15%;">
                  Alcaldía / Municipio:
                </td>
                <td colspan="4" class="user" style="width: 30%;">
                  {{ domicilio.delegacion_municipio }}
                </td>
              </tr>
              <tr>
                <td class="title" style="width: 15%;">
                  Entidad Federativa:
                </td>
                <td colspan="4" class="user" style="width: 30%;">
                  {{ domicilio.entidad }}
                </td>
              </tr>
              <tr>
                <td class="title" style="width: 15%;">
                  Colonia o Urbanización:
                </td>
                <td class="user" style="width: 15%;">
                  {{ domicilio.colonia }}
                </td>
                <td class="right-title" style="width: 7.5%;">
                  CP:
                </td>
                <td colspan="2" class="user" style="width: 7.5%;">
                  {{ domicilio.codigo_postal }}
                </td>
              </tr>
              <tr>
                <td class="title" style="width: 15%;">
                  Ciudad o Población:
                </td>
                <td class="user" style="width: 15%;">
                  {{ domicilio.ciudad }}
                </td>
                <td class="right-title" style="width: 7.5%;">
                  País:
                </td>
                <td colspan="2" class="user" style="width: 7.5%;">
                  {{ domicilio.pais }}
                </td>
              </tr>
            </div>
          </div>
        </table>
      </div>

      <div id="seccion_cuatro">
        <table class="tabla">
          <tr class="firstline">
            <td colspan="4" class="section">
              DATOS DE CONTACTO Y REDES DE LA PERSONA FÍSICA
            </td>
          </tr>
          <tr>
            <td class="title" style="width: 15%;">
              Teléfono Fijo:
            </td>
            <td class="user" style="width: 50%;">
              {{ currentCompareciente.telefonos[0].clave }}
              {{ currentCompareciente.telefonos[0].numero }}
              {{ currentCompareciente.telefonos[0].extension }}
            </td>
            <td class="right-title" style="width: 10%;">
              Facebook:
            </td>
            <td class="user" style="width: 25%;">
              {{ currentCompareciente.facebook }}
            </td>
          </tr>
          <tr>
            <td class="title" style="width: 15%;">
              Celular:
            </td>
            <td class="user" style="width: 50%;">
              {{ currentCompareciente.telefonos[1].clave }}
              {{ currentCompareciente.telefonos[1].numero }}
              {{ currentCompareciente.telefonos[1].extension }}
            </td>
            <td class="right-title" style="width: 10%;">
              Instagram:
            </td>
            <td class="user" style="width: 25%;">
              {{ currentCompareciente.instagram }}
            </td>
          </tr>
          <tr>
            <td class="title" style="width: 15%;">
              Correo electrónico:
            </td>
            <td class="user" style="width: 50%;">
              {{ currentCompareciente.email }}
            </td>
            <td class="right-title" style="width: 15%;">
              Twitter:
            </td>
            <td class="user" style="width: 50%;">
              {{ currentCompareciente.twitter }}
            </td>
          </tr>
        </table>
      </div>

      <div id="seccion_cinco">
        <table class="tabla">
          <tr class="firstline">
            <td colspan="8" class="section">
              EN CASO DE PERSONAS FÍSICAS EXTRANJERAS: DOMICILIO DE CONTACTO EN
              MÉXICO
            </td>
          </tr>
          <div v-for="domicilio in currentCompareciente.domicilios" :key="domicilio.id">
            <div v-if="domicilio.tipo == 'Extranjero'">
              <div v-if="domicilio.calle === 'No aplica'">
                <table class="tabla" style="border-collapse: collapse !important;">
                  <tr>
                    <td class="ashurado" colspan="12" style="height: 87px;background-color: gainsboro !important; ">
                    </td>
                  </tr>
                </table>
              </div>
              <div v-else>
                <tr>
                  <td class="title" style="width: 15%;">
                    Calle / Avenida / Vía:
                  </td>
                  <td colspan="3" class="user" style="width: 45%;">
                    {{ domicilio.calle }}
                  </td>
                  <td class="right-title" style="width: 15%;">
                    Num. Exterior:
                  </td>
                  <td class="user" style="width: 8.33%;">
                    {{ domicilio.num_exterior }}
                  </td>
                  <td class="right-title" style="width: 8.33%;">
                    Num. Interior:
                  </td>
                  <td class="user" style="width: 8.33%;">
                    {{ domicilio.num_interior }}
                  </td>
                </tr>
                <tr>
                  <td class="title" style="width: 15%;">
                    Colonia o Urbanización:
                  </td>
                  <td colspan="3" class="user" style="width: 45%;">
                    {{ domicilio.colonia }}
                  </td>
                  <td class="right-title" style="width: 15%;">
                    Alcaldía / Municipio:
                  </td>
                  <td colspan="3" class="user" style="width: 30%;">
                    {{ domicilio.delegacion_municipio }}
                  </td>
                </tr>
                <tr>
                  <td class="title" style="width: 15%;">
                    Ciudad o Población:
                  </td>
                  <td class="user" style="width: 15%;">
                    {{ domicilio.ciudad }}
                  </td>
                  <td class="right-title" style="width: 10%;">
                    Código Postal:
                  </td>
                  <td class="user" style="width: 10%;">
                    {{ domicilio.codigo_postal }}
                  </td>
                  <td class="right-title" style="width: 15%;">
                    Entidad Federativa:
                  </td>
                  <td colspan="3" class="user" style="width: 30%;">
                    {{ domicilio.entidad }}
                  </td>
                </tr>
              </div>
            </div>
          </div>
        </table>
      </div>

      <div id="seccion_seis">
        <table class="tabla">
          <tr class="firstline">
            <td colspan="8" class="section">
              EN CASO DE ACTUAR REPRESENTANDO - DATOS GENERALES DEL APODERADO
            </td>
          </tr>
          <!-- <div v-if="currentCompareciente.representantes.length > 0"> -->
          <div v-if="currentCompareciente.representantes !== 'No aplica'">
            <div v-if="currentCompareciente.representantes[0].nombre === 'No aplica'">
              <table class="tabla" style="border-collapse: collapse !important;">
                <tr>
                  <td class="ashurado" colspan="12" style="height: 144px;background-color: gainsboro !important; "></td>
                </tr>
              </table>
            </div>
            <div v-else>
              <tr>
                <td class="title" style="width: 15%;">
                  Nombre y apellidos:
                </td>
                <td colspan="7" class="user" style="width: 85%;">
                  {{ currentCompareciente.representantes[0].nombre }}
                  {{ currentCompareciente.representantes[0].paterno }}
                  {{ currentCompareciente.representantes[0].materno }}
                </td>
              </tr>
              <tr>
                <td colspan="2" class="title" style="width: 20%;">
                  Fecha y Lugar de Nacimiento:
                </td>
                <td class="user" style="width: 40%;">
                  <!-- {{ currentCompareciente.representantes[0].fecha_nacimiento.substr(0, 10) }} -->
                  {{ currentCompareciente.representantes[0].fecha_nacimiento }}
                  {{ currentCompareciente.representantes[0].lugar_nacim_ciudad }}
                  {{ currentCompareciente.representantes[0].lugar_nacim_estado }}
                  {{ currentCompareciente.representantes[0].lugar_nacim_pais }}
                </td>
                <td class="right-title" style="width: 8.33%;">
                  RFC:
                </td>
                <td class="user" style="width: 15%;">
                  {{ currentCompareciente.representantes[0].rfc }}
                </td>
              </tr>
              <tr>
                <td colspan="2" class="title" style="width: 20%;">
                  Datos de la escritura de poderes:
                </td>
                <td class="user" style="width: 40%;">
                  <div v-if="currentCompareciente.representantes.length > 0">
                    <span v-if="currentCompareciente.representantes[0].instrumento_numero == 'No aplica'">
                      {{
                        currentCompareciente.representantes[0]
                          .instrumento_numero
                      }}
                    </span>
                    <span class="info" v-else>
                      Constancia del instrumento público número
                      {{
                        currentCompareciente.representantes[0]
                          .instrumento_numero
                      }}, de fecha
                      <!-- {{ currentCompareciente.representantes[0].instrumento_numero_fecha.substr(0, 10) }} -->
                      {{ currentCompareciente.representantes[0].instrumento_numero_fecha }}
                      , otorgada ante la fe del licenciado
                      {{
                        currentCompareciente.representantes[0]
                          .instrumento_fedatario_nombre
                      }}, número
                      {{
                        currentCompareciente.representantes[0]
                          .instrumento_fedatario_numero
                      }}
                      de
                      {{
                        currentCompareciente.representantes[0]
                          .instrumento_fedatario_estado
                      }}
                    </span>
                  </div>
                </td>
                <td class="right-title" style="width: 8.33%;">
                  CURP:
                </td>
                <td colspan="3" class="user" style="width: 31.66%;">
                  {{ currentCompareciente.representantes[0].curp }}
                </td>
              </tr>
              <tr>
                <td class="title" style="width: 10%;">
                  Domicilio:
                </td>
                <td colspan="6" class="user" style="width: 90%;">
                  <div v-if="currentCompareciente.representantes.length > 0">
                    <span>
                      {{
                        currentCompareciente.representantes[0].domicilios[0]
                          .calle
                      }}
                      {{
                        currentCompareciente.representantes[0].domicilios[0]
                          .num_exterior
                      }}
                      {{
                        currentCompareciente.representantes[0].domicilios[0]
                          .num_interior
                      }}
                      {{
                        currentCompareciente.representantes[0].domicilios[0]
                          .colonia
                      }}
                      {{
                        currentCompareciente.representantes[0].domicilios[0]
                          .delegacion_municipio
                      }}
                      {{
                        currentCompareciente.representantes[0].domicilios[0]
                          .ciudad
                      }}
                      {{
                        currentCompareciente.representantes[0].domicilios[0]
                          .entidad
                      }}
                      {{
                        currentCompareciente.representantes[0].domicilios[0]
                          .pais
                      }}
                      {{
                        currentCompareciente.representantes[0].domicilios[0]
                          .codigo_postal
                      }}
                    </span>
                  </div>
                </td>
              </tr>
            </div>
          </div>
          <div v-else-if="currentCompareciente.representantes === 'No aplica'">
            <table class="tabla" style="border-collapse: collapse !important;">
              <tr>
                <td class="ashurado" colspan="12" style="height: 144px;background-color: gainsboro !important; "></td>
              </tr>
            </table>
          </div>
        </table>
      </div>

      <div id="seccion_siete">
        <table class="tabla" style="border-collapse: collapse !important;">
          <tr class="firstline">
            <td colspan="8" class="section">
              MANIFESTACIÓN SOBRE DUEÑO BENEFICIARIO / BENEFICIARIO CONTROLADOR
              (PLD)
            </td>
          </tr>
          <tr>
            <td colspan="8" class="title">
              Conforme a lo establecido en la fracción III de la Ley Federal
              para la Prevención e Identificación de Operaciones con Recursos de
              Procedencia lícita manifiesto, bajo protesta de decir verdad, que:
            </td>
          </tr>
          <!-- <tr class="leyenda">
            <td colspan="5" class="space" style="width: 55%;"></td>
            <td colspan="2" style="width: 30%; text-align: right;">
              (señalar SI o NO en el cuadro y firmar en la línea)
            </td>
            <td class="space" style="width: 15%"></td>
          </tr> -->
          <tr style="height: 20px;">
            <td rowspan="3" class="space" style="width: 2%;"></td>
            <td rowspan="3" class="verifycheck" style="width: 3%; border-style: dotted;">
              {{ leyenda_beneficiario_1 }}
            </td>
            <td colspan="6" class="space" style="width: 95%;"></td>
          </tr>
          <tr>
            <td class="space" style="width: 2%;"></td>
            <td colspan="4" class="title" style="width: 60%">
              {{ leyenda_beneficiario_2 }}
            </td>
            <!-- <td colspan="4" class="user"
              style="width: 35%; background-color: white !important; border-bottom: solid 2px black;">
              <span colspan="4" style="width: 35%; vertical-align: top; font-size: 10px; text-align: center;">
                {{ nombre_beneficiario_pld }}
              </span>
            </td> -->
            <td colspan="4" class=""
              style="width: 35%; background-color: white !important; border-bottom: solid 2px black;">
              <span
                style="display: block; width: 100%; font-size: 12px; text-align: center;">
                {{ nombre_beneficiario_pld }}
              </span>
            </td>
          </tr>
          <tr>
            <td class="space" style="width: 2%;"></td>
            <td colspan="4" class="title" style="width: 60%">
              {{ leyenda_beneficiario_3 }}
            </td>
            <td colspan="4" style="width: 35%; vertical-align: top; font-size: 10px; text-align: center;">
              (NOMBRE Y FIRMA)
            </td>
          </tr>

          <tr>
            <td colspan="8" class="space" style="height: 15px;"></td>
          </tr>
          <tr>
            <td colspan="8" class="verifycheck"
              style="background-color: white; border-color: black; text-align: left; font-size: 12px; font-style: italic;">
              Artículo 3 de la Ley Federal para la Prevención e Identificación
              de Operaciones con Recursos de Procedencia Ilícita.<br />
              Para los efectos de esta Ley, se entenderá por:<br />
              …III. Beneficiario Controlador, a la persona o grupo de personas
              que:<br />
              a) Por medio de otra o de cualquier acto, obtiene el beneficio
              derivado de éstos y es quien, en última instancia, ejerce los
              derechos de uso, goce, disfrute, aprovechamiento o <br />
              disposición de un bien o servicio, o<br />
              b) Ejerce el control de aquella persona moral que, en su carácter
              de cliente o usuario, lleve a cabo actos u operaciones con quien
              realice Actividades Vulnerables, así como las <br />
              personas por cuenta de quienes celebra alguno de ellos.<br />
              Se entiende que una persona o grupo de personas controla a una
              persona moral cuando, a través de la titularidad de valores, por
              contrato o de cualquier otro acto, puede:<br />
              i) Imponer, directa o indirectamente, decisiones en las asambleas
              generales de accionistas, socios unos órganos equivalentes, o
              nombrar o destituir a la mayoría de los consejeros, <br />
              administradores o sus equivalentes;<br />
              ii) Mantener la titularidad de los derechos que permitan, directa
              o indirectamente, ejercer el voto respecto de más del cincuenta
              por ciento del capital social, o<br />
              iii) Dirigir, directa o indirectamente, la administración, la
              estrategia o las principales políticas de la misma.
            </td>
          </tr>
          <tr>
            <td class="ashurado" colspan="12" style="height: 350px;background-color: gainsboro !important; "></td>
          </tr>
        </table>
      </div>

      <div id="seccion_ocho">
        <table class="tabla new-page" style="border-collapse: collapse !important;">
          <tr class="firstline">
            <td colspan="8" class="section">
              MANIFESTACIÓN RELACIONADA A LAS DISPOSICIONES DEL CFF Y LAS RMF EN
              MATERIA DE BENEFICIARIO CONTROLADOR
            </td>
          </tr>
          <tr>
            <td colspan="8" class="title">
              Declaro, bajo protesta de decir verdad, lo siguiente: (i) que me
              fue solicitada la información suficiente y necesaria para
              identificar a los Beneficiarios Controladores a que se refiere el
              Código Fiscal de la Federación: (ii) que toda la información y
              documentación que fue entregada es verdadera y la misma
              corresponde a la realidad; y (iii) que no cuento con información
              y/o documentación adicional que pudiere afectar la solicitud e
              investigación realizada por el prestador del servicio de fe
              pública ligado a este formulario y a las operaciones que derivan
              de la misma.
            </td>
          </tr>
          <tr style="height: 20px;">
            <td rowspan="3" class="space" style="width: 2%;"></td>
            <td rowspan="3" class="verifycheck" style="width: 3%; border-style: dotted;">
              {{ leyenda_beneficiario_4 }}
            </td>
            <td colspan="6" class="space" style="width: 95%;"></td>
          </tr>
          <tr>
            <td class="space" style="width: 2%;"></td>
            <td colspan="4" class="title" style="width: 60%">
              {{ leyenda_beneficiario_5 }}
            </td>
            <td class=""
              style="width: 35%; background-color: white !important; border-bottom: solid 2px black;">
              <!-- <span colspan="4" style="width: 35%; vertical-align: top; font-size: 10px; text-align: center;">
                {{ nombre_beneficiario_cff }}
              </span> -->
              <span
                style="display: block; width: 100%; font-size: 12px; text-align: center;">
                {{ nombre_beneficiario_cff }}
              </span>
            </td>
          </tr>
          <tr>
            <td class="space" style="width: 2%;"></td>
            <td colspan="4" class="title" style="width: 60%">
              {{ leyenda_beneficiario_6 }}
            </td>
            <td colspan="4" style="width: 35%; vertical-align: top; font-size: 10px; text-align: center;">
              (NOMBRE Y FIRMA)
            </td>
          </tr>
          <tr>
            <td colspan="3" class="space" style="width: 2%;"></td>
            <td colspan="5" class="title" style="width: 95%; font-size: 8px;">
              *En caso de si tener conocimiento de la existencia de Beneficiario
              Controlador adicional al que suscribe, se adjunta copia de toda la
              información actualizada y me compromento a que, en caso de que
              existan cambios posteriores a la misma, hacerle llegar las
              constancias correspondientes
            </td>
          </tr>
          <tr>
            <td colspan="8" class="space" style="height: 15px;"></td>
          </tr>
          <tr>
            <td colspan="8" class="verifycheck"
              style="background-color: white; border-color: black; text-align: left; font-size: 12px; font-style: italic;">
              Artículo 32-B Quáter CFF. Para efectos de este Código se entenderá
              por beneficiario controlador a la persona física o grupo de
              personas físicas que: <br />
              I. Directamente o por medio de otra u otras o de cualquier acto
              jurídico, obtiene u obtienen el beneficio derivado de su
              participación en una persona moral, un fideicomiso o cualquier<br />
              otra figura jurídica, así como de cualquier otro acto jurídico, o
              es quien o quienes en última instancia ejerce o ejercen los
              derechos de uso, goce, disfrute, aprovechamiento o disposición<br />
              de un bien o servicio o en cuyo nombre se realiza una transacción,
              aun y cuando lo haga o hagan de forma contingente. <br />
              II. Directa, indirectamente o de forma contingente, ejerzan el
              control de la persona moral, fideicomiso o cualquier otra figura
              jurídica.<br />
              Se entiende que una persona física o grupo de personas físicas
              ejerce el control cuando, a través de la titularidad de valores,
              por contrato o por cualquier otro acto jurídico, puede o<br />
              pueden:<br />
              a) Imponer, directa o indirectamente, decisiones en las asambleas
              generales de accionistas, socios u órganos equivalentes, o nombrar
              o destituir a la mayoría de los consejeros,<br />
              administradores o sus equivalentes.<br />
              b) Mantener la titularidad de los derechos que permitan, directa o
              indirectamente, ejercer el voto respecto de más del 15% del
              capital social o bien.<br />
              c) Dirigir, directa o indirectamente, la administración, la
              estrategia o las principales políticas de la persona moral,
              fideicomiso o cualquier otra figura jurídica.
            </td>
          </tr>
          <tr class="space" style="height: 5px;"></tr>
          <tr class="firstline">
            <td colspan="8" class="section" style="background-color: white; color: black;">
              CRITERIOS PARA LA DETERMINACIÓN DE LA CONDICIÓN DE BENEFICIARIO
              CONTROLADOR DE LAS PERSONAS MORALES
            </td>
          </tr>
          <tr class="space" style="height: 10px;"></tr>
          <tr>
            <td colspan="8" class="verifycheck"
              style="background-color: white; border-color: black; text-align: left; font-size: 12px; font-style: italic;">
              Entiendo que, para los efectos del artículo 32-B Ter del CFF, en
              la identificación de los beneficiarios controladores, se deberá
              aplicar lo dispuesto en el artículo 32-B Quáter, fracciones I<br />
              y II, incisos a), b) y c) de forma sucesiva, como criterios para
              su determinación.<br /><br />
              Para efectos de la obtención de la información a que se refiere el
              artículo 32-B Ter del CFF, las personas morales deberán
              identificar, verificar y validar la información sobre los<br />
              beneficiarios controladores, indicando los porcentajes de
              participación en el capital de la persona moral, incluyendo la
              información relativa a la cadena de titularidad, en los casos en
              que<br />
              el beneficiario controlador lo sea indirectamente. Se entiende por
              cadena de titularidad el supuesto en que se ostente la propiedad
              indirecta, a través de otras personas morales.<br />
              Asimismo, las personas morales también deberán identificar,
              verificar y validar la información relativa a la cadena de
              control, en los casos en los que el beneficiario controlador lo
              sea<br />
              por medios distintos a la propiedad. Se entiende por cadena de
              control el supuesto en que se ostente el control indirectamente, a
              través de otras personas morales, fideicomisos o<br />
              cualquier otra figura jurídica. <br /><br />
              Cuando no se identifique a persona física alguna bajo los
              criterios establecidos en esta regla, en relación con el artículo
              32-B Quáter del CFF, se considerará como beneficiario<br />
              controlador a la persona física que ocupe el cargo de
              administrador único de la persona moral o equivalente. En caso de
              que la persona moral cuente con un consejo de administración<br />
              u órgano equivalente, cada miembro de dicho consejo se considerará
              como beneficiario controlador de la persona moral.
            </td>
          </tr>
          <tr class="space" style="height: 5px;"></tr>
          <tr class="firstline">
            <td colspan="8" class="section" style="background-color: white; color: black;">
              DECLARO, BAJO PROTESTA DE DECIR VERDAD, QUE LOS DATOS ASENTADOS SON VERDADEROS
            </td>
          </tr>
          <tr class="space" style="height: 50px;"></tr>
          <tr>
            <td colspan="3" class="space" style="width: 20%;"></td>
            <td colspan="3" class="user"
              style="width: 60%; background-color: white !important; border-bottom: solid 2px black;"></td>
            <td colspan="2" class="space" style="width: 20%;"></td>
          </tr>
          <tr>
            <td colspan="3" class="space" style="width: 20%;"></td>
            <td colspan="3"
              style="width: 60%; vertical-align: top; font-size: 14px; text-align: center; background-color: white; font-weight: bold;">
              NOMBRE Y FIRMA
            </td>
            <td colspan="2" class="space" style="width: 20%;"></td>
          </tr>
          <tr>
            <td class="ashurado" colspan="12" style="height: 350px;background-color: gainsboro !important "></td>
          </tr>
        </table>
      </div>

      <div id="seccion_nueve">
        <div v-for="doc in currentCompareciente.documentos" :key="doc.id">
          <table class="tabla new-page">
            <tr>
              <td colspan="8" style="text-align: left; font-size: 14px; font-weight: bold;">
                ANEXO DOCUMENTAL
              </td>
            </tr>
            <tr>
              <td colspan="8" style="text-align: left; font-size: 14px; font-weight: bold;">
                {{ doc.tipo }}
              </td>
            </tr>
            <tr>
              <td colspan="8" style="text-align: left; font-size: 14px; font-weight: bold;">
                <img width="100%" data-v-0e3bdfbb="" :src="doc.documento + stgAccessKey" class="docs" />
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <div v-else>
      <!-- currentCompareciente.persona === 'Moral' -->
      <img :src="bannerColorUrl" class="logo" alt="Logo_Correduria_Digital" />

      <div id="seccion_uno">
        <table class="tabla">
          <tr>
            <th colspan="10" class="section">
              FORMATO DE IDENTIFICACIÓN DE CLIENTES - PERSONAS MORALES
            </th>
          </tr>
          <tr class="firstline">
            <td class="title">
              Persona Moral Mexicana
            </td>
            <td class="select">
              <h3 class="filledcircle" v-if="currentCompareciente.nacionalidades_nombres == 'México'"></h3>
              <h3 class="circle" v-else></h3>
            </td>

            <td class="space" style="width: 5%;"></td>
            <td class="title">
              Persona Moral Extranjera
            </td>
            <td class="select" style="width: 5%;">
              <h3 class="filledcircle" v-if="currentCompareciente.nacionalidades_nombres != 'México'"></h3>
              <h3 class="circle" v-else></h3>
            </td>
            <td class="space"></td>
            <td class="right-title">
              Fecha:
            </td>
            <td class="user" style="width: 30%;">
              {{ fecha }}
            </td>
          </tr>
        </table>
      </div>

      <div id="seccion_dos">
        <table class="tabla">
          <tr>
            <th colspan="8" class="section" style="width: 100%;">
              DATOS GENERALES DE IDENTIFICACIÓN
            </th>
          </tr>
          <tr class="firstline">
            <td class="title" style="width: 15%;">
              Denominación Social:
            </td>
            <td colspan="7" class="user" style="width: 80%;">
              {{ currentCompareciente.denominacion_social }}
            </td>
          </tr>
          <tr>
            <td class="title" style="width: 15%;">
              Fecha de Constitución:
            </td>
            <td colspan="3" class="user" style="width: 30%;">
              <!-- {{ currentCompareciente.fecha_constitucion.substr(0, 10) }} -->
              {{ currentCompareciente.fecha_constitucion }}
            </td>
            <td class="right-title" style="width: 7.5%;">
              Nacionalidad:
            </td>
            <td class="user" style="width: 7.5%;">
              {{ currentCompareciente.nacionalidades_nombres }}
            </td>
            <td class="right-title" style="width: 10%;">
              RFC o Tax ID:
            </td>
            <td class="user" style="width: 25%;">
              {{ currentCompareciente.rfc }}
            </td>
          </tr>
          <tr>
            <td class="title" style="width: 15%;">
              Actividad o Giro de Negocios:
            </td>
            <td colspan="4" class="user" style="width: 30%;">
              {{ currentCompareciente.actividad }}
            </td>
            <td class="right-title" style="width: 15%;">
              Domicilio Social:
            </td>
            <td colspan="2" class="user" style="width: 35%;">
              {{ currentCompareciente.domicilio_social }}
            </td>
          </tr>
          <tr>
            <td class="title" style="width: 15%; vertical-align: top;">
              Objeto Social:
            </td>
            <td colspan="7" class="verifycheck"
              style="text-align:left; vertical-align: top; font-weight: lighter; font-size: 12px; width: 65%; height: 80px;">
              {{ currentCompareciente.objeto_social }}
            </td>
          </tr>
        </table>
      </div>

      <div id="seccion_tres">
        <table class="tabla">
          <tr class="firstline">
            <td colspan="8" class="section" style="width: 10%;">
              DATOS DE CONSTITUCIÓN DE PERSONA MORAL MEXICANA
            </td>
          </tr>
          <tr>
            <td class="title" style="width: 15%;">
              Instrumento Número:
            </td>
            <td colspan="2" class="user" style="width: 20%;">
              {{ currentCompareciente.instrumento_numero }}
            </td>
            <td class="right-title" style="width: 15%;">
              De fecha:
            </td>
            <td colspan="2" class="user" style="width: 17.5%;">
              <!-- {{ currentCompareciente.instrumento_numero_fecha.substr(0, 10) }} -->
              {{ currentCompareciente.instrumento_numero_fecha }}
            </td>
            <td class="right-title" style="width: 15%;">
              Fedatario:
            </td>
            <td class="user" style="width: 17.5%;">
              {{ currentCompareciente.instrumento_fedatario }}
            </td>
          </tr>
          <tr>
            <td class="title" style="width: 15%;">
              Nombre del Fedatario:
            </td>
            <td colspan="3" class="user" style="width: 35%;">
              {{ currentCompareciente.instrumento_fedatario_nombre }}
            </td>
            <td class="right-title" style="width: 8.75%">
              Número
            </td>
            <td class="user" style="width: 8.75%;">
              {{ currentCompareciente.instrumento_fedatario_numero }}
            </td>
            <td class="right-title" style="width: 15%">
              Lugar:
            </td>
            <td class="user" style="width: 17.5%;">
              {{ currentCompareciente.instrumento_fedatario_estado }}
            </td>
          </tr>
          <tr>
            <td colspan="2" class="title" style="width: 30%;">
              Datos de Inscripción en el RPC - Folio Número:
            </td>
            <td colspan="2" class="user" style="width: 20%;">
              {{ currentCompareciente.instrumento_rpf_folio }}
            </td>
            <td colspan="2" class="right-title" style="width: 17.5%;">
              Fecha y Lugar:
            </td>
            <td colspan="2" class="user" style="width: 32.5%;">
              <!-- {{ currentCompareciente.instrumento_rpf_folio_fecha.substr(0, 10) }} -->
              {{ currentCompareciente.instrumento_rpf_folio_fecha }}
              {{ currentCompareciente.instrumento_rpf_folio_lugar }}
            </td>
          </tr>
        </table>
      </div>

      <div id="seccion_cuatro">
        <table class="tabla">
          <tr class="firstline">
            <th colspan="8" class="section">
              DOMICILIO CORPORATIVO O PRINCIPAL ASIENTO DE NEGOCIOS
            </th>
          </tr>
          <div v-for="domicilio in currentCompareciente.domicilios" :key="domicilio.id">
            <div v-if="
              domicilio.tipo == 'Particular' ||
              domicilio.tipo == 'Particular y Fiscal'
            ">
              <tr>
                <td class="title" style="width: 15%;">
                  Calle / Avenida / Vía:
                </td>
                <td colspan="4" class="user" style="width: 30%;">
                  {{ domicilio.calle }}
                </td>
                <td colspan="1"></td>
                <td rowspan="8" class="maps" style="width: 50%; " valign="center">
                  <img width="95%" height="70%" :src="`https://maps.googleapis.com/maps/api/staticmap?center=${domicilio.lat
                    },${domicilio.lng
                    }&zoom=17&scale=1&size=600x300&maptype=roadmap&format=png&visual_refresh=true
                        &markers=color:red%7Alabel:C%7C${domicilio.lat},${domicilio.lng
                    }
                        &key=AIzaSyC1LrDy2Gk5QhV8SjD7k4S6b4zg-wF2YEA`
                    " />
                </td>
              </tr>
              <tr>
                <td class="title" style="width: 15%;">
                  Número Exterior:
                </td>
                <td class="user" style="width: 15%;">
                  {{ domicilio.num_exterior }}
                </td>
                <td class="right-title" style="width: 7.5%;">
                  Interior:
                </td>
                <td colspan="2" class="user" style="width: 7.5%;">
                  {{ domicilio.num_interior }}
                </td>
              </tr>
              <tr>
                <td class="title" style="width: 15%;">
                  Alcaldía / Municipio:
                </td>
                <td colspan="4" class="user" style="width: 30%;">
                  {{ domicilio.delegacion_municipio }}
                </td>
              </tr>
              <tr>
                <td class="title" style="width: 15%;">
                  Entidad Federativa:
                </td>
                <td colspan="4" class="user" style="width: 30%;">
                  {{ domicilio.entidad }}
                </td>
              </tr>
              <tr>
                <td class="title" style="width: 15%;">
                  Colonia o Urbanización:
                </td>
                <td class="user" style="width: 15%;">
                  {{ domicilio.colonia }}
                </td>
                <td class="right-title" style="width: 7.5%;">
                  CP:
                </td>
                <td colspan="2" class="user" style="width: 7.5%;">
                  {{ domicilio.codigo_postal }}
                </td>
              </tr>
              <tr>
                <td class="title" style="width: 15%;">
                  Ciudad o Población:
                </td>
                <td class="user" style="width: 15%;">
                  {{ domicilio.ciudad }}
                </td>
                <td class="right-title" style="width: 7.5%;">
                  País:
                </td>
                <td colspan="2" class="user" style="width: 7.5%;">
                  {{ domicilio.pais }}
                </td>
              </tr>
            </div>
          </div>
        </table>
      </div>

      <div id="seccion_cinco">
        <table class="tabla ">
          <tr class="firstline">
            <th colspan="8" class="section">
              DOMICILIO FISCAL
            </th>
          </tr>
          <div v-for="domicilio in currentCompareciente.domicilios" :key="domicilio.id">
            <div v-if="domicilio.tipo == 'Fiscal'">
              <tr>
                <td colspan="2" class="title" style="width: 15%;">
                  Calle / Avenida / Vía:
                </td>
                <td colspan="3" class="user" style="width: 30%;">
                  {{ domicilio.calle }}
                </td>
              </tr>
              <tr>
                <td colspan="2" class="title" style="width: 15%;">
                  Número Exterior:
                </td>
                <td class="user" style="width: 15%;">
                  {{ domicilio.num_exterior }}
                </td>
                <td class="right-title" style="width: 7.5%;">
                  Interior:
                </td>
                <td class="user" style="width: 7.5%;">
                  {{ domicilio.num_interior }}
                </td>
              </tr>
              <tr>
                <td colspan="2" class="title" style="width: 15%;">
                  Alcaldía / Municipio:
                </td>
                <td colspan="3" class="user" style="width: 30%;">
                  {{ domicilio.delegacion_municipio }}
                </td>
              </tr>
              <tr>
                <td colspan="2" class="title" style="width: 15%;">
                  Entidad Federativa:
                </td>
                <td colspan="3" class="user" style="width: 30%;">
                  {{ domicilio.entidad }}
                </td>
              </tr>
              <tr>
                <td colspan="2" class="title" style="width: 15%;">
                  Colonia o Urbanización:
                </td>
                <td class="user" style="width: 15%;">
                  {{ domicilio.colonia }}
                </td>
                <td class="right-title" style="width: 7.5%;">
                  CP:
                </td>
                <td class="user" style="width: 7.5%;">
                  {{ domicilio.codigo_postal }}
                </td>
              </tr>
              <tr>
                <td colspan="2" class="title" style="width: 15%;">
                  Ciudad o Población:
                </td>
                <td class="user" style="width: 15%;">
                  {{ domicilio.ciudad }}
                </td>
                <td class="right-title" style="width: 7.5%;">
                  País:
                </td>
                <td class="user" style="width: 7.5%;">
                  {{ domicilio.pais }}
                </td>
              </tr>
            </div>
          </div>
        </table>
      </div>

      <div id="seccion_seis">
        <table class="tabla new-page">
          <tr class="firstline">
            <td colspan="4" class="section">
              DATOS DE CONTACTO DE LA PERSONA MORAL
            </td>
          </tr>
          <div v-if="currentCompareciente.representantes.length > 0">
            <tr>
              <td class="title" style="width: 15%;">
                Teléfono Fijo:
              </td>
              <td class="user" style="width: 35%;">
                {{ currentCompareciente.telefonos[0].clave }}
                {{ currentCompareciente.telefonos[0].numero }}
                {{ currentCompareciente.telefonos[0].extension }}
              </td>
              <td class="right-title" style="width: 15%;">
                Persona de Contacto:
              </td>
              <td class="user" style="width: 35%;">
                {{ currentCompareciente.representantes[0].nombre }}
                {{ currentCompareciente.representantes[0].paterno }}
                {{ currentCompareciente.representantes[0].materno }}
              </td>
            </tr>
            <tr>
              <td class="title" style="width: 15%;">
                Correo electrónico:
              </td>
              <td class="user" style="width: 35%;">
                {{ currentCompareciente.email }}
              </td>
              <td class="right-title" style="width: 15%;">
                WhatsApp o Red Social:
              </td>
              <td class="user" style="width: 35%;">
                No Aplica
              </td>
            </tr>
          </div>
        </table>
      </div>

      <div id="seccion_siete">
        <table class="tabla">
          <tr>
            <th colspan="8" class="section" style="width: 100%;">
              DATOS GENERALES DEL APODERADO O REPRESENTANTE
            </th>
          </tr>
          <div v-if="currentCompareciente.representantes.length > 0">
            <tr class="firstline">
              <td class="title" style="width: 15%;">
                Nombre y apellidos:
              </td>
              <td colspan="7" class="user" style="width: 80%;">
                {{ currentCompareciente.representantes[0].nombre }}
                {{ currentCompareciente.representantes[0].paterno }}
                {{ currentCompareciente.representantes[0].materno }}
              </td>
            </tr>
            <tr>
              <td class="title" style="width: 15%;">
                Fecha de Nacimiento:
              </td>
              <td colspan="3" class="user" style="width: 30%;">
                <!-- {{ currentCompareciente.representantes[0].fecha_nacimiento.substr(0, 10) }} -->
                {{ currentCompareciente.representantes[0].fecha_nacimiento }}
              </td>
              <td class="right-title" style="width: 7.5%;">
                Sexo:
              </td>
              <td class="user" style="width: 7.5%;">
                No aplica
              </td>
              <td class="right-title" style="width: 10%;">
                Alias/AKA:
              </td>
              <td class="user" style="width: 25%;">
                No aplica
              </td>
            </tr>
            <tr>
              <td class="title" style="width: 15%;">
                Lugar de Nacimiento:
              </td>
              <td class="title" style="width: 7.5%;">
                Ciudad:
              </td>
              <td class="user" style="width: 10%;">
                {{ currentCompareciente.representantes[0].lugar_nacim_ciudad }}
              </td>
              <td class="right-title" style="width: 7.5%;">
                Estado:
              </td>
              <td colspan="2" class="user" style="width: 10%;">
                {{ currentCompareciente.representantes[0].lugar_nacim_estado }}
              </td>
              <td class="right-title" style="width: 10%;">
                País:
              </td>
              <td class="user" style="width: 15%;">
                {{ currentCompareciente.representantes[0].lugar_nacim_pais }}
              </td>
            </tr>
            <tr>
              <td class="title" style="width: 15%;">
                Nacionalidad:
              </td>
              <td colspan="7" class="user" style="width: 80%;">
                {{ currentCompareciente.representantes[0].nacionalidad }}
              </td>
            </tr>
            <tr>
              <td class="title" style="width: 15%;">
                Actividad / Profesión:
              </td>
              <td colspan="3" class="user" style="width: 30%;">
                {{ currentCompareciente.representantes[0].ocupacion }}
              </td>
              <td colspan="2" class="right-title" style="width: 15%;">
                Estado Civil:
              </td>
              <td colspan="2" class="user" style="width: 35%;">
                {{ currentCompareciente.representantes[0].estado_civil }}
              </td>
            </tr>
            <tr>
              <td class="title" style="width: 15%;">
                CURP o equivalente:
              </td>
              <td colspan="3" class="user" style="width: 30%;">
                {{ currentCompareciente.representantes[0].curp }}
              </td>
              <td colspan="2" class="right-title" style="width: 15%;">
                RFC o Tax ID:
              </td>
              <td colspan="2" class="user" style="width: 35%;">
                {{ currentCompareciente.representantes[0].rfc }}
              </td>
            </tr>
            <tr>
              <td class="title" style="width: 15%;">
                Identificación:
              </td>
              <td class="title" style="width: 7.5%;">
                Tipo:
              </td>
              <td colspan="2" class="user" style="width: 12.5%;">
                {{ currentCompareciente.representantes[0].identificacion_tipo }}
              </td>
              <td colspan="2" class="right-title" style="width: 15%;">
                Autoridad que expide:
              </td>
              <td colspan="2" class="user" style="width: 35%;">
                {{
                  currentCompareciente.representantes[0]
                    .identificacion_autoridad
                }}
              </td>
            </tr>
            <tr>
              <td class="title" style="width: 15%;"></td>
              <td class="title" style="width: 7.5%;">
                Número:
              </td>
              <td colspan="6" class="user" style="width: 72.5%;">
                {{
                  currentCompareciente.representantes[0].identificacion_numero
                }}
              </td>
            </tr>
          </div>
        </table>
      </div>

      <div id="seccion_ocho">
        <table class="tabla">
          <tr class="firstline">
            <td colspan="9" class="section">
              DATOS DEL PODER
            </td>
          </tr>
          <tr>
            <td class="title" style="width: 15%;">
              Instrumento Número:
            </td>
            <td colspan="3" class="user" style="width: 20%;">
              {{ currentCompareciente.instrumento_numero }}
            </td>
            <td class="right-title" style="width: 15%;">
              De fecha:
            </td>
            <td colspan="2" class="user" style="width: 17.5%;">
              <!-- {{ currentCompareciente.instrumento_numero_fecha.substr(0, 10) }} -->
              {{ currentCompareciente.instrumento_numero_fecha }}
            </td>
            <td class="right-title" style="width: 10%;">
              Fedatario:
            </td>
            <td class="user" style="width: 22.5%;">
              {{ currentCompareciente.instrumento_fedatario }}
            </td>
          </tr>
          <tr>
            <td class="title" style="width: 15%;">
              Nombre del Fedatario:
            </td>
            <td colspan="4" class="user" style="width: 35%;">
              {{ currentCompareciente.instrumento_fedatario_nombre }}
            </td>
            <td class="right-title" style="width: 8.75%">
              Número
            </td>
            <td class="user" style="width: 8.75%;">
              {{ currentCompareciente.instrumento_fedatario_numero }}
            </td>
            <td class="right-title" style="width: 10%">
              Lugar:
            </td>
            <td class="user" style="width: 22.5%;">
              {{ currentCompareciente.instrumento_fedatario_estado }}
            </td>
          </tr>
          <tr>
            <td colspan="2" class="title" style="width: 30%;">
              Datos de Inscripción en el RPC - Folio Número:
            </td>
            <td colspan="3" class="user" style="width: 20%;">
              {{ currentCompareciente.instrumento_rpf_folio }}
            </td>
            <td colspan="2" class="right-title" style="width: 17.5%;">
              Fecha y Lugar:
            </td>
            <td colspan="2" class="user" style="width: 32.5%;">
              <!-- {{ currentCompareciente.instrumento_rpf_folio_fecha.substr(0, 10) }} -->
              {{ currentCompareciente.instrumento_rpf_folio_fecha }}
              {{ currentCompareciente.instrumento_rpf_folio_lugar }}
            </td>
          </tr>
          <!-- <tr>
                <td class="title" style="width: 20%;">
                    Tipo de representación o cargo:
                </td>
                <td colspan="4" class="user" style="width: 30%;">
                        Representante
                </td>
                <td class="right-title" style="width: 8.75%;">
                    Limitación:
                </td>
                <td colspan="4" class="user" style="width: 41.25;">
                    Limitante
                </td>
            </tr> -->
        </table>
      </div>

      <div id="seccion_nueve">
        <table class="tabla">
          <tr class="firstline">
            <td colspan="8" class="section">
              EN CASO DE PERSONAS MORALES EXTRANJERAS: DOMICILIO DE CONTACTO EN
              MÉXICO
            </td>
          </tr>
          <div v-for="domicilio in currentCompareciente.domicilios" :key="domicilio.id">
            <div v-if="domicilio.tipo == 'Extranjero'">
              <div v-if="domicilio.calle === 'No aplica'">
                <table class="tabla" style="border-collapse: collapse !important;">
                  <tr>
                    <td class="ashurado" colspan="12" style="height: 500px;background-color: gainsboro !important; ">
                    </td>
                  </tr>
                </table>
              </div>
              <div v-else>
                <tr>
                  <td class="title" style="width: 15%;">
                    Calle / Avenida / Vía:
                  </td>
                  <td colspan="3" class="user" style="width: 35%;">
                    {{ domicilio.calle }}
                  </td>
                  <td class="right-title" style="width: 10%;">
                    Num Exterior:
                  </td>
                  <td class="user" style="width: 20%;">
                    {{ domicilio.num_exterior }}
                  </td>
                  <td class="right-title" style="width: 10%;">
                    Num. Interior:
                  </td>
                  <td class="user" style="width: 10%;">
                    {{ domicilio.num_interior }}
                  </td>
                </tr>
                <tr>
                  <td class="title" style="width: 15%;">
                    Colonia o Urbanización:
                  </td>
                  <td colspan="3" class="user" style="width: 35%;">
                    {{ domicilio.colonia }}
                  </td>
                  <td class="right-title" style="width: 10%;">
                    Alcaldía / Municipio:
                  </td>
                  <td colspan="3" class="user" style="width: 40%;">
                    {{ domicilio.delegacion_municipio }}
                  </td>
                </tr>
                <tr>
                  <td class="title" style="width: 15%;">
                    Ciudad o Población:
                  </td>
                  <td class="user" style="width: 15%;">
                    {{ domicilio.ciudad }}
                  </td>
                  <td class="right-title" style="width: 10%;">
                    Código Postal:
                  </td>
                  <td class="user" style="width: 10%;">
                    {{ domicilio.codigo_postal }}
                  </td>
                  <td class="right-title" style="width: 10%;">
                    Entidad Federativa:
                  </td>
                  <td colspan="3" class="user" style="width: 40%;">
                    {{ domicilio.entidad }}
                  </td>
                </tr>
                <tr>
                  <td class="title" style="width: 15%; vertical-align: top;">
                    Datos del documento constitutivo:
                  </td>
                  <td colspan="7" class="verifycheck"
                    style="text-align:left; vertical-align: top; font-weight: lighter; font-size: 12px; width: 65%; height: 80px;">
                    {{ currentCompareciente.datos_prueba_constitucion }}
                  </td>
                </tr>
              </div>
            </div>
          </div>
          <tr>
            <td class="ashurado" colspan="12" style="height: 250px;background-color: gainsboro !important "></td>
          </tr>
        </table>
      </div>

      <div id="seccion_diez">
        <table class="tabla new-page" style="border-collapse: collapse !important;">
          <tr class="firstline">
            <td colspan="8" class="section">
              MANIFESTACIÓN SOBRE DUEÑO BENEFICIARIO / BENEFICIARIO CONTROLADOR
              (PLD)
            </td>
          </tr>
          <tr>
            <td colspan="8" class="title">
              Conforme a lo establecido en la fracción III de la Ley Federal
              para la Prevención e Identificación de Operaciones con Recursos de
              Procedencia lícita manifiesto, bajo protesta de decir verdad, que:
            </td>
          </tr>

          <tr style="height: 20px;">
            <td rowspan="3" class="space" style="width: 2%;"></td>
            <td rowspan="3" class="verifycheck" style="width: 3%; border-style: dotted;">
              {{ leyenda_beneficiario_1 }}
            </td>
            <td colspan="6" class="space" style="width: 95%;"></td>
          </tr>
          <tr>
            <td class="space" style="width: 2%;"></td>
            <td colspan="4" class="title" style="width: 60%">
              {{ leyenda_beneficiario_2 }}
            </td>
            <td class=""
              style="width: 35%; background-color: white !important; border-bottom: solid 2px black;">
              <!-- <span colspan="4" style="width: 35%; vertical-align: top; font-size: 10px; text-align: center;">
                {{ nombre_beneficiario_cff }}
              </span> -->
              <span
                style="display: block; width: 100%; font-size: 12px; text-align: center;">
                {{ nombre_beneficiario_cff }}
              </span>
            </td>
          </tr>
          <tr>
            <td class="space" style="width: 2%;"></td>
            <td colspan="4" class="title" style="width: 60%">
              {{ leyenda_beneficiario_3 }}
            </td>
            <td colspan="4" style="width: 35%; vertical-align: top; font-size: 10px; text-align: center;">
              (NOMBRE Y FIRMA)
            </td>
          </tr>
          <tr>
            <td colspan="8" class="space" style="height: 15px;"></td>
          </tr>
          <tr>
            <td colspan="8" class="verifycheck"
              style="background-color: white; border-color: black; text-align: left; font-size: 12px; font-style: italic;">
              Artículo 3 de la Ley Federal para la Prevención e Identificación
              de Operaciones con Recursos de Procedencia Ilícita.<br />
              Para los efectos de esta Ley, se entenderá por:<br />
              …III. Beneficiario Controlador, a la persona o grupo de personas
              que:<br />
              a) Por medio de otra o de cualquier acto, obtiene el beneficio
              derivado de éstos y es quien, en última instancia, ejerce los
              derechos de uso, goce, disfrute, aprovechamiento o <br />
              disposición de un bien o servicio, o<br />
              b) Ejerce el control de aquella persona moral que, en su carácter
              de cliente o usuario, lleve a cabo actos u operaciones con quien
              realice Actividades Vulnerables, así como las <br />
              personas por cuenta de quienes celebra alguno de ellos.<br />
              Se entiende que una persona o grupo de personas controla a una
              persona moral cuando, a través de la titularidad de valores, por
              contrato o de cualquier otro acto, puede:<br />
              i) Imponer, directa o indirectamente, decisiones en las asambleas
              generales de accionistas, socios unos órganos equivalentes, o
              nombrar o destituir a la mayoría de los consejeros, <br />
              administradores o sus equivalentes;<br />
              ii) Mantener la titularidad de los derechos que permitan, directa
              o indirectamente, ejercer el voto respecto de más del cincuenta
              por ciento del capital social, o<br />
              iii) Dirigir, directa o indirectamente, la administración, la
              estrategia o las principales políticas de la misma.
            </td>
          </tr>
          <tr class="space" style="height: 5px;"></tr>
        </table>
      </div>

      <div id="seccion_once">
        <table class="tabla" style="border-collapse: collapse !important;">
          <tr class="firstline">
            <td colspan="8" class="section" style="background-color: white; color: black;">
              DECLARO, BAJO PROTESTA DE DECIR VERDAD, QUE LOS DATOS ASENTADOS
              SON VERDADEROS
            </td>
          </tr>
          <tr class="space" style="height: 50px;"></tr>
          <tr>
            <td colspan="3" class="space" style="width: 20%;"></td>
            <td colspan="3" class="user"
              style="width: 60%; background-color: white !important; border-bottom: solid 2px black;"></td>
            <td colspan="2" class="space" style="width: 20%;"></td>
          </tr>
          <tr>
            <td colspan="3" class="space" style="width: 20%;"></td>
            <td colspan="3"
              style="width: 60%; vertical-align: top; font-size: 14px; text-align: center; background-color: white; font-weight: bold;">
              NOMBRE Y FIRMA
            </td>
            <td colspan="2" class="space" style="width: 20%;"></td>
          </tr>
          <tr>
            <td class="ashurado" colspan="12" style="height: 750px;background-color: gainsboro !important "></td>
          </tr>
        </table>
      </div>

      <div id="seccion_once_b">
        <table class="tabla new-page" style="border-collapse: collapse !important;">
          <tr class="firstline">
            <td colspan="8" class="section">
              MANIFESTACIÓN RELACIONADA A LAS DISPOSICIONES DEL CFF Y LAS RMF EN
              MATERIA DE BENEFICIARIO CONTROLADOR
            </td>
          </tr>
          <tr>
            <td colspan="8" class="title">
              Declaro, bajo protesta de decir verdad, lo siguiente: (i) que me
              fue solicitada la información suficiente y necesaria para
              identificar a los Beneficiarios Controladores a que se refiere el
              Código Fiscal de la Federación: (ii) que toda la información y
              documentación que fue entregada es verdadera y la misma
              corresponde a la realidad; y (iii) que no cuento con información
              y/o documentación adicional que pudiere afectar la solicitud e
              investigación realizada por el prestador del servicio de fe
              pública ligado a este formulario y a las operaciones que derivan
              de la misma.
            </td>
          </tr>
          <tr style="height: 20px;">
            <td rowspan="3" class="space" style="width: 2%;"></td>
            <td rowspan="3" class="verifycheck" style="width: 3%; border-style: dotted;">
              {{ leyenda_beneficiario_4 }}
            </td>
            <td colspan="6" class="space" style="width: 95%;"></td>
          </tr>
          <tr>
            <td class="space" style="width: 2%;"></td>
            <td colspan="4" class="title" style="width: 60%">
              {{ leyenda_beneficiario_5 }}
            </td>
            <td class=""
              style="width: 35%; background-color: white !important; border-bottom: solid 2px black;">
              <!-- <span colspan="4" style="width: 35%; vertical-align: top; font-size: 10px; text-align: center;">
                {{ nombre_beneficiario_cff }}
              </span> -->
              <span
                style="display: block; width: 100%; font-size: 12px; text-align: center;">
                {{ nombre_beneficiario_cff }}
              </span>
            </td>
          </tr>
          <tr>
            <td class="space" style="width: 2%;"></td>
            <td colspan="4" class="title" style="width: 60%">
              {{ leyenda_beneficiario_6 }}
            </td>
            <td colspan="4" style="width: 35%; vertical-align: top; font-size: 10px; text-align: center;">
              (NOMBRE Y FIRMA)
            </td>
          </tr>
          <tr>
            <td colspan="3" class="space" style="width: 2%;"></td>
            <td colspan="5" class="title" style="width: 95%; font-size: 8px;">
              *En caso de si tener conocimiento de la existencia de Beneficiario
              Controlador adicional al que suscribe, se adjunta copia de toda la
              información actualizada y me compromento a que, en caso de que
              existan cambios posteriores a la misma, hacerle llegar las
              constancias correspondientes
            </td>
          </tr>
          <tr>
            <td colspan="8" class="space" style="height: 15px;"></td>
          </tr>
          <tr>
            <td colspan="8" class="verifycheck"
              style="background-color: white; border-color: black; text-align: left; font-size: 12px; font-style: italic;">
              Artículo 32-B Quáter CFF. Para efectos de este Código se entenderá
              por beneficiario controlador a la persona física o grupo de
              personas físicas que: <br />
              I. Directamente o por medio de otra u otras o de cualquier acto
              jurídico, obtiene u obtienen el beneficio derivado de su
              participación en una persona moral, un fideicomiso o cualquier<br />
              otra figura jurídica, así como de cualquier otro acto jurídico, o
              es quien o quienes en última instancia ejerce o ejercen los
              derechos de uso, goce, disfrute, aprovechamiento o disposición<br />
              de un bien o servicio o en cuyo nombre se realiza una transacción,
              aun y cuando lo haga o hagan de forma contingente. <br />
              II. Directa, indirectamente o de forma contingente, ejerzan el
              control de la persona moral, fideicomiso o cualquier otra figura
              jurídica.<br />
              Se entiende que una persona física o grupo de personas físicas
              ejerce el control cuando, a través de la titularidad de valores,
              por contrato o por cualquier otro acto jurídico, puede o<br />
              pueden:<br />
              a) Imponer, directa o indirectamente, decisiones en las asambleas
              generales de accionistas, socios u órganos equivalentes, o nombrar
              o destituir a la mayoría de los consejeros,<br />
              administradores o sus equivalentes.<br />
              b) Mantener la titularidad de los derechos que permitan, directa o
              indirectamente, ejercer el voto respecto de más del 15% del
              capital social o bien.<br />
              c) Dirigir, directa o indirectamente, la administración, la
              estrategia o las principales políticas de la persona moral,
              fideicomiso o cualquier otra figura jurídica.
            </td>
          </tr>
          <tr class="space" style="height: 5px;"></tr>
          <tr class="firstline">
            <td colspan="8" class="section" style="background-color: white; color: black;">
              CRITERIOS PARA LA DETERMINACIÓN DE LA CONDICIÓN DE BENEFICIARIO
              CONTROLADOR DE LAS PERSONAS MORALES
            </td>
          </tr>
          <tr class="space" style="height: 10px;"></tr>
          <tr>
            <td colspan="8" class="verifycheck"
              style="background-color: white; border-color: black; text-align: left; font-size: 12px; font-style: italic;">
              Entiendo que, para los efectos del artículo 32-B Ter del CFF, en
              la identificación de los beneficiarios controladores, se deberá
              aplicar lo dispuesto en el artículo 32-B Quáter, fracciones I<br />
              y II, incisos a), b) y c) de forma sucesiva, como criterios para
              su determinación.<br /><br />
              Para efectos de la obtención de la información a que se refiere el
              artículo 32-B Ter del CFF, las personas morales deberán
              identificar, verificar y validar la información sobre los<br />
              beneficiarios controladores, indicando los porcentajes de
              participación en el capital de la persona moral, incluyendo la
              información relativa a la cadena de titularidad, en los casos en
              que<br />
              el beneficiario controlador lo sea indirectamente. Se entiende por
              cadena de titularidad el supuesto en que se ostente la propiedad
              indirecta, a través de otras personas morales.<br />
              Asimismo, las personas morales también deberán identificar,
              verificar y validar la información relativa a la cadena de
              control, en los casos en los que el beneficiario controlador lo
              sea<br />
              por medios distintos a la propiedad. Se entiende por cadena de
              control el supuesto en que se ostente el control indirectamente, a
              través de otras personas morales, fideicomisos o<br />
              cualquier otra figura jurídica. <br /><br />
              Cuando no se identifique a persona física alguna bajo los
              criterios establecidos en esta regla, en relación con el artículo
              32-B Quáter del CFF, se considerará como beneficiario<br />
              controlador a la persona física que ocupe el cargo de
              administrador único de la persona moral o equivalente. En caso de
              que la persona moral cuente con un consejo de administración<br />
              u órgano equivalente, cada miembro de dicho consejo se considerará
              como beneficiario controlador de la persona moral.
            </td>
          </tr>
          <tr class="space" style="height: 5px;"></tr>
          <tr class="firstline">
            <td colspan="8" class="section" style="background-color: white; color: black;">
              DECLARO, BAJO PROTESTA DE DECIR VERDAD, QUE LOS DATOS ASENTADOS SON VERDADEROS
            </td>
          </tr>
          <tr class="space" style="height: 50px;"></tr>
          <tr>
            <td colspan="3" class="space" style="width: 20%;"></td>
            <td colspan="3" class="user"
              style="width: 60%; background-color: white !important; border-bottom: solid 2px black;"></td>
            <td colspan="2" class="space" style="width: 20%;"></td>
          </tr>
          <tr>
            <td colspan="3" class="space" style="width: 20%;"></td>
            <td colspan="3"
              style="width: 60%; vertical-align: top; font-size: 14px; text-align: center; background-color: white; font-weight: bold;">
              NOMBRE Y FIRMA
            </td>
            <td colspan="2" class="space" style="width: 20%;"></td>
          </tr>
          <tr>
            <td class="ashurado" colspan="12" style="height: 350px;background-color: gainsboro !important "></td>
          </tr>
        </table>
      </div>

      <div id="seccion_doce">
        <div id="seccion_nueve">
          <div v-for="doc in currentCompareciente.documentos" :key="doc.id">
            <table class="tabla new-page">
              <tr>
                <td colspan="8" style="text-align: left; font-size: 14px; font-weight: bold;">
                  ANEXO DOCUMENTAL
                </td>
              </tr>
              <tr>
                <td colspan="8" style="text-align: left; font-size: 14px; font-weight: bold;">
                  {{ doc.tipo }}
                </td>
              </tr>
              <tr>
                <td colspan="8" style="text-align: left; font-size: 14px; font-weight: bold;">
                  <img width="80%" data-v-0e3bdfbb="" :src="doc.documento + stgAccessKey" class="docs" />
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "KycTemplate",

  props: {
    currentCompareciente: { type: Object },
    beneficiario_pld: { type: Number },
    beneficiario_cff: { type: Number },
    nombre_beneficiario_pld: { type: String },
    nombre_beneficiario_cff: { type: String },
  },

  created() {
    this.fecha = `${new Date().getDate()} / ${new Date().getUTCMonth() + 1} / ${new Date().getFullYear()}`;
    this.setNoAplicas();
    this.setBeneficiarioLeyendas();
  },

  data() {
    return {
      listoGenerar: false,
      //pld
      leyenda_beneficiario_1: null,
      leyenda_beneficiario_2: null,
      leyenda_beneficiario_3: null,
      //cff
      leyenda_beneficiario_4: null,
      leyenda_beneficiario_5: null,
      leyenda_beneficiario_6: null,

      currentDocumento: null,
      currentDocumentoId: null,
      fecha: null,

      options: [
        { text: "Proyecto PDF", value: "Proyecto PDF" },
        { text: "Proyecto Word", value: "Proyecto Word" }
      ],

      bannerColorUrl: "https://correduriadigital.blob.core.windows.net/correduria-digital-storage/cp_color_banner.jpg?sv=2023-01-03&st=2024-02-09T17%3A04%3A42Z&se=2034-02-11T17%3A04%3A00Z&sr=c&sp=rl&sig=rq9SDEcZMPv%2FbgiTq9FRE66lHZvEAeeiMqytpyiH2sk%3D",
      stgAccessKey: "?sv=2023-01-03&st=2024-02-09T17%3A04%3A42Z&se=2034-02-11T17%3A04%3A00Z&sr=c&sp=rl&sig=rq9SDEcZMPv%2FbgiTq9FRE66lHZvEAeeiMqytpyiH2sk%3D",
    };
  },

  components: {},

  computed: {},

  methods: {
    setBeneficiarioLeyendas() {
      if (this.beneficiario_pld == 1) {
        this.leyenda_beneficiario_1 = "NO";
        this.leyenda_beneficiario_2 =
          "tengo conocimiento del dueño beneficiario o beneficiario controlador,";
        this.leyenda_beneficiario_3 = "pues actúo por mi propio derecho.";
      } else if (this.beneficiario_pld == 2) {
        this.leyenda_beneficiario_1 = "NO";
        this.leyenda_beneficiario_2 =
          "tengo conocimiento del dueño beneficiario o beneficiario controlador, ya que actué por cuenta de mi representada, por lo que son sus accionistas quienes ";
        this.leyenda_beneficiario_3 =
          "se benefician de las consecuencias jurídicas del otorgamiento del instrumento público correspondiente.";
      } else if (this.beneficiario_pld == 3) {
        this.leyenda_beneficiario_1 = "SI";
        this.leyenda_beneficiario_2 =
          "tengo conocimiento del dueño beneficiario ";
        this.leyenda_beneficiario_3 = "o beneficiario controlador.";
      }

      if (this.beneficiario_cff == 4) {
        this.leyenda_beneficiario_4 = "NO";
        this.leyenda_beneficiario_5 =
          "Actúo por mi propio derecho, por lo que ";
        this.leyenda_beneficiario_6 =
          "NO hay beneficiario controlador distinto al suscrito.";
      } else if (this.beneficiario_cff == 5) {
        this.leyenda_beneficiario_4 = "SI";
        this.leyenda_beneficiario_5 =
          "tengo conocimiento del o los beneficiarios controladores, por lo que haré entrega ";
        this.leyenda_beneficiario_6 =
          "de la información y documentación que me sea solicitada.";
      } else if (this.beneficiario_cff == 6) {
        this.leyenda_beneficiario_4 = "NO";
        this.leyenda_beneficiario_5 =
          "tengo conocimiento del beneficiario controlador bajo los criterios establecidos en la regla 2.8.1.20. de la RMF. No obstante lo anterior, tengo conocimiento de la forma en la que está ";
        this.leyenda_beneficiario_6 =
          "compuesta la administración de mi representada, por lo que haré entrega de la información y documentación que me sea solicitada.";
      }
    },

    setNoAplicas() {
      const keys = Object.keys(this.currentCompareciente);

      for (let i = 0; i < keys.length; i++) {
        if (!this.currentCompareciente[keys[i]]) {
          this.currentCompareciente[keys[i]] = "No aplica";
        }
      }

      const telefono_na = {
        // id: 467,
        clave: "No aplica",
        telefono: "No aplica",
        tipo: "No aplica"
      };

      if (this.currentCompareciente.telefonos.length == 0) {
        this.currentCompareciente.telefonos.push(telefono_na); //tel
        this.currentCompareciente.telefonos.push(telefono_na); //cel
      } else if (this.currentCompareciente.telefonos.length == 1) {
        this.currentCompareciente.telefonos.push(telefono_na); //tel
      }

      if (this.currentCompareciente.persona == "Persona Física" || this.currentCompareciente.persona == 'Física') {
        if (this.currentCompareciente.domicilios.length > 0) {
          const domicilioParticular =
            this.currentCompareciente.domicilios.find(domicilio => domicilio.tipo == "Particular y Fiscal");

          if (domicilioParticular) {
            const index =
              this.currentCompareciente.domicilios.findIndex(domicilio => domicilio.tipo == "Particular y Fiscal");
            const domi_fiscal = Object.assign({}, this.currentCompareciente.domicilios[index]);
            // let domi_fiscal = this.currentCompareciente.domicilios[index];
            domi_fiscal.tipo = "Fiscal";
            domi_fiscal.id = null;
            this.currentCompareciente.domicilios.push(domi_fiscal);
          }

          let domicilio_extranjero = this.currentCompareciente.domicilios.find(domicilio => domicilio.tipo == "Extranjero");

          if (!domicilio_extranjero) {
            let domicilio_extranjero_na = {
              id: 467,
              calle: "No aplica",
              num_exterior: "No aplica",
              num_interior: "No aplica",
              colonia: "No aplica",
              delegacion_municipio: "No aplica",
              ciudad: "No aplica",
              entidad: "No aplica",
              pais: "No aplica",
              codigo_postal: "No aplica",
              tipo: "Extranjero"
              // lat: "19.4279485",
              // lng: "-99.15618719999999"
            };
            this.currentCompareciente.domicilios.push(domicilio_extranjero_na);
          }
        }

        if (!this.currentCompareciente.representantes
          || (Array.isArray(this.currentCompareciente.representantes) && this.currentCompareciente.representantes.length === 0)) {
          this.currentCompareciente.representantes = "No aplica";
        }

        if (this.currentCompareciente.representantes !== "No aplica") {
          if (this.currentCompareciente.representantes[0].domicilios.length == 0) {
            //añade uno de No aplica
            const domicilio_na = {
              // id: 467,
              calle: "No aplica",
              num_exterior: "No aplica",
              num_interior: "No aplica",
              colonia: "No aplica",
              delegacion_municipio: "No aplica",
              ciudad: "No aplica",
              entidad: "No aplica",
              pais: "No aplica",
              codigo_postal: "No aplica",
              tipo: "Particular y Fiscal"
              // lat: "19.4279485",
              // lng: "-99.15618719999999"
            };
            this.currentCompareciente.representantes[0].domicilios.push(domicilio_na);
          }
        }

        // if (this.currentCompareciente.representantes.length == 0) {
        //   // if (this.currentCompareciente.representantes === "No aplica") {
        //   //si no hay, añade uno con No aplica
        //   const representante_na = {
        //     // id: 467,
        //     nombre: "No aplica",
        //     email: "No aplica",
        //     rfc: "No aplica",
        //     curp: "No aplica",
        //     fecha_nacimiento: "No aplica",
        //     lugar_nacim_ciudad: "",
        //     lugar_nacim_estado: "",
        //     lugar_nacim_pais: "",
        //     nacionalidad: "",
        //     identificacion_tipo: "No aplica",
        //     identificacion_autoridad: "",
        //     identificacion_numero: "",
        //     instrumento_numero: "No aplica",
        //     instrumento_numero_fecha: "",
        //     instrumento_fedatario: "",
        //     instrumento_fedatario_nombre: "",
        //     instrumento_fedatario_numero: "",
        //     instrumento_fedatario_estado: "",
        //     instrumento_rpf_folio: "",
        //     instrumento_rpf_folio_fecha: "",
        //     instrumento_rpf_folio_lugar: "",
        //     ocupacion: "No aplica",
        //     estado_civil: "No aplica",
        //     domicilios: [
        //       {
        //         calle: "No aplica",
        //         num_exterior: "",
        //         num_interior: "",
        //         colonia: "",
        //         delegacion_municipio: "",
        //         ciudad: "",
        //         entidad: "",
        //         pais: "",
        //         codigo_postal: "",
        //         tipo: ""
        //         // lat: "19.4279485",
        //         // lng: "-99.15618719999999"
        //       }
        //     ]
        //   };
        //   this.currentCompareciente.representantes.push(representante_na);
        // }
      } else if (this.currentCompareciente.persona == "Persona Moral" || this.currentCompareciente.persona == 'Moral') {
        if (this.currentCompareciente.domicilios.length > 0) {
          let domicilioParticular = this.currentCompareciente.domicilios.find(
            domicilio => domicilio.tipo == "Particular y Fiscal"
          );
          if (domicilioParticular != null) {
            let index = this.currentCompareciente.domicilios.findIndex(
              domicilio => domicilio.tipo == "Particular y Fiscal"
            );
            let domi_fiscal = Object.assign(
              {},
              this.currentCompareciente.domicilios[index]
            );
            // let domi_fiscal = this.currentCompareciente.domicilios[index];
            domi_fiscal.tipo = "Fiscal";
            domi_fiscal.id = null;
            this.currentCompareciente.domicilios.push(domi_fiscal);
          }

          let domicilio_extranjero = this.currentCompareciente.domicilios.find(
            domicilio => domicilio.tipo == "Extranjero"
          );
          if (domicilio_extranjero == null) {
            let domicilio_extranjero_na = {
              id: 467,
              calle: "No aplica",
              num_exterior: "No aplica",
              num_interior: "No aplica",
              colonia: "No aplica",
              delegacion_municipio: "No aplica",
              ciudad: "No aplica",
              entidad: "No aplica",
              pais: "No aplica",
              codigo_postal: "No aplica",
              tipo: "Extranjero"
              // lat: "19.4279485",
              // lng: "-99.15618719999999"
            };
            this.currentCompareciente.domicilios.push(domicilio_extranjero_na);
          }
        }
      }
    }
  }
};
</script>

<style scoped>
.ashurado {
  /* margin-top: 30px;
  background-color: gainsboro !important;
  border: solid 1px gray; */

  /* background-image: linear-gradient(
    rgba(255, 255, 255, 0.2) 50%,
    transparent 50%,
    transparent
  ); */
}

.maps {
  text-align: center;
  vertical-align: middle;
  /* margin-right: 50px; */
}

.new-page {
  page-break-before: always;
}

.logo {
  width: auto;
  height: 100px;
  float: right;
}

.tabla {
  width: 100%;
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: separate;
  border-spacing: 0 1em;
}

.section {
  background-color: #4bacc6;
  color: white;
  text-align: center;
  height: 40px;
  font-weight: bolder;
}

.firstline {
  border: 5px solid white;
}

.title {
  font-size: 12px;
  text-align: left;
  width: 20%;
  font-weight: bold;
}

.select {
  text-align: left;
  width: 5%;
}

.circle {
  border-radius: 100%;
  height: 15px;
  width: 15px;
  border: 2px solid #4bacc6;
  margin-bottom: 0px;
}

.filledcircle {
  border-radius: 100%;
  height: 15px;
  width: 15px;
  border: 2px solid #4bacc6;
  background-color: #4bacc6;
  margin-bottom: 0px;
}

.verifycheck {
  border: 2px solid #4bacc6;
  background-color: rgb(231, 238, 245);
  text-align: center;
}

.right-title {
  font-size: 12px;
  text-align: right;
  font-weight: bold;
}

.user {
  text-align: left;
  font-size: 12px;
  font-weight: lighter;
  border-bottom: solid 1px black;
  background-color: rgb(231, 238, 245);
}

.leyenda {
  font-size: 9px;
}
</style>
