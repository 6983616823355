<template>
  <div>
    <div class="container">
      <div v-if="loading">
        <div id="loader">
          <font-awesome-icon icon="spinner" spin />
        </div>
      </div>
    </div>

    <DashboardStaff v-if="role === 'Staff Administrador' || role === 'Staff Usuario'" />
    <!-- <DashboardStaff v-if="role === 'Staff Usuario'" /> -->
    <DashboardClients v-else-if="role === 'Cliente Administrador' || role === 'Cliente Usuario'" />
    <!-- <DashboardClients v-else-if="role === 'Cliente Usuario'" /> -->
  </div>
</template>

<script>
import DashboardClients from "../views/clients/DashboardClients.vue";
import DashboardStaff from "../views/staff/DashboardStaff.vue";

import Storage from "@/utils/storage";

const storage = new Storage();

export default {
  name: "Dashboard",

  components: {
    DashboardStaff,
    DashboardClients,
  },

  data: () => ({
    loading: false,
    logged: false,
    role: null,
  }),

  created() {
    this.getLogged();
  },

  methods: {
    getLogged() {
      this.logged = storage.getItem("logged");
      this.role = storage.getItem("role");
      // console.log(this.logged);
      // console.log(this.role);
    },
  },
};
</script>
