<template>
  <div>
    <table class="tabla">
      <tr class="title">
        <td>
            HOJA DE FIRMAS CORRESPONDIENTE AL PROYECTO<br>DE ACTA DE RATIFICACION DE FIRMAS
        </td>
      </tr>
    </table>
    <table class="tabla" style="margin-top: 5px; border-spacing: 0 15px;">
      <tr>
        <td>
            El día {{ fecha }}, los suscritos confirmamos que hemos contratado los servicios de Correduria 
            Digital, S. de R.L. de C.V. (“Correduría Digital”), a efecto de que dicha entidad realice las gestiones 
            necesarias, con el Corredor Público de su elección en la plaza que al efecto se designe (el “Corredor 
            Público Designado”), a fin de que las personas que firmamos de forma digital el presente documento, 
            llevemos a cabo la firma del acta de ratificación de firmas {{ del_delos }} documento{{s}} a que se refiere 
            dicho proyecto de acta, en términos similares al documento que antecede a la presente hoja de firmas.
        </td>             
      </tr>
      <tr>
        <td>
            Declaramos a Correduría Digital y al Corredor Público Designado, bajo protesta de decir verdad, que 
            los suscritos somos los únicos responsables del contenido {{ del_delos }} documento{{s}} que presentamos 
            para su ratificación, mismo{{s}} que {{ fue_ron }} elaborado{{s}} y celebrado{{s}} con anterioridad a la presente 
            solicitud, sin que Correduría Digital ni el Corredor Público Designado intervinieran de manera alguna 
            en su elaboración.
        </td>   
      </tr>
      <tr>
        <td>
            Entendemos que el servicio contratado a Correduría Digital no incluye asesoría legal alguna respecto 
            del contenido {{ del_delos }} documento{{s}} que hemos presentado para su ratificación, ni certifica o califica 
            sobre la pertinencia, alcance o utilidad de dicho{{s}} documento{{s}}.
        </td>   
      </tr>
      <tr>
        <td>
            Asimismo, por medio de la presente confirmamos que toda la información y documentación que ha 
            sido entregada a través de medios digitales, y aquella que se entrega de forma física a Correduría Digital 
            y al Corredor Público Designado, es verídica, auténtica y exacta, y la misma corresponde a la realidad.
        </td>   
      </tr>
      <tr>
        <td>
            En el mismo sentido, declaramos conocer y aceptar el aviso de privacidad que ha sido publicado en la 
            plataforma y sitio de Internet visitado, así como los términos y condiciones aplicables a la prestación 
            de los servicios solicitados.
        </td>   
      </tr>
    </table>
    <table class="tabla">
        <tr style="height: 30px;"></tr>
    </table>
    <table class="tabla">
      <tbody v-for="(pair, index) in signerPairs" :key="index">
        <!-- names -->
        <tr>
          <th colspan="3" class="section firma">
            {{ !pair[0].derecho_propio ? pair[0].nombreCompleto : pair[0].moral_representada }}
          </th>
          <th v-if="pair[1]" colspan="3" class="section firma">
            {{ !pair[1].derecho_propio ? pair[1].nombreCompleto : pair[1].moral_representada }}
          </th>
        </tr>

        <!-- space for signatures -->
        <tr>
          <td style="width: 10%;"></td>
          <td style="width: 30%; background-color: white !important; border-bottom: solid 2px black;"></td>
          <td style="width: 10%;"></td>
          
          <td v-if="pair[1]" style="width: 10%;"></td>
          <td v-if="pair[1]" style="width: 30%; background-color: white !important; border-bottom: solid 2px black;"></td>
          <td v-if="pair[1]" style="width: 10%;"></td>
        </tr>

        <!-- name representante -->
        <tr>
          <!-- Left signer -->
          <td style="width: 10%;"></td>
          <td style="width: 30%; vertical-align: top; font-size: 14px; text-align: center; background-color: white;">
            {{ !pair[0].derecho_propio ? '' : `Por: ${pair[0].nombreCompleto}` }}
          </td>
          <td style="width: 10%;"></td>
          
          <!-- Right signer if exists -->
          <td v-if="pair[1]" style="width: 10%;"></td>
          <td v-if="pair[1]" style="width: 30%; vertical-align: top; font-size: 14px; text-align: center; background-color: white;">
            {{ !pair[1].derecho_propio ? '' : `Por: ${pair[1].nombreCompleto}` }}
          </td>
          <td v-if="pair[1]" style="width: 10%;"></td>
        </tr>

        <!-- cargo representante -->
        <tr>
          <!-- Left signer's position -->
          <td style="width: 10%;"></td>
          <td style="width: 30%; vertical-align: top; font-size: 14px; text-align: center; background-color: white;">
            {{ !pair[0].derecho_propio ? '' : `Cargo: Representante Legal` }}
          </td>
          <td style="width: 10%;"></td>

          <!-- Right signer's position if exists -->
          <td v-if="pair[1]" style="width: 10%;"></td>
          <td v-if="pair[1]" style="width: 30%; vertical-align: top; font-size: 14px; text-align: center; background-color: white;">
            {{ !pair[1].derecho_propio ? '' : `Cargo: Representante Legal` }}
          </td>
          <td v-if="pair[1]" style="width: 10%;"></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "HojaFirmasTemplate",

  props: {
    ratificacion: { type: Object }
  },

  data() {
    return {
      del_delos: null,
      s: null,
      fue_ron: null,
      fecha: null,
      ratificantes_fisicas: null
    }
  },

  computed: {
    signerPairs() {
      let pairs = [];
      for (let i=0; i < this.ratificantes_fisicas.length; i += 2) {
        pairs.push(this.ratificantes_fisicas.slice(i, i+2));
      }
      return pairs;
    }
  },

  created() {
    const documentos_principales = this.ratificacion.documentos.filter(doc => 
      parseInt(doc.cat_documentos_id) == 47);
    const multiples_documentos = documentos_principales.length > 1;

    multiples_documentos ? this.del_delos = 'de los' : 'del';
    multiples_documentos ? this.s = 's' : '';
    multiples_documentos ? this.fue_ron = 'fueron' : 'fue';

    this.fecha = this.fechaEnTexto();

    this.ratificantes_fisicas = this.ratificacion.ratificantes.filter(rati =>
      rati.persona == 'Física');

    this.ratificantes_fisicas.forEach(rati => {
      if (rati.moral_representada_id == null) {
        rati.derecho_propio = false;
      } else {
        rati.derecho_propio = true;
        rati.moral_representada = this.ratificacion.ratificantes.filter(obj => 
          obj.id == rati.moral_representada_id)[0].denominacion_social;
      }
    });
    
  },

  methods: {
    numeroAPalabras(num) {
      const unidades = ['cero', 'uno', 'dos', 'tres', 'cuatro', 'cinco', 'seis', 'siete', 'ocho', 'nueve'];
      const decenas = ['', 'diez', 'veinte', 'treinta', 'cuarenta', 'cincuenta', 'sesenta', 'setenta', 'ochenta', 'noventa'];
      const entre11y19 = ['once', 'doce', 'trece', 'catorce', 'quince', 'dieciséis', 'diecisiete', 'dieciocho', 'diecinueve'];
      const entre21y29 = 'veinti';
    
      if (num < 10) return unidades[num];
      if (num >= 10 && num <= 19) return entre11y19[num - 11];
      if (num >= 20 && num < 30) return entre21y29 + unidades[num - 20];
      if (num >= 30) return decenas[Math.floor(num / 10)] + (num % 10 !== 0 ? ' y ' + unidades[num % 10] : '');
    },

    fechaEnTexto(fecha = new Date()) {
      const meses = ['', 'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];

      let dia = fecha.getDate();
      let mes = meses[fecha.getMonth() + 1];
      let anio = fecha.getFullYear();
    
      // Convertir el año en palabras puede ser más complejo, así que lo vamos a hacer por partes
      let anioEnPalabras = '';
      if(anio >= 2000 && anio < 2100) {
          anioEnPalabras = 'dos mil ' + (anio > 2000 ? this.numeroAPalabras(anio - 2000) : '');
      } // Aquí puedes agregar más lógica para otros rangos de años si es necesario
    
      return `${this.numeroAPalabras(dia)} de ${mes} de ${anioEnPalabras}`.trim();
    }
  }
}
</script>
