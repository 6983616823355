<template>
  <div class="container">
    <h4 class="cardD">Entrega de información base</h4>
    <!-- <card class="blue">
      <i class="fa fa-info"></i>
      <font-awesome-icon icon="info" />
      Aquí van instrucciones.
      <p></p>
      <i class="fa fa-play"></i>
      Links a videos.
      <p></p>
      <i class="fa fa-question"></i>
      Datos de contacta a un asesor.
    </card> -->

    <!-- <div class="">
      <b-button type="submit" class="btn-fill orange-btn" size="m"
        >Comenzar</b-button
      >
    </div> -->
  </div>
</template>

<script>
export default {
  name: "InfoBase",
};
</script>

<style scoped>
.blue {
  background-color: #effbff;
  border-color: #effbff !important;
  text-align: justify;
}
</style>
