<template>
  <div class="container mt-5">
    <div class="card" id="container-openpay">
      <div class="card-body">

        <div class="mt-3 center">
          <p>
            Correduría Digital no guarda nigún dato de la compra. Todo se efectúa a través de la plataforma de OpenPay.
          </p>
        </div>

        <form id="payment-form">
          <input type="hidden" name="token_id" id="token_id" />
          <div class="form-group">
            <label for="holder_name">Nombre del titular</label>
            <input type="text" class="form-control" id="holder_name" placeholder="Como aparece en la tarjeta"
              autocomplete="off" data-openpay-card="holder_name" ref="input_holder_name" />
          </div>
          <div class="form-group">
            <label for="card_number">Número de tarjeta</label>
            <input type="text" class="form-control" id="card_number" autocomplete="off" data-openpay-card="card_number"
              placeholder="Número de tarjeta" ref="input_card_number" />
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="expiration_month">Fecha de expiración (Mes)</label>
              <input type="text" class="form-control" id="expiration_month" placeholder="Mes 2 dígitos"
                data-openpay-card="expiration_month" ref="input_expiration_month" />
            </div>
            <div class="form-group col-md-6">
              <label for="expiration_year">Fecha de expiración (Año)</label>
              <input type="text" class="form-control" id="expiration_year" placeholder="Año 2 dígitos"
                data-openpay-card="expiration_year" ref="input_expiration_year" />
            </div>
          </div>
          <div class="form-group">
            <label for="cvv2">Código de seguridad</label>
            <input type="text" class="form-control" id="cvv2" placeholder="3 dígitos" autocomplete="off"
              data-openpay-card="cvv2" ref="input_cvv2" />
          </div>
          <div class="d-flex justify-content-between align-items-center my-3">
            <small class="orange-text size14">
              <img src="/img/lock.png" alt="Seguridad" height="32" class="mr-2" />
              Tus pagos se realizan de forma segura con encriptación de 256 bits
            </small>
          </div>
          <div class="m-3 cards">
            <img src="/img/openpay.png" alt="Openpay" height="64" />
            <img src="/img/visa.png" alt="Visa" height="32" class="mr-3" />
            <img src="/img/mastercard.png" alt="Mastercard" height="32" class="mr-3" />
            <img src="/img/amex.png" alt="American Express" height="32" class="mr-3" />
          </div>
          <button type="button" ref="btnPagar" class="btn btn-block orange-btn" @click.prevent="pagar">
            Pagar
          </button>
        </form>
      </div>
    </div>

    <b-overlay :show="loading" no-wrap></b-overlay>
  </div>
</template>

<script>
import { FuncionesApi } from "@/api";
import Storage from "@/utils/storage";
const storage = new Storage();

export default {
  name: "Openpay",

  props: {
    cotizacion_id: { type: Number },
    precio_total: { type: Object }
  },

  data: () => ({
    loading: false,
    valid: true,
    number_card: "",
    mask_credit_card: "credit-card",
    rules_cvc: {
      required: v => !!v || "El cvc es requerido",
      min: v => (v && v.length >= 3) || "Mínimo 3 números",
      max: v => (v && v.length <= 4) || "Máximo 4 números"
    },
    items_months: [
      { text: "01 - Enero", value: "01" },
      { text: "02 - Febrero", value: "02" },
      { text: "03 - Marzo", value: "03" },
      { text: "04 - Abril", value: "04" },
      { text: "05 - Mayo", value: "05" },
      { text: "06 - Junio", value: "06" },
      { text: "07 - Julio", value: "07" },
      { text: "08 - Agosto", value: "08" },
      { text: "09 - Septiembre", value: "09" },
      { text: "10 - Octubre", value: "10" },
      { text: "11 - Noviembre", value: "11" },
      { text: "12 - Diciembre", value: "12" }
    ],
    name_titular: "",
    email: "",
    last_name_titular: "",
    month_expiration: "",
    year_expiration: "",
    cvv2: "",
    rules_year: [
      v => !!v || "El año de expiración es requerido",
      v => (v && v.length >= 2) || "Mínimo 2 números",
      v => (v && v.length <= 2) || "Máximo 2 números"
    ],
    emailRules: [
      v => !!v || "El correo electrónico es requerido",
      v => /.+@.+/.test(v) || "El correo electrónico debe de ser valido"
    ],
    token: "",
    endpoint_sandbox_openpay: "https://sandbox-api.openpay.mx/v1/",
    endpoint_payament: "http://laravel-openpay.test/api/charge",
    deviceSessionId: "",
    openpay_id: "mq1ywspky4llz2q57fng",
    openpay_key: "pk_622fbf66b6d745ffa4bc9e20b1aa5f39",
    openpay_sandbox_mode: true,
    mesageButton: "Pagar",
    iconButton: "attach_money",
    colorButton: "primary"
  }),

  mounted() {
    OpenPay.setId(this.openpay_id);
    OpenPay.setApiKey(this.openpay_key);
    OpenPay.setSandboxMode(this.openpay_sandbox_mode);
    this.deviceSessionId = OpenPay.deviceData.setup("payment-form", "deviceIdHiddenFieldName");
  },

  methods: {
    pagar() {
      if (this.precio_total <= 0) {
        this.showError(`El monto $${this.precio_total} no es una cantidad válida.`);
        return;
      }
      if (this.$refs.input_holder_name.value.length === 0) {
        this.showError(`El nombre del titular no es válido.`);
        return;
      }
      if (this.$refs.input_card_number.value.length === 0) {
        this.showError(`El número de tarjeta no es válido.`);
        return;
      }
      if (this.$refs.input_expiration_month.value.length === 0
        || this.$refs.input_expiration_year.value.length === 0) {
        this.showError(`La fecha de expiración no es válida.`);
        return;
      }
      if (this.$refs.input_cvv2.value.length === 0) {
        this.showError(`El código de seguridad no es válido.`);
        return;
      }

      this.$toast.info("Procesando pago, por favor espere.", {
        timeout: 4000,
        hideProgressBar: false,
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        closeButton: "button",
        icon: true
      });

      OpenPay.token.extractFormAndCreate(
        "payment-form",
        this.sucess_callbak,
        this.error_callbak
      );
    },

    showError(errorMessage = "") {
      this.$toast.error(errorMessage, {
        timeout: 4000,
        hideProgressBar: false,
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        closeButton: "button",
        icon: true
      });
    },

    async sucess_callbak(response) {
      this.loading = true;

      var token_id = response.data.id;
      $("#token_id").val(token_id);

      try {
        var clienteEmail = storage.getItem("user");
        let data = {
          sourceId: token_id,
          description: "Correduría Digital",
          amount: this.precio_total,
          deviceSessionId: this.deviceSessionId,
          cliente_email: clienteEmail,
          cotizaciones_id: this.cotizacion_id
        };
        let result = await FuncionesApi.payCotizacion(data);

        this.$toast.success("El pago fue realizado correctamente", {
          timeout: 4000,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });

        this.clearFields();
        this.$refs.btnPagar.disabled = true;
        this.$emit("payCotizacion", true);
      } catch (error) {
        this.$toast.error(error.data, {
          timeout: 4000,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
      } finally {
        this.loading = false;
      }
    },

    async error_callbak(response) {
      let desc = response.data.description !== undefined
        ? response.data.description
        : response.message;
      this.showError(desc);
    },

    clearFields() {
      this.$refs.input_holder_name.value = "";
      this.$refs.input_card_number.value = "";
      this.$refs.input_expiration_month.value = "";
      this.$refs.input_expiration_year.value = "";
      this.$refs.input_cvv2.value = "";
    }
  }
};
</script>

<style scoped>
.container {
  margin: 0 auto;
}

.size14 {
  font-size: 14px;
}

.cards {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.card {
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-body {
  padding: 20px;
}

.card .form-group {
  margin-bottom: 20px;
}

.card .btn {
  margin-top: 20px;
}

.b-overlay-wrap {
  position: relative;
}
</style>
