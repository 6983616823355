import axios from 'axios';
import config from '@/config';
import routes from '@/routes/routes.js';

const axiosService = axios.create({
  baseURL: config.API_URL,
  params: {
    code: config.API_KEY
  }
});

axiosService.interceptors.request.use(
  (_config) => {
    const token = localStorage.getItem('accessToken');

    if (token) {
      _config.headers['Authorization'] = `Bearer ${token}`;
    }

    return _config;
  },
  (error) => {
    if (config.DEBUG) {
      console.error('AXIOS: ', error);
    }

    return Promise.reject(error);
  },
);

axiosService.interceptors.response.use(
  (response) => response.data,
  (error) => {
    if (config.DEBUG) {
      console.error('AXIOS: ', error.response);
    }
    if (error.response && error.response.status === 401) {
      localStorage.clear();
      routes.push({ name: 'Login' });
    }

    return Promise.reject(error.response);
  },
);

export default axiosService;
