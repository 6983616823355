<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <ValidationProvider name="tipo" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El tipo es obligatorio'
        }">
          <label for="">Tipo</label>
          <select v-model="currentTelefono.tipo" class="custom-select">
            <option value="Teléfono">Fijo</option>
            <option value="Celular">Celular</option>
            <option value="Fax">Fax</option>
          </select>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-12 mt-2">
        <ValidationProvider name="clave" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La clave es obligatoria'
        }">
          <label>Clave de país</label>
          <select v-model="claveCompleta" class="custom-select" @change="setClave($event)">
            <option v-for="option in optionsClaves" :key="option.value">
              {{ option.text }}
            </option>
          </select>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-12">
        <ValidationProvider name="numero" :rules="{ required: true, length: 10, regex: /^\d{10}$/ }"
          v-slot="{ errors }" :custom-messages="{
            required: 'El número es obligatorio',
            length: 'El número debe tener 10 dígitos',
            regex: 'El número no tiene el formato correcto'
          }">
          <base-input type="tel" label="Número" placeholder="Número" v-model="currentTelefono.numero">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-12">
        <base-input type="extension" label="Extensión" placeholder="Extensión" v-model="currentTelefono.extension">
        </base-input>
      </div>
    </div>

    <div class="text-center" v-if="!disableSaveButton">
      <button type="submit" class="btn btn-fill float-right orange-btn">
        Guardar
      </button>
    </div>
  </div>
</template>

<script>

export default {
  created() {
    //poner en la clave cuál pais es de todos
    const index = this.optionsClaves.findIndex(
      o => o.value === this.currentTelefono.clave
    );
    if (index != -1) {
      this.claveCompleta = this.optionsClaves[index].text;
    }
  },

  props: {
    currentTelefono: {
      type: Object,
      default: () => {
        return {
          tipo: null,
          clave: null,
          numero: null,
          extension: null,
        }
      }
    },
    disableSaveButton: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      claveCompleta: null,
      optionsClaves: [
        { text: "México (52)", value: "52" },
        { text: "EUA (1)", value: "1" },
        { text: "Argentina (54)", value: "54" },
        { text: "España (34)", value: "34" },
        { text: "Afganistán (93)", value: "93" },
        { text: "Albania (355)", value: "355" },
        { text: "Alemania (49)", value: "49" },
        { text: "Andorra (376)", value: "376" },
        { text: "Angola (244)", value: "244" },
        // { text: "Antigua y Barbuda (1)", value: "1" },
        { text: "Arabia Saudita (966)", value: "966" },
        { text: "Argelia (213)", value: "213" },
        { text: "Armenia (374)", value: "374" },
        { text: "Australia (61)", value: "61" },
        { text: "Austria (43)", value: "43" },
        { text: "Azerbaiyán (994)", value: "994" },
        // { text: "Bahamas (1)", value: "1" },
        { text: "Bangladés (880)", value: "880" },
        // { text: "Barbados (1)", value: "1" },
        { text: "Baréin (973)", value: "973" },
        { text: "Bélgica (32)", value: "32" },
        { text: "Belice (501)", value: "501" },
        { text: "Benin (229)", value: "229" },
        { text: "Bielorrusia (375)", value: "375" },
        { text: "Bolivia (591)", value: "591" },
        { text: "Bosnia y Herzegovina (387)", value: "387" },
        { text: "Botsuana (267)", value: "267" },
        { text: "Brasil (55)", value: "55" },
        { text: "Brunéi (673)", value: "673" },
        { text: "Bulgaria (359)", value: "359" },
        { text: "Burkina Faso (226)", value: "226" },
        { text: "Burundi (257)", value: "257" },
        { text: "Bután (975)", value: "975" },
        { text: "Cabo Verde (238)", value: "238" },
        { text: "Camboya (855)", value: "855" },
        { text: "Camerún (237)", value: "237" },
        // { text: "Canadá (1)", value: "1" },
        { text: "Catar (974)", value: "974" },
        { text: "Chad (235)", value: "235" },
        { text: "Chile (56)", value: "56" },
        { text: "China (86)", value: "86" },
        { text: "Chipre (357)", value: "357" },
        { text: "Colombia (57)", value: "57" },
        { text: "Comoras (269)", value: "269" },
        { text: "Corea del Norte (850)", value: "850" },
        { text: "Corea del Sur (82)", value: "82" },
        { text: "Costa de Marfil (225)", value: "225" },
        { text: "Costa Rica (506)", value: "506" },
        { text: "Croacia (385)", value: "385" },
        { text: "Cuba (53)", value: "53" },
        { text: "Dinamarca (45)", value: "45" },
        // { text: "Dominica (1)", value: "1" },
        { text: "Ecuador (593)", value: "593" },
        { text: "Egipto (20)", value: "20" },
        { text: "El Salvador (503)", value: "503" },
        { text: "Emiratos Árabes Unidos (971)", value: "971" },
        { text: "Eritrea (291)", value: "291" },
        { text: "Eslovaquia (421)", value: "421" },
        { text: "Eslovenia (386)", value: "386" },
        { text: "Estonia (372)", value: "372" },
        { text: "Etiopía (251)", value: "251" },
        { text: "Fiji (679)", value: "679" },
        { text: "Filipinas (63)", value: "63" },
        { text: "Finlandia (358)", value: "358" },
        { text: "Francia (33)", value: "33" },
        { text: "Gabón (241)", value: "241" },
        { text: "Gambia (220)", value: "220" },
        { text: "Georgia (995)", value: "995" },
        { text: "Ghana (233)", value: "233" },
        // { text: "Granada (1)", value: "1" },
        { text: "Grecia (30)", value: "30" },
        { text: "Guatemala (502)", value: "502" },
        { text: "Guinea (224)", value: "224" },
        { text: "Guinea Ecuatorial (240)", value: "240" },
        { text: "Guinea-Bisáu (245)", value: "245" },
        { text: "Guyana (592)", value: "592" },
        { text: "Haití (509)", value: "509" },
        { text: "Honduras (504)", value: "504" },
        { text: "Hungría (36)", value: "36" },
        { text: "India (91)", value: "91" },
        { text: "Indonesia (62)", value: "62" },
        { text: "Irak (964)", value: "964" },
        { text: "Irán (98)", value: "98" },
        { text: "Irlanda (353)", value: "353" },
        { text: "Islandia (354)", value: "354" },
        { text: "Islas Marshall (692)", value: "692" },
        { text: "Islas Salomón (677)", value: "677" },
        { text: "Israel (972)", value: "972" },
        { text: "Italia (39)", value: "39" },
        // { text: "Jamaica (1)", value: "1" },
        { text: "Japón (81)", value: "81" },
        { text: "Jordania (962)", value: "962" },
        // { text: "Kazajistán (7)", value: "7" },
        { text: "Kenia (254)", value: "254" },
        { text: "Kirguistán (996)", value: "996" },
        { text: "Kiribati (686)", value: "686" },
        { text: "Kuwait (965)", value: "965" },
        { text: "Laos (856)", value: "856" },
        { text: "Lesoto (266)", value: "266" },
        { text: "Letonia (371)", value: "371" },
        { text: "Líbano (961)", value: "961" },
        { text: "Liberia (231)", value: "231" },
        { text: "Libia (218)", value: "218" },
        { text: "Liechtenstein (423)", value: "423" },
        { text: "Lituania (370)", value: "370" },
        { text: "Luxemburgo (352)", value: "352" },
        { text: "Macedonia (389)", value: "389" },
        { text: "Madagascar (261)", value: "261" },
        { text: "Malasia (60)", value: "60" },
        { text: "Malaui (265)", value: "265" },
        { text: "Maldivas (960)", value: "960" },
        { text: "Malí (223)", value: "223" },
        { text: "Malta (356)", value: "356" },
        { text: "Marruecos (212)", value: "212" },
        { text: "Mauricio (230)", value: "230" },
        { text: "Mauritania (222)", value: "222" },
        { text: "Micronesia (691)", value: "691" },
        { text: "Moldavia (373)", value: "373" },
        { text: "Mónaco (377)", value: "377" },
        { text: "Mongolia (976)", value: "976" },
        { text: "Montenegro (382)", value: "382" },
        { text: "Mozambique (258)", value: "258" },
        { text: "Myanmar (95)", value: "95" },
        { text: "Namibia (264)", value: "264" },
        { text: "Nauru (674)", value: "674" },
        { text: "Nepal (977)", value: "977" },
        { text: "Nicaragua (505)", value: "505" },
        { text: "Niger (227)", value: "227" },
        { text: "Nigeria (234)", value: "234" },
        { text: "Noruega (47)", value: "47" },
        { text: "Nueva Zelanda (64)", value: "64" },
        { text: "Omán (968)", value: "968" },
        { text: "Países Bajos (31)", value: "31" },
        { text: "Pakistán (92)", value: "92" },
        { text: "Palaos (680)", value: "680" },
        { text: "Panamá (507)", value: "507" },
        { text: "Papúa Nueva Guinea (675)", value: "675" },
        { text: "Paraguay (595)", value: "595" },
        { text: "Perú (51)", value: "51" },
        { text: "Polonia (48)", value: "48" },
        { text: "Portugal (351)", value: "351" },
        { text: "Reino Unido (44)", value: "44" },
        { text: "República Centroafricana (236)", value: "236" },
        { text: "República Checa (420)", value: "420" },
        { text: "República del Congo (242)", value: "242" },
        { text: "República Democratica del Congo (243)", value: "243" },
        // { text: "República Dominicana (1)", value: "1" },
        { text: "Ruanda (250)", value: "250" },
        { text: "Rumania (40)", value: "40" },
        { text: "Rusia (7)", value: "7" },
        { text: "Samoa (685)", value: "685" },
        // { text: "San Cristóbal y Nieves (1)", value: "1" },
        // { text: "San Marino (378)", value: "378" },
        // { text: "San Vicente y las Granadinas (1)", value: "1" },
        // { text: "Santa Lucía (1)", value: "1" },
        { text: "Santo Tomé y Príncipe (239)", value: "239" },
        { text: "Senegal (221)", value: "221" },
        { text: "Serbia (381)", value: "381" },
        { text: "Seychelles (248)", value: "248" },
        { text: "Sierra Leona (232)", value: "232" },
        { text: "Singapur (65)", value: "65" },
        { text: "Siria (963)", value: "963" },
        { text: "Somalia (252)", value: "252" },
        { text: "Sri Lanka (94)", value: "94" },
        { text: "Suazilandia (268)", value: "268" },
        { text: "Sudáfrica (27)", value: "27" },
        { text: "Sudán (249)", value: "249" },
        { text: "Sudán del Sur (211)", value: "211" },
        { text: "Suecia (46)", value: "46" },
        { text: "Suiza (41)", value: "41" },
        { text: "Surinam (597)", value: "597" },
        { text: "Tailandia (66)", value: "66" },
        { text: "Tanzania (255)", value: "255" },
        { text: "Tayikistán (992)", value: "992" },
        { text: "Timor Oriental (670)", value: "670" },
        { text: "Togo (228)", value: "228" },
        { text: "Tonga (676)", value: "676" },
        // { text: "Trinidad y Tobago (1)", value: "1" },
        { text: "Túnez (216)", value: "216" },
        { text: "Turkmenistán (993)", value: "993" },
        { text: "Turquía (90)", value: "90" },
        { text: "Tuvalu (688)", value: "688" },
        { text: "Ucrania (380)", value: "380" },
        { text: "Uganda (256)", value: "256" },
        { text: "Uruguay (598)", value: "598" },
        { text: "Uzbekistán (998)", value: "998" },
        { text: "Vanuatu (678)", value: "678" },
        { text: "Venezuela (58)", value: "58" },
        { text: "Vietnam (84)", value: "84" },
        { text: "Yemen (967)", value: "967" },
        { text: "Yibuti (253)", value: "253" },
        { text: "Zambia (260)", value: "260" },
        { text: "Zimbabue (263)", value: "263" }
      ],
      form: {
        tipo: null,
        clave: null,
        numero: null,
        extension: null
      }
    };
  },

  methods: {
    setClave(event) {
      // console.log(event.target.value);
      // console.log(this.optionsClaves[event.target.options.selectedIndex].value);
      this.currentTelefono.clave = this.optionsClaves[
        event.target.options.selectedIndex
      ].value;
      //poner el value de la option
    }
  }
};
</script>

<style scoped>
.cardErrors {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* color: #9a9a9a; */
  color: #ea640b;
  font-size: 13px;
}
</style>
