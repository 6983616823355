// API
import BaseApi from './base';

class RolesApi extends BaseApi {
  constructor() {
    super('roles');
  }

  rolPerId(id, params = {}) {
    return this.request.get(`/rol/${id}`, {
      params: {
        ...params
      }
    });
  }

  rolId(rol, params = {}) {
    return this.request.get(`/rol/id/${rol}`, {
      params: {
        ...params
      },
    });
  }
}

export default new RolesApi();
