<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <base-input
          type="text"
          label="Facebook"
          placeholder="Facebook"
          v-model="user.facebook"
          :disabled="user.disabled"
        >
        </base-input>
      </div>
      <div class="col-md-6">
        <base-input
          type="text"
          label="Twitter"
          placeholder="Twitter"
          v-model="user.twitter"
          :disabled="user.disabled"
        >
        </base-input>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <base-input
          type="text"
          label="Instagram"
          placeholder="Instagram"
          v-model="user.instagram"
          :disabled="user.disabled"
        >
        </base-input>
      </div>
      <div class="col-md-6">
        <base-input
          type="text"
          label="LinkedIn"
          placeholder="LinkedIn"
          v-model="user.linkedin"
          :disabled="user.disabled"
        >
        </base-input>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "RedesSociales",

  props: {
    user: { type: Object }
  },

  data() {
    return {
      form: {
        facebook: null,
        twitter: null,
        instagram: null,
        linkedin: null
      }
    };
  }
};
</script>
<style></style>
