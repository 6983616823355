<template>
  <div>
    <img
      id="img"
      :src="
        currentDocumento.documento + stgAccessKey
      "
      alt=""
    />
  </div>
</template>

<script>
export default {
  name: "ModalPreviewImg",

  props: {
    currentDocumento: { type: Object },
  },

  data() {
    return {
      stgAccessKey: "?sv=2023-01-03&st=2024-02-09T17%3A04%3A42Z&se=2034-02-11T17%3A04%3A00Z&sr=c&sp=rl&sig=rq9SDEcZMPv%2FbgiTq9FRE66lHZvEAeeiMqytpyiH2sk%3D",
    }
  },
};
</script>

<style scoped>
#img {
  width: 80%;
  height: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>
