<template>
  <div class="container">
    <h4 class="cardD">
      Expediente de la constitución
      <!-- Entrega de documentación digital e información complementaria -->
    </h4>

    <div class="container-fluid">
      <b-modal no-close-on-backdrop centered ref="documento-modal-preview-img" title="" hide-footer id="modaldi" size="xl">
        <ModalPreviewImg :currentDocumento="currentDocumento" />
      </b-modal>
      <b-modal no-close-on-backdrop centered ref="documento-modal-preview-pdf" title="" hide-footer id="modaldf" size="xl">
        <ModalPreviewPDF :currentDocumento="currentDocumento" />
      </b-modal>
      <b-modal no-close-on-backdrop centered ref="documento-modal-preview-docx" title="" hide-footer id="modaldd" size="xl">
        <ModalPreviewDocx :currentDocumento="currentDocumento" />
      </b-modal>
      <b-modal no-close-on-backdrop centered ref="documento-modal-preview-video" title="" hide-footer id="modalvideo" size="xl">
        <ModalPreviewVideo :currentDocumento="currentDocumento" />
      </b-modal>
      <!-- <h4 class="cardD">Accionistas</h4> -->

      <div class="row"></div>

      <b-table hover :fields="fields" :items="constitutiva.documentos">
        <template #cell(index)="{ index }">
          {{ getIndex(index + 1) }}
        </template>
        <template #cell(tipo)="{ item }">
          <div v-if="item.tipo === 'Otro'">
            <div v-b-tooltip.hover :title="item.descripcion">
              {{ item.tipo }}
            </div>
          </div>
          <div v-else-if="item.descripcion === 'Factura PDF'">
            {{ item.descripcion }}
          </div>
          <div v-else-if="item.descripcion === 'Factura XML'">
            {{ item.descripcion }}
          </div>
          <div v-else>
            {{ item.tipo }}
          </div>
        </template>
        <template #cell(acciones)="{ item }">
          <b-button variant="primary" size="sm" v-b-tooltip.hover title="Vista previa" @click="previewDocumento(item)"
            class="editBtn" :hidden="item.disabled">
            <b-icon-eye />
          </b-button>
          <b-button variant="success" size="sm" title="Descarga documento" @click="descargaFile(item.documento)"
            :disabled="constitutiva.disabledExpediente">
            <i class="fa fa-download"></i>
          </b-button>
        </template>
      </b-table>
    </div>

    <!-- <div class="mt-4">
        <div class="row">
          <div class="col-10">
            <b-button
              type="submit"
              class="btn-fill orange-btn bottom2"
              size="m"
              :disabled="constitutiva.disabledDocumentos"
              >Guardar</b-button
            >
          </div>
          <div class="col-2">
            <b-button
              variant="success"
              class="btn-fill bottom2"
              size="m"
              :disabled="constitutiva.disabledDocumentos"
              @click="validar()"
              >Validar</b-button
            >
          </div>
        </div>
      </div> -->
  </div>
</template>

<script>
// import ModalDocumentos from "@/views/clients/procesos/constitucion/entregadocumentos/ModalDocumentos.vue";
import ModalPreviewImg from "@/views/clients/procesos/constitucion/entregadocumentos/ModalPreviewImg.vue";
import ModalPreviewPDF from "@/views/clients/procesos/constitucion/entregadocumentos/ModalPreviewPDF.vue";
import ModalPreviewDocx from "@/views/clients/procesos/constitucion/entregadocumentos/ModalPreviewDocx.vue";
import ModalPreviewVideo from "@/views/clients/procesos/constitucion/entregadocumentos/ModalPreviewVideo.vue";
// import { FuncionesApi } from "@/api";

export default {
  name: "TabExpediente",

  props: {
    constitutiva: { type: Object }
  },

  components: {
    // ModalDocumentos,
    ModalPreviewImg,
    ModalPreviewPDF,
    ModalPreviewDocx,
    ModalPreviewVideo
  },

  created() {
    this.setDisabled();

  },

  computed: {
    fields() {
      let fields = [
        { key: "index", label: "", class: "text-center" },
        { key: "nombreFile", label: "Nombre", class: "text-center" },
        { key: "tipo", label: "Tipo", class: "text-center" },
        // { key: "nombre", label: "Persona Física", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" }
      ];
      return fields;
    }
  },

  data() {
    return {
      currentDocumento: null,
      currentDocumentoId: null,

      form: {
        documentos: []
      },

      stgAccessKey: "?sv=2023-01-03&st=2024-02-09T17%3A04%3A42Z&se=2034-02-11T17%3A04%3A00Z&sr=c&sp=rl&sig=rq9SDEcZMPv%2FbgiTq9FRE66lHZvEAeeiMqytpyiH2sk%3D",

    };
  },

  methods: {
    getIndex(index) {
      // return index + (this.currentPage - 1) * this.perPage;
      return index + (1 - 1) * this.constitutiva.documentos.length;
    },

    setDisabled() {
      for (let i = 0; i < this.constitutiva.documentos.length; i++) {
        let pretipoDocumento = this.constitutiva.documentos[i].nombreFile.split(
          "."
        );
        let tipoDocumento = pretipoDocumento[pretipoDocumento.length - 1];
        if (
          // tipoDocumento == "docx" ||
          tipoDocumento == "pdf" ||
          tipoDocumento == "jpg" ||
          tipoDocumento == "JPG" ||
          tipoDocumento == "jpeg" ||
          tipoDocumento == "png" ||
          tipoDocumento == "gif" ||
          tipoDocumento == "bmp" ||
          tipoDocumento == "webp" ||
          tipoDocumento == "svg" ||
          tipoDocumento == "tiff" ||
          tipoDocumento == "avif" ||
          tipoDocumento == "mov" ||
          tipoDocumento == "mp4"
        ) {
          this.constitutiva.documentos[i].disabled = false;
        } else {
          this.constitutiva.documentos[i].disabled = true;
        }
      }
    },

    previewDocumento(item) {
      this.currentDocumento = item;

      let pretipoDocumento = item.nombreFile.split(".");
      let tipoDocumento = pretipoDocumento[pretipoDocumento.length - 1];

      if (tipoDocumento === "pdf") {
        this.$refs["documento-modal-preview-pdf"].show();
      }
      if (
        tipoDocumento === "jpg" ||
        tipoDocumento === "JPG" ||
        tipoDocumento === "jpeg" ||
        tipoDocumento === "png" ||
        tipoDocumento === "gif" ||
        tipoDocumento === "bmp" ||
        tipoDocumento === "webp" ||
        tipoDocumento === "svg" ||
        tipoDocumento === "tiff" ||
        tipoDocumento === "avif"
      ) {
        this.$refs["documento-modal-preview-img"].show();
      }

      if (tipoDocumento === "docx") {
        this.$refs["documento-modal-preview-docx"].show();
      }

      if (tipoDocumento === "mov" || tipoDocumento === "mp4") {
        this.$refs["documento-modal-preview-video"].show();
      }
    },

    descargaFile(item) {
      const link = document.createElement("a");
      link.href = item + this.stgAccessKey;
      document.body.appendChild(link);
      link.click();
    },
  }
};
</script>

<style scoped>
.bottom2 {
  float: right;
}

.blue {
  background-color: #effbff;
  border-color: #effbff !important;
  text-align: justify;
}

.led-green {
  margin: 20px auto;
  width: 12px;
  height: 12px;
  background-color: #43eb34;
  /* #690 */
  border-radius: 50%;
  box-shadow: #257a1d 0 -1px 7px 1px, inset #460 0 -1px 9px, #7d0 0 2px 12px;
}

.led-red {
  margin: 20px auto;
  width: 12px;
  height: 12px;
  background-color: #e60505;
  border-radius: 50%;
  box-shadow: #a60903 0 -1px 7px 1px, inset #600 0 -1px 9px, #f00 0 2px 12px;
}

.led-yellow {
  margin: 20px auto;
  width: 12px;
  height: 12px;
  background-color: #f7e00a;
  border-radius: 50%;
  box-shadow: #d1ca04 0 -1px 7px 1px, inset #c4b206 0 -1px 9px, #dd0 0 2px 12px;
}
</style>
