<template>
  <div class="container">
    <!-- Modales -->
    <b-modal no-close-on-backdrop centered ref="documento-modal-preview-img" title="" hide-footer id="modaldi"
      size="xl">
      <ModalPreviewImg :currentDocumento="currentDocumento" />
    </b-modal>
    <b-modal no-close-on-backdrop centered ref="documento-modal-preview-pdf" title="" hide-footer id="modaldf"
      size="xl">
      <ModalPreviewPDF :currentDocumento="currentDocumento" />
    </b-modal>
    <!-- Template -->
    <h4 class="cardD">Firma de Documentos</h4>

    <div>
      <b-table ref="table" hover :fields="fieldsDocumentos" :items="notificacion.fad">
        <template #cell(tipo)="{ item }">
          <div v-if="item.tipo === 'Otro'">
            <div v-b-tooltip.hover :title="item.descripcion">
              {{ item.tipo }}
            </div>
          </div>
          <div v-else>
            {{ item.tipo }}
          </div>
        </template>
        <template #cell(acciones)="{ item }">
          <b-button variant="warning" size="sm" v-b-tooltip.hover title="Firmar" @click="signDocumento(item)"
            class="editBtn">
            <b-icon-pencil />
          </b-button>
          <b-button variant="primary" size="sm" v-b-tooltip.hover title="Vista previa" @click="previewDocumento(item)"
            class="editBtn">
            <b-icon-eye />
          </b-button>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
// Modales
import ModalPreviewImg from "@/views/clients/procesos/constitucion/entregadocumentos/ModalPreviewImg.vue";
import ModalPreviewPDF from "@/views/clients/procesos/constitucion/entregadocumentos/ModalPreviewPDF.vue";

// API
import { CatDocumentosApi } from "@/api";
import { DocumentosXServiciosApi } from "@/api";
import { DocumentosApi } from "@/api";
import { FuncionesApi } from "@/api";

export default {
  name: "FirmaDocumentos",

  components: {
    ModalPreviewImg,
    ModalPreviewPDF
  },

  props: {
    notificacion: { type: Object },
  },

  computed: {
    fieldsDocumentos() {
      let fieldsDocumentos = [
        { key: "tipo", label: "Tipo", class: "text-center" },
        { key: "nombre", label: "Nombre", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" }
      ];
      return fieldsDocumentos;
    },
  },

  created() {
    this.notificacion.fad = [];
    this.getCatDocumentosNotificaciones();
    this.getDocumentosXServiciosWithNotificacion();
  },

  data() {
    return {
      optionsDocumentos: [],
      documentosXServicios: [],
      currentDocumento: {}
    }
  },

  methods: {
    showErrorToast(msg) {
      this.$toast.error(msg, {
        timeout: 4000, //false,
        hideProgressBar: false,
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        closeButton: "button",
        icon: true,
      });
    },

    async getCatDocumentosNotificaciones() {
      this.loading = true;
      try {
        const { value } = await CatDocumentosApi.list({
          page: 0,
          registersPerPage: 0,
          categoria: "notificaciones"
        });
        this.populateOptionsDocumentos(value);
      } catch (error) {
        this.showErrorToast('No se pudo obtener el Catálogo de Documentos. Intente más tarde.');
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    populateOptionsDocumentos(documentos) {
      documentos.forEach(documento => {
        const obj = { text: documento.documento, value: documento.id };
        this.optionsDocumentos.push(obj);
      });
    },

    async getDocumentosXServiciosWithNotificacion() {
      this.loading = true;
      try {
        const { value } = await DocumentosXServiciosApi.list({
          page: 0,
          registersPerPage: 0,
          cat_servicios_id: 2,
          id_proceso: this.notificacion.id
        });
        this.documentosXServicios = value;
        this.getDocumentosFromDocumentosXServicios();
      } catch (error) {
        this.showErrorToast('No se pudieron obtener los Documentos de la Notificación. Intente más tarde.');
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    getDocumentosFromDocumentosXServicios() {
      this.documentosXServicios.forEach(documentoXServicio => {
        this.getDocumentoDetail(documentoXServicio.documentos_id);
      })
    },

    async getDocumentoDetail(documento_id) {
      this.loading = true;
      try {
        const documento = await DocumentosApi.detail(documento_id);
        this.completeFields(documento);
      } catch (error) {
        this.showErrorToast('Error al obtener archivo. Intente más tarde');
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    completeFields(documento) {
      const documentoWithTipo = this.addTipo(documento);
      this.addDocumentoToNotificacionFad(documentoWithTipo);
    },

    addTipo(documento) {
      const tipo = this.getCatDocumentoNombre(documento);
      documento.tipo = tipo;
      return documento;
    },

    getCatDocumentoNombre(documento) {
      const catDcoumentosIndex = this.getCatDocumentosIndex(documento);
      const nombre_catDocumento = this.optionsDocumentos[catDcoumentosIndex].text;
      return nombre_catDocumento;
    },

    getCatDocumentosIndex(documento) {
      let catDocumentosIndex = this.optionsDocumentos.findIndex(
        (optionDocumento) => optionDocumento.value === parseInt(documento.cat_documentos_id)
      );
      if (catDocumentosIndex == -1) {
        console.log(documento)
      }
      return catDocumentosIndex;
    },

    addDocumentoToNotificacionFad(documento) {
      if (this.documentoSeFirma(documento)) {
        this.notificacion.fad.push(documento);
        this.sortNotificacionDocumentosById();
      }
    },

    documentoSeFirma(documento) {
      const idDocsvalidos = [39, 43]
      if (idDocsvalidos.includes(parseInt(documento.cat_documentos_id))) { return true }
      else { console.log(documento.cat_documentos_id); return false };
    },

    sortNotificacionDocumentosById() {
      this.notificacion.fad.sort((a, b) => a.id - b.id)
    },

    previewDocumento(item) {
      this.currentDocumento = item;
      const isCurrentDocumentImage = this.isCurrentDocumentAnImage()
      if (isCurrentDocumentImage) {
        this.$refs["documento-modal-preview-img"].show();
      } else {
        this.$refs["documento-modal-preview-pdf"].show();
      }
    },

    isCurrentDocumentAnImage() {
      let documentoSeparatedByDots = this.currentDocumento.nombre.split('.')
      let extensionDocumento = documentoSeparatedByDots[documentoSeparatedByDots.length - 1]
      const imageExtensions = ["jpg", "JPG", "jpeg", "png", "gif", "bmp", "webp", "svg", "tiff", "avif"]
      if (imageExtensions.includes(extensionDocumento)) {
        return true;
      }
      return false;
    },

    signDocumento() {

    },
  }
}
</script>

<style scoped>
.bottom2 {
  float: right;
}
</style>
