<template>
  <div>
    <div id="pdf">
      <vue-pdf-embed :source="currentDocumento.documento + stgAccessKey" />
    </div>
  </div>
</template>

<script>
import config from "@/config";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";

export default {
  name: "ModalPreviewPDF",

  props: {
    currentDocumento: { type: Object },
  },

  data() {
    return {
      stgAccessKey: config.STG_ACCESS_KEY
    }
  },

  components: {
    VuePdfEmbed,
  },
};
</script>

<style scoped>
#pdf {
  width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>
