<template>
  <transition name="fade" mode="out-in">
    <router-view></router-view>
    <Tasks />
  </transition>
</template>

<script>
import Tasks from "@/views/staff/tasks/Tasks.vue";

export default {
  name: "DashboardContent",
  components: {
    Tasks,
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */
  {
  opacity: 0;
}
</style>
