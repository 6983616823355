// API
import BaseApi from './base';

class LoginApi extends BaseApi {

  login(data, params = {}) {
    return this.request.post('/usuarios/login', data, {
      params: {
        ...params
      }
    });
  }
}

export default new LoginApi();
