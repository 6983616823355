import { render, staticRenderFns } from "./DatosSociedad.vue?vue&type=template&id=5fb35e44&scoped=true"
import script from "./DatosSociedad.vue?vue&type=script&lang=js"
export * from "./DatosSociedad.vue?vue&type=script&lang=js"
import style0 from "./DatosSociedad.vue?vue&type=style&index=0&id=5fb35e44&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fb35e44",
  null
  
)

export default component.exports