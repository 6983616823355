// API
import BaseApi from './base';

class CatDocumentosApi extends BaseApi {
  constructor() {
    super('catDocumentos');
  }
}

export default new CatDocumentosApi();
