<template>
  <div class="container">
    <h4 class="cardD">Cita para firma</h4>

    <div class="mt-4 fecha">
      <!-- <div class="col-3">
        <label class="">Link cita</label>
      </div>
      <div class="col-9">
        {{ formalizacion.link_calendario }}
      </div> -->

      <b-form-group label="Link cita Calendly" label-for="input-link-cita">
        <b-input-group class="mt-3">
          <b-input-group-append>
            <b-button :variant="isTextCopied ? 'success' : 'secondary'" squared v-b-tooltip.hover title="Copiar"
              @click="copyLinkToClipboard">
              <b-icon v-if="isTextCopied" icon="check"></b-icon>
              <b-icon v-else icon="clipboard"></b-icon>
            </b-button>
          </b-input-group-append>
          <b-form-input id="input-link-cita" type="link" v-model="constitutiva.link_calendario"
            placeholder="Link Calendly" :disabled="true"></b-form-input>
        </b-input-group>
      </b-form-group>
    </div>

    <div class="row mt-4 fecha">
      <div class="col-3">
        <label class="">Fecha seleccionada</label>
      </div>
      <div class="col-9">
        {{ fecha_formatted }}
      </div>
    </div>

    <div v-if="!formalizacion.firma_fuera_oficina" class="mt-3">
      <ValidationProvider name="direccion_firma" rules="required" v-slot="{ errors }" :custom-messages="{
        required: 'La dirección es obligatoria'
      }">
        <base-input :disabled="formalizacion.disabledCita" type="text" label="Dirección"
          placeholder="Dirección donde será la firma" v-model="formalizacion.direccion_firma">
        </base-input>
        <span class="cardErrors">{{ errors[0] }}</span>
      </ValidationProvider>

      <div class="mt-4">
        <div class="">
          <b-button type="submit" class="btn-fill orange-btn bottom2" size="m"
            :disabled="formalizacion.disabledCita">Guardar</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CitaFirma",

  props: {
    formalizacion: { type: Object }
  },

  created() {
    let date = new Date(this.formalizacion.fecha_firma);
    let hour = this.formalizacion.fecha_firma.substring(11, 16);
    this.fecha_formatted = date.toLocaleDateString();
    this.fecha_formatted += " ";
    this.fecha_formatted += hour;
  },

  data: () => ({
    fecha_formatted: null
  }),

  methods: {
    validar() {
      this.$emit("validaForm", "CitaFirma");
    }
  }
};
</script>

<style scoped>
.blue {
  background-color: #effbff;
  border-color: #effbff !important;
  text-align: justify;
}

.bottom2 {
  float: right;
}

.datePicker {
  text-transform: lowercase !important;
  /* color: #79cff7; */
}

.datePicker:first-letter {
  text-transform: capitalize !important;
}
</style>
