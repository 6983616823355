<template>
  <div class="content">
    <b-breadcrumb>
      <b-breadcrumb-item :to="{ path: '/dashboard/clientes' }">
        Clientes
      </b-breadcrumb-item>
      <b-breadcrumb-item active>Editar cliente</b-breadcrumb-item>
    </b-breadcrumb>

    <div class="container">
      <div v-if="loading">
        <div id="loader">
          <font-awesome-icon icon="spinner" spin />
        </div>
      </div>
    </div>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog" hide-footer id="modal-confirm-dialog">
      <h4 class="text-center">
        ¿Seguro que quiere eliminar al usuario secundario?
      </h4>
      <h5 class="text-center">Se eliminarán todos los datos.</h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="deleteUsuarioSecundario()">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop ref="my-modal" centered title="" hide-footer id="modal" size="lg">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(postUsuario)">
          <Modal :currentuser="currentuser" @submit.prevent />
        </form>
      </ValidationObserver>
    </b-modal>

    <div class="wrapper wrapper-full-page section content">
      <div class="container">
        <!-- <h4 slot="header" class="card-title">Iniciar sesión</h4> -->
        <!-- <card class="card"> -->
        <div v-if="user.rol === 'Cliente Usuario'" class="mb-4 client">
          Usuario del cliente administrador: {{ user.admin_cliente_nombre }}
        </div>
        <!-- <h4 slot="header" class="card-title">Datos</h4> -->
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(updateClient)">
            <Form :user="user" :second_users="second_users" @submit.prevent @id="predeleteUsuarioSecundario"
              @dialog="showFormDialog" @usuariosecundario="editUsusarioSecundario" />
          </form>
        </ValidationObserver>
        <!-- <div class="mb-4" v-if="user.persona === 'Moral'">
          <b-button
            variant="success"
            class="btn-fill"
            size="sm"
            @click="showFormDialog"
            >+</b-button
          >
        </div> -->
        <!-- </card> -->
      </div>
    </div>

    <!-- <Footer /> -->
  </div>
</template>

<script>
import Card from "src/components/Cards/Card.vue";
import Modal from "@/views/clients/cuenta/FormModalClientes.vue";
import Form from "@/views/clients/cuenta/Form.vue";
import { ClientesApi } from "@/api";
import { RolesApi } from "@/api";

export default {
  name: "EditCliente",

  components: {
    Card,
    Modal,
    Form,
  },

  data: () => ({
    id: 0,
    loading: false,
    user: {
      firstName: null,
      lastNameP: null,
      lastNameM: null,
      email: null,
      password: null,
      rol: null,
      roles_id: null,
      // telefono: null,
      persona: null,
      rfc: null,
      admin_id: null,
      admin_cliente_nombre: null,
      nombre_comercial: null,
    },
    currentuser: {},
    // empresa: {
    //   nombre: null,
    //   rfc: null,
    // },
    second_users: [],
    rolesOptions: ["Cliente Administrador", "Cliente Usuario"],
    // role: "",
    currentUsuarioSecundarioId: null,
  }),

  created() {
    this.getClient();
  },

  methods: {
    async getClient() {
      this.loading = true;
      const id = this.$route.params.id;
      this.id = id;
      try {
        const {
          nombre,
          paterno,
          materno,
          email,
          contraseña,
          // telefono,
          persona,
          rfc,
          roles_id,
          admin_id,
          nombre_comercial,
          // empresas,
          usuarios_secundarios,
        } = await ClientesApi.detail(id);
        // .then((response) => {
        // this.currentProveedor = response;

        this.user.firstName = nombre;
        this.user.lastNameP = paterno;
        this.user.lastNameM = materno;
        this.user.email = email;
        this.user.password = contraseña;
        // this.user.telefono = telefono;
        this.user.persona = persona;
        this.user.rfc = rfc;
        this.user.admin_id = admin_id;
        this.user.nombre_comercial = nombre_comercial;

        const { rol } = await RolesApi.rolPerId(roles_id);
        this.user.rol = rol;

        if (roles_id === 4) {
          this.user.admin_cliente_nombre = await this.getAdminName(admin_id);
        }

        if (persona === "Moral") {
          // //empresas
          // this.empresa.nombre = empresas.nombre;
          // this.empresa.rfc = empresas.rfc;

          //get second users
          for (let i = 0; i < usuarios_secundarios.length; i++) {
            const { rol } = await RolesApi.rolPerId(
              usuarios_secundarios[i].roles_id
            );
            usuarios_secundarios[i].rol = rol;
          }
          this.second_users = usuarios_secundarios;
        }

        this.loading = false;

        // })
      } catch {
        this.loading = false;
      }
    },

    async getAdminName(admin_id) {
      const { nombre, paterno, materno, email } = await ClientesApi.detail(
        admin_id
      );
      let datos_admin = `${nombre} ${paterno} ${materno} ${email}`;
      return datos_admin;
    },

    showFormDialog(dialog) {
      this.$refs["my-modal"].show();
      this.currentuser = {};
      this.currentuser.rol = "Cliente Usuario";
    },

    async addUser() {
      this.$refs["my-modal"].show();
    },

    async getRoleId(roleName) {
      const { id } = await RolesApi.rolId(roleName);
      return id;
    },

    async postUsuario() {
      // let user_secu = {
      //   nombre: this.currentuser.firstName,
      //   paterno: this.currentuser.lastNameP,
      //   materno: this.currentuser.lastNameM,
      //   email: this.currentuser.email,
      //   rol: this.currentuser.rol,

      // };
      // this.second_users.push(user_secu);

      if (this.currentuser.id === undefined) {
        this.currentuser.id = this.second_users.length + 1;
        var a = Object.assign({}, this.currentuser);
        this.second_users.push(a);
      } else if (this.currentuser.id != undefined) {
        const index = this.second_users.findIndex(
          (o) => o.id === this.currentuser.id
        );
        var a = Object.assign({}, this.currentuser);
        this.second_users.splice([index], 1, a);
      }
      this.$refs["my-modal"].hide();
    },

    async updateClient() {
      this.loading = true;
      const { id } = await RolesApi.rolId(this.user.rol);

      let usuarios_secundarios = [];
      for (let i = 0; i < this.second_users.length; i++) {
        let role_id = await this.getRoleId(this.second_users[i].rol);

        let user_secu = {
          id: this.second_users[i].id,
          nombre: this.second_users[i].nombre,
          paterno: this.second_users[i].paterno,
          materno: this.second_users[i].materno,
          email: this.second_users[i].email,
          roles_id: role_id,
          // telefono: this.second_users[i].telefono,
          persona: "Física",
          nombre_comercial: this.user.nombre_comercial,
          activo: 1,
        };
        usuarios_secundarios.push(user_secu);
      }

      var data = {
        nombre: this.user.firstName,
        paterno: this.user.lastNameP,
        materno: this.user.lastNameM,
        email: this.user.email,
        // contraseña: this.user.password,
        roles_id: id,
        // telefono: this.user.telefono,
        persona: this.user.persona,
        rfc: this.user.rfc,
        admin_id: this.user.admin_id,
        nombre_comercial: this.user.nombre_comercial,
        activo: 1,
        // empresas: {
        //   nombre: this.empresa.nombre,
        //   rfc: this.empresa.rfc,
        // },
        usuarios_secundarios: usuarios_secundarios,
      };
      try {
        await ClientesApi.edit(this.id, data);
        this.$toast.success("Cliente actualizado", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true,
        });
        this.loading = false;
      } catch (error) {
        this.$toast.error("Sucedió un error, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true,
        });
        this.loading = false;
      }
      // var data = {
      //   email: this.user.email,
      //   contraseña: this.user.password,
      // };
      // LoginApi.login(data)
      //   .then((response) => {
      //     storage.saveItem("user", this.user.email);
      //     storage.saveItem("role", response.rol);
      //     storage.saveItem("logged", true);
      //     // this.role = response.rol;
      //     // storage.saveItem("role", "client");
      //     this.$router.push("/dashboard");
      //   })
      //   .catch(() => {
      //     storage.saveItem("logged", false);
      //     this.loading = false;
      //     this.$toast.error("Datos incorrectos", {
      //       timeout: false,
      //       hideProgressBar: true,
      //       position: "top-right",
      //       closeOnClick: true,
      //       draggable: false,
      //       closeButton: "button",
      //       icon: true,
      //     });
      //   });
      this.loading = false;
    },

    editUsusarioSecundario(usuariosecundario) {
      this.$refs["my-modal"].show();
      this.currentuser = usuariosecundario;
    },

    predeleteUsuarioSecundario(id) {
      this.currentUsuarioSecundarioId = id;
      this.$refs["modal-confirm-dialog"].show();
      // console.log(id + "predelete usuario secundario");
      //se elimina de lista this.second_users
      // this.numSelectedModelos--;
      // this.$confirm({
      //   title: "¿Seguro que quiere eliminar al cliente secundario?",
      //   message: "Se eliminarán todos los datos.",
      //   button: {
      //     yes: "OK",
      //     no: "Cancelar",
      //   },
      //   callback: (confirm) => {
      //     if (confirm) {
      //       this.deleteUsuarioSecundario(id);
      //       // self.$vueConfirm.close();
      //     }
      //   },
      // });
    },

    async deleteUsuarioSecundario() {
      //se borra también en back
      let id = this.currentUsuarioSecundarioId;

      await ClientesApi.deleteUser(id);
      const index = this.second_users.findIndex((o) => o.id == id);
      this.second_users.splice(index, 1);
      this.$refs["modal-confirm-dialog"].hide();
    },
  },
};
</script>

<style scoped>
/* .container-bread{
  ma
} */
.client,
.modal {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* color: #9a9a9a; */
  color: #ea640b;
  font-size: 13px;
}
</style>
