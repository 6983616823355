// API
import BaseApi from './base';

class DomiciliosApi extends BaseApi {
  constructor() {
    super('domicilios');
  }
}

export default new DomiciliosApi();
