<template>
  <div class="content">
    <b-breadcrumb>
      <b-breadcrumb-item :to="{ path: '/dashboard/corredurias' }">
        Corredurías
      </b-breadcrumb-item>
      <b-breadcrumb-item active>Editar correduría</b-breadcrumb-item>
    </b-breadcrumb>

    <div class="container">
      <div v-if="loading">
        <div id="loader">
          <font-awesome-icon icon="spinner" spin />
        </div>
      </div>
    </div>

    <div class="wrapper wrapper-full-page section content">
      <div class="container">
        <card id="card">
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(saveCorreduria)" @change="getAddressInMap(null)">
              <Form :correduria="correduria" @submit.prevent />
            </form>
          </ValidationObserver>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import Form from "@/views/staff/corredurias/Form.vue";
import Card from "src/components/Cards/Card.vue";
import { CorreduriasApi } from "@/api";
import { StaffApi } from "@/api";
import { RolesApi } from "@/api";
import { getGoogleMapUrl } from "@/utils/google-maps-utils";

export default {
  name: "EditCorreduria",

  components: {
    Form,
    Card
  },

  data: () => ({
    id: 0,
    loading: false,
    roles: [],

    correduria: {
      nombre: null,
      domicilios_id: null,
      domicilio: {
        id: null,
        calle: null,
        num_exterior: null,
        num_interior: null,
        colonia: null,
        delegacion_municipio: null,
        ciudad: null,
        entidad: null,
        pais: null,
        codigo_postal: null,
        lat: 19.4320605, //default
        lng: -99.1335938 //default
      },
      staff: [],
      email: ""
    }
  }),

  created() {
    this.getCorreduria();
  },

  methods: {
    async getCorreduria() {
      this.loading = true;
      const id = this.$route.params.id;
      this.id = id;

      try {
        const {
          nombre,
          domicilio,
          domicilios_id,
          email
        } = await CorreduriasApi.detail(id);
        this.correduria.nombre = nombre;
        this.correduria.id = id;
        this.correduria.domicilios_id = domicilios_id;
        this.correduria.domicilio.id = domicilio.id;
        this.correduria.domicilio.calle = domicilio.calle;
        this.correduria.domicilio.num_exterior = domicilio.num_exterior;
        this.correduria.domicilio.num_interior = domicilio.num_interior;
        this.correduria.domicilio.colonia = domicilio.colonia;
        this.correduria.domicilio.delegacion_municipio = domicilio.delegacion_municipio;
        this.correduria.domicilio.ciudad = domicilio.ciudad;
        this.correduria.domicilio.entidad = domicilio.entidad;
        this.correduria.domicilio.pais = domicilio.pais;
        this.correduria.domicilio.codigo_postal = domicilio.codigo_postal;
        this.correduria.domicilio.lat = domicilio.lat;
        this.correduria.domicilio.lng = domicilio.lng;
        this.correduria.email = email;

        await this.getStaff(id);

        this.loading = false;
      } catch {
        this.loading = false;
      }
    },

    async getStaff(corredurias_id) {
      this.loading = true;
      try {
        const {
          odata_page: page,
          odata_total: total,
          odata_perpage: perPage,
          value
        } = await StaffApi.list({
          page: 0, // this.currentPage,
          // data: this.searchStaff,
          registersPerPage: 0, //this.perPage,
          // rol: this.rolSearch,
          corredurias_id: corredurias_id
        });

        await this.getRoles();

        for (let i = 0; i < value.length; i++) {
          var role_id = value[i].roles_id;
          var obj = this.roles.find(o => o.id === role_id);
          value[i].rol = obj.rol;
        }
        this.correduria.staff = value;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async getRoles() {
      try {
        const { value } = await RolesApi.list({
          page: 0,
          registersPerPage: 0
        });
        this.roles = value;
      } catch (error) {
        // this.$swal({
      }
    },

    async getAddressInMap() {
      const address = `${this.correduria.domicilio.calle},
                      ${this.correduria.domicilio.num_exterior},
                      ${this.correduria.domicilio.colonia},
                      ${this.correduria.domicilio.delegacion_municipio},
                      ${this.correduria.domicilio.codigo_postal},
                      ${this.correduria.domicilio.ciudad},
                      ${this.correduria.domicilio.entidad}
                      `;

      const mapUrl = await getGoogleMapUrl(address);
      this.correduria.domicilio.lat = mapUrl.lat;
      this.correduria.domicilio.lng = mapUrl.lng;
    },

    async saveCorreduria() {
      this.loading = true;
      const data = {
        nombre: this.correduria.nombre,
        domicilios_id: this.correduria.domicilios_id,
        domicilio: this.correduria.domicilio,
        staff: this.correduria.staff,
        activo: 1,
        email: this.correduria.email
      };
      try {
        await CorreduriasApi.edit(this.id, data);
        this.$toast.success("Correduría actualizada", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
        this.loading = false;
      } catch (error) {
        this.$toast.error("Sucedió un error, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
#card {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #ea640b;
  font-size: 13px;
}
</style>
