<template>
  <div class="content">
    <b-breadcrumb>
      <b-breadcrumb-item :to="{ path: '/dashboard' }">Inicio</b-breadcrumb-item>
      <b-breadcrumb-item active>Editar Ratificación</b-breadcrumb-item>
    </b-breadcrumb>

    <!-- Aux -->
    <div class="container">
      <div v-if="loading">
        <div id="loader">
          <font-awesome-icon icon="spinner" spin />
        </div>
      </div>
    </div>

    <!-- Modales -->
    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog-route" hide-footer id="modal-confirm-dialog-route">
      <h4 class="text-center">
        ¿Seguro que quiere salir de la página?
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog-route')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="beforeLeave(true)">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog" hide-footer id="modal-confirm-dialog">
      <h4 class="text-center">
        ¿Seguro que quiere salir sin guardar los cambios?
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-validate" hide-footer id="modal-confirm-validate">
      <h4 class="text-center">
        ¿Está seguro de validar? Los datos no podrán ser modificados posteriormente.
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-validate')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="postMethod()">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-mail" hide-footer id="modal-confirm-mail">
      <h4 class="text-center">
        ¿Está seguro de validar la Diligencia? El cliente será notificado vía email.
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-mail')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="postMethod()">OK</b-button>
    </b-modal>


    <!-- Template -->
    <div class="container-fluid">
      <b-row>
        <b-col class="col-3">
          <card>
            <TreeEditRatificaciones :ratificacion="ratificacion" :user="'Staff'" @node="selected" />
          </card>
        </b-col>
        <b-col class="col-9">
          <card>
            <b-row>
              <b-col class="col-12">
                <div v-if="currentComponentBody === 'ValidaComparecientes'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form ref="validacomparecientes" @submit.prevent="handleSubmit(changeCurrentComponentBody(''))">
                      <ComplementaInformacion :ratificacion="ratificacion" @submit.prevent
                        @allChecksPassed="setModalValidate('ValidaComparecientes')" @node="selected" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'ValidaDocumentos'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form ref="validadocumentos" @submit.prevent="handleSubmit(setModalValidate('ValidaDocumentos'))">
                      <EntregaDocumentos :ratificacion="ratificacion" @submit.prevent @node="selected" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'Domicilio'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form ref="domicilio" @submit.prevent="handleSubmit()">
                      <Domicilio :ratificacion="ratificacion" @submit.prevent
                        @allChecksPassed="setModalValidate('Domicilio')" @node="selected" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'Correduria'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form ref="correduria" @submit.prevent="handleSubmit()">
                      <Correduria :ratificacion="ratificacion" @submit.prevent
                        @allChecksPassed="setModalValidate('Correduria')" @node="selected" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'EstatusDocumentos'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form ref="estatusdocumentos" @submit.prevent="handleSubmit(setModalValidate('EstatusDocumentos'))">
                      <EstatusDocumentos :ratificacion="ratificacion" @submit.prevent @node="selected" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'PagoComplementario'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form ref="pagocomplementario" @submit.prevent="handleSubmit()">
                      <PagoComplementario :ratificacion="ratificacion" @submit.prevent
                        @allChecksPassed="setModalValidate('PagoComplementario')"
                        @pagadoComplementario="setModalValidate('PagadoComplementario')" @node="selected" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'FAD'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form ref="fad" @submit.prevent="handleSubmit()">
                      <FAD :ratificacion="ratificacion" @submit.prevent @allChecksPassed="setModalValidate('FAD')"
                        @checkFadStatus="setModalValidate('checkFadStatus')" @node="selected" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'Cita'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form ref="cita" @submit.prevent="handleSubmit()">
                      <Cita :ratificacion="ratificacion" @submit.prevent @allChecksPassed="setModalValidate('Cita')"
                        @node="selected" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'RUG'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form ref="rug" @submit.prevent="handleSubmit(setModalValidate('RUG'))">
                      <RUG :ratificacion="ratificacion" @submit.prevent @node="selected" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'Preguntas'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form ref="preguntas" @submit.prevent="handleSubmit()">
                      <Preguntas :ratificacion="ratificacion" @submit.prevent
                        @allChecksPassed="setModalValidate('Preguntas')" @node="selected" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'Actas'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form ref="actas" @submit.prevent="handleSubmit(setModalValidate('Actas'))">
                      <Actas :ratificacion="ratificacion" @submit.prevent @node="selected" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'FADActa'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form ref="fadacta" @submit.prevent="handleSubmit(setModalValidate('FADActa'))">
                      <FADActa :ratificacion="ratificacion" @submit.prevent @node="selected" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'Expediente'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form ref="expediente" @submit.prevent="handleSubmit(setModalValidate('Expediente'))">
                      <Expediente :ratificacion="ratificacion" @submit.prevent @node="selected" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'Terminado'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form ref="terminado" @submit.prevent="handleSubmit(setModalValidate('Terminado'))">
                      <Terminado :ratificacion="ratificacion" @submit.prevent @node="selected" />
                    </form>
                  </ValidationObserver>
                </div>
              </b-col>
            </b-row>
          </card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import Card from "src/components/Cards/Card.vue";
// Usuario
import Storage from "@/utils/storage";
const storage = new Storage();
// Ratificaciones
import TreeEditRatificaciones from "@/views/clients/procesos/ratificaciones/TreeEditRatificaciones.vue";
import ComplementaInformacion from "@/views/clients/procesos/ratificaciones/ComplementaInformacion.vue";
import EntregaDocumentos from "@/views/clients/procesos/ratificaciones/EntregaDocumentos.vue";
import Correduria from "@/views/clients/procesos/ratificaciones/Correduria.vue";
import EstatusDocumentos from "@/views/clients/procesos/ratificaciones/EstatusDocumentos.vue";
import PagoComplementario from "@/views/clients/procesos/ratificaciones/PagoComplementario.vue";
import Domicilio from "@/views/clients/procesos/ratificaciones/Domicilio.vue"
import FAD from "@/views/clients/procesos/ratificaciones/FAD.vue"
import FADActa from "@/views/clients/procesos/ratificaciones/FADActa.vue"
import Cita from "@/views/clients/procesos/ratificaciones/Cita.vue";
import RUG from "@/views/clients/procesos/ratificaciones/RUG.vue"
import Preguntas from "@/views/clients/procesos/ratificaciones/Preguntas.vue"
import Actas from "@/views/clients/procesos/ratificaciones/Actas.vue"
import Expediente from "@/views/clients/procesos/ratificaciones/Expediente.vue"
import Terminado from "@/views/clients/procesos/ratificaciones/Terminado.vue"
// API
import { RatificacionesApi } from "@/api";
import { RatificantesApi } from "@/api";
import { ComparecientesApi } from "@/api";
import { CatDocumentosApi } from "@/api";
import { DocumentosXServiciosApi } from "@/api";
import { DocumentosApi } from "@/api";
import { DomiciliosApi } from "@/api";
import { CotizacionesApi } from "@/api";
import { FuncionesApi } from "@/api";
import { getGoogleMapUrl } from "@/utils/google-maps-utils";


export default ({
  name: "ValidRatificaciones",

  components: {
    TreeEditRatificaciones,
    Card,
    ComplementaInformacion,
    EntregaDocumentos,
    Domicilio,
    Correduria,
    EstatusDocumentos,
    PagoComplementario,
    FAD,
    FADActa,
    RUG,
    Preguntas,
    Actas,
    Expediente,
    Terminado,
    Cita
  },

  created() {
    this.loading = true;
    this.ratificacion.ratificaciones_id = this.$route.params.id;
    this.getRatificacion()
      .then(() => this.getRatificantes())
      .then(() => this.fillRatificantes())
      .then(() => this.fillNombresCompletos())
      .then(() => this.getCatDocumentosRatificaciones())
      .then(() => this.getDocumentosXServiciosRatificaciones())
      .then(() => this.getDocumentosRatificaciones())
      .then(() => this.addTipoToDocumentosRatificacion())
      .then(() => this.getDomicilio())
      .then(() => this.getCotizacionComplementaria())
      .then(() => this.getEstatus())
      .catch((error) => {
        this.showErrorToast('Sucedió un error, intente más tarde');
        console.log(error);
      })
      .finally(() => { this.loading = false; })
  },

  beforeRouteLeave(to, from, next) {
    if (!to.name === 'Editratificaciones') {
      this.$refs["modal-confirm-dialog-route"].show();
      this.next = next;
    }
    else {
      this.next = next;
      this.beforeLeave(true);
    }
  },

  data: () => ({
    next: null,
    prenode: null,
    modalValidateMethod: null,

    loading: false,
    currentComponentBody: "Terminado",

    ratificacion: {
      staff: true,
      estatusDocumentos: null,

      ratificaciones_id: null,
      estatus_id: null,
      clientes_id: null,
      cotizaciones_id: null,
      pago_complementario: null,
      cotizaciones_id_complementario: null,
      proceso_digital: null,
      monto_global: null,
      clausula_adeudo: null,
      adeudo: null,
      inscripcion_rug: null,
      monto_garantia: null,
      firma_fuera_oficina: null,
      entrega_fuera_oficina: null,
      fad_instrumento: null,
      fecha_firma: null,
      domicilio_firma: null,
      liga_zoom_firma: null,
      entregables_adicionales: null,
      anexos_certificados: null,
      domicilios_id: null,
      validaComparecientes: null,
      validaDocumentos: null,
      validaDomicilio: null,
      firmantes_no_ratificaron: null,
      todos_firmaron: null,
      validaDiligencia: null,

      ratificantes: [],
      documentos: [],
      precio_total: null,
      liga_pago_complementario: null,

      currentComponentValues: {
        ValidaComparecientes: false,
        ValidaDocumentos: false,
        Domicilio: false,
        Correduria: false,
        EstatusDocumentos: false,
        PagoComplementario: false,
        FAD: false,
        Cita: false,
        RUG: false,
        Preguntas: false,
        Actas: false,
        Expediente: false,
        FADActa: false,
        Terminado: false,
      },
    },
  }),

  methods: {
    async beforeLeave(answer) {
      if (answer) {
        this.next();
      }
    },

    async selected(node) {
      const keys = Object.keys(this.ratificacion.currentComponentValues);
      const values = Object.values(this.ratificacion.currentComponentValues);
      const dest = keys.find(value => value == node);
      this.currentComponentBody = dest;
    },


    setModalValidate(postMethod) {
      this.modalValidateMethod = postMethod;
      if (['PagoComplementario', 'PagadoComplementario', 'FAD', 'checkFadStatus', 'Expediente', 'Cita'].includes(postMethod)) {
        this.postMethod();
      } else if (postMethod == 'Realizacion') {
        this.openSendMailModal();
      } else {
        this.openValidateModal();
      }
    },

    async getAddressInMap(direcciones_id) {
      if (direcciones_id === null) {
        const address = `${this.ratificacion.domicilio.calle},
                      ${this.ratificacion.domicilio.num_exterior},
                      ${this.ratificacion.domicilio.colonia},
                      ${this.ratificacion.domicilio.delegacion_municipio},
                      ${this.ratificacion.domicilio.codigo_postal},
                      ${this.ratificacion.domicilio.ciudad},
                      ${this.ratificacion.domicilio.entidad}
                      `;
        const mapUrl = await getGoogleMapUrl(address);

        if (!mapUrl) {
          return;
        }

        this.ratificacion.domicilio.lat = mapUrl.lat.toString(); //parseFloat(mapUrl.lat);
        this.ratificacion.domicilio.lng = mapUrl.lng.toString(); //parseFloat(mapUrl.lng);
        var a = Object.assign({}, this.ratificacion.domicilio);
        this.ratificacion.domicilio = a;
      }
    },

    showErrorToast(msg) {
      this.$toast.error(msg, {
        timeout: 4000, //false,
        hideProgressBar: false,
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        closeButton: "button",
        icon: true,
      });
    },


    changeCurrentComponentValue(origin, destiny) {
      this.ratificacion.currentComponentValues[origin] = true;
      this.currentComponentBody = destiny;
    },

    changeCurrentComponentBody(body) {
      this.ratificacion.currentComponentValues[this.currentComponentBody] = true;
      this.currentComponentBody = body;
    },

    async getRatificacion() {
      this.ratificacion = {
        ...this.ratificacion,
        ...await RatificacionesApi.detail(this.ratificacion.ratificaciones_id)
      };
      return;
    },

    async getRatificantes() {
      const { value } = await RatificantesApi.list({
        page: 0,
        registersPerPage: 0,
        id_ratificaciones: this.ratificacion.ratificaciones_id
      });
      this.ratificacion.ratificantes = value;
    },

    async fillRatificantes() {
      const promises = this.ratificacion.ratificantes.map(async ratificante => {
        const compareciente = await this.getCompareciente(ratificante.comparecientes_id);
        return {
          ...ratificante,
          ...compareciente
        };
      })
      this.ratificacion.ratificantes = await Promise.all(promises);
      return;
    },

    async getCompareciente(comparecientes_id) {
      const compareciente = await ComparecientesApi.detail(comparecientes_id);
      return compareciente;
    },

    fillNombresCompletos() {
      return new Promise((resolve, reject) => {
        try {
          this.ratificacion.ratificantes.forEach(ratificante => {
            let nombreCompletoTemp = ""
            if (ratificante.persona === 'Física') {
              nombreCompletoTemp = ratificante.nombre + ' ' + ratificante.paterno;
              if (ratificante.materno) {
                nombreCompletoTemp = nombreCompletoTemp + ' ' + ratificante.materno
              }
            } else {
              nombreCompletoTemp = ratificante.denominacion_social;
            }
            ratificante.nombreCompleto = nombreCompletoTemp;
          })
          resolve();
        } catch (error) {
          reject(error);
        }
      })
    },

    async getCatDocumentosRatificaciones() {
      const { value } = await CatDocumentosApi.list({
        page: 0,
        registersPerPage: 0,
        categoria: "ratificaciones"
      })
      this.catDocumentos = value;
      return;
    },


    async getDocumentosXServiciosRatificaciones() {
      const { value } = await DocumentosXServiciosApi.list({
        page: 0,
        registersPerPage: 0,
        cat_servicios_id: 6,
        id_proceso: this.ratificacion.ratificaciones_id
      })
      this.documentosXServicios = value;
      return;
    },


    async getDocumentosRatificaciones() {
      const promises = this.documentosXServicios.map(async documentoXServicio => {
        const documento = await this.getDocumentoDetail(documentoXServicio.documentos_id);
        return documento;
      })
      this.ratificacion.documentos = await Promise.all(promises);
      return;
    },


    async getDocumentoDetail(id) {
      return await DocumentosApi.detail(id);
    },


    async addTipoToDocumentosRatificacion() {
      const promises = this.ratificacion.documentos.map(async documento => {
        const tipo = this.getCatDocumentoNombreById(documento.cat_documentos_id);
        documento.tipo = tipo;
        return documento;
      })
      this.ratificacion.documentos = await Promise.all(promises);
      return;
    },


    getCatDocumentoNombreById(cat_documentos_id) {
      let catDocumento = this.catDocumentos.find(doc => doc.id = cat_documentos_id);
      return catDocumento ? catDocumento.documento : null;
    },


    async getDomicilio() {
      this.ratificacion.domicilio = await DomiciliosApi.detail(this.ratificacion.domicilios_id);
      return;
    },


    async getCotizacionComplementaria() {
      if (this.ratificacion.cotizaciones_id_complementario == null) {
        return;
      }
      const { liga_pago } = await CotizacionesApi.detail(this.ratificacion.cotizaciones_id_complementario);
      this.ratificacion.liga_pago_complementario = liga_pago;
      return;
    },


    getEstatus() {
      this.changeCurrentComponentBody('ValidaComparecientes');
      if (this.ratificacion.validaComparecientes == 1) {
        this.changeCurrentComponentValue('ValidaComparecientes', 'ValidaDocumentos');
      }
      if (this.ratificacion.validaDocumentos == 1) {
        if (!this.ratificacion.entrega_fuera_oficina) {
          this.changeCurrentComponentValue('ValidaDocumentos', 'EstatusDocumentos');
        } else {
          this.changeCurrentComponentValue('ValidaDocumentos', 'Domicilio');
          if (this.ratificacion.validaDomicilio == 1) {
            this.changeCurrentComponentValue('Domicilio', 'EstatusDocumentos');
          }
        }
      }
      if (this.ratificacion.pago_complementario) {
        this.ratificacion.estatusDocumentos = 'pago';
      }
      if (this.ratificacion.estatus_id >= 38) {
        this.changeCurrentComponentValue('EstatusDocumentos', 'PagoComplementario');
      }
      if (this.ratificacion.estatus_id >= 39) {
        this.ratificacion.estatusDocumentos = 'ok';
        this.changeCurrentComponentValue('PagoComplementario', 'FAD');
      }
      if (this.ratificacion.estatus_id >= 40 && this.ratificacion.estatus_id <= 56) {
        this.changeCurrentComponentValue('FAD', 'Cita');
      }
      if (this.ratificacion.estatus_id >= 41 && this.ratificacion.estatus_id <= 56) {
        this.changeCurrentComponentValue('Cita', 'RUG');
      }
      if (this.ratificacion.estatus_id == 70 || [43, 56].includes(this.ratificacion.estatus_id)) {
        this.changeCurrentComponentValue('RUG', 'Preguntas');
      }
      if (this.ratificacion.validaDiligencia == 1) {
        this.changeCurrentComponentValue('Preguntas', 'Actas');
      }
      if ([43, 56].includes(this.ratificacion.estatus_id)) {
        this.changeCurrentComponentValue('Actas', 'Expediente');
      }
      if (this.ratificacion.estatus_id == 56) {
        this.changeCurrentComponentValue('Expediente', 'Terminado');
      }

      return;
    },


    openValidateModal() {
      this.$refs["modal-confirm-validate"].show();
    },


    hideValidateModal() {
      this.$refs["modal-confirm-validate"].hide();
    },


    openSendMailModal() {
      this.$refs["modal-confirm-mail"].show();
    },


    hideSendMailModal() {
      this.$refs["modal-confirm-mail"].hide();
    },


    postMethod() {
      this.hideValidateModal();
      this.hideSendMailModal();

      switch (this.modalValidateMethod) {
        case 'ValidaComparecientes':
          this.ratificacion.validaComparecientes = 1;
          this.actualizaRatificacion()
            .then(() => this.actualizaComparecientes())
            .then(() => this.changeCurrentComponentValue('ValidaComparecientes', 'ValidaDocumentos'));
          // this.changeCurrentComponentValue('ValidaComparecientes', 'ValidaDocumentos');
          break;
        case 'ValidaDocumentos':
          this.ratificacion.validaDocumentos = 1;
          this.actualizaRatificacion()
          if (!this.ratificacion.entrega_fuera_oficina) {
            this.changeCurrentComponentValue('ValidaDocumentos', 'EstatusDocumentos');
          } else {
            this.changeCurrentComponentValue('ValidaDocumentos', 'Domicilio');
          }
          break;
        case 'Domicilio':
          this.ratificacion.validaDomicilio = 1;
          this.actualizaDomicilio();
          this.actualizaRatificacion();
          this.changeCurrentComponentValue('Domicilio', 'Correduria');
          break;
        case 'Correduria':
          this.actualizaRatificacion();
          this.changeCurrentComponentValue('Correduria', 'EstatusDocumentos');
          break;
        case 'EstatusDocumentos':
          if (!this.ratificacion.estatusDocumentos) {
            this.showErrorToast('Por favor ingresa la información solicitada.');
            break;
          }
          let destinyComponent = '';
          if (this.ratificacion.estatusDocumentos == 'ok') {
            this.ratificacion.estatus_id = 39;
            destinyComponent = 'FAD';
          } else if (this.ratificacion.estatusDocumentos == 'pago') {
            this.ratificacion.estatus_id = 38;
            this.ratificacion.pago_complementario = 1;
            destinyComponent = 'PagoComplementario';
          } else if (this.ratificacion.estatusDocumentos == 'no ok') {
            this.showErrorToast('Por favor contacte al cliente.');
            break;
          }
          this.actualizaRatificacion();
          this.changeCurrentComponentValue('EstatusDocumentos', destinyComponent);
          break;
        case 'PagoComplementario':
          this.actualizaRatificacion();
          break;
        case 'PagadoComplementario':
          this.ratificacion.estatus_id = 39;
          this.changeCurrentComponentValue('PagoComplementario', 'FAD');
          this.actualizaRatificacion();
          break;
        case 'FAD':
          this.ratificacion.estatus_id = 68;
          this.actualizaRatificacion();
          break;
        case 'checkFadStatus':
          this.loading = true;
          this.checkFad()
            .then(() => this.getRatificacion())
            .then(() => {
              if (this.isFadSigned()) {
                this.changeCurrentComponentValue('FAD', 'Cita')
              } else {
                this.showErrorToast('El cliente no ha firmado el documento. Intente más tarde.')
              }
            })
            .catch((error) => {
              console.log(error);
              this.showErrorToast('Hubo un error al checar el estatus de FAD. Intente más tarde.')
            })
            .finally(() => { this.loading = false; });
          break;
        case 'Cita':
          this.ratificacion.estatus_id = 71;
          this.actualizaRatificacion();
          break;
        case 'RUG':
          this.ratificacion.estatus_id = 70;
          this.actualizaRatificacion();
          this.changeCurrentComponentValue('RUG', 'Preguntas');
          break;
        case 'Preguntas':
          this.loading = true;
          this.ratificacion.validaDiligencia = 1;
          this.actualizaDocumentosDiligencia()
            .then(() => this.actualizaRatificacion())
            .catch((error) => {
              console.log(error);
              this.showErrorToast('Hubo un error al checar el estatus de FAD. Intente más tarde.')
            })
            .finally(() => { this.loading = false; });
          this.changeCurrentComponentValue('Preguntas', 'Actas')
          break;
        case 'Actas':
          this.ratificacion.estatus_id = 43;
          this.actualizaRatificacion();
          this.changeCurrentComponentValue('Actas', 'FADActa');
          break;
        case 'FADActa':
          this.ratificacion.estatus_id = 43;
          this.actualizaRatificacion();
          this.changeCurrentComponentValue('FADActa', 'Expediente');
          break;
        case 'Expediente':
          this.changeCurrentComponentValue('Expediente', 'Terminado');
          break;
        case 'Terminado':
          this.ratificacion.estatus_id = 56;
          this.actualizaRatificacion();
          break;
      }
    },

    async actualizaComparecientes() {
      for (const ratificante in this.ratificacion.ratificantes) {
        const compareciente = await ComparecientesApi.detail(ratificante.comparecientes_id);

        compareciente.completado = true;
        compareciente.cat_servicio_id = 6;
        compareciente.servicio_id = this.ratificacion.ratificaciones_id;

        await ComparecientesApi.edit(compareciente.id, compareciente);
      }
    },

    async actualizaDomicilio() {
      return await DomiciliosApi.edit(this.ratificacion.domicilios_id, {
        calle: this.ratificacion.domicilio.calle,
        num_exterior: this.ratificacion.domicilio.num_exterior,
        num_interior: this.ratificacion.domicilio.num_interior,
        colonia: this.ratificacion.domicilio.colonia,
        delegacion_municipio: this.ratificacion.domicilio.delegacion_municipio,
        ciudad: this.ratificacion.domicilio.ciudad,
        entidad: this.ratificacion.domicilio.entidad,
        pais: this.ratificacion.domicilio.pais,
        codigo_postal: this.ratificacion.domicilio.codigo_postal,
        extranjero: 0,
        tipo: 'Personal',
        tieneExtranjero: null,
        tieneTipo: null,
        lat: this.ratificacion.domicilio.lat,
        lng: this.ratificacion.domicilio.lng,
        valido: 1,
        comentario: null,
        validador: null,
        activo: 1
      })
    },

    async actualizaRatificacion() {
      try {
        this.loading = true;
        delete this.ratificacion.actualizado;
        await RatificacionesApi.edit(this.ratificacion.ratificaciones_id, this.ratificacion);
      } catch (error) {
        console.log(error);
        this.showErrorToast('No se pudo actualizar la ratificación. Intente más tarde.')
      } finally {
        this.loading = false;
      }
    },

    async actualizaDocumento(documento) {
      await DocumentosApi.edit(documento.id, {
        documento: documento.documento,
        cat_documentos_id: documento.cat_documentos_id,
        auto_referencia: documento.auto_referencia,
        descripcion: documento.descripcion,
        paginas: parseInt(documento.paginas),
        copias_certificadas: parseInt(documento.copias_certificadas),
        nombre: documento.nombre,
        activo: 1,
        fecha: documento.fecha,
        idioma_extranjero: parseInt(documento.idioma_extranjero)
      })
    },

    async checkFad() {
      await FuncionesApi.checkFadKycsEstatus({
        proceso_id: this.ratificacion.ratificaciones_id,
        cat_servicios_id: 6
      })
    },

    isFadSigned() {
      return parseInt(this.ratificacion.estatus_id) === 40;
    },

    async actualizaDocumentosDiligencia() {
      const promises = [];

      if (!('documentos_diligencia' in this.ratificacion)) {
        return;
      }

      this.ratificacion.documentos_diligencia.forEach(doc => {
        promises.push(this.actualizaDocumento(doc))
      });

      await Promise.all(promises);
    },
  }
})
</script>
