<template>
  <div class="container">
    <h4 class="cardD">Denominación social</h4>
    <!-- <card class="blue">
      <i class="fa fa-info"></i>
      Aquí van instrucciones.
      <p></p>
      <i class="fa fa-play"></i>
      Links a videos.
      <p></p>
      <i class="fa fa-question"></i>
      Datos de contacta a un asesor.
    </card> -->

    <div class="container">
      <div v-if="loading">
        <div id="loader">
          <font-awesome-icon icon="spinner" spin />
        </div>
      </div>
    </div>

    <b-modal no-close-on-backdrop centered ref="documento-modal-preview-img" title="" hide-footer id="modaldi"
      size="xl">
      <ModalPreviewImg :currentDocumento="currentDocumento" />
    </b-modal>
    <b-modal no-close-on-backdrop centered ref="documento-modal-preview-pdf" title="" hide-footer id="modaldf" size="xl">
      <ModalPreviewPDF :currentDocumento="currentDocumento" />
    </b-modal>

    <h4 class="cardD">
      Carga del documento de Denominación social
    </h4>

    <div class="row mb-4">
      <div class="col-7">
        <ValidationProvider name="numero_permiso" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La clave Única de Documento es obligatoria'
        }">
          <base-input :disabled="constitutiva.disabledDenominacion" type="text" label="Clave Única de Documento"
            placeholder="A" v-model="constitutiva.numero_permiso">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-5">
        <ValidationProvider name="fecha_autoriza_se" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La fecha de autorización es obligatoria'
        }">
          <base-input :disabled="constitutiva.disabledDenominacion" type="date" label="Fecha de autorización"
            placeholder="Fecha de autorización" v-model="constitutiva.fecha_autoriza_se">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row">
      <div class="col-10">
        <ValidationProvider name="documento" rules="required" v-slot="{ errors }"
          :custom-messages="{ required: 'El documento es obligatorio' }">
          <b-form-input hidden v-model="currentDocumento.nombreFile">
          </b-form-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
        {{ currentDocumento.nombreFile }}
        <b-form-file v-model="currentDocumento.file" browse-text="Seleccionar" drop-placeholder="Cargar aquí"
          placeholder="Da click aquí o arrastra el archivo" @input="postDocumento" />
      </div>
      <div class="col-2">
        <b-button variant="primary" size="sm" v-b-tooltip.hover title="Vista previa" @click="previewDocumento()"
          class="editBtn">
          <!-- :disabled="constitutiva.disabledDocumentos" -->
          <b-icon-eye />
        </b-button>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col-12 mt-4" v-if="currentDocumento.nombreFile != null">

      </div>
    </div> -->

    <div class="mt-5"><label for="">Denominación social autorizada</label></div>

    <div class="row mt-2">
      <div class="col-md-10">
        {{ constitutiva.denominaciones_sociales_1 }}
      </div>
      <div class="col-2">
        <b-form-radio v-model="selected1" name="radios" value="1" @change="selectDenom(1)">
        </b-form-radio>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-10">
        {{ constitutiva.denominaciones_sociales_2 }}
      </div>
      <div class="col-2">
        <b-form-radio v-model="selected2" name="radios" value="2" @change="selectDenom(2)">
        </b-form-radio>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-10">
        {{ constitutiva.denominaciones_sociales_3 }}
      </div>
      <div class="col-2">
        <b-form-radio v-model="selected3" name="radios" value="3" @change="selectDenom(3)">
        </b-form-radio>
      </div>
    </div>

    <div class="row mt-2" v-if="
      constitutiva.autoriza_cd_denominacion === 1 ||
      constitutiva.autoriza_cd_denominacion === true
    ">
      <div class="col-10">
        <base-input :disabled="constitutiva.disabledDenominacion" class="mt-2" type="text" label=""
          placeholder="Denominación social autorizada" v-model="constitutiva.denom_otra" @keyup="updateOtro">
        </base-input>
      </div>
      <div class="col-2">
        <b-form-radio v-model="selected4" name="radios" value="4" @change="selectDenom(4)">
        </b-form-radio>
      </div>
    </div>

    <div class="mt-4">
      <div class="row">
        <div class="col-12">
          <b-button type="submit" class="btn-fill orange-btn bottom2" size="m"
            :disabled="constitutiva.disabledDenominacion">Guardar</b-button>
        </div>
        <!-- <div class="col-2">
          <b-button
            variant="success"
            class="btn-fill bottom2"
            size="m"
            :disabled="constitutiva.disabledDenominacion"
            @click="validar()"
            >Validar</b-button
          >
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import ModalDocumentos from "@/views/clients/procesos/constitucion/entregadocumentos/ModalDocumentos.vue";
import ModalPreviewImg from "@/views/clients/procesos/constitucion/entregadocumentos/ModalPreviewImg.vue";
import ModalPreviewPDF from "@/views/clients/procesos/constitucion/entregadocumentos/ModalPreviewPDF.vue";
import { FuncionesApi } from "@/api";

export default {
  name: "TabDenominacionSocial",

  props: {
    constitutiva: { type: Object }
  },

  created() {
    this.getDenominacionDoc();
  },

  components: {
    ModalDocumentos,
    ModalPreviewImg,
    ModalPreviewPDF
  },

  data() {
    return {
      loading: false,
      selected1: null,
      selected2: null,
      selected3: null,
      selected4: null,

      // denom_otra: null,
      // denominacionFile: null,
      currentDocumento: {
        nombreFile: null,
        documento: null,
        file: null
      }
    };
  },

  methods: {
    getDenominacionDoc() {
      let obj = this.constitutiva.documentos.find(
        x => x.tipo === "Denominación social"
      );
      if (obj) {
        this.currentDocumento = obj;
      }

      if (this.constitutiva.denominaciones_sociales.length > 3) {
        this.constitutiva.denom_otra = this.constitutiva.denominaciones_sociales[3].denominacion_social;
        this.constitutiva.denominaciones_sociales[3].tipo = "Otro";
      }

      let index = 0;
      this.constitutiva.denominaciones_sociales.forEach(element => {
        if (element.autorizada === 1) {
          index = this.constitutiva.denominaciones_sociales.indexOf(element);
        }
      });
      if (index === 0) {
        this.selected1 = "1";
        this.constitutiva.denominaciones_sociales[0].autorizada = 1;
      } else if (index === 1) {
        this.selected2 = "2";
      } else if (index === 2) {
        this.selected3 = "3";
      } else if (index === 3) {
        this.selected4 = "4";
      }
      //  else {
      //   this.constitutiva.denominaciones_sociales[0].autorizada = 1;
      // }
    },

    selectDenom(value) {
      this.constitutiva.denominaciones_sociales.forEach(element => {
        element.autorizada = 0;
      });
      let index = this.constitutiva.denominaciones_sociales.findIndex(
        x => x.tipo === "Otro"
      );
      if (index > -1) {
        this.constitutiva.denominaciones_sociales.splice(index, 1);
      }

      if (value === 1) {
        this.constitutiva.denominaciones_sociales[0].autorizada = 1;
        this.constitutiva.denom_otra = null;
      } else if (value === 2) {
        this.constitutiva.denominaciones_sociales[1].autorizada = 1;
        this.constitutiva.denom_otra = null;
      } else if (value === 3) {
        this.constitutiva.denominaciones_sociales[2].autorizada = 1;
        this.constitutiva.denom_otra = null;
      } else if (value === 4) {
        let obj = {};
        obj.autorizada = 1;
        obj.denominacion_social = this.constitutiva.denom_otra;
        obj.tipo = "Otro";
        this.constitutiva.denominaciones_sociales.push(obj);
      }
    },

    updateOtro() {
      let index = this.constitutiva.denominaciones_sociales.findIndex(
        x => x.tipo === "Otro"
      );
      this.constitutiva.denominaciones_sociales[
        index
      ].denominacion_social = this.constitutiva.denom_otra;
    },

    async postDocumento() {
      this.loading = true;
      await this.addFile();

      this.currentDocumento.tipo = "Denominación social";
      this.currentDocumento.cat_documentos_id = 12;
      var a = Object.assign({}, this.currentDocumento);
      this.constitutiva.documentos.push(a);

      this.loading = false;
    },

    async addFile() {
      this.loading = true;
      // console.log(this.currentDocumento.file);
      try {
        const formData = new FormData();
        this.currentDocumento.nombreFile = this.currentDocumento.file.name;
        formData.append("file", this.currentDocumento.file);
        const value = await FuncionesApi.file(
          this.constitutiva.id,
          "documentosConstituciones",
          formData,
          {}
        );
        this.currentDocumento.documento = value;
      } catch (error) {
        //
      }
      this.loading = false;
    },

    previewDocumento() {
      let pretipoDocumento = this.currentDocumento.nombreFile.split(".");
      let tipoDocumento = pretipoDocumento[pretipoDocumento.length - 1];

      if (tipoDocumento === "pdf") {
        this.$refs["documento-modal-preview-pdf"].show();
      }
      if (
        tipoDocumento === "jpg" ||
        tipoDocumento === "JPG" ||
        tipoDocumento === "jpeg" ||
        tipoDocumento === "png" ||
        // tipoDocumento === "png" ||
        tipoDocumento === "gif" ||
        tipoDocumento === "bmp" ||
        tipoDocumento === "webp" ||
        tipoDocumento === "svg" ||
        tipoDocumento === "tiff" ||
        tipoDocumento === "avif"
      ) {
        this.$refs["documento-modal-preview-img"].show();
      }
    }
  }
};
</script>

<style scoped>
.blue {
  background-color: #effbff;
  border-color: #effbff !important;
  text-align: justify;
}

.bottom2 {
  /* position: fixed;
  right: 2%;
  bottom: 5%;
  overflow: visible;
  z-index: 100; */
  float: right;
}
</style>
