<template>
  <div class="container">
    <div v-if="loading">
      <div id="loader">
        <font-awesome-icon icon="spinner" spin />
      </div>
    </div>

    <h4 class="cardD">
      Datos Generales del Delegado Especial o de quien hace la protocolización
    </h4>

    <b-modal no-close-on-backdrop centered ref="firmantes-modal" title="" hide-footer id="modalC" size="xl">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(putFirmante)">
          <ModalFirmantes :currentFirmante="currentFirmante" @submit.prevent :complete="false" />
        </form>
      </ValidationObserver>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog" hide-footer id="modal-confirm-dialog">
      <h4 class="text-center">
        ¿Seguro que quiere eliminar al firmante?
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="deleteFirmante">OK</b-button>
    </b-modal>

    <div class="mt-4">
      <label for="">Delegado especial o firmantes</label>
      <b-button v-if="
        formalizacion.firmantes.length < 2 ||
        formalizacion.firmantes[0].cargo != 'Delegado especial'
      " :disabled="formalizacion.disabled" variant="success" class="btn-fill spaceL" size="sm"
        @click="addFirmante">+</b-button>
    </div>

    <div class="mt-4">
      <label for="">Seleccionar Compareciente</label>
      <select class="custom-select" v-model="selectedCompareciente" @change="selectCompareciente"
        :disabled="formalizacion.disabled">
        <option v-for="option in options_comparecientes" :key="option.value" v-bind:value="option.value">
          {{ option.text }}
        </option>
      </select>
    </div>

    <div>
      <b-table hover :fields="fieldsCuadro" :items="formalizacion.firmantes">
        <template #cell(acciones)="{ item }">
          <b-button :disabled="formalizacion.disabled" variant="warning" size="sm" v-b-tooltip.hover title="Editar"
            class="editBtn" @click="editFirmante(item)">
            <b-icon-pencil />
          </b-button>
          <b-button :disabled="formalizacion.disabled" variant="danger" size="sm" v-b-tooltip.hover title="Eliminar"
            @click="predeleteFirmante(item.id)">
            <b-icon-trash />
          </b-button>
        </template>
      </b-table>
    </div>

    <div class="mt-4">
      <div class="">
        <b-button type="submit" class="btn-fill orange-btn bottom2" size="m"
          :disabled="formalizacion.disabled">Continuar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import ModalFirmantes from "@/views/clients/procesos/formalizacionactas/infoBase/modales/ModalFirmantes.vue";

import {
  ComparecientesApi,
  ClientesApi
} from "@/api";

import Storage from "@/utils/storage";
const storage = new Storage();


export default {
  name: "DatosDelegado",

  props: {
    formalizacion: { type: Object }
  },

  components: {
    ModalFirmantes
  },

  created() {
    this.getComparecientes();
  },

  computed: {
    fieldsCuadro() {
      let fieldsCuadro = [
        // {
        //   key: "nombreCompleto",
        //   label: "Nombre",
        //   class: "text-center"
        { key: "nombre", label: "Nombre", class: "text-center" },
        { key: "paterno", label: "Paterno", class: "text-center" },
        { key: "materno", label: "Materno", class: "text-center" },
        { key: "persona", label: "Persona", class: "text-center" },
        { key: "cargo", label: "Cargo", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" }
      ];
      return fieldsCuadro;
    }
  },

  data() {
    return {
      loading: false,
      comparecientes: [],
      options_comparecientes: [],
      selectedCompareciente: null,

      currentFirmante: null,
      currentFirmanteId: null
    };
  },

  methods: {
    async getComparecientes() {
      this.loading = true;
      var clienteEmail = storage.getItem("user");
      const { id } = await ClientesApi.clienteId(clienteEmail);

      let clientes_id = 0;
      if (id != 0) {
        clientes_id = id;
      } else {
        nombreTotal = element.denominacion_social;
      }

      try {
        const { value } = await ComparecientesApi.combo({
          clientes_id: clientes_id
        });

        value.forEach(element => {
          // let nombreTotal = "";
          if (element.persona === "Física") {
            let nombreTotal = `${element.nombre} ${element.paterno} ${element.materno}`;
            const obj = {
              text: nombreTotal,
              value: element.id
            };
            this.options_comparecientes.push(obj);

            element.nombreCompleto = nombreTotal;
            element.persona = `Persona ${element.persona}`;
          }

          // element.rfc = element.rfc;
        });
        this.comparecientes = value;

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    selectCompareciente() {
      const indexYa = this.formalizacion.firmantes.findIndex(o => o.id === this.selectedCompareciente);

      if (indexYa != -1) {
        this.$toast.warning("No se puede seleccionar 2 veces al mismo compareciente", {
            timeout: 4000, //false,
            hideProgressBar: false,
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: "button",
            icon: true
          });

        return;
      }

      const index = this.comparecientes.findIndex(o => o.id === this.selectedCompareciente);

      // this.comparecientes[index].capital_fijo = 0;
      // this.comparecientes[index].capital_variable = 0;
      // this.comparecientes[index].porcentaje = 0;
      // this.comparecientes[index].rfc = this.comparecientes[index].rfc;

      var a = Object.assign({}, this.comparecientes[index]);
      this.formalizacion.firmantes.push(a);
    },

    addFirmante() {
      this.$refs["firmantes-modal"].show();
      this.currentFirmante = {};
      // if (this.formalizacion.tipo_administracion === "Administrador único") {
      this.currentFirmante.cargo = "Delegado especial";
      this.currentFirmante.persona = "Física";
      // }
      // if (this.formalizacion.tipo_administracion === "Gerente General") {
      //   this.currentFirmante.cargo = "Gerente General";
      // }
    },

    editFirmante(item) {
      this.$refs["firmantes-modal"].show();
      this.currentFirmante = item;
    },

    putFirmante() {
      if (!this.currentFirmante.id) {
        let id = this.formalizacion.firmantes.length + 1;
        this.currentFirmante.id = id;
        var a = Object.assign({}, this.currentFirmante);
        this.formalizacion.firmantes.push(a);
      } else {
        let index = this.formalizacion.firmantes.findIndex(
          o => o.id === this.currentFirmante.id
        );
        var a = Object.assign({}, this.currentFirmante);
        this.formalizacion.firmantes.splice([index], 1, a);
      }
      this.$refs["firmantes-modal"].hide();
    },

    predeleteFirmante(id) {
      this.currentFirmanteId = id;
      this.$refs["modal-confirm-dialog"].show();
    },

    deleteFirmante() {
      let index2 = this.formalizacion.firmantes.findIndex(
        o => o.id === this.currentFirmanteId
      );
      this.formalizacion.firmantes.splice(index2, 1);
      this.$refs["modal-confirm-dialog"].hide();
    }
  }
};
</script>

<style scoped>
.bottom2 {
  float: right;
}

.leyendaBlock {
  color: #2937cf;
  font-size: 15px;
}

.leyenda {
  text-transform: lowercase !important;
  color: #79cff7;
}

.leyenda:first-letter {
  text-transform: capitalize;
}
</style>
