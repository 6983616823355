import { render, staticRenderFns } from "./FormalizacionActasInfo.vue?vue&type=template&id=54710b25&scoped=true"
import script from "./FormalizacionActasInfo.vue?vue&type=script&lang=js"
export * from "./FormalizacionActasInfo.vue?vue&type=script&lang=js"
import style0 from "./FormalizacionActasInfo.vue?vue&type=style&index=0&id=54710b25&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54710b25",
  null
  
)

export default component.exports