<template>
  <div class="container">
    <!-- <div v-if="loading">
      <div id="loader">
        <font-awesome-icon icon="spinner" spin />
      </div>
    </div> -->

    <br /><br />
    <div class="mt-3">
      <p>Al elegir esta forma de pago le llegará un correo electrónico con los
        datos bancarios para que realice la transferencia.</p>
      <p>Una vez realizada, le enviaremos un mail de confirmación y se verá
        reflejado en su proceso.</p>
      <p>A continuación, podrá continuar con los siguientes pasos.</p>
    </div>

    <div>
      <b-button class="btn-fill orange-btn bottom2" size="m" @click="pagar()">
        Pagar
      </b-button>
    </div>

    <b-overlay :show="loading" no-wrap></b-overlay>

  </div>
</template>

<script>
import { FuncionesApi } from "@/api";

import Storage from "@/utils/storage";
const storage = new Storage();

export default {
  name: "Spei",

  props: {
    cotizacion_id: { type: Number },
    precio_total: { type: Number },
    proceso_id: { type: Number },
    tipo: { type: String }
  },

  data: () => ({
    loading: false
  }),

  methods: {
    async pagar() {
      this.loading = true;
      var clienteEmail = storage.getItem("user");
      try {
        let data = {};
        if (this.tipo == "monedero") {
          data = {
            description: "Cargo a monedero",
            amount: this.precio_total,
            cliente_email: clienteEmail,
            monedero: 1
          };
        } else if (this.tipo == "constitutiva") {
          data = {
            description:
              "Transferencia pago Constitución de Sociedades " +
              this.proceso_id,
            amount: this.precio_total,
            cliente_email: clienteEmail,
            cotizaciones_id: this.cotizacion_id,
            cat_servicios_id: 1,
            procesos_id: this.proceso_id
          };
        } else if (this.tipo == "formalizacion") {
          data = {
            description:
              "Transferencia pago Formalización de Actas " + this.proceso_id,
            amount: this.precio_total,
            cliente_email: clienteEmail,
            cotizaciones_id: this.cotizacion_id,
            cat_servicios_id: 3,
            procesos_id: this.proceso_id
          };
        } else if (this.tipo == "notificacion") {
          data = {
            description: "Transferencia pago Notificacion " + this.proceso_id,
            amount: this.precio_total,
            cliente_email: clienteEmail,
            cotizaciones_id: this.cotizacion_id,
            cat_servicios_id: 2,
            procesos_id: this.proceso_id
          };
        } else if (this.tipo == "cotejo") {
          data = {
            description:
              "Transferencia pago Copias Certificadas " + this.proceso_id,
            amount: this.precio_total,
            cliente_email: clienteEmail,
            cotizaciones_id: this.cotizacion_id,
            cat_servicios_id: 4,
            procesos_id: this.proceso_id
          };
        } else if (this.tipo == "ratificacion") {
          data = {
            description: "Transferencia pago Ratificacion " + this.proceso_id,
            amount: this.precio_total,
            cliente_email: clienteEmail,
            cotizaciones_id: this.cotizacion_id,
            cat_servicios_id: 6,
            procesos_id: this.proceso_id
          };
        }
        await FuncionesApi.pagoConSpei(data);

        this.$emit("payCotizacionSpei", true);

      } catch {
        this.$emit("payCotizacionSpei", false);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
.paddCenter {
  /* padding: 50% 0; */
}
</style>
