<template>
  <div>
    <div class="row">
      <div class="col-md-4">
        <ValidationProvider
          name="firstName"
          rules="required"
          v-slot="{ errors }"
          :custom-messages="{ required: 'El nombre es obligatorio' }"
        >
          <base-input
            type="text"
            label="Nombre(s)"
            placeholder="Nombre(s)"
            v-model="user.nombre"
          >
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-5">
        <ValidationProvider
          name="lastNameP"
          rules="required"
          v-slot="{ errors }"
          :custom-messages="{
            required: 'El apellido paterno es obligatorio'
          }"
        >
          <base-input
            type="text"
            label="Apellido Paterno"
            placeholder="Apellido Paterno"
            v-model="user.paterno"
          >
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-3">
        <ValidationProvider
          name="lastNameM"
          rules="required"
          v-slot="{ errors }"
          :custom-messages="{
            required: 'El apellido materno es obligatorio'
          }"
        >
          <base-input
            type="text"
            label="Apellido Materno"
            placeholder="Apellido Materno"
            v-model="user.materno"
          >
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <ValidationProvider
          name="email"
          rules="required|email"
          v-slot="{ errors }"
          :custom-messages="{
            required: 'El email es obligatorio',
            email: 'Debe ser un email válido'
          }"
        >
          <base-input
            type="email"
            label="Email"
            placeholder="Email"
            v-model="user.email"
          >
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-6">
        <ValidationProvider
          name="rol"
          rules="required"
          v-slot="{ errors }"
          :custom-messages="{
            required: 'El rol es obligatorio'
          }"
        >
          <label for="">Rol</label>
          <select v-model="user.rol" class="custom-select">
            <option value="Staff Administrador">Staff Administrador</option>
            <option value="Staff Usuario">Staff Usuario</option>
          </select>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <b-button class="btn-fill float-right orange-btn" @click="saveStaff"
      >Guardar</b-button
    >
  </div>
</template>
<script>
import { StaffApi } from "@/api";
import { RolesApi } from "@/api";

export default {
  name: "StaffM",

  props: {
    user: { type: Object },
    corredurias_id: { type: String }
  },

  data() {
    return {
      form: {
        id: null,
        nombre: null,
        paterno: null,
        materno: null,
        email: null,
        rol: null
      }
    };
  },

  methods: {
    async saveStaff() {
      if (this.user.id != 0) {
        await this.editStaff();
      } else {
        await this.newStaff();
      }
    },

    async editStaff() {
      this.loading = true;
      const { id } = await RolesApi.rolId(this.user.rol);

      var data = {
        nombre: this.user.nombre,
        paterno: this.user.paterno,
        materno: this.user.materno,
        email: this.user.email,
        roles_id: id,
        activo: 1,
        corredurias_id: this.corredurias_id
      };
      try {
        await StaffApi.edit(this.user.id, data);
        this.$toast.success("Staff actualizado", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
        // this.$refs["staff-modal"].hide();
        this.$emit("closeModal");

        this.loading = false;
      } catch (error) {
        this.$toast.error("Sucedió un error, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
        this.loading = false;
      }
    },

    async newStaff() {
      this.loading = true;
      const { id } = await RolesApi.rolId(this.user.rol);

      var data = {
        nombre: this.user.nombre,
        paterno: this.user.paterno,
        materno: this.user.materno,
        email: this.user.email,
        roles_id: id,
        corredurias_id: this.corredurias_id
      };
      try {
        await StaffApi.create(data);
        this.$toast.success("Staff guardado", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
        this.$toast.warning(
          "Recuerde que se debe activar la cuenta para que aparezca en la tabla",
          {
            timeout: 8000, //false,
            hideProgressBar: false,
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: "button",
            icon: true
          }
        );
        // this.$refs["staff-modal"].hide();
        this.$emit("closeModal");

        this.loading = false;
      } catch (error) {
        console.log(error);
        this.$toast.error("Sucedió un error, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
        this.loading = false;
      }
    }
  }
};
</script>
<style>
.orange-btn {
  background-color: #ea640b !important;
  border-color: #ea640b !important;
  color: white !important;
}
.orange-btn:hover {
  background-color: #e87b31 !important;
  border-color: #e87b31 !important;
  color: white !important;
}
.orange-btn:active {
  background-color: #ea640b !important;
  border-color: #ea640b !important;
  color: white !important;
}
#card {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* color: #9a9a9a; */
  color: #ea640b;
  font-size: 13px;
}
</style>
