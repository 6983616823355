// API
import BaseApi from './base';

class CuponesApi extends BaseApi {
  constructor() {
    super('cupones');
  }

  cuponValido(data) {
    return this.request.post(`/cupones/validar`, data);
  }

  getAplicaciones(id, pagination) {
    return this.request.get(`/cupones/${id}/aplicaciones`, {
      params: {
        ...pagination
      }
    });
  }
}

export default new CuponesApi();
