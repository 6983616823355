<template>
  <div class="container">
    <div class="container">
      <div v-if="loading">
        <div id="loader">
          <font-awesome-icon icon="spinner" spin />
        </div>
      </div>
    </div>

    <h4 class="cardD">Cotización y pago</h4>

    <h4 class="cardD">Resumen de Constitución</h4>
    <div class="row">
      <label class="grey col-md-1">{{ num_accionistas_pf_incluido }}</label>
      <label class="grey col-md-5"> Accionistas Personas Físicas</label>
    </div>
    <div class="row">
      <label class="grey col-md-1">{{ num_administradores_incluido }}</label>
      <label class="grey col-md-5"> Administradores </label>
    </div>
    <div class="row">
      <label class="grey col-md-1">{{ num_comisarios_incluido }}</label>
      <label class="grey col-md-5">Comisarios </label>
    </div>
    <div class="row">
      <label class="grey col-md-1">{{ num_apoderados_incluido }}</label>
      <label class="grey col-md-5"> Apoderados </label>
    </div>
    <div class="row mt-2">
      <label class="grey col-md-6"> Subtotal </label>
      <label class="grey col-md-6">${{ subtotal_resumen }}</label>
    </div>

    <h4 class="cardD mt-1">Adicionales</h4>
    <div class="row">
      <label class="grey col-md-1">{{ num_accionistas_pf_adicionales }}</label>
      <label class="grey col-md-5"> Accionistas Personas Físicas </label>
      <label class="grey col-md-6">${{ precio_accionistas_pf_adicionales }}</label>
    </div>
    <div class="row">
      <label class="grey col-md-1">{{ num_accionistas_pm_total }}</label>
      <label class="grey col-md-5"> Accionistas Personas Morales </label>
      <label class="grey col-md-6">${{ precio_accionistas_pm_adicionales }}</label>
    </div>
    <div class="row">
      <label class="grey col-md-1">{{ num_administradores_adicionales }}</label>
      <label class="grey col-md-5"> Administradores </label>
      <label class="grey col-md-6">${{ precio_administradores_adicionales }}</label>
    </div>
    <!-- <div class="row">
      <label class="grey col-md-1">{{ num_comisarios_adicionales }}</label>
      <label class="grey col-md-5">Comisarios </label>
      <label class="grey col-md-6">${{ precio_comisarios_adicionales }}</label>
    </div> -->
    <div class="row">
      <label class="grey col-md-1">{{ num_apoderados_adicionales }}</label>
      <label class="grey col-md-5"> Apoderados </label>
      <label class="grey col-md-6">${{ precio_apoderados_adicionales }}</label>
    </div>
    <div class="row">
      <label class="grey col-md-1">{{ icon_capital_extranjero }}</label>
      <label class="grey col-md-5"> Capital extranjero </label>
      <label class="grey col-md-6">${{ precio_capital_extranjero }}</label>
    </div>
    <div class="row">
      <label class="grey col-md-1">{{ icon_reglas_control }}</label>
      <label class="grey col-md-5"> Reglas de control corporativo</label>
      <label class="grey col-md-6">${{ precio_reglas_control }}</label>
    </div>
    <div class="row">
      <label class="grey col-md-1">{{ icon_reglas_asambleas }}</label>
      <label class="grey col-md-5"> Reglas para asambleas telemáticas </label>
      <label class="grey col-md-6">${{ precio_reglas_asambleas }}</label>
    </div>
    <div class="row">
      <label class="grey col-md-1">{{ icon_exclusion_accionistas }}</label>
      <label class="grey col-md-5"> Exclusión de accionistas </label>
      <label class="grey col-md-6">${{ precio_exclusion_accionistas }}</label>
    </div>
    <div class="row">
      <label class="grey col-md-1">{{ icon_firma_fuera }}</label>
      <label class="grey col-md-5"> Firma fuera de la oficina </label>
      <label class="grey col-md-6">${{ precio_firma_fuera }}</label>
    </div>
    <div class="row mt-2">
      <label class="grey col-md-6"> Subtotal </label>
      <label class="grey col-md-6">${{ subtotal_adicionales }}</label>
    </div>

    <h4 class="cardD mt-1">Extras</h4>
    <div class="row">
      <div class="col-md-1">
        <div v-if="constitutiva.roleStaff">
          <b-form-checkbox :disabled="constitutiva.disabledCotizacion" v-model="constitutiva.titulos_acciones"
            @change="setTitulos">
          </b-form-checkbox>
        </div>
        <div v-else>
          <b-form-checkbox :disabled="constitutiva.disabled" v-model="constitutiva.titulos_acciones"
            @change="setTitulos">
          </b-form-checkbox>
        </div>
      </div>
      <div class="col-md-5">
        <label class="grey">Títulos de acciones</label>
      </div>
      <div class="col-md-6">
        <label class="grey">${{ constitutiva.subtotal_titulos }}</label>
      </div>
    </div>
    <div class="row">
      <div class="col-md-1">
        <div v-if="constitutiva.roleStaff">
          <b-form-checkbox :disabled="constitutiva.disabledCotizacion" v-model="this.constitutiva.libros_corporativos"
            @change="setLibros">
          </b-form-checkbox>
        </div>
        <div v-else>
          <b-form-checkbox :disabled="constitutiva.disabled" v-model="this.constitutiva.libros_corporativos"
            @change="setLibros">
          </b-form-checkbox>
        </div>
      </div>
      <div class="col-md-5">
        <label class="grey">Libros corporativos</label>
      </div>
      <div class="col-md-6">
        <label class="grey">${{ constitutiva.subtotal_libros }}</label>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-6">
        <label class="grey">Subtotal</label>
      </div>
      <div class="col-md-6">
        <label class="grey">${{ subtotal_extras }}</label>
      </div>
    </div>

    <h4 class="cardD mt-1">Gastos</h4>
    <div class="row">
      <div class="col-md-1">
        <b-form-checkbox disabled v-model="gastos_envio"></b-form-checkbox>
      </div>
      <div class="col-md-5">
        <label class="grey">Gastos de envío</label>
      </div>
      <div class="col-md-6">
        <label class="grey">$0</label>
      </div>
    </div>

    <!-- <div v-for="(item, index) in options_gastos" :key="index">
      <div class="row">
        <div class="col-md-1" v-if="item.text === 'Gastos de envío'">
          <b-form-checkbox disabled v-model="gastos_envio"></b-form-checkbox>
        </div>
        <div class="col-md-1" v-else>
          <div v-if="constitutiva.roleStaff">
            <b-form-checkbox
              :disabled="constitutiva.disabledCotizacion"
              @change="setGasto(item)"
              :value="item.value"
              v-model="constitutiva.gastos"
            ></b-form-checkbox>
          </div>
          <div v-else>
            <b-form-checkbox
              :disabled="constitutiva.disabled"
              @change="setGasto(item)"
              :value="item.value"
              v-model="constitutiva.gastos"
            ></b-form-checkbox>
          </div>
        </div>
        <div class="col-md-5">
          <label class="grey">{{ item.text }}</label>
        </div>
        <div class="col-md-6">
          <label class="grey">${{ item.value.costo }}</label>
        </div>
      </div>
    </div> -->
    <div class="row mt-2">
      <div class="col-md-6">
        <label class="grey">Subtotal</label>
      </div>
      <div class="col-md-6">
        <label class="grey">${{ subtotal_gastos }}</label>
      </div>
    </div>

    <h4 class="cardD mt-1">Cupones</h4>
    <div class="row">
      <div class="col-6 grey">
        <base-input :disabled="constitutiva.roleStaff" type="text" label="Cupón de descuento"
          placeholder="Cupón de descuento" v-model="nombreCuponDescuento"></base-input>
      </div>
      <div class="col-6">
        <br />
        <b-button class="btn-fill orange-btn" variant="info" size="m" :disabled="constitutiva.roleStaff"
          @click="validarCupon(constitutiva.cotizacion.cupon_info.nombre)">Aplicar</b-button>
      </div>
    </div>

    <div class="row mt-1" v-if="cuponAplicado">
      <div class="col-md-6">
        <label class="grey">Descuento {{ valorDescuentoCupon }}</label>
      </div>
      <div class="col-md-6">
        <label class="grey">{{ cantidadDescontadaCupon }}</label>
      </div>
    </div>

    <div class="row mt-1">
      <div class="col-md-6">
        <h4 class="cardD">Total</h4>
      </div>
      <div class="col-md-6">
        <h4 class="cardD">${{ constitutiva.precio_total }}</h4>
      </div>
    </div>

    <label class="mb-12 leyenda">
      Esta es una plataforma automática, no sustituye a una asesoría legal.
    </label>

    <!-- <div class="mt-4">
      <div class="">
        <b-button
          type="submit"
          class="btn-fill orange-btn bottom2"
          size="m"
          :disabled="constitutiva.disabledCotizacion"
          >Validar</b-button
        >
      </div>
    </div> -->

    <div class="mt-4">
      <div class="row">
        <div class="col-10">
          <b-button type="submit" class="btn-fill orange-btn bottom2" size="m"
            :disabled="constitutiva.disabledCotizacion">Guardar</b-button>
        </div>
        <div class="col-2">
          <b-button variant="success" class="btn-fill bottom2" size="m" :disabled="constitutiva.disabledCotizacion"
            @click="validar()">Validar</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  CuponesApi,
  ConstitucionSociedadesApi
} from "@/api";

import { formatCurrency } from "@/utils/number-utils";

export default {
  name: "TabCotizacion",

  props: {
    constitutiva: { type: Object },
    cat_gastos: { type: Array }
  },

  async created() {
    await this.getOptionsGastos();

    if (!this.constitutiva.cotizacion || !this.constitutiva.cotizacion.cupon_info) {
      // eslint-disable-next-line vue/no-mutating-props
      this.constitutiva.cotizacion = {
        cupon_info: {
          nombre: "",
          tipo: "",
          descuento:  0
         }
      };

      this.nombreCuponDescuento = this.constitutiva.cotizacion.cupon_info.nombre;
    }

    this.calculaDescuentoPrimeraVez();

    if (this.constitutiva.cotizacion.cupon_info) {
      this.nombreCuponDescuento = this.constitutiva.cotizacion.cupon_info.nombre;

      const total =
        this.subtotal_resumen +
        this.subtotal_adicionales +
        this.subtotal_extras +
        this.subtotal_gastos;

      if (this.constitutiva.cotizacion.cupon_info.tipo === "Porcentaje") {
        let descuentoDecimal = this.constitutiva.cotizacion.cupon_info.descuento;

        if (this.constitutiva.cotizacion.cupon_info.descuento > 1) {
          descuentoDecimal = this.constitutiva.cotizacion.cupon_info.descuento / 100;
        }

        this.valorDescuentoCupon = "%" + descuentoDecimal * 100;
        this.cantidadDescontadaCupon = formatCurrency(-1 * total * descuentoDecimal);
      } else if (this.constitutiva.cotizacion.cupon_info.tipo === "Monto") {
        this.valorDescuentoCupon = formatCurrency(this.constitutiva.cotizacion.cupon_info.descuento);
        this.cantidadDescontadaCupon = formatCurrency(this.constitutiva.cotizacion.cupon_info.descuento);
      }
      this.cuponAplicado = true;
    }
  },

  data() {
    return {
      loading: false,
      options_gastos: [],
      costo_accionista_pf: 0,
      costo_accionista_pm: 0,
      costo_administrador: 0,
      costo_comisario: 0,
      costo_apoderado: 0,
      costo_capital_extranjero: 0,
      costo_titulos: 0,
      costo_libros: 0,
      costo_reglas_control: 0,
      costo_reglas_asambleas: 0,
      costo_exclusion_accionistas: 0,
      costos_firma_fuera: 0,

      num_accionistas_pf_incluido: 0,
      num_administradores_incluido: 0,
      num_comisarios_incluido: 0,
      num_apoderados_incluido: 0,
      subtotal_resumen: 7000,

      num_accionistas_pf_adicionales: 0,
      // num_accionistas_pm_adicionales: 0,
      num_administradores_adicionales: 0,
      num_comisarios_adicionales: 0,
      num_apoderados_adicionales: 0,
      icon_capital_extranjero: "x",
      icon_reglas_control: "x",
      icon_reglas_asambleas: "x",
      icon_exclusion_accionistas: "x",
      icon_firma_fuera: "x",
      precio_accionistas_pf_adicionales: 0,
      precio_accionistas_pm_adicionales: 0,
      precio_administradores_adicionales: 0,
      precio_comisarios_adicionales: 0,
      precio_apoderados_adicionales: 0,
      precio_capital_extranjero: 0,
      precio_reglas_control: 0,
      precio_reglas_asambleas: 0,
      precio_exclusion_accionistas: 0,
      precio_firma_fuera: 0,
      subtotal_adicionales: 0,

      // subtotal_titulos: 0,
      // subtotal_libros: 0,
      subtotal_extras: 0,

      gastos_envio: true,
      subtotal_gastos: 0,

      num_accionistas_pf_total: 0,
      num_accionistas_pm_total: 0,
      num_administradores_total: null,
      num_comisarios_total: null,
      num_apoderados_total: null,

      form: {
        cupones_id: null,
        cupones_referencia_id: null,
        precio_total: 0,
        pagado: null,
        folio_transaccion: null,
        // titulos_acciones: null,
        // libros_corporativos: null,
        gastos: [],
        // cupones: [],
        cupones_id: null, //cuando entra el cupon, busco id
        cupones_referencia_id: null,

        subtotal_titulos: 0,
        subtotal_libros: 0
      },

      cuponAplicado: false,
      nombreCuponDescuento: "",
      valorDescuentoCupon: "",
      cantidadDescontadaCupon: 0,
    };
  },

  methods: {
    getOptionsGastos() {
      // let copy_cat_gastos = JSON.parse(JSON.stringify(this.cat_gastos));
      // let pre_options = copy_cat_gastos.slice(10, this.cat_gastos.length);

      // pre_options.forEach(element => {
      //   const obj = {
      //     text: element.servicio,
      //     value: element
      //   };
      //   var a = Object.assign({}, obj);
      //   this.options_gastos.push(a);
      // });

      // if (this.constitutiva.gastos) {
      //   if (this.constitutiva.gastos.length === 0) {
      //     for (let i = 0; i < this.options_gastos.length; i++) {
      //       this.options_gastos[i].value.costo = 0;
      //       var a = Object.assign({}, this.options_gastos[i]);
      //       this.options_gastos.splice([i], 1, a);
      //     }

      //     //solo si no esta agregado, en un get edit ya esta
      //     let hayGatosEnvio = this.constitutiva.gastos.find(
      //       x => x.clave === "gastos_envio"
      //     );
      //     if (!hayGatosEnvio) {
      //       let gastos_envio = this.cat_gastos.find(
      //         x => x.servicio === "Gastos de envío"
      //       );
      //       var a = Object.assign({}, gastos_envio);
      //       this.constitutiva.gastos.push(gastos_envio);
      //     }
      //   } else if (this.constitutiva.gastos.length >= 1) {
      //     //cuando regresa y esté en 0 la no selected
      //     for (let i = 0; i < this.options_gastos.length; i++) {
      //       let indexOG = this.constitutiva.gastos.findIndex(
      //         x => x.id === this.options_gastos[i].value.id
      //       );
      //       if (indexOG === -1) {
      //         this.options_gastos[i].value.costo = 0;
      //         var a = Object.assign({}, this.options_gastos[i]);
      //         this.options_gastos.splice([i], 1, a);
      //       }
      //     }
      //   }
      // }

      this.costo_accionista_pf = this.cat_gastos.find(
        x => x.clave === "accionista_pf"
      ).costo;
      this.costo_accionista_pm = this.cat_gastos.find(
        x => x.clave === "accionista_pm"
      ).costo;
      this.costo_administrador = this.cat_gastos.find(
        x => x.clave === "administrador"
      ).costo;
      this.costo_comisario = this.cat_gastos.find(
        x => x.clave === "comisario"
      ).costo;
      this.costo_apoderado = this.cat_gastos.find(
        x => x.clave === "apoderado"
      ).costo;
      this.costo_capital_extranjero = this.cat_gastos.find(
        x => x.clave === "capital_extranjero"
      ).costo;
      this.costo_titulos = this.cat_gastos.find(
        x => x.clave === "titulos_acciones"
      ).costo;
      this.costo_libros = this.cat_gastos.find(
        x => x.clave === "libros_corporativos"
      ).costo;
      this.costo_reglas_control = this.cat_gastos.find(
        x => x.clave === "reglas_control"
      ).costo;
      this.costo_reglas_asambleas = this.cat_gastos.find(
        x => x.clave === "reglas_asambleas"
      ).costo;
      this.costo_exclusion_accionistas = this.cat_gastos.find(
        x => x.clave === "exclusion_accionistas"
      ).costo;
      this.costos_firma_fuera = this.cat_gastos.find(
        x => x.clave === "firma_fuera"
      ).costo;

      this.calculaCotizacion();
    },

    calculaCotizacion() {
      this.loading = true;

      for (let i = 0; i < this.constitutiva.accionistas.length; i++) {
        if (this.constitutiva.accionistas[i].persona === "Persona Física") {
          this.num_accionistas_pf_total++;
        } else if (
          this.constitutiva.accionistas[i].persona === "Persona Moral"
        ) {
          this.num_accionistas_pm_total++;
        }
      }

      this.num_administradores_total = this.constitutiva.administradores.length;
      this.num_comisarios_total = this.constitutiva.vigilancia.length;
      this.num_apoderados_total = this.constitutiva.apoderados.length;

      if (this.num_accionistas_pf_total > 2) {
        this.num_accionistas_pf_incluido = 2;
        this.num_accionistas_pf_adicionales = this.num_accionistas_pf_total - 2;
        this.precio_accionistas_pf_adicionales =
          this.num_accionistas_pf_adicionales * this.costo_accionista_pf;
      } else {
        this.num_accionistas_pf_incluido = this.num_accionistas_pf_total;
      }

      if (this.num_accionistas_pm_total > 0) {
        this.precio_accionistas_pm_adicionales =
          this.num_accionistas_pm_total * this.costo_accionista_pm;
      }

      if (this.num_administradores_total > 1) {
        this.num_administradores_incluido = 1;
        this.num_administradores_adicionales =
          this.num_administradores_total - 1;
        this.precio_administradores_adicionales =
          this.num_administradores_adicionales * this.costo_administrador;
      } else {
        this.num_administradores_incluido = this.num_administradores_total;
      }

      if (this.num_comisarios_total > 1) {
        this.num_comisarios_incluido = 1;
        this.num_comisarios_adicionales = this.num_comisarios_total - 1;
        this.precio_comisarios_adicionales =
          this.num_comisarios_adicionales * this.costo_comisario;
      } else {
        this.num_comisarios_incluido = this.num_comisarios_total;
      }

      if (this.num_apoderados_total > 1) {
        this.num_apoderados_incluido = 1;
        this.num_apoderados_adicionales = this.num_apoderados_total - 1;
        this.precio_apoderados_adicionales =
          this.num_apoderados_adicionales * this.costo_apoderado;
      } else {
        this.num_apoderados_incluido = this.num_apoderados_total;
      }

      if (this.constitutiva.capital_extranjero) {
        this.icon_capital_extranjero = "✓";
        this.precio_capital_extranjero = this.costo_capital_extranjero;
      }
      if (this.constitutiva.reglas_control) {
        this.icon_reglas_control = "✓";
        this.precio_reglas_control = this.costo_reglas_control;
      }
      if (this.constitutiva.reglas_asambleas) {
        this.icon_reglas_asambleas = "✓";
        this.precio_reglas_asambleas = this.costo_reglas_asambleas;
      }
      if (this.constitutiva.exclusion_accionistas) {
        this.icon_exclusion_accionistas = "✓";
        this.precio_exclusion_accionistas = this.costo_exclusion_accionistas;
      }
      if (!this.constitutiva.firma_oficina) {
        this.icon_firma_fuera = "✓";
        this.precio_firma_fuera = this.costos_firma_fuera;
      }

      this.subtotal_adicionales =
        this.precio_accionistas_pf_adicionales +
        this.precio_accionistas_pm_adicionales +
        this.precio_administradores_adicionales +
        // this.precio_comisarios_adicionales +
        this.precio_apoderados_adicionales +
        this.precio_capital_extranjero +
        this.precio_reglas_control +
        this.precio_reglas_asambleas +
        this.precio_exclusion_accionistas +
        this.precio_firma_fuera;

      this.calculaSubtotalExtras();
      this.loading = false;
    },

    setTitulos() {
      if (this.constitutiva.titulos_acciones) {
        this.constitutiva.subtotal_titulos = this.costo_titulos;
      } else {
        this.constitutiva.subtotal_titulos = 0;
      }
      this.calculaSubtotalExtras();
    },
    setLibros() {
      if (this.constitutiva.libros_corporativos) {
        this.constitutiva.subtotal_libros = this.costo_libros;
      } else {
        this.constitutiva.subtotal_libros = 0;
      }
      this.calculaSubtotalExtras();
    },
    calculaSubtotalExtras() {
      this.subtotal_extras =
        this.constitutiva.subtotal_titulos + this.constitutiva.subtotal_libros;
      this.calculaGastos();
    },

    setGasto(gasto) {
      if (gasto.value.costo === 0) {
        let obj = this.cat_gastos.find(x => x.id === gasto.value.id);
        gasto.value.costo = obj.costo;
      } else {
        gasto.value.costo = 0;
      }

      this.calculaGastos();
    },
    calculaGastos() {
      let sumaGastos = 0;
      this.constitutiva.gastos.forEach(element => {
        sumaGastos = sumaGastos + element.costo;
      });
      this.subtotal_gastos = sumaGastos;
      this.calculaTotal();
    },

    calculaTotal() {
      let total =
        this.subtotal_resumen +
        this.subtotal_adicionales +
        this.subtotal_extras +
        this.subtotal_gastos;
      //cupones cuando se habilite
      let totalComma = this.commaNumber(total);
      this.constitutiva.precio_total = totalComma;

      // if (!this.constitutiva.precio_total) {
      //   this.constitutiva.precio_total = totalComma;
      // }
    },
    commaNumber(n) {
      return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    validar() {
      this.$emit("validaForm", "Cotizacion");
    },

    calculaDescuentoPrimeraVez() {
      // console.log(this.constitutiva.precio_total);
      if (this.constitutiva.cotizacion) {
        if (this.constitutiva.cotizacion.cupones_id) {
          let total =
            this.subtotal_resumen +
            this.subtotal_adicionales +
            this.subtotal_extras +
            this.subtotal_gastos;
          if (this.constitutiva.cotizacion.cupon_info.tipo == "Porcentaje") {
            let precio_total =
              total * (1 - this.constitutiva.cotizacion.cupon_info.descuento);
            // let totalComma = this.commaNumber(precio_total);
            // this.constitutiva.precio_total = totalComma;
            this.constitutiva.precio_total = precio_total;
          } else if (this.constitutiva.cotizacion.cupon_info.tipo == "Monto") {
            let precio_total =
              total - this.constitutiva.cotizacion.cupon_info.descuento;
            // let totalComma = this.commaNumber(precio_total);
            // this.constitutiva.precio_total = totalComma;
            this.constitutiva.precio_total = precio_total;
          }
        }
      }
    },

    async validarCupon(texto_cupon) {
      try {
        var time_zone = -(new Date().getTimezoneOffset() / 60);
        var data = {
          nombre: texto_cupon,
          timeZone: time_zone
        };
        const { isValid, id, tipo, descuento } = await CuponesApi.cuponValido(
          data
        );

        if (isValid) {
          let total =
            this.subtotal_resumen +
            this.subtotal_adicionales +
            this.subtotal_extras +
            this.subtotal_gastos;

          if (tipo == "Porcentaje") {
            let precio_total = total * (1 - descuento);
            let totalComma = this.commaNumber(precio_total);
            this.constitutiva.precio_total = totalComma;
          } else if (tipo == "Monto") {
            let precio_total = total - descuento;
            let totalComma = this.commaNumber(precio_total);
            this.constitutiva.precio_total = totalComma;
          }

          this.constitutiva.cotizacion.cupones_id = id;

          var data2 = {
            clientes_id: this.constitutiva.clientes_id,
            activo: 1
          };
          let objCoti = {
            id: this.constitutiva.cotizacion.id,
            cupones_id: id,
            precio_total: this.constitutiva.precio_total
          };
          data2["cotizacion"] = objCoti;
          await ConstitucionSociedadesApi.edit(this.constitutiva.id, data2);

          this.$toast.success("El cupón se aplicó correctamente", {
            timeout: 4000, //false,
            hideProgressBar: false,
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: "button",
            icon: true
          });
        } else {
          this.$toast.warning("El cupón es inválido", {
            timeout: 4000, //false,
            hideProgressBar: false,
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: "button",
            icon: true
          });
        }
      } catch {
        this.$toast.warning("El cupón es inválido", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
      }
    }
  }
};
</script>

<style scoped>
.bottom2 {
  float: right;
}

.blue {
  background-color: #effbff;
  border-color: #effbff !important;
  text-align: justify;
}

.leyenda {
  text-transform: lowercase !important;
  color: #79cff7;
}

.leyenda:first-letter {
  text-transform: capitalize;
}

.grey {
  color: #363535 !important;
  margin: 0 !important;
  padding-top: 0 !important;
  /* padding-bottom: 0 !important; */
}
</style>
