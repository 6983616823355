<template>
  <div class="contact-us full-screen">
    <div class="container">
      <div v-if="loading">
        <div id="loader">
          <font-awesome-icon icon="spinner" spin />
        </div>
      </div>
    </div>

    <TopNavbar />

    <div class="wrapper wrapper-full-page section content">
      <div class="container">
        <h4 slot="header" class="card-title">Iniciar sesión</h4>

        <card id="card">
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(enter)">
              <Form :user="user" @submit.prevent />
              <!-- @onSubmit="enter()" -->
            </form>
          </ValidationObserver>

          <router-link class="link" :to="'/recuperacionpass'">Olvidé mi contraseña</router-link>
        </card>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Card from "src/components/Cards/Card.vue";
import Footer from "@/layout/ContentFooter.vue";
import TopNavbar from "@/layout/TopNavbar.vue";
import Storage from "@/utils/storage";
import Form from "@/pages/login/Form.vue";

import { LoginApi } from "@/api";
// import { useToast } from "vue-toastification";

const storage = new Storage();

export default {
  name: "Login",

  components: {
    Card,
    TopNavbar,
    Footer,
    Form
  },

  data() {
    return {
      loading: false,
      user: {
        email: null,
        password: null
      }
      // role: "",
    };
  },

  methods: {
    enter() {
      this.loading = true;
      var data = {
        email: this.user.email,
        contraseña: this.user.password
      };
      LoginApi.login(data)
        .then(response => {
          const token = response.token.replace(/"/g, '');
          storage.saveItem("user", this.user.email);
          storage.saveItem("role", response.rol);
          storage.saveItem("corredurias_id", response.corredurias_id);
          storage.saveItem("logged", true);
          storage.saveItem("accessToken", token)

          this.$router.push("/dashboard");
        })
        .catch(error => {
          var status = error.status;
          storage.saveItem("logged", false);
          storage.removeItem("accessToken");
          this.loading = false;
          if (status === 401) {
            this.$toast.error("Datos incorrectos", {
              timeout: 4000, //false,
              hideProgressBar: false,
              position: "top-right",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              closeButton: "button",
              icon: true
            });
          } else if (status === 409) {
            this.$toast.warning("Por favor revise su correo electrónicoo y verifique su cuenta para que pueda iniciar sesión", {
              timeout: 8000, //false,
              hideProgressBar: false,
              position: "top-right",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              closeButton: "button",
              icon: true
            }
            );
          }
        });
    }
  }
};
</script>

<style scoped>
#card {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* color: #9a9a9a; */
  color: #ea640b;
  font-size: 13px;
}

.link {
  color: rgba(97, 95, 95, 0.533);
  size: 16px;
}

.link:hover {
  color: #ea640b;
}
</style>
