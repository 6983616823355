var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.currentCompareciente.existente)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"name":"persona","rules":"required","custom-messages":{
        required: 'La persona es obligatoria',
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{attrs:{"for":""}},[_vm._v("Persona")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentCompareciente.persona),expression:"currentCompareciente.persona"}],staticClass:"custom-select",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.currentCompareciente, "persona", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.optionsPersona),function(option){return _c('option',{key:option.value,domProps:{"value":option.value}},[_vm._v(" "+_vm._s(option.text)+" ")])}),0),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,258086156)})],1)]):_vm._e(),(_vm.currentCompareciente.persona === 'Moral' && !_vm.currentCompareciente.existente)?_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"name":"denominacion_social","rules":"required","custom-messages":{
        required: 'La denominacion social es obligatoria',
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"type":"text","label":"Denominación social (Incluir tipo de sociedad)","placeholder":"Denominacion social"},model:{value:(_vm.currentCompareciente.denominacion_social),callback:function ($$v) {_vm.$set(_vm.currentCompareciente, "denominacion_social", $$v)},expression:"currentCompareciente.denominacion_social"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2124261296)})],1)]):(_vm.currentCompareciente.persona === 'Física' && !_vm.currentCompareciente.existente)?_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"nombre","rules":"required","custom-messages":{
        required: 'El nombre es obligatorio',
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"type":"text","label":"Nombre","placeholder":"Nombre"},model:{value:(_vm.currentCompareciente.nombre),callback:function ($$v) {_vm.$set(_vm.currentCompareciente, "nombre", $$v)},expression:"currentCompareciente.nombre"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"nombre","rules":"required","custom-messages":{
        required: 'El paterno es obligatorio',
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"type":"text","label":"Paterno","placeholder":"Paterno"},model:{value:(_vm.currentCompareciente.paterno),callback:function ($$v) {_vm.$set(_vm.currentCompareciente, "paterno", $$v)},expression:"currentCompareciente.paterno"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-4"},[_c('base-input',{attrs:{"type":"text","label":"Materno","placeholder":"Materno"},model:{value:(_vm.currentCompareciente.materno),callback:function ($$v) {_vm.$set(_vm.currentCompareciente, "materno", $$v)},expression:"currentCompareciente.materno"}})],1)]):_vm._e(),(_vm.currentCompareciente.persona === 'Física' && _vm.vistaRatificantes)?_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"name":"email","rules":"required","custom-messages":{
        required: 'El email es obligatorio',
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"type":"text","label":"Correo electrónico","placeholder":"correo@dominio.com","disabled":_vm.currentCompareciente.existente},model:{value:(_vm.currentCompareciente.email),callback:function ($$v) {_vm.$set(_vm.currentCompareciente, "email", $$v)},expression:"currentCompareciente.email"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,421990964)})],1)]):_vm._e(),(_vm.currentCompareciente.persona === 'Física')?_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"principal"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"control-label",attrs:{"for":"chkPrinciapl"}},[_vm._v("Actúa por su propio derecho.")]),_c('b-form-checkbox',{attrs:{"id":"chkPrinciapl"},on:{"change":_vm.deshabilitaRepresentante},model:{value:(_vm.currentCompareciente.principal),callback:function ($$v) {_vm.$set(_vm.currentCompareciente, "principal", $$v)},expression:"currentCompareciente.principal"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1285258024)})],1),(_vm.representadas.length > 0 && _vm.currentCompareciente.persona === 'Física')?_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"representante"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"control-label"},[_vm._v("Representante")]),_c('b-form-checkbox',{on:{"change":_vm.deshabilitaPrincipal},model:{value:(_vm.representante),callback:function ($$v) {_vm.representante=$$v},expression:"representante"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,651586777)})],1):_vm._e(),(_vm.representante)?_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"representada","rules":"required","custom-messages":{
        required: 'La persona moral representada es obligatoria',
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{attrs:{"for":""}},[_vm._v("Persona representada")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentCompareciente.representada),expression:"currentCompareciente.representada"}],staticClass:"custom-select",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.currentCompareciente, "representada", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.agregaRepresentadanombre($event)}]}},_vm._l((_vm.representadas),function(option){return _c('option',{key:option.id,domProps:{"value":option.id}},[_vm._v(" "+_vm._s(option.nombreCompleto)+" ")])}),0),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,688484290)})],1):_vm._e()]):_vm._e(),_c('div',{staticClass:"row mt-4"}),_vm._m(0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4 text-center"},[_c('button',{staticClass:"btn btn-fill float-right orange-btn",attrs:{"type":"submit"}},[_vm._v(" Guardar ")])])
}]

export { render, staticRenderFns }