<template>
  <div class="content">
    <div class="container">
      <div v-if="loading">
        <div id="loader">
          <font-awesome-icon icon="spinner" spin />
        </div>
      </div>
    </div>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog" hide-footer id="modal-confirm-dialog">
      <h4 class="text-center">¿Seguro que quiere eliminar el cupón?</h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="deleteCupon">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="cupon-modal" title="" hide-footer id="modalC" size="xl">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(putCupon)">
          <ModalCupon :currentCupon="currentCupon" @submit.prevent @putCupon="putCupon($event)" />
        </form>
      </ValidationObserver>
    </b-modal>

    <div class="container-fluid">
      <div class="row">
        <div class="col-4">
          <base-input v-model="searchCupon" placeholder="Buscar"></base-input>
        </div>
        <div class="col-4"></div>
        <div class="col-4">
          <b-button type="submit" class="btn-fill float-right orange-btn" @click="addCupon">
            Nuevo cupón
          </b-button>
        </div>
      </div>

      <b-table hover :fields="fieldsCupones" :items="cupones">
        <template #cell(index)="{ index }">
          {{ getIndex(index + 1) }}
        </template>
        <template #cell(acciones)="{ item }">
          <b-button variant="warning" size="sm" v-b-tooltip.hover title="Editar" @click="editCupon(item)"
            class="editBtn">
            <b-icon-pencil />
          </b-button>
          <b-button variant="primary" size="sm" v-b-tooltip.hover title="Información aplicaciones"
            @click="cuponInfo(item)" class="editBtn">
            <b-icon-eye />
          </b-button>
          <b-button variant="danger" size="sm" v-b-tooltip.hover title="Eliminar" @click="predeleteCupon(item.id)"
            class="editBtn">
            <b-icon-trash />
          </b-button>
        </template>
      </b-table>
      <div class="d-flex justify-content-between pages">
        <b-pagination v-model="currentPage" :per-page="perPage" :total-rows="total" />

        <span>Mostrando {{ cupones.length }} registros de {{ total }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { CuponesApi } from "@/api";
import ModalCupon from "@/views/staff/settings/modales/ModalCupon.vue";

export default {
  name: "Cupones",

  created() {
    this.getCupones();
  },

  components: {
    ModalCupon
  },

  watch: {
    currentPage: "getCupones",
    searchCupon: "getCupones"
  },

  computed: {
    fieldsCupones() {
      let fieldsCupones = [
        { key: "index", label: "", class: "text-center" },
        { key: "nombre", label: "Nombre", class: "text-center" },
        { key: "descuento", label: "Descuento", class: "text-center" },
        { key: "tipo_letra", label: "Tipo", class: "text-center" },
        { key: "habilitado_simbolo", label: "Habilitado", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" }
      ];
      return fieldsCupones;
    }
  },

  data() {
    return {
      loading: true,
      searchCupon: null,
      currentPage: 1,
      perPage: 10,
      total: 0,
      cupones: [],
      currentCupon: {
        nombre: "",
        tipo: 0,
        tipo_letra: "",
        descuento: 0,
        limiteAplicaciones: 0,
        fechaInicio: "",
        fechaFin: "",
      },
      currentCuponId: null
    };
  },

  methods: {
    getIndex(index) {
      return index + (this.currentPage - 1) * this.perPage;
    },

    async getCupones() {
      this.loading = true;
      try {
        const {
          odata_page: page,
          odata_total: total,
          odata_perpage: perPage,
          value
        } = await CuponesApi.list({
          page: this.currentPage,
          data: this.searchGasto,
          registersPerPage: this.perPage
        });

        this.currentPage = page;
        this.total = total;

        value.forEach(element => {
          if (element.habilitado) element.habilitado_simbolo = "✓";
          else element.habilitado_simbolo = "x";

          if (element.tipo == 1) element.tipo_letra = "Porcentaje";
          else element.tipo_letra = "Monto";
        });

        this.cupones = value;
      } catch { }
      this.loading = false;
    },

    cuponInfo(item) {
      //ver si si es otra ruta o no,
      //seria una tabla con los que han sido usados
      //nombre de  servicio, tipo, costo, fecha
      this.$router.push({
        path: `/dashboard/settings/cupondetalle/${item.id}`
        // query: {
        //   // proceso: "addformalizacion",
        //   // procesoId: this.formalizacion.id,
        //   // accion: accion
        // }
      });
    },

    addCupon() {
      this.$refs["cupon-modal"].show();
      this.currentCupon = {};
      this.currentCupon.habilitado = 1;
    },

    editCupon(item) {
      this.$refs["cupon-modal"].show();
      this.currentCupon = item;
    },

    async putCupon(eventArgs) {
      this.loading = true;

      const data = {
        nombre: this.currentCupon.nombre,
        descuento: parseFloat(this.currentCupon.descuento),
        habilitado: this.currentCupon.habilitado,
        tipo: this.currentCupon.tipo,
        fechaInicio: this.currentCupon.fechaInicio,
        fechaFin: this.currentCupon.fechaFin,
        limiteAplicaciones: parseInt(this.currentCupon.limiteAplicaciones),
        activo: 1,
      };

      this.currentCupon.tipo_letra = this.currentCupon.tipo === 1 ? 'Porcentaje' : 'Monto';

      if (!this.currentCupon.id) {
        try {
          const { id } = await CuponesApi.create(data);
          this.currentCupon.id = id;

          if (this.currentCupon.habilitado) this.currentCupon.habilitado_simbolo = "✓";
          else this.currentCupon.habilitado_simbolo = "x";

          this.cupones.push(this.currentCupon);

          this.$toast.success("Cupón creado", {
            timeout: 4000, //false,
            hideProgressBar: false,
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: "button",
            icon: true
          });
          this.total++;
          this.loading = false;
        } catch (error) {
          this.$toast.error("Sucedió un error, intente más tarde", {
            timeout: 4000, //false,
            hideProgressBar: false,
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: "button",
            icon: true
          });
          this.loading = false;
        }
      } else {
        try {
          await CuponesApi.edit(this.currentCupon.id, data);
          this.$toast.success("Cupón guardado", {
            timeout: 4000, //false,
            hideProgressBar: false,
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: "button",
            icon: true
          });
          this.loading = false;
        } catch {
          this.$toast.error("Sucedió un error, intente más tarde", {
            timeout: 4000, //false,
            hideProgressBar: false,
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: "button",
            icon: true
          });
          this.loading = false;
        }
      }
      this.$refs["cupon-modal"].hide();
    },

    predeleteCupon(id) {
      this.currentCuponId = id;
      this.$refs["modal-confirm-dialog"].show();
    },

    async deleteCupon() {
      this.loading = true;
      try {
        let id = this.currentCuponId;
        await CuponesApi.delete(id);
        var index = this.cupones.findIndex(function (o) {
          return o.id === id;
        });
        this.cupones.splice(index, 1);
        this.total--;

        this.$toast.success("Cupón eliminado", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
        this.loading = false;
        this.$refs["modal-confirm-dialog"].hide();
      } catch {
        this.loading = false;
        this.$refs["modal-confirm-dialog"].hide();
        this.$toast.error("Sucedió un error, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
      }
    }
  }
};
</script>

<style scoped>
.editBtn {
  margin-right: 10px;
}
</style>
