<template>
  <div class="container">
    <div class="container">
      <div v-if="loading">
        <div id="loader">
          <font-awesome-icon icon="spinner" spin />
        </div>
      </div>
    </div>

    <h4 class="cardD">Cotización y pago</h4>
    <card class="blue">
      <i class="fa fa-info"></i>
      Aquí van instrucciones.
      <p></p>
      <i class="fa fa-play"></i>
      Links a videos.
      <p></p>
      <i class="fa fa-question"></i>
      Datos de contacta a un asesor.
    </card>

    <h4 class="cardD">Resumen de Formalización de Acta</h4>
    <div class="row">
      <label class="grey col-md-1">✓</label>
      <label class="grey col-md-5"> Monto base</label>
      <label class="grey col-md-6">$7000</label>
    </div>
    <div class="row mt-2">
      <label class="grey col-md-6"> Subtotal </label>
      <label class="grey col-md-6">${{ subtotal_resumen }}</label>
    </div>

    <h4 class="cardD mt-1">Adicionales</h4>
    <div v-if="formalizacion.modifica_capital">
      <div class="row" v-if="formalizacion.monto_modificado <= 5000000">
        <label class="grey col-md-1">{{
          icon_aumento_disminucion_capital_5mdp
        }}</label>
        <label class="grey col-md-5">
          Aumento o disminución de capital fijo o variable hasta 5MDP</label>
        <label class="grey col-md-6">${{ precio_aumento_disminucion_capital_5mdp }}</label>
      </div>
      <div v-else-if="formalizacion.monto_modificado > 5000000">
        <div class="row" v-if="formalizacion.capital_modificado == 'Fijo'">
          <label class="grey col-md-1">{{
            icon_aumento_disminucion_capital_fijo_20mdp
          }}</label>
          <label class="grey col-md-5">
            Aumento o disminución de capital fijo hasta 200MDP</label>
          <label class="grey col-md-6">${{ precio_aumento_disminucion_capital_fijo_20mdp }}</label>
        </div>
        <div v-else-if="formalizacion.capital_modificado == 'Variable'" class="row">
          <label class="grey col-md-1">{{
            icon_aumento_disminucion_capital_variable_20mdp
          }}</label>
          <label class="grey col-md-5">
            Aumento o disminución de capital variable hasta 200MDP
          </label>
          <label class="grey col-md-6">${{ precio_aumento_disminucion_capital_variable_20mdp }}</label>
        </div>
      </div>
    </div>

    <div class="row" v-if="formalizacion.nuevos_accionistas">
      <label class="grey col-md-1">{{ icon_ingreso_accionistas }}</label>
      <label class="grey col-md-5">Ingreso de accionistas </label>
      <label class="grey col-md-6">${{ precio_ingreso_accionistas }}</label>
    </div>
    <div class="row" v-if="formalizacion.capital_extranjero">
      <label class="grey col-md-1">{{ icon_ingreso_capital_extranjero }}</label>
      <label class="grey col-md-5"> Ingreso de capital extranjero</label>
      <label class="grey col-md-6">${{ precio_ingreso_capital_extranjero }}</label>
    </div>
    <div class="row" v-if="formalizacion.modifica_estatutos">
      <label class="grey col-md-1">{{ icon_modifica_estatutos }}</label>
      <label class="grey col-md-5"> Modificación de estatutos</label>
      <label class="grey col-md-6">${{ precio_modifica_estatutos }}</label>
    </div>
    <div class="row" v-if="formalizacion.modifica_domicilio_social">
      <label class="grey col-md-1">{{ icon_cambio_domicilio }}</label>
      <label class="grey col-md-5"> Cambio de domicilio de la sociedad</label>
      <label class="grey col-md-6">${{ precio_cambio_domicilio }}</label>
    </div>
    <div class="row" v-if="formalizacion.modifica_denominacion_social">
      <label class="grey col-md-1">{{ icon_cambio_denominacion }}</label>
      <label class="grey col-md-5"> Cambio de la denominación social</label>
      <label class="grey col-md-6">${{ precio_cambio_denominacion }}</label>
    </div>
    <div class="row" v-if="formalizacion.modifica_duracion_sociedad">
      <label class="grey col-md-1">{{ icon_cambio_duracion }}</label>
      <label class="grey col-md-5"> Cambio de la duración de la sociedad</label>
      <label class="grey col-md-6">${{ precio_cambio_duracion }}</label>
    </div>
    <div class="row" v-if="formalizacion.modifica_objeto_social">
      <label class="grey col-md-1">{{ icon_cambio_objeto_social }}</label>
      <label class="grey col-md-5"> Cambio del objeto social</label>
      <label class="grey col-md-6">${{ precio_cambio_objeto_social }}</label>
    </div>
    <div class="row" v-if="formalizacion.reforma_total">
      <label class="grey col-md-1">{{ icon_reforma_total_estatutos }}</label>
      <label class="grey col-md-5"> Reforma total de estatutos</label>
      <label class="grey col-md-6">${{ precio_reforma_total_estatutos }}</label>
    </div>
    <div class="row" v-if="formalizacion.disolucion_sociedad">
      <label class="grey col-md-1">{{ icon_disolucion }}</label>
      <label class="grey col-md-5"> Disolución de la sociedad</label>
      <label class="grey col-md-6">${{ precio_disolucion }}</label>
    </div>
    <div class="row">
      <label class="grey col-md-1">{{
        formalizacion.num_funcionarios_revocados
      }}</label>
      <label class="grey col-md-5"> Revocación de poderes</label>
      <label class="grey col-md-6">${{ costos_revocaciones_poder }}</label>
    </div>
    <div class="row">
      <label class="grey col-md-1">?</label>
      <label class="grey col-md-5"> Nombramientos adicionales</label>
      <!-- <label class="grey col-md-6">${{ precio_firma_fuera }}</label> -->
    </div>
    <div class="row mt-2">
      <label class="grey col-md-6"> Subtotal </label>
      <label class="grey col-md-6">${{ subtotal_adicionales }}</label>
    </div>

    <h4 class="cardD mt-1">Extras</h4>
    <div class="row">
      <div class="col-md-1">
        <b-form-checkbox id="chkEntregablesAdicionales" :disabled="formalizacion.disabled"
          v-model="entregablesAdicionales" @change="setEntregableAdicional">
        </b-form-checkbox>
      </div>
      <div class="col-md-5">
        <label for="chkEntregablesAdicionales" class="grey">Entregable adicional</label>
      </div>
      <div class="col-md-6">
        <label class="grey">${{ formalizacion.subtotal_entregable_adicional }}</label>
      </div>
    </div>
    <div class="row">
      <div class="col-md-1">
        <b-form-checkbox id="chkFirmaFueraOficina" :disabled="formalizacion.disabled" v-model="firmaFueraOficina"
          @change="setFirmaOficina">
        </b-form-checkbox>
      </div>
      <div class="col-md-5">
        <label for="chkFirmaFueraOficina" class="grey">Firma fuera de oficina</label>
      </div>
      <div class="col-md-6">
        <label class="grey">${{ formalizacion.subtotal_firma_fuera_oficina }}</label>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-md-6">
        <label class="grey">Subtotal</label>
      </div>
      <div class="col-md-6">
        <label class="grey">${{ subtotal_extras }}</label>
      </div>
    </div>

    <h4 class="cardD mt-1">Gastos</h4>
    <div class="row">
      <div class="col-md-1">
        <b-form-checkbox disabled v-model="gastos_envio"></b-form-checkbox>
      </div>
      <div class="col-md-5">
        <label class="grey">Gastos de envío</label>
      </div>
      <div class="col-md-6">
        <label class="grey">$0</label>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-md-6">
        <label class="grey">Subtotal</label>
      </div>
      <div class="col-md-6">
        <label class="grey">${{ subtotal_gastos }}</label>
      </div>
    </div>

    <h4 class="cardD mt-1">Cupones</h4>
    <div class="row">
      <div class="col-12">
        <base-input type="text" label="Si cuenta con un cupón puede aplicarlo." placeholder="Cupón de descuento"
          :disabled="cuponAplicado" v-model="nombreCuponDescuento"></base-input>
      </div>
      <div class="col-12 d-flex">
        <br />
        <b-button class="orange-btn" size="m" :disabled="cuponAplicado"
          @click="validarCupon(nombreCuponDescuento)">Aplicar</b-button>
      </div>
    </div>

    <div class="row mt-1">
      <div class="col-md-6">
        <h4 class="cardD">Total</h4>
      </div>
      <div class="col-md-6">
        <h4 class="cardD">{{ formattedTotal }}</h4>
      </div>
    </div>

    <label class="mb-12 leyenda">
      Esta es una plataforma automática, no sustituye a una asesoría legal.
    </label>

    <div class="mt-4">
      <div class="">
        <b-button type="submit" class="btn-fill orange-btn bottom2" size="m"
          :disabled="formalizacion.disabled">Pagar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  CuponesApi,
  FormalizacionActasApi
} from "@/api";

import { formatCurrency } from "@/utils/number-utils";

export default {
  name: "Cotizacion",

  props: {
    formalizacion: { type: Object },
    cat_gastos: { type: Array }
  },

  computed: {
    formattedTotal() {
      return formatCurrency(this.formalizacion.precio_total);
    },
  },

  async created() {
    if (!this.formalizacion.cotizacion) {
      this.formalizacion.cotizacion = {
        cupones_id: 0
      }
    }

    this.getOptionsGastos();

    if (this.formalizacion.firma_fuera_oficina == 0) {
      this.firmaFueraOficina = false;
    } else if (this.formalizacion.firma_fuera_oficina == 1) {
      this.firmaFueraOficina = true;
    }
    // if (this.formalizacion.firma_electronica == 0) {
    //   this.firmaElectronica = false;
    // } else if (this.formalizacion.firma_electronica == 1) {
    //   this.firmaElectronica = true;
    // }
    if (this.formalizacion.entregable_adicional == 0) {
      this.entregablesAdicionales = false;
    } else if (this.formalizacion.entregable_adicional == 1) {
      this.entregablesAdicionales = true;
    }

    // await this.calculaDescuentoPrimeraVez();
  },

  data() {
    return {
      loading: false,

      costo_aumento_disminucion_capital_5mdp: 0,
      costo_aumento_disminucion_capital_fijo_20mdp: 0,
      costo_aumento_disminucion_capital_variable_20mdp: 0,
      costo_ingreso_accionistas: 0,
      costo_ingreso_capital_extranjero: 0,
      costo_modifica_estatutos: 0,
      costo_cambio_domicilio: 0,
      costo_cambio_denominacion: 0,
      costo_cambio_duracion: 0,
      costo_cambio_objeto_social: 0,
      costo_reforma_total_estatutos: 0,
      costo_disolucion: 0,
      costo_revocacion_poder: 0,
      costos_revocaciones_poder: 0,
      costo_nombramiento_adicional: 0,

      costo_entregable_adicional: 0,
      costo_firma_fuera_oficina: 0,
      // costo_firma_electronica: 0,

      subtotal_resumen: 7000,

      icon_aumento_disminucion_capital_5mdp: "x",
      icon_aumento_disminucion_capital_fijo_20mdp: "x",
      icon_aumento_disminucion_capital_variable_20mdp: "x",
      icon_ingreso_accionistas: "x",
      icon_ingreso_capital_extranjero: "x",
      icon_modifica_estatutos: "x",
      icon_cambio_domicilio: "x",
      icon_cambio_denominacion: "x",
      icon_cambio_duracion: "x",
      icon_cambio_objeto_social: "x",
      icon_reforma_total_estatutos: "x",
      icon_disolucion: "x",
      icon_revocacion_poder: "x",
      icon_nombramiento_adicional: "x",

      precio_aumento_disminucion_capital_5mdp: 0,
      precio_aumento_disminucion_capital_fijo_20mdp: 0,
      precio_aumento_disminucion_capital_variable_20mdp: 0,
      precio_ingreso_accionistas: 0,
      precio_ingreso_capital_extranjero: 0,
      precio_modifica_estatutos: 0,
      precio_cambio_domicilio: 0,
      precio_cambio_denominacion: 0,
      precio_cambio_duracion: 0,
      precio_cambio_objeto_social: 0,
      precio_reforma_total_estatutos: 0,
      precio_disolucion: 0,
      precio_revocacion_poder: 0,
      precio_nombramiento_adicional: 0,

      subtotal_adicionales: 0,

      num_funcionarios_revocados: 0,

      // subtotal_entregable_adicional: 0,
      // subtotal_firma_fuera_oficina: 0,
      // subtotal_firma_electronica: 0,
      subtotal_extras: 0,

      gastos_envio: true,
      subtotal_gastos: 0,

      firmaFueraOficina: null,
      firmaElectronica: null,
      entregablesAdicionales: null,

      // Cupones
      cuponAplicado: false,
      nombreCuponDescuento: "",
    };
  },

  methods: {
    getOptionsGastos() {
      this.costo_aumento_disminucion_capital_5mdp = this.cat_gastos.find(
        x => x.clave === "aumento_disminucion_capital_5mdp"
      ).costo;
      this.costo_aumento_disminucion_capital_fijo_20mdp = this.cat_gastos.find(
        x => x.clave === "aumento_disminucion_capital_fijo_20mdp"
      ).costo;
      this.costo_aumento_disminucion_capital_variable_20mdp = this.cat_gastos.find(
        x => x.clave === "aumento_disminucion_capital_variable_20mdp"
      ).costo;
      this.costo_ingreso_accionistas = this.cat_gastos.find(
        x => x.clave === "ingreso_accionistas"
      ).costo;
      this.costo_ingreso_capital_extranjero = this.cat_gastos.find(
        x => x.clave === "ingreso_capital_extranjero"
      ).costo;
      this.costo_modifica_estatutos = this.cat_gastos.find(
        x => x.clave === "modifica_estatutos"
      ).costo;
      this.costo_cambio_domicilio = this.cat_gastos.find(
        x => x.clave === "cambio_domicilio"
      ).costo;
      this.costo_cambio_denominacion = this.cat_gastos.find(
        x => x.clave === "cambio_denominacion"
      ).costo;
      this.costo_cambio_duracion = this.cat_gastos.find(
        x => x.clave === "cambio_duracion"
      ).costo;
      this.costo_cambio_objeto_social = this.cat_gastos.find(
        x => x.clave === "cambio_objeto_social"
      ).costo;
      this.costo_reforma_total_estatutos = this.cat_gastos.find(
        x => x.clave === "reforma_total_estatutos"
      ).costo;
      this.costo_disolucion = this.cat_gastos.find(
        x => x.clave === "disolucion"
      ).costo;
      this.costo_revocacion_poder = this.cat_gastos.find(
        x => x.clave === "revocacion_poder"
      ).costo;
      this.costo_nombramiento_adicional = this.cat_gastos.find(
        x => x.clave === "nombramiento_adicional"
      ).costo;

      this.costo_entregable_adicional = this.cat_gastos.find(
        x => x.clave === "entregable_adicional"
      ).costo;
      this.costo_firma_fuera_oficina = this.cat_gastos.find(
        x => x.clave === "firma_fuera_oficina"
      ).costo;
      // this.costo_firma_electronica = this.cat_gastos.find(
      //   x => x.clave === "firma_electronica"
      // ).costo;

      if (this.formalizacion.firma_fuera_oficina == true) {
        this.formalizacion.subtotal_firma_fuera_oficina = this.costo_firma_fuera_oficina;
      } else {
        this.formalizacion.subtotal_firma_fuera_oficina = 0;
      }
      if (this.formalizacion.entregable_adicional == 1) {
        this.formalizacion.subtotal_entregable_adicional = this.costo_entregable_adicional;
      } else {
        this.formalizacion.subtotal_entregable_adicional = 0;
      }
      // if (this.formalizacion.firma_electronica == true) {
      //   this.formalizacion.subtotal_firma_electronica = this.costo_firma_electronica;
      // } else {
      //   this.formalizacion.subtotal_firma_electronica = 0;
      // }
      this.calculaCotizacion();
    },

    calculaCotizacion() {
      this.loading = true;
      let precio = 0;

      if (this.formalizacion.modifica_capital == 1) {
        if (this.formalizacion.monto_modificado <= 5000000) {
          precio += this.costo_aumento_disminucion_capital_5mdp;
          this.precio_aumento_disminucion_capital_5mdp = this.costo_aumento_disminucion_capital_5mdp;
          this.icon_aumento_disminucion_capital_5mdp = "✓";
        } else if (this.formalizacion.monto_modificado > 5000000) {
          if (this.formalizacion.capital_modificado == "Fijo") {
            let costo_modificacion =
              this.formalizacion.monto_modificado *
              this.costo_aumento_disminucion_capital_fijo_20mdp;
            precio += costo_modificacion;
            this.precio_aumento_disminucion_capital_fijo_20mdp = this.costo_aumento_disminucion_capital_fijo_20mdp;
            this.precio_aumento_disminucion_capital_fijo_20mdp = precio;
            this.icon_aumento_disminucion_capital_fijo_20mdp = "✓";
          } else if (this.formalizacion.capital_modificado == "Variable") {
            let costo_modificacion =
              this.formalizacion.monto_modificado *
              this.costo_aumento_disminucion_capital_variable_20mdp;
            precio += costo_modificacion;
            this.precio_aumento_disminucion_capital_fijo_20mdp = precio;
            this.icon_aumento_disminucion_capital_fijo_20mdp = "✓";
          }
        }
      }
      if (this.formalizacion.nuevos_accionistas == 1) {
        precio += this.costo_ingreso_accionistas;
        this.precio_ingreso_accionistas = this.costo_ingreso_accionistas;
        this.icon_ingreso_accionistas = "✓";
      }
      if (this.formalizacion.capital_extranjero == 1) {
        precio += this.costo_ingreso_capital_extranjero;
        this.precio_ingreso_capital_extranjero = this.costo_ingreso_capital_extranjero;
        this.icon_ingreso_capital_extranjero = "✓";
      }
      if (this.formalizacion.modifica_estatutos == 1) {
        precio += this.costo_modifica_estatutos;
        this.precio_modifica_estatutos = this.costo_modifica_estatutos;
        this.icon_modifica_estatutos = "✓";
      }
      if (this.formalizacion.modifica_domicilio_social == 1) {
        precio += this.costo_cambio_domicilio;
        this.precio_cambio_domicilio = this.costo_cambio_domicilio;
        this.icon_cambio_domicilio = "✓";
      }
      if (this.formalizacion.modifica_denominacion_social == 1) {
        precio += this.costo_cambio_denominacion;
        this.precio_cambio_denominacion = this.costo_cambio_denominacion;
        this.icon_cambio_denominacion = "✓";
      }
      if (this.formalizacion.modifica_duracion_sociedad == 1) {
        precio += this.costo_cambio_duracion;
        this.precio_cambio_duracion = this.costo_cambio_duracion;
        this.icon_cambio_duracion = "✓";
      }
      if (this.formalizacion.modifica_objeto_social == 1) {
        precio += this.costo_cambio_objeto_social;
        this.precio_cambio_objeto_social = this.costo_cambio_objeto_social;
        this.icon_cambio_objeto_social = "✓";
      }
      if (this.formalizacion.reforma_total == 1) {
        precio += this.costo_reforma_total_estatutos;
        this.precio_reforma_total_estatutos = this.costo_reforma_total_estatutos;
        this.icon_reforma_total_estatutos = "✓";
      }
      if (this.formalizacion.disolucion_sociedad == 1) {
        precio += this.costo_disolucion;
        this.precio_disolucion = this.costo_disolucion;
        this.icon_disolucion = "✓";
      }

      // //que onda aca con revocaciones y nombramientos adicionales c/U
      if (this.formalizacion.revoca_funcionarios == 1) {
        let precioadd =
          this.formalizacion.num_funcionarios_revocados *
          this.costo_revocacion_poder;
        precio += precioadd;
        this.costos_revocaciones_poder = precioadd;
      }
      // if (this.formalizacion. == 1) {
      //   precio += this.costo_nombramiento_adicional;
      // }

      this.subtotal_adicionales = precio;

      // this.setEntregableAdicional();
      // this.setFirmaElectronica();
      // this.setFirmaOficina();
      this.calculaSubtotalExtras();
      this.loading = false;
    },

    setEntregableAdicional() {
      if (this.entregablesAdicionales == 1) {
        this.formalizacion.subtotal_entregable_adicional = this.costo_entregable_adicional;
      } else {
        this.formalizacion.subtotal_entregable_adicional = 0;
      }
      this.calculaSubtotalExtras();
    },
    setFirmaOficina() {
      if (this.firmaFueraOficina == true) {
        this.formalizacion.subtotal_firma_fuera_oficina = this.costo_firma_fuera_oficina;
      } else {
        this.formalizacion.subtotal_firma_fuera_oficina = 0;
      }
      this.calculaSubtotalExtras();
    },
    // setFirmaElectronica() {
    //   if (this.firmaElectronica == true) {
    //     this.formalizacion.subtotal_firma_electronica = this.costo_firma_electronica;
    //   } else {
    //     this.formalizacion.subtotal_firma_electronica = 0;
    //   }
    //   this.calculaSubtotalExtras();
    // },
    calculaSubtotalExtras() {
      this.subtotal_extras =
        this.formalizacion.subtotal_entregable_adicional +
        this.formalizacion.subtotal_firma_fuera_oficina;
      // this.formalizacion.subtotal_firma_electronica;
      this.calculaTotal();
    },

    calculaTotal() {
      let total =
        this.subtotal_resumen +
        this.subtotal_adicionales +
        this.subtotal_extras;

      //cupones cuando se habilite
      // let totalComma = this.commaNumber(total);
      // this.formalizacion.precio_total = totalComma;
      this.formalizacion.precio_total = total;

      // // if (!this.constitutiva.precio_total) {
      // //   this.constitutiva.precio_total = totalComma;
      // // }
    },
    commaNumber(n) {
      return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    async calculaDescuentoPrimeraVez() {
      if (this.formalizacion.cotizacion != null) {
        if (this.formalizacion.cotizacion.cupones_id != null) {
          let total =
            this.subtotal_resumen +
            this.subtotal_adicionales +
            this.subtotal_extras +
            this.subtotal_gastos;
          if (this.formalizacion.cotizacion.cupon_info.tipo == "Porcentaje") {
            let precio_total =
              total * (1 - this.formalizacion.cotizacion.cupon_info.descuento);
            let totalComma = this.commaNumber(precio_total);
            this.formalizacion.precio_total = totalComma;
          } else if (this.formalizacion.cotizacion.cupon_info.tipo == "Monto") {
            let precio_total =
              total - this.formalizacion.cotizacion.cupon_info.descuento;
            let totalComma = this.commaNumber(precio_total);
            this.formalizacion.precio_total = totalComma;
          }
        }
      }
    },

    async validarCupon(texto_cupon) {
      try {
        const time_zone = -(new Date().getTimezoneOffset() / 60);
        const data = {
          nombre: texto_cupon,
          timeZone: time_zone
        };
        const { isValid, id, tipo, descuento } = await CuponesApi.cuponValido(data);

        if (!isValid) {
          this.$toast.warning("El cupón no es válido", {
            timeout: 4000, //false,
            hideProgressBar: false,
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: "button",
            icon: true
          });

          return;
        }

        let total =
          this.subtotal_resumen +
          this.subtotal_adicionales +
          this.subtotal_extras +
          this.subtotal_gastos;

        let precio_total = 0;
        if (tipo === "Porcentaje" && descuento < 1) {
          let descuentoDecimal = descuento;
          if (descuento > 1) {
            descuentoDecimal = descuento / 100;
          }
          precio_total = total * (1 - descuentoDecimal);
        } else if (tipo == "Monto") {
          precio_total = total - descuento;
        }

        this.formalizacion.precio_total = precio_total;
        this.formalizacion.cotizacion.cupones_id = id;
        this.cuponAplicado = true;

        const formalizacionResponse = await FormalizacionActasApi.detail(this.formalizacion.id);

        this.formalizacion = {
          ...this.formalizacion,
          ...formalizacionResponse,
          activo: 1
        }
        this.formalizacion.cotizacion = {
          id: this.formalizacion.cotizacion.id,
          cupones_id: id,
          precio_total: this.formalizacion.precio_total
        }

        await FormalizacionActasApi.edit(this.formalizacion.id, this.formalizacion);

        this.$toast.success("El cupón se aplicó correctamente", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
      } catch {
        this.$toast.warning("Hubo un error al aplicar el cupón.", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
      }
    }
  }
};
</script>

<style scoped>
.blue {
  background-color: #effbff;
  border-color: #effbff !important;
  text-align: justify;
}

.leyenda {
  text-transform: lowercase !important;
  color: #79cff7;
}

.leyenda:first-letter {
  text-transform: capitalize;
}

.grey {
  color: #363535 !important;
  margin: 0 !important;
  padding-top: 0 !important;
  /* padding-bottom: 0 !important; */
}

.bottom2 {
  float: right;
}
</style>
