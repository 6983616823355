// API
import BaseApi from './base';

class CatFacultadesApi extends BaseApi {
  constructor() {
    super('catFacultades');
  }
}

export default new CatFacultadesApi();
