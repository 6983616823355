<template>
  <div>
    <b-tabs card vertical>
      <b-tab title="Bienvenida Staff" active @click="getLayout(1)">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(saveLayout)">
            <LayoutEmail :layout="layout" @id="deleteFile" />
          </form>
        </ValidationObserver>
      </b-tab>
      <b-tab title="Bienvenida Cliente Administrador" @click="getLayout(2)">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(saveLayout)">
            <LayoutEmail :layout="layout" @id="deleteFile" />
          </form>
        </ValidationObserver>
      </b-tab>
      <b-tab title="Bienvenida Cliente Usuario" @click="getLayout(3)">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(saveLayout)">
            <LayoutEmail :layout="layout" @id="deleteFile" />
          </form>
        </ValidationObserver>
      </b-tab>
      <b-tab title="Verificación de cuenta" @click="getLayout(4)">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(saveLayout)">
            <LayoutEmail :layout="layout" @id="deleteFile" />
          </form>
        </ValidationObserver>
      </b-tab>
      <b-tab title="Reestablecer contraseña" @click="getLayout(5)">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(saveLayout)">
            <LayoutEmail :layout="layout" @id="deleteFile" />
          </form>
        </ValidationObserver>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import LayoutEmail from "src/views/staff/settings/LayoutEmail.vue";

export default {
  components: {
    LayoutEmail,
  },

  props: {
    layout: {
      type: Object,
    },
  },

  data() {
    return {
      loading: false,

      form: {
        body: null,
        file: null,
        header: null,
        // footer: null,
        subject: null,
        id: null,
      },
    };
  },

  methods: {
    async getLayout(id) {
      this.$emit("getLayout", id);
    },

    async deleteFile(id) {
      this.$emit("deleteFile", id);
    },

    async saveLayout(id) {
      this.$emit("saveLayout", id);
    },
  },
};
</script>

<style scoped></style>
