// API
import BaseApi from './base';

class CatObjetosSocialesApi extends BaseApi {
  constructor() {
    super('catObjetosSociales');
  }
}

export default new CatObjetosSocialesApi();
