<template>
  <div class="content">
    <b-breadcrumb>
      <b-breadcrumb-item active>Comparecientes</b-breadcrumb-item>
    </b-breadcrumb>

    <div class="container">
      <div v-if="loading">
        <div id="loader">
          <font-awesome-icon icon="spinner" spin />
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-4">
          <base-input v-model="searchCompareciente" placeholder="Buscar compareciente"></base-input>
        </div>

        <!-- <div class="col-4" v-if="rol === 'Staff'">
          <b-form-select
            v-model="comercialSearch"
            @change="getComparecientesPorComercial($event)"
            class="custom-select"
            :options="optionsComerciales"
          >
          </b-form-select>
        </div> -->

        <div class="col-12">
          <card class="strpied-tabled-with-hover" body-classes="table-full-width table-responsive">
            <!-- <vue-confirm-dialog></vue-confirm-dialog> -->
            <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog" hide-footer id="modal-confirm-dialog">
              <h4 class="text-center">
                ¿Seguro que quiere eliminar al compareciente?
              </h4>
              <h5 class="text-center">Se eliminarán todos los datos.</h5>
              <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog')">Cancelar</b-button>
              <b-button class="mt-3 float-right" variant="primary" @click="deleteCompareciente()">OK</b-button>
            </b-modal>

            <b-modal no-close-on-backdrop centered ref="modal-confirm-clon" hide-footer id="modal-confirm-clon">
              <h4 class="text-center">
                ¿Quiere clonar este compareciente?
              </h4>
              <h5 class="text-center">
                Para poder editarlo es necesario clonarlo, ya que pertenece a un
                servicio terminado.
              </h5>
              <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-clon')">Cancelar</b-button>
              <b-button class="mt-3 float-right" variant="primary" @click="postClonarCompareciente()">OK</b-button>
            </b-modal>

            <!-- <b-button id="popover-button-sync" variant="secondary">
              <i class="fa fa-filter"></i>
            </b-button>
            <b-popover target="popover-button-sync" title="" triggers="focus">
              <b-form-select
                v-model="comercialSearch"
                @change="getComparecientesPorComercial($event)"
                class="custom-select"
                :options="optionsComerciales"
              >
              </b-form-select>
            </b-popover>-->

            <!-- <div class="text-center">
              <ve-table
                class="veTable"
                rowKeyFieldName="rowKey"
                :columns="columns"
                :table-data="comparecientes"
              />
            </div> -->

            <!-- <table>
              <tr>
                <th>
                  Nombre comercial
                  <b-button
                    class="btn-srch"
                    id="popover-button-sync"
                    variant="secondary"
                    size="sm"
                  >
                    <i class="fa fa-filter"></i>
                  </b-button>
                  <b-popover
                    target="popover-button-sync"
                    title=""
                    triggers="focus"
                  >
                    <b-form-select
                      v-model="comercialSearch"
                      @change="getComparecientesPorComercial($event)"
                      class="custom-select"
                      :options="optionsComerciales"
                    >
                    </b-form-select>
                  </b-popover>
                </th>
              </tr>
            </table> -->

            <div class="row">
              <div class="col-3"></div>
              <div class="col-1">
                <b-button class="btn-srch" id="popover-button-sync" variant="secondary" size="sm">
                  <i class="fa fa-filter"></i>
                </b-button>
                <b-popover target="popover-button-sync" title="" triggers="focus">
                  <b-form-select v-model="comercialSearch" @change="getComparecientesPorComercial($event)"
                    class="custom-select" :options="optionsComerciales">
                  </b-form-select>
                </b-popover>
              </div>
            </div>

            <div class="text-center">
              <b-table :fields="fields" :items="comparecientes" hover>
                <template #cell(index)="{ index }">
                  {{ getIndex(index + 1) }}
                </template>
                <template #cell(acciones)="{ item }">
                  <!-- <router-link
                    :to="{
                      path:
                        '/dashboard/comparecientes/editcomparecientes/' +
                        item.id
                    }"
                  > -->
                  <b-button variant="warning" size="sm" @click="clonaCompareciente(item.id)" v-b-tooltip.hover
                    title="Editar" class="editBtn">
                    <b-icon-pencil />
                  </b-button>
                  <!-- </router-link> -->

                  <b-button variant="danger" @click="predeleteCompareciente(item.id)" size="sm" v-b-tooltip.hover
                    title="Eliminar">
                    <b-icon-trash />
                  </b-button>
                </template>
              </b-table>
            </div>

            <div class="d-flex justify-content-between pages">
              <b-pagination v-model="currentPage" :per-page="perPage" :total-rows="totalComparecientes" />

              <span>Mostrando {{ comparecientes.length }} registros de
                {{ totalComparecientes }}</span>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import LTable from "src/components/Table.vue";
import Card from "src/components/Cards/Card.vue";
import { ComparecientesApi } from "@/api";
import { ClientesApi } from "@/api";
import { FuncionesApi } from "@/api";
import Storage from "@/utils/storage";
const storage = new Storage();

export default {
  name: "Comparecientes",

  // data: () => ({
  data() {
    return {
      loading: false,
      currentPage: 1,
      perPage: 10,
      totalComparecientes: 0,
      searchCompareciente: null,
      comercialSearch: null,
      optionsComerciales: [], //this.getComerciales(), //
      // rol: null,
      comparecientes: [],
      currentComparecienteId: null,
      clonaComparecienteId: null

      // columns: [
      //   {
      //     field: "",
      //     key: "a",
      //     title: "",
      //     // width: "10%",
      //     align: "center",
      //     renderBodyCell: ({ row, column, rowIndex }, h) => {
      //       return ++rowIndex;
      //     }
      //   },
      //   {
      //     field: "persona",
      //     key: "b",
      //     title: "Persona",
      //     align: "left"
      //     // width: "15%",
      //   },
      //   {
      //     field: "nombre_comercial",
      //     key: "c",
      //     title: "Nombre comercial",
      //     align: "left",
      //     // width: "15%",
      //     // filter
      //     filter: {
      //       filterList: [
      //         //             for(let i=0;i<optionsComerciales.length;i++){
      //         //  { value: 0, label: "John", selected: false };
      //         //             }
      //         // { value: "Btob", label: "Btob", selected: false },
      //         // {
      //         //   value: "Gamesa",
      //         //   label: "Gamesa",
      //         //   selected: false,
      //         // },
      //         // { value: "GI", label: "GI", selected: false },
      //         // { value: "GIW", label: "GIW", selected: false },
      //         // {
      //         //   value: "Kwat Free Diouh",
      //         //   label: "Kwat Free Diouh",
      //         //   selected: false,
      //         // },
      //         // { value: "Nestle", label: "Nestle", selected: false },
      //         // { value: "Reestak", label: "Reestak", selected: false },
      //         // { value: "Telcel", label: "Telcel", selected: false },
      //       ],
      //       // filterList: optionsComerciales,
      //       isMultiple: false,
      //       filterConfirm: filterList => {
      //         const labels = filterList
      //           .filter(x => x.selected)
      //           .map(x => x.label);
      //         this.getComparecientesPorComercial(labels);
      //       },
      //       filterReset: filterList => {
      //         this.getComparecientes([]);
      //       }
      //       // maxHeight: 120,
      //     }
      //   },

      //   {
      //     field: "compareciente",
      //     key: "d",
      //     title: "Compareciente",
      //     align: "center"
      //     // width: "30%",
      //   },
      //   {
      //     field: "rfc",
      //     key: "e",
      //     title: "RFC",
      //     align: "left"
      //     // width: "10%",
      //   },
      //   {
      //     field: "acciones",
      //     key: "f",
      //     title: "Acciones",
      //     align: "left",
      //     // width: "20%",
      //     renderBodyCell: ({ row, column, rowIndex }, h) => {
      //       return (
      //         <span>
      //           <b-button
      //             variant="warning"
      //             on-click={() => this.editCompareciente(rowIndex)}
      //             size="sm"
      //             title="Editar"
      //             class="editBtn"
      //           >
      //             <b-icon-pencil />
      //           </b-button>
      //
      //           <b-button
      //             variant="danger"
      //             on-click={() => this.predeleteCompareciente(rowIndex)}
      //             size="sm"
      //             title="Eliminar"
      //           >
      //             <b-icon-trash />
      //           </b-button>
      //         </span>
      //         //  on-click={() => this.predeleteCompareciente(rowIndex)}
      //         //  v-b-modal.modal-confirm-dialog
      //         // this.deleteRow(rowIndex)
      //       );
      //     }
      //   }
      // ]
    };
  },

  computed: {
    fields() {
      let fields = [
        { key: "index", label: "", class: "text-center" },
        { key: "persona", label: "Persona", class: "text-center" },
        {
          key: "nombre_comercial",
          label: "Nombre comercial",
          class: "text-center"
        },
        { key: "compareciente", label: "Compareciente", class: "text-center" },
        { key: "rfc", label: "RFC", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" }
      ];
      return fields;
    }
  },

  watch: {
    currentPage: "getComparecientes",
    searchCompareciente: "getComparecientes"
  },

  components: {
    // LTable,
    Card
  },

  created() {
    this.getComparecientes();
    this.getComerciales();
  },

  methods: {
    getIndex(index) {
      return index + (this.currentPage - 1) * this.perPage;
    },

    async getComparecientes() {
      this.loading = true;

      var clienteEmail = storage.getItem("user");
      const { id } = await ClientesApi.clienteId(clienteEmail);

      let clientes_id = 0;
      if (id != 0) {
        //se supone que esto estaría en 0 cuando el staff admin pide los comparecientes y ellos no van a tener cliente_id
        clientes_id = id;
      }

      try {
        const {
          odata_page: page,
          odata_total: total,
          odata_perpage: perPage,
          // clientes_id: clientes_id,
          value
        } = await ComparecientesApi.myList(clientes_id, {
          page: this.currentPage,
          data: this.searchCompareciente,
          registersPerPage: this.perPage,
          visible: 1
        });

        this.currentPage = page;
        this.totalComparecientes = total;

        for (let i = 0; i < value.length; i++) {
          var { nombre_comercial } = await ClientesApi.clienteNombreComercial(
            value[i].cliente_id
          );
          value[i].nombre_comercial = nombre_comercial;

          if (
            value[i].persona == "Moral" ||
            value[i].persona == "Fideicomiso"
          ) {
            value[i].compareciente = value[i].denominacion_social;
          } else if (value[i].persona == "Física") {
            var nombreTotal = `${value[i].nombre} ${value[i].paterno} ${value[i].materno
              }`;
            value[i].compareciente = nombreTotal;
          }
        }
        this.comparecientes = value;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async getComerciales() {
      try {
        var value2 = await ClientesApi.allComerciales({});
        this.optionsComerciales[0] = "Todos los nombres comerciales";
        value2.forEach(element => {
          this.optionsComerciales.push(element);
        });

        // for (let i = 0; i < value2.length; i++) {
        //   const obj = { value: value2[i], label: value2[i], selected: false };
        //   var a = Object.assign({}, obj);
        //   this.columns[2].filter.filterList.push(a); //obj
        // }

        // value2.forEach((element) => {
        //   // this.optionsComerciales.push(element);
        //   const obj = { value: element, label: element, selected: false };
        //   var a = Object.assign({}, obj);
        //   this.columns[2].filter.filterList.push(a); //obj
        //   // this.optionsComerciales.push(a);
        // });
      } catch (error) {
        // this.loading = false;
      }
    },

    async getComparecientesPorComercial(nombreComercial) {
      this.loading = true;
      if (nombreComercial === "Todos los nombres comerciales") {
        await this.getComparecientes();
      } else {
        try {
          const {
            odata_page: page,
            odata_total: total,
            odata_perpage: perPage,
            value
          } = await ComparecientesApi.compPorComercial(nombreComercial, {
            //this.comercialSearch
            page: this.currentPage,
            data: this.searchCompareciente,
            registersPerPage: this.perPage
          });
          this.currentPage = page;
          this.totalComparecientes = total;

          for (let i = 0; i < value.length; i++) {
            var { nombre_comercial } = await ClientesApi.clienteNombreComercial(
              value[i].cliente_id
            );
            value[i].nombre_comercial = nombre_comercial;

            if (
              value[i].persona == "Moral" ||
              value[i].persona == "Fideicomiso"
            ) {
              value[i].compareciente = value[i].denominacion_social;
            } else if (value[i].persona == "Física") {
              var nombreTotal = `${value[i].nombre} ${value[i].paterno} ${value[i].materno
                }`;
              value[i].compareciente = nombreTotal;
            }
          }

          this.comparecientes = value;
          this.loading = false;
        } catch (error) {
          this.loading = false;
          // }
        }
      }
    },

    // editCompareciente(rowIndex) {
    //   const id = this.comparecientes[rowIndex].id;
    //   this.$router.push(`/dashboard/comparecientes/editcomparecientes/${id}`);
    // },

    predeleteCompareciente(rowIndex) {
      const id = this.comparecientes[rowIndex].id;
      this.currentComparecienteId = id;
      this.$refs["modal-confirm-dialog"].show();
    },

    async deleteCompareciente() {
      this.loading = true;
      try {
        let id = this.currentComparecienteId;
        await ComparecientesApi.delete(id);
        var index = this.comparecientes.findIndex(function (o) {
          return o.id === id;
        });
        this.comparecientes.splice(index, 1);
        this.totalComparecientes--;

        this.$toast.success("Compareciente eliminado", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
        this.$refs["modal-confirm-dialog"].hide();

        this.loading = false;
      } catch (error) {
        this.loading = false;
        if (error.status === 409) {
          this.$toast.warning(
            "No se puede eliminar al compareciente porque es parte de un proceso",
            {
              timeout: 4000, //false,
              hideProgressBar: false,
              position: "top-right",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              closeButton: "button",
              icon: true
            }
          );
        } else {
          this.$toast.error("Sucedió un error, intente más tarde", {
            timeout: 4000, //false,
            hideProgressBar: false,
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: "button",
            icon: true
          });
        }
        this.$refs["modal-confirm-dialog"].hide();
      }
      this.$refs["modal-confirm-dialog"].hide();
    },

    async clonaCompareciente(comparecientes_id) {
      try {
        var value = await FuncionesApi.clonDecision(comparecientes_id, {});
        if (value == 1) {
          this.clonaComparecienteId = comparecientes_id;
          this.$refs["modal-confirm-clon"].show();
        } else {
          //continua a ruta normal
          this.$router.push(
            `/dashboard/comparecientes/editcomparecientes/${comparecientes_id}`
          );
        }
      } catch (error) {
        this.$toast.error("Sucedió un error, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
      }
    },

    async postClonarCompareciente() {
      try {
        //clono compareciente y cacho id
        const { id } = await ComparecientesApi.clonaCompareciente(
          this.clonaComparecienteId,
          {
            comparecientes_id: this.clonaComparecienteId
          }
        );
        if (id) {
          this.$toast.success("Compareciente clonado", {
            timeout: 4000, //false,
            hideProgressBar: false,
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: "button",
            icon: true
          });
          this.$router.push(
            `/dashboard/comparecientes/editcomparecientes/${id}`
          );
          this.$refs["modal-confirm-clon"].close();
        } else {
          this.$toast.error("Sucedió un error, intente más tarde", {
            timeout: 4000, //false,
            hideProgressBar: false,
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: "button",
            icon: true
          });
        }
      } catch { }
    }
  }
};
</script>
<style scoped>
.editBtn {
  margin-right: 10px;
}

.text-center {
  text-align: center !important;
}

.pages {
  margin-left: 2%;
}

.btn-srch {
  border: none !important;
  margin-bottom: -20px;
  margin-left: 10px;
}

/* .th {
  font-size: 12px;
  text-transform: uppercase;
  color: #9a9a9a;
  font-weight: 400;
} */
/* veTable {
  background-color: red !important; */
/* } */
/* th::ve-table-deep ve-table-header
::table-deep th {
  background-color: red !important;
} */
</style>
