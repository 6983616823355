<template>
  <div class="container">

    <b-modal no-close-on-backdrop centered ref="documento-modal-preview" title="" hide-footer id="modaldf" size="xl">
      <ModalPreviewPDF :currentDocumento="currentDocumento" />
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="modal-regenerate" hide-footer id="modal-regenerate">
      <h4 class="text-center">
        ¿Está seguro de regenerar los KYCs? Los existentes serán eliminados.
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-regenerate')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="generaKycs()">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-fad" hide-footer id="modal-confirm-fad">
      <h4 class="text-center">
        ¿Está seguro de enviar a FAD? El cliente será notificado.
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-fad')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="sendToFad()">OK</b-button>
    </b-modal>

    <h4 class="cardD">FAD</h4>

    <b-table ref="table" hover :fields="fieldsCuadro" :items="Kycs">
      <template #cell(acciones)="{ item }">
        <b-button variant="primary" size="sm" v-b-tooltip.hover title="Vista previa" @click="openPreviewModal(item)"
          class="editBtn">
          <b-icon-eye />
        </b-button>
      </template>
    </b-table>

    <div class="mt-4">
      <div class="">
        <b-button class="btn-fill green-btn bottom1" size="m" @click="openModalRegenerate()">Regenerar KYCs</b-button>
      </div>
    </div>
    <div class="mt-4">
      <div class="">
        <b-button class="btn-fill orange-btn bottom2" size="m" :disabled="parseInt(ratificacion.estatus_id) > 39"
          @click="openSendToFadModal()">
          Enviar a FAD
        </b-button>
      </div>
    </div>
    <div class="mt-4">
      <div class="">
        <b-button @click="$emit('checkFadStatus')" class="btn-fill orange-btn bottom2" size="m"
          :disabled="parseInt(ratificacion.estatus_id) !== 68 || sentToFad">Verificar firma</b-button>
      </div>
    </div>

    <div hidden>
      <div v-for="compareciente in ratificacion.ratificantes" :key="compareciente.id">
        <KycTemplate :id="compareciente.id" :currentCompareciente="compareciente" :beneficiario_pld=2
          :beneficiario_cff=4 />
      </div>
    </div>
  </div>
</template>

<script>
// Modales
import ModalPreviewPDF from "@/views/clients/procesos/constitucion/entregadocumentos/ModalPreviewPDF.vue";
import KycTemplate from "@/views/staff/tasks/validaciones/constitucionsociedades/KycTemplate.vue";


// API
import { DocumentosXServiciosApi } from "@/api";
import { DocumentosApi } from "@/api";
import { FuncionesApi } from "@/api";

export default {
  name: 'FAD',

  components: {
    ModalPreviewPDF,
    KycTemplate
  },

  props: {
    ratificacion: { type: Object }
  },

  computed: {
    fieldsCuadro() {
      let fieldsCuadro = [
        { key: "index", label: "", class: "text-center" },
        { key: "nombre", label: "Nombre", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" },
      ];
      return fieldsCuadro;
    }
  },

  created() {
    this.loading = true;
    this.selectKycFromDocumentos()
      .then(() => this.generaPrimerosKYC())
      .catch((error) => {
        console.log(error);
        this.showErrorToast('No se pudieron obtener los documentos. Intente más tarde.')
      })
      .finally(() => { this.loading = false; })
  },

  data() {
    return {
      selectedDocumento: null,
      currentDocumento: null,
      currentDocumentoId: null,

      Kycs: [],

      style_pdf: `<style>
        .new-page {
            page-break-before: always;
          }
          .logo {
            width: auto;
            height: 100px;
            float: right;
          }
          .tabla {
            width: 100%;
            font-size: 15px;
            font-family: Arial, Helvetica, sans-serif;
            border-collapse: separate;
            border-spacing: 0 1em;
          }
          .section {
            background-color: #4BACC6;
            color: white;
            text-align: center;
            height: 40px;
            font-weight: bolder;
          }
          .firstline {
            border: 5px solid white;
          }
          .title {
            font-size: 12px;
            text-align: left;
            width: 20%;
            font-weight: bold;
          }
          .select {
            text-align: left;
            width: 5%;
          }
          .circle {
            border-radius: 100%;
            height: 15px;
            width: 15px;
            border: 2px solid #4BACC6;
            margin-bottom: 0px;
          }
          .filledcircle {
            border-radius: 100%;
            height: 15px;
            width: 15px;
            border: 2px solid #4BACC6;
            background-color: #4BACC6;
            margin-bottom: 0px;
          }
          .verifycheck {
            border: 2px solid #4BACC6;
            background-color: rgb(231, 238, 245);
            text-align: center;
          }
          .right-title {
            font-size: 12px;
            text-align: right;
            font-weight: bold;
          }
          .user {
            text-align: left;
            font-size: 12px;
            font-weight: lighter;
            border-bottom: solid 1px black;
            background-color: rgb(231, 238, 245);
          }
          .leyenda {
            font-size: 9px;
          }
        </style>`,

        sentToFad: false,
    }
  },

  methods: {
    showErrorToast(msg) {
      this.$toast.error(msg, {
        timeout: 4000, //false,
        hideProgressBar: false,
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        closeButton: "button",
        icon: true,
      });
    },

    openPreviewModal(item) {
      this.currentDocumento = item;
      this.$refs["documento-modal-preview"].show();
    },

    closePreviewModal() {
      this.$refs["documentos-modal-preview"].hide();
    },

    openModalRegenerate() {
      this.$refs['modal-regenerate'].show();
    },

    closeModalRegenerate() {
      this.$refs['modal-regenerate'].hide();
    },

    openSendToFadModal() {
      this.$refs["modal-confirm-fad"].show();
    },

    hideSendToFadModal() {
      this.$refs["modal-confirm-fad"].hide();
    },

    selectKycFromDocumentos() {
      return new Promise(resolve => {
        this.Kycs = this.ratificacion.documentos.filter(doc =>
          parseInt(doc.cat_documentos_id) == 68);
        resolve(this.Kycs);
      })
    },

    generaPrimerosKYC() {
      if (this.Kycs.length == 0) {
        this.generaKycs();
      }
    },

    async generaKycs() {
      let comparecientes_data = [];

      for (let i = 0; i < this.ratificacion.ratificantes.length; i++) {
        let id_div = this.ratificacion.ratificantes[i].id;
        let html = document.getElementById(id_div).innerHTML;

        let styled_html = this.style_pdf + html;

        let full_html = `<!DOCTYPE html>
            <html>
            <head>
              <meta charset="utf-8">
            </head>
            <body>
            ${styled_html}
            </body>
            </html>
            `;

        var buffer = Buffer.from(full_html);
        var buffer_str = buffer.toString("base64");

        let compareciente_obj = {
          comparecientes_id: this.ratificacion.ratificantes[i].id,
          html: buffer_str
        };
        comparecientes_data.push(compareciente_obj);
      }

      let data = {
        proceso_id: this.ratificacion.ratificaciones_id,
        cat_servicios_id: 6,
        comparecientes: comparecientes_data
      };

      let documentos_kycs = await FuncionesApi.generaKyc(data);
      this.Kycs = documentos_kycs;

      this.$toast.success("KYCs generados exitosamente.", {
        timeout: 4000, //false,
        hideProgressBar: false,
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        closeButton: "button",
        icon: true,
      });

      location.reload();
    },

    async sendToFad() {
      this.hideSendToFadModal();

      this.loading = true;

      const dataFad = {
        proceso_id: this.ratificacion.ratificaciones_id,
        cat_servicios_id: 6
      }

      await FuncionesApi.sendKycsFad(dataFad);

      this.sentToFad = true;

      this.$toast.success("Documentos enviados a firma", {
        timeout: 4000, //false,
        hideProgressBar: false,
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        closeButton: "button",
        icon: true
      });
      this.loading = false;
      this.$emit('allChecksPassed');
    }
  }

}
</script>

<style scoped>
.bottom1 {
  float: left;
}

.green-btn {
  background-color: green;
}
</style>
