// API
import BaseApi from './base';

class StaffApi extends BaseApi {
  constructor() {
    super('staff');
  }

  staffId(email, params = {}) {
    return this.request.get(`/staff/id/${email}`, {
      params: {
        ...params
      }
    });
  }
}

export default new StaffApi();
