<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <ValidationProvider name="persona" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La persona es obligatoria',
        }">
          <label for="">Persona</label>
          <select class="custom-select" v-model="currentCompareciente.persona" :disabled="currentCompareciente.id">
            <option v-for="option in optionsPersona" :key="option.value" :value="option.value">
              {{ option.text }}
            </option>
          </select>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row mt-4" v-if="currentCompareciente.persona === 'Moral'">
      <div class="col-md-12">
        <ValidationProvider name="denominacion_social" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La denominacion social es obligatoria',
        }">
          <base-input type="text" label="Denominación social (Incluir tipo de sociedad)"
            placeholder="Denominacion social" v-model="currentCompareciente.denominacion_social"
            :disabled="currentCompareciente.existente">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row mt-4" v-else-if="currentCompareciente.persona === 'Física' && !currentCompareciente.existente">
      <div class="col-md-4">
        <ValidationProvider name="nombre" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El nombre es obligatorio',
        }">
          <base-input type="text" label="Nombre" placeholder="Nombre" v-model="currentCompareciente.nombre"
            :disabled="currentCompareciente.existente">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <ValidationProvider name="nombre" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El paterno es obligatorio',
        }">
          <base-input type="text" label="Paterno" placeholder="Paterno" v-model="currentCompareciente.paterno">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <base-input type="text" label="Materno" placeholder="Materno" v-model="currentCompareciente.materno">
        </base-input>
      </div>
    </div>

    <div class="row mt-4" v-if="currentCompareciente.persona === 'Física'">
      <div class="col-md-4" v-if="representadas.length > 0 && currentCompareciente.persona === 'Física'">
        <ValidationProvider name="representante" v-slot="{ errors }">
          <label class="control-label">Representante</label>
          <b-form-checkbox v-model="representante">
          </b-form-checkbox>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4" v-if="representante">
        <ValidationProvider name="representada" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La persona moral representada es obligatoria',
        }">
          <label for="">Persona representada</label>
          <select class="custom-select" v-model="currentCompareciente.representada"
            @change="agregaRepresentadanombre($event)">
            <option v-for="option in representadas" :key="option.id" :value="option.id">
              {{ option.nombreCompleto }}
            </option>
          </select>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row mt-4" v-if="currentCompareciente.persona === 'Física'">
      <div class="col-md-12">
        <ValidationProvider name="email" rules="required" v-slot="{ errors }"
          :custom-messages="{ required: 'El email es obligatorio' }">
          <h4 for="inputCorreo" class="cardD">Correo electrónico</h4>
          <base-input id="inputCorreo" type="email" placeholder="correo@dominio.com"
            v-model="currentCompareciente.email"></base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div v-if="currentCompareciente.persona === 'Física'">
      <span class="warningData">
        Ingrese un número de teléfono celular que será usado para enviarle un SMS como parte del proceso de firma
        electrónica
      </span>

      <h4 class="cardD">Teléfono</h4>
      <!-- <ModalTelefono :currentTelefono="currentCompareciente.telefono" :disableSaveButton="true" @submit.prevent /> -->

      <div class="col-md-12">
        <ValidationProvider name="numero" :rules="{ required: true, length: 10, regex: /^\d{10}$/ }" v-slot="{ errors }"
          :custom-messages="{
            required: 'El número es obligatorio',
            length: 'El número debe tener 10 dígitos',
            regex: 'El número no tiene el formato correcto'
          }">
          <base-input type="tel" label="Número" placeholder="Número" v-model="currentCompareciente.telefono.numero">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <!-- <b-modal
        centered
        ref="telefono-modal"
        title=""
        hide-footer
        id="modalTR"
        size="lg"
        >
        <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(editTelefono())">
            <ModalTelefono :currentTelefono="currentCompareciente.telefono" @submit.prevent />
            </form>
        </ValidationObserver>
        </b-modal>

        <h4 class="cardD" v-if="currentCompareciente.persona === 'Física'">
          Teléfonos
          <b-button
            variant="success"
            class="btn-fill"
            size="sm"
            @click="addTelefono()"
            >+</b-button
          >
        </h4> -->

    <div class="row mt-4"></div>
    <div class="mt-4 text-center">
      <button type="submit" class="btn btn-fill float-right orange-btn">
        Guardar
      </button>
    </div>
  </div>
</template>

<script>
import ModalTelefono from "@/views/clients/comparecientes/ModalTelefono.vue";

export default {
  props: {
    currentCompareciente: { type: Object },
    solicitantes: { type: Array }
  },

  components: {
    ModalTelefono,
  },

  created() {
    if (!this.currentCompareciente.telefono) {
      this.currentCompareciente.telefono = {};
    }

    this.currentCompareciente.telefono.tipo = "Celular";
    this.currentCompareciente.telefono.clave = 52;

    this.defineRepresentadas()
    this.checkRepresentante()
  },

  data() {
    return {
      optionsPersona: [
        { text: "Persona Física", value: "Física" },
        { text: "Persona Moral", value: "Moral" },
      ],
      representadas: [],
      representante: false,
    };
  },

  methods: {
    deshabilitaPrincipal() {
      if (this.representante) {
        this.currentCompareciente.principal = false;
      } else {
        this.currentCompareciente.representada = null;
        this.currentCompareciente.representadanombre = null;
      }
    },

    deshabilitaRepresentante() {
      if (this.currentCompareciente.principal) {
        this.representante = false;
        this.currentCompareciente.representada = null;
        this.currentCompareciente.representadanombre = null;
      }
    },

    defineRepresentadas() {
      this.solicitantes.forEach(solicitante => {
        const representantes = this.countRepresentantes(solicitante.id)
        let maxRrepresentantes = 1;
        if (solicitante.persona === 'Moral') {
          maxRrepresentantes = 2;
        }

        if ((!solicitante.principal &&
          !solicitante.representada &&
          representantes < maxRrepresentantes &&
          solicitante.id != this.currentCompareciente.id) ||
          this.currentCompareciente.representada == solicitante.id) {
          this.representadas.push(solicitante);
        }
      });
      this.checkNotSelf();
    },

    checkNotSelf() {
      let index = this.representadas.findIndex(
        (o) => o.id === this.currentCompareciente.id
      );
      if (index !== -1) {
        this.representadas.splice(index, 1);
      };
    },

    countRepresentantes(representadaId) {
      let representantes = 0;
      this.solicitantes.forEach(solicitante => {
        if (parseInt(solicitante.representada) == parseInt(representadaId)) {
          representantes += 1;
        }
      })
      return representantes;
    },

    checkRepresentante() {
      if (this.currentCompareciente.representada) {
        this.representante = true;
      }
    },

    agregaRepresentadanombre(event) {
      let index = this.solicitantes.findIndex(
        (o) => o.id == event.target.value
      );
      console.log(index)
      this.currentCompareciente.representadanombre = this.solicitantes[index].nombreCompleto;
    },

    addTelefono() {
      this.$refs["telefono-modal"].show();
      if (!(this.currentCompareciente.hasOwnProperty('telefono'))) {
        this.currentCompareciente.telefono = {};
      }
    },

    editTelefono() {
      this.$refs["telefono-modal"].hide();
    },
  }
}
</script>

<style scoped>
.cardErrors {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* color: #9a9a9a; */
  color: #ea640b;
  font-size: 13px;
}

.leyenda {
  text-transform: lowercase !important;
  color: #79cff7;
}

.leyenda:first-letter {
  text-transform: capitalize;
}
</style>
