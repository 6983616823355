import BaseApi from "./base";

class FacturasApi extends BaseApi {
  constructor() {
    super('facturas');
  }

  getFacturaByCotizacionId(cotizacionId) {
    return this.request.get(`/facturas/cotizaciones/${cotizacionId}`);
  }

  postDatosFiscalesByCliente(data, params = {}) {
    return this.request.post('/facturas/datos-fiscales', data, {
      params: {
        ...params
      }
    });
  }

  getDatosFiscalesByClienteId(clienteId) {
    return this.request.get(`/facturas/datos-fiscales/${clienteId}`);
  }

  putDatosFiscalesByClienteId(clienteId, data, params = {}) {
    return this.request.put(`/facturas/datos-fiscales/${clienteId}`, data, {
      params: {
        ...params
      }
    });
  }
}

export default new FacturasApi();
