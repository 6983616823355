var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"name":"persona","rules":"required","custom-messages":{
        required: 'La persona es obligatoria',
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{attrs:{"for":""}},[_vm._v("Persona")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentNotificado.persona),expression:"currentNotificado.persona"}],staticClass:"custom-select",attrs:{"disabled":_vm.currentNotificado.aceptado || _vm.notificacion.validaNotificado == 1},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.currentNotificado, "persona", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.optionsPersona),function(option){return _c('option',{key:option.value,domProps:{"value":option.value}},[_vm._v(" "+_vm._s(option.text)+" ")])}),0),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),(_vm.currentNotificado.persona === 'Moral')?_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"name":"denominacion_social","rules":"required","custom-messages":{
        required: 'La denominacion social es obligatoria',
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"disabled":_vm.currentNotificado.aceptado || _vm.notificacion.validaNotificado == 1,"type":"text","label":"Denominacion social","placeholder":"Denominacion social"},model:{value:(_vm.currentNotificado.razon_social),callback:function ($$v) {_vm.$set(_vm.currentNotificado, "razon_social", $$v)},expression:"currentNotificado.razon_social"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3395390629)})],1)]):(_vm.currentNotificado.persona === 'Física')?_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"nombre","rules":"required","custom-messages":{
        required: 'El nombre es obligatorio',
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"disabled":_vm.currentNotificado.aceptado || _vm.notificacion.validaNotificado == 1,"type":"text","label":"Nombre","placeholder":"Nombre"},model:{value:(_vm.currentNotificado.nombre),callback:function ($$v) {_vm.$set(_vm.currentNotificado, "nombre", $$v)},expression:"currentNotificado.nombre"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"paterno","rules":"required","custom-messages":{
        required: 'El paterno es obligatorio',
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"disabled":_vm.currentNotificado.aceptado || _vm.notificacion.validaNotificado == 1,"type":"text","label":"Paterno","placeholder":"Paterno"},model:{value:(_vm.currentNotificado.paterno),callback:function ($$v) {_vm.$set(_vm.currentNotificado, "paterno", $$v)},expression:"currentNotificado.paterno"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-4"},[_c('base-input',{attrs:{"disabled":_vm.currentNotificado.aceptado || _vm.notificacion.validaNotificado == 1,"type":"text","label":"Materno","placeholder":"Materno"},model:{value:(_vm.currentNotificado.materno),callback:function ($$v) {_vm.$set(_vm.currentNotificado, "materno", $$v)},expression:"currentNotificado.materno"}})],1)]):_vm._e(),(_vm.currentNotificado.persona === 'Moral')?_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"name":"nombre_notificado","rules":"required","custom-messages":{
        required: 'El nombre del notificado es obligatoria',
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"disabled":_vm.currentNotificado.aceptado || _vm.notificacion.validaNotificado == 1,"type":"text","label":"Nombre del Notificado","placeholder":"Nombre del Notificado"},model:{value:(_vm.currentNotificado.nombre_notificado),callback:function ($$v) {_vm.$set(_vm.currentNotificado, "nombre_notificado", $$v)},expression:"currentNotificado.nombre_notificado"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4141634901)})],1)]):_vm._e(),_c('div',{staticClass:"row mt-4"}),(!_vm.currentNotificado.aceptado == true && !_vm.notificacion.validaNotificado == 1)?_c('div',{staticClass:"mt-4 text-center"},[_c('button',{staticClass:"btn btn-fill float-right orange-btn",attrs:{"type":"submit"}},[_vm._v(" Guardar ")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }