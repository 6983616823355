<template>
  <!-- <card> -->
  <horizontal-stepper
    :steps="demoSteps"
    @active-step="isStepActive"
    class="txtSize"
  >
    <!--@completed-step="completeStep"
       @stepper-finished="alert" -->
  </horizontal-stepper>
  <!-- </card >-->
</template>

<script>
// import Card from "src/components/Cards/Card.vue";
import HorizontalStepper from "vue-stepper";

// This components will have the content for each stepper step.
// import StepOne from "./StepOne.vue";
// import StepTwo from "./StepTwo.vue";

export default {
  name: "Stepper",

  components: {
    HorizontalStepper,
    // Card,
  },

  data() {
    return {
      demoSteps: [
        {
          // icon: "info",
          name: "uno",
          title: "Entrega de información",
          // subtitle: "Notas",
          // component: StepOne,
          completed: false,
        },
        {
          // icon: "payment",
          name: "dos",
          title: "Pago",
          // subtitle: "Notas",
          // component: StepTwo,
          completed: false,
        },
        {
          // icon: "book",
          name: "tres",
          title: "Entrega de documentos",
          // subtitle: "Notas",
          // component: StepTwo,
          completed: false,
        },
        {
          // icon: "settings",
          name: "cuatro",
          title: "Proceos interno",
          // subtitle: "Notas",
          // component: StepTwo,
          completed: false,
        },
        {
          // icon: "beenhere",
          name: "cinco",
          title: "Documento listo",
          // subtitle: "Notas",
          // component: StepTwo,
          completed: false,
        },
      ],
    };
  },

  methods: {
    // Executed when @completed-step event is triggered
    completeStep(payload) {
      // this.demoSteps.foreach((step) => {
      //   if (step.name === payload.name) {
      //     step.completed = true;
      //   }
      // });
    },

    // Executed when @active-step event is triggered
    isStepActive(payload) {
      // this.demoSteps.forEach((step) => {
      //   if (step.name === payload.name) {
      //     if (step.completed === true) {
      //       step.completed = false;
      //     }
      //   }
      // });
    },
    // Executed when @stepper-finished event is triggered
    alert(payload) {
      alert("end");
    },
  },
};
</script>

<style scoped>
.txtSize {
  text-size-adjust: 12px !important;
}
</style>
