<template>
  <div class="content">
    <div class="container-fluid mt-5 mb-5">
      <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-4">
          <h3 class="text-warning">Para mayor información contáctanos</h3>
          <h5>Te apoyaremos de la mejor manera</h5>
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-1"></div>
        <div class="col-md-4">
          <!-- <form >
            <input type="text" name="" id="">
          </form> -->
          <div class="mt-3"></div>
          <hr />
          <!-- <i class="fa fa-nc-single-02"></i> -->

          <p>
            <font-awesome-icon icon="phone" />
            +52 (55) 5555-5555
          </p>
          <p>
            <!-- <font-awesome-icon icon="images" />

            <font-awesome-icon icon="user-secret" /> -->
            <font-awesome-icon icon="envelope" />

            <!-- <i class="nc-icon nc-simple-remove"></i> -->
            info@correduriadigital.mx
          </p>
          <p>
            <!-- <i class="nc-icon nc-chat-round"></i>
            <i class="fa fa-github-alt"></i> -->
            <font-awesome-icon icon="building" />
            Av. Paseo de las Palmas, Lomas de Chapultepec, CDMX, México
          </p>
          <!-- <img src="/img/digital_services.jpg" alt="" style="max-width:100%;max-hieight:100%;"> -->
        </div>
        <!-- <div class="col-md-1"></div> -->
      </div>
    </div>
  </div>
</template>

<script>
// import EditProfileForm from './UserProfile/EditProfileForm.vue'
// import UserCard from './UserProfile/UserCard.vue'

export default {
  // components: {
  //   EditProfileForm,
  //   UserCard
  // }
};
</script>
<style></style>
