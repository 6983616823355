<template>
  <div>
    <div v-if="user === 'Client'">
      <div class="row">
        <div class="col-12">
          <b-button block class="btn-fill blue-btn">
            Entrega de información base
          </b-button>
        </div>
      </div>


      <div class="row">
        <div class="col-1">
          <div :class="['circle', ratificacion.currentComponentValues.Comparecientes ? 'completado' : '']">
            <span class="number"><i v-if="ratificacion.currentComponentValues.Comparecientes" class="fa fa-check"></i><i
                v-else class="fa fa-minus"></i></span>
          </div>
        </div>

        <div class="col-10">
          <b-button block class="btn-fill blue-btn child" @click="selected('Comparecientes')">
            Comparecientes
          </b-button>
        </div>
        <!--         <div class="col-1">
          <div v-if="ratificacion.currentComponentValues.Comparecientes" class="led-green"></div>
          <div v-else class="led-yellow"></div>
        </div> -->
      </div>


      <div class="row">
        <div class="col-1">
          <div :class="['circle', ratificacion.currentComponentValues.Montos ? 'completado' : '']">
            <span class="number"><i v-if="ratificacion.currentComponentValues.Montos" class="fa fa-check"></i><i v-else
                class="fa fa-minus"></i></span>
          </div>
        </div>
        <div class="col-10">
          <b-button block class="btn-fill blue-btn child" @click="selected('Montos')">
            Montos
          </b-button>
        </div>
        <!--         <div class="col-1">
          <div v-if="ratificacion.currentComponentValues.Montos" class="led-green"></div>
          <div v-else class="led-yellow"></div>
        </div> -->
      </div>
      <div class="row">
        <div class="col-1">
          <div :class="['circle', ratificacion.currentComponentValues.Documentos ? 'completado' : '']">
            <span class="number"><i v-if="ratificacion.currentComponentValues.Documentos" class="fa fa-check"></i><i
                v-else class="fa fa-minus"></i></span>
          </div>
        </div>
        <div class="col-10">
          <b-button block class="btn-fill blue-btn child" @click="selected('Documentos')">
            Documentos
          </b-button>
        </div>
        <!--         <div class="col-1">
          <div v-if="ratificacion.currentComponentValues.Documentos" class="led-green"></div>
          <div v-else class="led-yellow"></div>
        </div> -->
      </div>
      <div class="row">
        <div class="col-1">
          <div :class="['circle', ratificacion.currentComponentValues.Cotizacion ? 'completado' : '']">
            <span class="number"><i v-if="ratificacion.currentComponentValues.Cotizacion" class="fa fa-check"></i><i
                v-else class="fa fa-minus"></i></span>
          </div>
        </div>
        <div class="col-10">
          <b-button block class="btn-fill blue-btn" @click="selected('Cotizacion')">
            Cotización
          </b-button>
        </div>
        <!--       <div class="col-1">
          <div v-if="ratificacion.currentComponentValues.Cotizacion" class="led-green"></div>
          <div v-else class="led-yellow"></div>
        </div> -->
      </div>
      <div class="row">
        <div class="col-1">
          <div :class="['circle', ratificacion.currentComponentValues.Pago ? 'completado' : '']">
            <span class="number"><i v-if="ratificacion.currentComponentValues.Pago" class="fa fa-check"></i><i
                v-else class="fa fa-minus"></i></span>
          </div>
        </div>
        <div class="col-10">
          <b-button block class="btn-fill blue-btn" @click="selected('Pago')">
            Pago
          </b-button>
        </div>
    <!--     <div class="col-1">
          <div v-if="ratificacion.currentComponentValues.Pago" class="led-green"></div>
          <div v-else class="led-yellow"></div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TreeRatificaciones",

  props: {
    ratificacion: { type: Object },
    user: { type: String }
  },

  methods: {
    selected(node) {
      this.$emit("node", node);
    }
  }
};
</script>

<style scoped>
.invi {
  display: none !important;
  ;
}

.completado {
  background-color: yellowgreen !important;
}

.circle {
  height: 20px;
  width: 20px;
  margin-top: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: silver;
}

.icon-title {
  height: 20px;
  width: 20px;
  margin-top: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.icon-title-fa {
  font-size: 11px;
  color: black;
}

.number {
  font-size: 11px;
  color: white;
}

.title-text {
  font-weight: 600;
  color: black !important;

}

.complete-text {

  color: black !important;
}

.blue-btn {

  padding-left: 0px;
  padding-right: 0px;
  background-color: transparent !important;
  border-color: transparent !important;
  color: rgb(154, 154, 154);
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: -10px;
  text-align: left !important;

}

.blue-btn:hover {
  color: rgb(154, 154, 154);
  background-color: transparent !important;
  border-color: transparent !important;
  background-color: #effbff !important;
  border-color: #effbff !important;
}

.blue-btn:active {
  background-color: #effbff !important;
  border-color: #effbff !important;

}


.led-green {
  margin: 10px auto;
  width: 10px;
  height: 10px;
  background-color: #43eb34;
  border-radius: 50%;
  box-shadow: #257a1d 0 -1px 7px 1px, inset #460 0 -1px 9px, #7d0 0 2px 12px;
}

.led-red {
  margin: 10px auto;
  width: 10px;
  height: 10px;
  background-color: #e60505;
  border-radius: 50%;
  box-shadow: #a60903 0 -1px 7px 1px, inset #600 0 -1px 9px, #f00 0 2px 12px;
}

.led-yellow {
  margin: 10px auto;
  width: 10px;
  height: 10px;
  background-color: #f7e00a;
  border-radius: 50%;
  box-shadow: #d1ca04 0 -1px 7px 1px, inset #c4b206 0 -1px 9px, #dd0 0 2px 12px;
}
</style>
