import config from "@/config";
import axios from "axios";

export async function getGoogleMapUrl(address) {
  const geocode = await getGoogleGeocode(address);

  if (!geocode) return null;

  return geocode.geometry.location;
}

async function getGoogleGeocode(address) {
  try {
    const addr = address.replace(" ", "+");
    const endpoint = "https://maps.googleapis.com/maps/api/geocode/json?address=" + addr + "&key=" + config.GOOGLE_API_KEY;
    const res = await axios.get(endpoint);
    const data = res.data;

    if (!data.results || !data.results.length) {
      return null;
    }

    return data.results[0];
  } catch (error) {
    console.log(error);

    return null;
  }
}