<template>
  <div>
    <div class="row">
      <div class="col-md-4">
        <ValidationProvider name="firstName" rules="required" v-slot="{ errors }"
          :custom-messages="{ required: 'El nombre es obligatorio' }">
          <base-input type="text" label="Nombre(s)" placeholder="Nombre(s)" v-model="currentuser.nombre">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <ValidationProvider name="lastNameP" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El apellido paterno es obligatorio',
        }">
          <base-input type="text" label="Apellido Paterno" placeholder="Apellido Paterno" v-model="currentuser.paterno">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <!-- <ValidationProvider
          name="lastNameM"
          rules="required"
          v-slot="{ errors }"
          :custom-messages="{
            required: 'El apellido materno es obligatorio',
          }"
        > -->
        <base-input type="text" label="Apellido Materno" placeholder="Apellido Materno" v-model="currentuser.materno">
        </base-input>
        <!-- <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider> -->
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <ValidationProvider name="email" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El email es obligatorio',
        }">
          <!-- |email
        email: 'Debe ser un email válido', -->
          <base-input type="email" label="Email" placeholder="Email" v-model="currentuser.email">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <!-- <div class="col-md-6">
        <ValidationProvider
          name="telefono"
          rules="required|length:10|numeric"
          v-slot="{ errors }"
          :custom-messages="{
            required: 'El teléfono es obligatorio',
            length: 'El número debe tener 10 dígitos',
            numeric: 'Solo puede contener números',
          }"
        >
          <base-input
            type="number"
            label="Teléfono"
            placeholder="Teléfono o celular"
            v-model="currentuser.telefono"
          >
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div> -->
      <div class="col-md-6">
        <ValidationProvider name="rol" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El rol es obligatorio',
        }">
          <label for="">Rol</label>
          <select disabled v-model="currentuser.rol" class="custom-select">
            <option value="Cliente Administrador">Cliente Administrador</option>
            <option value="Cliente Usuario">Cliente Usuario</option>
          </select>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <!-- <div class="row"> -->

    <!-- <div class="col-md-6">
        <ValidationProvider
          name="persona"
          rules="required"
          v-slot="{ errors }"
          :custom-messages="{
            required: 'La persona es obligatoria',
          }"
        >
          <label for="">Persona</label>
          <select v-model="currentuser.persona" class="custom-select">
            <option value="Física">Física</option>
            <option value="Moral">Moral</option>
          </select>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div> -->
    <!-- </div> -->

    <!-- <div class="row">
      <div class="col-md-6">
        <ValidationProvider
          name="rfc"
          rules="required|length:13"
          v-slot="{ errors }"
          :custom-messages="{
            required: 'El RFC es obligatorio',
            length: 'Debe tener 13 caracteres',
          }"
        >
          <base-input
            type="text"
            label="RFC"
            placeholder="RFC"
            v-model="currentuser.rfc"
          >
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div> -->

    <div class="text-center">
      <button type="submit" class="btn btn-fill float-right orange-btn">
        Guardar
      </button>
    </div>
  </div>
</template>

<script>
import Card from "src/components/Cards/Card.vue";

export default {
  components: {
    Card,
  },

  props: {
    currentuser: { type: Object },
  },

  data() {
    return {
      form: {
        //user
        nombre: null,
        paterno: null,
        materno: null,
        email: null,
        // password: null,
        rol: null,
        roles_id: null,
        // telefono: null,
        persona: null,
        rfc: null,
        // admin_id: null,
        // admin_cliente_nombre: null,
        // externo: null,

        // nombre: null,
        // rfc: null,

        // second_users: [
        //   // {
        //   //   firstName: null,
        //   //   lastNameP: null,
        //   //   lastNameM: null,
        //   //   email: null,
        //   //   password: null,
        //   //   rol: null,
        //   //   telefono: null,
        //   // },
        // ],
      },
    };
  },
};
</script>

<style scoped>
.cardErrors {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* color: #9a9a9a; */
  color: #ea640b;
  font-size: 13px;
}
</style>
