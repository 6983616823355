<template>
  <div class="container">
    <!-- TODO: MODAL COMPARECIENTE -->
    <!-- <b-modal no-close-on-backdrop centered ref="compareciente-modal" title="" hide-footer id="modalC" size="xl">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(putCompareciente)">
          <ModalCompareciente :currentCompareciente="currentCompareciente" :solicitantes="notificacion.solicitantes"
            :solicitanteToAdd="solicitanteToAdd" @submit.prevent />
        </form>
      </ValidationObserver>
    </b-modal> -->

    <b-modal no-close-on-backdrop centered ref="compareciente-modal" title="" hide-footer id="modalC" size="xl">
      <AgregaSolicitante :current-solicitante="currentCompareciente" :max-number-of-representantes="1"
        @submit-form="putCompareciente" />
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog" hide-footer id="modal-confirm-dialog">
      <h4 class="text-center">¿Seguro que quiere remover el solicitante?</h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="deleteCompareciente">OK</b-button>
    </b-modal>

    <h4 class="cardD">Información de los Solicitantes</h4>
    <label class="fs-6 text-black-50 text-uppercase">Agregar solicitantes</label>
    <!-- TODO: ESTO ES PARA LA NUEVA MANERA DE AGREGAR SOLICITANTES USANDO RADIO BUTTONS -->
    <!--
    <div class="mt-2">
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" v-model="solicitanteType" value="nuevo" id="radio-nuevo"
          @click="addComparecienteNew">
        <label class="form-check-label" for="radio-nuevo">Nuevo</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" v-model="solicitanteType" value="existente" id="radio-existente">
        <label class="form-check-label" for="radio-existente">Existente</label>
      </div>
    </div>

    <div v-if="solicitanteType === 'nuevo'" class="mt-2">

      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" v-model="personType" value="fisica" id="radio-fisica">
        <label class="form-check-label" for="radio-fisica">Persona Fisica</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" v-model="personType" value="moral" id="radio-moral">
        <label class="form-check-label" for="radio-moral">Persona Moral</label>
      </div>

      <div v-if="personType === 'fisica'">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(putCompareciente)">
            <Fisica :isEmpty="comparecienteIsAdded" :currentCompareciente="currentCompareciente"
              :solicitantes="notificacion.solicitantes" :solicitanteToAdd="solicitanteToAdd" @submit.prevent />
          </form>
        </ValidationObserver>
      </div>

      <div v-if="personType === 'moral'">

        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(putCompareciente)">
            <Moral :isEmpty="comparecienteIsAdded" :currentCompareciente="currentCompareciente"
              :solicitantes="notificacion.solicitantes" :solicitanteToAdd="solicitanteToAdd" @submit.prevent />
          </form>
        </ValidationObserver>

        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(putCompareciente)">
            <Fisica :isEmpty="comparecienteIsAdded" :currentCompareciente="currentCompareciente"
              :solicitantes="notificacion.solicitantes" :solicitanteToAdd="solicitanteToAdd" @submit.prevent />
          </form>
        </ValidationObserver>
      </div>

      <div v-if="solicitanteType === 'existente'" class="select-container">
        <div>
          <label for="select-comparecientes" class="custom-label">
            Existente (solo aparecerán solicitantes de procesos concluídos)
          </label>
        </div>
        <div class="col-12">
          <select id="select-comparecientes" :disabled="!canAddSolicitante" class="custom-select"
            v-model="selectedSolicitante" @change="selectSolicitantes">
            <option v-for="option in comparecientes" :key="option.id" :value="option.id">
              {{ displaytext(option) }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div v-if="solicitanteType === 'existente'" class="mt-2">
      <div>
        <label for="select-comparecientes">Existente (solo aparecerán solicitantes de procesos concluídos)</label>
      </div>
      <div class="col-12">
        <select id="select-comparecientes" :disabled="!canAddSolicitante" class="custom-select"
          v-model="selectedSolicitante" @change="selectSolicitantes">
          <option v-for="option in comparecientes" :key="option.id" :value="option.id">
            {{ displaytext(option) }}
          </option>
        </select>
      </div>
    </div>
    -->
    <div class="mt-2">
      <label for="btn-add">Nuevo</label>
      <b-button variant="success" class="btn-fill spaceL" size="sm" @click="addCompareciente"
        :disabled="!canAddSolicitante" id="btn-add">
        +
      </b-button>
    </div>

    <div class="mt-2">
      <label>Ó</label>
    </div>

    <div class="mt-2">
      <div>
        <label for="select-comparecientes">Existente (solo aparecerán solicitantes de procesos concluídos)</label>
      </div>
      <div class="col-12">
        <select id="select-comparecientes" :disabled="!canAddSolicitante" class="custom-select"
          v-model="selectedSolicitante" @change="selectSolicitantes">
          <option v-for="option in comparecientes" :key="option.id" :value="option.id">
            {{ displaytext(option) }}
          </option>
        </select>
      </div>
    </div>

    <!-- <div class="row mt-3">
      <div class="col-12">
        <label class="control-label" for="">Existente (solo aparecerán solicitantes de procesos concluídos)</label>
      </div>
    </div> -->

    <!-- <div class="row mt-4">
      <div class="col-12">
        <label class="control-label" for="">Existente (solo aparecerán solicitantes de procesos concluídos)</label>
        <select :disabled="!canAddSolicitante" class="custom-select long" v-model="selectedSolicitante" @change="selectSolicitantes">
          <option v-for="option in comparecientes" :key="option.id" :value="option.id">
            {{ displaytext(option) }}
          </option>
        </select>
      </div>
    </div> -->

    <div v-if="notificacion.solicitantes.length > 0" class="mt-5">
      <b-table ref="table" hover :fields="fieldsCuadro" :items="notificacion.solicitantes">
        <template #cell(acciones)="{ item }">
          <b-button v-if="!item.existente && !item.representante" variant="warning" size="sm" v-b-tooltip.hover
            title="Editar" class="editBtn" @click="editCompareciente(item)">
            <b-icon-pencil />
          </b-button>
          <b-button v-if="!item.representante" variant="danger" size="sm" v-b-tooltip.hover title="Eliminar"
            @click="predeleteComparreciente(item.id)">
            <b-icon-trash />
          </b-button>
        </template>
      </b-table>
    </div>

    <div class="mt-4">
      <div>
        <b-button type="submit" class="btn-fill orange-btn bottom2" size="m">Continuar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import ModalCompareciente from "@/views/clients/procesos/notificaciones/infoBase/modales/ModalCompareciente.vue";
import Fisica from "@/views/clients/procesos/notificaciones/infoBase/TabComparecientes/Fisica.vue";
import Moral from "@/views/clients/procesos/notificaciones/infoBase/TabComparecientes/Moral.vue";
import AgregaSolicitante from "@/components/AgregaSolicitante.vue";

export default {
  name: "Solicitantes",

  components: {
    ModalCompareciente,
    Fisica,
    Moral,
    AgregaSolicitante
  },

  props: {
    notificacion: { type: Object },
    comparecientes: { type: Array }
  },
  data() {
    return {
      selectedSolicitante: null,
      currentCompareciente: null,
      currentComparecienteId: null,
      solicitanteType: '', // 'nuevo' o 'existente'
      personType: '', // 'fisica' o 'moral'

      canAddSolicitante: true,
      solicitanteToAdd: 2, //0: pf, 1: pm, 2: ambos
      comparecienteIsAdded: false,
      moralData: []
    }
  },
  computed: {
    fieldsCuadro() {
      return [
        { key: "index", label: "", class: "text-center" },
        { key: "nombreCompleto", label: "Nombre", class: "text-center" },
        { key: "persona", label: "Persona", class: "text-center" },
        { key: "principalsymbol", label: "Principal", class: "text-center" },
        { key: "representadanombre", label: "Representante", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" },
      ];
    },
  },

  created() {
  },

  watch: {
    currentCompareciente: function (newVal) {
      this.currentCompareciente.principal = true;
    },
    notificacion: {
      handler: function (newVal, oldVal) {
        const solicitantes = newVal.solicitantes;

        if (solicitantes.length === 0) {
          this.canAddSolicitante = true;
          this.solicitanteToAdd = 2;

          return;
        } else {
          this.canAddSolicitante = false;
        }

        if (solicitantes.length === 1) {
          solicitantes[0].principal = true;
          solicitantes[0].principalsymbol = '✓';

          if (solicitantes[0].persona === 'Física') {
            // this.canAddSolicitante = false;
            this.solicitanteToAdd = 1;
            solicitantes[0].representada = null;
            // solicitantes[0].representante = true;
          } else if (solicitantes[0].persona === 'Moral') {
            // this.canAddSolicitante = true;
            this.solicitanteToAdd = 0;
            solicitantes[0].representada = null;
          }

          return;
        }

        if (solicitantes.length >= 1) {
          const moralIndex = solicitantes.findIndex(s => s.persona === 'Moral');

          if (moralIndex === -1) {
            this.notificacion.solicitantes = [];
            // this.canAddSolicitante = false;
            this.solicitanteToAdd = 2;

            return;
          }

          if (solicitantes.length === 2) {
            // this.canAddSolicitante = true;
            this.solicitanteToAdd = 0;
          } else {
            // this.canAddSolicitante = false;
          }


          solicitantes.forEach(s => {
            if (s.persona === "Física") {
              s.principal = false;
              s.principalsymbol = null;
              // s.representante = true;
              // s.representada = true;
              // s.representadanombre = "Por su propio derecho";

              // if (moralIndex > -1) {
              //   s.representadanombre = solicitantes[moralIndex].denominacion_social;
              //   s.representada = solicitantes[moralIndex].id;
              // } else {
              //   s.representadanombre = null;
              //   s.representada = null;
              // }
            }
            if (s.persona === "Moral") {
              s.principal = true;
              s.principalsymbol = '✓';
              // s.representada = true;
            }
          });
        }

        // if (solicitantes.length === 2 || solicitantes.length === 3) {
        //   solicitantes.forEach((s) => {
        //     if (s.persona === "Moral") {
        //       s.principal = true;
        //       s.principalsymbol = '✓';
        //       s.representada = true;
        //     }
        //     if (s.persona === "Física") {
        //       s.principal = false;
        //       s.principalsymbol = null;
        //     }
        //   });
        // }
      },
      deep: true
    },
    solicitanteType: function (newVal, oldVal) {
      if (newVal !== oldVal || newVal === 'existente') {
        this.clearTable();
        this.personType = '';
      }
    },
    personType: function (newVal, oldVal) {
      if (newVal === 'fisica') {
        this.currentCompareciente = {};
        this.clearTable();
        this.currentCompareciente.persona = 'Física';
        this.currentCompareciente.tipo_persona = 'fisica';
      } else if (newVal === 'moral') {
        this.currentCompareciente = {};
        this.clearTable();
        // this.currentCompareciente.persona = 'Moral';
        // this.currentCompareciente.tipo_persona = 'moral';


      }
    },
    comparecienteIsEmpty() {
      return Object.keys(this.currentCompareciente).length === 0;
    }
  },

  methods: {
    displaytext(item) {
      if (item.persona === "Moral") {
        return item.denominacion_social + "(Persona moral)"
      } else if (item.persona === "Física") {
        return item.nombre + ' ' + item.paterno + ' ' + (item.materno || '') + " (Persona física)"
      }
    },

    selectSolicitantes() {
      const indexExistente = this.notificacion.solicitantes.findIndex(obj => obj.id === this.selectedSolicitante);

      if (indexExistente !== -1) {
        this.$toast.warning("No se puede seleccionar 2 veces al mismo compareciente", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true,
        });

        return;
      }

      const index = this.comparecientes.findIndex(o => o.id === this.selectedSolicitante);
      const materno = this.comparecientes[index].materno || '';
      let nombreCompleto = "";

      if (this.comparecientes[index].persona === 'Física') {
        nombreCompleto = this.comparecientes[index].nombre + ' ' + this.comparecientes[index].paterno + ' ' + materno;
        this.comparecientes[index].representadanombre = "Por su propio derecho";
      } else {
        nombreCompleto = this.comparecientes[index].denominacion_social;
      }

      this.comparecientes[index].nombreCompleto = nombreCompleto;
      this.comparecientes[index].existente = true;

      const a = Object.assign({}, this.comparecientes[index]);

      this.notificacion.solicitantes.push(a);

      if (this.comparecientes[index].persona === "Moral" && this.comparecientes[index].representantes.length > 0) {
        const representante = this.comparecientes[index].representantes[this.comparecientes[index].representantes.length - 1];

        representante.nombreCompleto = representante.nombre + ' ' + representante.paterno + ' ' + (representante.materno || '');
        representante.representadanombre = this.comparecientes[index].denominacion_social;
        representante.persona = "Física";
        representante.representante = true;
        representante.principal = false;
        representante.existente = true;

        const r = Object.assign({}, representante);

        this.notificacion.solicitantes.push(r);
      }

      this.selectedSolicitante = null;
    },

    addCompareciente() {
      this.$refs["compareciente-modal"].show(); // TODO: quitar esto
      this.currentCompareciente = {};
    },
    addComparecienteNew() {
      this.currentCompareciente = {};
    },

    editCompareciente(item) {
      this.$refs["compareciente-modal"].show();
      this.currentCompareciente = item;
    },

    putCompareciente(solicitante) {
      this.currentCompareciente = Object.assign({}, solicitante);

      this.currentCompareciente.persona = this.currentCompareciente.tipoPersona;
      this.currentCompareciente.principal = true;

      if (this.currentCompareciente.persona === 'Física') {
        this.currentCompareciente.nombreCompleto =
          this.currentCompareciente.nombre + ' ' + this.currentCompareciente.paterno + ' '
          + (this.currentCompareciente.materno || '');
        this.currentCompareciente.representadanombre = "Por su propio derecho";
        this.currentCompareciente.principalsymbol = "✓";
        this.currentCompareciente.principal = true;

        if (!this.currentCompareciente.id) {
          this.currentCompareciente.id = this.notificacion.solicitantes.length + 1;

          this.notificacion.solicitantes.push(this.currentCompareciente);
        }

      } else {
        this.currentCompareciente.nombreCompleto = this.currentCompareciente.denominacion_social;
        this.currentCompareciente.principalsymbol = "✓";
        this.currentCompareciente.principal = true;

        if (!this.currentCompareciente.id) {
          this.currentCompareciente.id = this.notificacion.solicitantes.length + 1;

          this.notificacion.solicitantes.push(this.currentCompareciente);
        }

        for (const representante of this.currentCompareciente.representantes) {
          if (!representante.id) {
            representante.id = this.notificacion.solicitantes.length + 1;
            representante.representadanombre = this.currentCompareciente.denominacion_social;
            representante.nombreCompleto = representante.nombre + ' ' + representante.paterno + ' '
              + (representante.materno || '');
            representante.persona = "Física";
            representante.representante = true;

            this.notificacion.solicitantes.push(representante);
          }
        }
      }

      // const index = this.notificacion.solicitantes.findIndex(o => o.id === this.currentCompareciente.id);

      // if (this.currentCompareciente.principal) {
      //   this.cambiaPrincipal(index);
      // } else {
      //   this.notificacion.solicitantes[index].principal = false;
      //   this.notificacion.solicitantes[index].principalsymbol = null;
      // }

      // this.notificacion.solicitantes[index].nombreCompleto = nombreCompleto;

      this.$refs["compareciente-modal"].hide();
      this.comparecienteIsAdded = true;

      return;

      // let nombreCompleto = ""
      // let materno = this.currentCompareciente.materno || '';
      // if (this.currentCompareciente.persona === 'Física') {
      //   nombreCompleto = this.currentCompareciente.nombre + ' ' + this.currentCompareciente.paterno + ' ' + materno;
      // } else {
      //   nombreCompleto = this.currentCompareciente.denominacion_social;

      // }

      // if (!this.currentCompareciente.id) {
      //   let id = this.notificacion.solicitantes.length + 1;
      //   this.currentCompareciente.id = id;
      //   this.notificacion.solicitantes.push({ ...this.currentCompareciente, nombreCompleto });
      // }
      // let index = this.notificacion.solicitantes.findIndex(
      //   (o) => o.id === this.currentCompareciente.id
      // );
      // if (this.currentCompareciente.principal == true) {
      //   this.cambiaPrincipal(index);
      // } else {
      //   this.notificacion.solicitantes[index].principal = false;
      //   this.notificacion.solicitantes[index].principalsymbol = null;
      // }
      // this.notificacion.solicitantes[index].nombreCompleto = nombreCompleto;
      // this.$refs["compareciente-modal"].hide();
      // this.comparecienteIsAdded = true;

    },

    predeleteComparreciente(id) {
      this.currentComparecienteId = id;
      this.$refs["modal-confirm-dialog"].show();
    },

    deleteCompareciente() {
      // const index2 = this.notificacion.solicitantes.findIndex(o => o.id === this.currentComparecienteId);
      // this.notificacion.solicitantes.splice(index2, 1);

      this.notificacion.solicitantes.length = 0;
      this.canAddSolicitante = true;

      this.$refs.table.refresh();
      this.personType = '';
      this.currentCompareciente = {};

      this.$refs["modal-confirm-dialog"].hide();
    },

    cambiaPrincipal(index) {
      this.notificacion.solicitantes.forEach(element => {
        if (this.notificacion.solicitantes.indexOf(element) === index) {
          this.notificacion.solicitantes[index].principal = true;
          this.notificacion.solicitantes[index].principalsymbol = '✓'
          if (this.notificacion.solicitantes[index].persona === 'Física') {
            this.notificacion.solicitantes[index].tipo_persona = 'fisica';
          } else {
            this.notificacion.solicitantes[index].tipo_persona = 'moral';
          };
        } else {
          element.principal = false;
          element.principalsymbol = null;
        }
      })
    },

    clearTable() {
      this.notificacion.solicitantes = [];
    },

    onSubmit() {
      this.$emit("submit-form");
    }
  }
}
</script>

<style scoped>
.icons-size {
  font-size: 20px;
}

.bottom2 {
  /* position: fixed;
  right: 2%;
  bottom: 5%;
  overflow: visible;
  z-index: 100; */
  float: right;
}

/* .container>>>.control-label {
  margin-left: 50px;
  width: 350px;
  position: absolute;
  vertical-align: middle;
} */

.container>>>.custom-control-label {
  margin-left: 50px;
  position: absolute;
  margin-top: 40px;
}

.container>>>.control-label-top {
  margin-top: 0px !important;
  margin-left: 50px;
  text-align: left;
  width: 200px;
}

/* .container>>>.form-control {
  width: 80px;
  margin-left: 400px;
  position: absolute;
  vertical-align: middle;
} */

/* .container>>>.custom-select {
  width: 350px;
  margin-left: 100px;
  vertical-align: middle;
} */

/* .container>>>.long {
  width: 650px !important;
  margin-left: 50px;
} */

.container>>>.form-check-sign {
  margin-top: 15px;
  position: absolute;
}

.check-label-pers {
  margin-left: 45px;
  margin-top: 20px;
  position: absolute;
}

.btn-fill {
  margin-left: 20px;
}

.accordion-container {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.accordion-item {
  border-top: 1px solid #e0e0e0;
}

.accordion-item:first-of-type {
  border-top: none;
}

.accordion-header {
  background-color: #f7f7f7;
  padding: 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease;
}

.accordion-header:hover {
  background-color: #e0e0e0;
}

.accordion-body {
  padding: 20px;
  background-color: #fff;
  border-top: 1px solid #e0e0e0;
}

.select-container {
  margin-top: 20px;
}

.custom-label {
  font-weight: bold;
  margin-bottom: 5px;
}

.custom-select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 5px;
}
</style>
