var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('div',[_c('div',{attrs:{"id":"loader"}},[_c('font-awesome-icon',{attrs:{"icon":"spinner","spin":""}})],1)]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"nombre","rules":"required","custom-messages":{ required: 'El nombre es obligatorio' }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"type":"text","label":"Nombre(s)","placeholder":"Nombre(s)"},model:{value:(_vm.currentAccionista.nombre),callback:function ($$v) {_vm.$set(_vm.currentAccionista, "nombre", $$v)},expression:"currentAccionista.nombre"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"paterno","rules":"required","custom-messages":{
        required: 'El apellido paterno es obligatorio'
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"type":"text","label":"Apellido Paterno","placeholder":"Apellido Paterno"},model:{value:(_vm.currentAccionista.paterno),callback:function ($$v) {_vm.$set(_vm.currentAccionista, "paterno", $$v)},expression:"currentAccionista.paterno"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-4"},[_c('base-input',{attrs:{"type":"text","label":"Apellido Materno","placeholder":"Apellido Materno"},model:{value:(_vm.currentAccionista.materno),callback:function ($$v) {_vm.$set(_vm.currentAccionista, "materno", $$v)},expression:"currentAccionista.materno"}})],1)]),_c('div',{staticClass:"row"}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"rfc","rules":"required|length:13","custom-messages":{
        required: 'El RFC es obligatorio',
        length: 'Debe tener 13 caracteres'
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"type":"text","label":"RFC","placeholder":"RFC"},model:{value:(_vm.currentAccionista.rfc),callback:function ($$v) {_vm.$set(_vm.currentAccionista, "rfc", $$v)},expression:"currentAccionista.rfc"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"porcentaje","rules":"required","custom-messages":{ required: 'El porcentaje es obligatorio' }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"type":"number","label":"Porcentaje","placeholder":"Porcentaje"},model:{value:(_vm.currentAccionista.porcentaje),callback:function ($$v) {_vm.$set(_vm.currentAccionista, "porcentaje", $$v)},expression:"currentAccionista.porcentaje"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"monto","rules":"required","custom-messages":{
        required: 'El monto es obligatorio'
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"type":"number","label":"Monto","placeholder":"Monto"},model:{value:(_vm.currentAccionista.monto),callback:function ($$v) {_vm.$set(_vm.currentAccionista, "monto", $$v)},expression:"currentAccionista.monto"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_vm._m(0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('button',{staticClass:"btn btn-fill float-right orange-btn",attrs:{"type":"submit"}},[_vm._v(" Guardar ")])])
}]

export { render, staticRenderFns }