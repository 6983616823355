<template>
  <div>
    <div v-if="!logged">
      <!-- el menu que está solo en el landing -->
      <!-- <div class="collapse navbar-collapse justify-content-end" v-if="!logged"> -->
      <ul class="nav nav-mobile-menu" style="padding-left: 0px !important">
        <li class="nav-item">
          <router-link class="nav-link" to="#" v-scroll-to="'#como_funciona'"
            >¿Cómo funciona?
          </router-link>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#"> Cuenta </a>
        </li>
        <!-- <li class="nav-item">
            <router-link :to="{ name: 'Home', hash: '#quienes_somos' }"
              >¿Quiénes somos?
            </router-link>
          </li> -->
        <!-- <li class="nav-item">
            <router-link class="nav-link" to="#" v-scroll-to="'#quienes_somos'"
              >¿Quiénes somos?
            </router-link>
          </li> -->
        <li class="nav-item">
          <router-link class="nav-link" to="#" v-scroll-to="'#servicios'"
            >Servicios
          </router-link>
        </li>
        <!-- <base-dropdown title="Servicios">
            <a class="dropdown-item" href="#">Constitución de sociedades</a>
            <a class="dropdown-item" href="#">Formalización de actas</a>
            <a class="dropdown-item" href="#">Notificaciones</a>
            <a class="dropdown-item" href="#">Copias Certificadas</a>
            <a class="dropdown-item" href="#">Fes de hechos</a>
            <a class="dropdown-item" href="#">Ratificación de formas</a>
            <a class="dropdown-item" href="#">Avalúos de mejoras</a>
             <div class="divider"></div>
            <a class="dropdown-item" href="#">Separated link</a>
          </base-dropdown> -->
        <li class="nav-item">
          <!-- <a class="nav-link" href="/signin"> Registro </a> -->
          <router-link class="nav-link" :to="'/signin'">Registro</router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="#" v-scroll-to="'#contacto'"
            >Contacto</router-link
          >
        </li>
        <li class="nav-item">
          <router-link class="nav-link" :to="'/login'"
            >Iniciar sesión</router-link
          >
        </li>
      </ul>
    </div>

    <div v-else-if="logged">
      <ul class="nav nav-mobile-menu" style="padding-left: 0px !important">
        <!-- <base-dropdown>
      <template slot="title">
        <i class="fa fa-globe"></i>
        <b class="caret"></b>
        <span class="notification">5 Notifications</span>
      </template>
      <a class="dropdown-item" href="#">Notification 1</a>
      <a class="dropdown-item" href="#">Notification 2</a>
      <a class="dropdown-item" href="#">Notification 3</a>
      <a class="dropdown-item" href="#">Notification 4</a>
      <a class="dropdown-item" href="#">Another notification</a>
    </base-dropdown> -->
        <!-- <li class="nav-item">
      <a href="#" class="nav-link">
        <i class="nc-icon nc-zoom-split hidden-lg-up"></i>
        <span class="d-lg-none">Search</span>
      </a>
    </li> -->
        <!-- <base-dropdown title="Dropdown">
      <a class="dropdown-item" href="#">Action</a>
      <a class="dropdown-item" href="#">Another action</a>
      <a class="dropdown-item" href="#">Something</a>
      <a class="dropdown-item" href="#">Something else here</a>
      <div class="divider"></div>
      <a class="dropdown-item" href="#">Separated link</a>
    </base-dropdown> -->
        <base-dropdown title="Servicios">
          <a class="dropdown-item" href="#">Constitución de sociedades</a>
          <a class="dropdown-item" href="#">Formalización de actas</a>
          <a class="dropdown-item" href="#">Notificaciones</a>
          <a class="dropdown-item" href="#">Copias Certificadas</a>
          <a class="dropdown-item" href="#">Fes de hechos</a>
          <a class="dropdown-item" href="#">Ratificación de formas</a>
          <a class="dropdown-item" href="#">Avalúos de mejoras</a>
          <!-- <div class="divider"></div>
            <a class="dropdown-item" href="#">Separated link</a> -->
        </base-dropdown>
        <li class="nav-item">
          <a class="nav-link" href="#"> Cuenta </a>
        </li>

        <li class="nav-item">
          <!-- <a class="nav-link" href="#pablo">
        <span class="no-icon">Log out</span>
      </a> -->
          <a href="#" class="nav-link" @click="logout"> Log out </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Storage from "@/utils/storage";
const storage = new Storage();

export default {
  name: "mobile-menu",

  data() {
    return {
      activeNotifications: false,
      loading: false,
      logged: false,
      role: null,
    };
  },

  created() {
    this.getLogged();
  },
  methods: {
    getLogged() {
      // this.logged = storage.getItem("logged");
      var logged_ = storage.getItem("logged");
      if (logged_ != null) {
        this.logged = logged_;
      }
      this.role = storage.getItem("role");
      // console.log("TopNavbar logged " + this.logged);
      // console.log("TopNavbar role " + this.role);
      //las rutas a los dashes ya vienen del loginy signin

      // if (this.logged) {
      //   //si está indefinido esque esta logged out, entonces se queda en home
      //   if (this.role === "staff") {
      //     this.$router.push({ name: "DashboardStaff" });
      //   } else if (this.role === "client") {
      //     this.$router.push({ name: "DashboardClients" });
      //   }
      // }

      //   if (this.logged == undefined) {
      //     this.$router.push({ name: "Login" });
      //   } else if (this.logged == false) {
      //     this.$router.push({ name: "Login" });
      //   }
      //   // si estás loggeado, depende del rol que tengas
      //   //yo creo: cliente o trabajador o admin
      //   //te mando a distinta view
      //   else if (this.logged == true) {
      //     this.$router.push({ name: "EjemploItems" });
      //   }
    },

    logout() {
      //   storage.removeItem("user");
      storage.removeItem("role");
      storage.removeItem("logged");
      storage.removeItem("accessToken");
      // storage.saveItem("logged", false);
      // storage.saveItem("role", null);
      this.role = null;
      this.logged = false;
      this.$router.push("/"); //"/"  { name: "Home" }
    },
  },
};
</script>
<style></style>
