<template>
  <div>
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-9">
        <ValidationProvider
          name="email"
          rules="required|email"
          v-slot="{ errors }"
          :custom-messages="{
            required: 'El email es obligatorio',
            email: 'Debe ser un email válido',
          }"
        >
          <base-input
            type="email"
            label="Email"
            placeholder="Email"
            v-model="user.email"
          >
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <button type="submit" class="btn orange-btn float-right">Continuar</button>
  </div>
</template>
<script>
export default {
  name: "Form",

  props: {
    user: { type: Object },
  },

  data() {
    return {
      form: {
        email: null,
      },
    };
  },
};
</script>
<style>
.orange-btn {
  background-color: #ea640b !important;
  border-color: #ea640b !important;
  color: white !important;
}
.orange-btn:hover {
  background-color: #e87b31 !important;
  border-color: #e87b31 !important;
  color: white !important;
}
.orange-btn:active {
  background-color: #ea640b !important;
  border-color: #ea640b !important;
  color: white !important;
}
.msg {
  color: red !important;
}
/* .card {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* color: #9a9a9a;
  color: #ea640b;
  font-size: 13px;
} */
</style>
