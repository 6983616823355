<template>
  <div class="container">
    <h4 class="cardD">
      Finalización de proceso
    </h4>

    <div class="mt-4">
      <div class="row ">
        <div class="col-12">
          <b-button
            type="submit"
            block
            class="btn-fill orange-btn bottom2"
            size="m"
            :disabled="constitutiva.disabledTerminado"
            >Dar proceso por terminado</b-button
          >
          <!-- :disabled="constitutiva.disabledTerminado" -->
        </div>
        <!-- <div class="col-2">
          <b-button
              variant="success"
              class="btn-fill bottom2"
              size="m"
              :disabled="constitutiva.disabledDocumentos"
              @click="validar()"
              >Validar</b-button
            >
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Terminado",

  props: {
    constitutiva: { type: Object }
  }
};
</script>

<style scoped>
.bottom2 {
  float: right;
}
</style>
