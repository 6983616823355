import BaseApi from "./base";

class RegionsApi extends BaseApi {
  constructor() {
    super('regions');
  }

  getCountries() {
    return this.request.get('/regions/countries');
  }

  getStates(countryId) {
    return this.request.get(`/regions/countries/${countryId}/states`);
  }

  getCities(stateId) {
    return this.request.get(`/regions/states/${stateId}/cities`);
  }
}

export default new RegionsApi();
