<template>
  <div>
    <div id="doc">

      <video
        id="my-player"
        class="video-js"
        controls
        preload="auto"

        data-setup="{}"
      >
      <!-- poster="//vjs.zencdn.net/v/oceans.png"
      src="//vjs.zencdn.net/v/oceans.mp4" -->
      <source
       :src="
          currentDocumento.documento + stgAccessKey
        "
        type="video/mp4"></source>
      </video>

    </div>
  </div>
</template>

<script>
import VueDocPreview from "vue-doc-preview";

export default {
  name: "ModalPreviewVideo",

  props: {
    currentDocumento: { type: Object }
  },

  components: {
    // VuePdfEmbed,
    VueDocPreview
  },

  data() {
    return {
      stgAccessKey: "?sv=2023-01-03&st=2024-02-09T17%3A04%3A42Z&se=2034-02-11T17%3A04%3A00Z&sr=c&sp=rl&sig=rq9SDEcZMPv%2FbgiTq9FRE66lHZvEAeeiMqytpyiH2sk%3D",
    }
  },
};
</script>

<style scoped>
#doc {
  width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
#my-player {
  text-align: center;
  margin: 0 auto;
  width: 100%;
}
</style>
