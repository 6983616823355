<template>
  <div class="content">
    <b-breadcrumb>
      <b-breadcrumb-item :to="{ path: '/dashboard' }">Inicio</b-breadcrumb-item>
      <b-breadcrumb-item active>Editar Cotejo</b-breadcrumb-item>
    </b-breadcrumb>

    <!-- Aux -->
    <div class="container">
      <div v-if="loading">
        <div id="loader">
          <font-awesome-icon icon="spinner" spin />
        </div>
      </div>
    </div>

    <!-- Modales -->
    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog-route" hide-footer id="modal-confirm-dialog-route">
      <h4 class="text-center">
        ¿Seguro que quiere salir de la página?
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog-route')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="beforeLeave(true)">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog" hide-footer id="modal-confirm-dialog">
      <h4 class="text-center">
        ¿Seguro que quiere salir sin guardar los cambios?
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-validate" hide-footer id="modal-confirm-validate">
      <h4 class="text-center">
        ¿Está seguro de validar? Los datos no podrán ser modificados posteriormente.
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-validate')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="postMethod()">OK</b-button>
    </b-modal>

    <!-- Template -->
    <div class="container-fluid">
      <b-row>
        <b-col class="col-3">
          <card>
            <TreeEditCotejo :cotejo="cotejo" :user="'Staff'" @node="selected" />
          </card>
        </b-col>
        <b-col class="col-9">
          <card>
            <b-row>
              <b-col class="col-12">
                <div v-if="currentComponentBody === 'ValidaComparecientes'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form ref="validacomparecientes" @submit.prevent="handleSubmit(changeCurrentComponentBody(''))">
                      <ComplementaInformacion :cotejo="cotejo" @submit.prevent
                        @allChecksPassed="setModalValidate('ValidaComparecientes')" @node="selected" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'ValidaDocumentos'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form ref="validadocumentos" @submit.prevent="handleSubmit(setModalValidate('ValidaDocumentos'))">
                      <EntregaDocumentos :cotejo="cotejo" @submit.prevent @node="selected"
                        @toggleOnLoadingEvent="toggleOnLoadingHandler"
                        @toggleOffLoadingEvent="toggleOffLoadingHandler" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'Domicilio'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form ref="domicilio" @submit.prevent="handleSubmit()">
                      <Domicilio :cotejo="cotejo" @submit.prevent @allChecksPassed="setModalValidate('Domicilio')"
                        @node="selected" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'Correduria'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form ref="correduria" @submit.prevent="handleSubmit()">
                      <Correduria :cotejo="cotejo" @submit.prevent @allChecksPassed="setModalValidate('Correduria')"
                        @node="selected" @toggleOnLoadingEvent="toggleOnLoadingHandler"
                        @toggleOffLoadingEvent="toggleOffLoadingHandler" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'EstatusDocumentos'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form ref="estatusdocumentos" @submit.prevent="handleSubmit(setModalValidate('EstatusDocumentos'))">
                      <EstatusDocumentos :cotejo="cotejo" @submit.prevent @node="selected" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'PagoComplementario'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form ref="pagocomplementario" @submit.prevent="handleSubmit()">
                      <PagoComplementario :cotejo="cotejo" @submit.prevent
                        @allChecksPassed="setModalValidate('PagoComplementario')"
                        @pagadoComplementario="setModalValidate('PagadoComplementario')" @node="selected"
                        @toggleOnLoadingEvent="toggleOnLoadingHandler"
                        @toggleOffLoadingEvent="toggleOffLoadingHandler" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'FAD'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form ref="fad" @submit.prevent="handleSubmit()">
                      <FAD :cotejo="cotejo" @submit.prevent @allChecksPassed="setModalValidate('FAD')"
                        @checkFadStatus="setModalValidate('checkFadStatus')" @node="selected"
                        @toggleOnLoadingEvent="toggleOnLoadingHandler"
                        @toggleOffLoadingEvent="toggleOffLoadingHandler" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'Actas'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form ref="actas" @submit.prevent="handleSubmit(setModalValidate('Actas'))">
                      <Actas :cotejo="cotejo" @submit.prevent @node="selected"
                        @toggleOnLoadingEvent="toggleOnLoadingHandler"
                        @toggleOffLoadingEvent="toggleOffLoadingHandler" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'Expediente'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form ref="expediente" @submit.prevent="handleSubmit(setModalValidate('Expediente'))">
                      <Expediente :cotejo="cotejo" @submit.prevent @node="selected" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'Terminado'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form ref="terminado" @submit.prevent="handleSubmit(setModalValidate('Terminado'))">
                      <Terminado :cotejo="cotejo" @submit.prevent @node="selected" />
                    </form>
                  </ValidationObserver>
                </div>
              </b-col>
            </b-row>
          </card>
        </b-col>

      </b-row>
    </div>

  </div>
</template>

<script>
import Actas from "@/views/clients/procesos/cotejo/Actas.vue";
import ComplementaInformacion from "@/views/clients/procesos/cotejo/ComplementaInformacion.vue";
import Correduria from "@/views/clients/procesos/cotejo/Correduria.vue";
import Domicilio from "@/views/clients/procesos/cotejo/Domicilio.vue";
import EntregaDocumentos from "@/views/clients/procesos/cotejo/EntregaDocumentos.vue";
import EstatusDocumentos from "@/views/clients/procesos/cotejo/EstatusDocumentos.vue";
import Expediente from "@/views/clients/procesos/cotejo/Expediente.vue";
import FAD from "@/views/clients/procesos/cotejo/FAD.vue";
import PagoComplementario from "@/views/clients/procesos/cotejo/PagoComplementario.vue";
import Terminado from "@/views/clients/procesos/cotejo/Terminado.vue";
import TreeEditCotejo from "@/views/clients/procesos/cotejo/TreeEditCotejo.vue";
import Card from "src/components/Cards/Card.vue";

import { CatDocumentosApi, ComparecientesApi, CotejosApi, CotizacionesApi, DocumentosApi, DocumentosXServiciosApi, DomiciliosApi, FuncionesApi } from "@/api";
import { getGoogleMapUrl } from "@/utils/google-maps-utils";

export default ({
  name: "ValidCotejo",

  components: {
    Card,
    TreeEditCotejo,
    ComplementaInformacion,
    EntregaDocumentos,
    Domicilio,
    Correduria,
    EstatusDocumentos,
    PagoComplementario,
    FAD,
    Actas,
    Expediente,
    Terminado
  },

  created() {
    this.loading = true;
    this.cotejo.id = this.$route.params.id;
    this.getCotejo()
      .then(() => this.addSolicitanteFromCompareciente())
      .then(() => this.addRepresentanteIfMoral())
      .then(() => this.fillNombresCompletos())
      .then(() => this.fillRepresentante())
      .then(() => this.getCatDocumentosCotejos())
      .then(() => this.getDocumentosXServiciosCotejos())
      .then(() => this.getDocumentosCotejos())
      .then(() => this.addTipoToDocumentos())
      .then(() => this.getDomicilio())
      .then(() => this.getCotizacionComplementaria())
      .then(() => this.getEstatus())
      .catch((error) => {
        this.showErrorToast('Sucedió un error, intente más tarde');
        console.log(error);
      })
      .finally(() => { this.loading = false; })
  },

  beforeRouteLeave(to, from, next) {
    if (!to.name === 'Editcotejo') {
      this.$refs["modal-confirm-dialog-route"].show();
      this.next = next;
    }
    else {
      this.next = next;
      this.beforeLeave(true);
    }
  },

  data: () => ({
    next: null,
    prenode: null,
    modalValidateMethod: null,

    loading: false,
    currentComponentBody: "Terminado",

    cotejo: {
      staff: true,
      estatusDocumentos: null,

      id: null,
      id_estatus: null,
      id_prioridad: null,
      id_cotizaciones: null,
      id_clientes: null,
      id_comparecientes: null,
      pago_complementario: null,
      id_cotizaciones_complementario: null,
      envio_domicilio: null,
      domicilios_id: null,
      corredurias_id: null,
      validaSolicitantes: null,
      validaDomicilio: null,
      validaDocumentos: null,
      id_comparecientes_representante: null,

      solicitantes: [],
      documentos: [],
      precio_total: null,
      liga_pago_complementario: null,

      currentComponentValues: {
        ValidaComparecientes: false,
        ValidaDocumentos: false,
        Domicilio: false,
        Correduria: false,
        EstatusDocumentos: false,
        PagoComplementario: false,
        FAD: false,
        Actas: false,
        Expediente: false,
        Terminado: false,
      },
    }
  }),

  methods: {
    toggleOnLoadingHandler() {
      console.log("toggle On");
      this.loading = true;
    },

    toggleOffLoadingHandler() {
      console.log("toggle Off");
      this.loading = false;
    },

    async beforeLeave(answer) {
      if (answer) {
        this.next();
      }
    },

    async selected(node) {
      const keys = Object.keys(this.cotejo.currentComponentValues);
      const values = Object.values(this.cotejo.currentComponentValues);
      const dest = keys.find(value => value == node);
      this.currentComponentBody = dest;
    },


    setModalValidate(postMethod) {
      this.modalValidateMethod = postMethod;
      if (['PagoComplementario', 'PagadoComplementario', 'FAD', 'checkFadStatus', 'Expediente'].includes(postMethod)) {
        this.postMethod();
      } else if (postMethod == 'Realizacion') {
        this.openSendMailModal();
      } else {
        this.openValidateModal();
      }
    },


    async getAddressInMap(direcciones_id) {
      if (direcciones_id === null) {
        const address = `${this.cotejo.domicilio.calle},
                      ${this.cotejo.domicilio.num_exterior},
                      ${this.cotejo.domicilio.colonia},
                      ${this.cotejo.domicilio.delegacion_municipio},
                      ${this.cotejo.domicilio.codigo_postal},
                      ${this.cotejo.domicilio.ciudad},
                      ${this.cotejo.domicilio.entidad}
                      `;
        const mapUrl = await getGoogleMapUrl(address);
        if (mapUrl === null) {
          return
        }
        this.cotejo.domicilio.lat = mapUrl.lat.toString(); //parseFloat(mapUrl.lat);
        this.cotejo.domicilio.lng = mapUrl.lng.toString(); //parseFloat(mapUrl.lng);
        var a = Object.assign({}, this.cotejo.domicilio);
        this.cotejo.domicilio = a;
      }
    },

    showErrorToast(msg) {
      this.$toast.error(msg, {
        timeout: 4000, //false,
        hideProgressBar: false,
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        closeButton: "button",
        icon: true,
      });
    },


    changeCurrentComponentValue(origin, destiny) {
      this.cotejo.currentComponentValues[origin] = true;
      this.currentComponentBody = destiny;
      return;
    },


    changeCurrentComponentBody(body) {
      this.cotejo.currentComponentValues[this.currentComponentBody] = true;
      this.currentComponentBody = body;
    },


    submitForm(formToSubmit) {
      this.$refs[formToSubmit].dispatchEvent(new Event('submit'));
    },


    async getCotejo() {
      this.cotejo = {
        ...this.cotejo,
        ...await CotejosApi.detail(this.cotejo.id)
      };
      return;
    },


    async addSolicitanteFromCompareciente() {
      const compareciente = await ComparecientesApi.detail(this.cotejo.id_comparecientes);
      this.cotejo.solicitantes.push(compareciente);
    },


    async addRepresentanteIfMoral() {
      if (this.cotejo.id_comparecientes_representante) {
        const representante = await ComparecientesApi.detail(this.cotejo.id_comparecientes_representante);
        this.cotejo.solicitantes.push(representante);
      }
    },


    async fillNombresCompletos() {
      this.cotejo.solicitantes.forEach(solicitante => {
        if (solicitante.persona === 'Física') {
          solicitante.nombreCompleto = `${solicitante.nombre} ${solicitante.paterno}`;
          solicitante.materno ? solicitante.nombreCompleto += ` ${solicitante.materno}`
            : solicitante.nombreCompleto;
        } else {
          solicitante.nombreCompleto = solicitante.denominacion_social;
        }
      })
    },


    async fillRepresentante() {
      this.cotejo.solicitantes.forEach(solicitante => {
        if (solicitante.moral_representada_id) {
          solicitante.representante = `✓`;
        }
      })
    },


    async getCatDocumentosCotejos() {
      const { value } = await CatDocumentosApi.list({
        page: 0,
        registersPerPage: 0,
        categoria: "cotejos"
      })
      this.catDocumentos = value;
      return;
    },


    async getDocumentosXServiciosCotejos() {
      const { value } = await DocumentosXServiciosApi.list({
        page: 0,
        registersPerPage: 0,
        cat_servicios_id: 4,
        id_proceso: this.cotejo.id
      })
      this.documentosXServicios = value;
      return;
    },


    async getDocumentosCotejos() {
      this.cotejo.documentos = await Promise.all(
        this.documentosXServicios.map(documentoXServicio =>
          this.getDocumentoDetail(documentoXServicio.documentos_id)
        )
      );
    },


    async getDocumentoDetail(id) {
      return DocumentosApi.detail(id);
    },


    async addTipoToDocumentos() {
      this.cotejo.documentos = await Promise.all(
        this.cotejo.documentos.map(async documento => {
          documento.tipo = this.getCatDocumentoNombreById(documento.cat_documentos_id);
          return documento;
        })
      );
    },


    getCatDocumentoNombreById(cat_documentos_id) {
      const catDocumento = this.catDocumentos.find(doc => doc.id == cat_documentos_id);
      return catDocumento ? catDocumento.documento : null;
    },


    async getDomicilio() {
      this.cotejo.domicilio = await DomiciliosApi.detail(this.cotejo.domicilios_id);
      return;
    },


    async getCotizacionComplementaria() {
      if (this.cotejo.id_cotizaciones_complementario == null) {
        return;
      }
      const { liga_pago } = await CotizacionesApi.detail(this.cotejo.id_cotizaciones_complementario);
      this.cotejo.liga_pago_complementario = liga_pago;
      return;
    },


    getEstatus() {
      this.changeCurrentComponentBody('ValidaComparecientes');
      if (this.cotejo.validaSolicitantes == 1) {
        this.changeCurrentComponentValue('ValidaComparecientes', 'ValidaDocumentos');
      }
      if (this.cotejo.validaDocumentos == 1) {
        if (!this.cotejo.envio_domicilio) {
          this.changeCurrentComponentValue('ValidaDocumentos', 'EstatusDocumentos');
        } else {
          this.changeCurrentComponentValue('ValidaDocumentos', 'Domicilio');
          if (this.cotejo.validaDomicilio == 1) {
            this.changeCurrentComponentValue('Domicilio', 'EstatusDocumentos');
          }
        }
      }
      if (this.cotejo.pago_complementario) {
        this.cotejo.estatusDocumentos = 'pago';
      }
      if ([18, 19, 20, 21, 74, 75, 76].includes(this.cotejo.id_estatus)) {
        this.changeCurrentComponentValue('EstatusDocumentos', 'PagoComplementario');
      }
      if ([19, 20, 21, 74, 75, 76].includes(this.cotejo.id_estatus)) {
        this.cotejo.estatusDocumentos = 'ok';
        this.changeCurrentComponentValue('PagoComplementario', 'FAD');
      }
      if ([19, 20, 21, 75].includes(this.cotejo.id_estatus)) {
        this.changeCurrentComponentValue('FAD', 'Actas');
      }
      if ([20, 21].includes(this.cotejo.id_estatus)) {
        this.changeCurrentComponentValue('Actas', 'Expediente');
      }
      if (this.cotejo.id_estatus == 21) {
        this.changeCurrentComponentValue('Expediente', 'Terminado');
      }

      return;
    },


    openValidateModal() {
      this.$refs["modal-confirm-validate"].show();
    },


    hideValidateModal() {
      this.$refs["modal-confirm-validate"].hide();
    },


    postMethod() {
      this.hideValidateModal();

      switch (this.modalValidateMethod) {
        case 'ValidaComparecientes':
          this.loading = true;
          this.cotejo.validaSolicitantes = 1;
          this.actualizaCompareciente()
            .then(() => this.actualizaRepresentante())
            .then(() => this.actualizaCotejo())
            .then(() => this.changeCurrentComponentValue('ValidaComparecientes', 'ValidaDocumentos'))
            .catch((error) => {
              this.showErrorToast('Sucedió un error, intente más tarde');
              console.log(error);
            })
            .finally(() => { this.loading = false; })
          break;
        case 'ValidaDocumentos':
          this.cotejo.validaDocumentos = 1;
          this.actualizaCotejo();
          if (!this.cotejo.envio_domicilio) {
            this.changeCurrentComponentValue('ValidaDocumentos', 'EstatusDocumentos');
          } else {
            this.changeCurrentComponentValue('ValidaDocumentos', 'Domicilio');
          }
          break;
        case 'Domicilio':
          this.cotejo.validaDomicilio = 1;
          this.actualizaDomicilio();
          this.actualizaCotejo();
          this.changeCurrentComponentValue('Domicilio', 'Correduria');
          break;
        case 'Correduria':
          this.actualizaCotejo();
          this.changeCurrentComponentValue('Correduria', 'EstatusDocumentos');
          break;
        case 'EstatusDocumentos':
          if (!this.cotejo.estatusDocumentos) {
            this.showErrorToast('Por favor ingresa la información solicitada.');
            break;
          }
          let destinyComponent = '';
          if (this.cotejo.estatusDocumentos == 'ok') {
            this.cotejo.id_estatus = 74;
            destinyComponent = 'FAD';
          } else if (this.cotejo.estatusDocumentos == 'pago') {
            this.cotejo.id_estatus = 18;
            this.cotejo.pago_complementario = 1;
            destinyComponent = 'PagoComplementario';
          } else if (this.cotejo.estatusDocumentos == 'no ok') {
            this.showErrorToast('Por favor contacte al cliente.');
            break;
          }
          this.actualizaCotejo();
          this.changeCurrentComponentValue('EstatusDocumentos', destinyComponent);
          break;
        case 'PagoComplementario':
          this.actualizaCotejo();
          break;
        case 'PagadoComplementario':
          this.cotejo.id_estatus = 74;
          this.changeCurrentComponentValue('PagoComplementario', 'FAD');
          this.actualizaCotejo();
          break;
        case 'FAD':
          this.cotejo.id_estatus = 76;
          this.actualizaCotejo();
          break;
        case 'checkFadStatus':
          this.loading = true;
          this.checkFad()
            .then(() => this.getCotejo())
            .then(() => {
              if (this.isFadSigned()) {
                this.changeCurrentComponentValue('FAD', 'Actas')
              } else {
                this.showErrorToast('El cliente no ha firmado el documento. Intente más tarde.')
              }
            })
            .catch((error) => {
              console.log(error);
              this.showErrorToast('Hubo un error al checar el estatus de FAD. Intente más tarde.')
            })
            .finally(() => { this.loading = false; });
          break;
        case 'Actas':
          this.cotejo.id_estatus = 20;
          this.actualizaCotejo();
          this.changeCurrentComponentValue('Actas', 'Expediente');
          break;
        case 'Expediente':
          this.changeCurrentComponentValue('Expediente', 'Terminado');
          break;
        case 'Terminado':
          this.cotejo.id_estatus = 21;
          this.actualizaCotejo();
          break;
      }
    },


    async actualizaDomicilio() {
      return await DomiciliosApi.edit(this.cotejo.domicilios_id, {
        calle: this.cotejo.domicilio.calle,
        num_exterior: this.cotejo.domicilio.num_exterior,
        num_interior: this.cotejo.domicilio.num_interior,
        colonia: this.cotejo.domicilio.colonia,
        delegacion_municipio: this.cotejo.domicilio.delegacion_municipio,
        ciudad: this.cotejo.domicilio.ciudad,
        entidad: this.cotejo.domicilio.entidad,
        pais: this.cotejo.domicilio.pais,
        codigo_postal: this.cotejo.domicilio.codigo_postal,
        extranjero: 0,
        tipo: 'Personal',
        tieneExtranjero: null,
        tieneTipo: null,
        lat: this.cotejo.domicilio.lat,
        lng: this.cotejo.domicilio.lng,
        valido: 1,
        comentario: null,
        validador: null,
        activo: 1
      })
    },


    async actualizaCotejo() {
      delete this.cotejo.actualizado;
      this.loading = true;
      try {
        await CotejosApi.edit(this.cotejo.id, this.cotejo);
      } catch (error) {
        console.log(error);
        this.showErrorToast('No se pudo actualizar la copia certificada. Intente más tarde.')
      } finally {
        this.loading = false;
        return;
      }
    },


    async actualizaCompareciente() {
      let compareciente = this.cotejo.solicitantes.length == 1 ? this.cotejo.solicitantes[0] : null;

      if (!compareciente) {
        compareciente = this.cotejo.solicitantes.find(solicitante => solicitante.persona == 'Moral');
      }

      compareciente.tipo_validacion = compareciente.persona == 'Moral' ? "new_constitucion_pm" : "new_constitucion_pf";

      if (compareciente.hasOwnProperty('telefono') && compareciente.telefono != {}) {
        const index = compareciente.telefonos.findIndex(telefono => telefono.id === compareciente.telefono.id);
        compareciente.telefonos[index] = compareciente.telefono;
      }

      compareciente.completado = true;
      compareciente.cat_servicio_id = 4;
      compareciente.servicio_id = this.cotejo.id;

      return await ComparecientesApi.edit(compareciente.id, compareciente);
    },


    async actualizaRepresentante() {
      if (this.cotejo.solicitantes.length == 1) {
        return;
      }
      let representante = this.cotejo.solicitantes.find(solicitante => solicitante.persona == 'Física');
      representante.tipo_validacion = "new_constitucion_pf";
      return await ComparecientesApi.edit(representante.id, representante);
    },

    async checkFad() {
      await FuncionesApi.checkFadKycsEstatus({
        proceso_id: this.cotejo.id,
        cat_servicios_id: 4
      })
    },

    isFadSigned() {
      if (parseInt(this.cotejo.estatus_id) === 75) {
        return true;
      }
      return false;
    }

  }

})
</script>
