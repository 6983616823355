<template>
  <div class="container">

    <b-modal no-close-on-backdrop centered ref="modal-confirm-mail" hide-footer id="modal-confirm-mail">
      <h4 class="text-center">
        ¿Está seguro de validar la Liga de Pago? El cliente será notificado vía email.
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-mail')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="processPago()">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-pagado" hide-footer id="modal-confirm-pagado">
      <h4 class="text-center">
        ¿Está seguro de validar el pago?
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-pagado')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="processPagado()">OK</b-button>
    </b-modal>

    <h4 class="cardD">Pago Complementario</h4>
    <div class="mt-4">
      <ValidationProvider name="liga_pago" rules="required" v-slot="{ errors }"
        :custom-messages="{ required: 'La liga de pago es requerida' }">

        <b-form-group label="Liga de pago Openpay" label-for="input-link-pago-complementario">
          <b-input-group class="mt-3">
            <b-input-group-append>
              <b-button :variant="isTextCopied ? 'success' : 'secondary'" squared v-b-tooltip.hover title="Copiar"
                @click="copyLinkToClipboard">
                <b-icon v-if="isTextCopied" icon="check"></b-icon>
                <b-icon v-else icon="clipboard"></b-icon>
              </b-button>
            </b-input-group-append>
            <b-form-input id="input-link-pago-complementario" type="link" v-model="cotejo.liga_pago_complementario"
              :disabled="[18, 19, 20, 21, 74, 75, 76].includes(cotejo.id_estatus) && !cotejo.staff"
              placeholder="Link Openpay"></b-form-input>
          </b-input-group>
        </b-form-group>

        <!-- <base-input type="text" label="Liga de pago Openpay" placeholder="" v-model="cotejo.liga_pago_complementario"
          :disabled="[18, 19, 20, 21, 74, 75, 76].includes(cotejo.id_estatus) && !cotejo.staff">
        </base-input> -->

        <span class="cardErrors">{{ errors[0] }}</span>
      </ValidationProvider>
    </div>

    <div class="mt-4" v-if="cotejo.staff">
      <div class="">
        <b-button class="btn-fill orange-btn bottom2" size="m" @click="openConfirmPagoModal()"
          :disabled="cotejo.id_cotizaciones_complementario">Validar</b-button>
      </div>
    </div>

    <div class="mt-4" v-if="cotejo.staff">
      <div class="">
        <b-button class="btn-fill green-btn bottom2" size="m" @click="openConfirmPagadoModal()"
          :disabled="cotejo.id_estatus != 18 || !cotejo.id_cotizaciones_complementario">Pagado</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { CotizacionesApi, FuncionesApi } from "@/api";
import loadingMixin from "@/mixins/loadingMixin";

export default ({
  name: "PagoComplementario",

  props: {
    cotejo: { type: Object }
  },

  mixins: [loadingMixin],

  data() {
    return {
      isTextCopied: false,
      headerImgUrl: "https://correduriadigital.blob.core.windows.net/correduria-digital-storage/layouts/cp_logooficial_color.jpg"
    }
  },

  methods: {
    copyLinkToClipboard() {
      if (!this.cotejo.liga_pago_complementario) {
        return;
      }

      navigator.clipboard
        .writeText(this.cotejo.liga_pago_complementario)
        .then(this.isTextCopied = true);
    },

    showErrorToast(msg) {
      this.$toast.error(msg, {
        timeout: 4000, //false,
        hideProgressBar: false,
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        closeButton: "button",
        icon: true,
      });
    },

    openConfirmPagoModal() {
      if (!this.cotejo.liga_pago_complementario) {
        this.showErrorToast('Por favor ingrese una liga de pago.');
        return;
      }
      this.$refs["modal-confirm-mail"].show();
    },

    openConfirmPagadoModal() {
      this.$refs["modal-confirm-pagado"].show();
    },

    processPago() {
      this.toggleOnLoading();
      this.$refs["modal-confirm-mail"].hide();
      this.createCotizacionComplementaria()
        .then(() => this.sendMailPagoComplementario())
        .then(() => this.submitPagoComplementario())
        .catch((error) => {
          this.showErrorToast('Sucedió un error, intente más tarde');
          console.log(error);
        })
        .finally(this.toggleOffLoading);
    },

    async createCotizacionComplementaria() {
      const { id } = await CotizacionesApi.create({
        liga_pago: this.cotejo.liga_pago_complementario,
        activo: 1
      });
      this.cotejo.id_cotizaciones_complementario = id;
      return;
    },

    processPagado() {
      this.$refs["modal-confirm-pagado"].hide();
      this.$emit('pagadoComplementario');
    },

    async sendMailPagoComplementario() {
      const solicitante_o_representante = this.cotejo.solicitantes.find(obj => obj.persona == 'Física');
      const email = solicitante_o_representante.email;

      await FuncionesApi.sendMail({
        emailTo: email,
        header: this.headerImgUrl,
        body: "<p>Buen d&iacute;a:</p>  <p><br></p>  <p>Por este medio le informamos que se requiere de un pago complementario en su proceso de Copias Certificadas.&nbsp;</p>" +
          `<p>Podr&aacute; realizarlo en la siguiente liga: ${this.cotejo.liga_pago_complementario}.</p>  <p><br></p>` +
          "<p>Si tiene alguna duda respecto al proceso, le pedimos que se comunique respondiendo este mail o llamando a Corredur&iacute;a Digital al xxxxxxx.</p>  <p><br></p> " +
          "<p>Saludos cordiales</p>  <p><br></p>  <p><br></p>  <p><br></p>  <p>Atentamente, Equipo de Corredur&iacute;a Digital</p>",
        footer: null,
        subject: "Pago Complementario para Proceso de Copias Certificadas"
      })
      this.$toast.success('Se ha notificado al cliente correctamente.', {
        timeout: 40000, //false,
        hideProgressBar: false,
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        closeButton: "button",
        icon: true,
      });
      return;
    },

    submitPagoComplementario() {
      this.$emit('allChecksPassed');
    }
  }
})
</script>

<style scoped>
.cardErrors {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* color: #9a9a9a; */
  color: #ea640b;
  font-size: 13px;
}

.cardD {
  font-size: 15px !important;
  color: #ea640b;
  margin-top: 0%;
}

.bottom2 {
  float: right;
}

.bottom1 {
  float: left;
}

.green-btn {
  background-color: green;
}

.container>>>.validacionDatosLabel {
  position: absolute;
  margin-left: 30px;
  top: 25%;
  max-width: 1500px;
}
</style>
