import LandingPage from '../pages/LandingPage.vue'
import Login from '../pages/Login.vue'
// import SignIn from '../pages/SignIn.vue'
import Dashboard from '../views/Dashboard.vue'
import RecuperacionPass from '../views/recuperacion/RecuperacionPass.vue'
import ReestablecerPass from '../views/reestablecer/ReestablecerPass.vue'
import VerificarCuenta from '../views/verificacionCuenta/VerificacionCuenta.vue'

import ClientAccount from '../views/clients/cuenta/Cuenta.vue'
import ClientOverview from '../views/clients/dashboard_content/Overview.vue'
import EditConstitucionSociedades from '../views/clients/tasks/EditConstitucionSociedades.vue'
import EditCotejo from '../views/clients/tasks/EditCotejos.vue'
import EditNotificaciones from '../views/clients/tasks/EditNotificaciones.vue'
import EditRatificaciones from '../views/clients/tasks/EditRatificaciones.vue'
import EditRatificacionesDigital from '../views/clients/tasks/EditRatificacionesDigital.vue'
import ClientTasks from '../views/clients/tasks/Tasks.vue'

import AddCompareciente from '../views/clients/comparecientes/AddCompareciente.vue'
import ClientComparecientes from '../views/clients/comparecientes/Comparecientes.vue'
import EditComparecientes from '../views/clients/comparecientes/EditCompareciente.vue'

import Monedero from '../views/clients/monedero/Monedero.vue'

import AddConstitucion from '../views/clients/procesos/constitucion/ConstitucionSociedades.vue'
import AddConstitucionRegresoComparecientes from '../views/clients/procesos/constitucion/ConstitucionSociedadesRegresoComparecientes.vue'
import AddCotejo from '../views/clients/procesos/cotejo/Cotejo.vue'
import AddNotificacion from '../views/clients/procesos/notificaciones/Notificaciones.vue'
import AddRatificacion from '../views/clients/procesos/ratificaciones/Ratificaciones.vue'
import AddRatificacionDigital from '../views/clients/procesos/ratificacionesdigital/RatificacionesDigital.vue'

import AvaluosMejorasInfo from '../views/clients/procesos/info_procesos/AvaluosMejorasInfo.vue'
import ConstitucionSociedadesInfo from '../views/clients/procesos/info_procesos/ConstitucionSociedadesInfo.vue'
import CotejoDocumentosInfo from '../views/clients/procesos/info_procesos/CotejoDocumentosInfo.vue'
import FesHechosInfo from '../views/clients/procesos/info_procesos/FesHechosInfo.vue'
import FormalizacionActasInfo from '../views/clients/procesos/info_procesos/FormalizacionActasInfo.vue'
import NotificacionesInfo from '../views/clients/procesos/info_procesos/NotificacionesInfo.vue'
import RatificacionFirmasInfo from '../views/clients/procesos/info_procesos/RatificacionFirmasInfo.vue'

import Clientes from '../views/staff/users/Clientes.vue'
import EditCliente from '../views/staff/users/EditCliente.vue'

import Tasks from '../views/staff/tasks/Tasks.vue'
import ValidConstitucionSociedades from '../views/staff/tasks/validaciones/ValidConstitucionSociedades.vue'
import ValidCotejo from '../views/staff/tasks/validaciones/ValidCotejo.vue'
import ValidNotificaciones from '../views/staff/tasks/validaciones/ValidNotificaciones.vue'
import ValidRatificaciones from '../views/staff/tasks/validaciones/ValidRatificaciones.vue'
import ValidRatificacionesDigital from '../views/staff/tasks/validaciones/ValidRatificacionesDigital.vue'
// import Terminados from '../views/staff/tasks/Terminados.vue'
import AddFormalizacionActas from '../views/clients/procesos/formalizacionactas/FormalizacionActas.vue'
import AddFormalizacionActasRegresoComparecientes from '../views/clients/procesos/formalizacionactas/FormalizacionActasRegresoComparecientes.vue'
import EditFormalizacionActas from '../views/clients/tasks/EditFormalizacionActas.vue'
import StaffComparecientes from '../views/staff/comparecientes/Comparecientes.vue'
import AddCorreduria from '../views/staff/corredurias/AddCorreduria.vue'
import Corredurias from '../views/staff/corredurias/Corredurias.vue'
import EditCorreduria from '../views/staff/corredurias/EditCorreduria.vue'
import StaffAccount from '../views/staff/cuenta/Cuenta.vue'
import CuponDetalle from '../views/staff/settings/CuponDetalle.vue'
import SettingsSistema from '../views/staff/settings/SettingsSistema.vue'
import ValidFormalizacionActas from '../views/staff/tasks/validaciones/ValidFormalizacionActas.vue'
import AddStaff from '../views/staff/users/AddStaff.vue'
import EditStaff from '../views/staff/users/EditStaff.vue'
import Staff from '../views/staff/users/Staff.vue'

// information page
import Information from '../pages/Information.vue'
// page 404
import NotFound from '../pages/NotFound.vue'
// proximamente
import SoonVuePage from '../pages/SoonVuePage.vue'

import Vue from 'vue'
import VueRouter from 'vue-router'
import InformationInDashboard from '../views/clients/dashboard_content/InformationInDashboard.vue'

import { isLoggedIn, roleIs } from '@/utils/auth'

import ExampleComponent from '@/components/ExampleComponent.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/e82789dd',
    component: ExampleComponent,
    name: 'Example',
    meta: { public: true, disabled: false },
  },
  {
    path: '/',
    component: LandingPage,
    name: 'Home',
    meta: { public: true, disableIfLoggedIn: true },
  },
  {
    path: '/login',
    component: Login,
    name: 'Login',
    meta: { public: true, disableIfLoggedIn: true },
  },
  {
    path: '/recuperacionpass',
    component: RecuperacionPass,
    name: 'RecuperacionPass',
    meta: { public: true, disableIfLoggedIn: true },
  },
  {
    path: '/reestablecerpass/:hash',
    component: ReestablecerPass,
    name: 'ReestablecerPass',
    meta: { public: true, disableIfLoggedIn: true },
  },
  {
    path: '/verificacion/:hash',
    component: VerificarCuenta,
    name: 'VerificarCuenta',
    meta: { public: true, disableIfLoggedIn: true },
  },
  {
    path: '/information/:type',
    component: Information,
    name: 'Information',
    meta: { public: true },
  },
  {
    path: '/dashboard',
    component: Dashboard,
    name: 'Dashboard',
    meta: { public: false, requiresAuth: true },
    children: [
      {
        path: 'overview',
        name: 'ClientOverview',
        component: ClientOverview,
        meta: { public: false, requiresAuth: true },
      },
      {
        path: 'clientaccount',
        name: 'ClientAccount',
        component: ClientAccount,
        meta: { public: false, requiresAuth: true },
      },
      {
        path: 'clienttasks',
        name: 'ClientTasks',
        component: ClientTasks,
        meta: { public: false, requiresAuth: true },
      },
      {
        path: 'clienttasks/editconstitucion/:id',
        name: 'EditConstitucionSociedades',
        component: EditConstitucionSociedades,
        meta: { public: false, requiresAuth: true },
      },
      {
        path: 'clienttasks/editnotificacion/:id',
        name: 'EditNotificaciones',
        component: EditNotificaciones,
        meta: { public: false, requiresAuth: true },
      },
      {
        path: 'clienttasks/editratificacion/:id',
        name: 'EditRatificaciones',
        component: EditRatificaciones,
        meta: { public: false, requiresAuth: true },
      },
      {
        path: 'clienttasks/editratificaciondigital/:id',
        name: 'EditRatificacionesDigital',
        component: EditRatificacionesDigital,
        meta: { public: false, requiresAuth: true },
      },
      {
        path: 'clienttasks/editcotejo/:id',
        name: 'EditCotejo',
        component: EditCotejo,
        meta: { public: false, requiresAuth: true },
      },
      {
        path: 'clienttasks/editformalizacion/:id',
        name: 'EditFormalizacionActas',
        component: EditFormalizacionActas,
        meta: { public: false, requiresAuth: true },
      },
      {
        path: 'comparecientes',
        name: 'ClientComparecientes',
        component: ClientComparecientes,
        meta: { public: false, requiresAuth: true },
      },
      {
        path: 'monedero',
        name: 'Monedero',
        component: Monedero,
        meta: { public: false, requiresAuth: true },
      },
      {
        path: 'comparecientes/editcomparecientes/:id',//?proceso&procesoId
        name: 'EditComparecientes',
        component: EditComparecientes,
        meta: { public: false, requiresAuth: true },
      },
      {
        path: 'comparecientes/addcompareciente',
        name: 'AddCompareciente',
        component: AddCompareciente,
        meta: { public: false, requiresAuth: true },
      },

      {
        path: 'procesos/addconstitucion',
        name: 'AddConstitucion',
        component: AddConstitucion,
        meta: { public: false, requiresAuth: true },
      },
      {
        path: 'procesos/newconstitucion/:id',
        name: 'AddConstitucionRegresoComparecientes',
        component: AddConstitucionRegresoComparecientes,
        meta: { public: false, requiresAuth: true },
      },
      {
        path: 'procesos/addformalizacion',
        name: 'AddFormalizacionActas',
        component: AddFormalizacionActas,
        meta: { public: false, requiresAuth: true },
      },
      {
        path: 'procesos/newformalizacion/:id',
        name: 'AddFormalizacionActasRegresoComparecientes',
        component: AddFormalizacionActasRegresoComparecientes,
        meta: { public: false, requiresAuth: true },
      },
      {
        path: 'constitucioninfo',
        name: 'ConstitucionSociedadesInfo',
        component: ConstitucionSociedadesInfo,
        meta: { public: false, requiresAuth: true },
      },
      {
        path: 'formalizacioninfo',
        name: 'FormalizacionActasInfo',
        component: FormalizacionActasInfo,
        meta: { public: false, requiresAuth: true },
      },
      {
        path: 'procesos/addnotificacion',
        name: 'AddNotificacion',
        component: AddNotificacion,
        meta: { public: false, requiresAuth: true },
      },
      {
        path: 'notificacionesinfo',
        name: 'NotificacionesInfo',
        component: NotificacionesInfo,
        meta: { public: false, requiresAuth: true },
      },
      {
        path: 'procesos/addcotejo',
        name: 'AddCotejo',
        component: AddCotejo,
        meta: { public: false, requiresAuth: true },
      },
      {
        path: 'cotejoinfo',
        name: 'CotejoDocumentosInfo',
        component: CotejoDocumentosInfo,
        meta: { public: false, requiresAuth: true },
      },
      {
        path: 'fesinfo',
        name: 'FesHechosInfo',
        component: FesHechosInfo,
        meta: { public: false, requiresAuth: true },
      },
      {
        path: 'ratificacioninfo',
        name: 'RatificacionFirmasInfo',
        component: RatificacionFirmasInfo,
        meta: { public: false, requiresAuth: true },
      },
      {
        path: 'procesos/addratificacion',
        name: 'AddRatificacion',
        component: AddRatificacion,
        meta: { public: false, requiresAuth: true },
      },
      {
        path: 'procesos/addratificaciondigital',
        name: 'AddRatificacionDigital',
        component: AddRatificacionDigital,
        meta: { public: false, requiresAuth: true },
      },
      {
        path: 'avaluosinfo',
        name: 'AvaluosMejorasInfo',
        component: AvaluosMejorasInfo,
        meta: { public: false, requiresAuth: true },
      },
      {
        path: 'staffaccount',
        name: 'StaffAccount',
        component: StaffAccount,
        meta: { public: false, requiresAuth: true, requiresStaff: true },
      },
      {
        path: 'corredurias',
        name: 'Corredurias',
        component: Corredurias,
        meta: { public: false, requiresAuth: true, requiresStaff: true },
      },
      {
        path: 'corredurias/editcorreduria/:id',
        name: 'EditCorreduria',
        component: EditCorreduria,
        meta: { public: false, requiresAuth: true, requiresStaff: true },
      },
      {
        path: 'corredurias/addcorreduria',
        name: 'AddCorreduria',
        component: AddCorreduria,
        meta: { public: false, requiresAuth: true, requiresStaff: true },
      },

      {
        path: 'overview',
        name: 'Tasks',
        component: Tasks,
        meta: { public: false, requiresAuth: true, requiresStaff: true },
      },
      {
        path: 'tasks/valid/constitucion/:id',
        name: 'ValidConstitucionSociedades',
        component: ValidConstitucionSociedades,
        meta: { public: false, requiresAuth: true, requiresStaff: true },
      },
      {
        path: 'tasks/valid/notificacion/:id',
        name: 'ValidNotificaciones',
        component: ValidNotificaciones,
        meta: { public: false, requiresAuth: true, requiresStaff: true },
      },
      {
        path: 'tasks/valid/ratificacion/:id',
        name: 'ValidRatificaciones',
        component: ValidRatificaciones,
        meta: { public: false, requiresAuth: true, requiresStaff: true },
      },
      {
        path: 'tasks/valid/ratificaciondigital/:id',
        name: 'ValidRatificacionesDigital',
        component: ValidRatificacionesDigital,
        meta: { public: false, requiresAuth: true, requiresStaff: true },
      },
      {
        path: 'tasks/valid/cotejo/:id',
        name: 'ValidCotejo',
        component: ValidCotejo,
        meta: { public: false, requiresAuth: true, requiresStaff: true },
      },
      {
        path: 'tasks/valid/formalizacion/:id',
        name: 'ValidFormalizacionActas',
        component: ValidFormalizacionActas,
        meta: { public: false, requiresAuth: true, requiresStaff: true },
      },

      {
        path: 'clientes',
        name: 'Clientes',
        component: Clientes,
        meta: { public: false, requiresAuth: true },
      },
      {
        path: 'clientes/editcliente/:id',
        name: 'EditCliente',
        component: EditCliente,
        meta: { public: false, requiresAuth: true },
      },
      {
        path: 'staff',
        name: 'Staff',
        component: Staff,
        meta: { public: false, requiresAuth: true, requiresStaff: true },
      },
      {
        path: 'staff/editstaff/:id',
        name: 'EditStaff',
        component: EditStaff,
        meta: { public: false, requiresAuth: true, requiresStaff: true },
      },
      {
        path: 'staff/addstaff',
        name: 'AddStaff',
        component: AddStaff,
        meta: { public: false, requiresAuth: true, requiresStaff: true },
      },
      {
        path: 'settings',
        name: 'SettingsSistema',
        component: SettingsSistema,
        meta: { public: false, requiresAuth: true, requiresStaff: true },
      },
      {
        path: 'comparecientesStf',
        name: 'StaffComparecientes',
        component: StaffComparecientes,
        meta: { public: false, requiresAuth: true, requiresStaff: true },
      },
      {
        path: 'settings/cupondetalle/:id',
        name: 'CuponDetalle',
        component: CuponDetalle,
        meta: { public: false, requiresAuth: true, requiresStaff: true },
      },
      {
        path: 'information/:type',
        component: InformationInDashboard,
        name: 'InformationInDashboard',
        meta: { public: false, requiresAuth: true },
      },
      // Path proximamente
      {
        path: 'proximamente',
        component: SoonVuePage,
      },
    ]


  },
  // proximamente

  { path: '*', component: NotFound } //si ponen otro path que no existe, mando a inicio
]


const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        // x, y as top-level variables define position not offset
        selector: to.hash,
        // offset has to be set as an extra object
        offset: { x: 0, y: 64 }
      }
    }
  }
});

router.beforeEach((to, from, next) => {
  if (to.meta.disabled) {
    next({ name: 'Home' });
  }

  if (!to.meta.public) {
    if (isLoggedIn()) {
      if (to.meta.requiresStaff) {
        if (roleIs() == 'Staff Administrador' || roleIs() == 'Staff Usuario') {
          next();
        }
        else {
          next({ name: 'Dashboard' });
        }
      }

      next();
    }
    else {
      next({ name: 'Login' });
    }
  }
  else if (to.meta.public && to.meta.disableIfLoggedIn && isLoggedIn()) {
    next({ name: 'Dashboard' });
  }

  next();
});

export default router
