<template>
  <div class="container">
    <h4 class="cardD">Asignar Correduría</h4>

    <div class="container">
      <div v-if="loading">
        <div id="loader">
          <font-awesome-icon icon="spinner" spin />
        </div>
      </div>
    </div>

    <div class="mt-5 mb-4"><label for="">Ubicación del cliente</label></div>

    <div class="row ">
      <div class="col-3">
        <label>Entidad federativa</label>
      </div>
      <div class="col-9">
        {{ entidad }}
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-3">
        <label>Municipio</label>
      </div>
      <div class="col-9">
        {{ municipio }}
      </div>
    </div>

    <label>Correduría</label>
    <select class="custom-select" v-model="notificacion.corredurias_id">
      <option v-for="option in options_correduria" :key="option.value" :value="option.value">
        {{ option.text }}
      </option>
    </select>

    <div class="mt-4">
      <div class="row">
        <div class="col-12">
          <b-button type="submit" class="btn-fill orange-btn bottom2" size="m">Asignar</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FuncionesApi } from "@/api";

export default {
  name: "TabAsignarCorreduria",

  props: {
    notificacion: { type: Object }
  },

  created() {
    this.getCorredurias();
  },

  data() {
    return {
      loading: false,
      entidad: null,
      municipio: null,
      options_correduria: [],
    };
  },

  methods: {
    async getCorredurias() {
      try {
        this.entidad = this.notificacion.domicilio.entidad;

        if (this.entidad != "Ciudad de México") {
          this.municipio = this.notificacion.domicilio.delegacion_municipio;
        }
        const { value } = await FuncionesApi.getCorreduriasDomicilios({
          entidad: null,
          municipio: null
        });

        value.forEach(element => {
          const exists = this.options_correduria.findIndex(x => x.text === element.entidad);

          if (exists == -1) {
            const obj = {
              text: `${element.entidad}, ${element.delegacion_municipio}, ${element.colonia}`,
              value: element.corredurias_id
            };

            this.options_correduria.push(obj);
          }
        });
      } catch { }
    },
  }
};
</script>

<style scoped>
.bottom2 {
  /* position: fixed;
  right: 2%;
  bottom: 5%;
  overflow: visible;
  z-index: 100; */
  float: right;
}
</style>
