<template>
  <div class="container">
    <h4 class="cardD">Datos generales de la Nueva Sociedad</h4>

    <div class="row">
      <div class="col-6">
        <ValidationProvider name="tipo_societario" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El tipo de societario es obligatorio',
        }">
          <label for="">Tipo de societario</label>
          <select class="custom-select" v-model="constitutiva.tipo_societario" :disabled="constitutiva.disabled">
            <!--  -->
            <option value="SA">SA</option>
            <option value="S de RL">S de RL</option>
            <option value="SAPI">SAPI</option>
          </select>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <!-- <div class="col-6">
        <label for="input-tiene-capital-variable">Tendrá capital variable</label>
        <b-form-checkbox id="input-tiene-capital-variable" v-model="constitutiva.tiene_capital_variable"
          :disabled="constitutiva.disabled">
        </b-form-checkbox>
      </div> -->
    </div>

    <div class="row mt-4">
      <div class="col-md-12">
        <ValidationProvider name="denominaciones_sociales" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La denominación social es obligatoria',
        }">
          <base-input :disabled="constitutiva.disabled" type="text" label="Denominación social" placeholder="Opción 1"
            v-model="constitutiva.denominaciones_sociales_1">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
        <ValidationProvider name="denominaciones_sociales" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La denominación social es obligatoria',
        }">
          <base-input :disabled="constitutiva.disabled" type="text" label="" placeholder="Opción 2"
            v-model="constitutiva.denominaciones_sociales_2">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
        <ValidationProvider name="denominaciones_sociales" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La denominación social es obligatoria',
        }">
          <base-input :disabled="constitutiva.disabled" type="text" label="" placeholder="Opción 3"
            v-model="constitutiva.denominaciones_sociales_3">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-6">
        <label for="input-autoriza-cd-denominacion">
          En caso de ser rechazada, autoriza a Correduría Digital proponer alguna
        </label>
        <b-form-checkbox id="input-autoriza-cd-denominacion" v-model="constitutiva.autoriza_cd_denominacion"
          :disabled="constitutiva.disabled">
        </b-form-checkbox>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-md-12">
        <ValidationProvider name="objeto_social" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El objeto social es obligatorio',
        }">
          <label for="">Objeto social</label>
          <select class="custom-select" v-model="constitutiva.objeto_social" :disabled="constitutiva.disabled">
            <option v-for="option in optionsObjetoSocial" :key="option.value">
              {{ option.text }}
            </option>
          </select>

          <base-input :disabled="constitutiva.disabled" class="mt-2" type="text" label="" placeholder="Objeto Social"
            v-model="constitutiva.objeto_social_otro" v-if="constitutiva.objeto_social === 'Otro'">
          </base-input>

          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-md-12">
        <ValidationProvider name="objeto_actividad" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El objeto y actividad es obligatorio',
        }">
          <label>Cuéntanos qué actividades desarrollará la empresa</label>
          <b-form-textarea :disabled="constitutiva.disabled" type="text"
            placeholder="Actividades que desarrollará la empresa" v-model="constitutiva.objeto_actividad">
          </b-form-textarea>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="mt-4">
      <div class="">
        <b-button type="submit" class="btn-fill orange-btn bottom2" size="m"
          :disabled="constitutiva.disabled">Continuar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
// import { CatObjetosSocialesApi } from "@/api";

export default {
  name: "DatosGenerales",

  props: {
    constitutiva: { type: Object },
    optionsObjetoSocial: { type: Array },
  },

  // created() {
  //   this.getCatObjetosSociales();
  // },

  data() {
    return {
      // optionsObjetoSocial: [],
      form: {
        tipo_societario: null,
        // denominaciones_sociales: [],
        denominaciones_sociales_1: null,
        denominaciones_sociales_2: null,
        denominaciones_sociales_3: null,
        tiene_capital_variable: null,
        autoriza_cd_denominacion: null,
        objeto_social: null,
        objeto_social_otro: null,
        objeto_actividad: null,
      },
    };
  },

  methods: {
    // async getCatObjetosSociales() {
    //   const { value } = await CatObjetosSocialesApi.list();
    //   value.forEach((element) => {
    //     const obj = {
    //       text: element.objeto_social,
    //       value: element.objeto_social,
    //     };
    //     this.optionsObjetoSocial.push(obj);
    //   });
    //   const last = { text: "Otro", value: "Otro" };
    //   this.optionsObjetoSocial.push(last);
    // },
    // async saveConstitucion() {
    //   // console.log("saveConstitucion");
    //   this.selected();
    // },
    // selected() {
    //   let component = "DatosCapital"; // node.model.component;
    //   this.$emit("node", component);
    // },
  },
};
</script>

<style scoped>
.bottom2 {
  /* position: fixed;
  right: 2%;
  bottom: 5%;
  overflow: visible;
  z-index: 100; */
  float: right;
}
</style>
