<template>
  <div class="container">
    <h4 class="cardD">Documentos</h4>

    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(formatDiligencia())">
        <div class="row mt-1">
          <b-table ref="table" hover :fields="fieldsDocumentos" :items="documentos_principales">
            <template #cell(fecha)="{ item }">
              <base-input id="fecha" class="date-input" type="date" v-model="item.fecha"
                :disabled="ratificacion.validaDiligencia == 1">
              </base-input>
            </template>
            <template #cell(anexosdigitales)="{ item }">
              <div v-if="item.anexosdigitales > 0">
                SI
              </div>
              <div v-else>
                NO
              </div>
            </template>
            <template #cell(tiene_idioma_extranjero)="{ item }">
              <div class="col-6">
                <b-form-radio v-model="item.idioma_extranjero" :name="`radios-${item.id}`" value="1"
                  :disabled="ratificacion.validaDiligencia == 1">
                  Sí
                </b-form-radio>
              </div>
              <div class="col-6">
                <b-form-radio v-model="item.idioma_extranjero" :name="`radios-${item.id}`" value="0"
                  :disabled="ratificacion.validaDiligencia == 1">
                  No
                </b-form-radio>
              </div>
            </template>
          </b-table>
        </div>

        <div class="row mt-2"></div>
        <h4 class="cardD">Preguntas</h4>
        <div class="row mt-1"></div>

        <div class="row mt-1">
          <div class="col-12">
            <ValidationProvider name="todas_firmaron" rules="required" v-slot="{ errors }" :custom-messages="{
              required: 'Este campo es obligatorio.'
            }">
              <label for="todas_firmaron" class="label">¿Todas las personas que firmaron el documento,
                ¿ratifican el mismo?
              </label>
              <div class="row mt-2">
                <b-form-radio v-model="todos_firmaron" name="radios_todos_firmaron" value="1"
                  :disabled="ratificacion.validaDiligencia == 1">
                  Sí
                </b-form-radio>
              </div>
              <div class="row mt-1">
                <b-form-radio v-model="todos_firmaron" name="radios_todos_firmaron" value="0"
                  :disabled="ratificacion.validaDiligencia == 1">
                  No
                </b-form-radio>
              </div>
              <span class="cardErrors">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>

        <div class="row mt-1" v-if="todos_firmaron == '0'">
          <div class="col-12">
            <ValidationProvider rules="required" v-slot="{ errors }" :custom-messages="{
              required: 'Este campo es requerido.',
            }">
              <label for="reporte_fotografico" class="label">¿Quiénes ratifican?</label>
              <base-input type="text" v-model="firmantes_si_ratificaron" disabled>
              </base-input>
              <span class="cardErrors">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>

        <div class="row mt-1" v-if="todos_firmaron == '0'">
          <div class="col-12">
            <ValidationProvider rules="required" v-slot="{ errors }" :custom-messages="{
              required: 'Este campo es requerido.',
            }">
              <label for="reporte_fotografico" class="label">¿Quiénes NO ratifican?</label>
              <base-input type="text" placeholder="Separados por comas" v-model="ratificacion.firmantes_no_ratificaron"
                :disabled="ratificacion.validaDiligencia == 1">
              </base-input>
              <span class="cardErrors">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>

        <div class="col-6">
          <ValidationProvider name="numero_ejemplares" rules="required" v-slot="{ errors }" :custom-messages="{
            required: 'Este campo es obligatorio.'
          }">
            <label for="numero_ejemplares" class="label">¿Cuántos ejemplares se solicitaron?
            </label>
            <base-input class="custom-text" type="number" disabled v-model="documentos_entregables">
            </base-input>
            <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="col-6">
          <ValidationProvider name="inscripcion_rug" rules="required" v-slot="{ errors }" :custom-messages="{
            required: 'Este campo es obligatorio.'
          }">
            <label for="inscripcion_rug" class="label">¿Se inscribe en el RUG?
            </label>
            <base-input class="custom-text" type="text" disabled v-model="inscripcion_rug">
            </base-input>
            <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="col-6">
          <ValidationProvider name="monto_garantia" rules="required" v-slot="{ errors }" :custom-messages="{
            required: 'Este campo es obligatorio.'
          }">
            <label for="monto_garantia" class="label">Monto que se garantiza e inscribe
            </label>
            <base-input class="custom-text" type="number" disabled v-model="ratificacion.monto_garantia">
            </base-input>
            <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <br><br>
        <div class="mt-4">
          <b-button type="submit" class="btn-fill orange-btn bottom2" size="m"
            :disabled="ratificacion.validaDiligencia == 1 || !allFieldsCompleted">Validar</b-button>
        </div>

      </form>
    </ValidationObserver>
  </div>
</template>

<script>
export default ({
  name: "Preguntas",

  created() {
    this.loading = true;
    this.formatDocuments()
      .then(() => this.formatFirmantes())
      .catch((error) => {
        console.log(error);
        this.showErrorToast('Intente más tarde')
      })
      .finally(() => { this.loading = false; })
  },

  mounted() {
    this.loading = true;
    this.convertDataToForm()
      .catch((error) => {
        console.log(error);
        this.showErrorToast('Intente más tarde')
      })
      .finally(() => { this.loading = false; })
  },

  props: {
    ratificacion: { type: Object }
  },

  computed: {
    fieldsDocumentos() {
      let fieldsDocumentos = [
        { key: "nombre", label: "Título", class: "center" },
        { key: "fecha", label: "Fecha", class: "center" },
        { key: "paginas", label: "Páginas", class: "center" },
        { key: "anexos", label: "No. Anexos", class: "center" },
        { key: "anexosdigitales", label: "Anexo Digital", class: "center" },
        { key: "tiene_idioma_extranjero", label: "Idioma Extranjero", class: "center" }
      ];
      return fieldsDocumentos;
    }
  },

  data: () => ({
    todos_firmaron: null,
    firmantes_si_ratificaron: '',
    documentos_entregables: null,
    inscripcion_rug: null,
    allFieldsCompleted: null,

    documentos_principales: []

  }),

  watch: {
    documentos_principales: {
      handler(newValue, oldValue) {
        this.isFormFilled();
      },
      deep: true
    },
    todos_firmaron: {
      handler(newValue, oldValue) {
        this.isFormFilled();
      },
      deep: true
    },
    'ratificacion.firmantes_no_ratificaron': {
      handler(newValue, oldValue) {
        this.isFormFilled();
      },
      deep: true
    }
  },

  methods: {
    showErrorToast(msg) {
      this.$toast.error(msg, {
        timeout: 4000, //false,
        hideProgressBar: false,
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        closeButton: "button",
        icon: true,
      });
    },

    convertDataToForm() {
      return new Promise((resolve, reject) => {
        try {

          resolve()
        } catch (error) {
          reject(error)
        }
      })
    },

    formatDocuments() {
      return new Promise((resolve, reject) => {
        try {
          this.documentos_principales = this.ratificacion.documentos.filter(doc =>
            parseInt(doc.cat_documentos_id) == 47);
          this.documentos_principales.forEach(docPrincipal => {
            const idPrincipal = docPrincipal.id;
            const anexos = this.ratificacion.documentos.filter(doc =>
              parseInt(doc.auto_referencia) == idPrincipal);
            docPrincipal.anexos = anexos.filter(anexo => parseInt(anexo.cat_documentos_id) == 48).length;
            docPrincipal.anexosdigitales = anexos.filter(anexo => parseInt(anexo.cat_documentos_id) == 50).length;
          });
          this.documentos_entregables = this.ratificacion.entregables_adicionales + 1;
          this.inscripcion_rug = this.ratificacion.inscripcion_rug == 1 ? 'Sí' : 'No';
          resolve(this.documentos_principales)
        } catch (error) {
          reject(error)
        }
      })
    },

    formatFirmantes() {
      return new Promise((resolve, reject) => {
        try {
          const firmantes = this.ratificacion.ratificantes.filter(ratificante =>
            ratificante.persona == 'Física');
          const nombreFirmantes = firmantes.map(firmante =>
            firmante.materno
              ? firmante.nombre + ' ' + firmante.paterno + ' ' + firmante.materno
              : firmante.nombre + ' ' + firmante.paterno
          );
          this.firmantes_si_ratificaron = nombreFirmantes.toString();
          resolve(this.firmantes_si_ratificaron)
        } catch (error) {
          reject(error)
        }
      })
    },

    isFormFilled() {
      const documentosCompletos = this.documentos_principales.every(doc =>
        doc.fecha !== null && doc.idioma_extranjero !== null);
      const firmaron = this.todos_firmaron !== null;
      let firma = true
      if (firmaron && parseInt(this.todos_firmaron) == 0 && !this.ratificacion.firmantes_no_ratificaron) {
        firma = false;
      }
      this.allFieldsCompleted = documentosCompletos && firmaron && firma;
    },

    formatDiligencia() {
      if (!this.allFieldsCompleted) {
        this.showErrorToast('Por favor ingresa todos los campos antes de continuar.')
        return;
      }

      this.ratificacion.todos_firmaron = parseInt(this.todos_firmaron);
      this.ratificacion.documentos_diligencia = this.documentos_principales;

      this.$emit('allChecksPassed');
    }
  }
})
</script>
