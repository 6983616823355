<template>
  <div class="container">
    <h4 class="cardD">Entrega de información base</h4>
    <card class="blue">
      <i class="fa fa-info"></i>
      Es importante que se conteste correctamente en cada apartado, ya que los
      datos ingresados permiten a Correduría Digital cotizar el trabajo.
      <p></p>
      <i class="fa fa-info"></i>
      Posteriormente se llevará a cabo la revisión y estudio de la información y
      documentación.
      <p></p>
      <i class="fa fa-info"></i>
      En caso de declarar alguna mentira o un campo falso y no se cuente con la
      documentación completa, el Fedatario no formalizará las actas y se
      entenderá que los trabajos de revisión y administrativos equivalen al 50%
      de los honorarios pagados. Se deberá contactar a Correduría Digital para
      el reembolso correspondiente.
      <p></p>
      <i class="fa fa-play"></i>
      Links a videos.
      <p></p>
      <i class="fa fa-question"></i>
      Datos de contacta a un asesor.
    </card>
  </div>
</template>

<script>
export default {
  name: "InfoBase"
};
</script>

<style scoped>
.blue {
  background-color: #effbff;
  border-color: #effbff !important;
  text-align: justify;
}
</style>
