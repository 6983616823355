import { render, staticRenderFns } from "./TabDatosGenerales.vue?vue&type=template&id=6807fe60&scoped=true"
import script from "./TabDatosGenerales.vue?vue&type=script&lang=js"
export * from "./TabDatosGenerales.vue?vue&type=script&lang=js"
import style0 from "./TabDatosGenerales.vue?vue&type=style&index=0&id=6807fe60&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6807fe60",
  null
  
)

export default component.exports