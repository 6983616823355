// API
import BaseApi from './base';

class EstatusApi extends BaseApi {
  constructor() {
    super('estatus');
  }

  procesos(params = {}) {
    return this.request.get(`/estatus/list/procesos`, {
      params: {
        ...params
      }
    });
  }
}

export default new EstatusApi();
