<template>
  <div class="container">
    <div v-if="loading">
      <div id="loader">
        <font-awesome-icon icon="spinner" spin />
      </div>
    </div>

    <h4 class="cardD">
      Entidad Federativa en la que será firmada la Póliza
    </h4>

    <div class="row">
      <div class="col-md-6">
        <label>Entidad Federativa</label>
        <select
          :disabled="formalizacion.disabled"
          class="custom-select"
          v-model="formalizacion.selectedEntidad"
          @change="getMunicipios"
        >
          <option
            v-for="option in formalizacion.optionsEntidades"
            :key="option.value"
          >
            {{ option.text }}
          </option>
        </select>
      </div>

      <div class="col-md-6" v-if="hay_municipios">
        <label>Municipio</label>
        <select
          :disabled="formalizacion.disabled"
          class="custom-select"
          v-model="formalizacion.selectedMunicipio"
          @change="selectCorreduria($event)"
        >
          <option
            v-for="option in formalizacion.optionsMunicipios"
            :key="option.value"
          >
            {{ option.text }}
          </option>
        </select>
      </div>
    </div>

    <!-- <div class="row mt-5">
      <div class="col-8">
        <label for="">La firma será en la oficina del Corredor </label>
        <b-form-checkbox
          v-model="formalizacion.firma_oficina"
          :disabled="formalizacion.disabled"
        >
        </b-form-checkbox>
        <label
          class="mb-12 leyenda"
          v-if="formalizacion.firma_oficina === false"
        >
          Aplicará un costo extra para gastos de movilidad en caso de recabar
          firmas.
        </label>
      </div>
    </div> -->

    <div class="mt-4">
      <div class="">
        <b-button
          type="submit"
          class="btn-fill orange-btn bottom2"
          size="m"
          :disabled="formalizacion.disabled"
          >Continuar</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EntidadFederativa",

  props: {
    catCorredurias: { type: Array },
    formalizacion: { type: Object }
  },

  data() {
    return {
      loading: false,
      hay_municipios: true
    };
  },

  created() {
    // this.formalizacion.disabled = false;

    if (this.formalizacion.selectedEntidad != null) {
      this.getMunicipios();
    }
  },

  methods: {
    async getMunicipios() {
      this.formalizacion.optionsMunicipios = [];
      try {
        if (this.formalizacion.selectedEntidad == "Ciudad de México") {
          //no trae municipios y se oculta
          this.hay_municipios = false;
          this.formalizacion.selectedMunicipio = "";
        } else {
          this.hay_municipios = true;
          this.catCorredurias.forEach(element => {
            if (element.entidad === this.formalizacion.selectedEntidad) {
              const obj = {
                text: element.delegacion_municipio,
                value: element.corredurias_id
              };
              this.formalizacion.optionsMunicipios.push(obj);
            }
          });
        }
      } catch (error) {}
    }
  }
};
</script>

<style scoped>
.bottom2 {
  float: right;
}
.leyenda {
  text-transform: lowercase !important;
  color: #79cff7;
}
.leyenda:first-letter {
  text-transform: capitalize;
}
</style>
