<template>
  <div class="content">
    <!-- contact-us full-screen -->
    <b-breadcrumb>
      <b-breadcrumb-item :to="{ path: '/dashboard/staff' }">
        Staff
      </b-breadcrumb-item>
      <b-breadcrumb-item active>Nuevo staff</b-breadcrumb-item>
    </b-breadcrumb>

    <div class="container">
      <div v-if="loading">
        <div id="loader">
          <font-awesome-icon icon="spinner" spin />
        </div>
      </div>
    </div>

    <div class="wrapper wrapper-full-page section content">
      <div class="container">
        <!-- <h4 slot="header" class="card-title">Iniciar sesión</h4> -->

        <card id="card">
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(newStaff)">
              <Form :user="user" @submit.prevent />
              <!--  -->
            </form>
          </ValidationObserver>
        </card>
      </div>
    </div>

    <!-- <Footer /> -->
  </div>
</template>

<script>
import Card from "src/components/Cards/Card.vue";
// import Footer from "@/layout/ContentFooter.vue";
import Form from "@/views/staff/cuenta/Form.vue";
import Storage from "@/utils/storage";
const storage = new Storage();
import { StaffApi } from "@/api";
import { RolesApi } from "@/api";

export default {
  name: "EditStaff",

  components: {
    Card,
    // Footer,
    Form
  },

  data: () => ({
    id: 0,
    loading: false,
    user: {
      firstName: null,
      lastNameP: null,
      lastNameM: null,
      email: null,
      password: null,
      rol: null,
      telefono: null,
      // persona: null,
      // rfc: null,
      roles_id: null
      // externo: null,
    }
  }),

  methods: {
    async newStaff() {
      this.loading = true;
      if (this.id != 0) {
        this.editStaff();
      } else {
        // const { id } = await RolesApi.rolId(this.user.rol);
        let role_id = await this.getRoleId(this.user.rol);

        // let externo = 0;
        // if (this.externo === true) {
        //   externo = 1;
        // }

        let corredurias_id = storage.getItem("corredurias_id");

        var data = {
          nombre: this.user.firstName,
          paterno: this.user.lastNameP,
          materno: this.user.lastNameM,
          email: this.user.email,
          contraseña: this.user.password,
          roles_id: role_id,
          activo: 1,
          corredurias_id: corredurias_id
        };
        try {
          let { id } = await StaffApi.create(data);
          this.id = id;
          this.$toast.success("Staff agregado", {
            timeout: 4000, //false,
            hideProgressBar: false,
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: "button",
            icon: true
          });
          this.loading = false;
        } catch (error) {


          this.$toast.error("Sucedió un error, intente más tarde", {
            timeout: 4000, //false,
            hideProgressBar: false,
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: "button",
            icon: true
          });
          this.loading = false;
        }
      }
    },

    async editStaff() {
      this.loading = true;
      let role_id = await this.getRoleId(this.user.rol);
      // let externo = 0;
      // if (this.user.externo === true) {
      //   externo = 1;
      // }

      var data = {
        nombre: this.user.firstName,
        paterno: this.user.lastNameP,
        materno: this.user.lastNameM,
        email: this.user.email,
        roles_id: role_id,
        activo: 1
        // externo: externo,
      };
      try {
        await StaffApi.edit(this.id, data);
        this.$toast.success("Staff actualizado", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
        this.loading = false;
      } catch (error) {
        this.$toast.error("Sucedió un error, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
        this.loading = false;
      }
    },

    async getRoleId(roleName) {
      const { id } = await RolesApi.rolId(roleName);
      return id;
    }
  }
};
</script>

<style scoped>
/* .container-bread{
  ma
} */
#card {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* color: #9a9a9a; */
  color: #ea640b;
  font-size: 13px;
}
</style>
