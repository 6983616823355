var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('h4',{staticClass:"cardD"},[_vm._v("Realización de Diligencia")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.formatDiligencia)}}},[_c('div',{staticClass:"row mt-1"},[_c('ValidationProvider',{attrs:{"name":"hora_llegada","rules":"required","custom-messages":{
          required: 'Este campo es obligatorio.'
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"label",attrs:{"for":"hora_llegada"}},[_vm._v("1. Fecha y hora de llegada")]),_c('base-input',{staticClass:"date-input",attrs:{"id":"fecha_llegada","disabled":_vm.diligencia_realizada,"type":"date"},model:{value:(_vm.fecha_llegada),callback:function ($$v) {_vm.fecha_llegada=$$v},expression:"fecha_llegada"}}),_c('base-input',{staticClass:"time-input",attrs:{"id":"hora_llegada","disabled":_vm.diligencia_realizada,"type":"time"},model:{value:(_vm.hora_llegada),callback:function ($$v) {_vm.hora_llegada=$$v},expression:"hora_llegada"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"row mt-1"},[_c('ValidationProvider',{attrs:{"name":"forma_cerciorarse","rules":"required","custom-messages":{
          required: 'Este campo es requerido.',
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"label",attrs:{"for":"forma_cerciorarse"}},[_vm._v("2. ¿Cuál fue la forma de cerciorarse del domicilio?")]),_c('b-form-select',{staticClass:"custom-select",attrs:{"id":"forma_cerciorarse","disabled":_vm.diligencia_realizada},model:{value:(_vm.forma_cerciorarse),callback:function ($$v) {_vm.forma_cerciorarse=$$v},expression:"forma_cerciorarse"}},[_c('option',{attrs:{"value":"inmueble_marcado"}},[_vm._v("El inmueble tenía marcado el número del domicilio en su exterior")]),_c('option',{attrs:{"value":"dicho_persona"}},[_vm._v("El dicho de la persona con quien se entendió la diligencia")]),_c('option',{attrs:{"value":"gps"}},[_vm._v("Aplicación GPS")]),_c('option',{attrs:{"value":"otro"}},[_vm._v("Otro")])]),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.forma_cerciorarse == 'otro')?_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-12"},[_c('ValidationProvider',{attrs:{"rules":"required","custom-messages":{
            required: 'Este campo es requerido.',
          }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"type":"text","disabled":_vm.diligencia_realizada,"placeholder":"Otro"},model:{value:(_vm.forma_cerciorarse_otro),callback:function ($$v) {_vm.forma_cerciorarse_otro=$$v},expression:"forma_cerciorarse_otro"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]):_vm._e(),_c('div',{staticClass:"row mt-1"},[_c('ValidationProvider',{attrs:{"name":"tipo_lugar","rules":"required","custom-messages":{
          required: 'Este campo es requerido.',
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"label",attrs:{"for":"tipo_lugar"}},[_vm._v("3. Tipo de lugar en que se practicó la diligencia")]),_c('b-form-select',{staticClass:"custom-select",attrs:{"id":"tipo_lugar","disabled":_vm.diligencia_realizada},model:{value:(_vm.tipo_lugar),callback:function ($$v) {_vm.tipo_lugar=$$v},expression:"tipo_lugar"}},[_c('option',{attrs:{"value":"edificio_corporativo"}},[_vm._v("Edificio corporativo o de oficinas")]),_c('option',{attrs:{"value":"casa_habilitada"}},[_vm._v("Casa habilitada como oficina")]),_c('option',{attrs:{"value":"casa_particular"}},[_vm._v("Casa particular")]),_c('option',{attrs:{"value":"edificio_departamentos"}},[_vm._v("Edificio de departamentos")]),_c('option',{attrs:{"value":"lugar_comercial"}},[_vm._v("Lugar comercial")]),_c('option',{attrs:{"value":"exterior_condominio"}},[_vm._v("Exterior de un condominio maestro")]),_c('option',{attrs:{"value":"terreno"}},[_vm._v("Terreno")]),_c('option',{attrs:{"value":"otro"}},[_vm._v("Otro")])]),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.tipo_lugar == 'otro')?_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-12"},[_c('ValidationProvider',{attrs:{"rules":"required","custom-messages":{
            required: 'Este campo es requerido.',
          }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"type":"text","disabled":_vm.diligencia_realizada,"placeholder":"Otro"},model:{value:(_vm.tipo_lugar_otro),callback:function ($$v) {_vm.tipo_lugar_otro=$$v},expression:"tipo_lugar_otro"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]):_vm._e(),_c('div',{staticClass:"row mt-2"},[_c('ValidationProvider',{attrs:{"name":"momento_clave_atendido","rules":"required","custom-messages":{
          required: 'Este campo es requerido.',
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"label",attrs:{"for":"momento_clave_atendido"}},[_vm._v("4. En el momento clave ¿fue atendido por alguna persona?")]),_c('b-form-select',{staticClass:"custom-select",attrs:{"id":"momento_clave_atendido","disabled":_vm.diligencia_realizada},model:{value:(_vm.momento_clave_atendido),callback:function ($$v) {_vm.momento_clave_atendido=$$v},expression:"momento_clave_atendido"}},[_c('option',{attrs:{"value":"1"}},[_vm._v("Sí")]),_c('option',{attrs:{"value":"0"}},[_vm._v("No")])]),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"row mt-2"},[_c('ValidationProvider',{attrs:{"name":"varios_lugares","rules":"required","custom-messages":{
          required: 'Este campo es requerido.',
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"label",attrs:{"for":"varios_lugares"}},[_vm._v("5. ¿La diligencia se realizó en más de un lugar?")]),_c('b-form-select',{staticClass:"custom-select",attrs:{"id":"varios_lugares","disabled":_vm.diligencia_realizada},model:{value:(_vm.varios_lugares),callback:function ($$v) {_vm.varios_lugares=$$v},expression:"varios_lugares"}},[_c('option',{attrs:{"value":"0"}},[_vm._v("No")]),_c('option',{attrs:{"value":"1"}},[_vm._v("Sí")])]),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.varios_lugares == '1')?_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-12"},[_c('ValidationProvider',{attrs:{"rules":"required","custom-messages":{
            required: 'Este campo es requerido.',
          }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"type":"text","disabled":_vm.diligencia_realizada,"placeholder":"¿Cuál?"},model:{value:(_vm.otro_lugar),callback:function ($$v) {_vm.otro_lugar=$$v},expression:"otro_lugar"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]):_vm._e(),_c('div',{staticClass:"row mt-2"},[_c('ValidationProvider',{attrs:{"name":"abierto_publico","rules":"required","custom-messages":{
          required: 'Este campo es requerido.',
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"label",attrs:{"for":"abierto_publico"}},[_vm._v("6. ¿El inmueble se encontraba abierto al público?")]),_c('b-form-select',{staticClass:"custom-select",attrs:{"id":"abierto_publico","disabled":_vm.diligencia_realizada},model:{value:(_vm.abierto_publico),callback:function ($$v) {_vm.abierto_publico=$$v},expression:"abierto_publico"}},[_c('option',{attrs:{"value":"si_ingreso"}},[_vm._v("Sí, por lo que se ingresó")]),_c('option',{attrs:{"value":"si_no_acceso"}},[_vm._v("Sí, pero no se le permitió el acceso")]),_c('option',{attrs:{"value":"no_atendido_exterior"}},[_vm._v("No, pero fue atendido en el exterior del inmueble")]),_c('option',{attrs:{"value":"no_no_atendido"}},[_vm._v("No, y no fue atendido")])]),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"row mt-2"},[_c('ValidationProvider',{attrs:{"name":"ingreso_numeros_interiores","rules":"required","custom-messages":{
          required: 'Este campo es requerido.',
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"label",attrs:{"for":"ingreso_numeros_interiores"}},[_vm._v("7. ¿Se ingresó a los números interiores del inmueble?")]),_c('b-form-select',{staticClass:"custom-select",attrs:{"id":"ingreso_numeros_interiores","disabled":_vm.diligencia_realizada},model:{value:(_vm.ingreso_numeros_interiores),callback:function ($$v) {_vm.ingreso_numeros_interiores=$$v},expression:"ingreso_numeros_interiores"}},[_c('option',{attrs:{"value":"si_registro"}},[_vm._v("Sí, por medio de un registro")]),_c('option',{attrs:{"value":"si_gafete"}},[_vm._v("Sí, por medio de la entrega de un gafete o control de acceso")]),_c('option',{attrs:{"value":"si_sin_registro"}},[_vm._v("Sí, sin registro, pero autorizado por quien controlaba el acceso")]),_c('option',{attrs:{"value":"si_libre_transito"}},[_vm._v("Sí, por libre tránsito")]),_c('option',{attrs:{"value":"no_atendido_recepcion"}},[_vm._v("No, pero fue atendido en la recepción general en PB")]),_c('option',{attrs:{"value":"no_no_atendido"}},[_vm._v("No, y no fue atendido")])]),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"row mt-2"},[_c('ValidationProvider',{attrs:{"name":"lugar_final_persona_atendio","rules":"required","custom-messages":{
          required: 'Este campo es requerido.',
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"label",attrs:{"for":"lugar_final_persona_atendio"}},[_vm._v("8. En el lugar final, fue atendido por:")]),_c('b-form-select',{staticClass:"custom-select",attrs:{"id":"lugar_final_persona_atendio","disabled":_vm.diligencia_realizada},model:{value:(_vm.lugar_final_persona_atendio),callback:function ($$v) {_vm.lugar_final_persona_atendio=$$v},expression:"lugar_final_persona_atendio"}},[_c('option',{attrs:{"value":"recepcionista"}},[_vm._v("Una recepcionista")]),_c('option',{attrs:{"value":"guardia"}},[_vm._v("Un guardia de seguridad")]),_c('option',{attrs:{"value":"femenino"}},[_vm._v("Una persona de sexo femenino y posteriormente por otra persona")]),_c('option',{attrs:{"value":"masculino"}},[_vm._v("Una persona de sexo masculino y posteriormente por otra persona")])]),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"row mt-3"},[_c('label',{staticClass:"label-long",attrs:{"for":"persona_atendio_sexo"}},[_vm._v("9. Características de la persona con quien, en última instancia, se entendió la diligencia")])]),_c('div',{staticClass:"row mt-2"},[_c('ValidationProvider',{attrs:{"name":"persona_atendio_sexo","rules":"required","custom-messages":{
          required: 'Este campo es requerido.',
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"label",attrs:{"for":"persona_atendio_sexo"}},[_vm._v("Sexo")]),_c('base-input',{staticClass:"custom-text",attrs:{"type":"text","disabled":_vm.diligencia_realizada,"placeholder":"Sexo"},model:{value:(_vm.persona_atendio_sexo),callback:function ($$v) {_vm.persona_atendio_sexo=$$v},expression:"persona_atendio_sexo"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"row mt-2"},[_c('ValidationProvider',{attrs:{"name":"persona_atendio_edad","rules":"required","custom-messages":{
          required: 'Este campo es requerido.',
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"label",attrs:{"for":"persona_atendio_edad"}},[_vm._v("Edad")]),_c('base-input',{staticClass:"custom-text",attrs:{"type":"number","disabled":_vm.diligencia_realizada,"min":1,"placeholder":"1"},model:{value:(_vm.persona_atendio_edad),callback:function ($$v) {_vm.persona_atendio_edad=$$v},expression:"persona_atendio_edad"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"row mt-2"},[_c('ValidationProvider',{attrs:{"name":"persona_atendio_estatura","rules":"required","custom-messages":{
          required: 'Este campo es requerido.',
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"label",attrs:{"for":"persona_atendio_estatura"}},[_vm._v("Estatura (cm)")]),_c('base-input',{staticClass:"custom-text",attrs:{"type":"number","disabled":_vm.diligencia_realizada,"min":1,"placeholder":"1"},model:{value:(_vm.persona_atendio_estatura),callback:function ($$v) {_vm.persona_atendio_estatura=$$v},expression:"persona_atendio_estatura"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"row mt-2"},[_c('ValidationProvider',{attrs:{"name":"persona_atendio_cabello","rules":"required","custom-messages":{
          required: 'Este campo es requerido.',
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"label",attrs:{"for":"persona_atendio_cabello"}},[_vm._v("Cabello")]),_c('base-input',{staticClass:"custom-text",attrs:{"type":"text","disabled":_vm.diligencia_realizada,"placeholder":"Cabello"},model:{value:(_vm.persona_atendio_cabello),callback:function ($$v) {_vm.persona_atendio_cabello=$$v},expression:"persona_atendio_cabello"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"row mt-2"},[_c('ValidationProvider',{attrs:{"name":"persona_atendio_complexion","rules":"required","custom-messages":{
          required: 'Este campo es requerido.',
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"label",attrs:{"for":"persona_atendio_complexion"}},[_vm._v("Complexión")]),_c('base-input',{staticClass:"custom-text",attrs:{"type":"text","disabled":_vm.diligencia_realizada,"placeholder":"Complexión"},model:{value:(_vm.persona_atendio_complexion),callback:function ($$v) {_vm.persona_atendio_complexion=$$v},expression:"persona_atendio_complexion"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"row mt-2"},[_c('ValidationProvider',{attrs:{"name":"momento_clave_persona_atendio","rules":"required","custom-messages":{
            required: 'Este campo es requerido.',
          }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"label",attrs:{"for":"momento_clave_persona_atendio"}},[_vm._v("10. ¿Quién atendió al Corredor Público?")]),_c('b-form-select',{staticClass:"custom-select",attrs:{"id":"momento_clave_persona_atendio","disabled":_vm.momento_clave_atendido == '0' || _vm.diligencia_realizada},model:{value:(_vm.momento_clave_persona_atendio),callback:function ($$v) {_vm.momento_clave_persona_atendio=$$v},expression:"momento_clave_persona_atendio"}},[_c('option',{attrs:{"value":"recepcionista"}},[_vm._v("Una recepcionista")]),_c('option',{attrs:{"value":"guardia"}},[_vm._v("Un guardia de seguridad")]),_c('option',{attrs:{"value":"conserje"}},[_vm._v("Conserje/Portero")]),_c('option',{attrs:{"value":"trabajador"}},[_vm._v("Trabajador")]),_c('option',{attrs:{"value":"familiar"}},[_vm._v("Familiar del notificado")]),_c('option',{attrs:{"value":"mismo"}},[_vm._v("El mismo notificado")]),_c('option',{attrs:{"value":"otro"}},[_vm._v("Otro")])]),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.momento_clave_persona_atendio == 'otro')?_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-12"},[_c('ValidationProvider',{attrs:{"rules":"required","custom-messages":{
            required: 'Este campo es requerido.',
          }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"type":"text","disabled":_vm.diligencia_realizada,"placeholder":"Otro"},model:{value:(_vm.momento_clave_persona_atendio_otro),callback:function ($$v) {_vm.momento_clave_persona_atendio_otro=$$v},expression:"momento_clave_persona_atendio_otro"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]):_vm._e(),_c('div',{staticClass:"row mt-2"},[_c('ValidationProvider',{attrs:{"name":"domicilio_señalado","rules":"required","custom-messages":{
          required: 'Este campo es requerido.',
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"label",attrs:{"for":"domicilio_señalado"}},[_vm._v("11. La persona que atendió la diligencia: ¿Confirmó que el lugar fuera el domicilio señalado?")]),_c('b-form-select',{staticClass:"custom-select",attrs:{"id":"domicilio_señalado","disabled":_vm.diligencia_realizada},model:{value:(_vm.domicilio_señalado),callback:function ($$v) {_vm.domicilio_señalado=$$v},expression:"domicilio_señalado"}},[_c('option',{attrs:{"value":"1"}},[_vm._v("Sí")]),_c('option',{attrs:{"value":"0"}},[_vm._v("No")])]),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"row mt-2"},[_c('ValidationProvider',{attrs:{"name":"domicilio_persona_buscada","rules":"required","custom-messages":{
          required: 'Este campo es requerido.',
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"label",attrs:{"for":"domicilio_persona_buscada"}},[_vm._v("12. La persona que atendió la diligencia: ¿Confirmó que el lugar fuera el domicilio de la persona buscada?")]),_c('b-form-select',{staticClass:"custom-select",attrs:{"id":"domicilio_persona_buscada","disabled":_vm.diligencia_realizada},model:{value:(_vm.domicilio_persona_buscada),callback:function ($$v) {_vm.domicilio_persona_buscada=$$v},expression:"domicilio_persona_buscada"}},[_c('option',{attrs:{"value":"1"}},[_vm._v("Sí")]),_c('option',{attrs:{"value":"0"}},[_vm._v("No")])]),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"row mt-2"},[_c('ValidationProvider',{attrs:{"name":"declaracion_domicilio_negativo","rules":"required","custom-messages":{
            required: 'Este campo es requerido.',
          }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"label",attrs:{"for":"declaracion_domicilio_negativo"}},[_vm._v("13. En caso negativo, ¿qué declaración realizó?")]),_c('b-form-select',{staticClass:"custom-select",attrs:{"id":"declaracion_domicilio_negativo","disabled":_vm.domicilio_persona_buscada == '1' || _vm.diligencia_realizada},model:{value:(_vm.declaracion_domicilio_negativo),callback:function ($$v) {_vm.declaracion_domicilio_negativo=$$v},expression:"declaracion_domicilio_negativo"}},[_c('option',{attrs:{"value":"otro_domicilio"}},[_vm._v("Señaló que la persona buscada tiene otro domicilio")]),_c('option',{attrs:{"value":"otra_persona"}},[_vm._v("Señaló que el domicilio se encontraba ocupado por otra persona")]),_c('option',{attrs:{"value":"desocupado"}},[_vm._v("Señaló que el domicilio estaba desocupado/vacío")]),_c('option',{attrs:{"value":"no_existia"}},[_vm._v("Señaló que el domicilio no existía")]),_c('option',{attrs:{"value":"anteriormente"}},[_vm._v("Señaló que anteriormente el lugar fue domicilio de la persona buscada, pero que ya no lo es")]),_c('option',{attrs:{"value":"sin_registro"}},[_vm._v("No tener registro de las personas")]),_c('option',{attrs:{"value":"otro"}},[_vm._v("Otro")])]),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.declaracion_domicilio_negativo == 'otro')?_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-12"},[_c('ValidationProvider',{attrs:{"rules":"required","custom-messages":{
            required: 'Este campo es requerido.',
          }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"type":"text","disabled":_vm.diligencia_realizada,"placeholder":"Otro"},model:{value:(_vm.declaracion_domicilio_negativo_otro),callback:function ($$v) {_vm.declaracion_domicilio_negativo_otro=$$v},expression:"declaracion_domicilio_negativo_otro"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]):_vm._e(),_c('div',{staticClass:"row mt-2"},[_c('ValidationProvider',{attrs:{"name":"recibio_comunicacion","rules":"required","custom-messages":{
          required: 'Este campo es requerido.',
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"label",attrs:{"for":"recibio_comunicacion"}},[_vm._v("14. ¿La persona que atendió la diligencia recibió la comunicación?")]),_c('b-form-select',{staticClass:"custom-select",attrs:{"id":"recibio_comunicacion","disabled":_vm.diligencia_realizada},model:{value:(_vm.recibio_comunicacion),callback:function ($$v) {_vm.recibio_comunicacion=$$v},expression:"recibio_comunicacion"}},[_c('option',{attrs:{"value":"1"}},[_vm._v("Sí")]),_c('option',{attrs:{"value":"0"}},[_vm._v("No")])]),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"row mt-2"},[_c('ValidationProvider',{attrs:{"name":"instructivo_firmado","rules":"required","custom-messages":{
          required: 'Este campo es requerido.',
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"label",attrs:{"for":"instructivo_firmado"}},[_vm._v("15. ¿La persona que atendió la diligencia firmó el instructivo?")]),_c('b-form-select',{staticClass:"custom-select",attrs:{"id":"instructivo_firmado","disabled":_vm.diligencia_realizada},model:{value:(_vm.instructivo_firmado),callback:function ($$v) {_vm.instructivo_firmado=$$v},expression:"instructivo_firmado"}},[_c('option',{attrs:{"value":"1"}},[_vm._v("Sí")]),_c('option',{attrs:{"value":"0"}},[_vm._v("No")])]),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"row mt-2"},[_c('ValidationProvider',{attrs:{"name":"persona_atendio_nombre_proporcionado","rules":"required","custom-messages":{
            required: 'Este campo es requerido.',
          }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"label",attrs:{"for":"persona_atendio_nombre_proporcionado"}},[_vm._v("16. ¿La persona que atendió la diligencia proporcionó su nombre?")]),_c('b-form-select',{staticClass:"custom-select",attrs:{"id":"persona_atendio_nombre_proporcionado","disabled":_vm.diligencia_realizada},model:{value:(_vm.persona_atendio_nombre_proporcionado),callback:function ($$v) {_vm.persona_atendio_nombre_proporcionado=$$v},expression:"persona_atendio_nombre_proporcionado"}},[_c('option',{attrs:{"value":"1"}},[_vm._v("Sí")]),_c('option',{attrs:{"value":"0"}},[_vm._v("No")])]),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.persona_atendio_nombre_proporcionado == '1')?_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-12"},[_c('ValidationProvider',{attrs:{"rules":"required","custom-messages":{
            required: 'Este campo es requerido.',
          }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"type":"text","disabled":_vm.diligencia_realizada,"placeholder":"Nombre"},model:{value:(_vm.persona_atendio_nombre),callback:function ($$v) {_vm.persona_atendio_nombre=$$v},expression:"persona_atendio_nombre"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]):_vm._e(),_c('div',{staticClass:"row mt-2"},[_c('ValidationProvider',{attrs:{"name":"persona_atendio_identificacion","rules":"required","custom-messages":{
            required: 'Este campo es requerido.',
          }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"label",attrs:{"for":"persona_atendio_identificacion"}},[_vm._v("17. ¿La persona que atendió la diligencia se identificó?")]),_c('b-form-select',{staticClass:"custom-select",attrs:{"id":"persona_atendio_identificacion","disabled":_vm.diligencia_realizada},model:{value:(_vm.persona_atendio_identificacion),callback:function ($$v) {_vm.persona_atendio_identificacion=$$v},expression:"persona_atendio_identificacion"}},[_c('option',{attrs:{"value":"1"}},[_vm._v("Sí")]),_c('option',{attrs:{"value":"0"}},[_vm._v("No")])]),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.persona_atendio_identificacion == '1')?_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-12"},[_c('ValidationProvider',{attrs:{"rules":"required","custom-messages":{
            required: 'Este campo es requerido.',
          }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"type":"text","disabled":_vm.diligencia_realizada,"placeholder":"Tipo de Documento"},model:{value:(_vm.persona_atendio_identificacion_tipo_documento),callback:function ($$v) {_vm.persona_atendio_identificacion_tipo_documento=$$v},expression:"persona_atendio_identificacion_tipo_documento"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]):_vm._e(),(_vm.persona_atendio_identificacion == '1')?_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-12"},[_c('ValidationProvider',{attrs:{"rules":"required","custom-messages":{
            required: 'Este campo es requerido.',
          }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"type":"text","disabled":_vm.diligencia_realizada,"placeholder":"Número de identificación"},model:{value:(_vm.persona_atendio_identificacion_numero),callback:function ($$v) {_vm.persona_atendio_identificacion_numero=$$v},expression:"persona_atendio_identificacion_numero"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]):_vm._e(),_c('div',{staticClass:"row mt-2"},[_c('ValidationProvider',{attrs:{"name":"procedimiento_notificacion","rules":"required","custom-messages":{
          required: 'Este campo es requerido.',
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"label",attrs:{"for":"procedimiento_notificacion"}},[_vm._v("18. ¿Cómo se procedió a realizar la notificación?")]),_c('b-form-select',{staticClass:"custom-select",attrs:{"id":"procedimiento_notificacion","disabled":_vm.diligencia_realizada},model:{value:(_vm.procedimiento_notificacion),callback:function ($$v) {_vm.procedimiento_notificacion=$$v},expression:"procedimiento_notificacion"}},[_c('option',{attrs:{"value":"entregado_instructivo_firmado"}},[_vm._v("El documento fue entregado y el instructivo firmado de recibido")]),_c('option',{attrs:{"value":"entregado_sin_firma"}},[_vm._v("El documento fue entregado, pero sin firma de recibido")]),_c('option',{attrs:{"value":"poder"}},[_vm._v("El documento se dejó en poder de la persona que atendió la diligencia")]),_c('option',{attrs:{"value":"dejo_interior"}},[_vm._v("Se dejó en el interior del inmueble")]),_c('option',{attrs:{"value":"deposito_interior"}},[_vm._v("Se depositó en el interior del inmueble")]),_c('option',{attrs:{"value":"fijo_exterior"}},[_vm._v("Se fijó en el exterior del inmueble")]),_c('option',{attrs:{"value":"otro"}},[_vm._v("Otro")])]),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.procedimiento_notificacion == 'otro')?_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-12"},[_c('ValidationProvider',{attrs:{"rules":"required","custom-messages":{
            required: 'Este campo es requerido.',
          }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"type":"text","disabled":_vm.diligencia_realizada,"placeholder":"Otro"},model:{value:(_vm.procedimiento_notificacion_otro),callback:function ($$v) {_vm.procedimiento_notificacion_otro=$$v},expression:"procedimiento_notificacion_otro"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]):_vm._e(),_c('div',{staticClass:"row mt-1"},[_c('ValidationProvider',{attrs:{"name":"hora_notificacion","rules":"required","custom-messages":{
          required: 'Este campo es obligatorio.'
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"label",attrs:{"for":"hora_notificacion"}},[_vm._v("19. Fecha y hora de la notificación")]),_c('base-input',{staticClass:"date-input",attrs:{"id":"fecha_notificacion","disabled":_vm.diligencia_realizada,"type":"date","min":_vm.fecha_llegada},model:{value:(_vm.fecha_notificacion),callback:function ($$v) {_vm.fecha_notificacion=$$v},expression:"fecha_notificacion"}}),_c('base-input',{staticClass:"time-input",attrs:{"id":"hora_notificacion","disabled":_vm.diligencia_realizada,"type":"time","min":_vm.hora_llegada},model:{value:(_vm.hora_notificacion),callback:function ($$v) {_vm.hora_notificacion=$$v},expression:"hora_notificacion"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"row mt-2"},[_c('ValidationProvider',{attrs:{"name":"persona_atendio_manifestaciones_realizadas","rules":"required","custom-messages":{
            required: 'Este campo es requerido.',
          }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"label",attrs:{"for":"persona_atendio_manifestaciones_realizadas"}},[_vm._v("20. La persona que atendió realizó manifestaciones")]),_c('b-form-select',{staticClass:"custom-select",attrs:{"id":"persona_atendio_manifestaciones_realizadas","disabled":_vm.diligencia_realizada},model:{value:(_vm.persona_atendio_manifestaciones_realizadas),callback:function ($$v) {_vm.persona_atendio_manifestaciones_realizadas=$$v},expression:"persona_atendio_manifestaciones_realizadas"}},[_c('option',{attrs:{"value":"1"}},[_vm._v("Sí")]),_c('option',{attrs:{"value":"0"}},[_vm._v("No")])]),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.persona_atendio_manifestaciones_realizadas == '1')?_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-12"},[_c('ValidationProvider',{attrs:{"rules":"required","custom-messages":{
            required: 'Este campo es requerido.',
          }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"type":"text","disabled":_vm.diligencia_realizada,"placeholder":"¿Cuáles?"},model:{value:(_vm.persona_atendio_manifestaciones),callback:function ($$v) {_vm.persona_atendio_manifestaciones=$$v},expression:"persona_atendio_manifestaciones"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]):_vm._e(),_c('div',{staticClass:"row mt-2"},[_c('ValidationProvider',{attrs:{"name":"reporte_fotografico","rules":"required","custom-messages":{
          required: 'Este campo es requerido.',
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"label",attrs:{"for":"reporte_fotografico"}},[_vm._v("21. ¿Hay reporte fotográfico?")]),_c('b-form-select',{staticClass:"custom-select",attrs:{"id":"reporte_fotografico","disabled":_vm.diligencia_realizada},model:{value:(_vm.reporte_fotografico),callback:function ($$v) {_vm.reporte_fotografico=$$v},expression:"reporte_fotografico"}},[_c('option',{attrs:{"value":"1"}},[_vm._v("Sí")]),_c('option',{attrs:{"value":"0"}},[_vm._v("No")])]),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('br'),_c('br'),_c('div',{staticClass:"mt-4"},[_c('b-button',{staticClass:"btn-fill orange-btn bottom2",attrs:{"type":"submit","size":"m","disabled":_vm.diligencia_realizada}},[_vm._v("Validar")])],1)])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }