<template>
  <div>
    <div class="row">
      <!-- {{ currentCompareciente.lugar_nacim_pais }}
      {{ notificacion.validaSolicitantes }} -->

      <div class="col-md-12">
        <ValidationProvider name="persona" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La persona es obligatoria',
        }">
          <label for="">Persona</label>
          <select class="form-control" v-model="currentCompareciente.persona" disabled>
            <option v-for="option in optionsPersona" :key="option.value" :value="option.value">
              {{ option.text }}
            </option>
          </select>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row mt-4" v-if="currentCompareciente.persona === 'Moral'">
      <div class="col-md-12">
        <ValidationProvider name="denominacion_social" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La denominacion social es obligatoria',
        }">
          <base-input :disabled="notificacion.validaSolicitantes == 1
            " type="text" label="Denominacion social" placeholder="Denominacion social"
            v-model="currentCompareciente.denominacion_social">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row mt-4" v-else-if="currentCompareciente.persona === 'Física'">
      <div class="col-md-4">
        <ValidationProvider name="nombre" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El nombre es obligatorio',
        }">
          <base-input :disabled="notificacion.validaSolicitantes == 1" type="text" label="Nombre" placeholder="Nombre"
            v-model="currentCompareciente.nombre">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <ValidationProvider name="nombre" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El paterno es obligatorio',
        }">
          <base-input :disabled="notificacion.validaSolicitantes == 1" type="text" label="Paterno" placeholder="Paterno"
            v-model="currentCompareciente.paterno">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <base-input :disabled="notificacion.validaSolicitantes == 1" type="text" label="Materno" placeholder="Materno"
          v-model="currentCompareciente.materno">
        </base-input>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-md-4" v-if="currentCompareciente.persona === 'Física'">
        <ValidationProvider name="rfc" :rules="{
          required: true,
          length: 13,
          regex: /^[A-Z&Ñ]{4}\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])[A-Z0-9]{3}$/,
        }" v-slot="{ errors }" :custom-messages="{
          required: 'El RFC es obligatorio',
          length: `Debe tener 13 caracteres`,
          regex: 'El RFC no tiene el formato correcto',
        }">
          <base-input :disabled="notificacion.validaSolicitantes == 1" type="text" label="RFC" placeholder="RFC"
            v-model="currentCompareciente.rfc">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>

      <div class="col-md-6" v-if="currentCompareciente.persona === 'Moral'">
        <ValidationProvider name="rfc" :rules="{
          required: true,
          length: 12,
          regex:
            /^[A-Z&Ñ]{3}\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])[A-Z0-9]{3}$/,
        }" v-slot="{ errors }" :custom-messages="{
          required: 'El RFC es obligatorio',
          length: `Debe tener 12 caracteres`,
          regex: 'El RFC no tiene el formato correcto',
        }">
          <base-input :disabled="notificacion.validaSolicitantes == 1" type="text" label="RFC" placeholder="RFC"
            v-model="currentCompareciente.rfc">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>

      <div class="col-md-4" v-if="currentCompareciente.persona === 'Física'">
        <ValidationProvider name="curp" :rules="{
          required: true,
          length: 18,
          regex:
            /^[A-Z&Ñ]{4}\d{6}[HM](AS|BC|BS|CC|CL|CM|CS|CH|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS)[A-Z0-9]{3}\d{2}$/,
        }" v-slot="{ errors }" :custom-messages="{
          required: 'La CURP es obligatorio',
          length: 'Debe tener 18 caracteres',
          regex: 'La CURP no tiene el formato correcto',
        }">
          <base-input :disabled="notificacion.validaSolicitantes == 1" type="text" label="CURP" placeholder="CURP"
            v-model="currentCompareciente.curp">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>

      <div class="col-md-4" v-if="currentCompareciente.persona === 'Física'">
        <ValidationProvider name="fecha_nacimiento" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La fecha de nacimiento es obligatoria',
        }">
          <base-input :disabled="notificacion.validaSolicitantes == 1" type="date" label="Fecha de nacimiento"
            placeholder="Fecha de nacimiento" v-model="currentCompareciente.fecha_nacimiento">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>

      <div class="col-md-6" v-else-if="currentCompareciente.persona === 'Moral'">
        <ValidationProvider name="fecha_constitucion" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La fecha de constitución es obligatoria',
        }">
          <base-input :disabled="notificacion.validaSolicitantes == 1" type="date" label="Fecha de constitución"
            placeholder="Fecha de constitución" v-model="currentCompareciente.fecha_constitucion">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-md-12" v-if="currentCompareciente.persona === 'Física'">
        <ValidationProvider name="ocupacion" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La ocupación es obligatoria',
        }">
          <base-input :disabled="notificacion.validaSolicitantes == 1" type="text"
            label="Ocupación / Actividad / Profesión" placeholder="Ocupación" v-model="currentCompareciente.ocupacion">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row mb-4" v-if="currentCompareciente.persona === 'Física'">
      <div class="col-md-4">
        <ValidationProvider name="lugar_nacim_pais" rules="required" v-slot="{ errors }"
          :custom-messages="{ required: 'El país es obligatorio' }">
          <label>Lugar de nacimiento: País</label>
          <select @change="fetchStatesByCountry" v-model="selectCountry" class="form-control"
            :disabled="notificacion.validaSolicitantes == 1">
            <option v-for="option in countries" :key="option.id">
              {{ option.name }}
            </option>
          </select>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <ValidationProvider name="lugar_nacim_estado" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El estado es obligatorio',
        }">
          <label>Lugar de nacimiento: Estado</label>
          <select @change="fetchCitiesByState" v-model="selectState" class="form-control" :disabled="notificacion.validaSolicitantes == 1
            ">
            <option v-if="isEmptyStates" value="noStates" selected>
              {{ noStatesText }}
            </option>

            <option v-for="option in states" :key="option.id">
              {{ option.name }}
            </option>
          </select>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <ValidationProvider name="lugar_nacim_ciudad" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La ciudad de nacimiento es obligatoria',
        }">
          <label>Municipio / Alcaldía</label>
          <select v-model="selectCity" class="form-control" :disabled="notificacion.validaSolicitantes == 1">
            <option v-if="isEmptyCities" value="noCities" selected>
              {{ noCitiesText }}
            </option>
            <option v-for="option in cities" :key="option.id">
              {{ option.name }}
            </option>
          </select>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>
    <div class="row mb-4">

      <div class="col-4" v-if="currentCompareciente.persona === 'Moral'">
        <ValidationProvider name="lugar_nacim_pais" rules="required" v-slot="{ errors }"
          :custom-messages="{ required: 'El país es obligatorio' }">
          <label>Lugar de nacimiento: País</label>
          <select @change="fetchStatesByCountry" v-model="selectCountry" class="form-control"
            :disabled="notificacion.validaSolicitantes == 1">
            <option v-for="option in countries" :key="option.id">
              {{ option.name }}
            </option>
          </select>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>

      <div
        :class="{ 'col-md-2': currentCompareciente.persona === 'Física', 'col-md-4': currentCompareciente.persona !== 'Física' }">
        <!-- currentCompareciente.telefonos[0].clave -->
        <ValidationProvider name="clave" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La clave LADA es requerida',
        }">
          <base-input type="text" label="Código país" placeholder="Código país" v-model="phoneKey">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div
        :class="{ 'col-md-5': currentCompareciente.persona === 'Física', 'col-md-4': currentCompareciente.persona !== 'Física' }">
        <ValidationProvider name="numero" rules="required|length:10" v-slot="{ errors }" :custom-messages="{
          required: 'El número de teléfono es requerido',
          length: 'El número debe tener 10 dígitos',
        }">
          <base-input :disabled="notificacion.validaSolicitantes == 1" type="text" label="Número de Teléfono Celular"
            placeholder="Número" v-model="currentCompareciente.telefonos[0].numero">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-5" v-if="currentCompareciente.persona === 'Física'">
        <ValidationProvider name="email" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El email es requerido',
        }">
          <base-input :disabled="notificacion.validaSolicitantes == 1" type="email" label="Email" placeholder="Email"
            v-model="currentCompareciente.email">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>
    <div class="row mt-4"></div>
    <div class="mt-4 text-center">
      <button type="submit" class="btn btn-fill float-right orange-btn" @click="setGeoCompareciente">
        Guardar
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  props: {
    currentCompareciente: { type: Object },
    notificacion: { type: Object },
    solicitantes: { type: Array },
  },

  data() {
    return {
      optionsPersona: [
        { text: "Persona Física", value: "Física" },
        { text: "Persona Moral", value: "Moral" },
      ],
      phoneKey: "",
      noStatesText: "No hay estados disponibles",
      noCitiesText: "No hay ciudades disponibles",
      selectCountry: "",
      selectState: "",
      selectCity: "",
    };
  },

  computed: {
    ...mapState([
      "countries",
      "states",
      "cities",
      "isEmptyStates",
      "isEmptyCities",
    ]),
    ...mapGetters([
      "getCountries",
      "getStatesByCountryId",
      "getCitiesByStateId",
    ]),
  },

  watch: {
    "currentCompareciente.rfc": function (value) {
      if (!value) {
        this.currentCompareciente.rfc = "";

        return;
      }

      this.currentCompareciente.rfc = this.currentCompareciente.rfc.toUpperCase();
    },

    "currentCompareciente.curp": function (value) {
      if (!value) {
        this.currentCompareciente.curp = "";

        return;
      }

      this.currentCompareciente.curp = this.currentCompareciente.curp.toUpperCase();
    }
  },

  created() {
    // this.selectCountry = this.currentCompareciente.lugar_nacim_pais;
    // this.selectState = this.currentCompareciente.lugar_nacim_estado;
    // this.selectCity = this.currentCompareciente.lugar_nacim_ciudad;
    // this.phoneKey = this.currentCompareciente.telefonos[0].clave;
  },

  async mounted() {
    await this.fetchCountries();

    if (this.currentCompareciente.lugar_nacim_pais) {
      this.selectCountry = this.currentCompareciente.lugar_nacim_pais;

      if (this.currentCompareciente.telefonos[0].clave) {
        this.phoneKey = this.currentCompareciente.telefonos[0].clave;
      }
      if (this.currentCompareciente.lugar_nacim_estado) {
        await this.getExistCountry(this.currentCompareciente.lugar_nacim_pais);
      }
      if (this.currentCompareciente.lugar_nacim_estado) {
        await this.getExistState(this.currentCompareciente.lugar_nacim_estado);
      }
      if (this.currentCompareciente.lugar_nacim_ciudad) {
        await this.getExistCity(this.currentCompareciente.lugar_nacim_ciudad);
      }
    }
  },

  methods: {
    ...mapActions(["fetchCountries", "fetchStates", "fetchCities"]),

    async fetchStatesByCountry() {
      /**
       * Esta fx se llama solo si ya se tiene un país seleccionado
       */
      this.selectState = "";
      const pais = this.selectCountry;
      this.selectCity = "";
      // change store state cities to empty
      this.$store.commit("setCities", []);
      // Obtengo el id del país
      const paisId = await this.getIdCountry(pais);
      // obtengo el código de area del país
      const phoneCode = await this.getPhoneCode(pais);
      this.phoneKey = phoneCode;
      // this.currentCompareciente.telefonos[0].clave = phoneCode;
      // obtengo los estados del país
      await this.fetchStates(paisId);
      if (this.isEmptyStates) {
        this.selectState = "noStates";
        this.selectCity = "noCities";
      }
    },

    async fetchCitiesByState() {
      /**
       * Se llama para obtener las ciudades de un estado seleccionado
       */
      this.selectCity = "";
      const estado = this.selectState
      const estadoId = await this.getIdState(estado);
      await this.fetchCities(estadoId);
    },

    async getIdCountry(countryName) {
      /**
       * Obtiene el id del país seleccionado
       */
      const country = this.countries.find((c) => c.name === countryName);
      return country ? country.id : null;
    },

    async getIdState(stateName) {
      /**
       * Obtiene el id del estado seleccionado
       */
      const state = this.states.find((s) => s.name === stateName);
      return state ? state.id : null;
    },

    async getPhoneCode(countryName) {
      /**
       * Obtiene el código de área del país seleccionado
       */
      const country = this.countries.find((c) => c.name === countryName);
      return country ? country.phone_code : null;
    },

    async getExistCountry(countryName) {
      /**
       * Se llama si ya se tiene un país seleccionado
       */
      const currentCountry = countryName === "Mexico" ? "México" : this.currentCompareciente.lugar_nacim_pais;
      this.selectCountry = currentCountry;
    },

    async getExistState(stateName) {
      /**
       * Se llama si ya se tiene un estado seleccionado
       */
      const currentState = stateName
      this.selectState = currentState;
      const paisId = await this.getIdCountry(this.selectCountry);
      await this.fetchStates(paisId);
    },

    async getExistCity(cityName) {
      /**
       * Se llama si ya se tiene una ciudad seleccionada
       */
      const currentCity = cityName;
      this.selectCity = currentCity;
      const estadoId = await this.getIdState(this.selectState);
      await this.fetchCities(estadoId);
    },

    setGeoCompareciente() {
      /**
       * Setea los valores de los campos de compareciente
       */
      this.currentCompareciente.lugar_nacim_pais = this.selectCountry;
      this.currentCompareciente.lugar_nacim_estado = this.selectState;
      this.currentCompareciente.lugar_nacim_ciudad = this.selectCity;
      this.currentCompareciente.telefonos[0].clave = this.phoneKey;
    },
  },
};
</script>

<style scoped>
.cardErrors {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* color: #9a9a9a; */
  color: #ea640b;
  font-size: 13px;
}

.leyenda {
  text-transform: lowercase !important;
  color: #79cff7;
}

.leyenda:first-letter {
  text-transform: capitalize;
}
</style>
