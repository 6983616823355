<template>
  <div class="container">
    <h4 class="cardD">
      Documentos firmados digitalmente
    </h4>

    <div class="container-fluid">
      <b-modal no-close-on-backdrop centered ref="documento-modal-preview-img" title="" hide-footer id="modaldi"
        size="xl">
        <ModalPreviewImg :currentDocumento="currentDocumento" />
      </b-modal>
      <b-modal no-close-on-backdrop centered ref="documento-modal-preview-pdf" title="" hide-footer id="modaldf"
        size="xl">
        <ModalPreviewPDF :currentDocumento="currentDocumento" />
      </b-modal>
      <b-modal no-close-on-backdrop centered ref="documento-modal-preview-video" title="" hide-footer id="modalvideo"
        size="xl">
        <ModalPreviewVideo :currentDocumento="currentDocumento" />
      </b-modal>

      <b-table hover :fields="fields" :items="documentosFAD">
        <template #cell(index)="{ index }">
          {{ getIndex(index + 1) }}
        </template>
        <template #cell(acciones)="{ item }">
          <!-- <b-button
            variant="primary"
            size="sm"
            v-b-tooltip.hover
            title="Vista previa"
            @click="previewDocumento(item)"
            class="editBtn"
            :disabled="constitutiva.disabledExpediente"
          >
            <b-icon-eye />
          </b-button> -->
          <b-button variant="primary" size="sm" v-b-tooltip.hover title="Vista previa" @click="previewDocumento(item)"
            class="editBtn" :hidden="item.disabled">
            <b-icon-eye />
          </b-button>
          <b-button variant="success" size="sm" title="Descarga documento" @click="descargaFile(item.documento)">
            <i class="fa fa-download"></i>
          </b-button>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import ModalPreviewImg from "@/views/clients/procesos/constitucion/entregadocumentos/ModalPreviewImg.vue";
import ModalPreviewPDF from "@/views/clients/procesos/constitucion/entregadocumentos/ModalPreviewPDF.vue";
import ModalPreviewVideo from "@/views/clients/procesos/constitucion/entregadocumentos/ModalPreviewVideo.vue";

export default {
  name: "DocFirmado",
  //acá vienen todos los documentos de FAD
  //que sean los del expediente pero con tipo x=FAD o algo así

  props: {
    formalizacion: { type: Object }
  },

  components: {
    // ModalDocumentos,
    ModalPreviewImg,
    ModalPreviewPDF,
    ModalPreviewVideo
  },

  computed: {
    fields() {
      let fields = [
        { key: "index", label: "", class: "text-center" },
        { key: "nombreFile", label: "Nombre", class: "text-center" },
        { key: "tipo", label: "Tipo", class: "text-center" },
        // { key: "nombre", label: "Persona Física", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" }
      ];
      return fields;
    }
  },

  data() {
    return {
      currentDocumento: null,
      currentDocumentoId: null,
      documentosFAD: [],
      form: {
        documentos: []
      },

      stgAccessKey: "?sv=2023-01-03&st=2024-02-09T17%3A04%3A42Z&se=2034-02-11T17%3A04%3A00Z&sr=c&sp=rl&sig=rq9SDEcZMPv%2FbgiTq9FRE66lHZvEAeeiMqytpyiH2sk%3D",

    };
  },

  created() {
    this.getDocsFAD();
  },

  methods: {
    getDocsFAD() {
      this.formalizacion.documentos.forEach(element => {
        if (element.cat_documentos_id == "88") {
          //tipo FAD
          this.documentosFAD.push(element);
        }
      });
      this.setDisabled();
    },

    getIndex(index) {
      // return index + (this.currentPage - 1) * this.perPage;
      return index + (1 - 1) * this.formalizacion.documentos.length;
    },

    setDisabled() {
      for (let i = 0; i < this.documentosFAD.length; i++) {
        let pretipoDocumento = this.documentosFAD[i].nombreFile.split(".");
        let tipoDocumento = pretipoDocumento[pretipoDocumento.length - 1];
        if (
          tipoDocumento == "docx" ||
          tipoDocumento == "pdf" ||
          tipoDocumento == "jpg" ||
          tipoDocumento == "JPG" ||
          tipoDocumento == "jpeg" ||
          tipoDocumento == "png" ||
          tipoDocumento == "gif" ||
          tipoDocumento == "bmp" ||
          tipoDocumento == "webp" ||
          tipoDocumento == "svg" ||
          tipoDocumento == "tiff" ||
          tipoDocumento == "avif" ||
          tipoDocumento == "mov" ||
          tipoDocumento == "mp4"
        ) {
          this.documentosFAD[i].disabled = false;
        } else {
          this.documentosFAD[i].disabled = true;
        }
      }
    },

    descargaFile(item) {
      const link = document.createElement("a");
      link.href = item + this.stgAccessKey;
      document.body.appendChild(link);
      link.click();
    },

    previewDocumento(item) {
      this.currentDocumento = item;

      let pretipoDocumento = item.nombreFile.split(".");
      let tipoDocumento = pretipoDocumento[pretipoDocumento.length - 1];

      if (tipoDocumento === "pdf") {
        this.$refs["documento-modal-preview-pdf"].show();
      }
      if (
        tipoDocumento === "jpg" ||
        tipoDocumento === "JPG" ||
        tipoDocumento === "jpeg" ||
        tipoDocumento === "png" ||
        tipoDocumento === "gif" ||
        tipoDocumento === "bmp" ||
        tipoDocumento === "webp" ||
        tipoDocumento === "svg" ||
        tipoDocumento === "tiff" ||
        tipoDocumento === "avif"
      ) {
        this.$refs["documento-modal-preview-img"].show();
      }

      if (tipoDocumento === "mov" || tipoDocumento === "mp4") {
        this.$refs["documento-modal-preview-video"].show();
      }
    }
  }
};
</script>

<style scoped></style>
