<template>
  <div>
    <div id="doc">
      <!-- <iframe
        :src="
        'https://view.officeapps.live.com/op/view.aspx?src=' +
          currentDocumento.documento + stgAccessKey
        "
      /> -->
      <VueDocPreview
        :value="
          currentDocumento.documento + stgAccessKey
        "
        type="office"
      />
    </div>
  </div>
</template>

<script>
// import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
import VueDocPreview from "vue-doc-preview";
import config from "../../../../../config"

export default {
  name: "ModalPreviewDocx",

  props: {
    currentDocumento: { type: Object }
  },

  data() {
    return {
      stgAccessKey: "?sv=2023-01-03&st=2024-02-09T17%3A04%3A42Z&se=2034-02-11T17%3A04%3A00Z&sr=c&sp=rl&sig=rq9SDEcZMPv%2FbgiTq9FRE66lHZvEAeeiMqytpyiH2sk%3D",
    }
  },

  components: {
    // VuePdfEmbed,
    VueDocPreview
  }
};
</script>

<style scoped>
#doc {
  width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>
