// API
import BaseApi from './base';

class DocumentosXServiciosApi extends BaseApi {
  constructor() {
    super('documentosServicios');
  }
}

export default new DocumentosXServiciosApi();
