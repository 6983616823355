<template>
    <div>
        <DescriptionServices :datos="datos" />
    </div>
</template>

<script>
import Datos from "@/data/information.json";
import DescriptionServices from "../../../pages/DescriptionServices.vue";


export default {
    name: "InformationInDashboard",
    components: {
        DescriptionServices
    },
    data() {
        return {
            datos: {}
        }
    },
    mounted() {
        this.loadData();
    },
    watch: {
        '$route.params.type': 'loadData'
    },
    methods: {
        loadData() {
            const type = this.$route.params.type;
            this.datos = Datos[type] || {};
        }
    }
} 
</script>