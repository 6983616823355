<template>
  <footer class="footer">
    <div class="container-fluid">
      <!-- <nav>
        <ul class="footer-menu">
          <li>
            <router-link :to="{path:'/admin'}">Dashboard</router-link>
          </li>
        </ul>
      </nav> -->
      <div class="copyright text-center">
        &copy; 2024 Correduría Digital
        <!-- <i class="fa fa-heart heart"></i> by
        <a href="https://binarcode.com" target="_blank">BinarCode</a>.
        Designed by <a href="https://www.creative-tim.com/?ref=pdf-vuejs" target="_blank">Creative Tim</a>. -->
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style>
#copyright
{
  margin-bottom: 0;
}
  
</style>
