<template>
  <div class="container">
    <h4 class="cardD">Extras</h4>

    <div class="row mt-1">
      <div class="col-md-12">
        <ValidationProvider
          name="acta_express"
          rules="required"
          v-slot="{ errors }"
          :custom-messages="{
            required: 'La selección de acta es obligatoria'
          }"
          >
          <label class="control-label">Generación de acta y entregable en plazo de 2-4 días hábiles</label>
          <b-form-checkbox
            v-model="notificacion.acta_express"
          >
          </b-form-checkbox>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-md-12">
        <ValidationProvider
          name="envio_domicilio"
          rules="required"
          v-slot="{ errors }"
          :custom-messages="{
            required: 'La selección de envío es obligatoria'
          }"
          >
          <label class="control-label">Envío de entregable a domicilio</label>
          <b-form-checkbox
            v-model="notificacion.envio_domicilio"
          >
          </b-form-checkbox>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="mt-4" v-if="!notificacion.staff">
      <div class="">
        <b-button
          type="submit"
          class="btn-fill orange-btn bottom2"
          size="m"
          >Continuar</b-button
        >
      </div>
    </div>
    <div class="mt-4" v-else-if="notificacion.staff">
      <div class="">
        <b-button
          type="submit"
          class="btn-fill orange-btn bottom2"
          size="m"
          :disabled="notificacion.validaExtras == 1"
          >Validar</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import BaseCheckbox from '../../../../../components/Inputs/BaseCheckbox.vue'
export default {
  components: { BaseCheckbox },
  name: "Extras",

  props: {
    notificacion: { type: Object }
  },

  data() {
    return {
      form: {
        paginas_primer_documento: 1,
        contiene_anexo: false,
        fad: false,
        numero_anexos: 1,
        paginas_anexo: 1,
        paginas_documento_adicional: 1,
        contenido_digital: false,
        acta_express: false
      }
    }
  }
}
</script>

<style scoped>
.bottom2 {
  /* position: fixed;
  right: 2%;
  bottom: 5%;
  overflow: visible;
  z-index: 100; */
  float: right;
}

.container >>> .form-check-sign {
  float: left;
  margin-left: 10px;
  position: absolute;
}

.container >>> .control-label {
  margin-left: 30px;
  width: 500px;
  position: absolute;
  vertical-align: middle;
}

.container >>> .form-control {
  width: 80px;
  margin-left: 370px;
  position: absolute;
  vertical-align: middle;
}

</style>
