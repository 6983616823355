<template>
  <div class="content">
    <b-breadcrumb>
      <b-breadcrumb-item active>Cuenta</b-breadcrumb-item>
    </b-breadcrumb>
    <div class="container">
      <div v-if="loading">
        <div id="loader">
          <font-awesome-icon icon="spinner" spin />
        </div>
      </div>
    </div>

    <!-- <div class="container-fluid"> -->
    <!-- <div class="row">
      <div class="col-md-12">
        <Form />
      </div>
    </div> -->
    <!-- </div> -->

    <div class="wrapper wrapper-full-page section content">
      <div class="container">
        <!-- <h4 slot="header" class="card-title">Iniciar sesión</h4> -->

        <card id="card">
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(updateProfile)">
              <Form :user="user" @submit.prevent />
            </form>
          </ValidationObserver>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import Card from "src/components/Cards/Card.vue";
import Form from "@/views/staff/cuenta/Form.vue";
import { StaffApi } from "@/api";
import { RolesApi } from "@/api";
import Storage from "@/utils/storage";
const storage = new Storage();

export default {
  name: "EditOwnStaff",

  components: {
    Form,
    Card,
  },

  data: () => ({
    id: 0,
    loading: false,
    user: {
      firstName: null,
      lastNameP: null,
      lastNameM: null,
      email: null,
      password: null,
      rol: null,
      telefono: null,
      persona: null,
      rfc: null,
      roles_id: null,
      // externo: null,
    },
  }),

  created() {
    this.getStaff();
  },

  methods: {
    async getStaff() {
      this.loading = true;
      var staffEmail = storage.getItem("user");
      const { id } = await StaffApi.staffId(staffEmail);
      this.id = id;

      try {
        const {
          nombre,
          paterno,
          materno,
          email,
          telefono,
          persona,
          rfc,
          roles_id,
          // externo,
        } = await StaffApi.detail(id);

        this.user.firstName = nombre;
        this.user.lastNameP = paterno;
        this.user.lastNameM = materno;
        this.user.email = email;
        this.user.telefono = telefono;
        this.user.persona = persona;
        this.user.rfc = rfc;
        this.roles_id = roles_id;

        // // this.externo = false;
        // if (externo == 1) {
        //   this.user.externo = true;
        // } else if (externo == 0) {
        //   this.user.externo = false;
        // }

        const { rol } = await RolesApi.rolPerId(roles_id);
        this.user.rol = rol;

        this.loading = false;
      } catch {
        this.loading = false;
      }
    },

    //editStaff
    async updateProfile() {
      this.loading = true;
      const { id } = await RolesApi.rolId(this.user.rol);
      // let externo = 0;
      // if (this.user.externo === true) {
      //   externo = 1;
      // }

      const data = {
        nombre: this.user.firstName,
        paterno: this.user.lastNameP,
        materno: this.user.lastNameM,
        email: this.user.email,
        roles_id: id,
        activo: 1,
        corredurias_id: storage.getItem("corredurias_id")
        // externo: externo,
      };

      try {
        await StaffApi.edit(this.id, data);
        this.$toast.success("Staff actualizado", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true,
        });
        this.loading = false;
      } catch (error) {
        this.$toast.error("Sucedió un error, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true,
        });
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
#card {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* color: #9a9a9a; */
  color: #ea640b;
  font-size: 13px;
}
</style>
