<template>
  <div class="content">
    <b-breadcrumb>
      <b-breadcrumb-item :to="{ path: '/dashboard' }">Inicio</b-breadcrumb-item>
      <b-breadcrumb-item active>Edita Formalización de actas</b-breadcrumb-item>
    </b-breadcrumb>

    <div class="container">
      <div v-if="loading">
        <div id="loader">
          <font-awesome-icon icon="spinner" spin />
        </div>
      </div>
    </div>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog-route" hide-footer id="modal-confirm-dialog-route">
      <h4 class="text-center">
        ¿Seguro que quiere salir de la página?
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog-route')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="beforeLeave(true)">OK</b-button>
    </b-modal>
    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog" hide-footer id="modal-confirm-dialog">
      <h4 class="text-center">
        ¿Seguro que quiere salir sin guardar los cambios?
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="selected('go')">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="pago-modal" title="" hide-footer id="modalC" size="xl">
      <p id="titulo">Forma de pago</p>
      <b-tabs fill>
        <b-tab title="Tarjeta de crédito o débito" active>
          <div class="mt-3 center">
            Correduría Digital no guarda nigún dato de la compra. Todo se efectúa a través de la plataforma de OpenPay.
          </div>
          <br />
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(payCotizacion)">
              <Openpay :cotizacion_id="formalizacion.cotizaciones_id" :precio_total="formalizacion.precio_total"
                @submit.prevent @payCotizacion="payCotizacion($event)" />
            </form>
          </ValidationObserver>
        </b-tab>
        <b-tab title="SPEI/Transferencia" class="modalPay">
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(payCotizacionSpei)">
              <Spei :cotizacion_id="formalizacion.cotizaciones_id" :precio_total="formalizacion.precio_total"
                :proceso_id="formalizacion.id" :tipo="'formalizacion'" @submit.prevent
                @payCotizacionSpei="payCotizacionSpei($event)" />
            </form>
          </ValidationObserver>
        </b-tab>
        <b-tab title="Monedero" class="modalPay">
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(payCotizacion)">
              <Monedero :cotizacion_id="formalizacion.cotizaciones_id" :precio_total="formalizacion.precio_total"
                :clientes_id="formalizacion.clientes_id" :servicio="'Formalización de Actas ' + formalizacion.id"
                @submit.prevent @payCotizacion="payCotizacionMonedero($event)" />
            </form>
          </ValidationObserver>
        </b-tab>
      </b-tabs>
    </b-modal>

    <b-modal no-close-on-backdrop id="confirmar-facturacion-modal" ref="confirmar-facturacion-modal" centered hide-footer>
      <h4 class="text-center">¿Desea facturar su pago?</h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="confirmGenerarFactura(false)">No en este momento</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="confirmGenerarFactura(true)">Sí</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop id="datos-fiscales-modal" ref="datos-fiscales-modal" centered hide-footer hide-header
      size="lg" @close.prevent>
      <FacturacionComponent :datosFacturacion="datosFacturacion" @closedFacturacionEvent="closedFacturacion($event)"
        @canceledFacturacionEvent="closedFacturacion($event)" />
    </b-modal>

    <div class="container-fluid">
      <b-row>
        <b-col class="col-3">
          <card>
            <TreeM @node="selected" :formalizacion="formalizacion" :user="'Client'" />
          </card>
        </b-col>
        <b-col class="col-9">
          <card>
            <b-row>
              <component v-bind:is="currentComponentInfoHead" class="tab"></component>
            </b-row>
            <b-row>
              <b-col class="col-12">
                <div v-if="currentComponentBody === 'DatosSociedad'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveFormalizacion)">
                      <DatosSociedad @submit.prevent @node="selected" :formalizacion="formalizacion" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'DatosAsamblea'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveFormalizacion)">
                      <DatosAsamblea @submit.prevent @node="selected" :formalizacion="formalizacion" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'DatosDelegado'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveFormalizacion)">
                      <DatosDelegado @submit.prevent @node="selected" :formalizacion="formalizacion" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'EntidadFederativa'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveFormalizacion)">
                      <EntidadFederativa :formalizacion="formalizacion" :catCorredurias="catCorredurias" @submit.prevent
                        @node="selected" />
                    </form>
                  </ValidationObserver>
                </div>

                <div v-if="currentComponentBody === 'Cotizacion'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(pagar)">
                      <Cotizacion @submit.prevent @node="selected" :cat_gastos="cat_gastos"
                        :formalizacion="formalizacion" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'ComplementaInformacion'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveFormalizacion)">
                      <ComplementaInformacion @submit.prevent @node="selected" :formalizacion="formalizacion" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'PagoComplementario'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveFormalizacion)">
                      <PagoComplementario @submit.prevent @node="selected" :formalizacion="formalizacion" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'FirmaDigital'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveFormalizacion)">
                      <FirmaDigital @submit.prevent @node="selected" :formalizacion="formalizacion" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'CitaFirma'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveFormalizacion)">
                      <CitaFirma @submit.prevent @node="selected" :formalizacion="formalizacion" />
                    </form>
                  </ValidationObserver>
                </div>
                <!-- <div v-if="currentComponentBody === 'DocListo'">
                  <DocListo
                    @submit.prevent
                    @node="selected"
                    :formalizacion="formalizacion"
                  />
                </div> -->
              </b-col>
            </b-row>
          </card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import TreeM from "@/views/clients/procesos/formalizacionactas/Tree.vue";
import InfoBase from "@/views/clients/procesos/formalizacionactas/infoBase/InfoBase.vue";
import DatosSociedad from "@/views/clients/procesos/formalizacionactas/infoBase/DatosSociedad.vue";
import DatosAsamblea from "@/views/clients/procesos/formalizacionactas/infoBase/DatosAsamblea.vue";
import DatosDelegado from "@/views/clients/procesos/formalizacionactas/infoBase/DatosDelegado.vue";
import EntidadFederativa from "@/views/clients/procesos/formalizacionactas/infoBase/EntidadFederativa.vue";

import Cotizacion from "@/views/clients/procesos/formalizacionactas/cotizacion/Cotizacion.vue";
import ComplementaInformacion from "@/views/clients/procesos/formalizacionactas/complementainfo/ComplementaInformacion.vue";
import PagoComplementario from "@/views/clients/procesos/formalizacionactas/pagocomplementario/PagoComplementario.vue";
import CitaFirma from "@/views/clients/procesos/formalizacionactas/citafirma/CitaFirma.vue";
// import DocListo from "@/views/clients/procesos/formalizacionactas/doclisto/DocListo.vue";

import {
  FormalizacionActasApi,
  FuncionesApi,
  CatGastosApi,
  CatFormulariosFormalizacionesApi,
  CotizacionesApi
} from "@/api";

import FirmaDigital from "@/views/clients/procesos/formalizacionactas/firmadigital/FirmaDigital.vue";
import Openpay from "@/views/openpay/Openpay.vue";
import Spei from "@/views/openpay/Spei.vue";
import Monedero from "@/views/openpay/Monedero.vue";
import FacturacionComponent from "@/views/openpay/FacturacionComponent.vue";

import Storage from "@/utils/storage";
const storage = new Storage();

export default {
  name: "EditFormalizacionActas",

  components: {
    TreeM,
    InfoBase,
    DatosSociedad,
    DatosAsamblea,
    DatosDelegado,
    EntidadFederativa,

    Cotizacion,
    ComplementaInformacion,
    PagoComplementario,
    CitaFirma,
    // DocListo,

    Openpay,
    Spei,
    Monedero,
    FirmaDigital,
    FacturacionComponent
  },

  async created() {
    // this.currentComponentBody = "DatosSociedad";
    // this.currentComponentBody = null;

    this.getCorredurias();
    this.getGastos();
    await this.getFormalizacion();

    const query = Object.assign({}, this.$route.query);
    let node = null;
    if (query) {
      node = query.node;

      // if (node) {
      //   this.currentComponentBody = node;
      // } else {
      //   switch (this.formalizacion.estatus) {
      //     case "Pagado":
      //       node = "ComplementaInformacion";
      //       break;

      //     default:
      //       node = "DatosSociedad";
      //       break;
      //   }
      // }

      if (!node) {
        switch (this.formalizacion.estatus) {
          case "Pagado":
            node = "ComplementaInformacion";
            break;

          default:
            node = "DatosSociedad";
            break;
        }
      }
    }

    this.selected(node, true);
  },

  beforeRouteLeave(to, from, next) {
    if (to.name == "EditComparecientes") {
      this.next = next;
      this.beforeLeave(true);
    } else {
      this.$refs["modal-confirm-dialog-route"].show();
      this.next = next;
    }
  },

  data: () => ({
    next: null,
    prenode: null,

    loading: false,
    currentComponentInfoHead: "InfoBase",
    currentComponentBody: null,

    cat_gastos: [],
    // optionsObjetoSocial: [],

    catCorredurias: [],
    formalizacion: {
      disabled: null,
      // disabledCotizacion: false,
      disabledComplementa: false,
      disabledPagoComplementario: false,
      disabledCita: false,

      validoSociedad: false,
      validoAsamblea: false,
      validoActa: false,
      validoDelegado: false,
      // validoCapitulos: false,
      validoEntidad: false,
      validoCotizacion: false,
      validoComplementa: false,
      validoPagoComplementario: false,
      validoEntrega: false,
      // validoDocListo: false,

      id: null,
      clientes_id: null,
      corredurias_id: null,
      cotizaciones_id: null,

      nombre_sociedad: null,

      domicilio: {
        id: null,
        num_exterior: null,
        num_interior: null,
        colonia: null,
        delegacion_municipio: null,
        ciudad: null,
        entidad: null,
        pais: null,
        codigo_postal: null,
        tipo: null,
        lat: "19.4401467",
        lng: "-99.20719109999999"
      },

      liga_pago_complementario: null,

      sociedad_inscrita_rpc: 0,
      convocatoria_lgsm: null,
      convocatoria_psm: null,
      representacion_cien: null,
      lista_asistencia_firmada: null,
      asistentes_firma_acta: null,
      rfcs_accionistas: null,
      sesion_instalada: null,
      sesion_totalitaria: null,

      modifica_capital: null,
      capital_modificado: null,
      tipo_modificado: null,
      monto_modificado: null,
      nuevos_accionistas: null,
      capital_extranjero: null,
      modifica_estatutos: null,
      reforma_total: null,
      modifica_objeto_social: null,
      modifica_duracion_sociedad: null,
      modifica_denominacion_social: null,
      modifica_domicilio_social: null,
      revoca_funcionarios: null,
      num_funcionarios_revocados: null,
      modifica_facultades_funcionarios: null,
      transforma_sociedad: null,
      fusion_sociedad: null,
      disolucion_sociedad: null,
      liquidacion_sociedad: null,

      firmantes: [],

      entregable_adicional: null,
      firma_fuera_oficina: null,

      subtotal_entregable_adicional: 0,
      subtotal_firma_fuera_oficina: 0,

      precio_total: 0,

      documentos: [],
      cotizaciones: {},
      accionistas: [],
      accionistas_cuadro: [],
      administradores_cuadro: [],

      entidad: null,
      delegacion_municipio: null,
      direccion_firma: null,

      optionsEntidades: [],
      selectedEntidad: null,
      selectedMunicipio: null,
      selectedCorreduria: null
    },

    //   accion: "nuevo",
    //   pagado: null,
    //   folio_transaccion: null,
    //   formularios: [],

    datosFacturacion: {
      cotizacionId: 0,
      alreadyInvoiced: false
    },
  }),

  methods: {
    async getCorredurias() {
      try {
        const { value } = await FuncionesApi.getCorreduriasDomicilios({
          entidad: null,
          municipio: null
        });

        this.catCorredurias = value;

        value.forEach(element => {
          const exists = this.formalizacion.optionsEntidades.findIndex(x => x.text === element.entidad);

          if (exists === -1) {
            const obj = {
              text: element.entidad,
              value: element.entidad
            };
            this.formalizacion.optionsEntidades.push(obj);
          }
        });
      } catch (error) {
        console.log(error);
      }
    },

    async getGastos() {
      try {
        this.loading = true;

        const { value } = await CatGastosApi.list({
          page: 0,
          registersPerPage: 0,
          cat_servicios_id: 3
        });

        this.cat_gastos = value;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async getFormalizacion() {
      this.loading = true;
      const id = this.$route.params.id;
      this.formalizacion.id = id;

      try {
        const {
          estatus,
          cotizaciones_id,
          clientes_id,
          corredurias_id,
          cotizacion,
          liga_pago_complementario,
          tipo_acta,
          subtipo_acta,
          nombre_sociedad,
          tipo_societario,
          domicilios_id,
          sociedad_inscrita_rpc,
          convocatoria_lgsm,
          convocatoria_psm,
          representacion_cien,
          lista_asistencia_firmada,
          asistentes_firma_acta,
          rfcs_accionistas,
          sesion_instalada,
          sesion_totalitaria,
          modifica_capital,
          capital_modificado,
          tipo_modificado,
          monto_modificado,
          nuevos_accionistas,
          capital_extranjero,
          modifica_estatutos,
          reforma_total,
          modifica_objeto_social,
          modifica_duracion_sociedad,
          modifica_denominacion_social,
          modifica_domicilio_social,
          revoca_funcionarios,
          num_funcionarios_revocados,
          modifica_facultades_funcionarios,
          transforma_sociedad,
          fusion_sociedad,
          disolucion_sociedad,
          liquidacion_sociedad,
          entregable_adicional,
          firma_fuera_oficina,
          actos_inscribibles,
          accionistas_extranjeros,
          rfcs_inscritos,
          reclasificacion_acciones,
          revocan_poderes,
          fecha_firma,
          domicilio_firma,
          estatus_id,

          formularios,
          firmantes,

          documentos,
          domicilio,
          link_calendario,
          entidad,
          delegacion_municipio,
          direccion_firma,
          accionistas
        } = await FormalizacionActasApi.detail(id);

        this.formalizacion.formularios = formularios;
        for (let u = 0; u < formularios.length; u++) {
          if (formularios[u].guardado === 1) {
            //guardado
            switch (formularios[u].formulario) {
              case "ComplementaInformacion":
                this.formalizacion.disabledComplementa = true;
                break;
              // case "PagoComplementario":
              //   this.formalizacion.disabledPagoComplementario = true;
              //   break;
              case "CitaFirma":
                this.formalizacion.disabledCita = true;
                break;
              default:
                break;
            }
          }
        }

        this.validaEstatusForms();

        // this.cotizaciones_id = cotizaciones_id;
        this.formalizacion.id = id;
        this.formalizacion.corredurias_id = corredurias_id;
        this.formalizacion.link_calendario = link_calendario;
        this.formalizacion.clientes_id = clientes_id;
        this.formalizacion.cotizaciones_id = cotizaciones_id;
        this.formalizacion.liga_pago_complementario = liga_pago_complementario;
        this.formalizacion.tipo_acta = tipo_acta;
        this.formalizacion.subtipo_acta = subtipo_acta;
        this.formalizacion.nombre_sociedad = nombre_sociedad;
        this.formalizacion.tipo_societario = tipo_societario;
        this.formalizacion.domicilios_id = domicilios_id;
        this.formalizacion.sociedad_inscrita_rpc = sociedad_inscrita_rpc;
        this.formalizacion.convocatoria_lgsm = convocatoria_lgsm;
        this.formalizacion.convocatoria_psm = convocatoria_psm;
        this.formalizacion.representacion_cien = representacion_cien;
        this.formalizacion.lista_asistencia_firmada = lista_asistencia_firmada;
        this.formalizacion.asistentes_firma_acta = asistentes_firma_acta;
        this.formalizacion.rfcs_accionistas = rfcs_accionistas;
        this.formalizacion.sesion_instalada = sesion_instalada;
        this.formalizacion.sesion_totalitaria = sesion_totalitaria;
        this.formalizacion.modifica_capital = modifica_capital;
        this.formalizacion.capital_modificado = capital_modificado;
        this.formalizacion.tipo_modificado = tipo_modificado;
        this.formalizacion.monto_modificado = monto_modificado;
        this.formalizacion.nuevos_accionistas = nuevos_accionistas;
        this.formalizacion.capital_extranjero = capital_extranjero;
        this.formalizacion.modifica_estatutos = modifica_estatutos;
        this.formalizacion.reforma_total = reforma_total;
        this.formalizacion.modifica_objeto_social = modifica_objeto_social;
        this.formalizacion.modifica_duracion_sociedad = modifica_duracion_sociedad;
        this.formalizacion.modifica_denominacion_social = modifica_denominacion_social;
        this.formalizacion.modifica_domicilio_social = modifica_domicilio_social;
        this.formalizacion.revoca_funcionarios = revoca_funcionarios;
        this.formalizacion.num_funcionarios_revocados = num_funcionarios_revocados;
        this.formalizacion.modifica_facultades_funcionarios = modifica_facultades_funcionarios;
        this.formalizacion.transforma_sociedad = transforma_sociedad;
        this.formalizacion.fusion_sociedad = fusion_sociedad;
        this.formalizacion.disolucion_sociedad = disolucion_sociedad;
        this.formalizacion.liquidacion_sociedad = liquidacion_sociedad;
        this.formalizacion.entregable_adicional = entregable_adicional;
        this.formalizacion.firma_fuera_oficina = firma_fuera_oficina;
        this.formalizacion.actos_inscribibles = actos_inscribibles;
        this.formalizacion.accionistas_extranjeros = accionistas_extranjeros;
        this.formalizacion.rfcs_inscritos = rfcs_inscritos;
        this.formalizacion.reclasificacion_acciones = reclasificacion_acciones;
        this.formalizacion.revocan_poderes = revocan_poderes;
        this.formalizacion.fecha_firma = fecha_firma;
        this.formalizacion.domicilio_firma = domicilio_firma;
        this.formalizacion.estatus_id = estatus_id;
        this.formalizacion.estatus = estatus;
        this.formalizacion.cotizacion = cotizacion;
        this.formalizacion.domicilio = domicilio;
        this.formalizacion.firmantes = firmantes;
        this.formalizacion.formularios = formularios;
        this.formalizacion.entidad = entidad;
        this.formalizacion.delegacion_municipio = delegacion_municipio;
        this.formalizacion.direccion_firma = direccion_firma;
        this.formalizacion.selectedEntidad = entidad;
        this.formalizacion.selectedMunicipio = delegacion_municipio;
        this.formalizacion.selectedCorreduria = corredurias_id;

        this.formalizacion.estatus = estatus;
        if (estatus === "Iniciado") {
          this.formalizacion.disabled = false;
        }
        if (
          estatus === "Pagado" ||
          estatus === "Documentos entregados" ||
          estatus === "Proceso interno" ||
          estatus === "Pago complementario" ||
          estatus === "Segundo proceso interno" ||
          estatus === "Documento listo" ||
          estatus === "Terminado"
        ) {
          this.formalizacion.disabled = true;
        }

        this.formalizacion.accionistas = accionistas;

        for (let y = 0; y < accionistas.length; y++) {
          var a = Object.assign({}, accionistas[y]);

          if (accionistas[y].tipo == "Accionista") {
            this.formalizacion.accionistas_cuadro.push(a);
          } else {
            //admin o repre
            this.formalizacion.administradores_cuadro.push(a);
          }
        }

        if (cotizacion) {
          // this.formalizacion.cupones_id = cotizacion.cupones_id;
          this.formalizacion.precio_total = cotizacion.precio_total;
          this.formalizacion.pagado = cotizacion.pagado;
          // this.constitutiva.folio_transaccion = cotizacion.folio_transaccion;
        }

        for (let r = 0; r < documentos.length; r++) {
          documentos[r].nombreFile = documentos[r].documento.substring(documentos[r].documento.lastIndexOf("/") + 1);
          documentos[r].tipo = "";

          // let tipobd = documentos[r].tipo;
          // if (
          //   tipobd === "Identificación oficial" ||
          //   tipobd === "Comprobante de domicilio" ||
          //   tipobd === "RFC o Cédula de identificación Fiscal" ||
          //   tipobd === "CURP" ||
          //   tipobd === "Constitutiva de la persona moral" ||
          //   tipobd === "Poderes del representante" ||
          //   tipobd === "Cuadro de distribución accionaria"
          //   // tipobd ===
          //   //   "PDF del formato de beneficiario controlador correspondiente a cada uno de las personas físicas finales, accionistas de la persona moral, que califiquen en tal carácter"
          // ) {
          //   documentos[r].tipo = tipobd;
          // } else {
          //   documentos[r].tipo = "Otro";
          //   documentos[r].tipoOtro = tipobd;
          // }
        }

        this.formalizacion.documentos = documentos;

        //node cuando regresan de comparecientes
        // const query = Object.assign({}, this.$route.query);
        // if (query) {
        //   let node = query.node;
        //   if (node) {
        //     this.currentComponentBody = node;

        //     this.selected(node, true);
        //   }
        // }

        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },

    validaEstatusForms() {
      for (let i = 0; i < this.formalizacion.formularios.length; i++) {
        if (this.formalizacion.formularios[i].guardado === 1) {
          //guardado
          switch (this.formalizacion.formularios[i].formulario) {
            case "DatosSociedad":
              this.formalizacion.validoSociedad = true;
              break;
            case "DatosAsamblea":
              this.formalizacion.validoAsamblea = true;
              break;
            case "DatosDelegado":
              this.formalizacion.validoDelegado = true;
              break;
            case "EntidadFederativa":
              this.formalizacion.validoEntidad = true;
              break;
            case "Cotizacion":
              this.formalizacion.validoCotizacion = true;
              break;
            case "ComplementaInformacion":
              this.formalizacion.validoComplementa = true;
              break;
            case "PagoComplementario":
              this.formalizacion.validoPagoComplementario = true;
              break;
            case "CitaFirma":
              this.formalizacion.validoCita = true;
              break;
          }
        }
      }
    },

    async saveFormalizacion(isMonedero = false) {
      this.loading = true;
      let saved = await this.editFormalizacion();

      if (saved && this.currentComponentBody === "Cotizacion" && !isMonedero) {
        this.$refs['confirmar-facturacion-modal'].show();
        this.loading = false;

        return;
      }

      if (saved) {
        let nodos = [
          "DatosSociedad",
          "DatosAsamblea",
          "DatosDelegado",
          // "CapitulosEspeciales",
          "EntidadFederativa",
          "Cotizacion",
          "ComplementaInformacion",
          "PagoComplementario",
          "FirmaDigital",
          "CitaFirma"
        ];

        const index = nodos.findIndex(o => o === this.currentComponentBody);
        const nextIndex = parseInt(index);
        let node = null;

        if (nextIndex < nodos.length - 1) {
          node = nodos[nextIndex + 1];
        } else {
          node = "CitaFirma";
        }

        this.selected(node, true);
      }

      this.loading = false;
    },

    pagar() {
      this.$refs["pago-modal"].show();
    },

    async editFormalizacion() {
      this.$refs["pago-modal"].hide();
      this.loading = true;

      let firmantesA = [];
      for (let i = 0; i < this.formalizacion.firmantes.length; i++) {
        //si tienen rfc ya existe compareciente
        if (this.formalizacion.firmantes[i].rfc) {
          const obj = {
            comparecientes_id: this.formalizacion.firmantes[i].id,
            cargo: this.formalizacion.firmantes[i].cargo
          };
          firmantesA.push(obj);
        } else {
          const obj = {
            cliente_id: this.formalizacion.clientes_id,
            nombre: this.formalizacion.firmantes[i].nombre,
            paterno: this.formalizacion.firmantes[i].paterno,
            materno: this.formalizacion.firmantes[i].materno,
            persona: "Física",
            cargo: this.formalizacion.firmantes[i].cargo
            // visible: this.formalizacion.firmantes[i].visible
          };
          firmantesA.push(obj);
        }
      }

      var data = {
        clientes_id: this.formalizacion.clientes_id,
        activo: 1,
        cotizaciones_id: this.formalizacion.cotizaciones_id
        // corredurias_id:this.formalizacion.corredurias_id
      };

      if (this.currentComponentBody === "DatosSociedad") {
        let domicilio_data = {
          id: this.formalizacion.domicilio.id,
          calle: this.formalizacion.domicilio.calle,
          num_exterior: this.formalizacion.domicilio.num_exterior,
          num_interior: this.formalizacion.domicilio.num_interior,
          colonia: this.formalizacion.domicilio.colonia,
          delegacion_municipio: this.formalizacion.domicilio
            .delegacion_municipio,
          ciudad: this.formalizacion.domicilio.ciudad,
          entidad: this.formalizacion.domicilio.entidad,
          pais: this.formalizacion.domicilio.pais,
          codigo_postal: this.formalizacion.domicilio.codigo_postal,
          lat: this.formalizacion.domicilio.lat,
          lng: this.formalizacion.domicilio.lng
        };

        data["tipo_acta"] = this.formalizacion.tipo_acta;
        data["subtipo_acta"] = this.formalizacion.subtipo_acta;
        data["nombre_sociedad"] = this.formalizacion.nombre_sociedad;
        data["tipo_societario"] = this.formalizacion.tipo_societario;
        data[
          "sociedad_inscrita_rpc"
        ] = this.formalizacion.sociedad_inscrita_rpc;
        data["domicilio"] = domicilio_data;
        data["formularios"] = [
          { formulario: "DatosSociedad", estatus: 1, guardado: 1 }
        ];
      }
      if (this.currentComponentBody === "DatosAsamblea") {
        data["modifica_capital"] = this.formalizacion.modifica_capital;
        data["capital_modificado"] = this.formalizacion.capital_modificado;
        data["tipo_modificado"] = this.formalizacion.tipo_modificado;
        data["monto_modificado"] = this.formalizacion.monto_modificado;
        data["nuevos_accionistas"] = this.formalizacion.nuevos_accionistas;
        data["capital_extranjero"] = this.formalizacion.capital_extranjero;
        data["modifica_estatutos"] = this.formalizacion.modifica_estatutos;
        data["reforma_total"] = this.formalizacion.reforma_total;
        data[
          "modifica_objeto_social"
        ] = this.formalizacion.modifica_objeto_social;
        data[
          "modifica_duracion_sociedad"
        ] = this.formalizacion.modifica_duracion_sociedad;
        data[
          "modifica_denominacion_social"
        ] = this.formalizacion.modifica_denominacion_social;
        data[
          "modifica_domicilio_social"
        ] = this.formalizacion.modifica_domicilio_social;
        data["revoca_funcionarios"] = this.formalizacion.revoca_funcionarios;
        data[
          "num_funcionarios_revocados"
        ] = this.formalizacion.num_funcionarios_revocados;
        data[
          "modifica_facultades_funcionarios"
        ] = this.formalizacion.modifica_facultades_funcionarios;
        data["transforma_sociedad"] = this.formalizacion.transforma_sociedad;
        data["fusion_sociedad"] = this.formalizacion.fusion_sociedad;
        data["disolucion_sociedad"] = this.formalizacion.disolucion_sociedad;
        data["liquidacion_sociedad"] = this.formalizacion.liquidacion_sociedad;

        data["convocatoria_lgsm"] = this.formalizacion.convocatoria_lgsm;
        data["convocatoria_psm"] = this.formalizacion.convocatoria_psm;
        data["representacion_cien"] = this.formalizacion.representacion_cien;
        data[
          "lista_asistencia_firmada"
        ] = this.formalizacion.lista_asistencia_firmada;
        data[
          "asistentes_firma_acta"
        ] = this.formalizacion.asistentes_firma_acta;

        data["formularios"] = [
          { formulario: "DatosDelegado", estatus: 1 },
          { formulario: "DatosAsamblea", estatus: 1, guardado: 1 }
        ];
      }

      if (this.currentComponentBody === "DatosDelegado") {
        data["firmantes"] = firmantesA;
        data["formularios"] = [
          { formulario: "Cotizacion", estatus: 1 },
          { formulario: "DatosDelegado", estatus: 1, guardado: 1 }
        ];
      }

      if (this.currentComponentBody === "EntidadFederativa") {
        data["entidad"] = this.formalizacion.selectedEntidad;
        data["delegacion_municipio"] = this.formalizacion.selectedMunicipio;
        data["formularios"] = [
          { formulario: "Cotizacion", estatus: 1 },
          { formulario: "EntidadFederativa", estatus: 1, guardado: 1 }
        ];
      }

      if (this.currentComponentBody === "Cotizacion") {
        data["estatus_id"] = "60"; //cuando paguen
        this.formalizacion.disabled = true;
        let entregable_adicional = 0;
        if (this.formalizacion.entregable_adicional) entregable_adicional = 1;
        let firma_fuera_oficina = 0;
        if (this.formalizacion.firma_fuera_oficina) firma_fuera_oficina = 1;

        data["entregable_adicional"] = entregable_adicional;
        data["firma_fuera_oficina"] = firma_fuera_oficina;

        // let objCoti = {
        //   id: this.formalizacion.cotizacion.id,
        //   cupones_id: this.formalizacion.cotizacion.cupones_id,
        //   precio_total: this.formalizacion.precio_total,
        //   pagado: 1,
        //   folio_transaccion: this.formalizacion.folio_transaccion
        // };
        // data["cotizacion"] = objCoti;

        const _cotizacion = await CotizacionesApi.detail(this.formalizacion.cotizacion.id);
        this.formalizacion.cotizacion = _cotizacion;
        data.cotizacion = _cotizacion;

        data["formularios"] = [
          { formulario: "ComplementaInformacion", estatus: 1 },
          { formulario: "Cotizacion", estatus: 1, guardado: 1 }
        ];
      }
      if (this.currentComponentBody === "ComplementaInformacion") {
        data["estatus_id"] = "61";
        var documentos = JSON.parse(JSON.stringify(this.formalizacion.documentos));
        data["documentos"] = documentos;
        data["accionistas"] = this.formalizacion.accionistas;
        data["formularios"] = [
          { formulario: "PagoComplementario", estatus: 1 },
          { formulario: "ComplementaInformacion", estatus: 1, guardado: 1 }
        ];
      }
      if (this.currentComponentBody === "PagoComplementario") {
      }
      if (this.currentComponentBody === "CitaFirma") {
      }

      try {
        const { firmantes } = await FormalizacionActasApi.edit(this.formalizacion.id, data);

        if (firmantes) {
          for (let i = 0; i < firmantes.length; i++) {
            const newId = firmantes[i].id;

            this.formalizacion.firmantes[i].id = newId;
            const a = Object.assign({}, this.formalizacion.firmantes[i]);

            this.formalizacion.firmantes.splice([i], 1, a);
          }
        }

        if (this.currentComponentBody === "DatosSociedad") {
          this.formalizacion.validoSociedad = true;
        }
        if (this.currentComponentBody === "DatosAsamblea") {
          this.formalizacion.validoAsamblea = true;
        }
        if (this.currentComponentBody === "DatosDelegado") {
          this.formalizacion.validoDelegado = true;
        }
        if (this.currentComponentBody === "EntidadFederativa") {
          this.formalizacion.validoEntidad = true;
        }
        if (this.currentComponentBody === "Cotizacion") {
          this.formalizacion.disabled = true;
          this.formalizacion.validoCotizacion = true;
        }
        if (this.currentComponentBody === "ComplementaInformacion") {
          this.formalizacion.disabledComplementa = true;
          this.formalizacion.validoComplementa = true;
        }
        if (this.currentComponentBody === "PagoComplementario") {
          this.formalizacion.disabledPagoComplementario = true;
          this.formalizacion.validoPagoComplementario = true;
        }
        if (this.currentComponentBody === "CitaFirma") {
          this.formalizacion.disabledCita = true;
          this.formalizacion.validoCita = true;
        }
        if (this.currentComponentBody === "FirmaDigital") {
          // this.formalizacion.disabledComplementa = true;
          // this.formalizacion.validoFir= true;
        }

        this.$toast.success("Información guardada", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
        return true;
      } catch (error) {
        this.$toast.error("Sucedió un error, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
        this.loading = false;
        return false;
      }
    },

    async payCotizacion(payed) {
      if (payed == true) {
        this.saveFormalizacion();
      }
    },

    async payCotizacionMonedero(payed) {
      if (payed == true) {
        this.saveFormalizacion();
      }
    },

    async payCotizacionSpei(payed) {
      if (payed == true) {
        // this.constitutiva.disabled = true;
        this.$refs["pago-modal"].hide();
        this.$toast.warning("Recibirá la información para la transferencia en su correo electrónico",
          {
            timeout: 10000, //false,
            hideProgressBar: false,
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: "button",
            icon: true
          }
        );
      } else {
        this.$toast.error("Sucedió un error, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
        this.loading = false;
      }
    },

    async beforeLeave(answer) {
      if (answer) {
        this.next();
      }
    },

    async selected(node, automatic) {
      this.loading = true;

      let formalizacion_id = 0;

      if (this.formalizacion.id) {
        formalizacion_id = this.formalizacion.id;
      }

      let estatus = "";

      if (node === "go") {
        estatus = await this.getEstatusForm(this.prenode, formalizacion_id);
      } else {
        estatus = await this.getEstatusForm(node, formalizacion_id);
      }

      if (estatus === 0) {
        this.$toast.warning("Complete el formulario actual para poder continuar", {
          timeout: 6000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
      } else {
        if (automatic === true) {
          this.$refs["modal-confirm-dialog"].hide();
          this.currentComponentBody = node;
          if (
            node === "DatosSociedad" ||
            node === "DatosAsamblea" ||
            // node === "DatosActa" ||
            node === "DatosDelegado" ||
            node === "EntidadFederativa"
          ) {
            this.currentComponentInfoHead = "InfoBase";
          }

          if (
            node === "CitaFirma" ||
            // node === "DocListo" ||
            node === "InfoBase" ||
            node === "Cotizacion" ||
            node === "ComplementaInformacion" ||
            node === "PagoComplementario" ||
            node === "FirmaDigital"
          ) {
            this.currentComponentInfoHead = null;
          }
        } else {
          if (node != "go") {
            this.prenode = node;
          }
          if (node != "go") {
            this.$refs["modal-confirm-dialog"].show();
          } else {
            this.$refs["modal-confirm-dialog"].hide();
            node = this.prenode;

            this.currentComponentBody = node;
            if (
              node === "DatosSociedad" ||
              node === "DatosAsamblea" ||
              node === "DatosDelegado" ||
              node === "EntidadFederativa"
            ) {
              this.currentComponentInfoHead = "InfoBase";
            }

            if (
              node === "CitaFirma" ||
              // node === "DocListo" ||
              node === "InfoBase" ||
              node === "Cotizacion" ||
              node === "ComplementaInformacion" ||
              node === "PagoComplementario" ||
              node === "FirmaDigital"
            ) {
              this.currentComponentInfoHead = null;
            }
          }
        }
      }
      this.loading = false;
    },

    async getEstatusForm(formulario, formalizacion_id) {
      try {
        const estatus = await CatFormulariosFormalizacionesApi.getEstatusForm(formulario, formalizacion_id);

        return estatus;
      } catch (error) {
        console.log(error);
      }
    },

    confirmGenerarFactura(crearFactura) {
      this.$refs['confirmar-facturacion-modal'].hide();

      if (!crearFactura) {
        const nodos = [
          "DatosSociedad",
          "DatosAsamblea",
          "DatosDelegado",
          // "CapitulosEspeciales",
          "EntidadFederativa",
          "Cotizacion",
          "ComplementaInformacion",
          "PagoComplementario",
          "FirmaDigital",
          "CitaFirma"
        ];

        const index = nodos.findIndex(o => o === this.currentComponentBody);
        let nextIndex = parseInt(index);
        let node = null;

        if (nextIndex < nodos.length - 1) {
          node = nodos[nextIndex + 1];
        } else {
          node = "CitaFirma";
        }

        this.selected(node, true);

        return;
      }

      this.datosFacturacion.cotizacionId = this.formalizacion.cotizaciones_id;
      this.datosFacturacion.alreadyInvoiced = false;

      this.$refs["datos-fiscales-modal"].show();
    },

    closedFacturacion(event) {
      const nodos = [
        "DatosSociedad",
        "DatosAsamblea",
        "DatosDelegado",
        // "CapitulosEspeciales",
        "EntidadFederativa",
        "Cotizacion",
        "ComplementaInformacion",
        "PagoComplementario",
        "FirmaDigital",
        "CitaFirma"
      ];

      const index = nodos.findIndex(o => o === this.currentComponentBody);
      let nextIndex = parseInt(index);
      let node = null;

      if (nextIndex < nodos.length - 1) {
        node = nodos[nextIndex + 1];
      } else {
        node = "CitaFirma";
      }

      this.selected(node, true);

      // if (this.currentComponentBody === "Cotizacion") {
      //   this.formalizacion.disabled = true;
      //   this.formalizacion.validoCotizacion = true;
      // }

      this.$refs['datos-fiscales-modal'].hide();
    },

    canceledFacturacion() {
      const nodos = [
        "DatosSociedad",
        "DatosAsamblea",
        "DatosDelegado",
        // "CapitulosEspeciales",
        "EntidadFederativa",
        "Cotizacion",
        "ComplementaInformacion",
        "PagoComplementario",
        "FirmaDigital",
        "CitaFirma"
      ];

      const index = nodos.findIndex(o => o === this.currentComponentBody);
      let nextIndex = parseInt(index);
      let node = null;
      if (nextIndex < nodos.length - 1) {
        node = nodos[nextIndex + 1];
      } else {
        node = "CitaFirma";
      }

      this.selected(node, true);

      // if (this.currentComponentBody === "Cotizacion") {
      //   this.formalizacion.disabled = true;
      //   this.formalizacion.validoCotizacion = true;
      // }

      this.$refs['datos-fiscales-modal'].hide();
    },
  }
};
</script>

<style scoped></style>
