<template>
  <div class="container">
    <!-- Modales -->
    <b-modal no-close-on-backdrop centered ref="documento-modal-preview-img" title="" hide-footer id="modaldi"
      size="xl">
      <ModalPreviewImg :currentDocumento="currentDocumento" />
    </b-modal>
    <b-modal no-close-on-backdrop centered ref="documento-modal-preview-pdf" title="" hide-footer id="modaldf"
      size="xl">
      <ModalPreviewPDF :currentDocumento="currentDocumento" />
    </b-modal>
    <b-modal no-close-on-backdrop centered ref="documento-modal-preview-video" title="" hide-footer id="modalvideo" size="xl">
      <ModalPreviewVideo :currentDocumento="currentDocumento" />
    </b-modal>
    <!-- Template -->
    <h4 class="cardD">Expediente</h4>

    <div>
      <b-table ref="table" hover :fields="fieldsDocumentos" :items="notificacion.documentos">
        <template #cell(tipo)="{ item }">
          <div v-if="item.tipo === 'Otro'">
            <div v-b-tooltip.hover :title="item.descripcion">
              {{ item.tipo }}
            </div>
          </div>
          <div v-else>
            {{ item.tipo }}
          </div>
        </template>
        <template #cell(acciones)="{ item }">
          <b-button variant="primary" size="sm" v-b-tooltip.hover title="Vista previa" @click="previewDocumento(item)"
            class="editBtn">
            <b-icon-eye />
          </b-button>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
// Modales
import ModalPreviewImg from "@/views/clients/procesos/constitucion/entregadocumentos/ModalPreviewImg.vue";
import ModalPreviewPDF from "@/views/clients/procesos/constitucion/entregadocumentos/ModalPreviewPDF.vue";
import ModalPreviewVideo from "@/views/clients/procesos/constitucion/entregadocumentos/ModalPreviewVideo.vue";

// API
import { CatDocumentosApi } from "@/api";
import { DocumentosXServiciosApi } from "@/api";
import { DocumentosApi } from "@/api";
import { FuncionesApi } from "@/api";
import { SolicitantesApi } from "@/api";
import { ComparecientesApi } from "@/api";

// Funciones

export default {
  name: "Expediente",

  components: {
    ModalPreviewImg,
    ModalPreviewPDF,
    ModalPreviewVideo
  },

  props: {
    notificacion: { type: Object },
  },

  computed: {
    fieldsDocumentos() {
      let fieldsDocumentos = [
        { key: "tipo", label: "Tipo", class: "text-center" },
        { key: "nombre", label: "Nombre", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" }
      ];
      return fieldsDocumentos;
    },
  },

  created() {
    this.loading = true;
    this.notificacion.documentos = [];
    this.getCatDocumentosNotificaciones()
      .then((catdocumentos) => this.populateOptionsDocumentos(catdocumentos))
      .then(() => this.getDocumentosXServiciosWithNotificacion())
      .then(() => this.getDocumentosFromDocumentosXServicios())
      .then((documentos) => this.addDocumentoToNotificacionDocumentos(documentos))
      .catch((error) => {
        console.log(error);
        this.showErrorToast('No se pudieron obtener los documentos. Intente más tarde.')
      })
      .finally(() => { this.loading = false; })
  },

  data() {
    return {
      optionsDocumentos: [],
      documentosXServicios: [],
      documentosCompletos: false,
      confirmAceptacion: false,

      currentDocumento: {},

      cartaInstruccion: null,
      datos: {},
      cartaKey: 0
    }
  },

  methods: {
    showErrorToast(msg) {
      this.$toast.error(msg, {
        timeout: 4000, //false,
        hideProgressBar: false,
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        closeButton: "button",
        icon: true,
      });
    },

    async getCatDocumentosNotificaciones() {
      const { value } = await CatDocumentosApi.list({
        page: 0,
        registersPerPage: 0,
        categoria: "notificaciones"
      });
      const catdocumentos = value;
      return catdocumentos;
    },

    populateOptionsDocumentos(documentos) {
      documentos.forEach(documento => {
        const obj = { text: documento.documento, value: documento.id };
        this.optionsDocumentos.push(obj);
      });
      return;
    },

    async getDocumentosXServiciosWithNotificacion() {
      const { value } = await DocumentosXServiciosApi.list({
        page: 0,
        registersPerPage: 0,
        cat_servicios_id: 2,
        id_proceso: this.notificacion.id
      });
      this.documentosXServicios = value;
      return;
    },

    getDocumentosFromDocumentosXServicios() {
      let documentos = [];
      this.documentosXServicios.forEach(documentoXServicio => {
        const documentoCompleto = this.getDocumentoDetail(documentoXServicio.documentos_id);
        documentos.push(documentoCompleto);
      })
      return documentos;
    },

    async getDocumentoDetail(documento_id) {
      const documento = await DocumentosApi.detail(documento_id);
      const documentoCompleto = this.completeFields(documento)
      return documentoCompleto;
    },

    completeFields(documento) {
      const documentoWithTipo = this.addTipo(documento);
      return documentoWithTipo;
    },

    addTipo(documento) {
      const tipo = this.getCatDocumentoNombre(documento);
      documento.tipo = tipo;
      return documento;
    },

    getCatDocumentoNombre(documento) {
      if (documento.cat_documentos_id == '94') {
        return "Factura";
      }

      const catDcoumentosIndex = this.getCatDocumentosIndex(documento);
      const nombre_catDocumento = this.optionsDocumentos[catDcoumentosIndex].text;
      return nombre_catDocumento;
    },

    getCatDocumentosIndex(documento) {
      let catDocumentosIndex = this.optionsDocumentos.findIndex(
        (optionDocumento) => optionDocumento.value === parseInt(documento.cat_documentos_id)
      );
      if (catDocumentosIndex == -1) {
        console.log(documento)
      }
      this.areDocumentosCompletos();
      return catDocumentosIndex;
    },

    addDocumentoToNotificacionDocumentos(documentos) {
      Promise.all(documentos).then((documentos) => {
        documentos.forEach(documento => {
          this.notificacion.documentos.push(documento)
        })
      })
        .then(() => { this.sortNotificacionDocumentosById() })
      return;
    },

    isDocumentoActaGenerada(documento) {
      if (documento.cat_documentos_id == 44) { return true }
      else { return false };
    },

    sortNotificacionDocumentosById() {
      this.notificacion.documentos.sort((a, b) => parseInt(a.id) - parseInt(b.id));
      return;
    },

    openEditDocumentoModal(documento) {
      this.$refs["entrega-documento-modal"].show();
      this.currentDocumento = documento;
    },

    postDocumento() {
      this.hideEntregaDocumentoModal();
      this.sendCurrentDocumentoToAzureStorage();
    },

    async sendCurrentDocumentoToAzureStorage() {
      this.loading = true;
      try {
        const formData = new FormData();
        this.currentDocumento.nombre = this.currentDocumento.file.name;
        formData.append("file", this.currentDocumento.file);
        const path = await FuncionesApi.file(
          this.notificacion.id,
          "documentosNotificaciones",
          formData,
          {}
        );
        this.currentDocumento.documento = path;
        this.updateDocumentoBD();
      } catch (error) {
        this.showErrorToast('Error al mandar el archivo. Intente más tarde.');
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async updateDocumentoBD() {
      this.loading = true;
      try {
        await DocumentosApi.edit(this.currentDocumento.id, {
          documento: this.currentDocumento.documento,
          cat_documentos_id: this.currentDocumento.cat_documentos_id,
          auto_referencia: this.currentDocumento.auto_referencia,
          descripcion: this.currentDocumento.descripcion,
          paginas: parseInt(this.currentDocumento.paginas),
          copias_certificadas: null,
          nombre: this.currentDocumento.nombre
        })
        this.updateNotificacionDocumentoFromCurrentDocumento();
      } catch (error) {
        this.showErrorToast('Error al actualizar el documento. Intente más tarde.');
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    updateNotificacionDocumentoFromCurrentDocumento() {
      const notificacionDocumentosIndex = this.getNotificacionDocumentosIndex(this.currentDocumento);
      this.notificacion.documentos[notificacionDocumentosIndex] = this.currentDocumento;
      this.areDocumentosCompletos();
    },

    getNotificacionDocumentosIndex(documento) {
      let notificacionDocumentosIndex = this.notificacion.documentos.findIndex(
        (notificacionDocumento) => notificacionDocumento.id === parseInt(documento.id)
      );
      return notificacionDocumentosIndex;
    },

    hideEntregaDocumentoModal() {
      this.$refs["entrega-documento-modal"].hide();
    },

    previewDocumento(item) {
      this.currentDocumento = item;
      const currentCocumentType = this.getCurrentDocumentType()
      switch (currentCocumentType) {
        case 'image':
          this.$refs["documento-modal-preview-img"].show();
          break;
        case 'pdf':
          this.$refs["documento-modal-preview-pdf"].show();
          break;
        case 'video':
          this.$refs["documento-modal-preview-video"].show();
          break;
      }
    },

    getCurrentDocumentType() {
      let documentoSeparatedByDots = this.currentDocumento.documento.split('.')
      let extensionDocumento = documentoSeparatedByDots[documentoSeparatedByDots.length - 1]
      const imageExtensions = ["jpg", "JPG", "jpeg", "png", "gif", "bmp", "webp", "svg", "tiff", "avif"]
      if (imageExtensions.includes(extensionDocumento)) {
        return 'image';
      } else if (extensionDocumento == 'pdf') {
        return 'pdf';
      } else if (extensionDocumento == 'mov') {
        return 'video';
      } else {
        console.log(extensionDocumento)
      };
    },

    areDocumentosCompletos() {
      let completos = true;
      if (this.notificacion.documentos.length == 0) {
        return;
      }
      this.notificacion.documentos.forEach(documento => {
        if (documento.nombre == "") {
          completos = false;
        }
      });
      this.documentosCompletos = completos;
    },

    createNombreCompleto(compareciente) {
      let nombreCompletoTemp = ""
      if (compareciente.persona === 'Física') {
        nombreCompletoTemp = compareciente.nombre + ' ' + compareciente.paterno;
        if (compareciente.materno) {
          nombreCompletoTemp = nombreCompletoTemp + ' ' + compareciente.materno
        }
      } else {
        nombreCompletoTemp = compareciente.denominacion_social;
      }
      return nombreCompletoTemp;
    },
  }
}
</script>

<style scoped>
.cardErrors {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* color: #9a9a9a; */
  color: #ea640b;
  font-size: 13px;
}

.cardD {
  font-size: 15px !important;
  color: #ea640b;
  margin-top: 0%;
}

.bottom2 {
  float: right;
}

.bottom1 {
  float: left;
}

.green-btn {
  background-color: green;
}

.container>>>.validacionDatosLabel {
  position: absolute;
  margin-left: 30px;
  top: 25%;
  max-width: 1500px;
}
</style>
