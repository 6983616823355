// API
import BaseApi from './base';

class SolicitantesApi extends BaseApi {
  constructor() {
    super('solicitantes');
  }

  postClonados(data, params = {}) {
    return this.request.post(`/solicitantes/clonados`, data, {
      params: {
        ...params
      }
    });
  }
}

export default new SolicitantesApi();
