<template>
  <div class="container">
    <h4 class="cardD">Datos generales de la Nueva Sociedad</h4>

    <div class="row">
      <div class="col-6">
        <ValidationProvider name="tipo_societario" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El tipo de societario es obligatorio',
        }">
          <label for="">Tipo de societario</label>
          <select class="custom-select" v-model="constitutiva.tipo_societario"
            :disabled="constitutiva.disabledGenerales">
            <option value="SA">SA</option>
            <option value="S de RL">S de RL</option>
            <option value="SAPI">SAPI</option>
          </select>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <!-- <div class="col-6">
        <label for="input-tiene-capital-variable">Tendrá capital variable</label>
        <b-form-checkbox v-model="constitutiva.tiene_capital_variable" :disabled="constitutiva.disabledGenerales">
        </b-form-checkbox>
      </div> -->
    </div>

    <div class="row mt-4">
      <div class="col-md-12">
        <ValidationProvider name="denominaciones_sociales" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La denominación social es obligatoria',
        }">
          <base-input :disabled="constitutiva.disabledGenerales" type="text" label="Denominación social"
            placeholder="Opción 1" v-model="constitutiva.denominaciones_sociales_1">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
        <ValidationProvider name="denominaciones_sociales" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La denominación social es obligatoria',
        }">
          <base-input :disabled="constitutiva.disabledGenerales" type="text" label="" placeholder="Opción 2"
            v-model="constitutiva.denominaciones_sociales_2">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
        <ValidationProvider name="denominaciones_sociales" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La denominación social es obligatoria',
        }">
          <base-input :disabled="constitutiva.disabledGenerales" type="text" label="" placeholder="Opción 3"
            v-model="constitutiva.denominaciones_sociales_3">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-6">
        <label for="input-autoriza-cd-denominacion">
          En caso de ser rechazada, autoriza a Correduría Digital proponer alguna
        </label>
        <b-form-checkbox id="input-autoriza-cd-denominacion" v-model="constitutiva.autoriza_cd_denominacion"
          :disabled="constitutiva.disabledGenerales">
        </b-form-checkbox>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-md-12">
        <ValidationProvider name="objeto_social" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El objeto social es obligatorio',
        }">
          <label for="">Objeto social</label>
          <select class="custom-select" v-model="constitutiva.objeto_social" :disabled="constitutiva.disabledGenerales">
            <option v-for="option in optionsObjetoSocial" :key="option.value">
              {{ option.text }}
            </option>
          </select>

          <base-input :disabled="constitutiva.disabledGenerales" class="mt-2" type="text" label=""
            placeholder="Objeto Social" v-model="constitutiva.objeto_social_otro"
            v-if="constitutiva.objeto_social === 'Otro'">
          </base-input>

          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-md-12">
        <ValidationProvider name="objeto_actividad" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El objeto y actividad es obligatorio',
        }">
          <label>Cuéntanos qué actividades desarrollará la empresa</label>
          <b-form-textarea :disabled="constitutiva.disabledGenerales" type="text"
            placeholder="Actividades que desarrollará la empresa" v-model="constitutiva.objeto_actividad">
          </b-form-textarea>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="mt-4">
      <div class="row">
        <div class="col-10">
          <b-button type="submit" class="btn-fill orange-btn bottom2" size="m"
            :disabled="constitutiva.disabledGenerales">Guardar</b-button>
        </div>
        <div class="col-2">
          <b-button variant="success" class="btn-fill bottom2" size="m" :disabled="constitutiva.disabledGenerales"
            @click="validar()">Validar</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TabDatosGenerales",

  props: {
    constitutiva: { type: Object },
    optionsObjetoSocial: { type: Array },
  },

  data() {
    return {
      form: {
        tipo_societario: null,

        denominaciones_sociales_1: null,
        denominaciones_sociales_2: null,
        denominaciones_sociales_3: null,
        tiene_capital_variable: null,
        autoriza_cd_denominacion: null,
        objeto_social: null,
        objeto_social_otro: null,
        objeto_actividad: null,
      },
    };
  },

  methods: {
    validar() {
      this.$emit("validaForm", "DatosGenerales");
    },
  },
};
</script>

<style scoped>
.bottom2 {
  /* position: fixed;
  right: 2%;
  bottom: 5%;
  overflow: visible;
  z-index: 100; */
  float: right;
}
</style>
