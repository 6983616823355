<template>
  <div class="content">
    <!-- <b-breadcrumb>
      <b-breadcrumb-item active>Monedero</b-breadcrumb-item>
    </b-breadcrumb> -->

    <div class="container">
      <div v-if="loading">
        <div id="loader">
          <font-awesome-icon icon="spinner" spin />
        </div>
      </div>
    </div>

    <div class="wrapper wrapper-full-page section content">
      <div class="container">
        <card>
          <div class="row">
            <div class="col-6">
              <h4 class="cardD">Saldo actual</h4>
              <h3 class="saldo">{{ formattedSaldo }}</h3>
            </div>
            <div class="col-6">
              <h4 class="cardD">Monto a recargar</h4>
              <base-input type="number" class="saldo" v-model="monto_carga" placeholder="Cantidad a recargar"
                :min="0"></base-input>
            </div>
          </div>

          <p class="titulo">Forma de recarga</p>
          <b-tabs fill>
            <b-tab title="Tarjeta de crédito o débito" active>
              <!-- <div class="mt-3 center">
                Correduría Digital no guarda nigún dato de la compra. Todo se
                efectúa a través de la plataforma de OpenPay.
              </div>
              <br /> -->

              <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(payCotizacion)">
                  <Openpay :cotizacion_id="null" :precio_total="monto_carga" @submit.prevent
                    @payCotizacion="recargar($event)" />
                  <!-- :cotizacion_id="constitutiva.cotizacion.id"
                  :precio_total="constitutiva.precio_total" -->
                </form>
              </ValidationObserver>
            </b-tab>
            <b-tab title="SPEI/Transferencia" class="modalPay">
              <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(payCotizacionSpei)">
                  <Spei :cotizacion_id="0" :precio_total="monto_carga" :proceso_id="null" :tipo="'monedero'"
                    @submit.prevent @payCotizacionSpei="payCotizacionSpei($event)" />
                </form>
              </ValidationObserver>
            </b-tab>
          </b-tabs>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import Openpay from "@/views/openpay/Openpay.vue";
import Spei from "@/views/openpay/Spei.vue";
import Card from "src/components/Cards/Card.vue";

import { ClientesApi } from "@/api";

import { formatCurrency } from "@/utils/number-utils";
import Storage from "@/utils/storage";

const storage = new Storage();

export default {
  name: "Monedero",

  components: {
    Card,
    Openpay,
    Spei
  },

  data: () => ({
    loading: false,
    id: 0,
    saldo: 0,
    monto_carga: 0
  }),

  created() {
    this.getClient();
  },

  computed: {
    formattedSaldo() {
      return formatCurrency(this.saldo);
    },
  },

  methods: {
    async getClient() {
      try {
        this.loading = true;

        const clienteEmail = storage.getItem("user");
        const { id } = await ClientesApi.clienteId(clienteEmail);
        const { saldo } = await ClientesApi.detail(id);

        this.id = id;
        this.saldo = saldo;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async recargar(payed) {
      if (!payed) {
        return;
      }

      //tengo que sumarle la cantidad al saldo
      const saldo_suma = this.saldo + Number(this.monto_carga);
      this.saldo = saldo_suma;

      this.$toast.success("Recarga de saldo realizada, se está verificando su pago.", {
        timeout: 5000, //false,
        hideProgressBar: false,
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        closeButton: "button",
        icon: true
      });
    },

    async payCotizacionSpei(payed) {
      if (payed == true) {
        // this.constitutiva.disabled = true;
        // this.$refs["pago-modal"].hide();
        this.$toast.warning("Recibirá la información para la transferencia en su correo electrónico", {
          timeout: 10000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
      } else {
        this.$toast.error("Sucedió un error, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
      }
      this.loading = false;
    }
  }
};
</script>

<style scoped>
.saldo {
  text-align: center;
}

.titulo {
  /* text-align: center; */
  color: #e87b31;
}

.modalPay {
  height: 530px !important;
  width: 830px !important;
}
</style>
