<template>
  <div>
    <div class="row">
      <div class="col-md-4">
        <ValidationProvider
          name="nombre"
          rules="required"
          v-slot="{ errors }"
          :custom-messages="{ required: 'El nombre es obligatorio' }"
        >
          <base-input
            type="text"
            label="Nombre(s)"
            placeholder="Nombre(s)"
            v-model="currentFirmante.nombre"
          >
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <ValidationProvider
          name="paterno"
          rules="required"
          v-slot="{ errors }"
          :custom-messages="{
            required: 'El apellido paterno es obligatorio'
          }"
        >
          <base-input
            type="text"
            label="Apellido Paterno"
            placeholder="Apellido Paterno"
            v-model="currentFirmante.paterno"
          >
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <base-input
          type="text"
          label="Apellido Materno"
          placeholder="Apellido Materno"
          v-model="currentFirmante.materno"
        >
        </base-input>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <ValidationProvider
          name="cargo"
          rules="required"
          v-slot="{ errors }"
          :custom-messages="{
            required: 'El Cargo es obligatorio'
          }"
        >
          <label for="">Cargo</label>
          <select class="custom-select" v-model="currentFirmante.cargo">
            <option v-for="option in options" :key="option.value">
              {{ option.text }}
            </option>
          </select>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="text-center">
      <button type="submit" class="btn btn-fill float-right orange-btn">
        Guardar
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentFirmante: { type: Object },
    complete: { type: Boolean }
  },

  data() {
    return {
      options: [
        { value: "Presidente", text: "Presidente" },
        { value: "Secretario", text: "Secretario" },
        { value: "Delegado especial", text: "Delegado especial" }
      ],

      // form: {
      //   nombre: null,
      //   paterno: null,
      //   materno: null,
      //   persona: "Física",

      //   cargo: null
      // }
    };
  },

  methods: {}
};
</script>

<style scoped>
.cardErrors {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* color: #9a9a9a; */
  color: #ea640b;
  font-size: 13px;
}
</style>
