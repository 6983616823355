var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('h4',{staticClass:"cardD"},[_vm._v("Datos generales de la Nueva Sociedad")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('ValidationProvider',{attrs:{"name":"tipo_societario","rules":"required","custom-messages":{
        required: 'El tipo de societario es obligatorio',
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{attrs:{"for":""}},[_vm._v("Tipo de societario")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.constitutiva.tipo_societario),expression:"constitutiva.tipo_societario"}],staticClass:"custom-select",attrs:{"disabled":_vm.constitutiva.disabled},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.constitutiva, "tipo_societario", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"SA"}},[_vm._v("SA")]),_c('option',{attrs:{"value":"S de RL"}},[_vm._v("S de RL")]),_c('option',{attrs:{"value":"SAPI"}},[_vm._v("SAPI")])]),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"name":"denominaciones_sociales","rules":"required","custom-messages":{
        required: 'La denominación social es obligatoria',
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"disabled":_vm.constitutiva.disabled,"type":"text","label":"Denominación social","placeholder":"Opción 1"},model:{value:(_vm.constitutiva.denominaciones_sociales_1),callback:function ($$v) {_vm.$set(_vm.constitutiva, "denominaciones_sociales_1", $$v)},expression:"constitutiva.denominaciones_sociales_1"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('ValidationProvider',{attrs:{"name":"denominaciones_sociales","rules":"required","custom-messages":{
        required: 'La denominación social es obligatoria',
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"disabled":_vm.constitutiva.disabled,"type":"text","label":"","placeholder":"Opción 2"},model:{value:(_vm.constitutiva.denominaciones_sociales_2),callback:function ($$v) {_vm.$set(_vm.constitutiva, "denominaciones_sociales_2", $$v)},expression:"constitutiva.denominaciones_sociales_2"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('ValidationProvider',{attrs:{"name":"denominaciones_sociales","rules":"required","custom-messages":{
        required: 'La denominación social es obligatoria',
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"disabled":_vm.constitutiva.disabled,"type":"text","label":"","placeholder":"Opción 3"},model:{value:(_vm.constitutiva.denominaciones_sociales_3),callback:function ($$v) {_vm.$set(_vm.constitutiva, "denominaciones_sociales_3", $$v)},expression:"constitutiva.denominaciones_sociales_3"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-6"},[_c('label',{attrs:{"for":"input-autoriza-cd-denominacion"}},[_vm._v(" En caso de ser rechazada, autoriza a Correduría Digital proponer alguna ")]),_c('b-form-checkbox',{attrs:{"id":"input-autoriza-cd-denominacion","disabled":_vm.constitutiva.disabled},model:{value:(_vm.constitutiva.autoriza_cd_denominacion),callback:function ($$v) {_vm.$set(_vm.constitutiva, "autoriza_cd_denominacion", $$v)},expression:"constitutiva.autoriza_cd_denominacion"}})],1)]),_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"name":"objeto_social","rules":"required","custom-messages":{
        required: 'El objeto social es obligatorio',
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{attrs:{"for":""}},[_vm._v("Objeto social")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.constitutiva.objeto_social),expression:"constitutiva.objeto_social"}],staticClass:"custom-select",attrs:{"disabled":_vm.constitutiva.disabled},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.constitutiva, "objeto_social", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.optionsObjetoSocial),function(option){return _c('option',{key:option.value},[_vm._v(" "+_vm._s(option.text)+" ")])}),0),(_vm.constitutiva.objeto_social === 'Otro')?_c('base-input',{staticClass:"mt-2",attrs:{"disabled":_vm.constitutiva.disabled,"type":"text","label":"","placeholder":"Objeto Social"},model:{value:(_vm.constitutiva.objeto_social_otro),callback:function ($$v) {_vm.$set(_vm.constitutiva, "objeto_social_otro", $$v)},expression:"constitutiva.objeto_social_otro"}}):_vm._e(),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"name":"objeto_actividad","rules":"required","custom-messages":{
        required: 'El objeto y actividad es obligatorio',
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',[_vm._v("Cuéntanos qué actividades desarrollará la empresa")]),_c('b-form-textarea',{attrs:{"disabled":_vm.constitutiva.disabled,"type":"text","placeholder":"Actividades que desarrollará la empresa"},model:{value:(_vm.constitutiva.objeto_actividad),callback:function ($$v) {_vm.$set(_vm.constitutiva, "objeto_actividad", $$v)},expression:"constitutiva.objeto_actividad"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"mt-4"},[_c('div',{},[_c('b-button',{staticClass:"btn-fill orange-btn bottom2",attrs:{"type":"submit","size":"m","disabled":_vm.constitutiva.disabled}},[_vm._v("Continuar")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }